<id37-modal [(modalOpen)]="modalOpen" (afterClose)="closeModal()" [modalSize]="'lg'">
  <id37-modal-title>Werte einfügen</id37-modal-title>
  <id37-modal-body>
    <form [formGroup]="form">
      <div class="clr-row clr-justify-content-between margin-0">
        <div class="clr-col-sm-12 clr-col-sm-12 clr-col-sm-12 clr-col-sm-12">
          <div class="columns">
            <!-- Column 1 -->
            <div class="column-1">
              <div class="clr-form-group">
                <label for="NEU">NEU</label>
                <input type="number" min="1" max="10" id="NEU" formControlName="NEU">
                <div class="clr-form-group">
                  <label for="SAN">SAN</label>
                  <input type="number" min="1" max="10" id="SAN" formControlName="SAN">
                </div>
                <div class="clr-form-group">
                  <label for="EIN">EIN</label>
                  <input type="number" min="1" max="10" id="EIN" formControlName="EIN">
                </div>
                <div class="clr-form-group">
                  <label for="STA">STA</label>
                  <input type="number" min="1" max="10" id="STA" formControlName="STA">
                </div>
              </div>
            </div>
            <div class="column-2">
              <div class="clr-form-group">
                <label for="BES">BES</label>
                <input type="number" min="1" max="10" id="BES" formControlName="BES">
              </div>
              <div class="clr-form-group">
                <label for="AUT">AUT</label>
                <input type="number" min="1" max="10" id="AUT" formControlName="AUT">
              </div>
              <div class="clr-form-group">
                <label for="SOZ">SOZ</label>
                <input type="number" min="1" max="10" id="SOZ" formControlName="SOZ">
              </div>
              <div class="clr-form-group">
                <label for="PRI">PRI</label>
                <input type="number" min="1" max="10" id="PRI" formControlName="PRI">
              </div>
            </div>


            <div class="column-3">
              <div class="clr-form-group">
                <label for="SEN">SEN</label>
                <input type="number" min="1" max="10" id="SEN" formControlName="SEN">
              </div>
              <div class="clr-form-group">
                <label for="STR">STR</label>
                <input type="number" min="1" max="10" id="STR" formControlName="STR">
              </div>
              <div class="clr-form-group">
                <label for="SIC">SIC</label>
                <input type="number" min="1" max="10" id="SIC" formControlName="SIC">
              </div>
              <div class="clr-form-group">
                <label for="REV">REV</label>
                <input type="number" min="1" max="10" id="REV" formControlName="REV">
              </div>
            </div>

            <div class="column-4">
              <div class="clr-form-group">
                <label for="BEW">BEW</label>
                <input type="number" min="1" max="10" id="BEW" formControlName="BEW">
              </div>
              <div class="clr-form-group">
                <label for="ESS">ESS</label>
                <input type="number" min="1" max="10" id="ESS" formControlName="ESS">
              </div>
              <div class="clr-form-group">
                <label for="FAM">FAM</label>
                <input type="number" min="1" max="10" id="FAM" formControlName="FAM">
              </div>
              <div class="clr-form-group">
                <label for="SIN">SIN</label>
                <input type="number" min="1" max="10" id="SIN" formControlName="SIN">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </id37-modal-body>
  <id37-modal-footer>
    <button (click)="submitForm()" class="id37-btn id37-btn-primary">Chat erzeugen</button>
  </id37-modal-footer>
</id37-modal>
