import {MessagePublicationMethod} from './message-publication-method';
import {UserTargetFilter} from './user-target-filter';
import {MulticastMessage} from './multicast-message';
import {LocalizedMessageRepresentation} from './localized-message-representation';

export class MulticastMessageRepresentation {

  public readonly localizedMessage: LocalizedMessageRepresentation;
  public readonly messagePublicationMethod: MessagePublicationMethod;
  public readonly userTargetFilter: UserTargetFilter;
  public readonly userTargetFilterData: string[];
  public readonly gettingAdvertising: boolean;

  constructor(localizedMessage: LocalizedMessageRepresentation,
              messagePublicationMethod: MessagePublicationMethod,
              userTargetFilter: UserTargetFilter,
              userTargetFilterData: string[],
              gettingAdvertising: boolean) {
    this.localizedMessage = localizedMessage;
    this.messagePublicationMethod = messagePublicationMethod;
    this.userTargetFilter = userTargetFilter;
    this.userTargetFilterData = userTargetFilterData;
    this.gettingAdvertising = gettingAdvertising;
  }

  public static toModelEntity(multicastMessageRepresentation: MulticastMessageRepresentation): MulticastMessage {
    return {
      localizedMessage: LocalizedMessageRepresentation.toModelEntity(multicastMessageRepresentation.localizedMessage),
      messagePublicationMethod: multicastMessageRepresentation.messagePublicationMethod,
      userTargetFilter: multicastMessageRepresentation.userTargetFilter,
      userTargetFilterData: multicastMessageRepresentation.userTargetFilterData,
      gettingAdvertising: multicastMessageRepresentation.gettingAdvertising
    };
  }

  public static fromModelEntity(multicastMessage: MulticastMessage): MulticastMessageRepresentation {
    return new MulticastMessageRepresentation(
      LocalizedMessageRepresentation.fromModelEntity(multicastMessage.localizedMessage),
      multicastMessage.messagePublicationMethod,
      multicastMessage.userTargetFilter,
      multicastMessage.userTargetFilterData,
      multicastMessage.gettingAdvertising
    );
  }

}
