import {Injectable} from '@angular/core';
import {CollapsibleHeaderComponent} from '../collapsible-header/collapsible-header.component';
import {Observable, Subject} from 'rxjs';
import {CollapsibleItemComponent} from '../collapsible-item/collapsible-item.component';

@Injectable()
export class ItemToHeaderAndBodyCommunicationService {

  private headerClickSource = new Subject<CollapsibleHeaderComponent>();
  private itemStateSource = new Subject<CollapsibleItemComponent>();
  private levelAdjustmentSource = new Subject<boolean>();

  onHeaderClick$: Observable<CollapsibleHeaderComponent> = this.headerClickSource.asObservable();
  onItemStateChange$: Observable<CollapsibleItemComponent> = this.itemStateSource.asObservable();
  onLevelShouldBeAdjusted$: Observable<boolean> = this.levelAdjustmentSource.asObservable();

  headerClicked(header: CollapsibleHeaderComponent): void {
    this.headerClickSource.next(header);
  }

  itemStateChanged(item: CollapsibleItemComponent): void {
    this.itemStateSource.next(item);
  }

  propagateLevelAdjustment(parentIsOdd: boolean): void {
    this.levelAdjustmentSource.next(parentIsOdd);
  }

}
