<div class="master-report-box">

  <table>
    <tr>
      <td>{{__('testPrice')}}:</td>
      <td *ngIf="!masterReport">{{__('calculating')}}</td>
      <td *ngIf="masterReport">{{masterReport.getPriceForId37Test}}</td>
    </tr>
    <tr>
      <td>{{__('amountOfClients')}}:</td>
      <td *ngIf="!masterReport">{{__('calculating')}}</td>
      <td *ngIf="masterReport">{{masterReport.clientCount}}</td>
    </tr>
    <tr>
      <td>{{__('amountOfFinishedTests')}}:</td>
      <td *ngIf="!masterReport">{{__('calculating')}}</td>
      <td *ngIf="masterReport">{{masterReport.finishedTestsCount}}</td>
    </tr>
  </table>

</div>
