import {BillingAddress} from './billing-address';

export class BillingAddressRepresentation {

  public readonly address: string;

  constructor(address: string) {
    this.address = address;
  }

  static buildFrom(billingAddress: BillingAddress) {
    return new BillingAddressRepresentation(
      billingAddress.address
    );
  }

}
