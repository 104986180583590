import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Team} from '../../team/state/team';
import {TeamService} from '../../team/state/team.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input()
    listItems: Team[];

  @Input()
    selectedItem: Team;

  showList: boolean = false;

  constructor(private elRef: ElementRef,
              private teamService: TeamService) {
  }

  ngOnInit(): void {
    this.selectedItem = this.listItems[0];
  }

  toggle(): void {
    this.showList = !this.showList;
    window.dispatchEvent(new Event('resize')); // otherwise cdkVirtualFor does not work properly
  }

  close(): void {
    this.showList = false;
  }

  select(index: number): void {
    this.selectedItem = this.listItems[index];
    this.teamService.changedSelectedTeam(this.selectedItem);
    this.showList = false;
  }

  calculateDropdownHeight(): number {
    const ROW_HEIGHT_IN_PIXEL = 38.5;
    if (this.listItems.length === 1) {
      return 45;
    }
    else {
      return Math.min(ROW_HEIGHT_IN_PIXEL * this.listItems.length, 11 * ROW_HEIGHT_IN_PIXEL);
    }
  }

}
