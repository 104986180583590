import {CartUpdate} from './cart-update';

export class CartUpdateRepresentation {

  public readonly product: string;
  public readonly amount: number | null;
  public readonly discountCode: string | null;

  constructor(product: string, amount: number | null, discountCode: string | null) {
    this.product = product;
    this.amount = amount;
    this.discountCode = discountCode;
  }

  static buildFrom(cartUpdate: CartUpdate): CartUpdateRepresentation {
    return new CartUpdateRepresentation(
      cartUpdate.product,
      typeof cartUpdate.amount === typeof undefined ? null : cartUpdate.amount,
      typeof cartUpdate.discountCode === typeof undefined ? null : cartUpdate.discountCode
    );
  }

}
