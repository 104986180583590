import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ActiveUser} from './active-user';
import {ActiveUserBuilder, ActiveUserRepresentation} from './active-user-representation';
import {ChatbotUserStatus} from '../user-data/chatbot-user-status';
import {Router} from '@angular/router';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private router: Router) {
  }

  public ensureBackendUserExists(): Observable<ActiveUser> {
    return this.http.post<ActiveUserRepresentation>(
      API_URL + '/user/ensureBackendUserExists',
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(
      map((activeUserRepresentation: ActiveUserRepresentation): ActiveUser =>
        ActiveUserBuilder.toModelEntity(activeUserRepresentation)
      )
    );
  }

  public getChatbotUserStatus(): Observable<ChatbotUserStatus> {
    return this.http.get<ChatbotUserStatus>(
      API_URL + '/user/getChatbotStatus',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    );
  }

  public activateChatbotFreeTrial(): Observable<ChatbotUserStatus> {
    return this.http.patch<ChatbotUserStatus>(
      API_URL + '/user/activateChatbotTest',
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public rejectChatbotPurchase(): void {
    this.http.post(`${API_URL}/user/reject-chatbot-purchase`,
      null,
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }).subscribe((res) => this.router.navigate(['dashboard']));
  }

}
