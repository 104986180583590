import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MotiveScore} from '../../../test/state/motive-score';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {Friendship} from '../../../friend/state/friendship';
import {ScrollService} from '../../../id37/scroll.service';
import {CollapsibleItemComponent} from '../../../collapsible/collapsible-item/collapsible-item.component';
import {FriendshipService} from '../../../friend/state/friendship.service';

@Component({
  selector: 'app-distant-filter',
  templateUrl: './distant-filter.component.html',
  styleUrls: ['./distant-filter.component.scss']
})

export class DistantFilterComponent implements OnInit {

  @ViewChild('exerciseDialogue')
    collapsibleItem: CollapsibleItemComponent;

  @Output()
    clickedMaster = new EventEmitter();

  @Input()
    distantMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    friendship: Friendship;

  @Input()
    friendId: string;

  @Input()
    friendFullName: string;

  @Input()
    friendFirstname: string;

  @Input()
    friendGender: string;

  @Input()
    distance: number;

  motivePrefix: string;

  motiveTextHtml: string[] = [];

  constructor(private currentUser: CurrentUser,
              private friendshipService: FriendshipService,
              public scrollService: ScrollService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    if (this.distantMotiveScoreTuple.length === 1) {
      this.motivePrefix = this.translatorService.translate('distanceGraphicPage.motive');
    }
    else {
      this.motivePrefix = this.translatorService.translate('distanceGraphicPage.motives');
    }
    this.computeMotiveText();
  }

  computeMotiveText(): void {
    this.distantMotiveScoreTuple.forEach((tuple) => {
      const motive: string = this.translatorService.translate('motive.' + tuple[0].motive.toLowerCase());
      let htmlString: string = '<strong>' + motive.toUpperCase()
        + this.translatorService.translate('distantFilter.motiveText.headline')
        + Math.abs(tuple[0].score - tuple[1].score) + '</strong><br>';
      if (tuple[1].score > 7) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.high');
      }
      else if (tuple[1].score < 4) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.low');
      }
      else if (tuple[0].score > 8) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.medium-high');
      }
      else if (tuple[0].score < 3) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.medium-low');
      }
      else {
        throw new Error('No cases');
      }
      htmlString = htmlString.replace('*SCORE_FR*', tuple[1].score.toString());
      htmlString = htmlString.replace('*SCORE*', tuple[0].score.toString());
      this.motiveTextHtml.push(htmlString);
    });
  }

  scroll(anchor: string, position: 'top' | 'middle' = 'top') {
    this.collapsibleItem.setExpanded(true);
    setTimeout(() => this.scrollService.scrollToElement(anchor, position), 200);
  }

  clickMaster(): void {
    this.clickedMaster.emit();
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('distantFilter.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation.replace(/\*FIRSTNAME\*/g, this.friendFirstname);
    revisedTranslation = revisedTranslation.replace(/\*MOTIVES\*/g, this.motivePrefix);
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*GENITIVE.F\/PL\*/g, this.translatorService.translate(
        'possessivePronoun.genitive.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.dative.f',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.m',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.C\*/g, this.translatorService.translate(
        'personalPronoun.nominative.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'personalPronoun.accusative',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
