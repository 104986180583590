import {Component} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {ChatbotDocumentsToolsComponent} from './chatbot-documents-tools/chatbot-documents-tools.component';

@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent {
  protected readonly ChatbotDocumentsComponent = ChatbotDocumentsToolsComponent;
  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate('toolbox.' + key);
  }
}
