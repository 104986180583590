import {Component, Input} from '@angular/core';
import {HowMotivesWorkText} from '../types/how-motives-work-text';
import {CurrentUser} from '../../user/current-user.service';

@Component({
  selector: 'app-how-motives-work',
  templateUrl: './how-motives-work.component.html',
  styleUrls: ['./how-motives-work.component.scss']
})
export class HowMotivesWorkComponent {

  @Input()
    howMotivesWorkText: HowMotivesWorkText;

  locale: string;

  constructor(private currentUser: CurrentUser) {
    this.locale = currentUser.locale;
  }

}
