<div class="content-container">
  <div class="parent">
    <div class="error-smiley">
      <img src="assets/icons/error-smiley.svg" alt="sad smiley"/>
    </div>
    <div class="error-text">
      <h1>Our Service is temporarily unavailable</h1>
      <h2>Please try again at another point in time</h2>
    </div>
  </div>
</div>
