import {Component, Input} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {Test} from '../state/test';
import {TestOriginBuy} from '../origin/test-origin-buy';
import {TestFunctions} from '../state/test.functions';
import {Id37Router} from '../../id37-router';

@Component({
  selector: 'app-test-info',
  templateUrl: './test-info.component.html',
  styleUrls: ['./test-info.component.scss']
})
export class TestInfoComponent {

  @Input()
    test: Test;

  constructor(public testFunctions: TestFunctions,
              public id37router: Id37Router,
              private translatorService: TranslatorService) {
  }

  status(): string {
    if (this.test.accessGranted) {
      return this.__('status.accessGranted');
    }
    else if (this.test.finished) {
      return this.__('status.finished');
    }
    else if (this.test.started) {
      return this.__('status.started');
    }
    return this.__('status.new');
  }

  public navigateToOrders(): void {
    this.id37router.routeToOrder((this.test.origin as TestOriginBuy).orderId);
  }

  __(key: string): string {
    return this.translatorService.translate('testInfo.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
