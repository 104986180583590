// eslint-disable-next-line no-shadow
export enum TestOriginType {

  UNKNOWN = 'UNKNOWN',
  IMPORT = 'IMPORT',
  INVITATION = 'INVITATION',
  BUY = 'BUY'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TestOriginType {
  export const LIST: TestOriginType[] = [
    TestOriginType.UNKNOWN, TestOriginType.IMPORT, TestOriginType.INVITATION, TestOriginType.BUY
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): TestOriginType | undefined => {
    name = !!name ? name : '';
    const found = TestOriginType.LIST.find((status: string) => status.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a test origin type with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

