<svg [attr.width]="500" [attr.height]="90" [attr.x]="880" [attr.y]="-16">

  <defs>
    <linearGradient id="leftRight" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color=#05F5FD />
        <stop offset="100%" stop-color=#00FF3D />
    </linearGradient>
  </defs>

  <text [attr.x]="0" [attr.y]="50" dominant-baseline="central" class="text-color">{{__('you')}}</text>
  <text [attr.x]="135" [attr.y]="50" dominant-baseline="central" class="text-color">{{friendFirstname}}</text>
  <line [attr.x1]="50" y1="50" [attr.x2]="115" y2="50.001" stroke="url(#leftRight)" stroke-width="20"
        stroke-linecap="round"/>
  <circle [attr.cx]="50" cy=50 r="5" fill="white"/>
  <line [attr.x1]="55" y1="50" [attr.x2]="110" y2="50.01" stroke="white" stroke-width="2"/>
  <polygon points="110,45 110,55 120,50" fill="white"/>

</svg>

<ng-container *ngFor="let motiveScoreTuple of normalizedMotiveScoreTuple; let i = index">

  <svg app-distance-score-description
       [motiveScoreA]="motiveScoreTuple[0]"
       [motiveScoreB]="motiveScoreTuple[1]"
       [index]="i"/>

</ng-container>
