import {Component, OnInit} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {UserService} from '../../user/user.service';
import {ChatbotUserStatus} from '../../user-data/chatbot-user-status';
import {OrderService} from '../order.service';
import {Order} from '../order';
import {OrderStatus} from '../order-status';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  chatbotUserStatus: ChatbotUserStatus;
  orders: Order[];

  constructor(private translatorService: TranslatorService,
              private userService: UserService,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.getUserData();
    this.getOrderData();
  }

  getUserData(): void {
    this.userService.getChatbotUserStatus().subscribe({
      next: (chatbotUserStatus: ChatbotUserStatus) => {
        this.chatbotUserStatus = chatbotUserStatus;
      }
    });
  }

  getOrderData() {
    this.orderService.loadOrdersForCurrentUser().subscribe(orders => {
      this.orders = orders
        .filter(order => this.canShowOrder(order))
        .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    });
  }

  private canShowOrder(order: Order) {
    return order.status === OrderStatus.COMPLETED || order.status === OrderStatus.UNSUBSCRIBED
      || order.status === OrderStatus.SUBSCRIPTION_TERMINATED;
  }

  canShowTestJayComponent(): boolean {
    return this.chatbotUserStatus === 'CHATBOT_NOT_TRIED_YET';
  }

  __(key: string): string {
    return this.translatorService.translate('orders.' + key);
  }
}
