import {Component} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateTimeSelectionEvent} from '../../datetime/state/date-time-selection-event';
import {TranslatorService} from '../../translation/translator.service';
import {AdminService} from '../admin.service';
import {FileDownloadService} from '../../shared/file-download-service';
import {MediaType} from '../../shared/media-type';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent {

  ongoingRequest: boolean = false;

  dateOf: string;
  dateTo: string;

  minFromDate: Moment = moment('1990-01-01').hour(0).minute(0);
  maxFromDate: Moment = moment().hour(23).minute(59);

  minToDate: Moment = moment('1990-01-01').hour(0).minute(0);
  maxToDate: Moment = moment().hour(23).minute(59);

  dateFromDisabled = false;
  dateToDisabled = false;

  constructor(private adminService: AdminService,
              private fileDownloadService: FileDownloadService,
              private translatorService: TranslatorService) {
  }

  onStartDateSelection(selected: DateTimeSelectionEvent): void {
    this.dateOf = selected.isoUtcFormatted + 'T00:00:00.000Z';
    this.minToDate = moment(this.dateOf).hour(0).minute(0);
  }

  onEndDateSelection(selected: DateTimeSelectionEvent): void {
    this.dateTo = selected.isoUtcFormatted + 'T23:59:59.000Z';
  }

  downloadBillingDetails(): void {
    this.ongoingRequest = true;
    this.adminService.downloadBillingDetails(this.dateOf, this.dateTo)
      .subscribe((response: HttpResponse<ArrayBuffer>) => {
        this.fileDownloadService.saveToFileSystemBlob(response,
          MediaType.EXCEL,
          'billingDetails.xlsx');
        this.ongoingRequest = false;
      }, error => this.ongoingRequest = false
      );
  }

  downloadPartnerBillingDetails(): void {
    this.ongoingRequest = true;
    this.adminService.downloadPartnerBillingDetails(this.dateOf, this.dateTo)
      .subscribe((response: HttpResponse<ArrayBuffer>) => {
        this.fileDownloadService.saveToFileSystemBlob(response,
          MediaType.EXCEL,
          'billingDetails.xlsx');
        this.ongoingRequest = false;
      }, error => this.ongoingRequest = false
      );
  }

  __(key: string): string {
    return this.translatorService.translate('admin.billingDetails.' + key);
  }

}
