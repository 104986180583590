<button *ngIf="showScrollButton && !mobileSidebar" type="button" (click)="scrollToBottom(target)" class="id37-btn id37-btn-secondary id37-btn-sm id37-btn-rounded-1 scroll-down-button">
  <img alt="Create Chat Icon" src="assets/icons/arrow_down_20x20.svg">
</button>

<ng-container *ngIf="chatbotChat" (scroll)="onScroll($event)">
  <ng-container *ngFor="let message of chatbotChat.messages">
    <ng-container *ngIf="message.question">
      <div class="chat-message-container chat-bubble-own">
        <app-chatbot-chat-message class="chat-message" [enableAutoscroll]="autoscroll" [question]="true" [chatQuestion]="message"></app-chatbot-chat-message>
      </div>
    </ng-container>
    <ng-container *ngIf="message.answer">
      <div class="chat-message-container chat-bubble-chatbot">
        <app-chatbot-chat-message class="chat-message" [enableAutoscroll]="autoscroll" [question]="false" [chatMessage]="message"></app-chatbot-chat-message>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div #target>
</div>
