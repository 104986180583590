import {Component, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AccountDataQuery} from '../../account/account-data-query';
import {AccountDataService} from '../../account/account-data-service';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-invited-by-partner',
  templateUrl: './invited-by-partner.component.html',
  styleUrls: ['./invited-by-partner.component.scss']
})
export class InvitedByPartnerComponent implements OnInit, OnDestroy {

  partnerId: string | null = null;
  partnerName: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private accountDataQuery: AccountDataQuery,
              private accountDataService: AccountDataService,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    if (!this.accountDataQuery.hasAccountDataForCurrentUser()) {
      this.accountDataService.loadForCurrentUser().pipe(take(1)).subscribe();
    }
    this.accountDataQuery.selectAccountDataForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accountData => {
        if (!!accountData) {
          this.partnerId = accountData.partnerInfo.userId;
          this.partnerName = (accountData.partnerInfo.firstName + ' ' + accountData.partnerInfo.lastName).trim();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  __(key: string): string {
    return this.translatorService.translate('invited-by-partner.' + key);
  }

}
