<div class="notifications">
  <clr-dropdown>
    <div class="dropdown-trigger" clrDropdownTrigger>
      <div class="bell-icon-wrapper">
        <img alt="notification bell - {{unreadNotificationAmount}} new notification" class="icon-svg"
             src="assets/icons/ID37_Notification_Inactive_36x36.svg"/>
        <app-unread-info
            [amount]="unreadNotificationAmount"
            [emRight]="0.55"
            [emSize]="0.4"
            [emTop]="-0.15"
        ></app-unread-info>
      </div>
    </div>
    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
      <div class="actions-top">
        <span (click)="markAllAsRead()" id="mark-all-notifications-as-read">
          {{__('markAllAsRead')}}
        </span>
      </div>
      <app-notifications></app-notifications>
    </clr-dropdown-menu>
  </clr-dropdown>
</div>
