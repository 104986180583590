import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationQuery} from './state/notification.query';
import {Subject} from 'rxjs';
import {Notification} from './state/notification';
import {takeUntil} from 'rxjs/operators';
import {TranslatorService} from '../translation/translator.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  notifications: Notification[];

  constructor(private notificationQuery: NotificationQuery,
              private translatorService: TranslatorService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.notificationQuery.selectNotifications()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(notifications => this.notifications = notifications);
  }

  __(key: string): string {
    return this.translatorService.translate('notifications.' + key);
  }

}
