import {Component, OnInit, ViewChild} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {CurrentUser} from '../user/current-user.service';
import {TranslatorService} from '../translation/translator.service';
import {take, tap} from 'rxjs/operators';
import {LocaleService} from '../user/locale.service';
import {Id37Language} from '../shared/id37-language';
import {UserInviteOptionComponent} from '../user/user-invite-option/user-invite-option.component';
import {TestQuery} from '../test/state/test.query';
import {Test} from '../test/state/test';
import {TestFunctions} from '../test/state/test.functions';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @ViewChild('userInviteOptionComponent') userInviteOptionComponent: UserInviteOptionComponent;

  /* Whether or not the mobile menu is opened. */
  mobileMenuOpen = false;
  locale: string;
  fullName: string;
  hasTestAccessGranted: boolean;
  hasBot: boolean;
  userId: string;
  testId: string;

  // TODO: Load from keycloak or backend?
  locales: string[] = Id37Language.STRING_LIST;

  constructor(private currentUser: CurrentUser,
              private keycloakService: KeycloakService,
              private localeService: LocaleService,
              private testFunctions: TestFunctions,
              private testQuery: TestQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.locale = this.currentUser.locale;
    this.fullName = this.currentUser.fullName;
    this.userId = this.currentUser.keycloakId;
    // TODO: wird beim Init mehrmals aufgerufen
    this.testQuery.selectTestsForCurrentUser()
      .pipe(tap((tests: Test[]) => {
        const completedTests = tests.filter(test => this.testFunctions.isCompleted(test));
        const firstCompletedTest = completedTests.shift();
        if (!!firstCompletedTest) {
          this.hasTestAccessGranted = true;
          this.testId = firstCompletedTest.testId;
        }
      })).subscribe();
    this.hasBot = this.showBot();
  }

  openMobileMenu(): void {
    document.body.classList.toggle('noScroll', true);
    this.mobileMenuOpen = true;
  }

  // close 'burger-menu' after selecting
  closeMobileMenu(): void {
    this.mobileMenuOpen = false;
    document.body.classList.toggle('noScroll', false);
  }

  openRecommendModal(): void {
    this.userInviteOptionComponent.show();
  }

  setLocale(locale: string): void {
    this.localeService.updateLanguage(locale)
      .pipe(take(1))
      .subscribe(() => {
        // TODO: Can we change the language without doing a full reload?
        location.reload();
      });
  }

  async doLogout(): Promise<any> {
    await this.keycloakService.logout();
  }

  showBot(): boolean {
    return this.currentUser.hasRole('administrator') || this.hasTestAccessGranted;
  }

  __(key: string): string {
    return this.translatorService.translate('nav.' + key);
  }

  ___(key: string, language?: string): string {
    return this.translatorService.translate(key, language);
  }

}
