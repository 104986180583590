import {Component, Input} from '@angular/core';
import {ProfileExampleText} from '../types/profile-example-text';

@Component({
  selector: 'app-profile-example',
  templateUrl: './profile-example.component.html',
  styleUrls: ['./profile-example.component.scss']
})
export class ProfileExampleComponent {

  @Input()
    profileExample: ProfileExampleText;

  constructor() {
  }

}
