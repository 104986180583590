import {Component, HostBinding} from '@angular/core';

import {CollapsibleAnimationsService} from '../services/collapsible-animation.service';
import {CollapsibleItemComponent} from '../collapsible-item/collapsible-item.component';
import {ItemToHeaderAndBodyCommunicationService} from '../services/item-to-header-and-body-communication.service';
import {LevelAdjustmentService} from '../services/level-adjustment.service';

@Component({
  selector: 'app-collapsible-body',
  templateUrl: './collapsible-body.component.html',
  styleUrls: ['./collapsible-body.component.scss'],
  animations: CollapsibleAnimationsService.collapsibleBodyAnimations('collapsibleBodyState'),
  providers: [LevelAdjustmentService]
})
export class CollapsibleBodyComponent {

  @HostBinding('@collapsibleBodyState')
    expandedState: string;

  constructor(private itemToHeaderAndBodyCom: ItemToHeaderAndBodyCommunicationService,
              private levelAdjustmentService: LevelAdjustmentService) {
    this.itemToHeaderAndBodyCom.onItemStateChange$.subscribe((item: CollapsibleItemComponent) => {
      this.expandedState = item.expanded.toString();
    });

    this.itemToHeaderAndBodyCom.onLevelShouldBeAdjusted$.subscribe((parentIsOdd: boolean) => {
      this.levelAdjustmentService.adjustLevels(parentIsOdd);
    });
  }

}
