<div class="clr-row clr-justify-content-center" style="margin-top: 2em">
  <div class="clr-col-xl-11 clr-col-lg-11 clr-col-md-11 clr-col-sm-11 clr-col-xs-12" style="display: flex">
    <button (click)="onCreate()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2">
      <img alt="plus" src="assets/icons/add_onButton.svg"/>
      <span>Neuen Code hinzufügen</span>
    </button>
    <button (click)="onGenerateCode()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2">
      <img alt="plus" src="assets/icons/add_onButton.svg"/>
      <span>Neue Codes generieren</span>
    </button>
    <div class="search-input-container">
      <input (keyup)="applyFilter()" [(ngModel)]="searchKey" autocomplete="off" placeholder="Suchen"/>
      <img (click)="onClear()" *ngIf="searchKey" alt="X" src="assets/icons/ID37_X.svg"/>
    </div>
  </div>
</div>

<div class="clr-row clr-justify-content-center" style="margin-top: 2em">
  <div class="clr-col-xl-11 clr-col-lg-10 clr-col-md-11 clr-col-sm-11">
    <clr-datagrid class="test-datagrid">
      <clr-dg-column [clrDgField]="'code'" class="customer-col">Code</clr-dg-column>
      <clr-dg-column [clrDgField]="'discountAsNumber'" class="test-clr-dg-column1">Rabatt</clr-dg-column>
      <clr-dg-column [clrDgField]="'active'">Aktiviert</clr-dg-column>
      <clr-dg-column [clrDgField]="'category'">Kategorie</clr-dg-column>
      <clr-dg-column [clrDgField]="'description'">Beschreibung</clr-dg-column>
      <clr-dg-column [clrDgField]="'activeFrom'">Aktiv<br>ab</clr-dg-column>
      <clr-dg-column [clrDgField]="'activeUntil'">Aktiv<br>bis</clr-dg-column>
      <clr-dg-column [clrDgField]="'uses'">Benutzungen</clr-dg-column>
      <!--      <clr-dg-column [clrDgField]="'usesLeft'">Benutzungen<br>übrig</clr-dg-column>-->
      <clr-dg-column [clrDgField]="'maximumUses'">Maximale<br>Verwendung</clr-dg-column>
      <clr-dg-column [clrDgField]="'createdAt'">Erstellt<br>am</clr-dg-column>
      <clr-dg-column [clrDgField]="'user'">User</clr-dg-column>
      <clr-dg-column [clrDgField]="'partner'">Affiliate</clr-dg-column>
      <clr-dg-column>Aktionen</clr-dg-column>

      <ng-container *ngIf="!loading">
        <clr-dg-row *clrDgItems="let t of discountCodeView" class="test-clr-dg-row">
          <clr-dg-cell class="big-col-width">
            <div class="cell-content">{{t.code}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="x-small-col-width">
            <div class="cell-content">{{t.discountAsNumber}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="x-small-col-width">
            <div class="cell-content">{{t.active}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="small-col-width">
            <div class="cell-content">{{t.category}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="normal-col-width">
            <div class="cell-content">{{t.description}}</div>
          </clr-dg-cell>
          <clr-dg-cell [innerHTML]="t.activeFrom | MomentDateTime"
                       style="font-size: 16px;line-height: 24px;"></clr-dg-cell>
          <clr-dg-cell [innerHTML]="t.activeUntil | MomentDateTime"
                       style="font-size: 16px;line-height: 24px;"></clr-dg-cell>
          <clr-dg-cell class="x-small-col-width">
            <div class="cell-content">{{t.uses}}</div>
          </clr-dg-cell>
          <!--        <clr-dg-cell class="x-small-col-width">-->
          <!--          <div class="cell-content">-->
          <!--            <span *ngIf="t.usesLeft ===-1; else uses">∞</span>-->
          <!--            <ng-template #uses>{{ t.usesLeft }}</ng-template>-->
          <!--          </div>-->
          <!--        </clr-dg-cell>-->
          <clr-dg-cell class="x-small-col-width">
            <div class="cell-content">{{t.maximumUses || '∞'}}</div>
          </clr-dg-cell>
          <clr-dg-cell [innerHTML]="t.createdAt | MomentDateTime"
                       style="font-size: 16px;line-height: 24px;"></clr-dg-cell>
          <clr-dg-cell class="normal-col-width">
            <div class="cell-content">{{t.userMail}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="normal-col-width">
            <div class="cell-content">{{t.affiliateMail}}</div>
          </clr-dg-cell>
          <clr-dg-cell class="small-col-width">
            <div class="button-container">
              <button (click)="onEdit(t)" class="id37-btn id37-btn-secondary id37-btn-sm">Bearbeiten</button>
              <button (click)="onDelete(t)" class="id37-btn id37-btn-danger id37-btn-sm">löschen</button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>
      </ng-container>
      <clr-dg-footer>
        <div class="footer">
          <span class="id37-text1">Codes: {{currentPage * pageSize }} - {{calcLastPage()}} von {{discountCodeList.length}}</span>
          <button (click)="previousPage()" class="id37-btn-outline pagination-btn"
                  [disabled]="(currentPage < 1) || loading">
            <img src="assets/icons/arrow_pager_left_20x20.svg" alt="arrow left"/>
          </button>
          <button (click)="nextPage()" class="id37-btn-outline pagination-btn"
                  [disabled]="(this.currentPage === this.lastPage && allCodesLoaded) || loading">
            <img src="assets/icons/arrow_pager_right_20x20.svg" alt="arrow right"/>
          </button>
        </div>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</div>
<app-loading-indicator *ngIf="loading"></app-loading-indicator>
<app-discount-code-generate-form #dcgForm></app-discount-code-generate-form>

<id37-modal [(modalOpen)]="clickOnEdit" [modalSize]="'lg'">
  <id37-modal-title>Rabattcode ändern</id37-modal-title>
  <id37-modal-body>
    <app-discountcode-form (refreshListonUpdate)="this.getDiscountCodeList()"
                           (updateClickOnEdit)="getUpdatedValue($event)"
                           [codeUsed]="codeUsed"
                           [edit]="getDiscountCodeFormService().edit">
    </app-discountcode-form>
  </id37-modal-body>
</id37-modal>

<id37-modal [(modalOpen)]="clickDelete">
  <id37-modal-title>
    Möchten Sie den Rabattcode wirklich löschen?
  </id37-modal-title>
  <id37-modal-body>
    Diese Entscheidung kann nicht rückgängig gemacht werden.
  </id37-modal-body>
  <id37-modal-footer>
    <div class="id37-btn-row">
      <button (click)="onDeleteConfirm(); closeDeleteModal()" class="id37-btn id37-btn-danger"
              type="button">
        Löschen
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn id37-btn-secondary"
              type="button">
        Abbrechen
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
