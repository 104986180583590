<div class="clr-row clr-justify-content-center" style="margin-top: 2em">
  <div class="clr-col-xl-8 clr-col-lg-10 clr-col-md-12 clr-col-sm-12">

    <input [(ngModel)]="friendFullName" type="text">
    <label class="id37-radio-label id37-text3" for="male">
      <input [(ngModel)]="friendGender" class="radio-inline" id="male" name="gender" type="radio" value="male">
      <div class="id37-radio-button"></div>
      Male
    </label>
    <label class="id37-radio-label id37-text3" for="female">
      <input [(ngModel)]="friendGender" class="radio-inline" id="female" name="gender" type="radio"
             value="female">
      <div class="id37-radio-button"></div>
      Female
    </label>
    <label class="id37-radio-label id37-text3" for="diverse">
      <input [(ngModel)]="friendGender" class="radio-inline" id="diverse" name="gender" type="radio"
             value="diverse">
      <div class="id37-radio-button"></div>
      Diverse
    </label>

    <button (click)="switchGender()" class="btn btn-primary">Switch Gender</button>

    <h2>Fragen</h2>
    <div class="motive-wrapper id37-text2 text-light">
      <div *ngFor="let element of questionTextHtml" [innerHTML]="element" class="motive-text"></div>
    </div>

    <h2>Distant-Filter</h2>
    <div class="motive-wrapper id37-text2 text-light">
      <div *ngFor="let element of distantMotiveTextHtml" [innerHTML]="element" class="motive-text"></div>
    </div>

    <h2>Nah-Filter</h2>
    <div class="motive-wrapper id37-text2 text-light">
      <div *ngFor="let element of closeMotiveTextHtml" [innerHTML]="element" class="motive-text"></div>
    </div>

  </div>
</div>
