import {JobAndEducation} from './job-and-education';

export class JobAndEducationRepresentation {

  public readonly industry: string;
  public readonly company: string;
  public readonly profession: string;
  public readonly academicDegree: string;
  public readonly academicTitle: string;

  constructor(industry: string, company: string, profession: string, academicDegree: string, academicTitle: string) {
    this.industry = industry;
    this.company = company;
    this.profession = profession;
    this.academicDegree = academicDegree;
    this.academicTitle = academicTitle;
  }

  static toModelEntity(jobAndEducationRepresentation: JobAndEducationRepresentation): JobAndEducation {
    return {
      industry: jobAndEducationRepresentation.industry,
      company: jobAndEducationRepresentation.company,
      profession: jobAndEducationRepresentation.profession,
      academicDegree: jobAndEducationRepresentation.academicDegree,
      academicTitle: jobAndEducationRepresentation.academicTitle
    };
  }

}
