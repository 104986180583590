import {Pipe, PipeTransform} from '@angular/core';
import {TestOriginBuy} from './test-origin-buy';

@Pipe({
  name: 'TestOriginBuyCast',
  pure: true
})
export class TestOriginBuyCastPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): TestOriginBuy {
    return value as TestOriginBuy;
  }

}
