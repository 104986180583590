import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {BrowserService} from '../../shared/browser.service';

@Component({
  selector: 'app-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent {

  @Input()
    text: string = '';

  @Input()
    showFirefoxWarning: boolean = false;

  @Output()
    buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private translatorService: TranslatorService,
              public browserService: BrowserService) {
  }

  __(key: string): string {
    return this.translatorService.translate('report.print-document.' + key);
  }

}
