<div class="chatbot-startscreen">
    <div class="chatbot-welcome-frame">
      <div class="intro">
        <div class="avatar">
          <img src="assets/icons/chatbot/JayIcon.svg" class="icon-svg" alt="Jay Avatar Icon">
        </div>
        <div class="message">
            <h2> {{__('Hello')}}</h2>
            <h4>{{__('Message')}}</h4>
        </div>
      </div>
    </div>
    <div class="chatbot-suggestions">
      <div class="suggestions-frame">
        <ng-container *ngFor="let suggestion of suggestionList">
          <div (click)="sendMessage(suggestion.title, suggestion.content)" class="suggestion">
            <div class="suggestion-text">
              <div class="suggestion-text-frame">
                <h6>{{suggestion.title}}</h6>
                <p *ngIf="suggestion.content">"{{suggestion.content}}"</p>
              </div>
            </div>
            <div class="suggestion-img-wrapper">
              <img alt="send message" src="assets/icons/ID37_Chatbot_IconSend_Filled.svg"/>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
</div>
