import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {MulticastMessageRepresentation} from './state/multicast-message-representation';
import {MulticastMessage} from './state/multicast-message';
import {map} from 'rxjs/operators';
import {Id37Language} from '../../shared/id37-language';
import {MessagePreview} from './state/message-preview';
import {MessagePreviewRepresentation} from './state/message-preview-representation';
import {BasicUserInvitationRepresentation} from '../../user/invitation/basic-user-invitation-representation';
import {BasicUserInvitation} from '../../user/invitation/basic-user-invitation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) {
  }

  public multicastMessage(multicastMessage: MulticastMessage): Observable<string[]> {
    return this.http.post<string[]>(
      API_URL + '/messages/multicast',
      MulticastMessageRepresentation.fromModelEntity(multicastMessage),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    );
  }

  public multicastMessagePreview(multicastMessage: MulticastMessage,
                                 language: Id37Language): Observable<MessagePreview> {
    return this.http.post<MessagePreviewRepresentation>(
      API_URL + '/messages/multicast/message-preview/' + language,
      MulticastMessageRepresentation.fromModelEntity(multicastMessage),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    );
  }

  public multicastMessageReceiversPreview(multicastMessage: MulticastMessage): Observable<BasicUserInvitation[]> {
    return this.http.post<BasicUserInvitationRepresentation[]>(
      API_URL + '/messages/multicast/receivers-preview',
      MulticastMessageRepresentation.fromModelEntity(multicastMessage),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(map((basicUserInvitationRepresentations: BasicUserInvitationRepresentation[]) =>
      basicUserInvitationRepresentations.map(basicUserInvitationRepresentation =>
        BasicUserInvitationRepresentation.toModelEntity(basicUserInvitationRepresentation))
    ));
  }

}
