import {Component, Input} from '@angular/core';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';

@Component({
  selector: 'app-localized-raw-message',
  templateUrl: './localized-raw-message.component.html',
  styleUrls: ['./localized-raw-message.component.scss']
})
export class LocalizedRawMessageComponent {

  @Input()
    notification: Notification;

  constructor(public currentUser: CurrentUser) {
  }

}
