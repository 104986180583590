import {Component} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {

  private readonly paidServiceURL = 'https://id37.typeform.com/to/rVvlYZ';
  private readonly freeServiceURL = 'https://id37.typeform.com/to/LZlIRa';

  constructor(private translatorService: TranslatorService) {
  }

  openPaidService(): void {
    window.open(this.paidServiceURL, '_blank');
  }

  openFreeService(): void {
    window.open(this.freeServiceURL, '_blank');
  }

  __(key: string): string {
    return this.translatorService.translate('master.support.' + key);
  }

}
