import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TranslatorService} from '../../../translation/translator.service';
import { ChatbotDocumentService } from '../../../chatbot/api-services/chatbot-document.service';

@Component({
  selector: 'app-documents',
  templateUrl: './chatbot-documents-tools.component.html',
  styleUrls: ['./chatbot-documents-tools.component.scss']
})
export class ChatbotDocumentsToolsComponent implements OnInit {

  @ViewChild('fileInput', {static: true})
    fileInput: ElementRef;

  selectedFile: File;
  success = false;
  failureState = false;
  failure: { importErrors: [{ rowNumber: number; errorMessage: string }] };
  inProgress = false;

  constructor(private translatorService: TranslatorService,
              private dokumentService: ChatbotDocumentService) {}

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.failure = null;
    this.failureState = false;
    this.success = false;
  }

  onUpload() {
    this.inProgress = true;
    const uploadData = new FormData();
    uploadData.append('upload', this.selectedFile, this.selectedFile.name);
    this.dokumentService.importJson(uploadData)
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.failure = err.error;
            this.failureState = true;
          }
          else {
            this.failure = null;
            this.failureState = false;
          }
          this.success = false;
          this.selectedFile = null;
          this.inProgress = false;
          this.fileInput.nativeElement.value = '';
          return of(err);
        })
      )
      .subscribe(response => {
        this.success = true;
        this.failure = null;
        this.failureState = false;
        this.inProgress = false;
        this.selectedFile = null;
        this.fileInput.nativeElement.value = '';
      });
  }
  onDownload(): void{
    this.dokumentService.exportJson();
  }
  ngOnInit() {
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-documents-tools.' + key);
  }

}
