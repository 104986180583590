<id37-modal [(modalOpen)]="open">
  <id37-modal-title>{{__('title')}}</id37-modal-title>
  <id37-modal-body>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-xl-8 clr-col-lg-8 clr-col-md-9 clr-col-sm-10">

        <div class="id37-text2" style="margin-bottom: 0.75em;text-align: center">{{__('text')}}</div>

        <form [formGroup]="inviteFriendForm" [ngClass]="{'errors': checkErrors()}">

          <div class="input-container">
            <label for="firstName"></label>
            <input formControlName="firstName" id="firstName" placeholder="{{__('firstName')}}" type="text">
            <img *ngIf="!getFormControl('firstName').errors"
                 alt="green check mark" src="../../../assets/icons/checkmark_green.svg">
          </div>

          <div class="input-container">
            <label for="lastName"></label>
            <input formControlName="lastName" id="lastName" placeholder="{{__('lastName')}}" type="text">
            <img *ngIf="!getFormControl('lastName').errors"
                 alt="green check mark" src="../../../assets/icons/checkmark_green.svg">
          </div>

          <div class="input-container">
            <label for="email"></label>
            <input formControlName="email" id="email" placeholder="{{__('email')}}" type="text">
            <img *ngIf="!getFormControl('email').errors"
                 alt="green check mark" src="../../../assets/icons/checkmark_green.svg">
          </div>
          <div *ngIf="getFormControl('email').errors && getFormControl('email').value !==''"
               class="clr-row error-tooltip">
            {{__('notAValidEmail')}}
          </div>

          <ng-container *ngIf="!ongoingRequest; else loadingIndicator">
            <button (click)="inviteUserToPlatform()"
                    [disabled]="error"
                    class="id37-btn-primary id37-btn-full-width"
                    type="button">
              <span class="button-text">{{__('sendInvitation')}}</span>
            </button>
          </ng-container>

          <ng-template #loadingIndicator>
            <button [disabled]="true"
                    class="id37-btn-primary id37-btn-full-width"
                    type="button">
              <app-loading-indicator></app-loading-indicator>
              <span class="button-text">{{__('sendInvitation')}}</span>
            </button>
          </ng-template>

          <div class="approval">
            <id37-checkbox #approval [(checked)]="checked" (click)="onCheck()"></id37-checkbox>
            <input formControlName="termsCheckBox" type="checkbox"/>
            <label (click)="approval.flip(); onCheck()" class="id37-text4">{{__('terms.text')}}</label>
          </div>

        </form>

        <ng-container *ngIf="requestError">
          <div class="alert alert-warning">
            <span class="id37-text2">
              {{__('alreadyOnPlatform.A')}}
              <a (click)="goToFriends()">{{__('alreadyOnPlatform.B')}}</a>
              {{__('alreadyOnPlatform.C')}}
            </span>
          </div>
        </ng-container>

      </div>
    </div>
  </id37-modal-body>
</id37-modal>

<id37-modal [(modalOpen)]="success">
  <id37-modal-title></id37-modal-title>
  <id37-modal-body>
    <img alt="green checkmark" src="assets/icons/ID37_checkmark_160x160.svg">
    <div class="thanks">{{__('thanks')}}</div>
    <div class="thanks-text text-center">
      <span>{{__('thanks.text')}}</span> <br>
      <span class="email">{{this.inviteFriendForm.value.email}}</span>
    </div>
  </id37-modal-body>
</id37-modal>
