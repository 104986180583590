import {ChatMessage} from './chat-message';

export class ChatMessageRepresentation {

  public readonly id: number;
  public readonly userAKeycloakId: string;
  public readonly userBKeycloakId: string;
  public readonly message: string;
  public readonly sent: string;
  public readonly received: string | null;
  public readonly read: string | null;
  public readonly updated: string | null;

  constructor(id: number,
              userAKeycloakId: string,
              userBKeycloakId: string,
              message: string,
              sent: string,
              received: string,
              read: string,
              updated: string) {
    this.id = id;
    this.userAKeycloakId = userAKeycloakId;
    this.userBKeycloakId = userBKeycloakId;
    this.message = message;
    this.sent = sent;
    this.received = received;
    this.read = read;
    this.updated = updated;
  }

  static toModelEntity(chatMessageRepresentation: ChatMessageRepresentation): ChatMessage {
    return {
      id: chatMessageRepresentation.id,
      senderKeycloakId: chatMessageRepresentation.userAKeycloakId,
      receiverKeycloakId: chatMessageRepresentation.userBKeycloakId,
      message: chatMessageRepresentation.message,
      sent: new Date(chatMessageRepresentation.sent),
      received: !!chatMessageRepresentation.received ? new Date(chatMessageRepresentation.received) : undefined,
      read: !!chatMessageRepresentation.read ? new Date(chatMessageRepresentation.read) : undefined,
      updated: !!chatMessageRepresentation.updated ? new Date(chatMessageRepresentation.updated) : undefined
    };
  }

  static fromModelEntity(chatMessage: ChatMessage): ChatMessageRepresentation {
    return new ChatMessageRepresentation(
      chatMessage.id,
      chatMessage.senderKeycloakId,
      chatMessage.receiverKeycloakId,
      chatMessage.message,
      chatMessage.sent.toISOString(),
      !!chatMessage.received ? chatMessage.received.toISOString() : null,
      !!chatMessage.read ? chatMessage.read.toISOString() : null,
      !!chatMessage.updated ? chatMessage.updated.toISOString() : null
    );
  }

}
