import {NodeRepresentation} from '../../graph/node-representation';
import {BasicUserRepresentation} from '../../user/basic-user-representation';
import {EdgeRepresentation} from '../../graph/edge-representation';
import {FriendshipGraph} from './friendship-graph';
import {BasicUser} from '../../user/basic-user';

export class FriendshipGraphRepresentation {

  public readonly nodes: NodeRepresentation<BasicUserRepresentation | null>[];
  public readonly edges: EdgeRepresentation[];

  public static toModelEntity(friendshipGraphRepresentation: FriendshipGraphRepresentation): FriendshipGraph {
    return {
      nodes: friendshipGraphRepresentation.nodes
        .map((nodeRepresentation) =>
          NodeRepresentation.toModelEntity<BasicUserRepresentation | null, BasicUser | null>(
            nodeRepresentation,
            (basicUserRepresentation) =>
              !basicUserRepresentation ? null : BasicUserRepresentation.toModelEntity(basicUserRepresentation)
          )),
      edges: friendshipGraphRepresentation.edges
        .map(edgeRepresentation =>
          EdgeRepresentation.toModelEntity(edgeRepresentation))
    };
  }

}
