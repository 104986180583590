<h3 [innerHTML]="__('header')">
</h3>

<div [innerHTML]="__('info')" class="id37-text1">
</div>

<div class="dashboard-btn-container">

  <div class="dashboard-btn-wrapper">
    <button (click)="chooseProvider(provider.UNZER, method.PAYPAL)"
            class="dashboard-btn">
      <img alt="PayPal" class="icon-svg" id="paypal-icon" src="assets/icons/payment/paypal.svg"/>
      <span class="icon-text">{{__('paypal')}}</span>
    </button>
  </div>

  <div class="dashboard-btn-wrapper">
    <button (click)="chooseProvider(provider.UNZER, method.CREDIT_CARD)"
            class="dashboard-btn">
      <img alt="Mastercard" class="icon-svg" id="mastercard-icon" src="assets/icons/payment/mastercard.svg"/>
      <span class="icon-text">{{__('mastercard')}}</span>
    </button>
  </div>

  <div class="dashboard-btn-wrapper">
    <button (click)="chooseProvider(provider.UNZER, method.CREDIT_CARD)"
            class="dashboard-btn">
      <img alt="Visa" class="icon-svg" id="visa-icon" src="assets/icons/payment/visa.svg"/>
      <span class="icon-text">{{__('visa')}}</span>
    </button>
  </div>

  <div class="dashboard-btn-wrapper">
    <button (click)="chooseProvider(provider.UNZER, method.CREDIT_CARD)"
            class="dashboard-btn">
      <img alt="American Express" class="icon-svg" id="american-express-icon"
           src="assets/icons/payment/amex.svg"/>
      <span class="icon-text">{{__('amex')}}</span>
    </button>
  </div>

  <!--
  <button (click)="notSupported()"
          class="dashboard-btn">
    <img class="icon-svg" src="assets/icons/payment/sofort.svg" alt="Sofortüberweisung"
         style="width: 9em; height: 6em;"/>
    <span class="icon-text">{{__('sofort')}}</span>
  </button>

  <button (click)="notSupported()"
          class="dashboard-btn">
    <img class="icon-svg" src="assets/icons/payment/apple_pay.svg" alt="ApplePay"
         style="width: 9em; height: 6em;"/>
    <span class="icon-text">{{__('applepay')}}</span>
  </button>
  -->

</div>
