<ng-container *ngIf="!!_members">
  <div *ngIf="_members.length <= maxDisplayingBubbles; else tooMany" class="nav-bubbles-wrapper"
       [style.left]="computeWrapperPos()">
    <ng-container *ngFor="let member of displayingMembers let i = index; let lst = last;">
      <div class="white-border" [style.z-index]="9000-i" [style.left]="computeBubblePos(i)">
        <app-team-graphic-nav-bubble
            [hide]="hide"
            [colorIndex]="i"
            [amount]="displayingMembers.length"
            [name]="getNameInitial(member)"
            [teamUser]="member"></app-team-graphic-nav-bubble>
      </div>
    </ng-container>

  </div>


  <ng-template #tooMany>
    <div class="nav-bubbles-wrapper">
      <ng-container *ngFor="let member of displayingMembers let i = index; let lst = last;">
        <div class="white-border" [style.z-index]="9000-i" [style.right]="computeBubblePos(displayingMembers.length - i)">
          <app-team-graphic-nav-bubble
              [hide]="hide"
              [colorIndex]="i"
              [amount]="amountOfMembers()"
              [name]="getNameInitial(member)"
              [teamUser]="member"></app-team-graphic-nav-bubble>
        </div>
      </ng-container>

    <div id37Dropdown (clickOutside)="closeDropdown()">
      <div class="white-border" [style.z-index]="9000-maxDisplayingBubbles">
        <app-team-graphic-nav-bubble
            [hide]="hide"
            style="right: 40px"
            [name]="'+' + hiddenMembers.length"
            [colorIndex]="1"
            [amount]="amountOfMembers()"
            [teamUsers]="hiddenMembers"
            (openDropdown)="triggerDropdown()"></app-team-graphic-nav-bubble>
      </div>

      <div class="dropdown-box" [ngClass]="{'open-list': this.open}">
        <cdk-virtual-scroll-viewport itemSize="1"
                                     class="dropdown-viewport"
                                     [style.min-height.px]="calculateDropdownHeight()">
          <div class="dropdown-list">
            <ng-container *cdkVirtualFor="let h of hiddenMembers;let i=index; let lst=last">
              <div class="list-item">
                <app-team-graphic-nav-bubble
                    [hide]="hide"
                    [style]="calculateHiddenBubblePos(i)"
                    (toggledEye)="toggleEye()"
                    [colorIndex]="displayingMembers.length + i"
                    [amount]="amountOfMembers()"
                    [teamUser]="h"
                    [name]="getNameInitial(h)"></app-team-graphic-nav-bubble>
                <div class="name id37-text4">
                  <span>{{h.firstName}} {{h.lastName}}</span>
                </div>
              </div>
              <hr *ngIf="!lst">
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
    </div>
  </ng-template>

</ng-container>
