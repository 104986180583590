import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {ChatMessagesService} from '../state/chat-messages.service';
import {ChatMessageFunctions} from '../state/chat-message';
import {CurrentUser} from '../../user/current-user.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatInputComponent {

  @Input()
    activeContactUserId: string;

  sendWithEnter: boolean = true;
  message: string = '';
  inputFocused: boolean = false;

  constructor(private currentUser: CurrentUser,
              private chatMessagesService: ChatMessagesService,
              private translatorService: TranslatorService) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEventOverrideDefaults(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.inputFocused
      && this.sendWithEnter && !event.shiftKey) {
      // Prevent, that enter adds an additional newline before sending the new message.
      event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.inputFocused
      && this.sendWithEnter && !event.shiftKey
      && this.message.length !== 0) {
      this.sendMessage();
    }
  }

  sendMessage(): void {
    // Store message to send and clear input field.
    const messageToSend = this.message;
    this.message = '';

    // Create a message (with id -1) to be send...
    const newChatMessage = ChatMessageFunctions
      .createNew(this.activeContactUserId, this.currentUser.keycloakId, messageToSend);

    // ...and displayed in the messages view.
    this.chatMessagesService.storeChatMessage(this.activeContactUserId, newChatMessage);

    // new Date(Date.now())
    this.chatMessagesService.sendChatMessage(this.activeContactUserId, newChatMessage).subscribe(
      () => {
        // Always remove the temporary message from the store.
        // It was only put there to be seen by the user.
        this.chatMessagesService.removeChatMessageFromStore(this.activeContactUserId, newChatMessage.id);
      },
      () => {
        this.message = messageToSend;
        // Always remove the temporary message from the store.
        // It was only put there to be seen by the user.
        this.chatMessagesService.removeChatMessageFromStore(this.activeContactUserId, newChatMessage.id);
      }
    );
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
