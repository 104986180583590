// eslint-disable-next-line no-shadow
export enum RegistrationSource {

  REGISTRATION = 'REGISTRATION',
  INVITE = 'INVITE',
  IMPORT = 'IMPORT',
  RECOMMENDATION = 'RECOMMENDATION'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RegistrationSource {
  export const LIST: RegistrationSource[] = [
    RegistrationSource.REGISTRATION,
    RegistrationSource.INVITE,
    RegistrationSource.IMPORT,
    RegistrationSource.RECOMMENDATION
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): RegistrationSource => {
    name = !!name ? name : '';
    const found: RegistrationSource = RegistrationSource.LIST.find((registrationSource: string) =>
      registrationSource.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a registration source with an unknown name: "' + name + '"');
      return RegistrationSource.REGISTRATION;
    }
    return found;
  };

}
