import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-circular-ring-test',
  templateUrl: './circular-ring-test.component.html',
  styleUrls: ['./circular-ring-test.component.scss']
})
export class CircularRingTestComponent implements OnInit {

  progress: number = 0.75;
  currentRingProgress: number = 0.0;

  constructor() {
  }

  ngOnInit(): void {
  }

}
