import {Injectable} from '@angular/core';
import {CollapsibleItemComponent} from '../collapsible-item/collapsible-item.component';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ListToItemCommunicationService {

  private _items: CollapsibleItemComponent[] = [];

  // Observable sources
  private itemAddSource = new Subject<CollapsibleItemComponent>();
  private itemStateChangeSource = new Subject<CollapsibleItemComponent>();
  private levelPropagationSource = new Subject<boolean>();

  // Observable streams
  onItemAdd$: Observable<CollapsibleItemComponent> = this.itemAddSource.asObservable();
  onItemStateChange$: Observable<CollapsibleItemComponent> = this.itemStateChangeSource.asObservable();
  onLevelShouldBePropagated$: Observable<boolean> = this.levelPropagationSource.asObservable();

  // Service message commands
  addItem(item: CollapsibleItemComponent): void {
    this._items.push(item);
    this.itemAddSource.next(item);
  }

  itemStateChange(item: CollapsibleItemComponent): void {
    this.itemStateChangeSource.next(item);
  }

  propagateLevel(parentIsOdd: boolean): void {
    this.levelPropagationSource.next(parentIsOdd);
  }

  get items(): CollapsibleItemComponent[] {
    return this._items;
  }

}
