<div class="variation-selection hide-on-print" *ngIf="variationControlAllowed">
  <ng-container *ngFor="let option of variationControlOptions">
    <button class="id37-btn id37-btn-bw id37-btn-flat" [ngClass]="{'selected': variation === option.variation}"
            (click)="variation = option.variation">
      {{option.displayName}}
    </button>
  </ng-container>
</div>

<!-- GRAPHIC -->
<svg #svgwrapper width="100%" xmlns="http://www.w3.org/2000/svg" class="svg-wrapper">
  <svg app-key-graphic
       [results]="results"
       [variation]="variation"
       [selectedMotiveScore]="signpostData.motiveScore"
       (tooltip)="showSignpost($event)">
  </svg>
</svg>

<!-- SIGNPOST -->
<div class="signpost-flex-wrap key-graphic-signpost" #signpost
     [ngClass]="{
        'sp-right': signpostData.orientation === 'left',
        'sp-left': signpostData.orientation === 'right'
     }"
     [style.top.px]="signpostData.top"
     [style.left.px]="signpostData.left"
     [style.opacity]="signpostData.opacity"
     [style.visibility]="signpostData.visibility">
  <div class="signpost-content-body">
    <div class="title-custom">
      <div class="bubble">
              <span class="digit">
                {{ signpostData.score }}
              </span>
      </div>
      <h3> {{ signpostData.title }}</h3>
    </div>
    <div class="body-custom">
      {{ signpostData.text }}
    </div>
  </div>
</div>

<!-- CLOSE -->
<div id="close-sign" class="hide-on-print">
  <img (click)="close()" src="assets/icons/ID37_X.svg" alt="close sign">
</div>
