import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-distance-score-descriptions]',
  templateUrl: './distance-score-descriptions.component.html',
  styleUrls: ['./distance-score-descriptions.component.scss']
})
export class DistanceScoreDescriptionsComponent implements OnInit {

  @Input()
    normalizedMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    friendFirstname: string;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
  }

  __(key: string): string {
    return this.translatorService.translate('distanceScoreDescriptions.' + key);
  }
}
