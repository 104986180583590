import {Address} from './address';
import {Origin} from './origin';
import {JobAndEducation} from './job-and-education';
import {PersonalData} from './personal-data';
import {MasterInfo} from '../master/master-info';
import {CountryService} from '../user-data/country.service';
import {ChoicesOptions} from '../user-data/choices-options';
import {PartnerInfo} from '../partner/partner-info';
import {Choice} from '../select/state/choices-types';

export interface AccountData {

  masterInfo: MasterInfo | undefined;
  partnerInfo: PartnerInfo | undefined;
  personalData: PersonalData;
  jobAndEducation: JobAndEducation;
  origin: Origin;
  address: Address;

}

export class AccountDataFunctions {

  static fullName(accountData: AccountData): string {
    return (accountData.personalData.firstName + ' ' + accountData.personalData.lastName).trim();
  }

  // TODO: deprecated
  static humanReadable(accountData: AccountData, countryService: CountryService, language: string): AccountData {
    return {
      masterInfo: accountData.masterInfo,
      partnerInfo: accountData.partnerInfo,
      personalData: accountData.personalData,
      jobAndEducation: {
        industry: this.mapIndustry(accountData.jobAndEducation.industry, language) || '',
        company: accountData.jobAndEducation.company,
        profession: this.mapProfession(accountData.jobAndEducation.profession, language) || '',
        academicDegree: this.mapAcademicDegree(accountData.jobAndEducation.academicDegree, language) || '',
        academicTitle: accountData.jobAndEducation.academicTitle
      },
      origin: {
        origin: this.mapNationality(countryService, accountData.origin.origin) || '',
        dateOfBirth: accountData.origin.dateOfBirth
      },
      address: {
        residence: this.mapCountry(countryService, accountData.address.residence) || '',
        city: accountData.address.city
      }
    };
  }

  static mapCountry(countryService: CountryService, alpha2: string): string {
    return countryService.getNameByAlpha2(alpha2);
  }

  static mapNationality(countryService: CountryService, alpha2: string): string {
    return countryService.getNationalityByAlpha2(alpha2);
  }

  static mapAcademicDegree(academicDegree: string, language: string): string {
    return this.searchInChoices(ChoicesOptions.academicDegrees(language), academicDegree);
  }

  static mapIndustry(industry: string, language: string): string {
    return this.searchInChoices(ChoicesOptions.industries(language), industry);
  }

  static mapProfession(profession: string, language: string): string {
    return this.searchInChoices(ChoicesOptions.professions(language), profession);
  }

  static searchInChoices(choices: Choice[], searchValue: string): string {
    for (const choice of choices) {
      if (choice.value === searchValue) {
        return choice.label;
      }
    }
    return undefined;
  }

}
