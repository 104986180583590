import {TestResultDistance} from './test-result-distance';

export class TestResultDistanceRepresentation {

  public readonly testIdA: string;
  public readonly testIdB: string;
  public readonly linearDistance: number;
  public readonly skewedDistance: number;

  public static toModelEntity(testResultDistanceRepresentation: TestResultDistanceRepresentation): TestResultDistance {
    return {
      testIdA: testResultDistanceRepresentation.testIdA,
      testIdB: testResultDistanceRepresentation.testIdB,
      linearDistance: testResultDistanceRepresentation.linearDistance,
      skewedDistance: testResultDistanceRepresentation.skewedDistance
    };
  }

}
