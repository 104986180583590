<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">{{__('heading')}}</h1>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="from">{{__('from')}}</label>
          <app-datetime
            [formControl]="form.controls['from']"
            [formPropagationType]="'isoFormatted'"
            [formPropagationZone]="'localeZone'"
            [guideSelectionMode]="'normal'"
            [sendCloseRequestOnDaySelection]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placeholder]="__('from')"
            [type]="'datetime'"
            id="from">
          </app-datetime>
        </div>
      </div>

      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="to">{{__('to')}}</label>
          <app-datetime
            [formControl]="form.controls['to']"
            [formPropagationType]="'isoFormatted'"
            [formPropagationZone]="'localeZone'"
            [guideSelectionMode]="'normal'"
            [sendCloseRequestOnDaySelection]="true"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placeholder]="__('to')"
            [type]="'datetime'"
            id="to">
          </app-datetime>
        </div>
      </div>

      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="codePattern">{{__('codePattern')}}</label>
          <input [formControl]="form.controls['codePattern']"
                 id="codePattern"
                 placeholder="{{__('codePattern')}}"
                 type="text">
        </div>
      </div>

      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="categoryPattern">{{__('categoryPattern')}}</label>
          <input [formControl]="form.controls['categoryPattern']"
                 id="categoryPattern"
                 placeholder="{{__('categoryPattern')}}"
                 type="text">
        </div>
      </div>
    </div>

    <div class="alert info" style="flex-direction: column;">
      <div style="padding-left: 1.5em;
                  font-weight: 300;
                  line-height: 24px;">
        <b>Code Pattern</b> und <b>Kategorie Pattern</b> erwarten beide einen regulären Ausdruck. Siehe folgende Beispiele:
      </div>
      <div>
        <ul>
          <li>Code Pattern: <b>WW-.*</b> - Für alle Codes, die mit "WW-" beginnen</li>
          <li>Code Pattern: <b>.*TOM.*</b> - Für alle Codes, die "TOM" beinhalten</li>
          <li>Code Pattern: <b>.*FREE</b> - Für alle Codes, die mit "FREE" enden</li>
          <li>Kategorie Pattern: <b>ww</b> - Für alle Codes mit der Kategorie "ww"</li>
          <li>Kategorie Pattern: <b>^$</b> - Für alle Codes ohne / mit leerer Kategorie</li>
        </ul>
      </div>
    </div>

    <button (click)="download()"
            class="id37-btn-primary id37-btn-full-width"
            type="button"
            style="margin-top: 3em;">
      {{__('export')}}
    </button>
  </div>
</div>
