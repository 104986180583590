import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ProfilePictureStore} from './profile-picture-store';
import {CurrentUser} from '../user/current-user.service';
import {Observable} from 'rxjs';
import {ProfilePicture} from './profile-picture';
import {tap} from 'rxjs/operators';
import {ProfilePictureQuery} from './profile-picture-query';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureService {

  constructor(private profilePictureStore: ProfilePictureStore,
              private profilePictureQuery: ProfilePictureQuery,
              private currentUser: CurrentUser,
              private http: HttpClient) {
  }

  deleteProfilePictureForCurrentUser(): Observable<void> {
    return this.deleteProfilePicture(this.currentUser.keycloakId);
  }

  deleteProfilePicture(userId: string): Observable<void> {
    const backup: ProfilePicture = this.profilePictureQuery.getProfilePicture(userId);
    this.profilePictureStore.remove(userId);

    return this.http.delete<void>(
      API_URL + '/user/' + userId + '/profilepicture',
      {
        headers: new HttpHeaders({})
      }
    ).pipe(tap(
      () => {
      },
      () => {
        console.error('Unable to delete profile picture. Restoring backup.');
        this.profilePictureStore.upsert(userId, () => backup);
      }
    ));
  }

  updateProfilePictureForCurrentUser(base64Data: string | undefined | null): Observable<void> {
    return this.updateProfilePicture(this.currentUser.keycloakId, base64Data);
  }

  updateProfilePicture(userId: string, base64Data: string | undefined | null): Observable<void> {
    const backup: ProfilePicture = this.profilePictureQuery.getProfilePicture(userId);

    if (!base64Data) {
      console.error('Unable to update profile picture with a value of: ' + base64Data);
      console.error('Did you mean to delete the picture?');
      return;
    }
    const newState: ProfilePicture = {
      base64: base64Data
    };
    this.profilePictureStore.upsert(userId, () => newState);

    return this.http.put<void>(
      API_URL + '/user/' + userId + '/profilepicture',
      base64Data,
      {
        headers: new HttpHeaders({
          'Content-Type': 'text/plain; charset=UTF-8'
        })
      }
    ).pipe(tap(
      () => {
      },
      () => {
        console.error('Unable to update profile picture. Restoring backup.');
        this.profilePictureStore.upsert(userId, () => backup);
      }
    ));
  }

}
