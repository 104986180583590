import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {AccountService} from '../account.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AccountData} from '../account-data';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserDataValidation} from '../../user-data/user-data-validation';
import {TranslatorService} from '../../translation/translator.service';
import {UpdatableAccountData} from '../updatable-account-data';
import {ChoicesOptions} from '../../user-data/choices-options';
import {CountryService} from '../../user-data/country.service';
import {MasterService} from '../../master/master.service';
import {BillingAddress} from '../../master/billing-address';
import {Gender} from '../../client/gender';
import {AccountDataService} from '../account-data-service';
import {AccountDataQuery} from '../account-data-query';
import {Test} from '../../test/state/test';
import {TestQuery} from '../../test/state/test.query';
import {TestFunctions} from '../../test/state/test.functions';
import * as moment from 'moment';
import {Moment} from 'moment';
import {environment} from '../../../environments/environment';
import {Choice, ChoicesGroup} from '../../select/state/choices-types';
import {ChatbotUserStatus} from '../../user-data/chatbot-user-status';
import {UserService} from '../../user/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, AfterViewInit {

  @ViewChild('confirmationInput', {static: true}) confirmationInput: ElementRef;
  optInNetworkingActive: boolean = environment.OPT_IN_NETWORKING;


  isRemoveMasterModalVisible = false;
  isDeleteModalVisible = false;
  deleteConfirmationInput = '';
  tests: Test[] | undefined;
  accountData: AccountData;
  accountDataForm: FormGroup;
  formValid = false;
  isMaster = false;
  saveOngoing = false;
  dataToSave: UpdatableAccountData | undefined;
  criticalDataIsToBeChanged: boolean = false;
  showSaveSuccessfulMsg = false;
  showSaveUnsuccessfulMsg = false;
  minDate: Moment = moment('1888-01-01').hour(0).minute(0);
  maxDate: Moment = moment().hour(23).minute(59);
  countryChoices: ChoicesGroup[];
  nationalityChoices: ChoicesGroup[];
  genderChoices: Choice[];
  academicDegreeChoices: Choice[];
  industryChoices: Choice[];
  professionChoices: Choice[];
  chatbotUserStatus: ChatbotUserStatus;
  chatbotDeletionWarning: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(private translatorService: TranslatorService,
              private currentUser: CurrentUser,
              private masterService: MasterService,
              private accountService: AccountService,
              private accountDataQuery: AccountDataQuery,
              private accountDataService: AccountDataService,
              private countryService: CountryService,
              private testQuery: TestQuery,
              private testFunctions: TestFunctions,
              private formBuilder: FormBuilder,
              private userService: UserService) {
    this.countryChoices = [
      {
        label: 'Top countries',
        id: 1,
        choices: ChoicesOptions.topCountries(this.currentUser.locale)
      },
      {
        label: 'All countries',
        id: 2,
        choices: this.countryService.getCountriesAsChoicesOptions()
      }
    ];
    this.nationalityChoices = [
      {
        label: 'Top nationalities',
        id: 1,
        choices: ChoicesOptions.topNationalities(this.currentUser.locale)
      },
      {
        label: 'All nationalities',
        id: 2,
        choices: this.countryService.getNationalitiesAsChoicesOptions()
      }
    ];
    this.genderChoices = ChoicesOptions.genders(this.currentUser.locale);
    this.academicDegreeChoices = ChoicesOptions.academicDegrees(this.currentUser.locale);
    this.industryChoices = ChoicesOptions.industries(this.currentUser.locale);
    this.professionChoices = ChoicesOptions.professions(this.currentUser.locale);
  }

  ngOnInit() {
    this.accountDataForm = this.buildForm();
    this.isMaster = this.currentUser.hasRole('master');
    if (!this.accountDataQuery.hasAccountDataForCurrentUser()) {
      this.accountDataService.loadForCurrentUser().pipe(take(1)).subscribe();
    }
    this.loadTestData();
    this.getUserData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeAccountData();
      this.loadBillingAddress();
    }, 0);
  }

  getUserData(): void {
    this.userService.getChatbotUserStatus().subscribe({
      next: (chatbotUserStatus: ChatbotUserStatus) => {
        this.chatbotUserStatus = chatbotUserStatus;
      }
    });
  }
  canRemoveAssociatedMaster(): boolean {
    if (!this.accountData || !this.tests) {
      return false;
    }

    let allTestsCompleted = true;
    for (const test of this.tests) {
      if (!this.testFunctions.isCompleted(test)) {
        allTestsCompleted = false;
      }
    }

    return allTestsCompleted && !!this.accountData.masterInfo;
  }

  removeAssociatedMasterOnServer() {
    this.accountService.removeAssociatedMaster()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.closeRemoveMasterModal();
        location.reload();
      });
  }

  openRemoveMasterModal() {
    this.isRemoveMasterModalVisible = true;
  }

  closeRemoveMasterModal(): void {
    this.isRemoveMasterModalVisible = false;
  }

  deleteConfirmationInputMatches(): boolean {
    return this.deleteConfirmationInput === this.__('deleteMyAccountInputToConfirm');
  }

  deleteCurrentUserOnServer(): void {
    this.accountService.deleteCurrentUserAccount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.closeDeleteModal();
        location.reload();
      });
  }

  openDeleteModal(): void {
    this.isDeleteModalVisible = true;
    this.deleteConfirmationInput = '';
    this.showChatbotDeletionWarning();
    setTimeout(() => {
      this.confirmationInput.nativeElement.focus();
    }, 300);
  }

  showChatbotDeletionWarning(): void {
    switch (this.chatbotUserStatus) {
      case ChatbotUserStatus.chatbot_not_tried_yet:
      case ChatbotUserStatus.chatbot_test:
      case ChatbotUserStatus.chatbot_expired: {
        this.chatbotDeletionWarning = false;
        break;
      }
      case ChatbotUserStatus.chatbot_customer:
      case ChatbotUserStatus.chatbot_invited:
      case ChatbotUserStatus.chatbot_cancelled: {
        this.chatbotDeletionWarning = true;
        break;
      }
      default: {
        console.error('ChatbotUserStatus not implemented', this.chatbotUserStatus);
        break;
      }
    }
  }

  closeDeleteModal(): void {
    this.isDeleteModalVisible = false;
  }

  initializeAccountData(): void {
    this.accountDataQuery.selectAccountDataForCurrentUser()
      .subscribe((accountData: AccountData) => {
        if (!!accountData) {
          this.accountData = accountData;
          this.accountDataForm.controls.firstName.setValue(accountData.personalData.firstName);
          this.accountDataForm.controls.lastName.setValue(accountData.personalData.lastName);
          this.accountDataForm.controls.email.setValue(accountData.personalData.email);
          this.accountDataForm.controls.dateOfBirth.setValue(accountData.origin.dateOfBirth);
          this.accountDataForm.controls.gender.setValue(accountData.personalData.gender);
          this.accountDataForm.controls.origin.setValue(accountData.origin.origin);
          this.accountDataForm.controls.residence.setValue(accountData.address.residence);
          this.accountDataForm.controls.city.setValue(accountData.address.city);
          this.accountDataForm.controls.mobilePhone.setValue(accountData.personalData.mobilePhone);
          this.accountDataForm.controls.industry.setValue(accountData.jobAndEducation.industry);
          this.accountDataForm.controls.company.setValue(accountData.jobAndEducation.company);
          this.accountDataForm.controls.profession.setValue(accountData.jobAndEducation.profession);
          this.accountDataForm.controls.academicDegree.setValue(accountData.jobAndEducation.academicDegree);
          this.accountDataForm.controls.academicTitle.setValue(accountData.jobAndEducation.academicTitle);
        }
      });
  }

  loadBillingAddress(): void {
    if (this.isMaster) {
      this.masterService.loadBillingAddress()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((billingAddress: BillingAddress) => {
          this.accountDataForm.controls.billingAddress.setValue(billingAddress.address);
        });
    }
  }

  loadTestData(): void {
    this.testQuery.selectTestsForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tests: Test[]) => {
        this.tests = tests;
      });
  }

  buildForm(): FormGroup {
    return this.formBuilder.group({
      billingAddress: '',
      firstName: ['',
        Validators.compose([Validators.required, Validators.maxLength(UserDataValidation.NAME_MAX_LENGTH), Validators.email])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(UserDataValidation.NAME_MAX_LENGTH)])],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(UserDataValidation.EMAIL_MAX_LENGTH)])],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      origin: ['', Validators.required],
      residence: ['', Validators.required],
      city: '',
      mobilePhone: '',
      academicDegree: '',
      academicTitle: '',
      company: '',
      industry: '',
      profession: ''
    });
  }

  checkValid() {
    this.formValid = true;
  }

  saveChanges(): void {
    this.checkValid();
    if (!this.formValid) {
      console.error('The form is not valid. Update can not be send.');
      return;
    }
    this.saveBillingAddress();
    this.saveAccountData();
  }

  saveBillingAddress(): void {
    if (this.isMaster) {
      this.masterService.saveBillingAddress(new BillingAddress(
        this.accountDataForm.controls.billingAddress.value as string
      )).pipe(takeUntil(this.unsubscribe$))
        .subscribe();
    }
  }

  saveAccountData(withApproval: boolean = false): void {
    this.dataToSave = this.mapFormInputToUpdatableAccountData();
    const keycloakDataChanged = this.firstNameIsToBeChanged() || this.lastNameIsToBeChanged() || this.emailIsToBeChanged();

    if (keycloakDataChanged && !withApproval) {
      this.openApproveCriticalChangesModal();
      return;
    }

    this.saveOngoing = true;
    this.accountDataService.updateForCurrentUser(this.dataToSave)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.saveOngoing = false;
        this.showSaveSuccessfulMsg = true;
        setTimeout(() => {
          this.showSaveSuccessfulMsg = false;
        }, 3000);
        // Reload the page if the user changed his name.
        if (keycloakDataChanged) {
          setTimeout(() => {
            location.reload();
          }, 200);
        }
      }, () => {
        this.saveOngoing = false;
        this.showSaveUnsuccessfulMsg = true;
        setTimeout(() => {
          this.showSaveUnsuccessfulMsg = false;
        }, 3000);
      });
  }

  firstNameIsToBeChanged(): boolean {
    return this.accountData.personalData.firstName !== this.dataToSave.firstName;
  }

  lastNameIsToBeChanged(): boolean {
    return this.accountData.personalData.lastName !== this.dataToSave.lastName;
  }

  emailIsToBeChanged(): boolean {
    return this.accountData.personalData.email !== this.dataToSave.email;
  }

  openApproveCriticalChangesModal(): void {
    this.criticalDataIsToBeChanged = true;
  }

  closeApproveCriticalChangesModal(): void {
    this.criticalDataIsToBeChanged = false;
  }

  mapFormInputToUpdatableAccountData(): UpdatableAccountData {
    return new UpdatableAccountData(
      this.accountDataForm.controls.firstName.value as string || '',
      this.accountDataForm.controls.lastName.value as string || '',
      this.accountDataForm.controls.email.value as string || '',
      Gender.fromName(this.accountDataForm.controls.gender.value as string || ''),
      this.accountDataForm.controls.academicDegree.value as string || '',
      this.accountDataForm.controls.academicTitle.value as string || '',
      this.accountDataForm.controls.city.value as string || '',
      this.accountDataForm.controls.company.value as string || '',
      this.accountDataForm.controls.dateOfBirth.value as string || '',
      this.accountDataForm.controls.industry.value as string || '',
      this.accountDataForm.controls.mobilePhone.value as string || '',
      this.accountDataForm.controls.origin.value as string || '',
      this.accountDataForm.controls.profession.value as string || '',
      this.accountDataForm.controls.residence.value as string || ''
    );
  }

  __(key: string): string {
    return this.translatorService.translate('my-account.' + key);
  }

}
