import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClarityModule} from '@clr/angular';
import {ItemPageComponent} from './my-test/item-page/item-page.component';
import {ItemComponent} from './my-test/item/item.component';
import {MyTestComponent} from './my-test/my-test.component';
import {StartPageComponent} from './my-test/start-page/start-page.component';
import {ThankyouPageComponent} from './my-test/thankyou-page/thankyou-page.component';
import {ReportModule} from '../report/report.module';
import {RouterModule} from '@angular/router';
import {Id37Module} from '../id37/id37.module';
import {BrowserModule} from '@angular/platform-browser';
import {TestOriginBuyCastPipe} from './origin/test-origin-buy-cast.pipe';
import {TestOriginUnknownCastPipe} from './origin/test-origin-unknown-cast.pipe';
import {TestOriginImportCastPipe} from './origin/test-origin-import-cast.pipe';
import {TestOriginInvitationCastPipe} from './origin/test-origin-invitation-cast.pipe';
import {TestOverviewComponent} from './test-overview/test-overview.component';
import {TestInfoComponent} from './test-info/test-info.component';
import {CollapsibleModule} from '../collapsible/collapsible.module';
import {TestResetComponent} from './test-reset/test-reset.component';
import {SelectModule} from '../select/select.module';
import {DateTimeModule} from '../datetime/datetime.module';
import {KeyGraphicModule} from '../graphics/key-graphic/key-graphic.module';
import {DirectivesModule} from '../directives/directives.module';
import {TestShareComponent} from './test-share/test-share.component';
import {ModalModule} from '../modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    KeyGraphicModule,
    ReportModule,
    Id37Module,
    BrowserModule,
    CollapsibleModule,
    SelectModule,
    DateTimeModule,
    DirectivesModule,
    ModalModule
  ],
  declarations: [
    MyTestComponent,
    StartPageComponent,
    ThankyouPageComponent,
    ItemComponent,
    ItemPageComponent,
    TestOriginBuyCastPipe,
    TestOriginUnknownCastPipe,
    TestOriginImportCastPipe,
    TestOriginInvitationCastPipe,
    TestOverviewComponent,
    TestInfoComponent,
    TestResetComponent,
    TestShareComponent
  ],
  exports: [
    MyTestComponent,
    TestOriginBuyCastPipe,
    TestOriginUnknownCastPipe,
    TestOriginImportCastPipe,
    TestOriginInvitationCastPipe,
    TestOverviewComponent,
    TestShareComponent
  ]
})
export class TestModule {
}
