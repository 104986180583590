import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CircularRingComponent} from './circular-ring/circular-ring.component';
import {CircularRingTestComponent} from './circular-ring-test/circular-ring-test.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    CircularRingComponent,
    CircularRingTestComponent
  ],
  exports: [
    CircularRingComponent,
    CircularRingTestComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class CircularRingModule {
}
