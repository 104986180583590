import {TestInvitationRepresentation} from './test-invitation-representation';
import {TestVariation} from '../../test/state/test-variation';

export class TestInvitation {

  private readonly _userEmail: string;
  private readonly _testVariation: TestVariation | undefined;

  constructor(userEmail: string, testVariation: TestVariation | undefined) {
    this._userEmail = userEmail;
    this._testVariation = testVariation;
  }

  get userEmail(): string {
    return this._userEmail;
  }

  get testVariation(): TestVariation | undefined {
    return this._testVariation;
  }

  static buildFrom(testInvitationRepresentation: TestInvitationRepresentation): TestInvitation {
    return new TestInvitation(
      testInvitationRepresentation.userEmail,
      TestVariation.fromName(testInvitationRepresentation.testVariation)
    );
  }

}
