import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChatbotDocumentService} from '../../../chatbot/api-services/chatbot-document.service';
import {NewChatbotDocument} from '../../../chatbot/state/new-chatbot-document';
import {ChatbotDocumentsFormService} from '../chatbot-documents-form.service';
import {take} from 'rxjs/operators';
import {TranslatorService} from '../../../translation/translator.service';



@Component({
  selector: 'app-chatbot-document-form',
  templateUrl: './chatbot-documents-form.component.html',
  styleUrls: ['./chatbot-documents-form.component.scss']
})
export class ChatbotDocumentsFormComponent implements OnInit{
  @Input() edit: boolean;
  @Output() updateClickOnEdit = new EventEmitter<boolean>();
  @Output() refreshListonUpdate = new EventEmitter();

  constructor(
    private chatbotDocumentService: ChatbotDocumentService,
    private chatbotDocumentFormService: ChatbotDocumentsFormService,
    private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.edit = this.chatbotDocumentFormService.edit;
  }

  onSubmit(): void {
    if (this.chatbotDocumentFormService.form.valid){
      const newDocument: NewChatbotDocument = {
        title: this.chatbotDocumentFormService.form.value.title,
        summary: this.chatbotDocumentFormService.form.value.summary,
        content: this.chatbotDocumentFormService.form.value.content
      };
      if (this.chatbotDocumentFormService.edit === false) {
        this.chatbotDocumentService.addDocument(newDocument).pipe(take(1)).subscribe(
          () => this.refreshListonUpdate.emit()
        );
      }
      else {
        const document_id: number = this.chatbotDocumentFormService.form.value.document_id;
        this.chatbotDocumentService.updateDocument(document_id, newDocument).pipe(take(1)).subscribe(
          () => this.refreshListonUpdate.emit()
        );
      }
    }
    this.chatbotDocumentFormService.form.reset();
    this.onCancel();

  }


  onCancel() {
    this.chatbotDocumentFormService.form.reset();
    this.chatbotDocumentFormService.initializeFormGroup();
    this.updateClickOnEdit.emit(false);
  }

  getChatbotDocumentFormService(): ChatbotDocumentsFormService {
    return this.chatbotDocumentFormService;
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-documents-form.' + key);
  }
}
