import {PaymentProvider} from './payment-provider';
import {PaymentMethod} from './payment-method';

export class PaymentProviderAndMethod {

  public readonly provider: PaymentProvider;
  public readonly method: PaymentMethod;

  constructor(provider: PaymentProvider, method: PaymentMethod) {
    this.provider = provider;
    this.method = method;
  }

}
