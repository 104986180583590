<div id="top-anchor"></div>

<ng-container *ngIf="!accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 4em">
    <div class="clr-col">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="accessGranted">
  <app-progress-box (shouldSwitchToNextProgressItem)="goToNextStage()"
                    (shouldSwitchToPreviousProgressItem)="goToPreviousStage()"
                    (shouldSwitchToProgressItem)="processClickOnPageKnob($event)"
                    [availableProgressItems]="availableProgressItems"
                    [backButtonActive]="currentStageIndex !== 0"
                    [continueButtonActive]="currentStageIndex < lastStageIndex"
                    [currentProgressItemIndex]="currentStageIndex"
                    [displayProgressItems]="true"
                    [displayProgress]="true"
                    [fixed]="false"
                    [lastProgressItemIndex]="lastStageIndex"
                    [sliderDisplayType]="'progress'">
  </app-progress-box>

  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 60px; margin-bottom: 50px">
    <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">


      <ng-container *ngIf="stage === stages.ADDRESS_INPUT">
        <!-- GREETING -->
        <h3>
          {{__('greet')}} {{currentUser.fullName}}
        </h3>

        <div class="id37-text1">
          <!-- Vielen Dank, dass du dich für ID37 entschieden hast. -->
          {{__('text1')}}
        </div>

        <div class="id37-text1" style="margin-bottom: 2em;">
          <!-- Bitte beginne mit der Eingabe deiner Rechnungsadresse. -->
          {{__('text2')}}
        </div>

        <div *ngIf="!!ongoingOrder" class="alert info">
          <div class="id37-text1">
            <!-- Laufende Bestellung gefunden -->
            <h4 style="font-weight: bold; font-size: 1em;">{{__('ongoingText1')}}</h4>
            <div>
              <!--
            Du hast die Rechnungsadresse für diese Bestellung bereits eingegeben.
            Du kannst sie hier überprüfen und nach Belieben ändern. -->
              {{__('ongoingText2')}}
            </div>
          </div>
        </div>

        <app-address-form (addressOut)="handleAddressInput($event)"
                          (valid)="handleAddressValidState($event)"
                          [addressIn]="addressSupplier"
                          [addressOutTrigger]="addressReceiver"
                          [showTitle]="false"></app-address-form>
        <div class="button-row">
          <button (click)="addressReceiver.emit()"
                  class="id37-btn-outline">
            {{__('continue')}}
          </button>
        </div>
      </ng-container>

      <ng-container *ngIf="stage === stages.OVERVIEW">
        <app-order-overview
            (back)="goToPreviousStage()"
            (completeOrder)="completeOrder()"
            (continue)="goToNextStage(false)">
        </app-order-overview>
      </ng-container>

      <ng-container *ngIf="stage === stages.PAYMENT_METHOD_SELECTION">
        <app-payment-provider-and-method-chooser
            (paymentProviderAndMethod)="setPaymentProviderAndMethodToUse($event)">
        </app-payment-provider-and-method-chooser>
      </ng-container>

      <ng-container *ngIf="stage === stages.PURCHASE">
        <app-unzer-paypal-form
            (doCharge)="doPurchase($event)"
            *ngIf="!ongoingPayment && paymentProviderAndMethod.method === method.PAYPAL">
        </app-unzer-paypal-form>
        <app-unzer-credit-card-form
            (doCharge)="doPurchase($event)"
            *ngIf="!ongoingPayment && paymentProviderAndMethod.method === method.CREDIT_CARD">
        </app-unzer-credit-card-form>
        <app-ongoing-payment *ngIf="ongoingPayment">
        </app-ongoing-payment>
      </ng-container>

    </div>
  </div>
</ng-container>
