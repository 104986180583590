import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DiscountCode} from '../../order/discountcode/discount-code';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodeFormService {

  form: FormGroup = new FormGroup({
    code: new FormControl('', Validators.required),
    discountAsNumber: new FormControl('',
      [Validators.required,
        Validators.pattern('^[0]|[0][.][0-9]+|[1]$')]),
    active: new FormControl('', Validators.required),
    category: new FormControl(''),
    activeFrom: new FormControl(''),
    activeUntil: new FormControl(''),
    maximumUses: new FormControl('', Validators.pattern('^[0-9]*$')),
    description: new FormControl(''),
    affiliateMail: new FormControl('')
  });
  lastErrorState: boolean = true;
  error: boolean = true;

  constructor() {
  }

  private _edit: boolean = false;

  get edit(): boolean {
    return this._edit;
  }

  set edit(value: boolean) {
    this._edit = value;
  }

  populateForm(discountCode: DiscountCode) {
    this.form.setValue({
      code: discountCode.code,
      discountAsNumber: discountCode.discountAsNumber,
      active: discountCode.active.toString(),
      activeFrom: !!discountCode.activeFrom ? discountCode.activeFrom : '',
      activeUntil: !!discountCode.activeUntil ? discountCode.activeUntil : '',
      maximumUses: discountCode.maximumUses || '',
      category: discountCode.category || '',
      description: discountCode.description || '',
      affiliateMail: discountCode.affiliateMail || ''
    });
  }

  initializeFormGroup() {
    this.form.setValue({
      code: '',
      discountAsNumber: '',
      active: 'true',
      activeFrom: '',
      activeUntil: '',
      maximumUses: '',
      category: '',
      description: '',
      affiliateMail: ''
    });
  }

  getForm(formName: string) {
    return this.form.get(formName);
  }

  checkErrors(): boolean {
    const hasErrors = this.hasErrors();
    if (hasErrors !== this.lastErrorState) {
      this.lastErrorState = hasErrors;
      this.error = hasErrors; // this.valid.emit(!hasErrors);
    }
    return hasErrors;
  }

  private hasErrors(): boolean {
    return !!this.getForm('code').errors
      || !!this.getForm('discountAsNumber').errors
      || !!this.getForm('active').errors
      || !!this.getForm('category').errors;
  }
}
