import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {Id37Module} from '../../id37/id37.module';
import {EmailSettingManagementComponent} from './email-setting-management/email-setting-management.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Id37Module
  ],
  declarations: [
    EmailSettingManagementComponent
  ],
  exports: [
    EmailSettingManagementComponent
  ]
})
export class EmailSettingsModule {
}
