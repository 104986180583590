<div class="chat-sidebar" >

  <!--Header -->
  <div class="header-box" (click)="newChat()" [title]="__('newChatTooltip')">
    <button (click)="newChat()" class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-sm id37-btn-rounded-1 header-create-chat">
      <img alt="Create Chat Icon" src="assets/icons/ID37_Chatbot_Create_Chat.svg">
    </button>
    <div class="header-avatar">
      <img src="assets/icons/chatbot/JayIcon.svg" alt="Jay Avatar Icon">
      <span>{{__('new-chat-field')}}</span>
    </div>
    <button (click)="sidebarEnable()" class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-sm id37-btn-rounded-1">
      <img alt="Create Chat Icon" src="assets/icons/x.svg">
    </button>
  </div>
  <!-- Chatlist -->
<div *ngIf="groupedChats as groups" class="chat-selections">

  <ng-container *ngFor="let group of groups | keyvalue: keepOrder">

    <div *ngIf="isGroupNotEmpty(group)" class="chat-group">
      <div class="chat-group-name" >
        {{ __(group.key) }}
      </div>

      <div class="chat-list">
        <ng-container *ngFor="let chat of group.value">

          <app-chatbot-chat-selection [chatbotChat]="chat"  [selectedChat]="selectedChat" (chatSelected)="selectChat($event)" (chatDeleted)="deleteChatFromChatList($event)" >
          </app-chatbot-chat-selection>
        </ng-container>
      </div>

    </div>
  </ng-container>
</div>

  <!-- Footer with Buttons -->
  <div class="footer-layout">
    <div class="footer-row">
      <button class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-sm id37-btn-rounded-1 clear-all-button" (click)="openDeleteForm()">
        <span>{{__('delete-all-btn')}}</span>
        <img src="assets/icons/chatbot/TrashCanIcon.svg" alt="Clear conversations button"/>
      </button>

    </div>
  </div>
</div>
<!-- Modal Dialog -->
<id37-modal [(modalOpen)]="deleteFormOpen" [modalSize]="'small-mobile'">

  <id37-modal-title [align]="'left'" [showX]="false">
    <div class="title-wrapper">
      {{__('deleteTitle')}}
    </div>
  </id37-modal-title>
  <id37-modal-body>
      <span>
        {{__('deleteAllChats')}}
      </span>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row button-row">
      <button class="id37-btn id37-btn-secondary id37-btn-rounded-1 id37-btn-sm" (click)="closeDeleteForm()">
        <span>{{__('cancel')}}</span>
      </button>
      <button class="id37-btn id37-btn-primary id37-btn-rounded-1 id37-btn-sm" (click)="deleteAllChats()">
        <img class="fit-parent" src="assets/icons/chatbot/TrashCanIconWhite.svg" alt="Trashcan Icon"/>
        <span>{{__('delete')}}</span>
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
