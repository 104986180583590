import {EntityStore, StoreConfig} from '@datorama/akita';
import {AccountData} from './account-data';
import {Injectable} from '@angular/core';
import {Gender} from '../client/gender';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'accountData'})
export class AccountDataStore extends EntityStore<AccountData> {

  constructor() {
    super(AccountDataStore.createInitialState());
  }

  public static createInitialState(): AccountData {
    return {
      masterInfo: undefined,
      partnerInfo: undefined,
      personalData: {
        firstName: '',
        lastName: '',
        email: '',
        gender: Gender.UNSPECIFIED,
        mobilePhone: '',
      },
      jobAndEducation: {
        industry: '',
        company: '',
        profession: '',
        academicDegree: '',
        academicTitle: ''
      },
      origin: {
        origin: '',
        dateOfBirth: undefined
      },
      address: {
        residence: '',
        city: ''
      }
    };
  }
}
