export class UnzerPaymentResourceRepresentation {

  public readonly resourceId: string;

  constructor(resourceId: string) {
    this.resourceId = resourceId;
  }

}

