import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {MotiveScore} from '../../../../test/state/motive-score';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-header]',
  templateUrl: './key-graphic-header.component.html',
  styleUrls: ['./key-graphic-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicHeaderComponent {

  @Input()
    selectedMotiveScore: MotiveScore;

  getScoreOpacity(score: number) {
    return !this.selectedMotiveScore ? 1 : (score === this.selectedMotiveScore.score ? 1 : 0.25);
  }

}
