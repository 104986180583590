import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ItemSelectEvent} from './item-select-event';
import {TranslatorService} from '../../../translation/translator.service';
import {Item} from '../../state/item';
import {TestService} from '../../state/test.service';
import {ItemId} from '../../state/item-id';
import {Answer} from '../../state/answer-type';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit, OnDestroy {

  public static readonly DEFAULT_MSG_DURATION = 5000; // 5 seconds.

  private static readonly SET_THROUGH_CLICK = 0;
  private static readonly SET_THROUGH_NUMBER_KEY = 1;
  private static readonly SET_THROUGH_ARROW_KEY = 2;
  @Output()
    answerChangedThroughClick = new EventEmitter<ItemComponent>();
  @Output()
    answerChangedThroughNumberKey = new EventEmitter<ItemComponent>();
  @Output()
    answerChangedThroughArrowKey = new EventEmitter<ItemComponent>();
  @Output()
    answerSaved = new EventEmitter<ItemComponent>();
  @Output()
    answerCouldNotBeSaved = new EventEmitter<ItemComponent>();
  @Output()
    gotSelected = new EventEmitter<ItemSelectEvent>();
  private unsubscribe$ = new Subject<void>();
  @Input()
  private readonly _testId: string;
  @Input()
  private _answerable: boolean;
  @Input()
  private _initiallySelected = false; // false if not otherwise specified!
  private _selected: boolean;

  isAnswerable: boolean = true;

  constructor(private translatorService: TranslatorService,
              private testService: TestService) {
  }

  @Input()
  private _item: Item;

  get item(): Item {
    return this._item;
  }

  set item(item: Item) {
    this._item = item;
  }

  @Input()
  private _infoMsg: string | null = null;

  get infoMsg(): string | null {
    return this._infoMsg;
  }

  @Input()
  private _errorMsg: string | null = null;

  get errorMsg(): string | null {
    return this._errorMsg;
  }

  private _options: Array<number> = [0, 1, 2, 3, 4, 5];

  get options(): Array<number> {
    return this._options;
  }

  ngOnInit(): void {
    this._selected = this._initiallySelected;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    this.setAnswerFromNumberKey(event);
    this.setAnswerFromArrowKey(event);
  }

  setAnswer(answer: number, setThrough: number): void {
    // Abort if the _selected answer can not be valid. => Logical error.
    if (this.isAnswerable) {
      this.isAnswerable = false;
      if (!this._options.includes(answer)) {
        console.error(`The given answer was not a supported option. Invalid value given: ${answer}`);
        return;
      }

      // Store the new answer in this component. It was not jet posted to / saved on the server.
      this._item.answer = answer;
      this._item.answerSaved = false;

      // Do not post the chosen value to the server if this item is not answerable.
      if (!this._answerable) {
        console.log('This item is not answerable.');
        return;
      }

      switch (setThrough) {
        case ItemComponent.SET_THROUGH_CLICK:
          this.answerChangedThroughClick.emit(this);
          break;
        case ItemComponent.SET_THROUGH_NUMBER_KEY:
          this.answerChangedThroughNumberKey.emit(this);
          break;
        case ItemComponent.SET_THROUGH_ARROW_KEY:
          this.answerChangedThroughArrowKey.emit(this);
          break;
        default:
          console.error('Wrong setThrough constant specified!');
      }

      this.testService.saveAnswer(this._testId, new ItemId(this._item.id), new Answer(this._item.answer))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            // Track that the answer is now saved. Tell the parent that the answer of this item changed.
            this._item.answerSaved = true;
            this.answerSaved.emit(this);
            this.unsetErrorMessage();
            this.isAnswerable = true;
          },
          () => {
            console.error('Server was unable to save the selected answer!');
            // Deselect the chosen answer.
            this.unsetAnswer();
            this.answerCouldNotBeSaved.emit(this);
            this.showErrorMessage(this.__('errorAnswerCouldNotBeSaved'));
            this.isAnswerable = true;
          }
        );
    }
  }

  unsetAnswer(): void {
    this._item.answer = -1;
    this._item.answerSaved = false;
  }

  isAnswerSaved(): boolean {
    return this._item.answerSaved;
  }

  select(autoScrollDesired: boolean): void {
    this._selected = true;
    this.gotSelected.emit({itemComponent: this, autoScrollDesired});
  }

  deselect(): void {
    this._selected = false;
  }

  isSelected(): boolean {
    return this._selected;
  }

  setAnswerFromClick(value: number): void {
    this.setAnswer(value, ItemComponent.SET_THROUGH_CLICK);
  }

  setAnswerFromNumberKey(event: KeyboardEvent): void {
    if (this._selected) {
      const intVal = parseInt(event.key, 10);
      if (this._options.includes(intVal)) {
        this.setAnswer(intVal, ItemComponent.SET_THROUGH_NUMBER_KEY);
      }
    }
  }

  setAnswerFromArrowKey(event: KeyboardEvent): void {
    // This code allows the user to change his answer by shifting the selection dot to the left and right.
    if (this._selected /* && event.ctrlKey */) {
      if (event.key === 'ArrowLeft' && this._item.answer && this._item.answer !== this._options[0]) {
        this.setAnswer(
          this._options[this.findOptionIndexFromCurrentAnswer(this._options.length) - 1],
          ItemComponent.SET_THROUGH_ARROW_KEY
        );
      }
      if (event.key === 'ArrowRight' && this._item.answer !== this._options[this._options.length - 1]) {
        this.setAnswer(
          this._options[this.findOptionIndexFromCurrentAnswer(-1) + 1],
          ItemComponent.SET_THROUGH_ARROW_KEY
        );
      }
    }
  }

  findOptionIndexFromCurrentAnswer(defaultValue: number = -1): number {
    for (let i = 0; i < this._options.length; i++) {
      if (this._options[i] === this._item.answer) {
        return i;
      }
    }
    return defaultValue;
  }

  unsetInfoMessage(): void {
    this._infoMsg = null;
  }

  showInfoMessage(msg: string, duration?: number | undefined): void {
    this._infoMsg = msg;
    if (duration) {
      if (duration <= 0) {
        console.error('Duration must be greater than 0!');
      }
      setTimeout(() => {
        this._infoMsg = null;
      }, duration);
    }
  }

  unsetErrorMessage(): void {
    this._errorMsg = null;
  }

  showErrorMessage(msg: string, duration?: number | undefined): void {
    this._errorMsg = msg;
    if (duration) {
      if (duration <= 0) {
        console.error('Duration must be greater than 0!');
      }
      setTimeout(() => {
        this._errorMsg = null;
      }, duration);
    }
  }

  __(key: string): string {
    return this.translatorService.translate('my-test.item.' + key);
  }

}
