import {Component, HostBinding, Injector, Input, OnDestroy} from '@angular/core';
import {CollapsibleItemComponent} from '../collapsible-item/collapsible-item.component';
import {ListToItemCommunicationService} from '../services/list-to-item-communication.service';
import {LevelAdjustmentService} from '../services/level-adjustment.service';

@Component({
  selector: 'app-collapsible-list',
  templateUrl: './collapsible-list.component.html',
  styleUrls: ['./collapsible-list.component.scss'],
  providers: [ListToItemCommunicationService]
})
export class CollapsibleListComponent implements OnDestroy {

  public static readonly TYPE_EXPANDABLE = 'expandable';
  public static readonly TYPE_ACCORDION = 'accordion';
  public static readonly FALLBACK_TYPE = CollapsibleListComponent.TYPE_ACCORDION;

  @Input()
    type: 'expandable' | 'accordion' = CollapsibleListComponent.FALLBACK_TYPE;

  @HostBinding('class.odd-level')
    oddLevel = true;

  @HostBinding('class.even-level')
    evenLevel = false;

  constructor(private listToItemCom: ListToItemCommunicationService,
              // The injector is used, as the LevelAdjustmentService might not be present.
              private injector: Injector) {
    this.checkType();

    // If the parent component provides a LevelAdjustmentService instance, this list should listen to push events.
    try {
      injector.get(LevelAdjustmentService).onLevelShouldBeAdjusted$.subscribe(parentIsOdd => this.propagateLevel(parentIsOdd));
    }
    catch (exception) {
      // This list component does not have a parent which provides a LevelAdjustmentService instance. Nothing to do here..
    }

    // Whenever a new item is added to this list (through asynchronous operations for example), trigger a propagation cycle.
    this.listToItemCom.onItemAdd$.subscribe(() => this.propagateLevel(this.oddLevel));

    // If an item changes its state, we might want to collapse all its siblings if the item got opened and this is an accordion.
    this.listToItemCom.onItemStateChange$.subscribe((itemComponentWithChangedState: CollapsibleItemComponent) => {
      if (itemComponentWithChangedState.expanded && this.type === CollapsibleListComponent.TYPE_ACCORDION) {
        this.collapseAllSiblingsOf(itemComponentWithChangedState);
      }
    });
  }

  ngOnDestroy(): void {
    // this.onLevelShouldBeAdjusted$.uns
  }

  private checkType(): void {
    if (this.type !== CollapsibleListComponent.TYPE_EXPANDABLE && this.type !== CollapsibleListComponent.TYPE_ACCORDION) {
      console.error('Unknown type specified: "' + this.type + '".');
      console.log('Falling back to type: "' + CollapsibleListComponent.FALLBACK_TYPE + '".');
      this.type = CollapsibleListComponent.FALLBACK_TYPE;
    }
  }

  private collapseAllSiblingsOf(targetItem: CollapsibleItemComponent): void {
    this.listToItemCom
      .items
      .filter((item) => item !== targetItem && item.expanded)
      .forEach((item) => item.setExpanded(false, false /* important! */));
  }

  private propagateLevel(parentIsOdd: boolean): void {
    this.oddLevel = !parentIsOdd;
    this.evenLevel = parentIsOdd;
    this.listToItemCom.propagateLevel(this.oddLevel);
  }

}
