<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">{{__('heading')}}</h1>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="from">{{__('from')}}</label>
          <app-datetime
              [formControl]="form.controls['from']"
              [formPropagationType]="'isoFormatted'"
              [formPropagationZone]="'localeZone'"
              [guideSelectionMode]="'normal'"
              [sendCloseRequestOnDaySelection]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [placeholder]="__('from')"
              [type]="'datetime'"
              id="from">
          </app-datetime>
        </div>
      </div>

      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="to">{{__('to')}}</label>
          <app-datetime
              [formControl]="form.controls['to']"
              [formPropagationType]="'isoFormatted'"
              [formPropagationZone]="'localeZone'"
              [guideSelectionMode]="'normal'"
              [sendCloseRequestOnDaySelection]="true"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [placeholder]="__('to')"
              [type]="'datetime'"
              id="to">
          </app-datetime>
        </div>
      </div>
    </div>
    <button (click)="download()"
            class="id37-btn-primary id37-btn-full-width"
            type="button"
            style="margin-top: 3em;">
      {{__('export')}}
    </button>
  </div>



</div>

