<div class="clr-row clr-justify-content-center margin-0" style="margin-top: 3em; margin-bottom: 7em">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <h1>{{__('title')}}</h1>

    <p class="id37-text1">{{__('info1')}}</p>

    <p class="id37-text1">{{__('info2')}}</p>

    <ng-container *ngIf="tests$ | async as tests">

      <ng-container *ngIf="tests.length === 0">
        <div class="no-tests id37-text-2">
          {{__('noTests')}}
        </div>
      </ng-container>

      <ng-container *ngIf="tests.length !== 0">
        <ng-container *ngFor="let test of tests">
          <div class="test-box">
            <div class="id37-text2">{{__('testInfo.dateLabel')}} {{test.creationDate | JsDateTime}}</div>
            <p class="id37-text2">{{__('testInfo.idLabel')}} {{test.testId}}</p>

            <button (click)="openConfirmationModal(test.testId)" class="id37-btn-outline"
                    type="button">
              {{__('resetAction')}}
            </button>
            <span *ngIf="testResetRequestSucceeded[selectedTestId]" class="ok">
              {{__('success')}}
            </span>
            <span *ngIf="testResetRequestFailed[selectedTestId]" class="failure">
              {{__('failure')}}
            </span>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>

  </div>
</div>

<id37-modal [(modalOpen)]="confirmationModalOpen">
  <id37-modal-title>
    {{__('confirmationModal.title')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('confirmationModal.body')}}
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="resetTest(); closeConfirmationModal()" class="id37-btn id37-btn-danger"
              type="button">
        {{__('confirmationModal.confirm')}}
      </button>
      <button (click)="closeConfirmationModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('confirmationModal.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
