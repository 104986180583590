import {Component, HostBinding, Input} from '@angular/core';
import {ModalService} from '../modal.service';

@Component({
  selector: 'id37-modal-title',
  templateUrl: './modal-title.component.html',
  styleUrls: ['./modal-title.component.scss']
})
export class ModalTitleComponent {

  @Input()
    align: 'left' | 'center' | 'right' = 'center';

  @Input()
    showX: boolean = true;

  @Input()
    showHTML: string = undefined;

  @HostBinding('class.aligned')
    aligned = true;

  @HostBinding('class.align-column')
    alignColumn = false;

  constructor(public modalService: ModalService) {
  }

  @HostBinding('class.align-left')
  get alignLeft() {
    return this.align === 'left';
  }

  @HostBinding('class.align-center')
  get alignCenter() {
    return this.align === 'center';
  }

  @HostBinding('class.align-right')
  get alignRight() {
    return this.align === 'right';
  }

}
