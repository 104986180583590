import {Injectable} from '@angular/core';
import {Test} from './test';

@Injectable({
  providedIn: 'root'
})
export class TestFunctions {

  constructor() {
  }

  isCompleted(test: Test): boolean {
    return test.started && test.finished && test.accessGranted;
  }

  isVariationUpdatePossible(test: Test): boolean {
    return test.variationUpdateAllowed && !test.started;
  }

  expirationDateString(test: Test): string {
    return !!test.expirationDate ? test.expirationDate.toISOString() : '';
  }

  creationDateString(test: Test): string {
    return test.creationDate.toISOString();
  }

}
