import {TestInvitation} from './test-invitation';

export class TestInvitationRepresentation {

  public readonly userEmail: string;
  public readonly testVariation: string | null;

  constructor(userEmail: string, testVariation: string | null) {
    this.userEmail = userEmail;
    this.testVariation = testVariation;
  }

  static buildFrom(testInvitation: TestInvitation) {
    return new TestInvitationRepresentation(
      testInvitation.userEmail,
      !!testInvitation.testVariation ? testInvitation.testVariation.internalName : null
    );
  }

}
