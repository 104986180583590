<ng-container *ngIf="!!selectedTeam && !!teams">

  <!-- Dropdown list of teams -->
  <app-dropdown [listItems]="teams" [selectedItem]="selectedTeam"></app-dropdown>

  <div class="member-wrapper">
    <app-team-graphic-nav-bubbles
      [members]="selectedTeam.users"
      [layout]="layout">
    </app-team-graphic-nav-bubbles>

    <div class="edit-wrapper">
      <a (click)="onEdit()" [attr.aria-describedby]="__('tooltip.edit')">
        <img src="assets/icons/edit_pen_32x32.svg" alt="pen">
      </a>
    </div>
  </div>

</ng-container>
