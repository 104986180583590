<input
  #input
  (click)="toggleMenu()"
  (focusin)="inFocus = true"
  (focusout)="inFocus = false"
  (keydown)="onKeyDown($event)"
  [ngClass]="{'disabled': disabled}"
  [placeholder]="placeholder"
  [value]="selectedLocaleZoneFormatted"
  class="datetime-selected"
  tabindex="0"
  type="text">

<div class="datetime-dropdown-menu-ref">
  <div *ngIf="menuOpen" class="datetime-dropdown-menu">

    <ng-container *ngIf="type === 'date'">
      <app-datetime-date-selector
          (closeRequest)="closeMenu()"
          (selection)="onDateSelection($event)"
          [currentlySelected]="selected"
          [guideSelectionMode]="guideSelectionMode"
          [maxDate]="maxDate"
          [minDate]="minDate"
          [sendCloseRequestOnDaySelection]="sendCloseRequestOnDaySelection ? sendCloseRequestOnDaySelection : true"
      ></app-datetime-date-selector>
    </ng-container>

    <ng-container *ngIf="type === 'time'">
      <app-datetime-time-selector
          (closeRequest)="closeMenu()"
          (selection)="onDateSelection($event)"
          [currentlySelected]="selected"
      ></app-datetime-time-selector>
    </ng-container>

    <ng-container *ngIf="type === 'datetime'">
      <app-datetime-date-selector
          (closeRequest)="closeMenu()"
          (selection)="onDateSelection($event)"
          [currentlySelected]="selected"
          [guideSelectionMode]="guideSelectionMode"
          [maxDate]="maxDate"
          [minDate]="minDate"
          [sendCloseRequestOnDaySelection]="sendCloseRequestOnDaySelection ? sendCloseRequestOnDaySelection : false"
      ></app-datetime-date-selector>
      <app-datetime-time-selector
          (closeRequest)="closeMenu()"
          (selection)="onDateSelection($event)"
          [currentlySelected]="selected"
      ></app-datetime-time-selector>
    </ng-container>

  </div>
</div>
