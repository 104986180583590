import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ongoing-payment',
  templateUrl: './ongoing-payment.component.html',
  styleUrls: ['./ongoing-payment.component.scss']
})
export class OngoingPaymentComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
