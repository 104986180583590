import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfilePictureManagerComponent} from './profile-picture-manager/profile-picture-manager.component';
import {ProfilePictureEditorComponent} from './profile-picture-editor/profile-picture-editor.component';
import {ProfilePictureComponent} from './profile-picture/profile-picture.component';
import {AngularCropperjsModule} from 'angular-cropperjs';
import {AvatarDistanceCircleComponent} from './avatar-distance-circle/avatar-distance-circle.component';
import {CircularRingModule} from '../circular-ring/circular-ring.module';

@NgModule({
  declarations: [
    ProfilePictureManagerComponent,
    ProfilePictureEditorComponent,
    ProfilePictureComponent,
    AvatarDistanceCircleComponent
  ],
  exports: [
    ProfilePictureComponent,
    ProfilePictureManagerComponent,
    AvatarDistanceCircleComponent
  ],
  imports: [
    CommonModule,
    AngularCropperjsModule,
    CircularRingModule
  ]
})
export class ProfilePictureModule {
}
