import {MasterInfo} from './master-info';

export class MasterInfoRepresentation {

  public readonly userId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;

  constructor(userId: string, firstName: string, lastName: string, email: string) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  public static toModelEntity(masterInfoRepresentation: MasterInfoRepresentation): MasterInfo {
    return {
      userId: masterInfoRepresentation.userId,
      firstName: masterInfoRepresentation.firstName,
      lastName: masterInfoRepresentation.lastName,
      email: masterInfoRepresentation.email
    };
  }

}
