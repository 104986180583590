<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">{{__('billings')}}</h1>

    <label for="dateOf">{{__('from')}}</label>
    <app-datetime
        (selection)="onStartDateSelection($event)"
        [disabled]="dateFromDisabled"
        [maxDate]="maxFromDate"
        [minDate]="minFromDate"
        [placeholder]="'Ein Datum...'"
        [type]="'date'"
        id="dateOf"
        style="margin-bottom: 1em">
    </app-datetime>
    <label for="dateTo">{{__('to')}}</label>
    <app-datetime
        (selection)="onEndDateSelection($event)"
        [disabled]="dateToDisabled"
        [maxDate]="maxToDate"
        [minDate]="minToDate"
        [placeholder]="'Ein Datum...'"
        [type]="'date'"
        id="dateTo"
        style="margin-bottom: 1em">
    </app-datetime>

    <div *ngIf="!ongoingRequest" class="clr-row clr-justify-content-center compareaction">
      <div class="clr-col-12 action">
        <button (click)="downloadBillingDetails()" class="id37-btn-outline" type="button">
          <clr-icon shape="download"></clr-icon>
          {{__('download')}}
        </button>
      </div>
    </div>

    <div *ngIf="!ongoingRequest" class="clr-row clr-justify-content-center compareaction">
      <div class="clr-col-12 action">
        <button (click)="downloadPartnerBillingDetails()" class="id37-btn-outline" type="button">
          <clr-icon shape="download"></clr-icon>
          {{__('download-partner')}}
        </button>
      </div>
    </div>
    <div *ngIf="ongoingRequest">
      <app-loading-indicator></app-loading-indicator>
    </div>

  </div>
</div>
