import {Origin} from './origin';

export class OriginRepresentation {

  public readonly origin: string;
  public readonly dateOfBirth: string;

  constructor(origin: string, dateOfBirth: string) {
    this.origin = origin;
    this.dateOfBirth = dateOfBirth;
  }

  static toModelEntity(originRepresentation: OriginRepresentation): Origin {
    return {
      origin: originRepresentation.origin,
      dateOfBirth: originRepresentation.dateOfBirth
    };
  }

}
