<div class="master-info-box">

  <div *ngIf="!masterInfo" class="no-master-info">
    <div>{{__('noMasterInfoText')}}</div>
    <div>
      <a class="id37-link" href="{{findMasterUrl}}" target="_blank">
        {{__('findMasterLinkName')}}
      </a>
    </div>
  </div>

  <ng-container *ngIf="!!masterInfo">
    <div class="master-info">

      <div class="clr-row">
        <div class="clr-col-8 master-info-content">

          <div class="clr-row">
            <div class="clr-col-3 master-info-label">
              {{__('masterId')}}:
            </div>
            <div class="clr-col-9">
              {{masterInfo.userId}}
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col-3 master-info-label">
              {{__('masterName')}}:
            </div>
            <div class="clr-col-9">
              {{masterInfo.firstName + ' ' + masterInfo.lastName}}
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col-3 master-info-label">
              {{__('masterEmail')}}:
            </div>
            <div class="clr-col-9">
              {{masterInfo.email}}
            </div>
          </div>
        </div>
        <div class="clr-col-4">
          <app-profile-picture [userId]="masterInfo.userId"></app-profile-picture>
        </div>
      </div>

    </div>
  </ng-container>

</div>
