import {ChargeRepresentation} from './charge-representation';

export class Charge {

  private readonly _chargeId: string;
  private readonly _returnUrl: string | undefined;

  constructor(chargeId: string,
              returnUrl: string | undefined) {
    this._chargeId = chargeId;
    this._returnUrl = returnUrl;
  }

  get chargeId(): string {
    return this._chargeId;
  }

  get returnUrl(): string | undefined {
    return this._returnUrl;
  }

  static buildFrom(chargeRepresentation: ChargeRepresentation): Charge {
    return new Charge(
      chargeRepresentation.chargeId,
      chargeRepresentation.returnUrl === null ? undefined : chargeRepresentation.returnUrl
    );
  }

}
