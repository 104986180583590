<div class="superLike gray-box">
  <id37-avatar-distance-circle
      *ngIf="!!friendship"
      [profilePictureUserId]="friendId"
      [profilePictureAllowAccountDataRetrieval]="true"
      [profilePictureWidth]="'4em'"
      [profilePictureHeight]="'4em'"
      [circularRingRender]="true"
      [circularRingProgress]="distance"
      [circularRingCircleSizePx]="128"
      [circularRingCircleStrokeWidth]="9"
      style="font-size: 1.6em;">
  </id37-avatar-distance-circle>

  <div class="info">
    <h3 style="margin:0">{{__('wow')}}</h3>
    <div class="id37-text2">{{__('text')}}</div>
    <button class="id37-btn-primary id37-btn-flat" (click)="clickReaction()">
      <img class="orange-arrow" src="assets/icons/arrow_white_18x18.svg" alt="white arrow">
      <span>{{__('button')}}</span>
    </button>
  </div>
</div>

<!-- report and keygraphic links -->
<div class="id37-text2 text-light gray-box headline-space-bottom">
  <h4 style="margin-top: 0">{{__('report.headline')}}</h4>
  <div>
    {{__('report.text_1')}}
    <a (click)="routeToFriendReport()">{{__('report.link')}}</a>
    <span>{{__('report.text_2')}}</span>
    <a (click)="routeToFriendKeyGraphic()">{{__('report.graphic')}}</a>
    {{__('report.text_3')}}
  </div>
</div>

<!-- Tipp - Das kannst Du tun -->
<div class="id37-text2 text-light gray-box">
  <h4 style="margin-top: 0">
    <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
    {{__('differences.tip.headline')}}
  </h4>

  {{__('differences.tip1_1')}}
  <a (click)="routeToFriendKeyGraphic()">{{__('differences.tip1.keyGraphic')}}</a>
  <span [innerHTML]="__('differences.tip1_2')"></span>

  <span [innerHTML]="__('differences.tip2')"></span>
  <span *ngIf="!!masterInfo" [innerHTML]="masterInfo"></span>
  <span *ngIf="!masterInfo">
    {{__('differences.tip3_B1')}}
    <a (click)="this.clickMaster()">{{__('differences.tip3_B2')}}</a>
  </span>
  <span [innerHTML]="__('differences.tip3')"></span>
  <!-- Gut zu wissen -->
  <span [innerHTML]="__('differences.good')"></span>
  <!-- Haltung -->
  <span [innerHTML]="__('differences.attitude')"></span>
</div>


<id37-modal [(modalOpen)]="clickedReaction">
  <id37-modal-title></id37-modal-title>
  <id37-modal-body>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <h3 style="margin-top: 0">{{__('headline')}}</h3>

        <div>{{__('intro')}}</div>

        <div [formGroup]="form">

          <div class="radio-container">

            <label class="id37-radio-label id37-text3" for="APPRECIATE">
              <input id="APPRECIATE" type="radio" formControlName="subject" value="APPRECIATE">
              <span class="id37-radio-button"></span>
              {{__('APPRECIATE')}}
            </label>

            <label class="id37-radio-label id37-text3" for="INTERESTING">
              <input id="INTERESTING" type="radio" formControlName="subject" value="INTERESTING">
              <span class="id37-radio-button"></span>
              {{__('INTERESTING')}}
            </label>

            <label class="id37-radio-label id37-text3" for="POTENTIAL">
              <input id="POTENTIAL" type="radio" formControlName="subject" value="POTENTIAL">
              <span class="id37-radio-button"></span>
              {{__('POTENTIAL')}}
            </label>

            <label class="id37-radio-label id37-text3" for="TALK">
              <input id="TALK" type="radio" formControlName="subject" value="TALK">
              <span class="id37-radio-button"></span>
              {{__('TALK')}}
            </label>

          </div>

          <label for="textarea"></label>
          <textarea id="textarea" class="id37-text3" placeholder="{{__('placeholder')}}"
                    formControlName="message"></textarea>
        </div>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer>
    <button [disabled]="hasErrors()" class="id37-btn-primary send-mail" (click)="sendSuperLike()">
      <span>{{__('send.button')}}</span>
    </button>
  </id37-modal-footer>
</id37-modal>
