import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';
import {Id37Router} from '../../../../id37-router';
import {FriendshipQuery} from '../../../../friend/state/friendship.query';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Friendship} from '../../../../friend/state/friendship';
import {FriendshipFunctions} from '../../../../friend/state/friendship-functions';

@Component({
  selector: 'app-friendship-accepted-message',
  templateUrl: './friendship-accepted-message.component.html',
  styleUrls: ['./friendship-accepted-message.component.scss']
})
export class FriendshipAcceptedMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  private unsubscribe$: Subject<void> = new Subject<void>();

  friendName: string = '...';
  messagePartA: string = '';

  constructor(private friendshipQuery: FriendshipQuery,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private router: Id37Router) {
  }

  ngOnInit(): void {
    this.computeMessage();
    this.friendshipQuery.selectFriendship(this.notification.data.friendshipId as number)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((friendship: Friendship | undefined) => {
        if (!!friendship) {
          this.friendName = FriendshipFunctions.getFriendFullName(friendship, this.currentUser.keycloakId);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToFriend(): void {
    this.router.routeToFriends(this.notification.data.friendshipId as number);
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
