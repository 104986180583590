import {OrderAddress} from './order-address';

export class OrderAddressRepresentation {

  public readonly firstName: string;
  public readonly lastName: string;
  public readonly company: string;
  public readonly vatIdentification: string;
  public readonly country: string;
  public readonly state: string;
  public readonly zipCode: string;
  public readonly city: string;
  public readonly street: string;
  public readonly houseNr: string;

  constructor(firstName: string,
              lastName: string,
              company: string,
              vatIdentification: string,
              country: string,
              state: string,
              zipCode: string,
              city: string,
              street: string,
              houseNr: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company;
    this.vatIdentification = vatIdentification;
    this.country = country;
    this.state = state;
    this.zipCode = zipCode;
    this.city = city;
    this.street = street;
    this.houseNr = houseNr;
  }

  static buildFrom(orderAddress: OrderAddress): OrderAddressRepresentation {
    return new OrderAddressRepresentation(
      orderAddress.firstName,
      orderAddress.lastName,
      orderAddress.company,
      orderAddress.vatIdentification,
      orderAddress.country,
      orderAddress.state,
      orderAddress.zipCode,
      orderAddress.city,
      orderAddress.street,
      orderAddress.houseNr
    );
  }

}
