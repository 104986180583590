import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from './admin.service';
import {ClientView} from './types/client-view-type';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  ongoingRequest: boolean = false;
  pageNumber: number = 0;

  clients: ClientView[];
  selectedClient: ClientView = null;
  isDeleteModalVisible = false;

  private unsubscribe$ = new Subject<void>();
  private pageSize: number = 250;

  constructor(private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.loadClients();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }

  formatDate(s: string): string {
    const date = new Date(s);
    return (
      [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
      ].join('.') + ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes())
      ].join(':')
    );
  }


  deleteClientOnServer(client: ClientView): void {
    this.adminService.deleteCustomers([client])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.loadClients());
  }

  openDeleteModal(client: ClientView): void {
    this.selectedClient = client;
    this.isDeleteModalVisible = true;
  }

  closeDeleteModal(): void {
    this.selectedClient = null;
    this.isDeleteModalVisible = false;
  }

  getNextPageOfUsers(): void {
    this.pageNumber += 1;
    this.loadClients();
  }

  private loadClients(): void {
    this.ongoingRequest = true;
    this.adminService.loadClients(this.pageNumber, this.pageSize)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(clients => {
        this.clients = clients;
        this.ongoingRequest = false;
      });
  }


}
