import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgIf, SlicePipe} from '@angular/common';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-chatbot-chat-edit-chat-title',
  standalone: true,
  imports: [
    SlicePipe,
    NgIf,
    FormsModule
  ],
  templateUrl: './chatbot-chat-edit-chat-title.component.html',
  styleUrl: './chatbot-chat-edit-chat-title.component.scss'
})
export class ChatbotChatEditChatTitleComponent implements OnInit {
  @Input()
    chatbotTitle: string;
  @Input()
    isEditEnabled: boolean;
  @Output()
    newTitle = new EventEmitter<string>();

  title: string;

  ngOnInit() {
    this.title = this.chatbotTitle;
  }

  changeTitle() {
    this.newTitle.emit(this.title);
  }

  cancelChange() {
    this.newTitle.emit(this.chatbotTitle);
  }
}
