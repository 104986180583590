import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerResetService {

  private loadCustomers$: Subject<void> = new Subject<void>();

  constructor() {
  }

  public shouldReloadCustomers(): Observable<void> {
    return this.loadCustomers$.asObservable();
  }

  public reloadCustomers(): void {
    this.loadCustomers$.next();
  }

}
