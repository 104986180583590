import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BasicUser} from '../basic-user';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {

  @Input()
    additionalFilter: string;

  @Output()
    selected: EventEmitter<BasicUser> = new EventEmitter<BasicUser>();
  searching: boolean = false;
  searchResult: BasicUser[] | undefined = undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

  onUserChoosed(user: BasicUser): void {
    this.selected.emit(user);
  }
}
