<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <h2 id="title">
          {{__('title')}}
        </h2>
        <div id="file-download">
          <button class="id-37-btn id37-btn-outline id37-btn-rounded-2"
                  (click)="onDownload()">
            <img alt="pfeil" src="assets/icons/export_onButton.svg"/>
            <span>{{__('download-button')}}</span>
          </button>
        </div>
        <div class="paragraph">
          <h3>
            {{__('desc-headline')}}
          </h3>
          <div class="id37-text1 text-light">
            <p>
              {{__('desc')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="card">
          <div *ngIf="selectedFile" class="card-header">
            <clr-icon shape="file"></clr-icon>
            {{selectedFile.name}}
          </div>
          <div class="card-block">
            <input #fileInput (change)="onFileChanged($event)"
                   accept="application/json"
                   style="display: none" type="file">

            <button (click)="fileInput.click()" class="id37-btn-outline">
              {{__('btnSelectFile')}}
            </button>
            <button (click)="onUpload()" *ngIf="selectedFile"
                    class="id37-btn-outline">
              {{__('btnDoUpload')}}
            </button>
          </div>
          <div class="card-footer">
            <div class="card-text">
              <div *ngIf="inProgress" class="progress loop">
                <progress></progress>
              </div>

              <div *ngIf="success && failureState !== true" class="alert alert-success" role="alert">
                <div class="alert-items">
                  <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                      <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                    </div>
                    <span class="alert-text">{{__('success')}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="failureState">
                <div *ngFor="let error of failure.importErrors" class="alert alert-danger" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                      </div>
                      <span class="alert-text">{{__('errorInRow')}} {{error.rowNumber}}</span>
                    </div>
                    <div class="alert-item static">
                      <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                      </div>
                      <span class="alert-text">{{error.errorMessage}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
