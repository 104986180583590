import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CurrentUser} from '../../user/current-user.service';

@Component({
  selector: 'app-datetime-date-selector',
  templateUrl: './datetime-date-selector.component.html',
  styleUrls: ['./datetime-date-selector.component.scss']
})
export class DatetimeDateSelectorComponent implements OnInit {

  readonly moment = moment;
  readonly now: Moment = moment().locale(this.currentUser.locale);

  selected: Moment = this.now;
  showYearSelection: boolean = false;
  showMonthSelection: boolean = false;

  months: Month[];
  shortWeekDayNames: string[];

  years: Year[];
  weeks: Week[];

  startDate: Moment = moment(this.now).subtract(2, 'days');
  futureDate: Moment = moment().year(3000);
  @Input()
    guideSelectionMode: string = 'normal';
  @Input()
    minDate: Moment | undefined = undefined;
  @Input()
    maxDate: Moment | undefined = undefined;
  @Input()
    sendCloseRequestOnDaySelection: boolean = false;
  @Output()
    selection: EventEmitter<Moment> = new EventEmitter<Moment>();
  @Output()
    closeRequest: EventEmitter<void> = new EventEmitter<void>();

  constructor(private currentUser: CurrentUser) {
  }

  @Input()
  set currentlySelected(selected: Moment | undefined) {
    this.selected = !!selected ? selected : moment(this.now);
    if (typeof this.minDate !== typeof undefined && this.selected.isBefore(this.minDate)) {
      this.selected = moment(this.minDate).locale(this.currentUser.locale);
    }
    if (typeof this.maxDate !== typeof undefined && this.selected.isAfter(this.maxDate)) {
      this.selected = moment(this.maxDate).locale(this.currentUser.locale);
    }
    this.initDays();
  }

  ngOnInit(): void {
    if (this.guideSelectionMode === 'yearFirst') {
      this.initYearSelection();
      this.initWeekDayNames();
      this.initMonths();
      this.initDays();
    }
    else {
      this.initWeekDayNames();
      this.initMonths();
      this.initDays();
    }
  }

  initWeekDayNames(): void {
    this.shortWeekDayNames = [
      moment().locale(this.currentUser.locale).weekday(0).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(1).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(2).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(3).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(4).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(5).format('ddd'),
      moment().locale(this.currentUser.locale).weekday(6).format('ddd')
    ];
  }

  initMonths(): void {
    this.months = new Array(12);
    for (let i = 0; i < 12; i++) {
      const month: Moment = moment(this.selected).month(i);
      this.months[i] = {
        index: i,
        name: month.format('MMMM'),
        isNow: this.now.year() === month.year() && this.now.month() === month.month(),
        disabled: this.shouldMonthBeDisabled(month.month())
      };
    }
  }

  shouldMonthBeDisabled(month: number): boolean {
    return (
      typeof this.minDate !== typeof undefined
      && this.selected.year() <= this.minDate.year() && month < this.minDate.month()
    ) || (
      typeof this.maxDate !== typeof undefined
      && this.selected.year() >= this.maxDate.year() && month > this.maxDate.month()
    );
  }

  initDays(): void {
    // Calculate the index of the first day of the month (in current locale).
    const firstWeekdayIndex: number = moment(this.selected).startOf('month').weekday(); // in range [0..6]
    const numberOfDaysInMonth: number = this.selected.daysInMonth();
    const indexOfLastDayInMonth: number = firstWeekdayIndex + numberOfDaysInMonth;

    const prevMonth: Moment = moment(this.selected).subtract(1, 'month');
    const nextMonth: Moment = moment(this.selected).add(1, 'month');

    const daysInPreviousMonth: number = prevMonth.daysInMonth();

    const currentDayInPrevMonth: boolean = this.now.month() === prevMonth.month();
    const currentDayInThisMonth: boolean = this.now.month() === this.selected.month();
    const currentDayInNextMonth: boolean = this.now.month() === nextMonth.month();

    this.weeks = new Array(6);
    for (let w = 0; w < 6; w++) { // 6 weeks to display.
      const week: Week = {days: new Array(7)};
      for (let d = 0; d < 7; d++) { // 7 days each week.
        const i = d + w * 7;

        const day: Day = {
          index: -1,
          displayName: '',
          inPreviousMonth: false,
          inCurrentMonth: false,
          inNextMonth: false,
          disabled: false,
          highlighted: false,
          selected: false,
          isNow: false
        };
        if (i < firstWeekdayIndex) {
          const dayInPrevMonth = daysInPreviousMonth - firstWeekdayIndex + i + 1; // base 1 (!)
          day.index = dayInPrevMonth;
          day.displayName = String(dayInPrevMonth);
          day.inPreviousMonth = true;
          day.isNow = currentDayInPrevMonth
            ? this.now.year() === prevMonth.year() && this.now.date() === moment(prevMonth).date(dayInPrevMonth).date()
            : false;
          day.disabled = !moment(prevMonth).date(dayInPrevMonth).isBetween(
            this.minDate ? this.minDate : this.startDate,
            this.maxDate ? this.maxDate : this.futureDate);
        }
        else if (i >= firstWeekdayIndex && i < indexOfLastDayInMonth) {
          const dayInMonth = i - firstWeekdayIndex + 1; // base 1 (!)
          day.index = dayInMonth;
          day.displayName = String(dayInMonth);
          day.inCurrentMonth = true;
          day.selected = this.selected.date() === dayInMonth;
          day.isNow = currentDayInThisMonth
            ? this.now.year() === prevMonth.year() && this.now.date() === moment(prevMonth).date(dayInMonth).date()
            : false;
          day.disabled = !moment(this.selected).date(dayInMonth).isBetween(
            this.minDate ? this.minDate : this.startDate,
            this.maxDate ? this.maxDate : this.futureDate);
        }
        else {
          const dayInNextMonth = i - indexOfLastDayInMonth + 1; // base 1 (!)
          day.index = dayInNextMonth;
          day.displayName = String(dayInNextMonth);
          day.inNextMonth = true;
          day.isNow = currentDayInNextMonth
            ? this.now.year() === prevMonth.year() && this.now.date() === moment(prevMonth).date(dayInNextMonth).date()
            : false;
          day.disabled = !moment(nextMonth).date(dayInNextMonth).isBetween(
            this.minDate ? this.minDate : this.startDate,
            this.maxDate ? this.maxDate : this.futureDate);
        }

        week.days[d] = day;
      }
      this.weeks[w] = week;
    }
  }

  propagateDateSelection(): void {
    this.selection.emit(this.selected);
    if (this.sendCloseRequestOnDaySelection) {
      this.closeRequest.emit();
    }
  }

  selectDay(day: Day): void {
    if (day.disabled) {
      return;
    }
    this.selected.date(1); // Prevent overflow.
    if (day.inPreviousMonth) {
      this.selected.subtract(1, 'month');
    }
    else if (day.inNextMonth) {
      this.selected.add(1, 'month');
    }
    this.selected.date(day.index);
    this.propagateDateSelection();
    this.initDays();
  }

  selectMonth(month: Month): void {
    if (month.disabled) {
      return;
    }
    this.selected.month(month.index);
    this.destroyMonthSelection();
    if (this.guideSelectionMode === 'yearFirst') {
      this.initWeekDayNames();
      this.initMonths();
    }
    this.initDays();
  }

  selectNextMonth(save?: boolean): void {
    if (typeof save === typeof undefined) {
      save = true;
    }
    this.selected.add(1, 'month');
    this.initYears();
    this.initMonths();
    this.initDays();
    if (save && this.months[this.selected.month()].disabled) {
      this.selectPreviousMonth(false);
    }
  }

  selectPreviousMonth(save?: boolean): void {
    if (typeof save === typeof undefined) {
      save = true;
    }
    this.selected.subtract(1, 'month');
    this.initYears();
    this.initMonths();
    this.initDays();
    if (save && this.months[this.selected.month()].disabled) {
      this.selectNextMonth(false);
    }
  }

  initMonthSelection(): void {
    this.initMonths();
    this.showMonthSelection = true;
  }

  destroyMonthSelection(): void {
    this.showMonthSelection = false;
  }

  selectYear(year: Year): void {
    if (year.disabled) {
      return;
    }
    this.selected.year(year.number);
    this.initMonthSelection();
    this.destroyYearSelection();
  }

  selectNextYear(save?: boolean): void {
    if (typeof save === typeof undefined) {
      save = true;
    }
    this.selected.add(1, 'year');
    this.initYears(this.selected.year() - 4);
    if (save && this.years[4].disabled) {
      this.selectPreviousYear(false);
    }
    this.initMonthSelection();
  }

  selectPreviousYear(save?: boolean): void {
    if (typeof save === typeof undefined) {
      save = true;
    }
    this.selected.subtract(1, 'year');
    this.initYears(this.selected.year() - 4);
    if (save && this.years[4].disabled) {
      this.selectNextYear(false);
    }
    this.initMonthSelection();
  }

  initYearSelection(): void {
    this.destroyMonthSelection();
    this.initYears();
    this.showYearSelection = true;
  }

  initYears(startingYear?: number | undefined): void {
    const amount = 3 * 7; // 7 rows of 3 year numbers each.
    if (typeof startingYear === typeof undefined || startingYear === null) {
      startingYear = this.selected.year() - 4; // We want our current year to be in the middle of row 2.
    }
    this.years = new Array(amount);
    for (let i = 0; i < amount; i++) {
      const yearNumber: number = startingYear + i;
      this.years[i] = {
        number: yearNumber,
        isNow: yearNumber === this.now.year(),
        disabled: this.shouldYearBeDisabled(yearNumber)
      };
    }
  }

  shouldYearBeDisabled(year: number): boolean {
    return (typeof this.minDate !== typeof undefined && year < this.minDate.year())
      || (typeof this.maxDate !== typeof undefined && year > this.maxDate.year());
  }

  selectPreviousYears(): void {
    this.initYears(this.years[0].number - (3 * 7));
  }

  selectNextYears(): void {
    this.initYears(this.years[this.years.length - 1].number + 1);
  }

  destroyYearSelection(): void {
    this.years = undefined;
    this.showYearSelection = false;
  }

}

export interface Year {
  number: number;
  isNow: boolean;
  disabled: boolean;
}

export interface Month {
  index: number; // base 0 (!)
  name: string;
  isNow: boolean;
  disabled: boolean;
}

export interface Week {
  days: Day[];
}

export interface Day {
  index: number;
  displayName: string;
  inPreviousMonth: boolean;
  inCurrentMonth: boolean;
  inNextMonth: boolean;
  disabled: boolean;
  highlighted: boolean;
  selected: boolean;
  isNow: boolean;
}
