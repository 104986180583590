<div class="sidebar-mobile-toggle-wrapper">
  <div (click)="toggleMobileMenu()" class="sidebar-mobile-toggle">
    <div class="icon-wrapper">
      <div [ngClass]="{'up': mobileMenuOpen}" class="arrow-icon">
        <div class="bar1"></div>
        <div class="bar2"></div>
      </div>
    </div>
  </div>
</div>

<div (mouseleave)="overlapping = false"
     [ngClass]="{'collapsed': collapsed,
                 'overlapping': overlapping,
                 'mobileMenuOpen': mobileMenuOpen}"
     class="sidebar">

  <div id="collapse-toggle-wrapper">
    <div id="collapse-toggle-hover-wrapper">
      <div (click)="toggleCollapsedState()" [class.isMobile]="isMobile" id="collapse-toggle">
        <div id="collapse-toggle-content">
          <div id="bar1"></div>
          <div id="bar2"></div>
          <div id="bar3"></div>
        </div>
      </div>
    </div>
  </div>

  <div (mouseenter)="enableOverlapping()"
       class="navigation">
    <div class="content">
      <h2>{{__('content')}}</h2>

      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('client-info-anchor');
                    closeMobileMenu()"
           *rolesAllowed="'master'">
          {{__('nav.clientInfo')}}
        </a>
      </div>

      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('intro-anchor');
                    closeMobileMenu()">
          {{__('nav.intro')}}
        </a>
      </div>

      <!-- MOTIVES -->
      <div class="motive-group">
        <div *ngFor="let motive of (motives$ | async)" class="nav-element">
          <a (click)="scrollService.scrollToElement('motive-' + motive.motiveName);
                    motive.collapsibleItem.setExpanded(true);
                    closeMobileMenu()">
            <span class="motive-name">{{___('motive.' + motive.motiveName)}}</span>
          </a>
        </div>
      </div>


      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('find-master'); closeMobileMenu()"
           *hasMaster>
          {{__('nav.findMaster')}}
        </a>
      </div>

      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('book-anchor');
                    closeMobileMenu()">
          {{__('nav.book')}}
        </a>
      </div>

      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('contact-anchor');
                    closeMobileMenu()">
          {{__('nav.contact')}}
        </a>
      </div>

      <div class="nav-element">
        <a (click)="scrollService.scrollToElement('print-anchor');
                    closeMobileMenu()">
          {{__('nav.print')}}
        </a>
      </div>
    </div>
  </div>
</div>
