import {Component, Input} from '@angular/core';
import {ProfileText} from '../types/profile-text-type';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  @Input()
    profileText: ProfileText;

  constructor() {
  }

}
