import {Component, OnInit} from '@angular/core';
import {ChatMessagesStore} from '../state/chat-messages.store';
import {ChatMessagesQuery} from '../state/chat-messages.query';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FriendshipQuery} from '../../friend/state/friendship.query';
import {Friend} from '../../friend/state/friend';
import {CurrentUser} from '../../user/current-user.service';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';
import {Id37Router} from '../../id37-router';
import {TranslatorService} from '../../translation/translator.service';
import {ChatMessage} from '../state/chat-message';

@Component({
  selector: 'app-active-contact-info',
  templateUrl: './active-contact-info.component.html',
  styleUrls: ['./active-contact-info.component.scss']
})
export class ActiveContactInfoComponent implements OnInit {

  activeContact$: Observable<{ friend: Friend; lastMessage: ChatMessage | undefined } | undefined>;

  constructor(private chatMessagesQuery: ChatMessagesQuery,
              private chatMessagesStore: ChatMessagesStore,
              private friendshipQuery: FriendshipQuery,
              private currentUser: CurrentUser,
              public id37router: Id37Router,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.activeContact$ = this.chatMessagesQuery.selectActiveContactMessages().pipe(
      map((data) => !!data
        ? {
          friend: FriendshipFunctions.getFriend(data.friendship, this.currentUser.keycloakId),
          lastMessage: data.chatMessages.length > 0 ? data.chatMessages[data.chatMessages.length - 1] : undefined
        }
        : undefined
      )
    );
  }

  deselect(): void {
    this.chatMessagesStore.unsetActiveContact();
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
