<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">

    <div class="clr-row clr-justify-content-center" style="margin-top: 60px; margin-bottom: 50px;">
      <div class="clr-col-12">
        <h2>
          {{__('heading')}} ({{customers.length}})
        </h2>
        <button (click)="toggleAddCustomers()"
                *ngIf="!newCustomers"
                class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 add-customer-button">
          <img src="assets/icons/add_onButton.svg"/>
          <span>{{__('addClients')}}</span>
        </button>
        <button (click)="toggleAddCustomers()"
                *ngIf="newCustomers"
                class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 add-customer-button">
          <img src="assets/icons/close_onButton.svg"/>
          <span>{{__('close')}}</span>
        </button>
      </div>
    </div>

    <app-new-customers (sent)="onSent($event)"
                       [groups$]="groups$"
                       [isOpen]="newCustomers"></app-new-customers>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">

        <div class="clr-row">
          <h3 class="clr-col-8 existing-customers-title">
            {{__('existingClients')}} ({{customers.length}})
          </h3>
          <div class="clr-col-4">
            <div *ngIf="customersView" class="search-input-container">
              <input (ngModelChange)="doMetaSearch()"
                     [(ngModel)]="metaSearch" placeholder="{{__('searchPlaceholder')}}"
                     title="Search" type="text">
              <img src="assets/icons/arrow_link_12x12.svg"/>
            </div>
          </div>
        </div>

        <div *ngIf="!customersView && !newCustomers" class="no-customers-initial">
          <div class="svg">
            <img src="assets/icons/testgraphic_120x120.png"/>
          </div>
          <div class="text text-important">
            {{__('hello')}} {{name}}.<br>
            {{__('noCustomersYetStartNow')}}<br>
          </div>
          <button (click)="toggleAddCustomers()" *ngIf="!newCustomers"
                  class="id37-btn-outline id37-btn-no-customers-initial">
            <span>{{__('addClientsNow')}}</span>
          </button>
        </div>
        <clr-datagrid *ngIf="customersView || newCustomers">
          <clr-dg-column [(clrDgSortOrder)]="nameSortOrder"
                         [(clrFilterValue)]="customerNameFilter"
                         [clrDgField]="'_lastName'"
                         [clrDgSortBy]="'_lastName'" class="customer-col">
            <div class="cell-label">{{__('name')}}</div>
            <clr-dg-string-filter [clrDgStringFilter]="customerNameFilter">
            </clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column [(clrDgSortOrder)]="groupSortOrder"
                         [(clrFilterValue)]="customerGroupFilter"
                         [clrDgField]="'_groupName'"
                         [clrDgSortBy]="'_groupName'" class="customer-col">
            <div class="cell-label">{{__('group')}}</div>
            <clr-dg-string-filter [clrDgStringFilter]="customerGroupFilter">
            </clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column [(clrDgSortOrder)]="dateSortOrder"
                         [clrDgSortBy]="'_creationDate'"
                         class="customer-col">
            <div class="cell-label">{{__('date')}}</div>
          </clr-dg-column>
          <clr-dg-column [(clrDgSortOrder)]="variationSortOrder"
                         [(clrFilterValue)]="customerVariationFilter"
                         [clrDgField]="'_tests'"
                         [clrDgSortBy]="variationComparator" class="customer-col">
            <div class="cell-label">{{__('variation')}}</div>
            <clr-dg-string-filter [clrDgStringFilter]="customerVariationFilter">
            </clr-dg-string-filter>
          </clr-dg-column>
          <clr-dg-column [(clrDgSortOrder)]="actionSortOrder"
                         [clrDgSortBy]="actionComparator"
                         class="customer-col">
            <div class="cell-label">{{__('action')}}</div>
          </clr-dg-column>
          <clr-dg-column class="customer-col"></clr-dg-column>
          <clr-dg-placeholder *ngIf="!customersView" class="no-customers-yet">
            <table>
              <tr>
                <td><img src="../../../assets/icons/smiley_neutral_42x42.svg"/></td>
                <td><span>{{__('noCustomersYet')}}</span></td>
              </tr>
            </table>
          </clr-dg-placeholder>
          <clr-dg-row *clrDgItems="let customer of customersView; trackBy: trackById" [clrDgItem]="customer">
            <clr-dg-cell class="cell-field username-col-width">
              <div class="double-align">
                <ng-container *rolesAllowed="'master'">
                  <div *ngIf="!customer.hasFinishedTests()" class="username">
                    {{customer.firstName}} {{customer.lastName}}
                  </div>
                  <div *ngIf="customer.hasFinishedTests()" class="username">
                    <a (click)="openReport(customer.keycloakId, customer.getIdOfFirstFinishedTest())">
                      {{customer.firstName}} {{customer.lastName}}
                    </a>
                  </div>
                </ng-container>
                <ng-container *rolesAllowed="'partner'">
                  <div class="username">
                    {{customer.firstName}} {{customer.lastName}}
                  </div>
                </ng-container>
                <div class="email">{{customer.email}}</div>
              </div>
            </clr-dg-cell>
            <clr-dg-cell class="cell-field group-col-width">
              <div class="align group">
                <svg (click)="openGroupChangeModal(customer)" class="edit-pencil" fill="#ff5711"
                     style="enable-background:new 0 0 24 24;"
                     version="1.1" viewBox="0 0 24 24" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" y="0px"><path d="M21.528,3.847l-1.376-1.376c-1.137-1.138-2.99-1.138-4.128,0L3.642,14.854c-0.078,0.078-0.135,0.174-0.165,0.28  l-1.834,6.421c-0.065,0.227-0.001,0.47,0.165,0.637c0.124,0.124,0.289,0.19,0.459,0.19c0.06,0,0.12-0.008,0.178-0.025l6.421-1.835  c0.106-0.03,0.203-0.087,0.281-0.165L21.528,7.975c0.552-0.551,0.855-1.284,0.855-2.064C22.383,5.131,22.08,4.398,21.528,3.847z   M8.347,19.322L3.21,20.789l1.468-5.136l9.971-9.971l3.669,3.669L8.347,19.322z M20.611,7.058l-1.376,1.376l-3.669-3.669  l1.376-1.376c0.632-0.632,1.661-0.631,2.294,0l1.376,1.376c0.307,0.306,0.475,0.714,0.475,1.147  C21.086,6.344,20.917,6.751,20.611,7.058z"></path></svg>
                {{customer.groupName}}
              </div>
            </clr-dg-cell>
            <clr-dg-cell class="cell-field date-col-width">
              <div class="align date">{{customer.creationDate | date}}</div>
            </clr-dg-cell>
            <clr-dg-cell class="cell-field variation-col-width">
              <div class="align variation">
                {{!!customer.getVariationOfFirstTest() ? ___(customer.getVariationOfFirstTest()) : ''}}
              </div>
            </clr-dg-cell>
            <clr-dg-cell class="cell-field action-col-width">
              <div class="action">
                <ng-container *rolesAllowed="['master', 'partner']">
                  <button #actionElement
                          (click)="performActionByClick(
                                                       $event,
                                                       computeAction(customer), customer)"
                          *ngIf="!performingAction
                                                && !loadingCustomers
                                                && computeAction(customer) != 'done'
                                                && computeAction(customer) != 'in progress'"
                          class="id37-btn id37-btn-primary id37-btn-transparent">
                                    <span *ngIf="computeAction(customer)">
                                        {{__('action.' + computeAction(customer).trim().replace(" ", ""))}}
                                    </span>
                    <span *ngIf="!computeAction(customer)"></span>
                  </button>
                </ng-container>
                <button *ngIf="performingAction
                                                || loadingCustomers
                                                || computeAction(customer) === 'done'
                                                || computeAction(customer) === 'in progress'"
                        class="id37-btn id37-btn-primary id37-btn-transparent"
                        disabled>
                                    <span *ngIf="computeAction(customer)">
                                        {{__('action.' + computeAction(customer).trim().replace(" ", ""))}}
                                    </span>
                  <span *ngIf="!computeAction(customer)"></span>
                </button>
              </div>
            </clr-dg-cell>

            <clr-dg-cell class="cell-field delete-col-width">
              <ng-container *rolesAllowed="['master']">
                <a (click)="openDeleteModal(customer)" *ngIf="!customer.hasStartedTests()">
                  <clr-icon class="align" shape="trash"></clr-icon>
                </a>
              </ng-container>
            </clr-dg-cell>

          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination #pagination [(clrDgPage)]="page" [clrDgPageSize]="pageSize">
              <table class="footer">
                <tr>
                  <td>
                    <span class="footer-text">
                      {{__('page')}} {{pagination.currentPage}} {{__('of')}} {{pagination.lastPage}}
                    </span>
                  </td>
                  <td>
                    <button (click)="previousPage()"
                            class="id37-btn-outline pagination-btn">
                      <img src="assets/icons/arrow_pager_left_20x20.svg"/>
                    </button>
                  </td>
                  <td style="padding-left: 8px;">
                    <button (click)="nextPage()" class="id37-btn-outline pagination-btn">
                      <img src="assets/icons/arrow_pager_right_20x20.svg"/>
                    </button>
                  </td>
                </tr>
              </table>
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</div>

<id37-modal [(modalOpen)]="isDeleteModalVisible" [modalSize]="'sm'">
  <id37-modal-title>
    {{__('deleteClient.areYouSure')}}
  </id37-modal-title>
  <id37-modal-body [align]="'left'">
    {{__('deleteClient.explanation')}}
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="delete(customerToDelete); closeDeleteModal()" class="id37-btn id37-btn-danger"
              type="button">
        {{__('deleteClient.accept')}}
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('deleteClient.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<id37-modal [(modalOpen)]="isGroupChangeModalVisible">
  <id37-modal-title>
    {{__('changeGroupModal.title')}}
  </id37-modal-title>
  <id37-modal-body>
    <div>
      {{__('changeGroupModal.description')}}
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="new-client-group">{{__('changeGroupModal.label')}}</label>
          <input [formControl]="newGroup" id="new-client-group" type="text">
        </div>
      </div>
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="new-client-group-for-all">{{__('changeGroupModal.labelall')}}</label>
          <app-toggle-switch (valueChange)="changeGroupNameForAll=$event"
                             [value]="changeGroupNameForAll"
                             id="new-client-group-for-all">
          </app-toggle-switch>
        </div>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="changeGroupOfCustomer(customerToChangeGroup); closeGroupChangeModal()"
              class="id37-btn-outline"
              type="button">
        {{__('changeGroupModal.button.change')}}
      </button>
      <button (click)="closeGroupChangeModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('changeGroupModal.button.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<!-- Dummy to add some whitespace -->
<div style="height: 100px"></div>
<div [hidden]="!dropdownVisible"
     [style.left.px]="dropdownPosition.left"
     [style.top.px]="dropdownPosition.top"
     class="variation-dropdown">
</div>

<id37-modal [(modalOpen)]="openVariationModal">
  <id37-modal-title>{{__('changeProduct')}}</id37-modal-title>
  <id37-modal-body>
    <div class="clr-row clr-justify-content-center">
      <form [formGroup]="form">
        <div class="radio-buttons-container">
          <ng-container>
            <label class="id37-radio-label id37-text3" for="16">
              <input (change)="changeVariation($event)" [checked]="variationChecked=='test.variation.16'"
                     formControlName="testVariation" id="16"
                     name="testVariation"
                     type="radio" value="ID37/16">
              <span class="id37-radio-button"></span>
              <span>ID37/16</span>
            </label>

            <label class="id37-radio-label id37-text3" for="15">
              <input (change)="changeVariation($event)" [checked]="variationChecked=='test.variation.15'"
                     formControlName="testVariation" id="15"
                     name="testVariation"
                     type="radio" value="ID37/15">
              <span class="id37-radio-button"></span>
              <span>ID37/15</span>
            </label>

            <label class="id37-radio-label id37-text3" for="14">
              <input (change)="changeVariation($event)" [checked]="variationChecked=='test.variation.14'"
                     formControlName="testVariation" id="14"
                     name="testVariation"
                     type="radio" value="ID37/14">
              <span class="id37-radio-button"></span>
              <span>ID37/14</span>
            </label>

            <label class="id37-radio-label id37-text3" for="13">
              <input (change)="changeVariation($event)" [checked]="variationChecked=='test.variation.unspecified'"
                     formControlName="testVariation" id="13"
                     name="testVariation"
                     type="radio"
                     value="customer-chooses">
              <span class="id37-radio-button"></span>
              <span>{{__('customerChoose')}}</span>
            </label>
          </ng-container>
        </div>
        <button (click)="onVariationSelection()" type="button">
          {{__('changeProductModal.button.change')}}
        </button>
      </form>
    </div>
  </id37-modal-body>
</id37-modal>
