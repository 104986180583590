export enum Stage {
  PRODUCT_SELECTION = 'PRODUCT_SELECTION',
  ADDRESS_INPUT = 'ADDRESS_INPUT',
  PAYMENT_METHOD_SELECTION = 'PAYMENT_METHOD_SELECTION',
  OVERVIEW = 'OVERVIEW',
  PURCHASE = 'PURCHASE'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Stage {
  export const LIST: Stage[] = [
    Stage.PRODUCT_SELECTION,
    Stage.ADDRESS_INPUT,
    Stage.PAYMENT_METHOD_SELECTION,
    Stage.OVERVIEW,
    Stage.PURCHASE
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): Stage | undefined => {
    name = !!name ? name : '';
    const found = Stage.LIST.find((stage: string) => stage.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a stage with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };
}
