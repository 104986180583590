// eslint-disable-next-line no-shadow
export enum Referrer {

  /**
   * Users which registered using a WeightWatchers link.
   */
  WW = 'WW',

  /**
   * Users with a referrer that was not known to our system.
   */
  UNKNOWN = 'UNKNOWN',

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Referrer {
  export const LIST: Referrer[] = [
    Referrer.WW,
    Referrer.UNKNOWN
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string): Referrer => {
    name = name ? name : '';
    if (name === '') {
      return Referrer.UNKNOWN;
    }
    const found = Referrer.LIST.find(
      (referrer: string) => referrer.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a Referrer with an unknown name: "' + name + '"');
      return Referrer.UNKNOWN;
    }
    return found;
  };

}
