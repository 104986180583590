import {TestResultRepresentation} from '../../test/state/test-result-representation';
import {TeamUser} from './team-user';

export class TeamUserRepresentation {

  public readonly userKeycloakId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly result: TestResultRepresentation;

  static toModelEntity(teamUserRepresentation: TeamUserRepresentation): TeamUser {
    return {
      keycloakId: teamUserRepresentation.userKeycloakId,
      firstName: teamUserRepresentation.firstName,
      lastName: teamUserRepresentation.lastName,
      result: TestResultRepresentation.toModelEntity(teamUserRepresentation.result),
      hide: false
    };
  }
}
