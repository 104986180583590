import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {Order} from '../../order';
import {ChatbotUserStatus} from '../../../user-data/chatbot-user-status';
import {OrderService} from '../../order.service';
import {Router} from '@angular/router';
import {CartUpdate} from '../../cart/cart-update';
import {Product} from '../../../product/product';
import {CartService} from '../../cart/cart-service';
import {UserService} from '../../../user/user.service';
import {map, Subject, switchMap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-orders-subscriptions',
  templateUrl: './orders-subscriptions.component.html',
  styleUrl: './orders-subscriptions.component.scss'
})
export class OrdersSubscriptionsComponent implements OnInit, OnDestroy, OnChanges{

  @Input()
    orders: Order[];
  @Input()
    chatbotUserStatus: ChatbotUserStatus;

  isInformationExpanded: boolean = false;
  subscriptionStatus: string;
  orderID: string;
  subscriptionInformationText: string;
  isCancelModalVisible: boolean = false;

  statusDate: Date;
  isTerminationPossible: boolean;
  subscriptionInformationClass: string;

  private destroy$ = new Subject<void>();

  constructor(private translatorService: TranslatorService,
              private router: Router,
              private cartService: CartService,
              private orderService: OrderService,
              private userService: UserService) {
  }


  ngOnInit() {
    this.renderSubscriptionInformation();
  }

  ngOnChanges() {
    this.renderSubscriptionInformation();
  }

  renderSubscriptionInformation(): void {
    switch (this.chatbotUserStatus) {
      case ChatbotUserStatus.chatbot_not_tried_yet:
      case ChatbotUserStatus.chatbot_test:
      case ChatbotUserStatus.chatbot_expired:
      case ChatbotUserStatus.chatbot_error: {

        this.subscriptionStatus = this.__('subscriptionStatus.notSubscribed');
        this.subscriptionInformationText = this.__('subscriptionInformationText.notSubscribed');
        this.isTerminationPossible = false;
        this.subscriptionInformationClass = 'subscription-information-notSubscribed';
        break;
      }
      case ChatbotUserStatus.chatbot_customer: {
        this.subscriptionStatus = this.__('subscriptionStatus.subscribed');
        this.subscriptionInformationText = this.__('subscriptionInformationText.subscribed') + this.getStatusDate('renewal');
        this.isTerminationPossible = true;
        this.subscriptionInformationClass = 'subscription-information-subscribed';
        break;
      }
      case ChatbotUserStatus.chatbot_invited: {
        this.subscriptionStatus = this.__('subscriptionStatus.subscribed');
        this.subscriptionInformationText = this.__('personalChatbotBuyButtonAcquisition');
        this.isTerminationPossible = false;
        this.subscriptionInformationClass = 'subscription-information-subscribed';
        break;
      }
      case ChatbotUserStatus.chatbot_cancelled: {
        this.subscriptionInformationClass = 'subscription-information-terminated';
        this.subscriptionStatus = this.__('subscriptionStatus.terminated');
        this.subscriptionInformationText = this.__('subscriptionInformationText.terminated') + this.getStatusDate('termination');
        this.isTerminationPossible = false;
        break;
      }
      default: {
        break;
      }
    }
  }

  getStatusDate(status: string): string {
    const creationDate: Date[] = this.orders?.map(i => i.creationDate);
    const products: string[] = this.orders?.map(i => i.positions.map(l => l.product).toString());
    for (let i = 0; i < creationDate.length; i++) {
      if (products[i] === 'ID37_CHATBOT') {
        this.statusDate = creationDate[i];

        if (this.statusDate.getMonth() > 11) {
          this.statusDate.setFullYear(this.statusDate.getFullYear() + 1);
          this.statusDate.setMonth(1);
        }
        else {
          this.statusDate.setMonth(this.statusDate.getMonth() + 1);
        }
        if (status === 'termination') {
          this.statusDate.setDate(this.statusDate.getDate() - 1);
        }
        return this.formatDate(this.statusDate);
      }
    }
  }


  cancelChatbotSubscription() {
    this.orderService.cancelChatbotSubscription(this.orderID).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isCancelModalVisible = false;
      this.isTerminationPossible = false;
      this.getOrderData();
    }
    );
  }

  getOrderData() {
    this.orderService.loadOrdersForCurrentUser().pipe(
      map(orders => orders.sort((a, b) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      )),
      switchMap(orders => {
        this.orders = orders;
        return this.userService.getChatbotUserStatus();
      }),
      takeUntil(this.destroy$)
    ).subscribe(chatbotUserStatus => {
      this.chatbotUserStatus = chatbotUserStatus;
      this.renderSubscriptionInformation();
    });
  }

  openCancelChatbotModal() {
    this.orderID = this.orders[0].orderId;
    this.isCancelModalVisible = true;
  }

  buyChatbotSubscription(): void {
    this.cartService.replaceContent([new CartUpdate(Product.ID37_CHATBOT, 1)]).subscribe(() => {
      this.router.navigate(['order']);
    });

  }

  closeCancelChatbotModal() {
    this.isCancelModalVisible = false;
  }

  toggleExpandInformation(): void {
    this.isInformationExpanded = !this.isInformationExpanded;
  }

  __(key: string): string {
    return this.translatorService.translate('orders.subscriptions.' + key);
  }

  formatDate(date: any): string {
    if (!date) {
      throw new Error('Date is undefined');
    }
    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();

    const returnDay = day < 10 ? '0' + day : day;
    const returnMonth = month < 10 ? '0' + month : month;

    return `${returnDay}` + this.__('date') + `${returnMonth}` + this.__('date') + `${year}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  protected readonly ChatbotUserStatus = ChatbotUserStatus;
}
