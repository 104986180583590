import {Order} from '../../order/order';
import {TableColumn, TableColumnButtonIcon, TableRow} from './webapp-table/webapp-table.component';
import {JsDatePipe} from '../../datetime/js-date.pipe';

export class CreateWebAppTableColumnsUtils {
  public static readonly COLUMN_INCLUDES_BUTTON = true;
  public static readonly BOLD_COLOR_CSS_CLASS = 'bold-color';
  public static readonly BUTTON_CSS_CLASS = 'table-button';
  public static readonly BUTTON_ICON_CSS_CLASS = 'download-image';

  public static createTableColumn(label: string, cssClass: string = '', hasButton: boolean = false,
                                  buttonIcon: TableColumnButtonIcon = null): TableColumn  {
    return {
      label,
      cssClass,
      hasButton,
      buttonIcon
    };
  }

  public static createTableColumnButtonIcon(imageUrl: string, imageAlt: string, imageCssClass: string = ''): TableColumnButtonIcon {
    return {
      imageUrl,
      imageAlt,
      imageCssClass
    };
  }
}
