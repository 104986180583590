import {Component, OnInit} from '@angular/core';
import {Test} from '../state/test';
import {TestQuery} from '../state/test.query';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TestService} from '../state/test.service';
import {TranslatorService} from '../../translation/translator.service';
import {FriendshipResetService} from '../../friend/state/friendship-reset.service';

@Component({
  selector: 'app-test-reset',
  templateUrl: './test-reset.component.html',
  styleUrls: ['./test-reset.component.scss']
})
export class TestResetComponent implements OnInit {

  tests$: Observable<Test[]>;
  confirmationModalOpen: boolean;
  selectedTestId: string | undefined;
  testResetRequestSucceeded: { [key: string]: boolean } = {};
  testResetRequestFailed: { [key: string]: boolean } = {};

  constructor(private testQuery: TestQuery,
              private testService: TestService,
              private translatorService: TranslatorService,
              private friendshipResetService: FriendshipResetService) {
  }

  ngOnInit() {
    this.tests$ = this.testQuery.selectTestsForCurrentUser();
  }

  resetTest(): void {
    if (!this.selectedTestId) {
      console.error('No test was selected!');
    }
    this.testService.resetTest(this.selectedTestId)
      .pipe(take(1))
      .subscribe(
        (test) => {
          this.testResetRequestSucceeded[this.selectedTestId] = true;
          this.friendshipResetService.reloadFriendships();
          setTimeout(() => this.testResetRequestSucceeded[this.selectedTestId] = false, 5000);
        },
        () => {
          this.testResetRequestFailed[this.selectedTestId] = true;
          setTimeout(() => this.testResetRequestFailed[this.selectedTestId] = false, 5000);
        }
      );
  }

  openConfirmationModal(testId: string): void {
    this.confirmationModalOpen = true;
    this.selectedTestId = testId;
  }

  closeConfirmationModal(): void {
    this.confirmationModalOpen = false;
    this.selectedTestId = undefined;
  }

  __(key: string): string {
    return this.translatorService.translate('testReset.' + key);
  }

}
