import {Component, OnInit, ViewChild} from '@angular/core';
import {ChatbotSystemPromptService} from './chatbot-system-prompt.service';
import {TranslatorService} from '../../translation/translator.service';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';

import {FormsModule} from '@angular/forms';
import {ClrDatagridModule} from '@clr/angular';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-chatbot-system-prompt',
  templateUrl: './chatbot-system-prompt.component.html',
  styleUrls: ['./chatbot-system-prompt.component.scss'],
  standalone: true,
  imports: [
    TextFieldModule,
    FormsModule,
    ClrDatagridModule,
    NgOptimizedImage
  ]
})
export class ChatbotSystemPromptComponent implements OnInit{
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  currentPrompt: string = '';
  constructor(
    private systemPromptService: ChatbotSystemPromptService,
    private translatorService: TranslatorService,
  ) {}


  ngOnInit(): void {
    this.getSystemPrompt();
  }

  onUpdate(): void {
    this.systemPromptService.updateSystemPrompt(this.currentPrompt).subscribe(
      response => {
        console.log('Updated prompt: ', response);
        this.getSystemPrompt();
      }
    );
  }

  getSystemPrompt(): void {
    this.systemPromptService.getSystemPrompt().subscribe(prompt => this.currentPrompt = prompt);
    console.log('get prompt: ');

  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-system-prompts.' + key);
  }

}
