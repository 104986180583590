import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ClarityModule, ClrDropdownModule} from '@clr/angular';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {MasterModule} from './master/master.module';
import {MyAccountComponent} from './account/my-account/my-account.component';
import {ReportModule} from './report/report.module';
import {routes} from './shared/app.routes';
import {initializeKeycloak} from './shared/keycloak.init';
import {Id37AuthGuard} from './shared/id37-auth-guard.service';
import {CountryService} from './user-data/country.service';
import {AdminModule} from './admin/admin.module';
import {MasterService} from './master/master.service';
import {AdminService} from './admin/admin.service';
import {TranslatorService} from './translation/translator.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CollapsibleModule} from './collapsible/collapsible.module';
import {BrowserService} from './shared/browser.service';
import {Id37Module} from './id37/id37.module';
import {RoutingService} from './routing/routing.service';
import {NavModule} from './nav/nav.module';
import {UserService} from './user/user.service';
import {OrderModule} from './order/order.module';
import {ProfilePictureModule} from './profile-picture/profile-picture.module';
import {MasterInfoComponent} from './account/master-info/master-info.component';
import {MasterReportComponent} from './account/master-report/master-report.component';
import {InvitedByPartnerComponent} from './partner/invited-by-partner/invited-by-partner.component';
import {DirectivesModule} from './directives/directives.module';
import {environment} from '../environments/environment';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {TestModule} from './test/test.module';
import {LogoutComponent} from './user/logout/logout.component';
import {TestService} from './test/state/test.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NotificationsModule} from './notifications/notifications.module';
import {FriendModule} from './friend/friend.module';
import {UserModule} from './user/user.module';
import {CookieService} from 'ngx-cookie-service';
import {SelectModule} from './select/select.module';
import {DateTimeModule} from './datetime/datetime.module';
import {ServiceUnavailableComponent} from './service-unavailable/service-unavailable.component';
import {CircularRingModule} from './circular-ring/circular-ring.module';
import {InternetExplorerComponent} from './internet-explorer/internet-explorer.component';
import {ChatModule} from './chat/chat.module';
import {TestShareService} from './test/state/test-share.service';
import {EmailSettingsModule} from './account/email-settings/email-settings.module';
import {ModalModule} from './modal/modal.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {LifecycleModule} from './lifecycle/lifecycle.module';
import {TextComponent} from './text/text.component';
import {ChartModule} from './chart/chart.module';
import {PwaComponent} from './pwa/pwa.component';
import {KeyGraphicModule} from './graphics/key-graphic/key-graphic.module';
import {TeamModule} from './team/team.module';
import {StylesComponent} from './styles/styles.component';
import { CalendlyComponent } from './calendly/calendly.component';
import {ChatbotModule} from './chatbot/chatbot.module';
import { MarkdownModule } from 'ngx-markdown';

registerLocaleData(localeDe);

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    // deprecated Angular 15
    // RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    RouterModule.forRoot(routes, {}),
    ReportModule,
    ChartModule,
    ClarityModule,
    ClrDropdownModule,
    BrowserAnimationsModule,
    ModalModule,
    MasterModule,
    AdminModule,
    CollapsibleModule,
    Id37Module,
    NavModule,
    OrderModule,
    ProfilePictureModule,
    DirectivesModule,
    environment.production ? [] : AkitaNgDevtools,
    TestModule,
    FontAwesomeModule,
    NotificationsModule,
    FriendModule,
    UserModule,
    SelectModule,
    DateTimeModule,
    CircularRingModule,
    ChatModule,
    ChatbotModule,
    EmailSettingsModule,
    LifecycleModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    KeyGraphicModule,
    TeamModule,
    MarkdownModule.forRoot(),
    ServiceUnavailableComponent
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    MyAccountComponent,
    MasterInfoComponent,
    MasterReportComponent,
    InvitedByPartnerComponent,
    LogoutComponent,
    InternetExplorerComponent,
    TextComponent,
    PwaComponent,
    StylesComponent,
    CalendlyComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'de'
    },
    Id37AuthGuard,
    CountryService,
    AdminService,
    MasterService,
    TestService,
    TestShareService,
    TranslatorService,
    BrowserService,
    RoutingService,
    UserService,
    CookieService
  ],
  exports: [
    TextComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

