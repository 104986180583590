<section class="invoices-container">
  <h1 class="invoices-headline">{{ __('invoices.header') }}</h1>


    @if(isErrorStatus){
    <div class="error-container">
      <div class="text-container">
        <div class="headline">
          <h3>{{__('invoices.errorHeadline')}}</h3>
        </div>
        <div [innerHTML]="__('invoices.errorText')" class="id37-text1">
        </div>
    </div>
  </div>
  }

  @if(!isMobileView()) {
    <app-webapp-table
      [tableHeader]="webappTableHeaderColumns"
      [webappTableBodyRows]="webappTableBodyRows"
      [buttonClickedParams]="buttonClickedParams"
      (downloadInvoicesButtonClickedEvent)="downloadInvoicePdf($event)"
    ></app-webapp-table>
  } @else {
    <app-mobile-table
      [orders]="orders"
      [isUnfinished]="isUnfinished"
      (downloadInvoicesButtonClickedEvent)="downloadInvoicePdf($event)"
    ></app-mobile-table>
  }
</section>


