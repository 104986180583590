import {Component} from '@angular/core';
import {ServiceService} from './service.service';
import {take} from 'rxjs/operators';
import {TranslatorService} from '../../translation/translator.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent {

  notificationRefreshTimeInSeconds = environment.NOTIFICATION_REFRESH_TIME_IN_SECONDS;

  messageTimeoutInS = 2;

  triggerSystemTestNotificationOngoingRequest: boolean = false;
  triggerSystemTestNotificationRequestSucceeded: boolean = false;
  triggerSystemTestNotificationRequestFailed: boolean = false;

  triggerTestExpirationCheckerOngoingRequest: boolean = false;
  triggerTestExpirationCheckerRequestSucceeded: boolean = false;
  triggerTestExpirationCheckerRequestFailed: boolean = false;

  triggerKeycloakUserCacheRefresherOngoingRequest: boolean = false;
  triggerKeycloakUserCacheRefresherRequestSucceeded: boolean = false;
  triggerKeycloakUserCacheRefresherRequestFailed: boolean = false;

  triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest: boolean = false;
  triggerKeycloakUsernameAndEmailEqualityCheckerRequestSucceeded: boolean = false;
  triggerKeycloakUsernameAndEmailEqualityCheckerRequestFailed: boolean = false;

  constructor(private serviceService: ServiceService,
              private translatorService: TranslatorService) {
  }

  triggerSystemTestNotification(): void {
    if (this.triggerSystemTestNotificationOngoingRequest) {
      return;
    }
    this.triggerSystemTestNotificationOngoingRequest = true;
    this.serviceService.triggerSystemTestNotification().pipe(take(1)).subscribe(
      () => {
        this.triggerSystemTestNotificationOngoingRequest = false;
        this.triggerSystemTestNotificationRequestSucceeded = true;
        setTimeout(() => {
          this.triggerSystemTestNotificationRequestSucceeded = false;
        }, this.messageTimeoutInS * 1000);
      },
      () => {
        this.triggerSystemTestNotificationOngoingRequest = false;
        this.triggerSystemTestNotificationRequestFailed = true;
        setTimeout(() => {
          this.triggerSystemTestNotificationRequestFailed = false;
        }, this.messageTimeoutInS * 1000);
      }
    );
  }

  triggerTestExpirationChecker(): void {
    if (this.triggerTestExpirationCheckerOngoingRequest) {
      return;
    }
    this.triggerTestExpirationCheckerOngoingRequest = true;
    this.serviceService.triggerTestExpirationChecker().pipe(take(1)).subscribe(
      () => {
        this.triggerTestExpirationCheckerOngoingRequest = false;
        this.triggerTestExpirationCheckerRequestSucceeded = true;
        setTimeout(() => {
          this.triggerTestExpirationCheckerRequestSucceeded = false;
        }, this.messageTimeoutInS * 1000);
      },
      () => {
        this.triggerTestExpirationCheckerOngoingRequest = false;
        this.triggerTestExpirationCheckerRequestFailed = true;
        setTimeout(() => {
          this.triggerTestExpirationCheckerRequestFailed = false;
        }, this.messageTimeoutInS * 1000);
      }
    );
  }

  triggerKeycloakUserCacheRefresher(): void {
    if (this.triggerKeycloakUserCacheRefresherOngoingRequest) {
      return;
    }
    this.triggerKeycloakUserCacheRefresherOngoingRequest = true;
    this.serviceService.triggerKeycloakUserCacheRefresher().pipe(take(1)).subscribe(
      () => {
        this.triggerKeycloakUserCacheRefresherOngoingRequest = false;
        this.triggerKeycloakUserCacheRefresherRequestSucceeded = true;
        setTimeout(() => {
          this.triggerKeycloakUserCacheRefresherRequestSucceeded = false;
        }, this.messageTimeoutInS * 1000);
      },
      () => {
        this.triggerKeycloakUserCacheRefresherOngoingRequest = false;
        this.triggerKeycloakUserCacheRefresherRequestFailed = true;
        setTimeout(() => {
          this.triggerKeycloakUserCacheRefresherRequestFailed = false;
        }, this.messageTimeoutInS * 1000);
      }
    );
  }

  triggerKeycloakUsernameAndEmailEqualityChecker(): void {
    if (this.triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest) {
      return;
    }
    this.triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest = true;
    this.serviceService.triggerKeycloakUsernameAndEmailEqualityChecker().pipe(take(1)).subscribe(
      () => {
        this.triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest = false;
        this.triggerKeycloakUsernameAndEmailEqualityCheckerRequestSucceeded = true;
        setTimeout(() => {
          this.triggerKeycloakUsernameAndEmailEqualityCheckerRequestSucceeded = false;
        }, this.messageTimeoutInS * 1000);
      },
      () => {
        this.triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest = false;
        this.triggerKeycloakUsernameAndEmailEqualityCheckerRequestFailed = true;
        setTimeout(() => {
          this.triggerKeycloakUsernameAndEmailEqualityCheckerRequestFailed = false;
        }, this.messageTimeoutInS * 1000);
      }
    );
  }

  __(key: string): string {
    return this.translatorService.translate('service.' + key);
  }

}
