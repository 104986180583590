import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BasicUser} from '../../basic-user';
import {Choice} from '../../../select/state/choices-types';

@Component({
  selector: 'app-user-search-output',
  templateUrl: './user-search-output.component.html',
  styleUrls: ['./user-search-output.component.scss']
})
export class UserSearchOutputComponent implements OnInit {

  @Input()
    searching: boolean = false;

  @Output()
    selected: EventEmitter<BasicUser> = new EventEmitter<BasicUser>();

  choices: Choice[] = [];

  private _searchResult: BasicUser[] | undefined = undefined;

  constructor() {
  }

  @Input()
  set searchResult(searchResult: BasicUser[] | undefined) {
    this._searchResult = searchResult;
    this.choices = [];
    if (!!searchResult) {
      for (const result of searchResult) {
        this.choices.push({value: result.keycloakId, label: result.firstName + ' ' + result.lastName});
      }
    }
  }

  ngOnInit(): void {
  }

  selection(selected: Choice | Choice[] | undefined): void {
    if (!selected || Array.isArray(selected)) {
      console.error('Unable to handle array-like selection.');
      return;
    }
    // Search selected user.
    for (const user of this._searchResult) {
      if (user.keycloakId === selected.value) {
        this.selected.emit(user);
        return;
      }
    }
    console.error('Assertion error: Could not find user.');
  }


}
