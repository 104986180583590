<div class="chartWrapper">
  <canvas id="canvas"></canvas>
</div>

<div class="buttonContainer">
  <button (click)="switchAxes()" class="id37-btn-primary">Switch Axes</button>
  <button (click)="toggleLines()" class="id37-btn-primary">Toggle Lines</button>
</div>

<div class="button-container">
  <ng-container *ngFor="let b of motiveButtons">
    <button
        (click)="toggleMotive(b.motive)"
        [ngClass]="{'selected': isSelected(b.motive)}"
        class="id37-btn id37-btn-bw id37-btn-flat">
      {{b.displayName}}
    </button>
  </ng-container>
</div>
