import {Edge} from './edge';

export class EdgeRepresentation {

  public readonly source: number;
  public readonly target: number;

  static toModelEntity(edgeRepresentation: EdgeRepresentation): Edge {
    return {
      source: edgeRepresentation.source,
      target: edgeRepresentation.target
    };
  }

}
