import {Pipe, PipeTransform} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';

@Pipe({
  name: 'JsTime',
  pure: true
})
export class JsTimePipe implements PipeTransform {

  constructor(private currentUser: CurrentUser) {
  }

  transform(date: Date | undefined, args?: any): string {
    if (!date) {
      return '';
    }
    return date.toLocaleTimeString(
      this.currentUser.locale,
      {
        hour: '2-digit',
        minute: '2-digit'
      }
    );
  }

}
