import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUser} from './user/current-user.service';

@Injectable({
  providedIn: 'root'
})
export class Id37Router {
  constructor(private router: Router, private currentUser: CurrentUser) {
  }

  routeToDashboard(): void {
    this.router.navigate(['dashboard']);
  }

  routeToAccount(): void {
    this.router.navigate(['my-account']);
  }

  routeToCustomers(userId?: string, testId?: string): void {
    // TODO: process userId and testId to select specific customer in the customers view.
    this.router.navigate(['my-customers']);
  }

  routeToMyReport(testId: string): void {
    this.routeToReport(this.currentUser.keycloakId, testId);
  }

  routeToReport(userId: string, testId: string): void {
    this.router.navigate(['report', userId, testId]);
  }

  routeToMyKeyGraphic(testId: string): void {
    this.router.navigate(['key-graphic', testId]);
  }

  routeToKeyGraphicOfUser(
    userId: string,
    testId: string,
    userFullName: string
  ): void {
    this.router.navigate(['key-graphic', testId], {
      state: {
        clientId: userId,
        clientName: userFullName
      }
    });
  }

  routeToTest(testId: string): void {
    this.router.navigate(['my-test', testId]);
  }

  routeToTestReset(): void {
    this.router.navigate(['test-reset']);
  }

  routeToComparer(
    userAId: string,
    userATestId: string,
    userAName: string,
    userBId: string,
    userBTestId: string,
    userBName: string
  ): void {
    this.router.navigate(['comparer'], {
      state: {
        user1Id: userAId,
        user1TestId: userATestId,
        user1Name: userAName,
        user2Id: userBId,
        user2TestId: userBTestId,
        user2Name: userBName
      }
    });
  }

  routeToFriends(friendshipId?: number | undefined): void {
    // TODO: Use friendshipId to highlight friendship in list view.
    this.router.navigate(['friends']);
  }

  routeToFriendCompare(friendshipId: number): void {
    this.router.navigate(['friends', 'friend-compare', friendshipId]);
  }

  routeToChat(): void {
    this.router.navigate(['chat']);
  }

  routeToOrders(): void {
    this.router.navigate(['orders']);
  }

  routeToOrder(orderId: string): void {
    this.router.navigate(['orders', orderId]);
  }

  routeToOrderProcessing(orderId: string): void {
    this.router.navigate(['orders', orderId, 'process']);
  }
}
