import {Injectable, QueryList} from '@angular/core';
import {MotiveComponent} from './motive/motive.component';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ReportComponentService {

  private motiveSubject = new BehaviorSubject<MotiveComponent[]>([]);
  motiveComponents$: Observable<MotiveComponent[]> = this.motiveSubject.asObservable();

  setMotiveComponents(motiveComponents: QueryList<MotiveComponent>): void {
    setTimeout(() => {
      this.motiveSubject.next(motiveComponents.toArray());
    }, 0);
  }

}
