import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-distance-line]',
  templateUrl: './distance-line.component.html',
  styleUrls: ['./distance-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistanceLineComponent implements OnInit {

  @Input()
    motiveScoreA: MotiveScore;

  @Input()
    motiveScoreB: MotiveScore;

  @Input()
    index: number;

  private readonly xBase = -10.5;
  private readonly yBase = 70;
  private readonly gridStep = 80;

  y: number;
  x1: number;
  x2: number;
  strokeClass: string;
  tPoint1: string;
  tPoint2: string;
  tPoint3: string;
  distance: number;

  constructor() {
  }

  ngOnInit(): void {
    this.y = (this.index * this.gridStep) + this.yBase;
    this.x1 = (this.motiveScoreA.score - 1) * 80 + this.xBase;
    this.x2 = (this.motiveScoreB.score - 1) * 80 + this.xBase;
    this.strokeClass = this.computeStrokeClass(this.motiveScoreA.score, this.motiveScoreB.score);
    this.computeTriangle(this.motiveScoreA.score, this.motiveScoreB.score);
    this.distance = Math.abs(this.motiveScoreA.score - this.motiveScoreB.score);
  }

  private computeTriangle(scoreA: number, scoreB: number) {
    if (scoreA < scoreB) { // arrow to the right
      this.tPoint1 = `${46 + this.x2},42`;
      this.tPoint2 = `${46 + this.x2},58`;
      this.tPoint3 = `${59 + this.x2},50`;
    }
    else { // arrow to the left
      this.tPoint1 = `${57 + this.x2},42`;
      this.tPoint2 = `${57 + this.x2},58`;
      this.tPoint3 = `${44 + this.x2},50`;
    }
  }

  private computeStrokeClass(r1: number, r2: number): string {
    if (r1 <= r2) {
      return 'url(#leftRight)';
    }
    else {
      return 'url(#rightLeft)';
    }
  }
}
