import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {AccountDataQuery} from '../../../../account/account-data-query';
import {CurrentUser} from '../../../../user/current-user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-test-revoked-message',
  templateUrl: './test-revoked-message.component.html',
  styleUrls: ['./test-revoked-message.component.scss']
})
export class TestRevokedMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  private unsubscribe$: Subject<void> = new Subject<void>();

  isMaster: boolean = false;
  isPartner: boolean = false;

  messagePartA: string = '';
  messageRoleMaster: string = '';
  messageRolePartner: string = '';
  messagePartB: string = '';

  constructor(private currentUser: CurrentUser,
              private accountDataQuery: AccountDataQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.accountDataQuery.selectAccountData(this.currentUser.keycloakId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accountData => {
        this.isMaster = !!accountData.masterInfo;
        this.isPartner = !!accountData.partnerInfo;
      });
    this.computeMessage();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
    this.messageRoleMaster = this.__('roleMaster');
    this.messageRolePartner = this.__('rolePartner');
    this.messagePartB = this.__('partB');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
