import {Injectable} from '@angular/core';
import {arrayUpdate} from '@datorama/akita';
import {Team} from './team';
import {Observable, OperatorFunction, Subject, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {TeamsRepresentation} from './teams-representation';
import {environment} from '../../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {NewTeamRepresentation} from './new-team-representation';
import {TeamStore} from './team.store';
import {TeamRepresentation} from './team-representation';
import {TeamQuery} from './team.query';
import {TeamUser} from './team-user';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  bubblesHidden = new Subject<boolean>();
  motivesHidden = new Subject<void>();
  motivesVariationChanged = new Subject<number>();
  resetView = new Subject<void>();

  constructor(private http: HttpClient,
              private teamQuery: TeamQuery,
              private teamStore: TeamStore) {
  }

  storeTeam(team: Team): void {
    const updatedTeam: Team = {
      teamId: team.teamId,
      creatorId: team.creatorId,
      name: team.name,
      users: team.users
    };
    this.teamStore.upsert(team.teamId, () => updatedTeam);
  }

  storeTeams(teams: Team[]): void {
    teams.forEach(team => this.storeTeam(team));
  }

  loadTeams(): Observable<Team[]> {
    this.teamStore.setLoading(true);
    return this.http.get<TeamsRepresentation>(
      API_URL + '/team',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((teamsRepresentation: TeamsRepresentation) =>
        TeamsRepresentation.toModelEntity(teamsRepresentation)),
      tap((teams: Team[]) => {
        this.storeTeams(teams);
        if (teams.length !== 0) {
          teams.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          this.changedSelectedTeam(teams[0]);
        }
        this.teamStore.setLoading(false);
      }, (error) => {
        console.log(error);
        this.teamStore.setLoading(false);
      })
    );
  }

  changedSelectedTeam(selectedTeam: Team): void {
    this.teamStore.update({selectedTeam});
    this.checkFadedMembers();
  }

  changedSelectedTeamView(teamUser: TeamUser, hide: boolean): void {
    this.teamStore.update(this.teamStore.getValue().selectedTeam.teamId, (state) => ({
      users: arrayUpdate(state.users, user => user.keycloakId === teamUser.keycloakId, {
        keycloakId: teamUser.keycloakId, hide
      })
    }));
    this.changedSelectedTeam(this.teamQuery.getEntity(this.teamStore.getValue().selectedTeam.teamId));
  }


  resetTeamView(): void {
    this.teamStore.update(this.teamStore.getValue().selectedTeam.teamId, (state) => ({
      users: arrayUpdate(state.users, users => users.hide === true, {hide: false})
    }));
    this.changedSelectedTeam(this.teamQuery.getEntity(this.teamStore.getValue().selectedTeam.teamId));
    this.resetView.next();
  }

  createTeam(newTeamRepresentation: NewTeamRepresentation): Observable<Team> {
    return this.http.post<TeamRepresentation>(
      API_URL + '/team',
      newTeamRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    ).pipe(
      map((teamRepresentation: TeamRepresentation) =>
        TeamRepresentation.toModelEntity(teamRepresentation)),
      tap((team: Team) => {
        this.storeTeam(team);
        this.changedSelectedTeam(team);
      })
    );
  }

  updateTeam(teamId: string, newTeam: NewTeamRepresentation): Observable<Team> {
    return this.http.patch<TeamRepresentation>(
      API_URL + '/team/' + teamId,
      newTeam,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    ).pipe(
      map((teamRepresentation: TeamRepresentation) =>
        TeamRepresentation.toModelEntity(teamRepresentation)),
      tap((team: Team) => {
        this.storeTeam(team);
        this.changedSelectedTeam(team);
      }),
      catchError(
        (error: HttpErrorResponse): Observable<Team> => {
          console.error(error);
          return throwError(error);
        }
      )
    );
  }

  deleteTeam(team: Team): Observable<any> {
    this.teamStore.remove(team.teamId);
    return this.http.delete<void>(
      API_URL + '/team/' + team.teamId,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    ).pipe(
      catchError(
        (error: HttpErrorResponse): Observable<void> => {
          // Restore team on error.
          this.storeTeam(team);
          // Rethrow.
          return throwError(error);
        }
      ) as OperatorFunction<void, any>
    );
  }

  private checkFadedMembers(): void {
    if (this.teamQuery.getEntity(this.teamStore.getValue().selectedTeam.teamId).users.map(
      (user) => user.hide).includes(true)) {
      this.bubblesHidden.next(true);
    }
    else {
      this.bubblesHidden.next(false);
    }
  }
}
