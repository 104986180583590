import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {OrderService} from '../order.service';
import {Stage} from '../stage';
import {Order} from '../order';
import {forkJoin} from 'rxjs';
import {AccountData} from '../../account/account-data';
import {OrderStatus} from '../order-status';
import {TranslatorService} from '../../translation/translator.service';
import {OrderAddress} from '../address/order-address';
import {UnzerPaymentResourceRepresentation} from '../payment/unzer-payment-resource-representation';
import {Charge} from '../payment/charge/charge';
import {ProgressItem} from '../../id37/progress-box/progress-item';
import {ProgressSwitchEvent} from '../../id37/progress-box/progress-switch-event';
import {PaymentProviderAndMethod} from '../payment/payment-provider-and-method';
import {CartService} from '../cart/cart-service';
import {Cart} from '../cart/cart';
import {ScrollService} from '../../id37/scroll.service';
import {AccountDataQuery} from '../../account/account-data-query';
import {TestQuery} from '../../test/state/test.query';
import {Test} from '../../test/state/test';
import {take} from 'rxjs/operators';
import {PaymentMethod} from '../payment/payment-method';
import {Id37Router} from '../../id37-router';
import {RecurringRedirectRepresentation} from '../payment/recurring-redirect-representation';
import {Product} from '../../product/product';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  accessGranted: boolean = false;

  ongoingRequest: boolean = false;

  ongoingOrder: Order | undefined;
  accountData: AccountData | undefined;
  currentStageIndex: number = 0;
  lastStageIndex: number = 0;
  stages = Stage;
  addressIsValid: boolean = false;
  addressSupplier: EventEmitter<OrderAddress> = new EventEmitter<OrderAddress>();
  addressReceiver: EventEmitter<void> = new EventEmitter<void>();
  paymentProviderAndMethod: PaymentProviderAndMethod | undefined;
  method = PaymentMethod;
  availableProgressItems: ProgressItem<string>[] = [
    new ProgressItem(Stage.ADDRESS_INPUT, this.__(Stage.ADDRESS_INPUT)),
    new ProgressItem(Stage.OVERVIEW, this.__(Stage.OVERVIEW)),
    new ProgressItem(Stage.PAYMENT_METHOD_SELECTION, this.__(Stage.PAYMENT_METHOD_SELECTION)),
    new ProgressItem(Stage.PURCHASE, this.__(Stage.PURCHASE))
  ];
  ongoingPayment: boolean = false;
  chatbotOrder: boolean = false;

  constructor(private accountDataQuery: AccountDataQuery,
              private testQuery: TestQuery,
              public currentUser: CurrentUser,
              private orderService: OrderService,
              private cartService: CartService,
              private scrollService: ScrollService,
              private translatorService: TranslatorService,
              private changeDetectorRef: ChangeDetectorRef,
              private id37Router: Id37Router,
              private keycloakService: KeycloakService) {
  }

  private _stage: Stage;

  get stage(): Stage {
    return this._stage;
  }

  ngOnInit() {
    this.setStage(Stage.ADDRESS_INPUT, false);
    this.accountDataQuery.selectAccountDataForCurrentUser().subscribe((accountData) => {
      this.accountData = accountData;
    });
    this.loadData();
  }

  handleAddressValidState(isValid: boolean): void {
    this.addressIsValid = isValid;
    this.changeDetectorRef.detectChanges();
  }

  handleAddressInput(invoiceAddress: OrderAddress): void {
    this.ongoingRequest = true;
    if (typeof this.ongoingOrder === typeof undefined) {
      this.orderService.initializeOrder(invoiceAddress).subscribe(order => {
        this.setOngoingOrder(order);
        this.goToNextStage(false);
        this.ongoingRequest = false;
      }, () => this.ongoingRequest = false);
    }
    else {
      this.orderService.patchOrderAddress(this.ongoingOrder.orderId, invoiceAddress).subscribe(order => {
        this.setOngoingOrder(order);
        this.goToNextStage(false);
        this.ongoingRequest = false;
      }, () => this.ongoingRequest = false);
    }
  }

  setPaymentProviderAndMethodToUse(paymentProviderAndMethod: PaymentProviderAndMethod): void {
    this.paymentProviderAndMethod = paymentProviderAndMethod;
    this.goToNextStage(false);
  }

  completeOrder(): void {
    this.orderService.completeOrder(this.ongoingOrder.orderId).subscribe({
      next: (updatedOrder) => {
        this.ongoingOrder = updatedOrder;
        this.id37Router.routeToOrderProcessing(this.ongoingOrder.orderId);
      }
    });
  }

  doPurchase(unzerPaymentResourceRepresentation: UnzerPaymentResourceRepresentation): void {
    this.ongoingPayment = true;
    if (this.paymentProviderAndMethod.method === PaymentMethod.CREDIT_CARD) {
      this.chargeCreditCard(unzerPaymentResourceRepresentation);
    }
    else if (this.paymentProviderAndMethod.method === PaymentMethod.PAYPAL) {
      if (this.chatbotOrder) {
        this.initiateRecurringPaypalCharge(unzerPaymentResourceRepresentation);
      }
      else {
        this.chargePayPal(unzerPaymentResourceRepresentation);
      }
    }
    else {
      console.error('Unsupported payment method.');
      return;
    }
  }

  chargeCreditCard(unzerPaymentResourceRepresentation: UnzerPaymentResourceRepresentation): void {
    this.orderService.chargeCreditCard(this.ongoingOrder.orderId, unzerPaymentResourceRepresentation)
      .subscribe(
        (charge: Charge) => this.handleChargeResponse(charge),
        error => this.handleChargeError(error)
      );
  }

  chargePayPal(unzerPaymentResourceRepresentation: UnzerPaymentResourceRepresentation): void {
    this.orderService.chargePayPal(this.ongoingOrder.orderId, unzerPaymentResourceRepresentation)
      .subscribe(
        (charge: Charge) => this.handleChargeResponse(charge),
        error => this.handleChargeError(error)
      );
  }

  initiateRecurringPaypalCharge(unzerPaymentResourceRepresentation: UnzerPaymentResourceRepresentation): void {
    this.orderService.initiateRecurringPaypalCharge(this.ongoingOrder.orderId, unzerPaymentResourceRepresentation)
      .subscribe(
        (redirectRepresentation: RecurringRedirectRepresentation) => {
          window.location.assign(redirectRepresentation.redirectUrl);
        },
        error => this.handleChargeError(error)
      );
  }

  handleChargeResponse(charge: Charge): void {
    if (!!charge.returnUrl && charge.returnUrl.length !== 0) {
      window.location.assign(charge.returnUrl);
    }
    this.ongoingPayment = false;
  }

  handleChargeError(error: any): void {
    console.error('Failed to charge ' + this.paymentProviderAndMethod.method + '.', error);
    this.ongoingPayment = false;
  }

  processClickOnPageKnob(progressSwitchEvent: ProgressSwitchEvent<string>): void {
    this.setStage(Stage.fromName(progressSwitchEvent.item.key), true);
  }

  goToNextStage(withCheck: boolean = true): void {
    let next: Stage;
    if (this.stage === Stage.ADDRESS_INPUT) {
      next = Stage.OVERVIEW;
    }
    else if (this.stage === Stage.OVERVIEW) {
      next = Stage.PAYMENT_METHOD_SELECTION;
    }
    else if (this.stage === Stage.PAYMENT_METHOD_SELECTION) {
      next = Stage.PURCHASE;
    }
    this.setStage(next, withCheck);
  }

  goToPreviousStage(): void {
    let previous: Stage;
    if (this.stage === Stage.PURCHASE) {
      previous = Stage.PAYMENT_METHOD_SELECTION;
    }
    else if (this.stage === Stage.PAYMENT_METHOD_SELECTION) {
      previous = Stage.OVERVIEW;
    }
    else if (this.stage === Stage.OVERVIEW) {
      previous = Stage.ADDRESS_INPUT;
    }
    this.setStage(previous, true);
  }

  __(key: string): string {
    return this.translatorService.translate('order.' + key);
  }

  private loadData(): void {
    forkJoin([
      this.testQuery.selectTestsForCurrentUser().pipe(take(1)),
      this.cartService.getContent(),
      this.orderService.loadOrdersForCurrentUser()
    ]).subscribe(
      (data: [Test[], Cart, Order[]]) => {
        if (data[1].positions.length === 0) {
          console.error('Placing an order is not allowed. Your cart is empty.');
          this.id37Router.routeToDashboard();
          return;
        }
        this.chatbotOrder = data[1].containsProduct(Product.ID37_CHATBOT);
        if (data[0].length !== 0 && !this.chatbotOrder) {
          console.error('Placing an order is not allowed. Test is already present.');
          this.id37Router.routeToDashboard();
          return;
        }
        // Do not let the user start a new order if another one is still pending.
        for (const order of data[2]) {
          if (order.status === OrderStatus.PENDING) {
            this.id37Router.routeToOrderProcessing(order.orderId);
          }
        }
        this.setOngoingOrder(data[2]
          .filter(order => order.status === OrderStatus.INITIALIZED)
          .pop());
        this.accessGranted = true;
        this.changeDetectorRef.detectChanges();
        this.supplyInitialAddressToAddressForm();
      }
    );
  }

  private setOngoingOrder(order: Order | undefined): void {
    this.ongoingOrder = order;
  }

  private supplyInitialAddressToAddressForm(): void {
    if (typeof this.ongoingOrder !== typeof undefined) {
      this.addressSupplier.emit(this.ongoingOrder.invoiceAddress);
    }
    else if (!!this.accountData) {
      this.addressSupplier.emit(new OrderAddress(
        this.accountData.personalData.firstName,
        this.accountData.personalData.lastName,
        this.accountData.jobAndEducation.company,
        '',
        '',
        '',
        '',
        '',
        '',
        ''
      ));
    }
  }

  private setStage(stage: Stage | undefined, withCheck: boolean): void {
    // If stage is not defined. Do nothing.
    if (typeof stage === typeof undefined) {
      return;
    }
    let progressItemIndex = -1;
    this.availableProgressItems.forEach((item, index) => {
      if (item.key === stage) {
        progressItemIndex = index;
      }
    });
    if (progressItemIndex === -1) {
      console.warn('Assertion error. Stage should have an associated progress item.');
      return;
    }
    // Only go to that stage, if it is below or equal to the last stage.
    if (withCheck) {
      if (progressItemIndex <= this.lastStageIndex) {
        this.setStageInsecure(stage, progressItemIndex);
      }
      else {
        console.log('Rejected stage change.');
      }
    }
    else {
      this.setStageInsecure(stage, progressItemIndex);
    }
  }

  private setStageInsecure(stage: Stage, progressItemIndex: number): void {
    this._stage = stage;
    // Update the stage indices.
    this.currentStageIndex = progressItemIndex;
    if (this.currentStageIndex > this.lastStageIndex) {
      this.lastStageIndex = this.currentStageIndex;
    }
    this.processStageChange(this._stage);
  }

  private processStageChange( stage: Stage) {
    this.scrollService.scrollToElement('top-anchor');
    this.changeDetectorRef.detectChanges();
    if (stage === Stage.ADDRESS_INPUT) {
      this.supplyInitialAddressToAddressForm();
    }
  }

}

