import {Choice, LocalizedChoices} from '../select/state/choices-types';

export class ChoicesOptions {

  static readonly TOP_COUNTRIES: LocalizedChoices = {
    de: [
      {value: 'de', label: 'Deutschland'},
      {value: 'gb', label: 'England'},
      {value: 'fr', label: 'Frankreich'},
      {value: 'at', label: 'Österreich'},
      {value: 'pl', label: 'Polen'},
      {value: 'nl', label: 'Niederlande'},
      {value: 'ch', label: 'Schweiz'},
      {value: 'ru', label: 'Russland'},
      {value: 'ua', label: 'Ukraine'},
      {value: 'kz', label: 'Kasachstan'}
    ],
    en: [
      {value: 'de', label: 'Germany'},
      {value: 'gb', label: 'United Kingdom'},
      {value: 'fr', label: 'France'},
      {value: 'at', label: 'Austria'},
      {value: 'pl', label: 'Poland'},
      {value: 'nl', label: 'Netherlands'},
      {value: 'ch', label: 'Switzerland'},
      {value: 'ru', label: 'Russia'},
      {value: 'ua', label: 'Ukraine'},
      {value: 'kz', label: 'Kazakhstan'}
    ],
    ru: [
      {value: 'de', label: 'Германия'},
      {value: 'gb', label: 'Англия'},
      {value: 'fr', label: 'Франция'},
      {value: 'at', label: 'Австрия'},
      {value: 'pl', label: 'Польша'},
      {value: 'nl', label: 'Нидерланды'},
      {value: 'ch', label: 'Швейцария'},
      {value: 'ru', label: 'Россия'},
      {value: 'ua', label: 'Украина'},
      {value: 'kz', label: 'Казахстан'}
    ]
  };

  static readonly TOP_NATIONALITIES: LocalizedChoices = {
    de: [
      {value: 'de', label: 'Deutsch'},
      {value: 'gb', label: 'Britisch'},
      {value: 'fr', label: 'Französisch'},
      {value: 'at', label: 'Österreichisch'},
      {value: 'pl', label: 'Polnisch'},
      {value: 'nl', label: 'Niederländisch'},
      {value: 'ch', label: 'Schweizerisch'},
      {value: 'ru', label: 'Russisch'},
      {value: 'ua', label: 'Ukrainisch'},
      {value: 'kz', label: 'Kasachisch'}
    ],
    en: [
      {value: 'de', label: 'German'},
      {value: 'gb', label: 'British'},
      {value: 'fr', label: 'French'},
      {value: 'at', label: 'Austrian'},
      {value: 'pl', label: 'Polish'},
      {value: 'nl', label: 'Dutch'},
      {value: 'ch', label: 'Swiss'},
      {value: 'ru', label: 'Russian'},
      {value: 'ua', label: 'Ukrainian'},
      {value: 'kz', label: 'Kazakh'}
    ],
    ru: [
      {value: 'de', label: 'Немец'},
      {value: 'gb', label: 'Британец'},
      {value: 'fr', label: 'Француз'},
      {value: 'at', label: 'Австриец'},
      {value: 'pl', label: 'Поляк'},
      {value: 'nl', label: 'Голландец'},
      {value: 'ch', label: 'Швейцарец'},
      {value: 'ru', label: 'Русский'},
      {value: 'ua', label: 'Украинец'},
      {value: 'kz', label: 'Казах'}
    ]
  };

  static readonly TRANSLATED_LANGUAGES: LocalizedChoices = {
    de: [
      {value: 'de', label: 'Deutsch'},
      {value: 'en', label: 'Englisch'},
      {value: 'fr', label: 'Französisch'},
      {value: 'nl', label: 'Niederländisch'},
      {value: 'ru', label: 'Russisch'},
      {value: 'es', label: 'Spanisch'},
      {value: 'it', label: 'Italienisch'},
      {value: 'ka', label: 'Georgisch'}
    ],
    en: [
      {value: 'de', label: 'German'},
      {value: 'en', label: 'English'},
      {value: 'fr', label: 'French'},
      {value: 'nl', label: 'Dutch'},
      {value: 'ru', label: 'Russian'},
      {value: 'es', label: 'Spanish'},
      {value: 'it', label: 'Italian'},
      {value: 'ka', label: 'Georgian'}
    ],
    ru: [
      {value: 'de', label: 'Немец'},
      {value: 'en', label: 'Британец'},
      {value: 'fr', label: 'Француз'},
      {value: 'nl', label: 'Голландец'},
      {value: 'ru', label: 'Русский'},
      {value: 'es', label: 'испанский'},
      {value: 'it', label: 'Итальянский'},
      {value: 'ka', label: 'грузинский'}
    ],
    fr: [
      {value: 'de', label: 'Allemand'},
      {value: 'en', label: 'Anglais'},
      {value: 'fr', label: 'Français'},
      {value: 'nl', label: 'Néerlandais'},
      {value: 'ru', label: 'Russe'},
      {value: 'es', label: 'Espagnol'},
      {value: 'it', label: 'Italien'},
      {value: 'ka', label: 'Géorgien'}
    ],
    nl: [
      {value: 'de', label: 'Duits'},
      {value: 'en', label: 'Engels'},
      {value: 'fr', label: 'Frans'},
      {value: 'nl', label: 'Nederlands'},
      {value: 'ru', label: 'Russisch'},
      {value: 'es', label: 'Holandés'},
      {value: 'it', label: 'Italiaans'},
      {value: 'ka', label: 'Georgisch'}
    ],
    es: [
      {value: 'de', label: 'Alemán'},
      {value: 'en', label: 'Inglés'},
      {value: 'fr', label: 'Francés'},
      {value: 'nl', label: 'Holandés'},
      {value: 'ru', label: 'Ruso'},
      {value: 'es', label: 'Español'},
      {value: 'it', label: 'Italiano'},
      {value: 'ka', label: 'Georgiano'}
    ],
    it: [
      {value: 'de', label: 'Tedesco'},
      {value: 'en', label: 'Inglese'},
      {value: 'fr', label: 'Francese'},
      {value: 'nl', label: 'Olandese'},
      {value: 'ru', label: 'Russo'},
      {value: 'es', label: 'Spagnolo'},
      {value: 'it', label: 'Italiano'},
      {value: 'ka', label: 'Georgiano'}
    ],
    ka: [
      {value: 'de', label: 'გერმანული'},
      {value: 'en', label: 'ინგლისური'},
      {value: 'fr', label: 'ფრანგული'},
      {value: 'nl', label: 'ჰოლანდიური'},
      {value: 'ru', label: 'რუსული'},
      {value: 'es', label: 'ესპანური'},
      {value: 'it', label: 'იტალიური'},
      {value: 'ka', label: 'ქართული'}
    ],
  };

  static readonly GENDERS_WITH_UNSPECIFIED_OPTION: LocalizedChoices = {
    de: [
      {value: '', label: '-'},
      {value: 'male', label: 'Männlich'},
      {value: 'female', label: 'Weiblich'},
      {value: 'diverse', label: 'Divers'}
    ],
    en: [
      {value: '', label: '-'},
      {value: 'male', label: 'Male'},
      {value: 'female', label: 'Female'},
      {value: 'diverse', label: 'Diverse'}
    ],
    ru: [
      {value: '', label: '-'},
      {value: 'male', label: 'Мужской'},
      {value: 'female', label: 'Женский'},
      {value: 'diverse', label: 'Без указания'}
    ],
    ka: [
      {value: '', label: '-'},
      {value: 'male', label: 'მამრობითი'},
      {value: 'female', label: 'მდედრობითი'},
      {value: 'diverse', label: 'სხვა'}
    ]
  };

  static readonly GENDERS: LocalizedChoices = {
    de: [
      {value: 'male', label: 'Männlich'},
      {value: 'female', label: 'Weiblich'},
      {value: 'diverse', label: 'Divers'}
    ],
    en: [
      {value: 'male', label: 'Male'},
      {value: 'female', label: 'Female'},
      {value: 'diverse', label: 'Diverse'}
    ],
    ru: [
      {value: 'male', label: 'Мужской'},
      {value: 'female', label: 'Женский'},
      {value: 'diverse', label: 'Без указания'}
    ],
    ka: [
      {value: '', label: '-'},
      {value: 'male', label: 'მამრობითი'},
      {value: 'female', label: 'მდედრობითი'},
      {value: 'diverse', label: 'სხვა'}
    ]
  };

  static readonly VARIATIONS: Choice[] = [
    {value: 'ID37/16', label: 'ID37/16'},
    {value: 'ID37/15', label: 'ID37/15'},
    {value: 'ID37/14', label: 'ID37/14'}
  ];

  static readonly ACADEMIC_DEGREES: LocalizedChoices = {
    de: [
      {value: '1', label: 'Hauptschulabschluss'},
      {value: '2', label: 'Weiterführende Schule'},
      {value: '3', label: 'Abitur / Hochschulreife'},
      {value: '4', label: 'Keinen'}
    ],
    en: [
      {value: '1', label: 'Secondary Education Certificate'},
      {value: '2', label: 'Tertiary Education Certificate'},
      {value: '3', label: 'Matriculation Certificate'},
      {value: '4', label: 'None'}
    ],
    ru: [
      {value: '1', label: 'Школьное образование'},
      {value: '2', label: 'Бакалавр'},
      {value: '3', label: 'Магистр'},
      {value: '4', label: 'Никакое'}
    ],
    fr: [
      {value: '1', label: 'Diplôme marquant la fin de l’instruction obligatoire'},
      {value: '2', label: 'Diplôme d’enseignement professionnel'},
      {value: '3', label: 'Diplôme permettant d’accéder à l’enseignement supérieur'},
      {value: '4', label: 'Aucun diplôme'}
    ],
    es: [
      {value: '1', label: 'Graduado escolar'},
      {value: '2', label: 'Enseñanza secundaria obligatoria'},
      {value: '3', label: 'Bachillerato'},
      {value: '4', label: 'Ninguna'}
    ],
    it: [
      {value: '1', label: 'Licenza media'},
      {value: '2', label: 'Titolo di istruzione secondaria superiore'},
      {value: '3', label: 'Diploma di maturità'},
      {value: '4', label: 'Nessuno'}
    ],
    ka: [
      {value: '1', label: 'საშუალო განათლების სერთიფიკატი'},
      {value: '2', label: 'უმაღლესი განათლების სერთიფიკატი'},
      {value: '3', label: 'მაგისტრი'},
      {value: '4', label: 'არც ერთი'}
    ]
  };

  static readonly PROFESSIONS: LocalizedChoices = {
    de: [
      {value: '1', label: 'Student'},
      {value: '2', label: 'Hausfrau oder ähnlich'},
      {value: '3', label: 'Selbstständig'},
      {value: '4', label: 'Selbstständig als Berater, Trainer, Coach'},
      {value: '5', label: 'Beamter'},
      {value: '6', label: 'Fachkraft'},
      {value: '7', label: 'Führungskraft'},
      {value: '8', label: 'Rentner'},
      {value: '9', label: 'Sonstiges'}
    ],
    en: [
      {value: '1', label: 'Student'},
      {value: '2', label: 'Homemaker'},
      {value: '3', label: 'Self-employed'},
      {value: '4', label: 'Self-employed Consultant, Trainer, Coach'},
      {value: '5', label: 'Civil Servant'},
      {value: '6', label: 'Skilled Worker'},
      {value: '7', label: 'Executive'},
      {value: '8', label: 'Retiree'},
      {value: '9', label: 'Other'}
    ],
    ru: [
      {value: '1', label: 'Студент'},
      {value: '2', label: 'Безработный'},
      {value: '3', label: 'Частный предприниматель'},
      {value: '4', label: 'Частный предприниматель: консультант, тренер, коуч'},
      {value: '5', label: 'Чиновник'},
      {value: '6', label: 'Специалист'},
      {value: '7', label: 'Руководитель'},
      {value: '8', label: 'Пенсионер'},
      {value: '9', label: 'Другое'}
    ],
    fr: [
      {value: '1', label: 'Étudiant'},
      {value: '2', label: 'Au foyer ou comparable'},
      {value: '3', label: 'Indépendant(e)'},
      {value: '4', label: 'Indépendant(e) en tant que consultant, formateur, instructeur'},
      {value: '5', label: 'Fonctionnaire'},
      {value: '6', label: 'Employé(e) spécialisé(e)'},
      {value: '7', label: 'Cadre'},
      {value: '8', label: 'Retraité(e)'},
      {value: '9', label: 'Autre'}
    ],
    es: [
      {value: '1', label: 'Estudiante'},
      {value: '2', label: 'Ama de casa o similar'},
      {value: '3', label: 'Autónomo/a'},
      {value: '4', label: 'Autónomo/a como asesor/a, trainer o coach'},
      {value: '5', label: 'Funcionario/a'},
      {value: '6', label: 'Profesional cualificado'},
      {value: '7', label: 'Gerente'},
      {value: '8', label: 'Jubilado/a'},
      {value: '9', label: 'Otros'}
    ],
    it: [
      {value: '1', label: 'Studente'},
      {value: '2', label: 'Casalingo/a o simile'},
      {value: '3', label: 'Libero/a professionista'},
      {value: '4', label: 'Consulente, formatore/trice, coach freelance'},
      {value: '5', label: 'Funzionario/a'},
      {value: '6', label: 'Personale specializzato'},
      {value: '7', label: 'Dirigente'},
      {value: '8', label: 'Pensionato/a'},
      {value: '9', label: 'Altro'}
    ],
    ka: [
      {value: '1', label: 'სტუდენტი'},
      {value: '2', label: 'უმუშევარი'},
      {value: '3', label: 'ინდ. მეწარმე'},
      {value: '4', label: 'ინდ. მეწარმე კონსულტანტი, ტრენერი, ქოუჩი'},
      {value: '5', label: 'საჯარო მოხელე'},
      {value: '6', label: 'კვალიფიციური მუშაკი'},
      {value: '7', label: 'აღმასრულებელი'},
      {value: '8', label: 'პენსიონერი'},
      {value: '9', label: 'სხვა'}
    ]
  };

  static readonly INDUSTRIES: LocalizedChoices = {
    de: [
      {value: '1', label: 'Automobil / Automobilzulieferer'},
      {value: '2', label: 'Banken'},
      {value: '3', label: 'Baugewerbe / Architektur'},
      {value: '4', label: 'Beratung / Consulting'},
      {value: '5', label: 'Bildung / Universität / FH / Schulen'},
      {value: '6', label: 'Chemie'},
      {value: '7', label: 'Dienstleistung'},
      {value: '8', label: 'Druck / Papier / Verpackung'},
      {value: '9', label: 'EDV / IT'},
      {value: '10', label: 'Einkauf / Beschaffung'},
      {value: '11', label: 'Elektro / Elektronik'},
      {value: '12', label: 'Energiewirtschaft'},
      {value: '13', label: 'Finanzen'},
      {value: '14', label: 'Forschung / Entwicklung / Wissenschaft'},
      {value: '15', label: 'Gesundheitswesen / Soziales / Pflege'},
      {value: '16', label: 'Handel / Konsum'},
      {value: '17', label: 'Handwerk'},
      {value: '18', label: 'Immobilien / Facility Management'},
      {value: '19', label: 'Industrie'},
      {value: '20', label: 'Internet / Multimedia'},
      {value: '21', label: 'Kunst / Kultur / Unterhaltung'},
      {value: '22', label: 'Marketing / Werbung / PR'},
      {value: '23', label: 'Marktforschung'},
      {value: '24', label: 'Maschinen / Anlagenbau'},
      {value: '25', label: 'Medien'},
      {value: '26', label: 'Medizin / Pharma'},
      {value: '27', label: 'Medizintechnik'},
      {value: '28', label: 'Nahrungsmittel / Land / Forstwirtschaft'},
      {value: '29', label: 'Personalwesen / Personalbeschaffung'},
      {value: '30', label: 'Rechtsberatung'},
      {value: '31', label: 'Seminar / Messeanbieter'},
      {value: '32', label: 'Sonstige Branchen'},
      {value: '33', label: 'Sport / Fitness / Beauty'},
      {value: '34', label: 'Steuerberatung / Wirtschaftsprüfung'},
      {value: '35', label: 'Telekommunikation'},
      {value: '36', label: 'Textilbranche'},
      {value: '37', label: 'Tourismus / Hotel / Gastronomie'},
      {value: '38', label: 'Vereine'},
      {value: '39', label: 'Verkehr / Transport / Logistik'},
      {value: '40', label: 'Versicherung'},
      {value: '41', label: 'Verwaltung'}
    ],
    en: [
      {value: '1', label: 'Automotive / Automotive-Supplier'},
      {value: '2', label: 'Banking/Finance'},
      {value: '3', label: 'Construction / Architecture'},
      {value: '4', label: 'Consulting'},
      {value: '5', label: 'Education'},
      {value: '6', label: 'Chemistry'},
      {value: '7', label: 'Services'},
      {value: '8', label: 'Printing/Publishing'},
      {value: '9', label: 'IT'},
      {value: '10', label: 'Procurement'},
      {value: '11', label: 'Electronics'},
      {value: '12', label: 'Energy/Utilities'},
      {value: '13', label: 'Financial Services'},
      {value: '14', label: 'Scientific Industries/R&D'},
      {value: '15', label: 'Healthcare and Social Assistance'},
      {value: '16', label: 'Retail/Sales'},
      {value: '17', label: 'Handcraft'},
      {value: '18', label: 'Real Estate / Facility Management'},
      {value: '19', label: 'Manufacturing'},
      {value: '20', label: 'Internet / Multimedia'},
      {value: '21', label: 'Arts/Entertainment Industry'},
      {value: '22', label: 'Marketing/PR'},
      {value: '23', label: 'Market Research'},
      {value: '24', label: 'Mechanical / Plant Engineering'},
      {value: '25', label: 'Media'},
      {value: '26', label: 'Medicine / Pharmaceutics'},
      {value: '27', label: 'Medical Technology'},
      {value: '28', label: 'Food / Agriculture / Forestry'},
      {value: '29', label: 'Human Resources'},
      {value: '30', label: 'Legal Services'},
      {value: '31', label: 'Seminar/Trade Fair Hosting'},
      {value: '32', label: 'Other'},
      {value: '33', label: 'Sport / Fitness / Beauty'},
      {value: '34', label: 'Tax Advice / Audit'},
      {value: '35', label: 'Telecommunications'},
      {value: '36', label: 'Textiles'},
      {value: '37', label: 'Tourism / Hotel and Food Services'},
      {value: '38', label: 'Non-profit Association'},
      {value: '39', label: 'Transport / Logistics'},
      {value: '40', label: 'Insurance'},
      {value: '41', label: 'Administration'}
    ],
    ru: [
      {value: '1', label: 'Автомобильная промышленность'},
      {value: '2', label: 'Банковское дело'},
      {value: '3', label: 'Строительство / Архитектура'},
      {value: '4', label: 'Консалтинг'},
      {value: '5', label: 'Образование / Университет / Школы'},
      {value: '6', label: 'Химия'},
      {value: '7', label: 'Обслуживание'},
      {value: '8', label: 'Печать'},
      {value: '9', label: 'IT'},
      {value: '10', label: 'Поставки'},
      {value: '11', label: 'Электроника'},
      {value: '12', label: 'Энергетика'},
      {value: '13', label: 'Финансы'},
      {value: '14', label: 'Исследования / Разработка / Наука'},
      {value: '15', label: 'Здравоохранение'},
      {value: '16', label: 'Торговля'},
      {value: '17', label: 'Ремесло'},
      {value: '18', label: 'Недвижимость'},
      {value: '19', label: 'Промышленность'},
      {value: '20', label: 'Интернет / Мультимедиа'},
      {value: '21', label: 'Искусство / Культура'},
      {value: '22', label: 'Маркетинг / Реклама / PR'},
      {value: '23', label: 'Исследование рынка'},
      {value: '24', label: 'Машино- и заводостроение'},
      {value: '25', label: 'СМИ'},
      {value: '26', label: 'Медицина / Фармацевтика'},
      {value: '27', label: 'Медицинские технологии'},
      {value: '28', label: 'Продовольствие / Сельское хозяйство / Лесное хозяйство'},
      {value: '29', label: 'Работа с персоналом / Подбор персонала'},
      {value: '30', label: 'Юридические консультации'},
      {value: '31', label: 'Семинары / Провайдер выставок'},
      {value: '32', label: 'Другие отрасли'},
      {value: '33', label: 'Спорт / Фитнес / Красота'},
      {value: '34', label: 'Налоговый консалтинг / Аудит'},
      {value: '35', label: 'Телекоммуникации'},
      {value: '36', label: 'Текстильная промышленность'},
      {value: '37', label: 'Туризм / Гостиничный бизнес / Гастрономия'},
      {value: '38', label: 'Некоммерческие объединения'},
      {value: '39', label: 'Транспорт / Логистика'},
      {value: '40', label: 'Страхование'},
      {value: '41', label: 'Администрирование'}
    ],
    fr: [
      {value: '1', label: 'Constructeur automobile / équipementier'},
      {value: '2', label: 'Banque'},
      {value: '3', label: 'Bâtiment / architecture'},
      {value: '4', label: 'Conseil / consulting'},
      {value: '5', label: 'Éducation / université / enseignement supérieur / école primaire ou secondaire'},
      {value: '6', label: 'Chimie'},
      {value: '7', label: 'Services'},
      {value: '8', label: 'Imprimerie / papier / emballage'},
      {value: '9', label: 'Informatique'},
      {value: '10', label: 'Achats / approvisionnement'},
      {value: '11', label: 'Électricité / électronique'},
      {value: '12', label: 'Énergie'},
      {value: '13', label: 'Finances'},
      {value: '14', label: 'Recherche / développement / science'},
      {value: '15', label: 'Santé / social / soin à la personne'},
      {value: '16', label: 'Commerce / consommation'},
      {value: '17', label: 'Artisanat'},
      {value: '18', label: 'Immobilier / gestion immobilière'},
      {value: '19', label: 'Industrie'},
      {value: '20', label: 'Internet / multimédia'},
      {value: '21', label: 'Art / culture / divertissement'},
      {value: '22', label: 'Marketing / publicité / relations publiques'},
      {value: '23', label: 'Étude de marché'},
      {value: '24', label: 'Machines / construction d\'installations'},
      {value: '25', label: 'Médias'},
      {value: '26', label: 'Médecine / industrie pharmaceutique'},
      {value: '27', label: 'Technique médicale'},
      {value: '28', label: 'Agroalimentaire / agriculture / sylviculture'},
      {value: '29', label: 'Gestion de personnel / recrutement de personnel'},
      {value: '30', label: 'Conseil juridique'},
      {value: '31', label: 'Séminaire / salons'},
      {value: '32', label: 'Autres secteurs'},
      {value: '33', label: 'Sport / fitness / beauté'},
      {value: '34', label: 'Conseil fiscal / comptabilité / audit'},
      {value: '35', label: 'Télécommunications'},
      {value: '36', label: 'Textile'},
      {value: '37', label: 'Tourisme / hôtel / gastronomie'},
      {value: '38', label: 'Secteur associatif'},
      {value: '39', label: 'Transport / logistique'},
      {value: '40', label: 'Assurance'},
      {value: '41', label: 'Administration'}
    ],
    es: [
      {value: '1', label: 'Automovilística / Proveedor automovilístico'},
      {value: '2', label: 'Banca'},
      {value: '3', label: 'Construcción / Arquitectura'},
      {value: '4', label: 'Asesoría / Consultoría'},
      {value: '5', label: 'Educación / Universidades / Escuelas técnicas / Colegios'},
      {value: '6', label: 'Química'},
      {value: '7', label: 'Servicios'},
      {value: '8', label: 'Impresión / Papel / Embalaje'},
      {value: '9', label: 'Informática / Tecnologías de la información'},
      {value: '10', label: 'Adquisición / Aprovisionamiento'},
      {value: '11', label: 'Eléctrica / Electrónica'},
      {value: '12', label: 'Energética'},
      {value: '13', label: 'Financiera'},
      {value: '14', label: 'Investigación / Desarrollo / Ciencia'},
      {value: '15', label: 'Sanidad / Social / Geriátrica'},
      {value: '16', label: 'Comercio / Consumo'},
      {value: '17', label: 'Artesanal'},
      {value: '18', label: 'Inmobiliaria / Gestión de instalaciones'},
      {value: '19', label: 'Industria'},
      {value: '20', label: 'Internet / Multimedia'},
      {value: '21', label: 'Arte / Cultural / Entretenimiento'},
      {value: '22', label: 'Marketing / Publicidad / Relaciones Publicas'},
      {value: '23', label: 'Estudio de mercado'},
      {value: '24', label: 'Maquinaria / Constructoras'},
      {value: '25', label: 'Medios'},
      {value: '26', label: 'Medica / Farmacéutica'},
      {value: '27', label: 'Ingeniería medica'},
      {value: '28', label: 'Alimentaria / Agrícola / Silvicultura'},
      {value: '29', label: 'Recursos humanos / Reclutamiento'},
      {value: '30', label: 'Jurídica'},
      {value: '31', label: 'Seminarios / Proveedor de ferias'},
      {value: '32', label: 'Otros sectores'},
      {value: '33', label: 'Deporte / Gimnasios / Belleza'},
      {value: '34', label: 'Asesoría fiscal / Auditoria'},
      {value: '35', label: 'Telecomunicaciones'},
      {value: '36', label: 'Textil'},
      {value: '37', label: 'Turismo / Hotelería / Gastronomía'},
      {value: '38', label: 'Asociaciones'},
      {value: '39', label: 'Trafico / Transporte / Logística'},
      {value: '40', label: 'Seguros'},
      {value: '41', label: 'Administración'}
    ],
    it: [
      {value: '1', label: 'Automobile, Fornitura compenenti automobilistici'},
      {value: '2', label: 'Bancaria'},
      {value: '3', label: 'Edile/Architettura'},
      {value: '4', label: 'Consulenza/Consulting'},
      {value: '5', label: 'Formazione, Università'},
      {value: '6', label: 'Chimica'},
      {value: '7', label: 'Servizi'},
      {value: '8', label: 'Tipografia/Carta/Imballagi'},
      {value: '9', label: 'Informatica, IT'},
      {value: '10', label: 'Acquisti'},
      {value: '11', label: 'Eletricità /Elettronica'},
      {value: '12', label: 'Energia'},
      {value: '13', label: 'Finanziario'},
      {value: '14', label: 'Ricerca, Sviluppo, Scienza'},
      {value: '15', label: 'Sanità /Sociale/Cura'},
      {value: '16', label: 'Commercio/Consumo'},
      {value: '17', label: 'Artigianato'},
      {value: '18', label: 'Immobili/Manutenzione Immobili'},
      {value: '19', label: 'Industria'},
      {value: '20', label: 'Internet/Multimedia/Web'},
      {value: '21', label: 'Arte/Cultura/Intrattenimento'},
      {value: '22', label: 'Marketing/Pubblicità/PR'},
      {value: '23', label: 'Ricerca di mercato'},
      {value: '24', label: 'Macchinari/Costruzioni d\'impianti'},
      {value: '25', label: 'Media'},
      {value: '26', label: 'Medicina/Farmacologia'},
      {value: '27', label: 'Tecnologia medica'},
      {value: '28', label: 'Alimentari/Agricoltura/Silvicoltura'},
      {value: '29', label: 'Risorse umane/Assunzione personale'},
      {value: '30', label: 'Consulenza giuridica'},
      {value: '31', label: 'Seminari/Prestatore fiere'},
      {value: '32', label: 'Altro'},
      {value: '33', label: 'Sport/Fitness/Beauty'},
      {value: '34', label: 'Consulenza fiscale/Revisione contabile'},
      {value: '35', label: 'Telecomunicazione'},
      {value: '36', label: 'Tessile'},
      {value: '37', label: 'Turismo/Albergheria/Gastronomia'},
      {value: '38', label: 'Associazioni'},
      {value: '39', label: 'Traffico/Trasporto/Logistica'},
      {value: '40', label: 'Assicurazioni'},
      {value: '41', label: 'Amministrazione'}
    ],
    ka: [
      {value: '1', label: 'ავტომობილები / ავტომობილები-მიმწოდებელი'},
      {value: '2', label: 'საბანკო/საფინანსო'},
      {value: '3', label: 'სამშენებლო/არქიტექტურა'},
      {value: '4', label: 'საკონსულტაციო'},
      {value: '5', label: 'საგანმანათლებლო'},
      {value: '6', label: 'ქიმია'},
      {value: '7', label: 'მომსახურება'},
      {value: '8', label: 'ბეჭდვა / გამომცემლობა'},
      {value: '9', label: 'IT'},
      {value: '10', label: 'შესყიდვა'},
      {value: '11', label: 'ელექტროობა'},
      {value: '12', label: 'ენერგეტიკა/კომუნალური მომსახურება'},
      {value: '13', label: 'ფინანსური მომსახურება'},
      {value: '14', label: 'სამეცნიერო ინდუსტრიები/R&D'},
      {value: '15', label: 'ჯანდაცვა / სოც. დახმარება'},
      {value: '16', label: 'საცალო/გაყიდვები'},
      {value: '17', label: 'ხელნაკეთობა'},
      {value: '18', label: 'უძრავი ქონება'},
      {value: '19', label: 'წარმოება'},
      {value: '20', label: 'ინტერნეტი / მულტიმედია'},
      {value: '21', label: 'ხელოვნება / გასართობი ინდუსტრია'},
      {value: '22', label: 'მარკეტინგი / PR'},
      {value: '23', label: 'ბაზრის კვლევა'},
      {value: '24', label: 'მექანიკა / მცენარეთა ინჟინერია'},
      {value: '25', label: 'მედია'},
      {value: '26', label: 'სამედიცინო / ფარმაცევტული'},
      {value: '27', label: 'სამედიცინო ტექნოლოგია'},
      {value: '28', label: 'საკვები / სოფლის მეურნეობა / მეტყევეობა'},
      {value: '29', label: 'ადამიანური რესურსები (HR)'},
      {value: '30', label: 'იურიდიული მომსახურება'},
      {value: '31', label: 'ღონისძიების/ სავაჭრო ბაზრობის ორგანიზება'},
      {value: '32', label: 'სხვა'},
      {value: '33', label: 'სპორტი / ფიტნესი / სილამაზე'},
      {value: '34', label: 'საგადასახადო / აუდიტი'},
      {value: '35', label: 'ტელეკომუნიკაცია'},
      {value: '36', label: 'ტექსტილი'},
      {value: '37', label: 'ტურიზმი / ჰორეკა'},
      {value: '38', label: 'არასამეწარმეო საქმიანობა'},
      {value: '39', label: 'ტრანსპორტი / ლოგისტიკა'},
      {value: '40', label: 'დაზღვევა'},
      {value: '41', label: 'ადმინისტრირება'}
    ]
  };

  static topCountries(locale: string = 'en'): Choice[] {
    return typeof this.TOP_COUNTRIES[locale] === typeof undefined
      ? [...this.TOP_COUNTRIES.en]
      : [...this.TOP_COUNTRIES[locale]];
  }

  static topNationalities(locale: string = 'en'): Choice[] {
    return typeof this.TOP_NATIONALITIES[locale] === typeof undefined
      ? [...this.TOP_NATIONALITIES.en]
      : [...this.TOP_NATIONALITIES[locale]];
  }

  static translatedLanguages(locale: string = 'en'): Choice[] {
    return typeof this.TRANSLATED_LANGUAGES[locale] === typeof undefined
      ? [...this.TRANSLATED_LANGUAGES.en]
      : [...this.TRANSLATED_LANGUAGES[locale]];
  }

  static genders(locale: string = 'en'): Choice[] {
    const data = typeof this.GENDERS[locale] === typeof undefined
      ? this.GENDERS.en
      : this.GENDERS[locale];
    return [...data];
  }

  static variations(): Choice[] {
    return [...this.VARIATIONS];
  }

  static academicDegrees(locale: string = 'en'): Choice[] {
    const data = typeof this.ACADEMIC_DEGREES[locale] === typeof undefined
      ? this.ACADEMIC_DEGREES.en
      : this.ACADEMIC_DEGREES[locale];
    return [...data];
  }

  static professions(locale: string = 'en'): Choice[] {
    const data = typeof this.PROFESSIONS[locale] === typeof undefined
      ? this.PROFESSIONS.en
      : this.PROFESSIONS[locale];
    return [...data];
  }

  static industries(locale: string = 'en'): Choice[] {
    const data = typeof this.INDUSTRIES[locale] === typeof undefined
      ? this.INDUSTRIES.en
      : this.INDUSTRIES[locale];
    return [...data];
  }

}
