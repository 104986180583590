<div class="customer-details">

  <!--
  'analysisForAddition' next to the client's name (without a space) is due to grammatical requirements of georgian.
  For other languages it's empty
  -->
  <h3 style="margin-bottom: 1.5em">
    {{__('analysisFor')}}
    {{clientAccountData.jobAndEducation.academicTitle}}
    {{clientAccountData.personalData.firstName}} {{clientAccountData.personalData.lastName}}{{__('analysisForAddition')}}
  </h3>

  <div class="clr-row text-light">

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('email')}}:</span> {{clientAccountData.personalData.email}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('gender')}}:</span> {{clientAccountData.personalData.gender}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('academicDegree')}}:</span> {{clientAccountData.jobAndEducation.academicDegree}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('origin')}}:</span> {{clientAccountData.origin.origin}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('dateOfBirth')}}:</span> {{clientAccountData.origin.dateOfBirth | date}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('residence')}}:</span> {{clientAccountData.address.residence}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('city')}}:</span> {{clientAccountData.address.city}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('industry')}}:</span> {{clientAccountData.jobAndEducation.industry}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('company')}}:</span> {{clientAccountData.jobAndEducation.company}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('profession')}}:</span> {{clientAccountData.jobAndEducation.profession}}
    </div>

    <div class="clr-col-xl-4 clr-col-md-6 clr-col-xs-12">
      <span class="key">{{__('mobilePhoneNumber')}}:</span> {{clientAccountData.personalData.mobilePhone}}
    </div>

  </div>

</div>
