import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {MasterService} from '../../master.service';
import {Customer} from '../../my-customers/types/customer';
import {Group} from '../../my-customers/types/group-type';
import {CustomerlistComponent, SelectionEvent} from '../../../shared/customerlist/customerlist.component';
import {TeamanalysisService} from '../../teamanalysis.service';
import {Test} from '../../../test/state/test';
import {Router} from '@angular/router';
import {MotiveScores} from '../../../test/state/motive-score';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-teamanalysis',
  templateUrl: './teamanalysis.component.html',
  styleUrls: ['./teamanalysis.component.scss']
})
// TODO: TO BE DELETED ?
export class TeamAnalysisComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerlistComponent, {static: true})
    customerListComponent: CustomerlistComponent;
  selectedCustomers: SelectedCustomerAndTest[] = [];
  teamEnabled: boolean = environment.TEAM;
  customers$: Observable<Customer[]>;
  customers: Customer[] = [];
  customersView: Customer[] = [];
  groups$: Observable<Group[]>;
  metaSearch = '';
  len = 0;
  page = 1;
  lastPage = 1;
  pageSize = 6;
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private masterService: MasterService,
              private currentUser: CurrentUser,
              private router: Router,
              private translatorService: TranslatorService,
              private teamAnalysisService: TeamanalysisService) {
  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refreshData(): void {
    this.loadCustomers();
    this.loadGroups();
  }

  loadCustomers(): void {
    this.customers$ = this.masterService.loadCustomers()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(customers => customers.filter(customer => customer.hasFinishedTests())),
        map((data: Customer[]) => data.sort((clientA, clientB) => clientB.creationDate.getTime() - clientA.creationDate.getTime()))
      );

    this.customers$
      .subscribe((data: Customer[]) => {
        this.customers = data;

        if (this.customers && this.customers.length > 0) {
          this.customersView = this.customers;
          this.len = this.customers.length;
          this.lastPage = Math.ceil(this.len / this.pageSize);
        }
      });

  }

  loadGroups(): void {
    this.groups$ = this.masterService.loadGroups();
  }

  compare(): void {
    this.teamAnalysisService.downloadExcel(this.selectedCustomers);
  }

  toChart(): void {
    const data: ChartData[] = [];
    this.selectedCustomers.forEach(s => data.push({
      name: s.selectedCustomer.fullName,
      motiveScore: s.selectedTest.result.motiveScores
    }));
    this.router.navigateByUrl('/chart', {
      state: {
        chartData: data
      }
    });
  }

  onSelection(event: SelectionEvent): void {
    if (event.selected === true) {
      this.selectCustomer(event.customer);
    }
  }

  selectCustomer(customer: Customer): void {
    this.selectedCustomers.push({
      selectedCustomer: customer,
      selectedTest: customer.getFirstTest()
    } as SelectedCustomerAndTest);
  }

  unselectCustomer(customer: Customer): void {
    if (this.isSelected(customer)) {
      const index: number = this.selectedCustomers.findIndex(selectedCustomerAndTest =>
        selectedCustomerAndTest.selectedCustomer.keycloakId === customer.keycloakId);
      if (index > -1) {
        this.selectedCustomers.splice(index, 1);
        this.customerListComponent.unselectCustomer(customer);
      }
    }
  }

  isSelected(customer: Customer): boolean {
    return this.customerListComponent.isSelected(customer);
  }

  __(key: string): string {
    return this.translatorService.translate('master.teamanalysis.' + key);
  }

}

export interface SelectedCustomerAndTest {
  selectedCustomer: Customer;
  selectedTest: Test;
}

export interface ChartData {
  name: string;
  motiveScore: MotiveScores;
}
