import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Friendship} from '../../../friend/state/friendship';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FriendshipFunctions} from '../../../friend/state/friendship-functions';
import {SuperlikeType} from '../../superlike-type';
import {SuperlikeRepresentation} from '../../../friend/state/superlike-representation';
import {CurrentUser} from '../../../user/current-user.service';
import {FriendshipService} from '../../../friend/state/friendship.service';
import {TranslatorService} from '../../../translation/translator.service';
import {AccountData} from '../../../account/account-data';
import {AccountDataQuery} from '../../../account/account-data-query';
import {Id37Router} from '../../../id37-router';

@Component({
  selector: 'app-superlike',
  templateUrl: './superlike.component.html',
  styleUrls: ['./superlike.component.scss']
})
export class SuperlikeComponent implements OnInit {

  @Input()
    friendship: Friendship;

  @Input()
    friendId: string;

  @Input()
    friendFullName: string;

  @Input()
    friendFirstname: string;

  @Input()
    friendGender: string;

  @Input()
    distance: number;

  @Output()
    clickedMaster = new EventEmitter();

  masterInfo: string = undefined;

  clickedReaction: boolean;
  form: FormGroup;

  constructor(private accountDataQuery: AccountDataQuery,
              private currentUser: CurrentUser,
              private friendshipService: FriendshipService,
              private id37Router: Id37Router,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required)
    });
    this.accountDataQuery.selectAccountDataForCurrentUser()
      .subscribe((accountData: AccountData) => {
        if (!!accountData && !!accountData.masterInfo) {
          this.masterInfo = this.__('differences.tip3_A') + `<a href=mailto:${accountData.masterInfo.email}>
                    <strong>${accountData.masterInfo.firstName} ${accountData.masterInfo.lastName}</strong></a>`;
        }
      });
  }

  clickReaction(): void {
    this.form.reset();
    this.clickedReaction = !this.clickedReaction;
  }

  sendSuperLike() {
    const friendId: string = FriendshipFunctions.getFriendUserId(this.friendship, this.currentUser.keycloakId);
    let superlike: SuperlikeType;
    switch (this.form.value.subject) {
      case 'APPRECIATE':
        superlike = SuperlikeType.I_APPRECIATE_YOU_A_LOT;
        break;
      case 'INTERESTING':
        superlike = SuperlikeType.INTERESTING_PERSONALITY;
        break;
      case 'POTENTIAL':
        superlike = SuperlikeType.WE_HAVE_A_LOT_OF_POTENTIAL;
        break;
      case 'TALK':
        superlike = SuperlikeType.LETS_TALK;
        break;
      default:
        throw new Error();
    }
    this.friendshipService.sendSuperLike(new SuperlikeRepresentation(friendId, superlike, this.form.value.message as string))
      .subscribe(() => {
      },
      error => {
        console.error('Error: ', error);
      }
      );
    this.clickReaction();
    this.form.reset();
  }

  hasErrors(): boolean {
    return !!this.form.get('subject').errors || !!this.form.get('message').errors;
  }

  routeToFriendReport(): void {
    this.id37Router.routeToReport(
      FriendshipFunctions.getFriendUserId(this.friendship, this.currentUser.backendUser.keycloakId),
      FriendshipFunctions.getFriendTestId(this.friendship, this.currentUser.backendUser.keycloakId));
  }

  routeToFriendKeyGraphic(): void {
    this.id37Router.routeToKeyGraphicOfUser(
      FriendshipFunctions.getFriendUserId(this.friendship, this.currentUser.backendUser.keycloakId),
      FriendshipFunctions.getFriendTestId(this.friendship, this.currentUser.backendUser.keycloakId),
      this.friendFullName);
  }

  clickMaster(): void {
    this.clickedMaster.emit();
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('superLike.' + key);
    revisedTranslation = revisedTranslation.replace(/\*FIRSTNAME\*/g, this.friendFirstname);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
