import {DiscountCodeRepresentation} from './discount-code-representation';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CurrentUser} from '../../user/current-user.service';

export class DiscountCode {

  private readonly _code: string;
  private readonly _discountAsNumber: number;
  private readonly _discountAsPercentage: string;
  private readonly _active: boolean;
  private readonly _activeFrom: Moment | undefined;
  private readonly _activeUntil: Moment | undefined;
  private readonly _maximumUses: number | undefined;
  private readonly _usable: boolean;
  private readonly _uses: number;
  private readonly _usesLeft: number;
  private readonly _category: string;
  private readonly _description: string | undefined;
  private readonly _createdAt: Moment | undefined;
  private readonly _softDeleted: boolean;
  private readonly _userMail: string;
  private readonly _affiliateMail: string;

  constructor(code: string,
              discount: number,
              percentage: string,
              active: boolean,
              activeFrom: Moment,
              activeUntil: Moment,
              maximumUses: number,
              usable: boolean,
              uses: number,
              usesLeft: number,
              category: string,
              description: string,
              createAt: Moment,
              softDeleted: boolean,
              userMail: string,
              affiliateMail: string) {
    this._code = code;
    this._discountAsNumber = discount;
    this._discountAsPercentage = percentage;
    this._active = active;
    this._activeFrom = activeFrom;
    this._activeUntil = activeUntil;
    this._maximumUses = maximumUses;
    this._usable = usable;
    this._uses = uses;
    this._usesLeft = usesLeft;
    this._category = category;
    this._description = description;
    this._createdAt = createAt;
    this._softDeleted = softDeleted;
    this._userMail = userMail;
    this._affiliateMail = affiliateMail;
  }

  get code(): string {
    return this._code;
  }

  get discountAsNumber(): number {
    return this._discountAsNumber;
  }

  get discountAsPercentage(): string {
    return this._discountAsPercentage;
  }

  get active(): boolean {
    return this._active;
  }

  get activeFrom(): Moment | undefined {
    return this._activeFrom;
  }

  get activeUntil(): Moment | undefined {
    return this._activeUntil;
  }

  get maximumUses(): number | undefined {
    return this._maximumUses;
  }

  get usable(): boolean {
    return this._usable;
  }

  get uses(): number {
    return this._uses;
  }

  get usesLeft(): number {
    return this._usesLeft;
  }

  get category(): string {
    return this._category;
  }

  get description(): string | undefined {
    return this._description;
  }

  get createdAt(): Moment | undefined {
    return this._createdAt;
  }

  get softDeleted(): boolean {
    return this._softDeleted;
  }

  get userMail(): string {
    return this._userMail;
  }

  get affiliateMail(): string {
    return this._affiliateMail;
  }

  static buildFrom(
    discountCodeRepresentation: DiscountCodeRepresentation,
    currentUser: CurrentUser
  ): DiscountCode {
    return new DiscountCode(discountCodeRepresentation.code,
      Number(discountCodeRepresentation.discountAsNumber.replace(',', '.')),
      discountCodeRepresentation.discountAsPercentage,
      Boolean(discountCodeRepresentation.active),
      discountCodeRepresentation.activeFrom != null
        ? moment(discountCodeRepresentation.activeFrom).locale(currentUser.locale)
        : undefined,
      discountCodeRepresentation.activeUntil != null
        ? moment(discountCodeRepresentation.activeUntil).locale(currentUser.locale)
        : undefined,
      discountCodeRepresentation.maximumUses != null
        ? Number(discountCodeRepresentation.maximumUses)
        : undefined,
      Boolean(discountCodeRepresentation.usable),
      Number(discountCodeRepresentation.uses),
      Number(discountCodeRepresentation.usesLeft),
      discountCodeRepresentation.category,
      discountCodeRepresentation.description || undefined,
      discountCodeRepresentation.createdAt != null
        ? moment(discountCodeRepresentation.createdAt).locale(currentUser.locale)
        : undefined,
      Boolean(discountCodeRepresentation.softDeleted),
      String(discountCodeRepresentation.userMail),
      discountCodeRepresentation.affiliateMail
    );
  }
}
