import {Component} from '@angular/core';

@Component({
  selector: 'app-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss'],
  standalone: true
})
export class ServiceUnavailableComponent {

  constructor() {
  }

}
