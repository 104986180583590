export enum PaymentMethod {

  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAYPAL'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PaymentMethod {
  export const LIST: PaymentMethod[] = [
    PaymentMethod.CREDIT_CARD,
    PaymentMethod.PAYPAL
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): PaymentMethod | undefined => {
    name = !!name ? name : '';
    const found = PaymentMethod.LIST.find((method: string) => method.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a payment method with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

