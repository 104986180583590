import {Component, Input} from '@angular/core';
import {AccountData} from '../../account/account-data';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent {

  @Input()
    clientAccountData: AccountData;

  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate('report.clientInfo.' + key);
  }

}
