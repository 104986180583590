import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ProfilePictureStore} from './profile-picture-store';
import {CurrentUser} from '../user/current-user.service';
import {Observable, throwError} from 'rxjs';
import {ProfilePicture} from './profile-picture';
import {catchError, map} from 'rxjs/operators';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureLoadService {

  constructor(private profilePictureStore: ProfilePictureStore,
              private currentUser: CurrentUser,
              private http: HttpClient) {
  }

  loadProfilePictureForCurrentUser(): Observable<ProfilePicture> {
    return this.loadProfilePicture(this.currentUser.keycloakId);
  }

  loadProfilePicture(userId: string): Observable<ProfilePicture> {
    return this.http.get(
      API_URL + '/user/' + userId + '/profilepicture',
      {
        headers: new HttpHeaders({
          Accept: 'text/plain; charset=UTF-8'
        }),
        responseType: 'text'
      }
    ).pipe(map((base64Data: string) => {
      const newState: ProfilePicture = {
        base64: base64Data
      };
      this.profilePictureStore.upsert(userId, () => newState);
      return newState;
    }),
    catchError(
      (error: HttpErrorResponse): Observable<ProfilePicture> => {
        console.error(error);
        return throwError(error);
      }
    ));
  }

}
