import {ChangeDetectionStrategy, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {TeamUser} from '../../../team/state/team-user';
import {TeamFunctions} from '../../../team/state/team-functions';
import {Layout} from '../../../team/team.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-team-graphic-nav-bubbles',
  templateUrl: './team-graphic-nav-bubbles.component.html',
  styleUrls: ['./team-graphic-nav-bubbles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamGraphicNavBubblesComponent implements OnInit {

  @Input() layout: Layout;

  @ViewChild('scrollViewport')
  private cdkVirtualScrollViewport;

  _members: TeamUser[];
  displayingMembers: TeamUser[];
  hiddenMembers: TeamUser[];

  maxDisplayingBubbles: number = 9;

  // hidden bubbles
  yStart: number = 2;
  yBaseStep: number = 47;

  open: boolean = false;
  hide: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input()
  set members(users: TeamUser[]) {
    this._members = users;
    this.getScreenSize();
    this.computeBubbles();
  }

  computeBubbles(): void {
    // Needed the reverse order to display the bubbles correctly: Overlapping SVGs will show the last added completely
    // const copy = [...this._members];
    // const reversedMembers = copy.reverse();
    if (this._members.length <= this.maxDisplayingBubbles) {
      this.displayingMembers = this._members;
    }
    else {
      this.displayingMembers = this._members.slice(0, this.maxDisplayingBubbles - 1);
      this.hiddenMembers = this._members.slice(this.maxDisplayingBubbles - 1);
      //
      // this.xStart = 68;
    }
  }

  computeBubblePos(index: number): string {
    return `-${index * 7}px`;
  }

  computeWrapperPos(): string {
    return `${(this.displayingMembers.length - 1) * 6}px`;
  }

  calculateHiddenBubblePos(index: number): string {
    return `top: ${this.yStart + this.yBaseStep * index}px`;
  }

  getNameInitial(m: TeamUser): string {
    return TeamFunctions.getNameInitials(m.firstName, m.lastName);
  }

  triggerDropdown(): void {
    this.open = !this.open;
    window.dispatchEvent(new Event('resize'));
  }

  closeDropdown(): void {
    this.open = false;
  }

  toggleEye(): void {
    this.open = false;
  }

  amountOfMembers() {
    return this._members.length;
  }

  calculateDropdownHeight(): number {
    const ROW_HEIGHT_IN_PIXEL = this.yBaseStep;
    setTimeout(() => {
      this.cdkVirtualScrollViewport.checkViewportSize();
    }, 20);
    return Math.min(ROW_HEIGHT_IN_PIXEL * this.hiddenMembers.length, 9 * ROW_HEIGHT_IN_PIXEL);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    const oldMaxDisplayedMember = this.maxDisplayingBubbles;
    this.maxDisplayingBubbles = window.innerWidth < 1000 ? 9 : 15;
    if (this.maxDisplayingBubbles !== oldMaxDisplayedMember) {
      this.computeBubbles();
    }
  }

}
