<div class="search-input-container">

  <input (ngModelChange)='searchInputChanged.next($event)' [(ngModel)]="searchKey" maxlength="100"
         placeholder="{{__('searchInputPlaceholder')}}"
         title="Search"
         type="search">

  <button (click)="abortSearch()" *ngIf="searchKey.length !== 0" class="id37-btn id37-btn-sm id37-btn-secondary">
    <img alt="Abort search" src="assets/icons/ID37_X.svg">
  </button>

  <button *ngIf="searchKey.length === 0" class="id37-btn id37-btn-sm id37-btn-secondary">
    <img alt="search" src="assets/icons/arrow_link_12x12.svg"/>
  </button>

</div>
