<div *ngIf="isOpen" [@openClose] class="customer-list-container">

  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-12 invite-title">
      <span>{{__('addClients')}}</span>
    </div>
  </div>

  <app-new-customers-list (validate)="checkValid()"
                          [customerFormArray]="newCustomerFormArray"
                          [groups$]="groups$"></app-new-customers-list>

  <div class="clr-row send-area">
    <div class="clr-col-6 clr-justify-content-start">
      <button (click)="addRow()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 add-button">
        <img src="assets/icons/close_circle.svg" alt="plus symbol"/>
        <span>{{__('addClient')}}</span>
      </button>
    </div>
    <div class="clr-col-6 clr-justify-content-end">
      <button
          (click)="sendRows()"
          [disabled]="!newCustomerFormArrayValid"
          class="id37-btn-outline send-button">
        <img src="assets/icons/send_invitation.svg" alt="letter symbol"/>
        <span>{{__('sendInvitations')}}</span>
      </button>
    </div>
  </div>
</div>
