import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable()
export class Id37AuthGuard extends KeycloakAuthGuard {

  constructor(protected router: Router,
              protected keycloakService: KeycloakService) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + state.url
      });
    }

    // Get the roles from the route, from which at least one must be present.
    let anyRoleRequired: string | string[] | undefined = route.data.anyRoleRequired;
    let anyRolePresent = true;
    if (!!anyRoleRequired) {
      if (typeof anyRoleRequired === 'string' || anyRoleRequired instanceof String) {
        anyRoleRequired = [anyRoleRequired as string];
      }
      if (anyRoleRequired.length !== 0) {
        anyRolePresent = anyRoleRequired.some((role) => this.roles.includes(role));
      }
    }

    // Get the roles from the route, which must all be present.
    let allRolesRequired: string | string[] | undefined = route.data.allRolesRequired;
    let allRolesPresent = true;
    if (!!allRolesRequired) {
      if (typeof allRolesRequired === 'string' || allRolesRequired instanceof String) {
        allRolesRequired = [allRolesRequired as string];
      }
      if (allRolesRequired.length !== 0) {
        allRolesPresent = allRolesRequired.every((role) => this.roles.includes(role));
      }
    }

    const accessAllowed = anyRolePresent && allRolesPresent;
    if (!accessAllowed) {
      await this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }

}
