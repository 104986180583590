import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {ActivatedRoute} from '@angular/router';
import {Friendship} from '../state/friendship';
import {FriendshipQuery} from '../state/friendship.query';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FriendshipFunctions} from '../state/friendship-functions';
import {TranslatorService} from '../../translation/translator.service';
import {Friend} from '../state/friend';
import {TestQuery} from '../../test/state/test.query';
import {MotiveScores} from '../../test/state/motive-score';
import {AccountDataService} from '../../account/account-data-service';
import {Id37Router} from '../../id37-router';

@Component({
  selector: 'app-friend-compare',
  templateUrl: './friend-compare-site.component.html',
  styleUrls: ['./friend-compare-site.component.scss']
})
export class FriendCompareSiteComponent implements OnInit, OnDestroy {

  currentUserMotiveScores: MotiveScores;
  friendMotiveScores: MotiveScores;
  friendship: Friendship | undefined = undefined;
  friend: Friend | undefined = undefined;
  friendFirstName: string = '';
  friendFullName: string;
  friendGender: '' | 'male' | 'female' | 'diverse' = undefined;
  distance: number = 0.0;
  currentRingProgress: number = 0.0;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private accountDataService: AccountDataService,
              private activatedRoute: ActivatedRoute,
              private currentUser: CurrentUser,
              private friendshipQuery: FriendshipQuery,
              private router: Id37Router,
              private testQuery: TestQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.friendshipQuery
        .selectFriendship(Number(params.friendshipId))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((friendship) => {
          if (!!friendship) {
            this.friendship = friendship;
            this.friend = FriendshipFunctions.getFriend(this.friendship, this.currentUser.keycloakId);
            this.friendFirstName = this.friend.firstName;
            this.friendFullName = (this.friend.firstName + ' ' + this.friend.lastName).trim();
            const testResultDistance = FriendshipFunctions.getDistanceBetweenNewestTestShares(this.friendship);
            if (!!testResultDistance) {
              this.distance = testResultDistance.skewedDistance;
            }
            else {
              this.distance = 0.0;
            }

            this.accountDataService.load(this.friend.id).pipe(takeUntil(this.unsubscribe$))
              .subscribe(accountData => this.friendGender = accountData.personalData.gender);

            this.loadMotiveScores(FriendshipFunctions.getMyTestId(this.friendship, this.currentUser.keycloakId),
              FriendshipFunctions.getFriendTestId(this.friendship, this.currentUser.keycloakId));
          }
        });
    });
  }

  loadMotiveScores(currentUserTestId: string, friendTestId: string) {
    this.testQuery.selectTest(this.currentUser.keycloakId, currentUserTestId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((test) => this.currentUserMotiveScores = test.result.motiveScores);

    this.testQuery.selectTest(this.friend.id, friendTestId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((test) => this.friendMotiveScores = test.result.motiveScores);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('friendCompareSite.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.___('accusative.f/pl', this.friendGender));
    return revisedTranslation;
  }

  close() {
    this.router.routeToFriends();
  }

  ___(key: string, friendGender: string): string {
    return this.translatorService.translate('possessivePronoun.' + key, this.currentUser.locale, friendGender);
  }

}
