<ng-container *ngIf="!!friendship || !!user; else templateRecommend">
  <!-- MENU ACTIONS -->
  <ng-container *ngIf="friendship">
    <div class="friend-menu-container">
      <clr-dropdown>
        <clr-icon clrDropdownTrigger shape="ellipsis-horizontal" style="padding: 0 0 0 0.4em;"></clr-icon>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">

          <ng-container *ngIf="!!friendship">
            <div (click)="updateTestShare.emit(this.friendship)"
                 *ngIf="this.shareStatus === 'motiveSelection' || this.shareStatus === 'done'"
                 clrDropdownItem>
              {{__('updateTestShare')}}
            </div>
            <div (click)="deleteFriendship.emit(this.friendship)"
                 *ngIf="friendship.computedStatus === 'friends'"
                 clrDropdownItem>
              {{__('deleteFriendship')}}
            </div>
            <div
                (click)="declineFriendship.emit(this.friendship)"
                *ngIf="friendship.computedStatus === 'pending' || friendship.computedStatus === 'requested'"
                clrDropdownItem>
              {{__('deleteRequest')}}
            </div>
          </ng-container>

        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </ng-container>

  <!-- NAME -->
  <ng-container *ngIf="!friendship">
    <div class="friend-name">
      <span>{{user.firstName + ' ' + user.lastName}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!!friendship">
    <div (click)="openCompare()" class="friend-name">
      {{friendName}}
    </div>
  </ng-container>

  <!-- PROFILE PICTURE -->
  <id37-avatar-distance-circle
      (click)="openCompare()"
      *ngIf="!friendship"
      [circularRingRender]="false"
      [profilePictureAllowAccountDataRetrieval]="false"
      [profilePictureUserId]="user.keycloakId">
  </id37-avatar-distance-circle>

  <id37-avatar-distance-circle
      (circularRingProgressChange)="currentRingProgress = $event"
      (click)="openCompare()"
      *ngIf="!!friendship"
      [circularRingProgress]="distance"
      [circularRingRender]="friendship.computedStatus === 'friends'"
      [profilePictureUserId]="friendUserId">
  </id37-avatar-distance-circle>

  <!-- ACTIONS / STATE -->
  <ng-container *ngIf="!loading; else templateLoading">
    <div (click)="openCompare()" *ngIf="!!friendship && friendship.computedStatus !== 'friends'"
         class="state-nofriends-container">
      <div class=" friend-email">
        <span *ngIf="!!friendEmail">{{friendEmail}}</span>
      </div>
      <div *ngIf="friendship.computedStatus === 'requested'">{{__('text.notApproved')}}</div>

      <div class="arrow-text-container">
        <img alt="orange arrow" src="assets/icons/arrow_orange_18x18.svg">
        <div (click)="resendFriendshipRequest.emit(this.friendship)"
             *ngIf="friendship.computedStatus === 'requested'">
          {{__('action.requestAgain')}}
        </div>
        <div (click)="acceptFriendship.emit(this.friendship)" *ngIf="friendship.computedStatus === 'pending'">
          {{__('action.acceptRequest')}}
        </div>
      </div>
    </div>

    <div (click)="openCompare()"
         *ngIf="!!friendship && friendship.computedStatus === 'friends'" class="state-friends-container">

      <ng-container *ngIf="friendship.testShares.length == 2; else inWork">
        <div class="distance-index-text-container">
          <div class="distance-index">
            <div class="distance">
              <span>{{currentRingProgress * 100.0 | number:'1.0-0'}}</span>
              <span class="percentage">%</span>
            </div>
          </div>
          <div class="diffText">{{__('different')}}</div>
        </div>
        <img alt="" src="assets/icons/arrow_green_18x18.svg">
      </ng-container>

      <ng-template #inWork>
        <ng-container [ngSwitch]="shareStatus">
          <div (click)="routeToDashboard()" *ngSwitchCase="'noTest'" class="clickAble">
            <img alt="orange arrow" src="assets/icons/arrow_orange_18x18.svg">
            <span>{{__('state.noTest')}}</span>
          </div>

          <div (click)="routeToDashboard()" *ngSwitchCase="'unfinishedTest'" class="clickAble">
            <img alt="orange arrow" src="assets/icons/arrow_orange_18x18.svg">
            <span>{{__('state.unfinishedTest')}}</span>
          </div>

          <div (click)="updateTestShare.emit(this.friendship)" *ngSwitchCase="'motiveSelection'"
               class="clickAble">
            <img alt="orange arrow" src="assets/icons/arrow_orange_18x18.svg">
            <span>{{__('state.motiveSelection')}}</span>
          </div>

          <div *ngSwitchCase="'grantTest'" class="inProgress">{{__('state.grantTest')}}</div>
          <div *ngSwitchCase="'done'" class="inProgress">{{__('state.inProgress')}}</div>
        </ng-container>
      </ng-template>

    </div>

    <div *ngIf="!friendship" class="state-nofriends-container">
      <div (click)="createFriendship.emit(this.user)"
           class="arrow-text-container" style="margin-top: 2.9em">
        <img alt="" src="assets/icons/arrow_orange_18x18.svg">
        <div>{{__('action.sendRequest')}}</div>
      </div>
    </div>
  </ng-container>

  <ng-template #templateLoading>
    <app-loading-indicator [fontSize]="'0.7em'" style="margin-top: 1em"></app-loading-indicator>
  </ng-template>
</ng-container>

<ng-template #templateRecommend>
  <div class="recommend">
    <button (click)="recommend.emit()">
      <img alt="thumbs up" class="thumbs-up-icon" src="assets/icons/ID37_Empfehlen_100x100.svg"/>
      <img alt="orange arrow" src="assets/icons/arrow_orange_18x18.svg">
      <span class="recommend-text"> {{__('recommend')}} </span>
    </button>
  </div>
</ng-template>
