import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BubbleScore, BubbleScoreFunctions, HiddenBubbleScore} from '../../../../../team/state/bubble-score';
import {TeamFunctions} from '../../../../../team/state/team-functions';
import {Layout} from '../../../../../team/team.component';
import {TeamService} from '../../../../../team/state/team.service';
import {Subscription} from 'rxjs';
import {TranslatorService} from '../../../../../translation/translator.service';

@Component({
  selector: 'app-team-graphic-motive',
  templateUrl: './team-graphic-motive.component.html',
  styleUrls: ['./team-graphic-motive.component.scss']
})
export class TeamGraphicMotiveComponent implements OnInit, OnDestroy {

  @Input() teamId: string;

  @Input() motiveName: string = '';

  @Input() layout: Layout;

  @Input() numberOfTeamMembers: number;

  specificBubbleScores: BubbleScore[];
  displayingBubbleScores: BubbleScore[];
  hiddenBubbleScores: HiddenBubbleScore[];

  previousTeamId: string;
  show: boolean = true;

  isLinebreak: boolean;

  linebreakText: string[];

  resetSub: Subscription;

  constructor(private teamService: TeamService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.resetSub = this.teamService.resetView.subscribe(() => this.show = true);
    this.checkLineBreak();
  }

  ngOnDestroy(): void {
    this.resetSub.unsubscribe();
  }

  @Input()
  set setSpecificBubbleScores(bubbleScores: BubbleScore[]) {
    if (!!bubbleScores) { // if not undefined
      this.specificBubbleScores = bubbleScores;
      this.computeBubbleScores();
      if (!this.previousTeamId || this.previousTeamId !== this.teamId) {
        this.previousTeamId = this.teamId;
        this.show = true;
      }
    }
    else {
      this.displayingBubbleScores = undefined;
      this.hiddenBubbleScores = undefined;
    }

  }

  checkLineBreak(): void {
    this.linebreakText = this.motiveName.split(' ');
    if (this.linebreakText.length > 2) {
      return console.error('secret motive !?');
    }

    if (this.linebreakText.length === 2) {
      this.isLinebreak = true;
    }
  }

  computeBubbleScores(): void {
    const hiddenIdx: number[] = BubbleScoreFunctions.getHiddenBubbleScoreColorIndexes(this.specificBubbleScores);
    if (hiddenIdx.length) {
      this.displayingBubbleScores = this.specificBubbleScores.filter(
        (b: BubbleScore) => !hiddenIdx.includes(b.colorIndex));
      const hidden = this.specificBubbleScores.filter(
        (b: BubbleScore) => hiddenIdx.includes(b.colorIndex));
      this.hiddenBubbleScores = BubbleScoreFunctions.computeHiddenBubbleScore(hidden);
    }
    else {
      this.displayingBubbleScores = this.specificBubbleScores;
      this.hiddenBubbleScores = [];
    }
  }

  computeBubblePositionX(bubbleScore: BubbleScore, layout: Layout): number {
    const n: number[] =
      BubbleScoreFunctions.countScore(this.specificBubbleScores, bubbleScore.colorIndex, bubbleScore.motive.score);
    return BubbleScoreFunctions.calculateBubblePositionX(bubbleScore.motive.score, n[0], n[1], layout);
  }

  computeBubblePositionY(bubbleScore: BubbleScore, layout: Layout): number {
    const n: number[] =
      BubbleScoreFunctions.countScore(this.specificBubbleScores, bubbleScore.colorIndex, bubbleScore.motive.score);
    return BubbleScoreFunctions.calculateBubblePositionY(n[0], n[1], layout);
  }

  computeHiddenBubblePositionX(hiddenBubbleScore: HiddenBubbleScore, layout: Layout): number {
    // position 3 and amount 4 is one of the cases where the bubble is on bot right
    return BubbleScoreFunctions.calculateBubblePositionX(hiddenBubbleScore.score, 3, 4, layout);
  }

  computeHiddenBubblePositionY(layout: Layout): number {
    // position 2 and amount 6 is one of the cases where the bubble is on bot right
    return BubbleScoreFunctions.calculateBubblePositionY(3, 4, layout);
  }

  computeName(firstName: string, lastName: string): string {
    return TeamFunctions.getNameInitials(firstName, lastName);
  }

  onEye(): void {
    this.show = !this.show;
    this.teamService.motivesHidden.next();
  }

  __(key: string): string {
    return this.translatorService.translate('teamGraphic.' + key.toLowerCase());
  }
}
