<h2 [innerHTML]="introText.heading"></h2>
<h4 [innerHTML]="introText.subheading" style="margin: 2em 0 0.5em 0"></h4>

<!-- If the placeholder was not replaced... -->
<div *ngIf="introText.mainText" [innerHTML]="introText.mainText" class="id37-text2 text-light"></div>

<!-- If the placeholder was replaced... -->
<div *ngIf="!introText.mainText" class="id37-text2 text-light">
  <span [innerHTML]="introText.mainTextPartA"></span>
  <span>(<a (click)="scrollToScienceText()" class="id37-link">{{__('toScienceLink')}}</a>)</span>
  <span [innerHTML]="introText.mainTextPartB"></span>
</div>

<div *ngIf="!masterId" [innerHTML]="introText.selectMasterText" class="id37-text2 text-light"></div>
