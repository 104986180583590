import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';

export interface TestIds {
  ids: string[];
  loadedAll: boolean;
}

export type TestIdsState = EntityState<TestIds>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'testId'})
export class TestIdStore extends EntityStore<TestIdsState, TestIds, string> {

  constructor() {
    super({
      ids: [],
      loadedAll: false
    });
  }


}
