import { Component } from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';

@Component({
  selector: 'app-orders-error',
  standalone: true,
  imports: [],
  templateUrl: './orders-error.component.html',
  styleUrl: './orders-error.component.scss'
})
export class OrdersErrorComponent {

  constructor(private translatorService: TranslatorService) {
  }


  __(key: string): string {
    return this.translatorService.translate('orders.error.' + key);
  }

}
