import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CountryService} from '../../../user-data/country.service';
import {forkJoin, Observable, ObservableInput, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BrowserService} from '../../../shared/browser.service';
import {ChoicesOptions} from '../../../user-data/choices-options';
import {TranslatorService} from '../../../translation/translator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CurrentUser} from '../../../user/current-user.service';
import {Gender} from '../../../client/gender';
import {Test} from '../../state/test';
import {Item} from '../../state/item';
import {TestQuery} from '../../state/test.query';
import {TestService} from '../../state/test.service';
import {PersonalDataBeforeTest} from '../../state/personal-data-before-test';
import {TestVariation} from '../../state/test-variation';
import {Choice, ChoicesGroup} from '../../../select/state/choices-types';
import {DateTimeComponent} from '../../../datetime/datetime/datetime.component';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit, OnDestroy {

  @ViewChild('birthdayInput')
    birthdayInput: DateTimeComponent;
  testId: string;
  test: Test;
  accessGranted: boolean = false;
  dummyItem: Item;
  minDate: Moment = moment('1888-01-01').hour(0).minute(0);
  maxDate: Moment = moment().hour(23).minute(59);
  showVariationSelection = false;
  testDataForm: FormGroup;
  personalDataForm: FormGroup;
  isSavingData = false;
  variationChoices: Choice[];
  countryChoices: ChoicesGroup[];
  nationalityChoices: ChoicesGroup[];
  genderChoices: Choice[];
  languageChoices: Choice[];
  language: string;
  private unsubscribe$ = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private testService: TestService,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private countryService: CountryService,
              private browserService: BrowserService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router) {
    this.variationChoices = ChoicesOptions.variations();
    this.countryChoices = [
      {
        label: 'Top countries',
        id: 1,
        choices: ChoicesOptions.topCountries(this.currentUser.locale)
      },
      {
        label: 'All countries',
        id: 2,
        choices: this.countryService.getCountriesAsChoicesOptions()
      }
    ];
    this.nationalityChoices = [
      {
        label: 'Top nationalities',
        id: 1,
        choices: ChoicesOptions.topNationalities(this.currentUser.locale)
      },
      {
        label: 'All nationalities',
        id: 2,
        choices: this.countryService.getNationalitiesAsChoicesOptions()
      }
    ];
    this.genderChoices = ChoicesOptions.genders(this.currentUser.locale);
    this.languageChoices = ChoicesOptions.translatedLanguages(this.currentUser.locale);
  }

  get dateOfBirth(): AbstractControl {
    return this.personalDataForm.get('dateOfBirth');
  }

  get residence(): AbstractControl {
    return this.personalDataForm.get('residence');
  }

  get origin(): AbstractControl {
    return this.personalDataForm.get('origin');
  }

  get gender(): AbstractControl {
    return this.personalDataForm.get('gender');
  }

  private static isEmpty(str: string): boolean {
    return (typeof str === typeof undefined || str === 'null' || str.length === 0);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.testId = params.testId;
    });

    this.checkIfUserHasAccess(() => {
      this.dummyItem = new Item(-1, 'ABC', this.__('exampleItemText'), -1, false);

      this.testDataForm = this.formBuilder.group({
        variation: ['', Validators.required]
      });

      this.personalDataForm = this.formBuilder.group({
        dateOfBirth: ['', Validators.required],
        residence: ['', Validators.required],
        origin: ['', Validators.required],
        gender: ['', Validators.required],
        language: ['', Validators.required]
      });
      this.loadPersonalData();

      // Only allow variation selection if the user is allowed to update the variation of his test.
      this.showVariationSelection = this.test.variationUpdateAllowed && !this.test.started;
      setTimeout(
        () => this.testDataForm.controls.variation.setValue(this.___(this.test.variation.translatorKey)),
        0
      );

    }, () => {
      console.error('Forbidden!');
      this.router.navigate(['/dashboard']);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkIfUserHasAccess(allowed: () => void, notAllowed: () => void): void {
    this.testQuery.selectTestForCurrentUser(this.testId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (test: Test) => {
          // We can't check against !test.started, as the test is only considered to be "started" after the first item was answered!
          if (test.started || test.finished) {
            this.router.navigate(['/my-test', this.testId]);
          }
          else {
            this.accessGranted = true;
            this.test = test;
            allowed();
          }
        },
        () => {
          this.accessGranted = false;
          notAllowed();
        }
      );
  }

  leaveStartPage(): void {
    if (this.formValid()) {
      this.saveData();
    }
    else {
      this.setRequiredFields();
    }
  }

  loadPersonalData(): void {
    this.testService.loadPersonalDataBeforeTest()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((personalData: PersonalDataBeforeTest) => {
        // TODO: Update selected values from this.
        this.personalDataForm.controls.residence.setValue(personalData.residence);
        this.personalDataForm.controls.origin.setValue(personalData.origin);
        this.personalDataForm.controls.gender.setValue(personalData.gender);
        this.personalDataForm.controls.dateOfBirth.setValue(this.parseDate(personalData.dateOfBirth));
        this.personalDataForm.controls.language.setValue(this.currentUser.locale);
      });
  }

  setRequiredFields(): void {
    this.personalDataForm.controls.residence.markAsTouched();
    this.personalDataForm.controls.origin.markAsTouched();
    this.personalDataForm.controls.gender.markAsTouched();
    this.personalDataForm.controls.dateOfBirth.markAsTouched();
  }

  saveData() {
    const variationName = this.testDataForm.controls.variation.value;

    const personalDataBefore: PersonalDataBeforeTest = {
      residence: this.personalDataForm.controls.residence.value,
      origin: this.personalDataForm.controls.origin.value,
      gender: Gender.fromName(this.personalDataForm.controls.gender.value as string),
      dateOfBirth: this.personalDataForm.controls.dateOfBirth.value
    };

    this.isSavingData = true;

    const observables: ObservableInput<unknown>[] = [];
    observables.push(this.testService.savePersonalDataBeforeTest(personalDataBefore));
    if (this.showVariationSelection) {
      observables.push(this.testService.updateVariation(this.testId, TestVariation.fromName(variationName as string)));
    }

    this.language = this.personalDataForm.controls.language.value;

    forkJoin(observables).subscribe(
      () => {
        this.isSavingData = false;
        this.router.navigateByUrl(`/my-test/${this.testId}/items`, {
          state: {
            language: this.language
          }
        });
      },
      () => {
        this.isSavingData = false;
      }
    );

  }

  formValid(): boolean {
    return !StartPageComponent.isEmpty(String(this.testDataForm.controls.variation.value))
      && !StartPageComponent.isEmpty(String(this.personalDataForm.controls.residence.value))
      && !StartPageComponent.isEmpty(String(this.personalDataForm.controls.origin.value))
      && !StartPageComponent.isEmpty(String(this.personalDataForm.controls.gender.value))
      && !StartPageComponent.isEmpty(String(this.personalDataForm.controls.dateOfBirth.value))
      && !!this.birthdayInput && !!this.birthdayInput.hasSelection();
  }

  __(key: string): string {
    return this.translatorService.translate('my-test.start.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

  private parseDate(dateString: string): string {
    if (typeof dateString === typeof undefined || dateString === null || dateString === '1888-01-01') {
      return '';
    }
    else {
      return dateString;
    }
  }

}
