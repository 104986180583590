<ng-container *ngIf="!accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 4em">
    <div class="clr-col">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="accessGranted">
  <app-progress-box (shouldSwitchToNextProgressItem)="goToNextPage(true)"
                    (shouldSwitchToPreviousProgressItem)="goToPreviousPage(true)"
                    (shouldSwitchToProgressItem)="processClickOnPageKnob($event)"
                    [availableProgressItems]="availableProgressItems"
                    [backButtonActive]="backButtonActive"
                    [continueButtonActive]="continueButtonActive"
                    [currentProgressItemIndex]="currentPageNumber - 1 || 0"
                    [fixed]="true"
                    [lastProgressItemIndex]="lastFilledOutPageNumber - 1 || 0"></app-progress-box>


  <div class="clr-row clr-justify-content-center items-row margin-0">
    <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">
      <ng-container *ngFor="let item of getItemsOnCurrentPage(); let i = index">
        <div *ngIf="item !== undefined">
          <app-item (answerChangedThroughArrowKey)="answerChangedThroughArrowKey($event)"
                    (answerChangedThroughClick)="answerChangedThroughClick($event)"
                    (answerChangedThroughNumberKey)="answerChangedThroughNumberKey($event)"
                    (answerCouldNotBeSaved)="answerCouldNotBeSaved($event)"
                    (answerSaved)="answerSaved($event)"
                    (focus)="selectItemComponentForItem(item, false);"
                    (gotSelected)="itemComponentGotSelected($event, i)"
                    [_answerable]="true"
                    [_item]="item"
                    [_testId]="this.testId"
                    id="item-{{i}}"
                    style="outline: none;"
                    tabindex="0"></app-item>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="clr-row clr-justify-content-center button-row margin-0">
    <div class="clr-col-xs-12 clr-justify-content-center" style="margin-bottom: 5em">
      <button (click)="goToPreviousPage(false)"
              [ngClass]="{'disabled': !backButtonActive}"
              class="id37-btn-outline-secondary previous">
        {{__('back')}}
      </button>
      <button (click)="goToNextPage(false)"
              [ngClass]="{'disabled': !continueButtonActive}"
              class="id37-btn-outline next">
        {{__('next')}}
      </button>
    </div>
  </div>
</ng-container>
