<svg [ngStyle]="{'width': svgFill ? '100%' : 'auto', 'height': svgFill ? '100%' : 'auto'}">
  <defs>
    <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" [attr.stop-color]="startColor"/>
      <stop offset="100%" [attr.stop-color]="middleColor"/>
    </linearGradient>
    <linearGradient id="grad2" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" [attr.stop-color]="endColor"/>
      <stop offset="100%" [attr.stop-color]="middleColor"/>
    </linearGradient>
  </defs>
  <g fill="none" stroke="url(#grad1)" [attr.stroke-width]="circleStrokeWidth" stroke-linecap="round">
    <path [attr.d]="
      'M ' + centerX + ' ' + circleBorderPx +
      ' A ' + radius + ' ' + radius + ' 0 0 1 ' + rightCircleX + ' ' + rightCircleY
    "/>
  </g>
  <ng-container *ngIf="enableLeftCircle">
    <g fill="none" stroke="url(#grad2)" [attr.stroke-width]="circleStrokeWidth" stroke-linecap="round">
      <path [attr.d]="
        'M ' + centerX + ' ' + (circleSizePx - circleBorderPx) +
        ' A ' + radius + ' ' + radius + ' 0 0 1 ' + leftCircleX + ' ' + leftCircleY
      "/>
    </g>
  </ng-container>
</svg>
