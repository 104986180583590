import {BasicUserInvitation} from './basic-user-invitation';

export class BasicUserInvitationRepresentation {

  public readonly email: string;
  public readonly firstName: string;
  public readonly lastName: string;

  constructor(email: string, firstName: string, lastName: string) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  static toModelEntity(basicUserInvitationRepresentation: BasicUserInvitationRepresentation): BasicUserInvitation {
    return {
      email: basicUserInvitationRepresentation.email,
      firstName: basicUserInvitationRepresentation.firstName,
      lastName: basicUserInvitationRepresentation.lastName
    };
  }

}
