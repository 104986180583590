import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  static readonly DEFAULT_ROUTE = '/dashboard';
  static readonly MAX_HISTORY_SIZE = 25;

  private _history: string[];

  constructor(private router: Router) {
  }

  init(): void {
    this._history = [];
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((navigationEnd: NavigationEnd) => {
        const length = this._history.push(navigationEnd.urlAfterRedirects);
        if (length > RoutingService.MAX_HISTORY_SIZE) {
          this._history.shift();
        }
      });
  }

  routeBack(reverseIndex: number = 1): void {
    this.router.navigate([this.getRouteFromEndOfHistory(reverseIndex) || RoutingService.DEFAULT_ROUTE]);
  }

  getHistory(): string[] {
    return this._history;
  }

  getRouteFromEndOfHistory(reverseIndex: number = 0): string | undefined {
    return this.getHistory()[this.getHistory().length - 1 - reverseIndex];
  }

  getCurrentRoute(): string {
    return this.getRouteFromEndOfHistory(0) || RoutingService.DEFAULT_ROUTE;
  }

  getPreviousRoute(): string {
    return this.getRouteFromEndOfHistory(1) || RoutingService.DEFAULT_ROUTE;
  }

}
