import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RadarComponent} from './radar/radar.component';
import {BubbleComponent} from './bubble/bubble.component';
import {ChartComponent} from './chart.component';
import {ScatterComponent} from './scatter/scatter.component';
import {PolarComponent} from './polar/polar.component';


@NgModule({
  declarations: [
    BubbleComponent,
    ChartComponent,
    PolarComponent,
    RadarComponent,
    ScatterComponent],
  exports: [
    BubbleComponent,
    ChartComponent,
    RadarComponent,
    ScatterComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ChartModule {
}
