import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamGraphicHeaderComponent} from './team-graphic/team-graphic-header/team-graphic-header.component';
import {TeamGraphicComponent} from './team-graphic/team-graphic.component';
import {TeamGraphicMotivesComponent} from './team-graphic/team-graphic-motives/team-graphic-motives.component';
import {TeamGraphicMotiveComponent} from './team-graphic/team-graphic-motives/team-graphic-motive/team-graphic-motive.component';
import {TeamGraphicBubbleComponent} from './team-graphic/team-graphic-motives/team-graphic-motive/team-graphic-bubble/team-graphic-bubble.component';
import {TeamGraphicNavBubblesComponent} from './team-graphic-nav-bubbles/team-graphic-nav-bubbles.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TeamGraphicNavBubbleComponent} from './team-graphic-nav-bubbles/team-graphic-nav-bubble/team-graphic-nav-bubble.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DirectivesModule} from '../../directives/directives.module';
import {ProfilePictureModule} from '../../profile-picture/profile-picture.module';

@NgModule({
  declarations: [
    TeamGraphicHeaderComponent,
    TeamGraphicComponent,
    TeamGraphicMotivesComponent,
    TeamGraphicMotiveComponent,
    TeamGraphicBubbleComponent,
    TeamGraphicNavBubblesComponent,
    TeamGraphicNavBubbleComponent
  ],
  exports: [
    TeamGraphicHeaderComponent,
    TeamGraphicComponent,
    TeamGraphicBubbleComponent,
    TeamGraphicNavBubblesComponent
  ],
  imports: [CommonModule, DragDropModule, ScrollingModule, DirectivesModule, ProfilePictureModule]
})
export class TeamGraphicModule {
}
