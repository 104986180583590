<h3 *ngIf="showTitle" class="section-start">
  {{__('title')}}
</h3>

<div [formGroup]="invoiceAddressForm" [ngClass]="{'errors': checkErrors()}">
  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-6">
      <div class="clr-form-group">
        <label for="first-name">{{__('firstName')}}</label>
        <input formControlName="firstName" id="first-name" type="text">
        <div *ngIf="firstName.errors?.required && firstName.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="firstName.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
    <div class="clr-col-md-6">
      <div class="clr-form-group">
        <label for="last-name">{{__('lastName')}}</label>
        <input formControlName="lastName" id="last-name" type="text">
        <div *ngIf="lastName.errors?.required  && lastName.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="lastName.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row clr-justify-content-center asACompany">
    <div class="clr-col-md-12">
      <div class="clr-form-group">
        <label for="company">{{__('company')}}</label>
        <input formControlName="company" id="company" placeholder="{{__('companyPlaceholder')}}" type="text">
        <div *ngIf="company.errors?.required  && company.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="company.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
    <div class="clr-col-md-12">
      <div class="clr-form-group">
        <label for="vatIdentification">{{__('vatIdentification')}}</label>
        <input formControlName="vatIdentification" id="vatIdentification" placeholder="{{__('optional')}}"
               type="text">
        <div *ngIf="vatIdentification.errors?.required  && vatIdentification.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="vatIdentification.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-6">
      <div class="clr-form-group">
        <label for="country-select">{{__('country')}}</label>
        <app-select
            [choicesGroups]="countryChoices"
            [formControlName]="'country'"
            [placeholder]="''"
            id="country-select">
        </app-select>
        <div *ngIf="country.errors?.required  && country.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="country.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
    <div class="clr-col-md-6">
      <div class="clr-form-group">
        <label for="state">{{__('state')}}</label>
        <input formControlName="state" id="state" type="text">
        <div *ngIf="state.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-4">
      <div class="clr-form-group">
        <label for="zip-code">{{__('zipCode')}}</label>
        <input formControlName="zipCode" id="zip-code" type="text">
        <div *ngIf="zipCode.errors?.required  && zipCode.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="zipCode.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
    <div class="clr-col-md-8">
      <div class="clr-form-group">
        <label for="city">{{__('city')}}</label>
        <input formControlName="city" id="city" type="text">
        <div *ngIf="city.errors?.required  && city.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="city.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-md-8">
      <div class="clr-form-group">
        <label for="street">{{__('street')}}</label>
        <input formControlName="street" id="street" type="text">
        <div *ngIf="street.errors?.required  && street.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="street.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
    <div class="clr-col-md-4">
      <div class="clr-form-group">
        <label for="house-nr">{{__('houseNr')}}</label>
        <input formControlName="houseNr" id="house-nr" type="text">
        <div *ngIf="houseNr.errors?.required  && houseNr.touched" class="alert danger">
          {{__('errors.required')}}
        </div>
        <div *ngIf="houseNr.errors?.whitespace" class="alert danger">
          {{__('errors.whitespace')}}
        </div>
      </div>
    </div>
  </div>

  <div class="id37-text3 text-light" style="margin-top: 1em">
    {{__('required')}}
  </div>

</div>
