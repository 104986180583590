import {Directive, Input} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[default]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[src]': 'src',
    '(error)': 'updateUrl()'
  }
})

export class DefaultImageDirective {

  @Input()
    src: string;

  @Input()
    default: string;

  constructor() {
  }

  updateUrl() {
    this.src = this.default;
  }
}


