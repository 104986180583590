import {
  ChangeDetectionStrategy,
  Component, DestroyRef,
  EventEmitter, inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {ChatbotUserService} from '../api-services/chatbot-user.service';
import {ChatbotChat} from '../state/chatbot-chat';
import {TranslatorService} from '../../translation/translator.service';
import {ChatbotMessagePair} from '../state/chatbot-message-pair';
import {ChatbotChatEventService} from '../utility-services/chatbot-chat-event.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-chatbot-chat-selections',
  templateUrl: './chatbot-chat-selections.component.html',
  styleUrls: ['./chatbot-chat-selections.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatbotChatSelectionsComponent implements OnInit {

  @Input()
    selectedChat: ChatbotChat;

  @Output()
    chatSelected: EventEmitter<ChatbotChat> = new EventEmitter<ChatbotChat>();

  chatList: ChatbotChat[] = [];
  groupedChats: { [key: string]: ChatbotChat[] } = {};

  deleteFormOpen: boolean = false;

  isSidebarClosed: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(private currentUser: CurrentUser,
              private chatbotChatService: ChatbotChatService,
              private chatbotUserService: ChatbotUserService,
              private translatorService: TranslatorService,
              private chatbotChatEventService: ChatbotChatEventService) {
  }

  ngOnInit(): void {

    this.chatbotUserService.checkChatbotUserExists().subscribe({
      next: (exists) => {
        if (exists) {
          void this.chatbotChatService.getChatListByUserID().subscribe({
            next: (value) => {
              this.chatList = value;
              this.groupChatsByDate();
            },
            error: (error) => console.error('Error at getChatListByUserID', error)
          });
        }
      },
      error: (error) => console.error('Error at checkChatbotUserExists', error)
    });

    this.chatbotChatEventService.messageSend$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.groupChatsByDate());
    this.chatbotChatEventService.chatCreated$.pipe(takeUntilDestroyed(this.destroyRef), filter(value => !!value))
      .subscribe(chat => this.addChatToChatList(chat));

  }

  newChat(): void {
    this.selectedChat = null;
    this.chatSelected.emit(this.selectedChat);
  }

  selectChat(chat: ChatbotChat): void {
    this.selectedChat = chat;
    this.chatSelected.emit(chat);
  }

  addChatToChatList(chat: ChatbotChat): void {
    this.chatList.unshift(chat);
    this.groupChatsByDate();
    this.selectChat(chat);
  }

  deleteChatFromChatList(chat: ChatbotChat): void {
    this.chatbotChatService.deleteChatByID(chat.chat_id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      console.log('deleteChatByID at chatbot-chat-selection', value);
    });
    this.chatList = this.chatList.filter(chatbotChat => chatbotChat.chat_id !== chat.chat_id);
    if (this.selectedChat && this.selectedChat.chat_id === chat.chat_id) {
      this.newChat();
    }
    this.groupChatsByDate();
  }

  sortChatListByTimestamp(): void {
    this.chatList.sort((a, b) => {
      // Infinity sorts empty chats to the top
      let timeA = Infinity;
      let timeB = Infinity;

      if (a.messages && a.messages.length > 0) {
        a.messages.sort((x, y) => x.question_id - y.question_id);
        const lastMessageA: ChatbotMessagePair = a.messages[a.messages.length - 1];
        timeA = new Date(lastMessageA.timestamp.valueOf()).getTime();
      }

      if (b.messages && b.messages.length > 0) {
        b.messages.sort((x, y) => x.question_id - y.question_id);
        const lastMessageB: ChatbotMessagePair = b.messages[b.messages.length - 1];
        timeB = new Date(lastMessageB.timestamp.valueOf()).getTime();
      }
      return timeB - timeA;
    });
  }

  groupChatsByDate(): void {
    this.sortChatListByTimestamp();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const lastweek = new Date(today);
    lastweek.setDate(lastweek.getDate() - 7);

    // Displayed order of the groups is determined by the order here
    this.groupedChats = {
      Empty: [],
      Today: [],
      Recent: [],
      Older: []
    };

    for (const chat of this.chatList) {
      if (chat.messages && chat.messages.length > 0) {
        const lastMessage: ChatbotMessagePair = chat.messages[chat.messages.length - 1];
        const chatDate = new Date(lastMessage.timestamp.valueOf());
        chatDate.setHours(0, 0, 0, 0);

        if (+chatDate === +today) {
          this.groupedChats.Today.push(chat);
        }
        else if (+chatDate > +lastweek) {
          this.groupedChats.Recent.push(chat);
        }
        else {
          this.groupedChats.Older.push(chat);
        }
      }
      else {
        this.groupedChats.Today.push(chat);
      }
    }
  }
  keepOrder = (a, b) => 0;

  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  isGroupNotEmpty(group: {key: string, value: ChatbotChat[]}): boolean {
    return group.value.length > 0 && group.key !== 'Empty';
  }

  deleteAllChats(): void {
    this.chatList = [];
    this.groupedChats = {};
    this.chatSelected.emit(null);
    this.chatbotChatService.deleteAllChats().subscribe(value => {
      console.log('deleteAllChats at chatbot-chat-selections', value);
    });
    this.deleteFormOpen = false;
  }

  openDeleteForm(): void {
    this.deleteFormOpen = true;
  }

  closeDeleteForm(): void {
    this.deleteFormOpen = false;
  }

  toggleSidebar(): void {
    this.isSidebarClosed = !this.isSidebarClosed;
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-chat-selections.' + key);
  }
}
