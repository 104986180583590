<svg>
  <symbol id="top-gradient-label-text" preserveAspectRatio="xMinYMin" viewBox="0 0 800 10">
    <text [style.opacity]="getScoreOpacity(1)" id="top-gradient-label-text1" text-anchor="middle" x="40" y="12">1
    </text>
    <text [style.opacity]="getScoreOpacity(2)" id="top-gradient-label-text2" text-anchor="middle" x="120" y="12">2
    </text>
    <text [style.opacity]="getScoreOpacity(3)" id="top-gradient-label-text3" text-anchor="middle" x="200" y="12">3
    </text>
    <text [style.opacity]="getScoreOpacity(4)" id="top-gradient-label-text4" text-anchor="middle" x="280" y="12">4
    </text>
    <text [style.opacity]="getScoreOpacity(5)" id="top-gradient-label-text5" text-anchor="middle" x="360" y="12">5
    </text>
    <text [style.opacity]="getScoreOpacity(6)" id="top-gradient-label-text6" text-anchor="middle" x="440" y="12">6
    </text>
    <text [style.opacity]="getScoreOpacity(7)" id="top-gradient-label-text7" text-anchor="middle" x="520" y="12">7
    </text>
    <text [style.opacity]="getScoreOpacity(8)" id="top-gradient-label-text8" text-anchor="middle" x="600" y="12">8
    </text>
    <text [style.opacity]="getScoreOpacity(9)" id="top-gradient-label-text9" text-anchor="middle" x="680" y="12">9
    </text>
    <text [style.opacity]="getScoreOpacity(10)" id="top-gradient-label-text10" text-anchor="middle" x="760" y="12">
      10
    </text>
  </symbol>
</svg>

<svg>
  <symbol id="gradient-boxes-top" preserveAspectRatio="xMinYMin" viewBox="0 0 800 7">
    <rect [style.opacity]="getScoreOpacity(1)" fill="var(--first-stop)" height="7" width="78" x="1"></rect>
    <rect [style.opacity]="getScoreOpacity(2)" fill="var(--second-stop)" height="7" width="78" x="81"></rect>
    <rect [style.opacity]="getScoreOpacity(3)" fill="var(--third-stop)" height="7" width="78" x="161"></rect>
    <rect [style.opacity]="getScoreOpacity(4)" fill="var(--fourth-stop)" height="7" width="78" x="241"></rect>
    <rect [style.opacity]="getScoreOpacity(5)" fill="var(--fifth-stop)" height="7" width="78" x="321"></rect>
    <rect [style.opacity]="getScoreOpacity(6)" fill="var(--fifth-stop)" height="7" width="78" x="401"></rect>
    <rect [style.opacity]="getScoreOpacity(7)" fill="var(--fourth-stop)" height="7" width="78" x="481"></rect>
    <rect [style.opacity]="getScoreOpacity(8)" fill="var(--third-stop)" height="7" width="78" x="561"></rect>
    <rect [style.opacity]="getScoreOpacity(9)" fill="var(--second-stop)" height="7" width="78" x="641"></rect>
    <rect [style.opacity]="getScoreOpacity(10)" fill="var(--first-stop)" height="7" width="78" x="721"></rect>
  </symbol>
</svg>

<svg>
  <use width="800" x="0" xlink:href="#top-gradient-label-text" y="0"/>
  <use width="800" x="0" xlink:href="#gradient-boxes-top" y="30"/>
</svg>
