<app-collapsible-list>
  <app-collapsible-item>
    <app-collapsible-header [title]="interpretationText.heading"></app-collapsible-header>
    <app-collapsible-body>
      <div [innerHTML]="interpretationText.mainText" class="id37-text2 text-light"></div>
      <ul class="id37-text2 text-light">
        <li *ngFor="let bulletPoint of interpretationText.bulletPointTexts" [innerHTML]="bulletPoint"></li>
      </ul>
      <div [innerHTML]="interpretationText.endText" class="id37-text2 text-light"></div>
      <div *ngIf="!masterId" [innerHTML]="interpretationText.selectMasterText"
           class="id37-text2 text-light"></div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>
