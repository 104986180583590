import {Component, Input} from '@angular/core';
import {FindMasterText} from '../types/find-master-text';

@Component({
  selector: 'app-find-master',
  templateUrl: './find-master.component.html',
  styleUrls: ['./find-master.component.scss']
})
export class FindMasterComponent {

  @Input()
    findMasterText: FindMasterText;

  constructor() {
  }

}
