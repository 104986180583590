import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Order} from '../order';
import {map, takeUntil} from 'rxjs/operators';
import {OrderProcessingResult} from '../order-processing-result';
import {TranslatorService} from '../../translation/translator.service';
import {TestOriginBuy} from '../../test/origin/test-origin-buy';
import {Test} from '../../test/state/test';
import {TestQuery} from '../../test/state/test.query';
import {Subject} from 'rxjs';
import {TestResetService} from '../../test/state/test-reset.service';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-order-completed',
  templateUrl: './order-completed.component.html',
  styleUrls: ['./order-completed.component.scss']
})
export class OrderCompletedComponent implements OnInit, OnDestroy {

  orderId: string;
  order: Order;
  orderProcessingResult: OrderProcessingResult;
  newTest: Test;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private testResetService: TestResetService,
              private testQuery: TestQuery,
              private translatorService: TranslatorService,
              private router: Router,
              private route: ActivatedRoute,
              private keycloakService: KeycloakService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderId = params.orderId;
    });
    this.route.paramMap.pipe(map(() => window.history.state)).subscribe(data => {
      if (typeof data.orderAndOrderProcessingResult === typeof undefined) {
        console.log('No data given. Redirecting...');
        this.router.navigate(['orders', this.orderId, 'process']);
      }
      else {
        this.order = Order.copyOf(data.orderAndOrderProcessingResult._order as Order);
        this.orderProcessingResult = OrderProcessingResult.copyOf(
          data.orderAndOrderProcessingResult._orderProcessingResult as OrderProcessingResult);
        if (!this.order.isRecurring) {
          this.loadNewTestForCompletedOrder();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public navigateToOrders(): void {
    this.router.navigate(['/orders', this.order.orderId]);
  }

  public navigateToTest(): void {
    this.router.navigate(['/my-test', this.newTest.testId]);
  }

  public navigateToChatbot(): void {
    this.keycloakService.updateToken(-1).then(
      () => {
        void this.router.navigate(['/chatbot']);
      }
    ).catch(() => console.log('Error while updating Token!'));
  }
  __(key: string): string {
    return this.translatorService.translate('order.completed.' + key);
  }

  private loadNewTestForCompletedOrder(): void {
    this.testResetService.reloadTestsForCurrentUser();
    this.testQuery.selectTestsForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tests: Test[]) => {
        this.newTest = tests
          .filter(test => test.origin.isBuy() && (test.origin as TestOriginBuy).orderId === this.order.orderId)
          .shift();
        if (typeof this.newTest === typeof undefined) {
          console.error('AssertionError: No test of the current user seems to be originated from the completed order.');
        }
      });
  }

}
