export class TestVariation {

  public static readonly ID37_14: TestVariation = new TestVariation(14, 'ID37_14', 'test.variation.14');
  public static readonly ID37_15: TestVariation = new TestVariation(15, 'ID37_15', 'test.variation.15');
  public static readonly ID37_16: TestVariation = new TestVariation(16, 'ID37_16', 'test.variation.16');

  private readonly _motiveCount: number;
  private readonly _internalName: string;
  private readonly _translatorKey: string;

  private constructor(motiveCount: number, internalName: string, translatorKey: string) {
    this._motiveCount = motiveCount;
    this._internalName = internalName;
    this._translatorKey = translatorKey;
  }

  get motiveCount(): number {
    return this._motiveCount;
  }

  get internalName(): string {
    return this._internalName;
  }

  get translatorKey(): string {
    return this._translatorKey;
  }

  public static fromName(name: string): TestVariation | undefined {
    switch (name) {
      case 'ID37/14':
      case 'ID37_14':
        return TestVariation.ID37_14;
      case 'ID37/15':
      case 'ID37_15':
        return TestVariation.ID37_15;
      case 'ID37/16':
      case 'ID37_16':
        return TestVariation.ID37_16;
      default:
        return undefined;
    }
  }

  static getMinimum(a: TestVariation, b: TestVariation): TestVariation {
    return a._motiveCount < b._motiveCount ? a : b;
  }

}
