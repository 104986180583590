import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {BrowserService} from '../../../shared/browser.service';
import {MotiveScores} from '../../../test/state/motive-score';

@Component({
  selector: 'app-key-graphic-print-layout',
  templateUrl: './key-graphic-print-layout.component.html',
  styleUrls: ['./key-graphic-print-layout.component.scss']
})
export class KeyGraphicPrintLayoutComponent {

  @Input()
    results: MotiveScores[];

  @Input()
    name = '';

  @Input()
    showLogo: boolean = true;

  @Output()
    shouldBePrinted = new EventEmitter();

  constructor(private translatorService: TranslatorService,
              public browserService: BrowserService) {
  }

  issuePrintEvent() {
    this.shouldBePrinted.emit();
  }

  __(key: string): string {
    return (key === 'firefoxPrintWarning')
      ? this.translatorService.translate('report.print-document.' + key)
      : this.translatorService.translate('graphic.' + key);
  }

}
