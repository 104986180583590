import {TestShare} from './test-share';
import {TestVariation} from './test-variation';

export class TestShareRepresentation {

  public readonly testShareId: string;
  public readonly testId: string;
  public readonly variation: string;

  constructor(testShareId: string, testId: string, variation: string) {
    this.testShareId = testShareId;
    this.testId = testId;
    this.variation = variation;
  }

  public static toModelEntity(testShareRepresentation: TestShareRepresentation): TestShare {
    return {
      testShareId: testShareRepresentation.testShareId,
      testId: testShareRepresentation.testId,
      variation: TestVariation.fromName(testShareRepresentation.variation)
    };
  }

}
