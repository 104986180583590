import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Notification} from './notification';

export type NotificationsState = EntityState<Notification, number>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'notification'})
export class NotificationStore extends EntityStore<NotificationsState> {

  constructor() {
    super();
  }

}
