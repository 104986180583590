import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TestQuery} from '../state/test.query';
import {Test} from '../state/test';

@Component({
  selector: 'app-my-test',
  templateUrl: './my-test.component.html',
  styleUrls: ['./my-test.component.scss']
})
export class MyTestComponent implements OnInit, OnDestroy {

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.testQuery.selectTestForCurrentUser(params.testId as string)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (test: Test) => this.handleTest(test),
          () => this.handleError()
        );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handleTest(test: Test): void {
    if (!test.started) {
      this.router.navigate(['/my-test', test.testId, 'start']);
    }
    else if (test.started && !test.finished) {
      this.router.navigate(['/my-test', test.testId, 'items']);
    }
    else if (test.finished) {
      this.router.navigate(['/my-test', test.testId, 'thank-you']);
    }
  }

  private handleError(): void {
    console.error('Forbidden!');
    this.router.navigate(['/dashboard']);
  }

}
