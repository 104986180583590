import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslatorService} from '../../../translation/translator.service';
import {NewTeamUser} from '../../state/new-team-user';
import {UserSearchService} from '../../../user/user-search.service';
import {BasicUser} from '../../../user/basic-user';
import {UserGroup} from '../../../user/user-group';

@Component({
  selector: 'app-team-users-selection',
  templateUrl: './team-users-selection.component.html',
  styleUrls: ['./team-users-selection.component.scss']
})
export class TeamUsersSelectionComponent implements OnInit, OnDestroy {

  @Input() members: NewTeamUser[];

  @Output() addMember: EventEmitter<NewTeamUser> = new EventEmitter<NewTeamUser>();

  @Output() deleteMember: EventEmitter<NewTeamUser> = new EventEmitter<NewTeamUser>();

  @Output() searching: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() limit: EventEmitter<boolean> = new EventEmitter<boolean>();

  listForm: FormGroup;
  searchResult: UserGroup[];
  searchWord: string;
  selectedUsers: number = 0;
  allSelected: boolean;
  loaded: boolean;
  loading: boolean;

  private checkboxPlaceholder: ElementRef;

  @ViewChild('allSelect', {static: false}) set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.checkboxPlaceholder = content;
      // TODO: ExpressionChangedAfterItHasBeenCheckedError:
      (this.checkboxPlaceholder as any).checked = this.allSelected;
    }
  }

  private unsubscribe$ = new Subject<void>();

  constructor(private translatorService: TranslatorService,
              private userSearchService: UserSearchService) {
  }

  ngOnInit(): void {
  }

  @Input()
  set searchKey(searchString: string) {
    if (searchString.length > 1 && !this.loading) {
      const toSearch: string = this.processedSearchKey(searchString, 3, '~');
      this.loaded = false;
      this.loading = true;
      this.searchWord = searchString;
      this.initForm();
      this.userSearchService.searchUsersInFriendsAndGroups(toSearch).pipe(takeUntil(this.unsubscribe$))
        .subscribe((userGroups: UserGroup[]) => {
          this.searchResult = userGroups;
          this.addItems();
          this.loaded = true;
          this.loading = false;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initForm(): void {
    this.listForm = new FormGroup({
      items: new FormArray([])
    });
  }

  addItems(): void {
    this.allSelected = true;
    this.searchResult.forEach((basicUser: BasicUser) => {
      // check if searched user is selected already: if yes -> set checkbox to true
      const isSelected = this.members.find((p) => p.keycloakId === basicUser.keycloakId);
      (this.listForm.get('items') as FormArray).push(new FormGroup({checked: new FormControl(!!isSelected)}));
      // check if all of the searched users are selected already
      if (!isSelected) {
        this.allSelected = false;
      }
      else {
        this.selectedUsers++;
      }
    });
  }

  get items(): AbstractControl[] {
    return (this.listForm.get('items') as FormArray).controls;
  }

  onCheckbox(index: number, value: boolean): void {
    if (value) {
      ++this.selectedUsers;
    }
    else {
      --this.selectedUsers;
    }

    if (value && this.selectedUsers > 40) {
      this.limit.emit(value);
      this.items[index].get('checked').setValue(value);
      return;
    }
    else if (!value && this.selectedUsers <= 40) {
      this.limit.emit(value);
      this.items[index].get('checked').setValue(value);
    }

    const teamUser: NewTeamUser = {
      firstName: this.searchResult[index].firstName,
      lastName: this.searchResult[index].lastName,
      keycloakId: this.searchResult[index].keycloakId
    };
    if (value) {
      this.addMember.emit(teamUser);
    }
    else {
      this.deleteMember.emit(teamUser);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      // (this.checkboxPlaceholder as any).checked = false;
      this.items[index].get('checked').setValue(false);
    }
  }

  onSelectAll(value: boolean): void {
    this.items.forEach((item, index) => {
      if (Boolean(item.get('checked').value) !== value) {
        this.onCheckbox(index, value);
        item.setValue({checked: value});
      }
    });
  }

  __(key: string): string {
    return this.translatorService.translate('teamModal.' + key);
  }

  /* splits words after ' ' (whitespace), filters all words with at least 2 letters,
then only return only 'maxWords' and then connect/join them with a 'separator'
 */
  private processedSearchKey(searchString: string, maxWords: number, separator: string): string {
    return searchString.trim().toLowerCase()
      .split(/\s/g)
      .filter(word => word.length > 1)
      .filter((word, index) => index < maxWords)
      .join(separator);
  }

}



