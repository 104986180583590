import {Gender} from '../client/gender';

export class UpdatableAccountData {

  constructor(firstName: string,
              lastName: string,
              email: string,
              gender: Gender,
              academicDegree: string,
              academicTitle: string,
              city: string,
              company: string,
              dateOfBirth: string,
              industry: string,
              mobilePhoneNumber: string,
              origin: string,
              profession: string,
              residence: string) {
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._gender = gender;
    this._academicDegree = academicDegree;
    this._academicTitle = academicTitle;
    this._city = city;
    this._company = company;
    this._dateOfBirth = dateOfBirth;
    this._industry = industry;
    this._mobilePhoneNumber = mobilePhoneNumber;
    this._origin = origin;
    this._profession = profession;
    this._residence = residence;
  }

  private _firstName: string;

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  private _lastName: string;

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  private _email: string;

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  private _gender: Gender;

  get gender(): Gender {
    return this._gender;
  }

  set gender(value: Gender) {
    this._gender = value;
  }

  private _academicDegree: string;

  get academicDegree(): string {
    return this._academicDegree;
  }

  set academicDegree(value: string) {
    this._academicDegree = value;
  }

  private _academicTitle: string;

  get academicTitle(): string {
    return this._academicTitle;
  }

  set academicTitle(value: string) {
    this._academicTitle = value;
  }

  private _city: string;

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  private _company: string;

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
  }

  private _dateOfBirth: string;

  get dateOfBirth(): string {
    return this._dateOfBirth;
  }

  set dateOfBirth(value: string) {
    this._dateOfBirth = value;
  }

  private _industry: string;

  get industry(): string {
    return this._industry;
  }

  set industry(value: string) {
    this._industry = value;
  }

  private _mobilePhoneNumber: string;

  get mobilePhoneNumber(): string {
    return this._mobilePhoneNumber;
  }

  set mobilePhoneNumber(value: string) {
    this._mobilePhoneNumber = value;
  }

  private _origin: string;

  get origin(): string {
    return this._origin;
  }

  set origin(value: string) {
    this._origin = value;
  }

  private _profession: string;

  get profession(): string {
    return this._profession;
  }

  set profession(value: string) {
    this._profession = value;
  }

  private _residence: string;

  get residence(): string {
    return this._residence;
  }

  set residence(value: string) {
    this._residence = value;
  }
}
