import {Component, OnInit} from '@angular/core';
import {Order} from '../order';
import {OrderProcessingResult} from '../order-processing-result';
import {ActivatedRoute, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {OrderStatus} from '../order-status';
import {OrderAction} from '../order-action';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-order-not-completed',
  templateUrl: './order-not-completed.component.html',
  styleUrls: ['./order-not-completed.component.scss']
})
export class OrderNotCompletedComponent implements OnInit {

  loading: boolean = true;

  orderId: string;
  order: Order;
  orderProcessingResult: OrderProcessingResult;

  orderStatus = OrderStatus;
  orderAction = OrderAction;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderId = params.orderId;
      this.route.paramMap.pipe(map(() => window.history.state)).subscribe(data => {
        if (typeof data.orderAndOrderProcessingResult === typeof undefined) {
          this.router.navigate(['orders', this.orderId, 'process']);
        }
        else {
          this.order = Order.copyOf(data.orderAndOrderProcessingResult._order as Order);
          this.orderProcessingResult = OrderProcessingResult.copyOf(
            data.orderAndOrderProcessingResult._orderProcessingResult as OrderProcessingResult);
          this.loading = false;
        }
      });
    });
  }

  navigateToOrderPage(): void {
    this.router.navigate(['order']);
  }

  __(key: string): string {
    return this.translatorService.translate('order.notCompleted.' + key);
  }

}
