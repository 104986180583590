import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {UserInvitationService} from '../invitation/user-invitation.service';
import {TranslatorService} from '../../translation/translator.service';
import {Subject} from 'rxjs';
import {Id37Router} from '../../id37-router';
import {RecommendationType} from '../../recommendation/recommendation-type';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.scss']
})
export class UserInviteComponent implements OnInit {

  @Input()
    inviteAsFriend: boolean = true;

  @Output()
    invited = new EventEmitter<void>();
  /**
   * Determines whether or not the modal is open.
   */
  open: boolean;
  success: boolean;
  inviteFriendForm: FormGroup;
  lastErrorState: boolean = true;
  error: boolean = true;
  checked: boolean;
  ongoingRequest: boolean = false;
  requestError: boolean = false;
  recommendationType: RecommendationType;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private userInvitationService: UserInvitationService,
              private translatorService: TranslatorService,
              private formBuilder: FormBuilder,
              private router: Id37Router) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  show(recommendationType: RecommendationType): void {
    this.recommendationType = recommendationType;
    this.resetForm();
    this.open = true;

  }

  hide(): void {
    this.open = false;
  }

  onCheck(): void {
    this.getFormControl('termsCheckBox')
      .setValue(!this.getFormControl('termsCheckBox').value);
  }

  switchToSuccessModal(): void {
    this.hide();
    this.openSuccessModal();
  }

  openSuccessModal(): void {
    this.success = true;
    setTimeout(() => {
      this.success = false;
    }, 3500);
  }

  public getFormControl(formName: string): AbstractControl {
    return this.inviteFriendForm.get(formName);
  }

  public checkErrors(): boolean {
    const hasErrors = this.hasErrors();
    if (hasErrors !== this.lastErrorState) {
      this.lastErrorState = hasErrors;
      this.error = hasErrors; // this.valid.emit(!hasErrors);
    }
    return hasErrors;
  }

  public inviteUserToPlatform(): void {
    if (this.inviteAsFriend) {
      this.ongoingRequest = true;
      this.userInvitationService.recommendAsFriend(
        this.inviteFriendForm.value.email as string,
        this.inviteFriendForm.value.firstName as string,
        this.inviteFriendForm.value.lastName as string,
        this.recommendationType
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(
        () => {
          this.ongoingRequest = false;
          this.invited.emit();
          this.switchToSuccessModal();
        },
        () => {
          this.ongoingRequest = false;
          this.requestError = true;
        }
      );
    }
    else {
      this.userInvitationService.recommendUser(
        this.inviteFriendForm.value.email as string,
        this.inviteFriendForm.value.firstName as string,
        this.inviteFriendForm.value.lastName as string
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(
        () => {
          this.ongoingRequest = false;
          this.invited.emit();
          this.switchToSuccessModal();
        },
        () => {
          this.ongoingRequest = false;
          this.requestError = true;
        }
      );
    }
  }

  goToFriends(): void {
    this.router.routeToFriends();
  }

  __(key: string): string {
    return this.translatorService.translate('userInvite.' + key);
  }

  private initForm(): void {
    this.inviteFriendForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required,
        Validators.pattern('^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\' +
          's@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')]],
      termsCheckBox: [false, Validators.pattern('true')]
    });
  }

  private resetForm(): void {
    this.initForm();
    this.checked = false;
    this.requestError = false;
  }

  private hasErrors(): boolean {
    return !!this.getFormControl('firstName').errors
      || !!this.getFormControl('lastName').errors
      || !!this.getFormControl('email').errors
      || !!this.getFormControl('termsCheckBox').errors;
  }

}
