<svg style="display: none" xmlns="http://www.w3.org/2000/svg">
  <symbol id="bottom-gradient-label-text" preserveAspectRatio="xMinYMax" viewBox="0 0 800 1">
    <title>bottom-gradient-label-text</title>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="40" y="0">2%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="120" y="0">5%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="200" y="0">9%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="280" y="0">15%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="360" y="0">19%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="440" y="0">19%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="520" y="0">15%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="600" y="0">9%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="680" y="0">5%</text>
    <text id="bottom-gradient-label-text" text-anchor="middle" x="760" y="0">2%</text>
  </symbol>
</svg>
<svg>
  <symbol id="gradient-boxes-bottom" preserveAspectRatio="xMinYMax" viewBox="0 0 800 7">
    <rect fill="var(--first-stop)" height="7" width="78" x="1"/>
    <rect fill="var(--second-stop)" height="7" width="78" x="81"/>
    <rect fill="var(--third-stop)" height="7" width="78" x="161"/>
    <rect fill="var(--fourth-stop)" height="7" width="78" x="241"/>
    <rect fill="var(--fifth-stop)" height="7" width="78" x="321"/>
    <rect fill="var(--fifth-stop)" height="7" width="78" x="401"/>
    <rect fill="var(--fourth-stop)" height="7" width="78" x="481"/>
    <rect fill="var(--third-stop)" height="7" width="78" x="561"/>
    <rect fill="var(--second-stop)" height="7" width="78" x="641"/>
    <rect fill="var(--first-stop)" height="7" width="78" x="721"/>
  </symbol>
</svg>
<svg>
  <symbol id="bottom-brackets-with-text" preserveAspectRatio="xMinYMax" viewBox="0 0 800 40">
    <line [attr.x1]="2" [attr.x2]="2" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="238" [attr.x2]="238" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="1" [attr.x2]="71" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="168" [attr.x2]="238" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <text id="bottom-bracket-label-left-text"
          style="font-size:1em;font-weight:500; fill: var(--grid-horizontal-lines-color)" text-anchor="middle" x="120"
          y="35">
      {{__('footer.low')}}
    </text>

    <line [attr.x1]="242" [attr.x2]="242" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="557" [attr.x2]="557" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="242" [attr.x2]="342" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="457" [attr.x2]="557" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <text id="bottom-bracket-label-left-text"
          style="font-size:1em;font-weight:500; fill: var(--grid-horizontal-lines-color)" text-anchor="middle" x="399.5"
          y="35">
      {{__('footer.mod')}}
    </text>

    <line [attr.x1]="561" [attr.x2]="561" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="798" [attr.x2]="798" [attr.y1]="0" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="561" [attr.x2]="631" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <line [attr.x1]="729" [attr.x2]="799" [attr.y1]="30" [attr.y2]="30"
          stroke-width="0.2em"
          style="stroke: var(--grid-horizontal-lines-color)"/>
    <text id="bottom-bracket-label-left-text"
          style="font-size:1em;font-weight:500; fill: var(--grid-horizontal-lines-color)" text-anchor="middle" x="680"
          y="35">
      {{__('footer.high')}}
    </text>
  </symbol>
</svg>
<svg>
  <symbol id="bottom-blank-area-behind-footer" preserveAspectRatio="xMinYMax" viewBox="0 0 800 110">
    <title>white-solid-rectangle</title>
    <rect fill="var(--background-color)" height="110" width="800"/>
  </symbol>
</svg>
<svg>
  <use [attr.width]="800" [attr.x]="0" [attr.y]="-70" xlink:href="#gradient-boxes-bottom"/>
</svg>
<svg>
  <use [attr.width]="800" [attr.x]="0" [attr.y]="-30" xlink:href="#bottom-gradient-label-text"/>
</svg>
<svg>
  <use [attr.width]="800" [attr.x]="0" [attr.y]="0" xlink:href="#bottom-brackets-with-text"/>
</svg>
