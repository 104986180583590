import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProgressItem} from './progress-item';
import {ProgressSwitchEvent} from './progress-switch-event';

@Component({
  selector: 'app-progress-box',
  templateUrl: './progress-box.component.html',
  styleUrls: ['./progress-box.component.scss']
})
export class ProgressBoxComponent {

  @Input()
    fixed: boolean = false;

  available: ProgressItem<unknown>[];
  current: ProgressItem<unknown>;
  currentIndex: number;
  last: ProgressItem<unknown>;
  lastIndex: number;
  @Input()
    displayProgress: boolean = true;
  @Input()
    displayProgressItems: boolean = false;
  @Input()
    sliderDisplayType: 'progress' | 'arrow' = 'progress';
  @Input()
    backButtonActive: boolean;
  @Input()
    continueButtonActive: boolean;
  @Output()
    shouldSwitchToProgressItem: EventEmitter<ProgressSwitchEvent<unknown>> = new EventEmitter<ProgressSwitchEvent<unknown>>();
  @Output()
    shouldSwitchToPreviousProgressItem: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    shouldSwitchToNextProgressItem: EventEmitter<void> = new EventEmitter<void>();
  progressionPercentage: number | undefined;

  constructor() {
    this.available = [];
    this.currentIndex = 0;
    this.lastIndex = 0;
  }

  @Input()
  set availableProgressItems(available: ProgressItem<unknown>[]) {
    this.available = available;
    this.current = this.calculateCurrent();
    this.currentIndex = this.calculateCurrentIndex();
    this.last = this.calculateLast();
    this.lastIndex = this.calculateLastIndex();
    this.progressionPercentage = this.calculateProgressionPercentageForCurrentIndex();
  }

  @Input()
  set currentProgressItem(current: ProgressItem<unknown>) {
    this.current = current;
    this.currentIndex = this.calculateCurrentIndex();
    this.progressionPercentage = this.calculateProgressionPercentageForCurrentIndex();
  }

  @Input()
  set currentProgressItemIndex(currentIndex: number) {
    this.currentIndex = currentIndex;
    this.current = this.calculateCurrent();
    this.progressionPercentage = this.calculateProgressionPercentageForCurrentIndex();
  }

  @Input()
  set lastProgressItem(last: ProgressItem<unknown>) {
    this.last = last;
    this.lastIndex = this.calculateLastIndex();
  }

  @Input()
  set lastProgressItemIndex(lastIndex: number) {
    this.lastIndex = lastIndex;
    this.last = this.calculateLast();
  }

  onFirstPage(): boolean {
    return this.currentIndex === 0;
  }

  onLastPage(): boolean {
    return this.currentIndex === this.available.length - 1;
  }

  emitProgressSwitchEventTo(progressItem: ProgressItem<unknown>, progressItemIndex: number): void {
    this.shouldSwitchToProgressItem.emit(new ProgressSwitchEvent(progressItem, progressItemIndex));
  }

  private setCorrectly(): boolean {
    return typeof this.available !== typeof undefined
      && this.available.length !== 0
      && typeof this.current !== typeof undefined
      && typeof this.last !== typeof undefined;
  }

  private calculateProgressionPercentageForCurrentIndex(): number | undefined {
    // The currentPageNumber starts at '1'. We therefore never return 0%. Go up to (currentPageNumber / availablePageNumbers + 1[!]),
    // so that we also miss out the 100% mark. -> Leads to a symmetric view.
    // return (this.currentPageNumber / (this.availablePageNumbers + 1)) * 100;
    if (!this.setCorrectly()) {
      return undefined;
    }
    return ((this.currentIndex + 1) / (this.available.length + 1)) * 100.0;
  }

  private calculateCurrent(): ProgressItem<unknown> | undefined {
    if (typeof this.available === typeof undefined || typeof this.currentIndex === typeof undefined) {
      return undefined;
    }
    return this.available[this.currentIndex];
  }

  private calculateCurrentIndex(): number | undefined {
    if (typeof this.available === typeof undefined || typeof this.current === typeof undefined) {
      return undefined;
    }
    return this.available.indexOf(this.current);
  }

  private calculateLast(): ProgressItem<unknown> | undefined {
    if (typeof this.available === typeof undefined || typeof this.lastIndex === typeof undefined) {
      return undefined;
    }
    return this.available[this.lastIndex];
  }

  private calculateLastIndex(): number | undefined {
    if (typeof this.available === typeof undefined || typeof this.last === typeof undefined) {
      return undefined;
    }
    return this.available.indexOf(this.last);
  }

}
