import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {Choice, ChoicesGroup} from '../state/choices-types';
import {ScrollService} from '../../id37/scroll.service';
import {Random} from '../../shared/random';
import {ChoicesService} from '../state/choices.service';
import {SelectState} from '../state/select-state';

@Component({
  selector: 'app-select-menu',
  templateUrl: './select-menu.component.html',
  styleUrls: ['./select-menu.component.scss']
})
export class SelectMenuComponent {

  @ViewChild('scrollContainer', {static: true})
    scrollContainer: ElementRef;

  @Output()
    select: EventEmitter<Choice> = new EventEmitter<Choice>();

  @Output()
    deselect: EventEmitter<Choice> = new EventEmitter<Choice>();

  seed: number = Random.getRandomizer(Number.MAX_SAFE_INTEGER / 2, Number.MAX_SAFE_INTEGER)();
  preSelected: Choice | undefined;

  constructor(public state: SelectState,
              private choicesService: ChoicesService,
              private scrollService: ScrollService) {
  }

  private _choicesGroups: ChoicesGroup[];

  @Input()
  set choicesGroups(choicesGroups: ChoicesGroup[]) {
    this._choicesGroups = choicesGroups;
    this.applyFilter();
  }

  private _filteredChoicesGroups: ChoicesGroup[];

  get filteredChoicesGroups(): ChoicesGroup[] {
    return this._filteredChoicesGroups;
  }

  private _filter: string;

  @Input()
  set filter(filter: string) {
    this._filter = filter;
    this.applyFilter();
  }

  @HostListener('window:keydown', ['$event'])
  preSelectChoice(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.selectPreviousChoice();
    }
    if (event.key === 'ArrowDown') {
      this.selectNextChoice();
    }
    if (event.key === 'Enter') {
      if (!!this.preSelected) {
        if (this.preSelected.selected === true) {
          this.deselect.emit(this.preSelected);
        }
        else {
          this.select.emit(this.preSelected);
        }
      }
    }
  }

  selectPreviousChoice(): void {
    this.preSelected = this.choicesService.calculatePreviousChoice(this.filteredChoicesGroups, this.preSelected);
    this.scrollToPreSelected();
  }

  selectNextChoice(): void {
    this.preSelected = this.choicesService.calculateNextChoice(this.filteredChoicesGroups, this.preSelected);
    this.scrollToPreSelected();
  }

  scrollToPreSelected(): void {
    if (!!this.preSelected && !!this.scrollContainer && !!this.scrollContainer.nativeElement) {
      this.scrollService.scrollToElementInContainer(
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        this.scrollContainer.nativeElement as string | HTMLElement, this.seed + '-' + this.preSelected.value, 'middle'
      );
    }
  }

  private applyFilter(): void {
    if (typeof this._filter === typeof undefined || this._filter.length === 0) {
      this._filteredChoicesGroups = this._choicesGroups;
      return;
    }

    this._filteredChoicesGroups = this._choicesGroups
      // Create copies of the original choices groups...
      .map((choicesGroup) => ({
        id: choicesGroup.id,
        label: choicesGroup.label,
        // in which unnecessary choices are filtered out.
        choices: choicesGroup.choices.filter((choice) =>
          choice.label.toLowerCase().includes(this._filter.toLowerCase()))
      }))
      // Every empty group must not be displayed.H
      .filter((choicesGroup) => choicesGroup.choices.length > 0);
  }

}
