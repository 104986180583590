<app-collapsible-list>
  <app-collapsible-item>
    <app-collapsible-header [title]="howMotivesWorkText.heading"></app-collapsible-header>
    <app-collapsible-body>
      <div [innerHTML]="howMotivesWorkText.mainText" class="id37-text2 text-light"></div>
      <ul class="id37-text2 text-light">
        <li *ngFor="let bulletPoint of howMotivesWorkText.bulletPointTexts" [innerHTML]="bulletPoint"></li>
      </ul>
      <h5 [innerHTML]="howMotivesWorkText.graphicHeading" class="graphic-heading"></h5>

      <img [default]="'assets/images/ID37_Grafik_Verhaltensweisen_en.svg'"
           [src]='"assets/images/ID37_Grafik_Verhaltensweisen_"+locale.toLowerCase()+".svg"'
           alt="example about the mechanism of motives"/>

    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>
