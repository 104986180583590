import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {SimpleDiscountCode} from './simple-discount-code';
import {SimpleDiscountCodeRepresentation} from './simple-discount-code-representation';
import {DiscountCodeApplicationRepresentation} from './discount-code-application-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class DiscountCodeApplicationService {

  constructor(private http: HttpClient) {
  }

  public apply(discountCodeString: string): Observable<SimpleDiscountCode> {
    return this.http.post<SimpleDiscountCodeRepresentation>(
      API_URL + '/discountCodes/apply',
      new DiscountCodeApplicationRepresentation(discountCodeString),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1',
          Accept: 'application/json;version=1'
        })
      }
    ).pipe(map((simpleDiscountCodeRepresentation: SimpleDiscountCodeRepresentation) =>
      SimpleDiscountCode.buildFrom(simpleDiscountCodeRepresentation)
    ));
  }

}
