import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableRow} from '../webapp-table/webapp-table.component';
import {DateTimeModule} from '../../../datetime/datetime.module';
import {Order} from '../../../order/order';
import {OrderStatus} from '../../../order/order-status';
import {TranslatorService} from '../../../translation/translator.service';

@Component({
  selector: 'app-mobile-table',
  templateUrl: './mobile-table.component.html',
  styleUrl: './mobile-table.component.scss'
})
export class MobileTableComponent {
  @Input()
    orders: Order[];
  @Input()
    isUnfinished: boolean;
  @Output()
    downloadInvoicesButtonClickedEvent: EventEmitter<(number | string)[]>
      = new EventEmitter<(number | string)[]>(null);

  constructor(private translatorService: TranslatorService) {
  }
  onClicked(orderId: string, invoiceNr: number) {
    this.downloadInvoicesButtonClickedEvent.emit([orderId, invoiceNr]);
  }

  getProduct(product: string): string {
    if (this.isUnfinished) {
      return this.__('products.unfinishedOrder');
    }
    switch (product) {
      case 'ID37_TEST':
        return this.__('products.id37Test');
      case 'ID37_TEST_WITH_COACHING':
        return this.__('products.id37TestWithCoaching');
      case 'ID37_CHATBOT':
        return this.__('products.id37Chatbot');
      default:
        return this.__('products.unknownProduct');
    }
  }

  getProductStatus(status: string): string {
    switch (status) {
      case OrderStatus.INITIALIZED:
        return 'initialized';
      case OrderStatus.PENDING:
        return 'pending';
      case OrderStatus.CANCELED:
        return 'canceled';
      case OrderStatus.COMPLETED:
        return 'completed';
      case OrderStatus.RETRYING:
        return 'retrying';
      case OrderStatus.UNSUBSCRIBED:
        return 'unsubscribed';
      case OrderStatus.SUBSCRIPTION_TERMINATED:
        return 'subscription_terminated';
      default:
        return 'error';
    }
  }

  __(key: string): string {
    return this.translatorService.translate('orders.' + key);
  }
}
