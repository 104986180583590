import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {CurrentUser} from '../../user/current-user.service';
import {FormControl} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {NewTeamRepresentation} from '../state/new-team-representation';
import {NewTeamUser} from '../state/new-team-user';
import {Team} from '../state/team';
import {UpdateTeam} from '../team.component';

@Component({
  selector: 'app-team-modal',
  templateUrl: './team-modal.component.html',
  styleUrls: ['./team-modal.component.scss']
})
export class TeamModalComponent implements OnInit {

  @Output()
    saved = new EventEmitter<NewTeamRepresentation>();

  @Output()
    edited = new EventEmitter<UpdateTeam>();

  @Output()
    deleted = new EventEmitter<Team>();

  show: boolean;
  editMode: boolean;
  deleteMode: boolean;
  searchKey = '';
  team: Team;
  debounceSearchKey = '';
  searchInputChanged: Subject<string> = new Subject<string>();
  searchInputChangedSubscription: Subscription;
  searching: boolean;

  maxSelectedUsers: boolean = false;
  tooManyUsers: boolean = false;

  control = new FormControl();

  fullName: string = '';
  showList: boolean = true;
  teamName: string = '';
  members: NewTeamUser[] = [];


  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.fullName = this.currentUser.fullName;
    this.createSearchInputChangedSubscription();
    this.tooManyUsers = false;
    this.maxSelectedUsers = false;
  }

  onContinue(): void {
    this.showList = false;
    this.searchKey = '';
    this.debounceSearchKey = '';
  }

  onSave(): void {
    const usersId: string[] = this.members.map(user => user.keycloakId);
    const newTeam: NewTeamRepresentation = {
      creatorId: this.currentUser.keycloakId,
      teamName: this.teamName,
      userIds: usersId
    };

    if (this.editMode) {
      this.edited.emit({ssid: this.team.teamId, team: newTeam});
    }
    else {
      this.saved.emit(newTeam);
    }
  }

  createTeam(): void {
    this.editMode = false;
    this.maxSelectedUsers = false;
    this.resetForm();
    this.show = true;
  }

  editTeam(team: Team): void {
    this.resetForm();
    this.maxSelectedUsers = false;
    this.editMode = true;
    this.team = team;
    this.teamName = team.name;
    team.users.forEach(user => {
      this.members.push({
        keycloakId: user.keycloakId,
        firstName: user.firstName,
        lastName: user.lastName
      });
    });
    if (this.members.length > 40) {
      this.tooManyUsers = true;
    }
    this.show = true;
  }

  closeModal(): void {
    this.show = false;
    this.editMode = false;
    this.resetForm();
  }

  addMember(newUser: NewTeamUser): void {
    if (!this.members.map(user => user.keycloakId).includes(newUser.keycloakId)) {
      this.members.push(newUser);
    }
    if (this.members.length > 40) {
      this.tooManyUsers = true;
    }
  }

  deleteMember(user: NewTeamUser): void {
    this.members = this.members.filter(p => p.keycloakId !== user.keycloakId);
    if (this.members.length <= 40) {
      this.tooManyUsers = false;
    }
  }

  limitReached(value: boolean): void {
    this.maxSelectedUsers = value;
  }

  onDeleteTeam(): void {
    this.deleteMode = true;
  }

  onDeleteCancel(): void {
    this.deleteMode = false;
  }

  onDeleteApprove(): void {
    this.deleted.emit(this.team);
    this.deleteMode = false;
  }

  __(key: string): string {
    return this.translatorService.translate('teamModal.' + key);
  }

  private createSearchInputChangedSubscription(): void {
    if (this.searchInputChangedSubscription) {
      this.searchInputChangedSubscription.unsubscribe();
    }
    this.searchInputChangedSubscription = this.searchInputChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(newSearchKey => {
        if (newSearchKey.trim().length > 1) {
          this.debounceSearchKey = newSearchKey;
        }
        else {
          this.debounceSearchKey = '';
        }
      });
  }

  private resetForm(): void {
    this.members = [];
    this.team = undefined;
    this.tooManyUsers = false;
    this.teamName = '';
    this.searchKey = '';
    this.debounceSearchKey = '';
  }

}
