import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {DiscountCodeRepresentation} from './discount-code-representation';
import {DiscountCode} from './discount-code';
import {NewDiscountCodeRepresentation} from './new-discount-code-representation';
import {CurrentUser} from '../../user/current-user.service';
import {MediaType} from '../../shared/media-type';
import {FileDownloadService} from '../../shared/file-download-service';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class DiscountCodeService {

  constructor(private http: HttpClient,
              private fileDownloadService: FileDownloadService,
              private currentUser: CurrentUser) {
  }

  public findOptimalDiscountCode(codes: DiscountCode[]): DiscountCode | null {
    let selected: DiscountCode | null = null;
    for (const code of codes) {
      if (selected === null) {
        if (code.usable) {
          selected = code;
        }
        continue;
      }
      // We have a selected code!
      if (code.usable && code.discountAsNumber > selected.discountAsNumber) {
        selected = code;
      }
    }
    return selected;
  }

  public getDiscountCodeList(pageNumber: number, pageSize: number): Observable<DiscountCode[]> {
    return this.http.get<DiscountCodeRepresentation[]>(
      API_URL + `/discountCodes?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((discountCodeRepresentations: DiscountCodeRepresentation[]) =>
      discountCodeRepresentations.map(discountCodeRepresentation =>
        DiscountCode.buildFrom(discountCodeRepresentation, this.currentUser))
    ));
  }

  public getDiscountCodeListFromUser(): Observable<DiscountCode[]> {
    return this.http.get<DiscountCodeRepresentation[]>(
      API_URL + '/discountCodes/user',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;version=1'
        })
      }
    ).pipe(map((discountCodeRepresentations: DiscountCodeRepresentation[]) =>
      discountCodeRepresentations.map(discountCodeRepresentation =>
        DiscountCode.buildFrom(discountCodeRepresentation, this.currentUser))
    ));
  }

  public updateDiscountCode(newDiscountCodeRepresentation: NewDiscountCodeRepresentation): Observable<void> {
    return this.http.put<void>(
      API_URL + '/discountCodes',
      newDiscountCodeRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    );
  }

  public addDiscountCode(newDiscountCodeRepresentation: NewDiscountCodeRepresentation): Observable<void> {
    return this.http.post<void>(
      API_URL + '/discountCodes',
      newDiscountCodeRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    );
  }

  public deleteDiscountCode(discountCodeString: string): Observable<any> {
    return this.http.request(
      'delete',
      API_URL + '/discountCodes',
      {
        body: discountCodeString
      }
    );
  }

  public downloadUsageExportExcel(from: string, to: string, codePattern: string, categoryPattern: string): void {
    if (codePattern === '') {
      codePattern = null;
    }
    if (categoryPattern === '') {
      categoryPattern = null;
    }
    this.fileDownloadService.downloadFile(
      API_URL + '/discount-codes/usages/export-excel',
      MediaType.EXCEL,
      'export.xlsx',
      {
        timeRange: {
          from,
          to
        },
        filterOptions: {
          codePattern,
          categoryPattern
        }
      }
    );
  }

}
