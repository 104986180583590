<div #mainContainer
     *ngIf="data$ | async as data; else loading"
     class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-12 padding-0">

    <app-sidebar></app-sidebar>

    <app-report-content
        [humanReadableAccountData]="data.humanReadableAccountData"
        [mainContainer]="mainContainer"
        [motiveScores]="data.motiveScores"
        [report]="data.report"
        [sidebarCollapsed]="!!sidebarComponent ? sidebarComponent.collapsed : false"></app-report-content>

    <div id="close-report">
      <img (click)="closeReport()" alt="close sign" src="assets/icons/ID37_X.svg">
    </div>

  </div>
</div>

<ng-template #loading>
  <app-centered-loading-indicator [atTop]="true"></app-centered-loading-indicator>
</ng-template>
