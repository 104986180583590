import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {TeamUser} from '../../../../team/state/team-user';
import {BubbleScore, BubbleScoreFunctions} from '../../../../team/state/bubble-score';
import {Motive} from '../../../../report/types/motives/motive';
import {Layout} from '../../../../team/team.component';
import {Team} from '../../../../team/state/team';
import {TeamService} from '../../../../team/state/team.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-team-graphic-motives',
  templateUrl: './team-graphic-motives.component.html',
  styleUrls: ['./team-graphic-motives.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeamGraphicMotivesComponent implements OnInit, OnDestroy {

  @Input() layout: Layout;

  @Output() orderChange = new EventEmitter<boolean>();

  teamId: string;
  _members: TeamUser[];

  bubbleScores: BubbleScore[];
  defaultMotives: string[] = Motive.STRING_LIST;
  displayingMotives: string[];
  maxNumberOfMotivesShown: number = 14; // show variation 14 as default to avoid conflict

  resetSub: Subscription;
  motivesVariationChangedSub: Subscription;

  constructor(private changeDetectionRef: ChangeDetectorRef,
              private teamService: TeamService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.resetSub = this.teamService.resetView.subscribe(() => this.computeMotiveGraphics());
    this.motivesVariationChangedSub = this.teamService.motivesVariationChanged.subscribe((variation: number) => {
      this.maxNumberOfMotivesShown = variation;
      this.computeMotiveGraphics();
      this.changeDetectionRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.resetSub.unsubscribe();
  }

  @Input()
  set members(team: Team) {
    this._members = team.users;

    if (!this.teamId) { // this.teamId is initial undefined - !undefined = true
      this.teamId = team.teamId;
      this.computeMotiveGraphics();
    }
    else if (this.teamId === team.teamId) {
      // do nothing: no need to reset the motives order
    }
    else if (this.teamId !== team.teamId) {
      this.teamId = team.teamId;
      this.computeMotiveGraphics();
    }
  }

  computeMotiveGraphics(): void {
    if (this._members.length === 0) {
      this.displayingMotives = this.defaultMotives;
    }
    else {
      this.displayingMotives = this.defaultMotives.slice(0, this.maxNumberOfMotivesShown);
    }
  }


  // return BubbleScore for a certain motive
  getSpecificBubbleScores(motiveName: string): BubbleScore[] | undefined {
    return BubbleScoreFunctions.getSpecificBubbleScores(this._members, motiveName);
  }

  __(key: string): string {
    return this.translatorService.translate('motive.team.' + key.toLowerCase());
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      this.orderChange.emit(true);
    }
    moveItemInArray(this.displayingMotives, event.previousIndex, event.currentIndex);
  }

  getLayoutClass(): string {
    if (this.layout === 'desktop') {
      return 'drag-handle-desktop';
    }
    else if (this.layout === 'tablet') {
      return 'drag-handle-tablet';
    }
    else if (this.layout === 'mobile') {
      return 'drag-handle-mobile';
    }
  }

  hasMotiveEros(motive: string): boolean {
    return motive === Motive.SIN;
  }
}
