export class RecurringRedirectRepresentation {
  resourceId: string;
  redirectUrl: string;

  constructor(resourceId: string, redirectUrl: string) {
    this.resourceId = resourceId;
    this.redirectUrl = redirectUrl;
  }

  static fromResponse(response: any): RecurringRedirectRepresentation {
    if (typeof response.resourceId !== 'string' || typeof response.redirectUrl !== 'string') {
      throw new Error('Invalid response object');
    }
    else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return new RecurringRedirectRepresentation(response.resourceId, response.redirectUrl);
    }
  }
}
