import {Pipe, PipeTransform} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';

@Pipe({
  name: 'JsDateTime',
  pure: true
})
export class JsDatetimePipe implements PipeTransform {

  constructor(private currentUser: CurrentUser) {
  }

  transform(date: Date, args?: any): string {
    if (!date) {
      return '';
    }
    return date.toLocaleDateString(
      this.currentUser.locale,
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    );
  }

}
