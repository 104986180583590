<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <h1 class="title">
      Create a message
    </h1>


    <h3 class="section-start">
      1 - To whom shall this message go?
    </h3>

    <div>
      <button (click)="setUserTargetFilter(userTargetFilters.ALL)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.ALL}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        All users
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.ALL_ADMINISTRATORS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.ALL_ADMINISTRATORS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        All administrators
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.ALL_MASTERS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.ALL_MASTERS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        All masters
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.ALL_PARTNERS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.ALL_PARTNERS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        All partners
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.CHOOSE_MASTERS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.CHOOSE_MASTERS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        Choose masters
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.CHOOSE_PARTNERS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.CHOOSE_PARTNERS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        Choose partners
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.CHOOSE_MASTER_CLIENTS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.CHOOSE_MASTER_CLIENTS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        Clients of a master
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.CHOOSE_PARTNER_CLIENTS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.CHOOSE_PARTNER_CLIENTS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        Clients of a partner
      </button>
      <button (click)="setUserTargetFilter(userTargetFilters.CHOSEN_FILTERED_USERS)"
              [ngClass]="{'selected': userTargetFilter === userTargetFilters.CHOSEN_FILTERED_USERS}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        Choose user
      </button>
    </div>

    <!--Choosing clients from Master or Partner => two selection-lists for Masters/Partners and for their clients-->

    <div *ngIf="searchUsersFromMaster || searchUsersFromPartner" class="search-input-container">

      <input (ngModelChange)='searchInputFilterChanged($event, additionalFilter)' [(ngModel)]="searchFilterKey"
             maxlength="100" placeholder="Search {{additionalFilter}}"
             title="Search"
             type="search">

      <button (click)="abortSearch()" *ngIf="searchFilterKey.length !== 0"
              class="id37-btn id37-btn-sm id37-btn-secondary">
        <img alt="Abort search" src="assets/icons/ID37_X.svg">
      </button>

      <button *ngIf="searchFilterKey.length === 0" class="id37-btn id37-btn-sm id37-btn-secondary">
        <img alt="search" src="assets/icons/arrow_link_12x12.svg"/>
      </button>
    </div>
    <app-select (selection)="selectionOfMasterOrPartner($event)" *ngIf="choices.length > 0" [allowDeselect]="true"
                [choices]="choices"></app-select>
    <br>
    <app-select (selection)="selectionOfUsersOfMasterOrPartner($event)" *ngIf="choicesForReceivers.length > 0"
                [allowDeselect]="true" [choices]="choicesForReceivers"></app-select>


    <!--Choosing/searching of users/masters/partners => one selection list(app-user-search component)-->


    <app-user-search (selected)="addUserForMailing($event)" *ngIf="searchUsers || searchMasters || searchPartners"
                     [additionalFilter]="additionalFilter">
    </app-user-search>
    <br>
    <div *ngIf="planedReceivers.length > 0" class="receivers-preview">
      <div *ngFor="let receiver of planedReceivers"
           class="receiver">{{receiver.firstName}} {{receiver.lastName}}
        <img (click)="deleteReceiver(receiver)"
             alt="edit x"
             class="edit-pencil" src="assets/icons/x.svg" style="float: right">
      </div>
    </div>

    <br>
    <h3 class="section-start">
      2 - How should this message get delivered?
    </h3>

    <div>
      <button (click)="setMessagePublicationMethod(messagePublicationMethods.MAIL)"
              [ngClass]="{'selected': messagePublicationMethod === messagePublicationMethods.MAIL}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        By mail
      </button>
      <button (click)="setMessagePublicationMethod(messagePublicationMethods.NOTIFICATION)"
              [ngClass]="{'selected': messagePublicationMethod === messagePublicationMethods.NOTIFICATION}"
              class="id37-btn id37-btn-bw id37-btn-flat"
              type="button">
        By notification
      </button>
    </div>

    <h3 class="section-start">
      3 - Write your message
    </h3>

    <div class="id37-text2 text-light">
      It is recommended to include an english version of the text. <br>
      As we want to be as user friendly as possible, the message is delivered to each user in the language of the
      user
      if possible. <br>
      If defined, the english text will be taken as the default text, when the message was not defined in the
      users
      language. <br>
      If no (english) default text was provided, the email addresses of any users who could not be informed are
      returned
      to you, so manual message sending can take place.
    </div>

    <div class="alert info" style="flex-direction: column">
      <div class="id37-text2 text-light">
        <div>
          <strong>You can use the following placeholders in your message:</strong>
        </div>
        (Include them WITH the curly braces.)
      </div>
      <div>
        <ol>
          <li>
            {{'{formOfAddress}'}} - Gender specific form of address:
            (de-female) 'Liebe' / (de-male) 'Lieber' / (de-diverse) 'Hallo' / (en) 'Dear' / ...
          </li>
          <li>{{'{receiverFirstName}'}} - First name of the receiver</li>
          <li>{{'{receiverLastName}'}} - Last name of the receiver</li>
          <li>{{'{senderFirstName}'}} - Your first name</li>
          <li>{{'{senderLastName}'}} - Your last name</li>
        </ol>
      </div>
    </div>

    <h5>English - Subject</h5>
    <input #enTitle (change)="setTitle(languages.en, enTitle.value)" type="text">

    <h5>English</h5>
    <quill-editor
        (onContentChanged)="updateReadyState()"
        [(ngModel)]="messages[languages.en].text"
        [formats]="formats"
        [modules]="modules"
        format="html"
        id="en-text-editor"
        trackChanges="all"></quill-editor>

    <h5>German - Subject</h5>
    <input #deTitle (change)="setTitle(languages.de, deTitle.value)" type="text">

    <h5>German</h5>
    <quill-editor
        (onContentChanged)="updateReadyState()"
        [(ngModel)]="messages[languages.de].text"
        [formats]="formats"
        [modules]="modules"
        format="html"
        id="de-text-editor"
        trackChanges="all"></quill-editor>

    <h3 class="section-start">
      4 - Preview your message
    </h3>

    <button *ngIf="!readyToSend[0] || loadingMessagePreview"
            class="id37-btn id37-btn-primary disabled"
            type="button">
      Preview message
    </button>
    <button (click)="getMessagePreview()"
            *ngIf="readyToSend[0] && !loadingMessagePreview"
            class="id37-btn id37-btn-primary"
            type="button">
      Preview message
    </button>

    <app-loading-indicator *ngIf="!messagePreview && loadingMessagePreview"></app-loading-indicator>
    <div *ngIf="loadingMessagePreviewFailed" class="alert danger">
      <div class="id37-text2 text-light">
        Loading the message preview failed unexpectedly.
      </div>
    </div>
    <div *ngIf="!!messagePreview" class="message-preview">
      <div>Subject:</div>
      <div [innerHTML]="messagePreview.title"></div>
      <div>Message:</div>
      <iframe [srcdoc]="messagePreview.text | safeHtml" class="iframe"></iframe>
    </div>


    <h3 class="section-start">
      5 - Preview receivers
    </h3>

    <button *ngIf="!readyToSend[0] || loadingReceivers"
            class="id37-btn id37-btn-primary disabled"
            type="button">
      Preview receivers
    </button>
    <button (click)="getReceiversPreview()"
            *ngIf="readyToSend[0] && !loadingReceivers"
            class="id37-btn id37-btn-primary"
            type="button">
      Preview receivers
    </button>

    <app-loading-indicator *ngIf="!receivers && loadingReceivers"></app-loading-indicator>
    <div *ngIf="!!receivers" class="receivers-preview">
      <div *ngFor="let receiver of receivers"
           class="receiver">{{receiver.firstName}} {{receiver.lastName}}
        - {{receiver.email}}</div>
    </div>
    <div *ngIf="loadingReceiversFailed" class="alert danger">
      <div class="id37-text2 text-light">
        Loading the receivers failed unexpectedly.
      </div>
    </div>
    <div *ngIf="!!receivers" class="receivers-count-info">
      {{receivers.length}} <span *ngIf="receivers.length == 1">user</span> <span
        *ngIf="receivers.length > 1">users</span> will receive your message!
    </div>

    <h3 class="section-start">
      6 - Is advertising
    </h3>
    <label (click)="approval.flip()"></label>
    <id37-checkbox #approval [(checked)]="isAdvertising"></id37-checkbox>

    <h3 class="section-start">
      7 - Send
    </h3>

    <button *ngIf="!readyToSend[0]"
            class="id37-btn id37-btn-primary disabled"
            type="button">
      Send
    </button>
    <button (click)="send()"
            *ngIf="readyToSend[0]"
            class="id37-btn id37-btn-primary"
            type="button">
      Send
    </button>

    <ng-container *ngIf="readyToSend[1].length !== 0">
      <div class="alert info" style="flex-direction: column">
        <div class="id37-text2 text-light">
          <ol>
            <li *ngFor="let message of readyToSend[1]">
              {{message}}
            </li>
          </ol>
        </div>
      </div>
    </ng-container>

    <div *ngIf="success" class="alert success">ok</div>
    <div *ngIf="error" class="alert error">error</div>

    <div *ngIf="notSendTo" class="alert error" style="flex-direction: column">
      <strong>Not send to:</strong>
      <div class="id37-text2 text-light">
        <ol>
          <li *ngFor="let address of notSendTo">{{address}}</li>
        </ol>
      </div>
    </div>

    <h1 class="title section-start">
      Previous messages
      [...]
    </h1>

  </div>
</div>
