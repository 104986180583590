<h3>
  {{ __('cart') }}
</h3>

<app-cart-overview (shown)="setCartOverviewShown($event)" [refresh]="refreshCart"></app-cart-overview>

<ng-container *ngIf="isID37TestPurchase">
  <ng-container *ngIf="isWW; else discountCode">
    <label for="discountCode">
      <div contenteditable="true">{{ __('coachCode.label.A') }} <br> {{ __('coachCode.label.B') }}</div>
    </label>
    <div *ngIf="activeDiscountCode !== null" class="alert info active-discount-info">
      <div class="id37-text1">
        <div>
          {{ __('coachCode.activeInfoA1') }}
          <span class="text-bold">{{ activeDiscountCode.code }}</span>
          {{ __('coachCode.activeInfoA2') }}
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #discountCode>
    <label for="discountCode">{{ __('discountCode') }}</label>
    <div *ngIf="activeDiscountCode !== null" class="alert info active-discount-info">
      <div class="id37-text1">
        <div>
          {{ __('discountCode.activeInfoA1') }}
          <span class="text-bold">{{ activeDiscountCode.code }}</span>
          {{ __('discountCode.activeInfoA2') }}
        </div>
        <div>
          {{ __('discountCode.activeInfoB1') }}
          <span class="text-bold">{{ activeDiscountCode.discountAsPercentage }}</span>
          {{ __('discountCode.activeInfoB2') }}
        </div>
        <span *ngIf="completeActiveDiscountCode">
        {{ __('discountCode.comes.from.category') }}{{ completeActiveDiscountCode.category }}</span>
      </div>
    </div>
  </ng-template>

  <input [(ngModel)]="discountCodeString" id="discountCode" type="text">

  <div *ngIf="isWW" class="id37-text4" style="margin-top: 0.5em">{{ __('coachCode.required') }}</div>
  <div *ngIf="displayDiscountCodeSuccessfullyApplied"
       class="alert success" id="discount-code-application-successful">
    {{ __('discountCode.applicationSuccessful') }}
  </div>
  <div *ngIf="displayDiscountCodeNotSuccessfullyApplied"
       class="alert error" id="discount-code-application-unsuccessful">
    {{ __('discountCode.applicationUnsuccessful') }}
  </div>
  <button (click)="applyDiscountCode()"
          class="id37-btn-outline"
          id="apply-discount-btn">
    {{ __('discountCode.apply') }}
  </button>
</ng-container>

<div class="seller-info paragraph">
  <p class="id37-text1 text-bold">
    {{ __('soldBy') }}
  </p>
  <p class="id37-text1">
    {{ __('companyInfo1') }}<br>
    {{ __('companyInfo2') }}<br>
    {{ __('companyInfo3') }}<br>
    {{ __('companyInfo4') }}
  </p>
  <p class="id37-text1">
    <a href="{{__('agbLink')}}" target="_blank">{{ __('agb') }}</a>
    <span> | </span>
    <a href="{{__('privacyPolicyLink')}}" target="_blank">{{ __('privacyPolicy') }}</a>
    <span> | </span>
    <a href="{{__('imprintLink')}}" target="_blank">{{ __('imprint') }}</a>
  </p>
</div>

<div class="additional-input">
  <div class="approval">
    <id37-checkbox #approval [(checked)]="digitalProductInfoConfirmed"></id37-checkbox>
    <label (click)="approval.flip()" class="id37-text1 checkbox-text">{{ __('digitalProductInfo') }}</label>
  </div>
</div>
<div class="button-row">
  <button (click)="emitBack()"
          class="id37-btn-outline-secondary">
    {{ __('back') }}
  </button>
  <ng-container *ngIf="earlyCompletion; else normalProcess">
    <button *ngIf="!cartOverviewShown || ongoingRequest || !digitalProductInfoConfirmed"
            class="id37-btn-outline" disabled>
      {{ __('completeOrder') }}
    </button>
    <button (click)="completeOrderNow()"
            *ngIf="cartOverviewShown && !ongoingRequest && digitalProductInfoConfirmed"
            class="id37-btn-outline">
      {{ __('completeOrder') }}
    </button>
  </ng-container>
  <ng-template #normalProcess>
    <ng-container *ngIf="!isWW; else discountCodeRequired">
      <button *ngIf="!cartOverviewShown || ongoingRequest || !digitalProductInfoConfirmed"
              class="id37-btn-outline" disabled>
        {{ __('continue') }}
      </button>
      <button (click)="emitContinue()"
              *ngIf="cartOverviewShown && !ongoingRequest && digitalProductInfoConfirmed"
              class="id37-btn-outline">
        {{ __('continue') }}
      </button>
    </ng-container>
    <ng-template #discountCodeRequired>
      <button *ngIf="!cartOverviewShown || ongoingRequest || !digitalProductInfoConfirmed || !activeDiscountCode"
              class="id37-btn-outline" disabled>
        {{ __('continue') }}
      </button>
      <button (click)="emitContinue()"
              *ngIf="cartOverviewShown && !ongoingRequest && digitalProductInfoConfirmed && activeDiscountCode"
              class="id37-btn-outline">
        {{ __('continue') }}
      </button>
    </ng-template>
  </ng-template>
</div>
