import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor() {
  }

  getOverview(): string {
    let output = 'Detecting browsers by ducktyping:<hr>';
    output += 'isFirefox: ' + this.isFirefox() + '<br>';
    output += 'isChrome: ' + this.isChrome() + '<br>';
    output += 'isSafari: ' + this.isSafari() + '<br>';
    output += 'isOpera: ' + this.isOpera() + '<br>';
    output += 'isIE: ' + this.isIE() + '<br>';
    output += 'isEdge: ' + this.isEdge() + '<br>';
    output += 'isBlink: ' + this.isBlink() + '<br>';
    return output;
  }

  isIE(): boolean {
    // Internet Explorer 6-11
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return /* @cc_on!@*/!!document.documentMode;
  }

  isEdge() {
    // Edge 20+
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !this.isIE() && !!window.StyleMedia;
  }

  isChrome(): boolean {
    // Chrome 1+
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  }

  isFirefox(): boolean {
    // Firefox 1.0+
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return typeof InstallTrigger !== 'undefined';
  }

  isOpera(): boolean {
    // Opera 8.0+
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }

  isBlink(): boolean {
    // Blink engine detection
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (this.isChrome() || this.isOpera()) && !!window.CSS;
  }

  isSafari(): boolean {
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const arg = !window.safari || safari.pushNotification;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return /constructor/i.test(window.HTMLElement) || arg.toString() === '[object SafariRemoteNotification]';
  }

}
