<div [formGroup]="getDiscountCodeFormService().form"
     [ngClass]="{'errors': getDiscountCodeFormService().checkErrors()}">

  <div class="clr-row clr-justify-content-center margin-0">
    <div class="clr-col-md-6 clr-col-sm-12">

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="code-label">Code</label>
            <!--suppress XmlDuplicatedId -->
            <input *ngIf="!edit" formControlName="code" id="code-label" type="text">
            <!--suppress XmlDuplicatedId -->
            <input *ngIf="edit" disabled formControlName="code" id="code-label" type="text">
            <div *ngIf="getDiscountCodeFormService().getForm('code').errors?.required"
                 class="alert danger">
              Dieses Feld darf nicht leer sein.
            </div>
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="discount-label">Rabatt</label>
            <input [readOnly]="codeUsed" formControlName="discountAsNumber" id="discount-label" type="text">
            <div *ngIf="getDiscountCodeFormService().getForm('discountAsNumber').errors?.required"
                 class="alert danger">
              Dieses Feld darf nicht leer sein.
            </div>
            <div *ngIf="getDiscountCodeFormService().getForm('discountAsNumber').errors?.pattern"
                 class="alert danger">
              Geben den Rabatt im Intervall zwischen 0.0 und 1 an.
            </div>
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="category-label">Kategorie</label>
            <input formControlName="category" id="category-label" type="text">
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12" style="margin-top: 3em; margin-left: 0.5em;">
          <div class="clr-form-group radio-button">
            <label for="active-yes">Aktiv</label>
            <input formControlName="active" id="active-yes" name="active" type="radio" value="true">
            <label for="active-no">Inaktiv</label>
            <input formControlName="active" id="active-no" name="active" type="radio" value="false">
          </div>
        </div>
      </div>
    </div>

    <div class="clr-col-md-6 clr-col-sm-12">

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="activeFrom-label">Aktiv von</label>
            <app-datetime
              #d1
              [disabled]="codeUsed"
              [type]="'datetime'"
              formControlName="activeFrom"
              id="activeFrom-label">
            </app-datetime>
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="activeUntil-label">Aktiv bis</label>
            <app-datetime
              #d2
              [type]="'datetime'"
              formControlName="activeUntil"
              id="activeUntil-label">
            </app-datetime>
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="maximumUses-label">Maximale Verwendung</label>
            <input formControlName="maximumUses" id="maximumUses-label" type="number">
          </div>
        </div>
      </div>

      <div class="clr-row clr-justify-content-center">
        <div class="clr-col-12">
          <div class="clr-form-group">
            <label for="description-label">Beschreibung</label>
            <input formControlName="description" id="description-label" type="text">
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="clr-form-group">
    <label for="affiliate-label">Affiliate</label>
    <input formControlName="affiliateMail" id="affiliate-label" type="text">
  </div>

  <div class="ok-cancel-group">
    <button (click)="onSubmit()" [disabled]="getDiscountCodeFormService().error" class="id37-btn-outline"
            type="button">OK
    </button>
    <button (click)="onCancel()" class="id37-btn id37-btn-secondary" type="button">Abbrechen</button>
  </div>
</div>
