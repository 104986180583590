import {EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {ProfilePicture} from './profile-picture';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'profilePicture', resettable: true})
export class ProfilePictureStore extends EntityStore<ProfilePicture> {

  constructor() {
    super(ProfilePictureStore.createInitialState());
  }

  private static createInitialState(): ProfilePicture {
    return {
      base64: undefined
    };
  }

}
