import {ProgressItem} from './progress-item';

export class ProgressSwitchEvent<T> {

  public readonly item: ProgressItem<T>;
  public readonly index: number;

  constructor(item: ProgressItem<T>, index: number) {
    this.item = item;
    this.index = index;
  }

}
