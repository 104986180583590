import {RecommendationCountRepresentation} from './recommendation-count-representation';

export class RecommendationCount {

  private readonly _recommendationsLeft: number;

  constructor(recommendationsLeft: number) {
    this._recommendationsLeft = recommendationsLeft;
  }

  static buildFrom(recommendationCountRepresentation: RecommendationCountRepresentation): RecommendationCount {
    return new RecommendationCount(recommendationCountRepresentation.recommendationsLeft);
  }

  get recommendationsLeft(): number {
    return this._recommendationsLeft;
  }

}
