export const environment = {
  production: true,
  FEATURE_PAYMENT: true,
  FEATURE_FRIENDS: true,
  FEATURE_CHAT: true,
  FEATURE_CHATBOT: true,
  FEATURE_RECURRING_PAYMENT: true,
  FEATURE_RECOMMEND_FRIENDS_AND_FAMILY: true,
  FEATURE_RECOMMEND_FRIENDS_AND_FAMILY_FOR_MASTER_CLIENTS: true,
  FEATURE_RECOMMEND_FRIENDS_AND_FAMILY_FOR_PARTNER_CLIENTS: false,
  OPT_IN_NETWORKING: true,
  OPT_IN_ADVERTISING: true,
  FEATURE_TEAMS: true,
  API_URL: 'https://test.id37.io:443/api',
  API_WS_URL: 'wss://test.id37.io:443/api',
  KEYCLOAK_URL: 'https://test.id37.io:443/auth',
  KEYCLOAK_REALM: 'ID37Realm',
  KEYCLOAK_CLIENTID: 'ID37',
  CHATBOT_API: 'https://test.id37.io:443/chatbot',
  // First line: Original public key for the test environment. Not working anymore.
  // Second line: Public key from email (13.07.2020). Should work for now.
  // HEIDELPAY_PUBLIC_KEY: 's-pub-2a10ifVINFAjpQJ9qW8jBe5OJPBx6Gxa',
  HEIDELPAY_PUBLIC_KEY: 's-pub-2a10lGPqetG9wkgzNjLbb75cyuV5ljtJ',
  NOTIFICATION_REFRESH_TIME_IN_SECONDS: 15,
  HIDE_CALENDLY_BOOKED_INFO_AFTER_X_HOURS: 4,
  TEAM: true,
  USE_DUMMY_AUTH: false
};
