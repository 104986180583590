import {MasterInfoRepresentation} from '../master/master-info-representation';
import {AddressRepresentation} from './address-representation';
import {JobAndEducationRepresentation} from './job-and-education-representation';
import {OriginRepresentation} from './origin-representation';
import {PersonalDataRepresentation} from './personal-data-representation';
import {AccountData} from './account-data';
import {PartnerInfoRepresentation} from '../partner/partner-info-representation';

export class AccountDataRepresentation {

  public readonly masterInfo: MasterInfoRepresentation;
  public readonly partnerInfo: PartnerInfoRepresentation;
  public readonly personalData: PersonalDataRepresentation;
  public readonly jobAndEducation: JobAndEducationRepresentation;
  public readonly origin: OriginRepresentation;
  public readonly address: AddressRepresentation;

  constructor(masterInfo: MasterInfoRepresentation,
              partnerInfo: PartnerInfoRepresentation,
              personalData: PersonalDataRepresentation,
              jobAndEducation: JobAndEducationRepresentation,
              origin: OriginRepresentation,
              address: AddressRepresentation) {
    this.masterInfo = masterInfo;
    this.partnerInfo = partnerInfo;
    this.personalData = personalData;
    this.jobAndEducation = jobAndEducation;
    this.origin = origin;
    this.address = address;
  }

  public static toModelEntity(accountDataRepresentation: AccountDataRepresentation): AccountData {
    return {
      masterInfo: !!accountDataRepresentation.masterInfo
        ? MasterInfoRepresentation.toModelEntity(accountDataRepresentation.masterInfo)
        : undefined,
      partnerInfo: !!accountDataRepresentation.partnerInfo
        ? PartnerInfoRepresentation.toModelEntity(accountDataRepresentation.partnerInfo)
        : undefined,
      personalData: PersonalDataRepresentation.toModelEntity(accountDataRepresentation.personalData),
      jobAndEducation: JobAndEducationRepresentation.toModelEntity(accountDataRepresentation.jobAndEducation),
      origin: OriginRepresentation.toModelEntity(accountDataRepresentation.origin),
      address: AddressRepresentation.toModelEntity(accountDataRepresentation.address)
    };
  }

}
