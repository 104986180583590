import {Component, Input, ViewChild} from '@angular/core';
import {MotiveText} from '../types/motives/motive-text';
import {CollapsibleItemComponent} from '../../collapsible/collapsible-item/collapsible-item.component';

@Component({
  selector: 'app-motive',
  templateUrl: './motive.component.html',
  styleUrls: ['./motive.component.scss']
})
export class MotiveComponent {

  @Input()
    motiveName: string;

  @Input()
    motiveText: MotiveText;

  @ViewChild(CollapsibleItemComponent)
    collapsibleItem: CollapsibleItemComponent;

  show = true;

  constructor() {
  }

}
