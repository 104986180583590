import {NewClient} from './new-client';

export class NewClientRepresentation {

  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly groupName: string;
  public readonly variation: string | null;

  constructor(firstName: string, lastName: string, email: string, groupName: string, variation: string | null) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.groupName = groupName;
    this.variation = variation;
  }

  static buildFrom(newClient: NewClient): NewClientRepresentation {
    return new NewClientRepresentation(
      newClient.firstName,
      newClient.lastName,
      newClient.email,
      newClient.groupName,
      newClient.variation === null ? null : newClient.variation.internalName
    );
  }

}
