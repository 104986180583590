import {Friend} from './friend';
import {TestShare} from '../../test/state/test-share';
import {TestResultDistance} from '../../test/state/test-result-distance';
import {FriendshipFunctions} from './friendship-functions';

export type FriendshipStatus = 'friends' | 'requested' | 'pending';

export interface Friendship {

  id: number;
  userA: Friend;
  userB: Friend;
  createdAt: Date;
  inviteApproved: boolean;
  testShares: TestShare[];
  distances: TestResultDistance[];
  computedStatus: FriendshipStatus;

}

export const compareFriendNames = (currentUserId: string): (a: Friendship, b: Friendship) => number => (a: Friendship, b: Friendship) => {
  const af: Friend = FriendshipFunctions.getFriend(a, currentUserId);
  const bf: Friend = FriendshipFunctions.getFriend(b, currentUserId);
  return af.lastName.localeCompare(bf.lastName);
};
