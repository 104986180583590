export enum MessagePublicationMethod {

  MAIL = 'MAIL',
  NOTIFICATION = 'NOTIFICATION'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MessagePublicationMethod {
  export const LIST: MessagePublicationMethod[] = [
    MessagePublicationMethod.MAIL, MessagePublicationMethod.NOTIFICATION
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): MessagePublicationMethod | undefined => {
    name = !!name ? name : '';
    const found = MessagePublicationMethod.LIST
      .find((action: string) => action.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a message publication method with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

