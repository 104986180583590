<input type="number" step="0.01" [(ngModel)]="progress">

<app-circular-ring
    [animated]="true"
    [progress]="progress"
    [startColor]="'#05F5FD'"
    [middleColor]="'#02fa9d'"
    [endColor]="'#00FF3D'"
    (updateProgress)="currentRingProgress = $event">
</app-circular-ring>

<h2>{{currentRingProgress * 100.0 | number:'1.0-0'}}</h2>
