<ng-container *ngFor="let motiveScores of results; index as index; first as first; last as last">
  <ng-container *ngFor="let motiveScore of motiveScores | keyvalue">
    <svg app-key-graphic-motive-score-description
         [motiveScore]="motiveScore.value"
         [index]="index"
         [isLast]="last"
         [isSingle]="first == last"
         [ngClass]="{
            'dot_black': last == first,
            'dot_blue': index == 0 && last != first,
            'dot_green': index == 1 && last != first
          }"/>
  </ng-container>
</ng-container>
