import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PrintDocumentText} from '../types/print-document-text';

@Component({
  selector: 'app-print-document',
  templateUrl: './print-document.component.html',
  styleUrls: ['./print-document.component.scss']
})
export class PrintDocumentComponent {

  @Input()
    printDocumentText: PrintDocumentText;

  @Output()
    shouldPrintReportEmitter: EventEmitter<void>;

  constructor() {
    this.shouldPrintReportEmitter = new EventEmitter();
  }

  printReport(): void {
    this.shouldPrintReportEmitter.emit();
  }

}
