import {Address} from './address';

export class AddressRepresentation {

  public readonly residence: string;
  public readonly city: string;

  constructor(residence: string, city: string) {
    this.residence = residence;
    this.city = city;
  }

  static toModelEntity(addressRepresentation: AddressRepresentation): Address {
    return {
      residence: addressRepresentation.residence,
      city: addressRepresentation.city
    };
  }

}
