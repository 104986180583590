export class GroupRepresentation {

  public readonly name: string;

  public readonly masterId: string;

  constructor(name, masterId) {
    this.name = name;
    this.masterId = masterId;
  }

  static buildFrom(name: string, masterId: string) {
    return new GroupRepresentation(
      name, masterId
    );
  }

}
