import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../environments/environment';

export const initializeKeycloak =
  (keycloakService: KeycloakService): (() => Promise<boolean>) =>
    () => {
      if (!environment.USE_DUMMY_AUTH) {
        return keycloakService.init({
          config: {
            url: environment.KEYCLOAK_URL,
            realm: environment.KEYCLOAK_REALM,
            clientId: environment.KEYCLOAK_CLIENTID
          },
          initOptions: {
            onLoad: 'login-required',
            checkLoginIframe: false
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/assets', '/clients/public']
        });
      }
      else {
        return keycloakService.init({
          config: {
            url: environment.KEYCLOAK_URL,
            realm: environment.KEYCLOAK_REALM,
            clientId: environment.KEYCLOAK_CLIENTID
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
            useNonce: false
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/assets', '/clients/public']
        });
      }
    };

