import {PersonalData} from './personal-data';
import {Gender} from '../client/gender';

export class PersonalDataRepresentation {

  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly gender: string | null;
  public readonly mobilePhone: string;

  constructor(firstName: string, lastName: string, email: string, gender: string | null, mobilePhone: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.gender = gender;
    this.mobilePhone = mobilePhone;
  }

  static toModelEntity(personalDataRepresentation: PersonalDataRepresentation): PersonalData {
    return {
      firstName: personalDataRepresentation.firstName,
      lastName: personalDataRepresentation.lastName,
      email: personalDataRepresentation.email,
      gender: Gender.fromName(personalDataRepresentation.gender),
      mobilePhone: personalDataRepresentation.mobilePhone,
    };
  }

}
