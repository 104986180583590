import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {KeyGraphicHeaderComponent} from './key-graphic/key-graphic-header/key-graphic-header.component';
import {KeyGraphicFooterComponent} from './key-graphic/key-graphic-footer/key-graphic-footer.component';
import {KeyGraphicGridComponent} from './key-graphic/key-graphic-grid/key-graphic-grid.component';
import {KeyGraphicMotiveScoreComponent} from './key-graphic/key-graphic-motive-score/key-graphic-motive-score.component';
import {KeyGraphicMotiveScoreConnectionComponent} from './key-graphic/key-graphic-motive-score-connection/key-graphic-motive-score-connection.component';
import {KeyGraphicMotiveScoreDescriptionComponent} from './key-graphic/key-graphic-motive-score-description/key-graphic-motive-score-description.component';
import {KeyGraphicMotiveScoreDescriptionsComponent} from './key-graphic/key-graphic-motive-score-descriptions/key-graphic-motive-score-descriptions.component';
import {KeyGraphicComponent} from './key-graphic/key-graphic.component';
import {KeyGraphicExampleComponent} from './key-graphic-example/key-graphic-example.component';
import {KeyGraphicPageComponent} from './key-graphic-page/key-graphic-page.component';
import {KeyGraphicPrintLayoutComponent} from './key-graphic-print-layout/key-graphic-print-layout.component';
import {KeyGraphicViewComponent} from './key-graphic-view/key-graphic-view.component';
import {Id37Module} from '../../id37/id37.module';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    Id37Module,
    DirectivesModule
  ],
  declarations: [
    KeyGraphicHeaderComponent,
    KeyGraphicFooterComponent,
    KeyGraphicGridComponent,
    KeyGraphicMotiveScoreComponent,
    KeyGraphicMotiveScoreConnectionComponent,
    KeyGraphicMotiveScoreDescriptionComponent,
    KeyGraphicMotiveScoreDescriptionsComponent,
    KeyGraphicComponent,
    KeyGraphicExampleComponent,
    KeyGraphicPageComponent,
    KeyGraphicPrintLayoutComponent,
    KeyGraphicViewComponent
  ],
  exports: [
    KeyGraphicComponent,
    KeyGraphicPageComponent,
    KeyGraphicPrintLayoutComponent,
    KeyGraphicExampleComponent,
    KeyGraphicViewComponent,
    KeyGraphicGridComponent,
    KeyGraphicHeaderComponent
  ]
})
export class KeyGraphicModule {
}
