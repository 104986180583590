import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-footer]',
  templateUrl: './key-graphic-footer.component.html',
  styleUrls: ['./key-graphic-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicFooterComponent implements AfterViewInit {

  constructor(private translatorService: TranslatorService,
              private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  __(key: string): string {
    return this.translatorService.translate('graphic.' + key);
  }

}
