<div class="id37-table-wrapper">
  <table class="id37-table id37-table-bordered">

    <tr>
      <th>{{__('displayName')}}</th>
      <th>{{__('amount')}}</th>
      <th>{{__('netPrice')}}</th>
      <ng-container *ngIf="showDiscount">
        <th>{{__('discount')}}</th>
      </ng-container>
      <th>{{__('vat')}}</th>
      <th>{{__('finalPrice')}}</th>
    </tr>

    <tr *ngFor="let position of positions">
      <td>{{position.displayName}}</td>
      <td>{{position.amount}}</td>
      <td>{{position.netPriceAsMoney}}</td>
      <ng-container *ngIf="showDiscount">
        <td [ngClass]="{'discounted-from-discount-code': position.discountedFromDiscountCode}">
          {{position.discountAsPercentage}}
        </td>
      </ng-container>
      <td>{{position.vatAsPercentage}}</td>
      <td>{{position.finalPriceAsMoney}}</td>
    </tr>

  </table>
</div>
