import {OrderAddressRepresentation} from './order-address-representation';

export class OrderAddress {

  constructor(firstName: string,
              lastName: string,
              company: string,
              vatIdentification: string,
              country: string,
              state: string,
              zipCode: string,
              city: string,
              street: string,
              houseNr: string) {
    this._firstName = firstName;
    this._lastName = lastName;
    this._company = company;
    this._vatIdentification = vatIdentification;
    this._country = country;
    this._state = state;
    this._zipCode = zipCode;
    this._city = city;
    this._street = street;
    this._houseNr = houseNr;
  }

  private _firstName: string;

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  private _lastName: string;

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  private _company: string;

  get company(): string {
    return this._company;
  }

  set company(value: string) {
    this._company = value;
  }

  private _vatIdentification: string;

  get vatIdentification(): string {
    return this._vatIdentification;
  }

  set vatIdentification(value: string) {
    this._vatIdentification = value;
  }

  private _country: string;

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  private _state: string;

  get state(): string {
    return this._state;
  }

  set state(value: string) {
    this._state = value;
  }

  private _zipCode: string;

  get zipCode(): string {
    return this._zipCode;
  }

  set zipCode(value: string) {
    this._zipCode = value;
  }

  private _city: string;

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  private _street: string;

  get street(): string {
    return this._street;
  }

  set street(value: string) {
    this._street = value;
  }

  private _houseNr: string;

  get houseNr(): string {
    return this._houseNr;
  }

  set houseNr(value: string) {
    this._houseNr = value;
  }

  static createEmpty(): OrderAddress {
    return new OrderAddress(
      '', '', '', '', '', '', '', '', '', ''
    );
  }

  static buildFrom(orderAddressRepresentation: OrderAddressRepresentation): OrderAddress {
    return new OrderAddress(
      orderAddressRepresentation.firstName,
      orderAddressRepresentation.lastName,
      orderAddressRepresentation.company,
      orderAddressRepresentation.vatIdentification,
      orderAddressRepresentation.country,
      orderAddressRepresentation.state,
      orderAddressRepresentation.zipCode,
      orderAddressRepresentation.city,
      orderAddressRepresentation.street,
      orderAddressRepresentation.houseNr
    );
  }

}
