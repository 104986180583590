export enum ChatbotUserStatus {
  // The User has not tried Jay yet.
  chatbot_not_tried_yet = 'CHATBOT_NOT_TRIED_YET',

  // The User is currently trying out the Jay Feature.
  chatbot_test = 'CHATBOT_TEST',

  // The User has full, paid access.
  chatbot_customer = 'CHATBOT_CUSTOMER',

  // The User has canceled his subscription and has access till the end of the month
  chatbot_cancelled = 'CHATBOT_CANCELLED',

  // The User has no access, already tested the Jay Feature.
  chatbot_expired = 'CHATBOT_EXPIRED',

  // The User has been granted Access to Jay by an admin.
  chatbot_invited = 'CHATBOT_INVITED',

  // An error has occurred.
  chatbot_error = 'CHATBOT_ERROR'
}
