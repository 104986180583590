import {Component, HostBinding, OnInit} from '@angular/core';
import {TranslatorService} from '../translation/translator.service';
import {timer} from 'rxjs';
import {take} from 'rxjs/operators';
import {Platform} from '@angular/cdk/platform';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-pwa',
  templateUrl: './pwa.component.html',
  styleUrls: ['./pwa.component.scss']
})
export class PwaComponent implements OnInit {

  @HostBinding('class.shown')
  private _modalOpen: boolean;

  open: boolean;
  iOS: boolean;
  deferredPrompt: any;

  constructor(private cookieService: CookieService,
              private translatorService: TranslatorService,
              private platform: Platform) {
  }

  ngOnInit(): void {
    if (this.cookieService.get('PWA_DECLINED') === 'true') {
      return;
    }
    if (!this.platform.isBrowser) {
      return;
    }
    // Chromium based (Chrome, Edge)
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      this.iOS = true;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    this.cookieService.set('PWA_DECLINED', 'true', 7);
    timer(300)
      .pipe(take(1))
      .subscribe(() => this.show(this.deferredPrompt, mobileType));
  }

  show(promptEvent: any, mobileType: 'ios' | 'android') {
    if (mobileType === 'android') {
      this.iOS = false;
    }
    this._modalOpen = true;
    this.open = true;
    this.deferredPrompt = promptEvent;
  }


  async installPrompt() {
    // Show the install prompt
    this.deferredPrompt.prompt();
    const {outcome} = await this.deferredPrompt.userChoice;
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null;
    this.hide();
  }

  hide() {
    this.open = false;
    this._modalOpen = false;
  }

  __(key: string): string {
    return this.translatorService.translate('pwa.' + key);
  }
}
