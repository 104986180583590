import {Component, Input, OnInit} from '@angular/core';
import {ClrIconModule} from '@clr/angular';
import {TranslatorService} from '../../../translation/translator.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-orders-testing',
  templateUrl: './orders-testing.component.html',
  styleUrl: './orders-testing.component.scss'
})
export class OrdersTestingComponent implements OnInit {
  @Input() canShowTestJayComponent: boolean;

  constructor(private translatorService: TranslatorService,
              private router: Router) {
  }

  ngOnInit(): void {
  }


  openActivateJay() {
    this.router.navigate(['/chatbot']);
  }

  __(key: string): string {
    return this.translatorService.translate('orders.' + key);
  }
}
