<div class="bar-container">
  <button class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-sm id37-btn-rounded-1" (click)="toggleSidebar()">
    <img src="assets/icons/chatbot/SidebarButton.svg" alt="Toggle sidebar button"/>
  </button>
  <div class="conversation-name">
  <span >{{conversationName}}</span>
  </div>
  <button (click)="newChat()" class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-sm id37-btn-rounded-1 header-create-chat">
    <img alt="Create Chat Icon" src="assets/icons/ID37_Chatbot_Create_Chat.svg">
  </button>
</div>
