import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesComponent} from './messages/messages.component';
import {Id37Module} from '../../id37/id37.module';
import {QuillModule} from 'ngx-quill';
import {FormsModule} from '@angular/forms';
import {UserModule} from '../../user/user.module';
import {PipesModule} from '../../pipes/pipes.module';
import {SelectModule} from '../../select/select.module';

@NgModule({
  declarations: [
    MessagesComponent
  ],
  exports: [
    MessagesComponent
  ],
  imports: [
    CommonModule,
    Id37Module,
    QuillModule.forRoot(),
    FormsModule,
    UserModule,
    PipesModule,
    SelectModule
  ]
})
export class MessagesModule {
}
