import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {RouterModule} from '@angular/router';
import {NotificationsComponent} from './notifications.component';
import {NotificationComponent} from './notification/notification.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TestAccessGrantedMessageComponent} from './notification/messages/test-access-granted-message/test-access-granted-message.component';
import {AccountCreatedMessageComponent} from './notification/messages/account-created-message/account-created-message.component';
import {TestInvitationMessageComponent} from './notification/messages/test-invitation-message/test-invitation-message.component';
import {TestFinishedMessageComponent} from './notification/messages/test-finished-message/test-finished-message.component';
import {TestRevokedMessageComponent} from './notification/messages/test-revoked-message/test-revoked-message.component';
import {FriendshipInvitationMessageComponent} from './notification/messages/friendship-invitation-message/friendship-invitation-message.component';
import {FriendshipAcceptedMessageComponent} from './notification/messages/friendship-accepted-message/friendship-accepted-message.component';
import {TestExpiringMasterMessageComponent} from './notification/messages/test-expiring-master-message/test-expiring-master-message.component';
import {TestExpiringClientMessageComponent} from './notification/messages/test-expiring-client-message/test-expiring-client-message.component';
import {TestExpiredOfClientMessageComponent} from './notification/messages/test-expired-of-client-message/test-expired-of-client-message.component';
import {LocalizedRawMessageComponent} from './notification/messages/localized-raw-message/localized-raw-message.component';
import {RawMessageComponent} from './notification/messages/raw-message/raw-message.component';
import {LocalizedRawTitleComponent} from './notification/titles/localized-raw-title/localized-raw-title.component';
import {RawTitleComponent} from './notification/titles/raw-title/raw-title.component';
import {SuperlikeMessageComponent} from './notification/messages/superlike-message/superlike-message.component';
import {DateTimeModule} from '../datetime/datetime.module';
import {TestShareChangedMessageComponent} from './notification/messages/motives-changed-message/test-share-changed-message.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ClarityModule,
    FontAwesomeModule,
    DateTimeModule
  ],
  declarations: [
    NotificationsComponent,
    NotificationComponent,
    TestAccessGrantedMessageComponent,
    AccountCreatedMessageComponent,
    TestInvitationMessageComponent,
    TestFinishedMessageComponent,
    TestRevokedMessageComponent,
    FriendshipInvitationMessageComponent,
    FriendshipAcceptedMessageComponent,
    TestExpiringMasterMessageComponent,
    TestExpiringClientMessageComponent,
    TestExpiredOfClientMessageComponent,
    LocalizedRawMessageComponent,
    RawMessageComponent,
    LocalizedRawTitleComponent,
    RawTitleComponent,
    LocalizedRawTitleComponent,
    SuperlikeMessageComponent,
    TestShareChangedMessageComponent
  ],
  exports: [
    NotificationsComponent
  ]
})
export class NotificationsModule {
}
