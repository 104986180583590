<div class="id37-top-gradient"></div>

<div [ngClass]="{'mobileMenuOpen': mobileMenuOpen, 'mobileMenuClosed': !mobileMenuOpen}" class="id37-top-nav">
  <div (click)="openMobileMenu()" *ngIf="!mobileMenuOpen" class="burger-icon">
    <app-burger-icon></app-burger-icon>
  </div>
  <div (click)="closeMobileMenu()" *ngIf="mobileMenuOpen" class="close-icon">
    <app-close-icon></app-close-icon>
  </div>

  <!-- LOGO -->
  <div class="logo">
    <app-logo (activated)="closeMobileMenu()"></app-logo>
  </div>

  <!-- LINKS -->
  <div class="links">
    <a (click)="closeMobileMenu()" routerLinkActive="active" class="link" routerLink="/dashboard">
      <span class="text">{{__('dashboard')}}</span>
    </a>

    <ng-container *ngIf="hasTestAccessGranted">
      <a [routerLink]="['/report', userId, testId]" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
        <span class="text">{{__('analysis')}}</span>
      </a>

      <a [routerLink]="['/key-graphic', testId]" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
        <span class="text">{{__('graphic')}}</span>
      </a>
    </ng-container>

    <ng-container *rolesAllowed="'master'">
      <a [routerLink]="['/my-customers']" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
        <span class="text">{{__('customers')}}</span>
      </a>
    </ng-container>

    <ng-container *ngIf="hasBot">
        <a *featureAllowed="'chatbot'" [routerLink]="['/chatbot']" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
          <span class="text">{{__('chatbot')}}</span>
        </a>
    </ng-container>

    <ng-container *rolesAllowed="'master'">
      <a [routerLink]="['/toolbox']" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
        <span class="text">{{__('toolbox')}}</span>
      </a>
    </ng-container>

    <a [routerLink]="['/friends']" (click)="closeMobileMenu()" class="link" routerLinkActive="active">
      <span class="text">{{__('network')}}</span>
    </a>

    <a *featureAllowed="'teams'" [routerLink]="['/teams']" (click)="closeMobileMenu()"
        class="link" routerLinkActive="active">
      <span class="text">{{__('teams')}}</span>
    </a>

    <a *featureAllowed="'recommend'" (click)="openRecommendModal(); closeMobileMenu()" class="link" routerLinkActive="active">
      <span class="text">{{__('recommend')}}</span>
    </a>

    <div id="mobile-user-actions">
      <div class="separator"></div>
      <a (click)="closeMobileMenu()" class="link" routerLink="/my-account" routerLinkActive="active">
        <span class="text">{{__('myAccount')}}</span>
      </a>
      <a (click)="closeMobileMenu()" class="link" routerLink="/orders" routerLinkActive="active">
        <span class="text">{{__('orders')}}</span>
      </a>
      <a (click)="doLogout()" class="link">
        <span class="text">{{__('logOut')}}</span>
      </a>
    </div>
  </div>

  <div class="language-choices">
    <clr-dropdown>
      <button clrDropdownTrigger style="display: flex">
        <span style="margin-right: 0.25em;">{{___('language.' + locale, locale)}}</span>
        <span class="arrow-down"></span>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <ng-container *ngFor="let loc of locales">
          <a (click)="setLocale(loc)" clrDropdownItem>
            {{___('language.' + loc, loc)}}
          </a>
        </ng-container>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

  <app-chat-overview *featureAllowed="'chat'"></app-chat-overview>

  <app-notification-overview></app-notification-overview>

  <!-- USER INFO -->
  <div class="user-actions">
    <clr-dropdown>
      <!-- This ID is used by an AWS canary test! -->
      <div class="nav-text" clrDropdownTrigger id="user-actions-dropdown-trigger">
        <div class="name-container">
          <span style="font-weight: normal">{{__('hello')}},</span>
          <br>
          <span>{{fullName.trim()}}</span>
        </div>
        <app-profile-picture></app-profile-picture>
      </div>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a clrDropdownItem routerLink="/my-account">
          {{__('myAccount')}}
        </a>
        <a clrDropdownItem routerLink="/orders">
          {{__('orders')}}
        </a>
        <!-- This ID is used by an AWS canary test! -->
        <a (click)="doLogout()" clrDropdownItem id="user-action-logout">
          {{__('logOut')}}
        </a>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>

</div>

<!-- recommend ID37 modal -->
<app-user-invite-option #userInviteOptionComponent></app-user-invite-option>

<!-- OPTIONAL SPACER -->
<div class="id37-top-nav-spacer"></div>
