<div [ngClass]="{'contact-selected': !!activeContactUserId}" class="chat-container">
  <div class="chat-contact-pane">
    <app-chat-contacts></app-chat-contacts>
  </div>
  <div class="chat-content-pane">
    <app-active-contact-info></app-active-contact-info>
    <app-chat-messages></app-chat-messages>
    <ng-container *ngIf="!!activeContactUserId">
      <app-chat-input [activeContactUserId]="activeContactUserId"></app-chat-input>
    </ng-container>
  </div>
</div>

