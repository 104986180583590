import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-distance-score-description]',
  templateUrl: './distance-score-description.component.html',
  styleUrls: ['./distance-score-description.component.scss']
})
export class DistanceScoreDescriptionComponent implements OnInit {

  @Input()
    motiveScoreA: MotiveScore;

  @Input()
    motiveScoreB: MotiveScore;

  @Input()
    index: number;

  motive: string;
  distance: number;

  readonly xBase: number = 790;
  readonly yBase: number = 70;
  readonly gridStep = 80;
  y: number;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.motive = this.__('motive.' + this.motiveScoreA.motive.toLocaleLowerCase());
    this.distance = Math.abs(this.motiveScoreA.score - this.motiveScoreB.score);
    this.y = (this.index * this.gridStep) + this.yBase;
  }

  __(key: string): string {
    return this.translatorService.translate(key);
  }

}
