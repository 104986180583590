import {QueryEntity} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {ProfilePicture} from './profile-picture';
import {ProfilePictureStore} from './profile-picture-store';
import {Observable} from 'rxjs';
import {CurrentUser} from '../user/current-user.service';
import {take} from 'rxjs/operators';
import {ProfilePictureLoadService} from './profile-picture-load-service';

@Injectable({providedIn: 'root'})
export class ProfilePictureQuery extends QueryEntity<ProfilePicture, ProfilePicture, string> {

  constructor(protected store: ProfilePictureStore,
              private currentUser: CurrentUser,
              private profilePictureLoadService: ProfilePictureLoadService) {
    super(store);
  }

  hasLoadedProfilePictureForCurrentUser(): boolean {
    return this.hasLoadedProfilePicture(this.currentUser.keycloakId);
  }

  hasLoadedProfilePicture(userId: string | undefined): boolean {
    if (typeof userId === typeof undefined) {
      return this.hasLoadedProfilePictureForCurrentUser();
    }
    return this.hasEntity(userId);
  }

  hasProfilePictureForCurrentUser(): boolean {
    return this.hasProfilePicture(this.currentUser.keycloakId);
  }

  hasProfilePicture(userId: string | undefined): boolean {
    if (typeof userId === typeof undefined) {
      return this.hasLoadedProfilePictureForCurrentUser();
    }
    if (!this.hasLoadedProfilePicture(userId)) {
      return false;
    }
    const profilePicture = this.getEntity(userId);
    return !!profilePicture.base64 && profilePicture.base64.length !== 0;
  }

  selectProfilePictureForCurrentUser(): Observable<ProfilePicture> {
    return this.selectProfilePicture(this.currentUser.keycloakId);
  }

  selectProfilePicture(userId: string | undefined): Observable<ProfilePicture> {
    if (typeof userId === typeof undefined) {
      return this.selectProfilePictureForCurrentUser();
    }
    if (!this.hasLoadedProfilePicture(userId)) {
      this.profilePictureLoadService.loadProfilePicture(userId).pipe(take(1)).subscribe();
    }
    return this.selectEntity(userId);
  }

  /**
   * Basically it is like selectProfilePicture but without loading the picture in case the user hasn't loaded it yet
   The loading needs to trigger somewhere manually else
   *
   * @param userId The keycloak id of the user for which the profile picture should be returned.
   */
  selectProfilePictureWithoutLoading(userId: string | undefined): Observable<ProfilePicture> {
    if (typeof userId === typeof undefined) {
      return this.selectProfilePictureForCurrentUser();
    }
    return this.selectEntity(userId);
  }

  /**
   * Just returns the current state of that users profile picture. Use with caution.
   *
   * @param userId The keycloak id of the user for which the profile picture should be returned.
   */
  getProfilePicture(userId: string): ProfilePicture {
    return this.getEntity(userId);
  }

}
