import {TestVariation} from './test-variation';

export class TestVariationNameRepresentation {

  public readonly variationName: string;

  private constructor(variationName: string) {
    this.variationName = variationName;
  }

  static of(variation: TestVariation): TestVariationNameRepresentation {
    return new TestVariationNameRepresentation(variation.internalName);
  }

}
