import {Component, Input} from '@angular/core';
import {ProfileSpecialText} from '../types/profile-special-text';

@Component({
  selector: 'app-profile-special',
  templateUrl: './profile-special.component.html',
  styleUrls: ['./profile-special.component.scss']
})
export class ProfileSpecialComponent {

  @Input()
    profileSpecialText: ProfileSpecialText;

  constructor() {
  }

}
