import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {TestState, TestStore} from './test.store';
import {TestService} from './test.service';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, map, mergeAll, take} from 'rxjs/operators';
import {TestIdQuery} from './test-id.query';
import {TestIds} from './test-id.store';
import {Test} from './test';
import {CurrentUser} from '../../user/current-user.service';

@Injectable({providedIn: 'root'})
export class TestQuery extends QueryEntity<TestState, Test, string> {

  constructor(protected store: TestStore,
              private testIdQuery: TestIdQuery,
              private testService: TestService,
              private currentUser: CurrentUser) {
    super(store);
  }

  hasTest(userId: string, testId: string): boolean {
    return this.hasEntity([userId, testId].join(':'));
  }

  selectTestForCurrentUser(testId: string): Observable<Test> {
    return this.selectTest(this.currentUser.keycloakId, testId);
  }

  selectTest(userId: string, testId: string): Observable<Test> {
    if (!this.hasTest(userId, testId)) {
      this.testService.loadTest(userId, testId).pipe(take(1)).subscribe();
    }
    return this.selectEntity([userId, testId].join(':')).pipe(filter(data => !!data));
  }

  selectTestsForCurrentUser(): Observable<Test[]> {
    return this.selectTests(this.currentUser.keycloakId);
  }

  selectTests(userId: string): Observable<Test[]> {
    return this.testIdQuery.selectTestIds(userId)
      .pipe(
        map((testIds: TestIds): Observable<Test>[] =>
          testIds.ids.map(testId =>
            this.selectTest(userId, testId))),
        map((observables: Observable<Test>[]): Observable<Test[]> =>
          observables.length === 0
            ? of([])
            : combineLatest(observables)),
        mergeAll()
      );
  }

}
