import {BasicUserInvitationRepresentation} from './basic-user-invitation-representation';
import {RecommendationType} from '../../recommendation/recommendation-type';

export class RecommendationUserInvitationRepresentation extends BasicUserInvitationRepresentation {

  public readonly recommendationType: RecommendationType;

  constructor(email: string, firstName: string, lastName: string, recommendationType: RecommendationType) {
    super(email, firstName, lastName);
    this.recommendationType = recommendationType;
  }

}
