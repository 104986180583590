<div class="chartWrapper">
  <div class="canvas-container">
    <canvas id="polar-canvas"></canvas>
  </div>

  <div class="button-container">
    <ng-container *ngFor="let b of motiveButtons">
      <button
          (click)="initChartData(b.motive)"
          [ngClass]="{'selected': variation === b.motive}"
          class="id37-btn id37-btn-bw id37-btn-flat">
        {{b.displayName}}
      </button>
    </ng-container>
  </div>
</div>




