import {QueryEntity} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {ReportState, ReportStore} from './report-store';
import {Observable, zip} from 'rxjs';
import {CurrentUser} from '../user/current-user.service';
import {filter, map, take} from 'rxjs/operators';
import {ReportService} from './report-service';
import {AccountData, AccountDataFunctions} from '../account/account-data';
import {AccountDataQuery} from '../account/account-data-query';
import {CountryService} from '../user-data/country.service';
import {ReportFunctions} from './report-functions';
import {Report} from './types/report';

@Injectable({providedIn: 'root'})
export class ReportQuery extends QueryEntity<ReportState, Report, string> {

  constructor(protected store: ReportStore,
              private currentUser: CurrentUser,
              private reportService: ReportService,
              private reportFunctions: ReportFunctions,
              private accountDataQuery: AccountDataQuery,
              private countryService: CountryService) {
    super(store);
  }

  hasReport(userId: string, testId: string): boolean {
    return this.hasEntity([userId, testId].join(':'));
  }

  selectProcessedReport(userId: string, testId: string): Observable<Report> {
    return this.selectReportAndAccountData(userId, testId)
      .pipe(map(([report, accountData]) => this.processedReport(report, accountData)));
  }

  private selectReportAndAccountData(userId: string, testId: string): Observable<[Report, AccountData]> {
    return zip(
      this.selectReport(userId, testId),
      this.accountDataQuery.selectAccountData(userId)
    );
  }

  private selectReport(userId: string, testId: string): Observable<Report> {
    if (!this.hasReport(userId, testId)) {
      this.reportService.loadReport(userId, testId).pipe(take(1)).subscribe();
    }
    return this.selectEntity([userId, testId].join(':')).pipe(filter(data => !!data));
  }

  private processedReport(unprocessedReport: Report, unprocessedAccountData: AccountData): Report {
    return this.reportFunctions.reportWithReplacements(
      unprocessedReport,
      AccountDataFunctions.humanReadable(unprocessedAccountData, this.countryService, this.currentUser.locale)
    );
  }

}
