@if (emailSettings) {
  <div class="email-settings">
    <div class="email-setting">

      <div [innerHTML]="__('informAboutUnreadChatMessages.label')" class="email-setting-label"></div>
      <div class="switch-container">
        <app-toggle-switch
          (valueChange)="updateInformAboutUnreadChatMessages($event)"
          [value]="emailSettings.informAboutUnreadChatMessages"
          class="email-setting-switch">
        </app-toggle-switch>
      </div>
      <div
        *ngIf="emailSettings.informAboutUnreadChatMessages"
        [innerHTML]="__('informAboutUnreadChatMessages.enabledText')"
        class="email-setting-text id37-text3">
      </div>
      <div
        *ngIf="!emailSettings.informAboutUnreadChatMessages"
        [innerHTML]="__('informAboutUnreadChatMessages.disabledText')"
        class="email-setting-text id37-text3">
      </div>
      <div class="placeholder"></div>


      <div [innerHTML]="__('productInformation.label')" class="product-information-label"></div>
      <div class="switch-container">
        <app-toggle-switch
          (valueChange)="updateInformAboutProductInformation($event)"
          [value]="emailSettings.informAboutProductInformations"
          class="product-information-switch">
        </app-toggle-switch>
      </div>
      <div
        *ngIf="emailSettings.informAboutProductInformations"
        [innerHTML]="__('productInformation.enabledText')"
        class="product-information-text id37-text3">
      </div>
      <div
        *ngIf="!emailSettings.informAboutProductInformations"
        [innerHTML]="__('productInformation.disabledText')"
        class="product-information-text id37-text3">
      </div>
      <div class="placeholder"></div>

      @if (emailSettings.informAboutNetworking) {
        <div [innerHTML]="__('networking.labelEnabled')" class="networking-label"></div>
        <div
          [innerHTML]="__('networking.enabledText')"
          class="networking-text id37-text3">
        </div>
      } @else {
        <div [innerHTML]="__('networking.labelDisabled')" class="networking-label"></div>
        <div
          [innerHTML]="__('networking.disabledText')"
          class="networking-text id37-text3">
        </div>
      }
      <div class="switch-container">
        <app-toggle-switch
          (valueChange)="updateInformAboutNetworking($event)"
          [value]="emailSettings.informAboutNetworking"
          class="networking-switch">
        </app-toggle-switch>
      </div>
      <div class="placeholder"></div>
    </div>
  </div>
}
