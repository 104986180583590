import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ChatbotDocumentService } from '../../chatbot/api-services/chatbot-document.service';
import { ChatbotDocument } from '../../chatbot/state/chatbot-document';
import {catchError, take} from 'rxjs/operators';
import { ChatbotDocumentsFormComponent } from './chatbot-documents-form/chatbot-documents-form.component';
import {ChatbotDocumentsFormService} from './chatbot-documents-form.service';
import {TranslatorService} from '../../translation/translator.service';
import {of} from 'rxjs';


@Component({
  selector: 'app-documents',
  templateUrl: './chatbot-documents.component.html',
  styleUrls: ['./chatbot-documents.component.scss']
})
export class ChatbotDocumentsComponent implements OnInit {

  @ViewChild('documentForm')
    documentForm: ChatbotDocumentsFormComponent;
  @ViewChild('fileInput', {static: true})
    fileInput: ElementRef;

  searchKey: string;
  clickDelete: boolean = false;
  clickOnEdit: boolean = false;
  documentList: ChatbotDocument[] = [];
  documentView: ChatbotDocument[] = [];
  loading: boolean;

  lastPage = 1;
  currentPage = 1;
  pageSize = 50;
  allDocumentsLoaded: boolean = false;
  maxContentLength: number = 200;

  clickOnImport: boolean = false;
  selectedFile: File;
  success = false;
  failureState = false;
  failure: { importErrors: [{ rowNumber: number; errorMessage: string }] };
  inProgress = false;

  constructor(
    private documentFormService: ChatbotDocumentsFormService,
    private documentService: ChatbotDocumentService,
    private translatorService: TranslatorService
  ) {}

  ngOnInit(): void {
    this.getDocumentList();
  }

  onCreate(): void {
    this.documentFormService.edit = false;
    this.documentFormService.initializeFormGroup();
    this.clickOnEdit = true;
  }

  applyFilter(): void {
    if (this.searchKey.length !== 0) {
      this.documentView = this.documentList.filter(d =>
        d.title.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1 ||
        d.summary.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1 ||
        d.content.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1
      );
    }
    else {
      this.documentView = this.documentList;
    }
  }

  onClear(): void {
    this.searchKey = '';
    this.applyFilter();
  }

  onEdit(document: ChatbotDocument): void {
    this.documentFormService.edit = true;
    this.documentFormService.populateForm(document);
    this.clickOnEdit = true;
  }

  onDelete(document: ChatbotDocument): void {
    this.documentFormService.populateForm(document);
    this.openDeleteModal();
  }

  openDeleteModal(): void {
    this.clickDelete = true;
  }

  closeDeleteModal(): void {
    this.clickDelete = false;
  }

  onDeleteConfirm(): void {
    const documentId: number = this.documentFormService.form.value.document_id;
    this.documentService.deleteDocumentByID(documentId)
      .pipe(take(1))
      .subscribe(() => {
        this.resetDocumentList();
        this.getDocumentList();
      });
  }

  getDocumentList(): void {
    this.loading = true;
    this.documentService.getDocumentList()
      .pipe(take(1))
      .subscribe(
        (list: ChatbotDocument[]) => {
          // this.allDocumentsLoaded = list.length < this.pageSize;
          this.documentList.push(...list);
          this.documentView = list;
          this.lastPage = Math.ceil(this.documentList.length / this.pageSize);
          this.loading = false;
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
      );
  }

  getUpdatedValue(value: boolean): void {
    this.clickOnEdit = value;
  }

  getDocumentFormService(): ChatbotDocumentsFormService {
    return this.documentFormService;
  }

  nextPage(): void {
    if (this.currentPage + 1 <= this.lastPage) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  // calcLastPage(): number {
  //   let a = (this.currentPage + 1) * this.pageSize;
  //   if (a > this.documentList.length) {
  //     a = this.documentList.length;
  //   }
  //   return a;
  // }

  resetDocumentList(): void {
    this.currentPage = 0;
    this.lastPage = 0;
    this.documentList = [];
    this.documentView = [];
    this.allDocumentsLoaded = false;
  }

  truncateContent(document: ChatbotDocument): string {
    if (document.show_more || document.content.length <= this.maxContentLength){
      return document.content;
    }
    return document.content.slice(0, this.maxContentLength) + '...';
  }


  toggleContent(document: ChatbotDocument): void {
    document.show_more = !document.show_more;
  }

  onImportFile(): void{
    const formData = new FormData();
    formData.append('upload', this.selectedFile, this.selectedFile.name);
    this.documentService.importExcel(formData).pipe(
      catchError(err => {
        if (err.status === 400) {
          this.failure = err.error;
          this.failureState = true;
        }
        else {
          this.failure = null;
          this.failureState = false;
        }
        this.success = false;
        this.selectedFile = null;
        this.inProgress = false;
        this.fileInput.nativeElement.value = '';
        return of(err);
      })
    )
      .subscribe(response => {
        this.success = true;
        this.failure = null;
        this.failureState = false;
        this.inProgress = false;
        this.selectedFile = null;
        this.fileInput.nativeElement.value = '';
        this.resetDocumentList();
        this.getDocumentList();
      });
  }
  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.failure = null;
    this.failureState = false;
    this.success = false;
  }
  openImportModal(): void {
    this.clickOnImport = true;
    this.selectedFile = null;
    this.failure = null;
    this.failureState = false;
    this.success = false;
  }

  closeImportModal(): void {
    this.clickOnImport = false;
    this.selectedFile = null;
    this.failure = null;
    this.failureState = false;
    this.success = false;
    this.fileInput.nativeElement.value = '';
  }
  __(key: string): string {
    return this.translatorService.translate('chatbot-documents.' + key);
  }

}
