import {Message} from './message';

export class MessageRepresentation {

  public readonly title: string;
  public readonly text: string;

  constructor(title: string, text: string) {
    this.title = title;
    this.text = text;
  }

  public static toModelEntity(messageRepresentation: MessageRepresentation): Message {
    return {
      title: messageRepresentation.title,
      text: messageRepresentation.text
    };
  }

  public static fromModelEntity(message: Message): MessageRepresentation {
    return new MessageRepresentation(
      message.title,
      message.text
    );
  }

}
