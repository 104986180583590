import {Pipe, PipeTransform} from '@angular/core';
import {TestOriginInvitation} from './test-origin-invitation';

@Pipe({
  name: 'TestOriginInvitationCast',
  pure: true
})
export class TestOriginInvitationCastPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): TestOriginInvitation {
    return value as TestOriginInvitation;
  }

}
