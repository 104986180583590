<div class="datetime-calendar-month">

  <div class="actions">

    <div (click)="selectPreviousMonth()"
         *ngIf="!showYearSelection && !showMonthSelection"
         class="previous arrow-left">
    </div>

    <div (click)="selectPreviousYear()"
         *ngIf="showMonthSelection"
         class="previous arrow-left">
    </div>

    <div (click)="selectPreviousYears()"
         *ngIf="showYearSelection"
         class="previous arrow-left">
    </div>

    <div (click)="destroyYearSelection()" *ngIf="showYearSelection" class="current-date">
      {{years[0].number}} - {{years[years.length - 1].number}}
    </div>

    <div (click)="initYearSelection()" *ngIf="showMonthSelection" class="current-date">
      {{moment(selected).format('YYYY')}}
    </div>

    <div (click)="initYearSelection()" *ngIf="!showMonthSelection && !showYearSelection" class="current-date">
      {{moment(selected).format('MMMM')}}
      {{moment(selected).format('YYYY')}}
    </div>

    <div (click)="selectNextMonth()"
         *ngIf="!showYearSelection && !showMonthSelection"
         class="next arrow-right">
    </div>

    <div (click)="selectNextYear()"
         *ngIf="showMonthSelection"
         class="next arrow-right">
    </div>

    <div (click)="selectNextYears()"
         *ngIf="showYearSelection"
         class="next arrow-right">
    </div>
  </div>

  <ng-container *ngIf="showYearSelection">

    <div class="years">
      <ng-container *ngFor="let year of years">
        <div (click)="selectYear(year)"
             [ngClass]="{'is-now': year.isNow, 'disabled': year.disabled}"
             class="year">
          {{year.number}}
        </div>
      </ng-container>
    </div>

  </ng-container>

  <ng-container *ngIf="showMonthSelection">

    <div class="months">
      <ng-container *ngFor="let month of months">
        <div (click)="selectMonth(month)"
             [ngClass]="{'is-now': month.isNow, 'disabled': month.disabled}"
             class="month">
          {{month.name}}
        </div>
      </ng-container>
    </div>

  </ng-container>

  <ng-container *ngIf="!showYearSelection && !showMonthSelection">

    <div class="weekday-names">
      <ng-container *ngFor="let dayName of shortWeekDayNames">
        <div class="weekday-name">{{dayName}} </div>
      </ng-container>
    </div>

    <ng-container *ngFor="let week of weeks">
      <div class="week">
        <ng-container *ngFor="let day of week.days">
          <div
              (click)="selectDay(day)"
              [ngClass]="{
                'not-in-month': !day.inCurrentMonth,
                'disabled': day.disabled,
                'selected': day.selected
              }"
              class="day">
            <span [ngClass]="{'is-now': day.isNow}" class="text">
              {{day.displayName}}
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>

  </ng-container>

</div>
