<div (click)="toggleEye()" class="nav-bubble"
     [attr.aria-describedby]="tooltipUsername"
     [ngStyle]="{
       'width': '3em',
       'height': '3em',
       'border': '1.9px solid ' + borderColor,
       'background-color': fillColor}"
     [ngClass]="{'name-bubble': cssClass === 'name-bubble','placeholder-bubble': cssClass === 'placeholder-bubble'}">

  <ng-container *ngIf="!teamUsers; else placeholder">
    <div *ngIf="hasProfilePicture$ | async as profilePicture; else noPic" [ngClass]="{'hide': hide}">
      <app-profile-picture *ngIf="profilePicture.base64; else noPic"
                           [allowAccountDataRetrieval]="false"
                           [userId]="teamUser.keycloakId"></app-profile-picture>
    </div>

    <ng-template #noPic>
      <span [ngClass]="{'hide': hide}">{{name}}</span>
    </ng-template>
  </ng-container>

  <ng-template #placeholder>
    <span [ngClass]="{'hide': hide}">{{name}}</span>
  </ng-template>


  <svg *ngIf="!teamUsers" width="36" height="36" fill="none">
    <g id="eye-opened" class="hide" [ngClass]="{'show': hide, 'display': cssClass === 'name-bubble'}"
       transform="translate(10.000000, 13.000000)">
      <path id="Shape" [style.fill]="borderColor" fill-rule="nonzero"
            d="M8,0 C4.47095425,0 1.42938368,1.98982105 0.0321836614,4.86127073 C-0.0107278871,4.94828435 -0.0107278871,5.05007388 0.0321836614,5.13872927 C1.42938368,8.01016253 4.47097141,10 8,10 C11.5290286,10 14.5706163,8.01017895 15.9678163,5.13872927 C16.0107279,5.05171565 16.0107279,4.94992612 15.9678163,4.86127073 C14.5706163,1.98982105 11.5290458,0 8,0 Z M8,8.4501888 C6.00718768,8.4501888 4.39371346,6.90527007 4.39371346,5.00082088 C4.39371346,3.09472993 6.00890415,1.55146938 8,1.55146938 C9.99281232,1.55146938 11.6062865,3.0963717 11.6062865,5.00082088 C11.6062865,6.90527007 9.99109585,8.4501888 8,8.4501888 Z"></path>
      <ellipse id="Oval" [style.fill]="borderColor" fill-rule="nonzero" cx="8" cy="4.99917912" rx="2.30864131"
               ry="2.208176"></ellipse>
    </g>

    <g id="eye-closed" class="hide" [ngClass]="{'show': hide, 'display': cssClass === 'name-bubble'}" transform="translate(11.000000, 15.000000)">
      <path [style.stroke]="borderColor" d="M14,0 C9.25796207,3.929504 4.5912954,3.929504 0,0" id="Path-12"  stroke-width="1.75"></path>
      <line [style.stroke]="borderColor" x1="6.9474846" y1="2.947128" x2="6.9474846" y2="6" id="Path-13"  stroke-width="1.75"></line>
      <line [style.stroke]="borderColor" x1="3.95441065" y1="2.45903443" x2="3.20987337" y2="5.43366151" id="Path-13" stroke-width="1.75"></line>
      <line [style.stroke]="borderColor" x1="10.6500837" y1="2.45903443" x2="9.90554643" y2="5.43366151" id="Path-13" stroke-width="1.75"
            transform="translate(10.277815, 3.946348) scale(-1, 1) translate(-10.277815, -3.946348) "></line>
      <line [style.stroke]="borderColor" x1="1.6081926" y1="1.2555986" x2="0.00358204684" y2="3.92570061" id="Path-13" stroke-width="1.75"></line>
      <line [style.stroke]="borderColor" x1="13.856375" y1="1.2555986" x2="12.2517645" y2="3.92570061" id="Path-13" stroke-width="1.75"
            transform="translate(13.054070, 2.590650) scale(-1, 1) translate(-13.054070, -2.590650) "></line>
    </g>
  </svg>
</div>
