<ng-container *ngIf="!accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 4em">
    <div class="clr-col">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 3em; margin-bottom: 5em">
    <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

      <h2>
        {{__('headline')}} {{ name }}{{__('headline2')}}
      </h2>

      <div class="paragraph id37-text2 text-light">
        {{__('explanation')}}
      </div>

      <div class="clr-row">
        <div class="clr-col-md-8 clr-col-sm-12">
          <form [formGroup]="personalDataForm" class="clr-form">

            <!-- PLACE -->
            <div class="clr-form-group">
              <label for="place-input">{{__('placeLabel')}}</label>
              <input [formControl]="personalDataForm.controls['city']" id="place-input"
                     placeholder="{{__('placePlaceholder')}}"
                     style="line-height: 10px"
                     type="text">
            </div>

            <!-- SCHOOL -->
            <div class="clr-form-group">
              <label for="academic-degree-select">{{__('schoolLabel')}}</label>
              <app-select
                  [choices]="academicDegreeChoices"
                  [formControl]="personalDataForm.controls['academicDegree']"
                  [placeholder]="__('schoolPlaceholder')"
                  id="academic-degree-select">
              </app-select>
            </div>

            <!-- JOB -->
            <div class="clr-form-group">
              <label for="profession-select">{{__('jobLabel')}}</label>
              <app-select
                  [choices]="professionChoices"
                  [formControl]="personalDataForm.controls['profession']"
                  [placeholder]="__('jobPlaceholder')"
                  id="profession-select">
              </app-select>
            </div>

            <!-- BUSINESS -->
            <div class="clr-form-group">
              <label for="industry-select">{{__('businessLabel')}}</label>
              <app-select
                  [choices]="industryChoices"
                  [formControl]="personalDataForm.controls['industry']"
                  [placeholder]="__('businessPlaceholder')"
                  id="industry-select">
              </app-select>
            </div>

            <!-- Work -->
            <div class="clr-form-group">
              <label for="company-input">{{__('workLabel')}}</label>
              <input [formControl]="personalDataForm.controls['company']" id="company-input"
                     placeholder="{{__('workPlaceholder')}}"
                     title="Company"
                     type="text">
            </div>

            <!-- MOBILE -->
            <div class="clr-form-group">
              <label for="mobile">{{__('mobileLabel')}}</label>
              <input [formControl]="personalDataForm.controls['mobilePhone']" class="text-input" id="mobile"
                     placeholder="{{__('mobilePlaceholder')}}"
                     title="Mobile"
                     type="text">
            </div>

          </form>
        </div>
      </div>

      <div class="id37-text2 text-bold" style="margin-top: 4em">
        {{__('endText')}}
      </div>

      <button (click)="savePersonalDataAfterTest()"
              [routerLink]="['/dashboard']"
              class="id37-btn-outline send-btn">
        {{__('send')}}
      </button>

    </div>
  </div>
</ng-container>
