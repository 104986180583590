import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../../../test/state/motive-score';
import {TranslatorService} from '../../../../translation/translator.service';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-motive-score-description]',
  templateUrl: './key-graphic-motive-score-description.component.html',
  styleUrls: ['./key-graphic-motive-score-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicMotiveScoreDescriptionComponent implements OnInit {

  private readonly yBase = 75;
  private readonly gridStep = 80;

  @Input()
    index: number;

  @Input()
    motiveScore: MotiveScore;

  @Input()
    isLast: boolean;

  @Input()
    isSingle: boolean;

  x: number = 810;
  y: number = 0;
  motiveName: string = '';

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.x += (this.index * 50);
    this.y = (this.motiveScore.motiveIndex * this.gridStep) + this.yBase;
    this.motiveName = this.___('motive.' + this.motiveScore.motive.toLowerCase());
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
