import {ClrDatagridStringFilterInterface} from '@clr/angular';
import {Customer} from './types/customer';
import {TranslatorService} from '../../translation/translator.service';

export class CustomerNameFilter implements ClrDatagridStringFilterInterface<Customer> {
  accepts(customer: Customer, search: string): boolean {
    let test: boolean;
    test =
      '' + customer.firstName === search ||
      customer.firstName.toLowerCase().indexOf(search) >= 0;
    if (test) {
      return test;
    }
    test =
      '' + customer.lastName === search ||
      customer.lastName.toLowerCase().indexOf(search) >= 0;
    if (test) {
      return test;
    }
  }
}

export class CustomerGroupFilter implements ClrDatagridStringFilterInterface<Customer> {
  accepts(customer: Customer, search: string): boolean {
    return (
      '' + customer.groupName === search ||
      customer.groupName.toLowerCase().indexOf(search) >= 0
    );
  }
}

export class CustomerVariationFilter implements ClrDatagridStringFilterInterface<Customer> {

  private readonly _translatorService: TranslatorService;

  constructor(translatorService: TranslatorService) {
    this._translatorService = translatorService;
  }

  accepts(customer: Customer, search: string): boolean {
    let variationOfFirstTest: string | undefined = customer.getVariationOfFirstTest();
    if (!!variationOfFirstTest) {
      variationOfFirstTest = this._translatorService.translate(variationOfFirstTest);
      return ('' + variationOfFirstTest === search || variationOfFirstTest.toLowerCase().indexOf(search) >= 0);
    }
    return false;
  }
}

export class CustomerMetaFilter implements ClrDatagridStringFilterInterface<Customer> {

  private readonly _translatorService: TranslatorService;

  constructor(translatorService: TranslatorService) {
    this._translatorService = translatorService;
  }

  accepts(customer: Customer, search: string): boolean {
    const filters: ClrDatagridStringFilterInterface<Customer>[] =
      [new CustomerNameFilter(), new CustomerGroupFilter(), new CustomerVariationFilter(this._translatorService)];
    return filters.some(filter => filter.accepts(customer, search));
  }
}
