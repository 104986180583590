<div *ngIf="!selectedTeam; else show"
     style="padding: 6em">
  <h2>Wähle ein Team aus</h2>
</div>

<ng-template #show>

  <app-team-graphic
      [team]="selectedTeam"
      [maxTestVariation]="maxTestvariation"
      [layout]="layout">
  </app-team-graphic>

  <app-print-button [text]="__('printbutton')"
                    (buttonClicked)="print()"></app-print-button>

  <div class="static-text">

    <h3 style="margin-top:2em">{{__("infoHeader1")}}</h3>
    <span class="id37-text2 text-light">{{__("infoText1")}}</span>

    <h3>{{__("infoHeader2")}}</h3>

    <!-- Fokus auf Gemeinsamkeiten im Team -->
    <app-collapsible-list>
      <app-collapsible-item>
        <app-collapsible-header [title]="__('collapsibleHeader1')"></app-collapsible-header>
        <app-collapsible-body class="id37-text2 text-light">
          <span [innerHTML]="__('collapsibleText1')"></span>
          <div [innerHTML]="__('collapsibleText1.daily')"></div>
          <div>
            <span [innerHTML]="__('collapsibleText1.effect')"></span>
            <ul>
              <li [innerHTML]="__('collapsibleText1.effect.listItem1')"></li>
              <li [innerHTML]="__('collapsibleText1.effect.listItem2')"></li>
              <li [innerHTML]="__('collapsibleText1.effect.listItem3')"></li>
              <li [innerHTML]="__('collapsibleText1.effect.listItem4')"></li>
            </ul>
          </div>

          <div [innerHTML]="__('collapsibleText1.measures')"></div>
        </app-collapsible-body>
      </app-collapsible-item>
    </app-collapsible-list>

    <!-- Fokus auf Unterschiede im Team -->
    <app-collapsible-list>
      <app-collapsible-item>
        <app-collapsible-header [title]="__('collapsibleHeader2')"></app-collapsible-header>
        <app-collapsible-body class="id37-text2 text-light">
          <span [innerHTML]="__('collapsibleText2')"></span>
          <div [innerHTML]="__('collapsibleText2.daily')"></div>
          <div [innerHTML]="__('collapsibleText2.effect')"></div>
          <div [innerHTML]="__('collapsibleText2.measures')"></div>
        </app-collapsible-body>
      </app-collapsible-item>

    </app-collapsible-list>

    <!-- Fokus auf Lagerbildung -->
    <app-collapsible-list>
      <app-collapsible-item>
        <app-collapsible-header [title]="__('collapsibleHeader3')"></app-collapsible-header>
        <app-collapsible-body class="id37-text2 text-light">
          <span [innerHTML]="__('collapsibleText3')"></span>
          <div [innerHTML]="__('collapsibleText3.daily')"></div>
          <div [innerHTML]="__('collapsibleText3.effect')"></div>
          <div [innerHTML]="__('collapsibleText3.measures')"></div>
          <div>
            <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
            <span [innerHTML]="__('collapsibleText3.tip')"></span>
          </div>
        </app-collapsible-body>
      </app-collapsible-item>
    </app-collapsible-list>

    <!-- Fokus auf Vielfalt als Stärke -->
    <app-collapsible-list>
      <app-collapsible-item>
        <app-collapsible-header [title]="__('collapsibleHeader4')"></app-collapsible-header>
        <app-collapsible-body class="id37-text2 text-light">
          <span [innerHTML]="__('collapsibleText4')"></span>
          <div [innerHTML]="__('collapsibleText4.daily')"></div>
          <div [innerHTML]="__('collapsibleText4.effect')"></div>
          <div [innerHTML]="__('collapsibleText4.measures')"></div>
          <div>
            <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
            <span [innerHTML]="__('collapsibleText4.tip')"></span>
          </div>
        </app-collapsible-body>
      </app-collapsible-item>
    </app-collapsible-list>

    <!-- Funktionalitäten der ID37 Teamanalyse -->
    <h3>{{__("infoHeader3")}}</h3>
    <div class="id37-text2 text-light">
      <div>
        <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
        <span [innerHTML]="__('infoText3.a')"></span>
      </div>
      <ul>
        <li [innerHTML]="__('infoText3.listItem1')"></li>
        <li [innerHTML]="__('infoText3.listItem2')"></li>
        <li [innerHTML]="__('infoText3.listItem3')"></li>
      </ul>
      <div [innerHTML]="__('infoText3.b')"></div>
      <div [innerHTML]="__('infoText3.c')" style="margin-top: 1em"></div>
    </div>

    <!-- Haben alle Mitglieder ein ID37 Profil? -->
    <h3 *featureAllowed="'recommend'">{{__("infoHeader4")}}</h3>
    <div *featureAllowed="'recommend'" class="id37-text2 text-light">
      <span><a (click)="openRecommendModal()">{{__('infoText4.link')}}</a></span>
      <span [innerHTML]="__('infoText4')"></span>
    </div>

    <!-- Fragen oder Anmerkungen? -->
    <h3>{{__("infoHeader5")}}</h3>
    <span class="id37-text2 text-light" [innerHTML]="__('infoText5')" style="padding-bottom:3rem;"></span>
  </div>
</ng-template>

<!-- recommend ID37 modal -->
<app-user-invite-option #userInviteOptionComponent></app-user-invite-option>
