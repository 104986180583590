import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private keycloakService: KeycloakService,
              private router: Router) {
  }

  ngOnInit(): void  {
    void this.router.navigate(['dashboard']);
    void this.doLogout();
  }

  async doLogout(): Promise<any> {
    await this.keycloakService.logout();
  }

}
