import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateTimeSelectionEvent} from '../state/date-time-selection-event';

@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true
    }
  ]
})
export class DateTimeComponent implements OnInit, ControlValueAccessor {

  @Input()
    guideSelectionMode: string = 'normal';

  @Input()
    type: 'date' | 'time' | 'datetime' = 'date';

  @Input()
    formPropagationType: 'localeFormatted' | 'isoFormatted' = 'isoFormatted';

  @Input()
    formPropagationZone: 'localeZone' | 'utc' = 'utc';

  @Input()
    range: boolean = false;

  @Input()
    sendCloseRequestOnDaySelection: boolean | undefined = undefined;

  @Input()
    minDate: Moment | undefined = undefined;

  @Input()
    maxDate: Moment | undefined = undefined;

  @Input()
    disabled: boolean = false;

  @Input()
    formControl: AbstractControl;

  @Input()
    placeholder: string = '';

  @Output()
    open: EventEmitter<void> = new EventEmitter<void>();

  @Output()
    close: EventEmitter<void> = new EventEmitter<void>();

  @Output()
    selection: EventEmitter<DateTimeSelectionEvent> = new EventEmitter<DateTimeSelectionEvent>();

  @ViewChild('input')
    input: ElementRef;

  selected: Moment | undefined = undefined;
  selectedIsoZoneFormatted: string | null = null;
  selectedIsoUtcFormatted: string | null = null;
  selectedLocaleZoneFormatted: string | null = null;
  selectedLocaleZoneFormattedWithOffset: string | null = null;
  selectedLocaleUtcFormatted: string | null = null;
  menuOpen: boolean = false;
  inFocus: boolean = false;
  clickedInside: boolean = false;
  touched: boolean = false;


  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              @Inject(DOCUMENT) private document: HTMLDocument) {
  }

  ngOnInit(): void {
    if (this.formControl !== undefined) { // only used in start-page.component
      this.formControl.markAsTouched = () => {
        this.onClickInside();
      };
    }
  }

  @HostBinding('style.opacity')
  get opacity() {
    return this.disabled ? 0.5 : 1;
  }

  @HostListener('click')
  onClickInside() {
    this.touched = true;
    this.clickedInside = true;
  }

  @HostListener('document:click')
  onClickOutside() {
    if (!this.clickedInside && this.menuOpen) {
      // Clicked outside!
      this.closeMenu();
    }
    this.clickedInside = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.inFocus) {
      if (!this.menuOpen && (event.key === 'ArrowDown' || event.key === 'Enter' || event.code === 'Space')) {
        event.preventDefault();
        this.openMenu();
      }
      else if (this.menuOpen && (event.key === 'Escape' || event.key === 'Tab')) {
        this.closeMenu();
      }
      else if (event.key === 'Tab') {
        // Do nothing.
      }
      else {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }


  openMenu(): void {
    if (!this.menuOpen) {
      this.menuOpen = true;
      this.open.emit();
    }
  }

  closeMenu(): void {
    if (this.menuOpen) {
      // Opening the menu puts the focus on the search input.
      // On close, the focus on the select itself should be restored, as the user might still want to interact with it
      // or want to tab further down the focusable elements on the site.
      // If the menu is closed by pressing escape,
      // the search input is still focused and the focus can be restored safely.
      // If the menu is closed with a click outside the select menu and onto a focusable element, restoring focus
      // to the select would probably be against the users intention / will.
      if (!!this.input && !!this.input.nativeElement
        && !!this.document.activeElement
        && (this.document.activeElement === this.document.body
          || this.document.activeElement === this.document.body.parentElement)) {
        this.input.nativeElement.focus();
      }
      this.menuOpen = false;
      this.close.emit();
    }
  }

  toggleMenu(): void {
    // Do not let the user interact with the select if it is disabled.
    if (this.disabled) {
      return;
    }
    if (this.menuOpen) {
      this.closeMenu();
    }
    else {
      this.openMenu();
    }
  }

  propagateDateSelection(): void {
    this.selection.emit(new DateTimeSelectionEvent(
      this.type,
      this.currentUser.locale,
      this.selected,
      this.selectedIsoZoneFormatted,
      this.selectedIsoUtcFormatted,
      this.selectedLocaleZoneFormatted,
      this.selectedLocaleZoneFormattedWithOffset,
      this.selectedLocaleUtcFormatted
    ));
    if (this.formPropagationType === 'isoFormatted' && this.formPropagationZone === 'localeZone') {
      this.ngFormsPropagateChange(this.selectedIsoZoneFormatted);
    }
    else if (this.formPropagationType === 'isoFormatted' && this.formPropagationZone === 'utc') {
      this.ngFormsPropagateChange(this.selectedIsoUtcFormatted);
    }
    else if (this.formPropagationType === 'localeFormatted' && this.formPropagationZone === 'localeZone') {
      this.ngFormsPropagateChange(this.selectedLocaleZoneFormatted);
    }
    else if (this.formPropagationType === 'localeFormatted' && this.formPropagationZone === 'utc') {
      this.ngFormsPropagateChange(this.selectedLocaleUtcFormatted);
    }
    else {
      console.error('Unable to propagate selected date to form. '
        + 'Unknown combination of formPropagationType and formPropagationZone.');
    }
  }

  updateSelected(date: Moment): void {
    // Creating a new moment from the existing moment triggers change detection!
    this.selected = moment(date).locale(this.currentUser.locale);
    if (this.type === 'date') {
      this.selectedIsoZoneFormatted = this.selected.format('YYYY-MM-DD');
      this.selectedIsoUtcFormatted = moment(this.selected).utc().format('YYYY-MM-DD');
      this.selectedLocaleZoneFormatted = this.selected.format('L');
      this.selectedLocaleZoneFormattedWithOffset = this.selected.format('L');
      this.selectedLocaleUtcFormatted = moment(this.selected).utc().format('L');
    }
    else if (this.type === 'time') {
      this.selectedIsoZoneFormatted = this.selected.format('HH:mm:ss.SSSS');
      this.selectedIsoUtcFormatted = moment(this.selected).utc().format('HH:mm:ss.SSSS');
      this.selectedLocaleZoneFormatted = this.selected.format('LT');
      this.selectedLocaleZoneFormattedWithOffset = this.selected.format('LT Z');
      this.selectedLocaleUtcFormatted = moment(this.selected).utc().format('LT');
    }
    else if (this.type === 'datetime') {
      this.selectedIsoZoneFormatted = this.selected.toISOString(true);
      this.selectedIsoUtcFormatted = moment(this.selected).utc().toISOString();
      this.selectedLocaleZoneFormatted = this.selected.format('L LT');
      this.selectedLocaleZoneFormattedWithOffset = this.selected.format('L LT Z');
      this.selectedLocaleUtcFormatted = moment(this.selected).utc().format('L LT');
    }
    else {
      this.selectedIsoZoneFormatted = null;
      this.selectedIsoUtcFormatted = null;
      this.selectedLocaleZoneFormatted = null;
      this.selectedLocaleZoneFormattedWithOffset = null;
      this.selectedLocaleUtcFormatted = null;
      console.error('Formatting for type ' + this.type + ' is not specified.');
    }
    this.propagateDateSelection();
  }

  onDateSelection(date: Moment): void {
    this.updateSelected(date);
  }

  hasSelection(): boolean {
    return !!this.selected;
  }

  /* ControlValueAccessor implementation */

  // The argument to this function is propagated to the form control value!
  ngFormsPropagateChange = (selectedValues) => {
  };

  registerOnChange(fn: any): void {
    this.ngFormsPropagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // On blur, etc...
    // We don't care right now.
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any | null | undefined): void {
    if (typeof obj === typeof undefined || obj === null) {
      this.selected = undefined;
      return;
    }
    if (typeof obj !== 'string' && !moment.isMoment(obj)) {
      console.error('Unable to write value to datetime. Not a string or a Moment.', obj);
      return;
    }
    if (obj === '') {
      return;
    }
    // console.log('writing datetime: ', obj);
    const parsed: Moment = moment(obj).locale(this.currentUser.locale);
    // console.log('parsed datetime: ', parsed);
    this.updateSelected(parsed);
  }

}
