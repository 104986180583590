import {Component, EventEmitter, HostBinding, HostListener, Inject, Input, Output} from '@angular/core';
import {ModalService} from '../modal.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'id37-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModalService]
})
export class ModalComponent {

  @Output()
    modalOpenChange = new EventEmitter<boolean>();
  @Input()
    modalSize: 'small-mobile'| 'small' | 'sm' | 'normal' | 'large' | 'lg' = 'normal';
  @Output()
    beforeOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    afterOpen: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    beforeClose: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    afterClose: EventEmitter<void> = new EventEmitter<void>();
  private _scrollPosition: number = 0;

  constructor(private modalService: ModalService,
              @Inject(DOCUMENT) private document: HTMLDocument) {
    this.modalService.modalComponent = this;
  }

  @HostBinding('class.shown')
  private _modalOpen: boolean = false;

  get modalOpen(): boolean {
    return this._modalOpen;
  }

  @Input()
  set modalOpen(modalOpen: boolean) {
    if (modalOpen && !this._modalOpen) {
      this.show();
    }
    else if (!modalOpen && this._modalOpen) {
      this.hide();
    }
  }

  show(): void {
    this.beforeOpen.emit();
    this.enableOverlay();
    this.setModalOpened(true);
    this.afterOpen.emit();
  }

  hide(): void {
    this.beforeClose.emit();
    this.disableOverlay();
    this.setModalOpened(false);
    this.afterClose.emit();
  }

  enableOverlay() {
    this._scrollPosition = window.pageYOffset;
    const mainElement: HTMLElement = this.document.body;
    mainElement.style.setProperty('top', -this._scrollPosition + 'px');
    this.document.body.classList.add('no-scroll');
    if (mainElement.scrollHeight > mainElement.clientHeight) {
      this.document.body.classList.add('keep-vertical-scrollbar');
    }
    if (mainElement.scrollWidth > mainElement.clientWidth) {
      this.document.body.classList.add('keep-horizontal-scrollbar');
    }
  }

  disableOverlay() {
    this.document.body.classList.remove('no-scroll', 'keep-vertical-scrollbar', 'keep-horizontal-scrollbar');
    window.scrollTo(0, this._scrollPosition);
    const mainElement: HTMLElement = this.document.body;
    mainElement.style.removeProperty('top');
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this._modalOpen) {
      this.hide();
    }
  }

  private setModalOpened(opened: boolean): void {
    this._modalOpen = opened;
    this.modalOpenChange.emit(this._modalOpen);
  }

}
