import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {AdminService} from '../admin.service';
import {Subject} from 'rxjs';
import {SelfBuyer} from '../types/self-buyer';
import {ClrDatagridStringFilterInterface} from '@clr/angular';
import {SelfBuyersPaymentsRecommendation} from '../types/self-buyer-payment-recommendation';
import {SelfBuyersPrice} from '../types/self-buyer-price';


class MyStringFilter implements ClrDatagridStringFilterInterface<string> {
  accepts(item: string, search: string): boolean {
    return '' + item === search.toLowerCase() || item.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  }
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  selfBuyerPaymentsRecommendation: SelfBuyersPaymentsRecommendation;
  selfBuyersPrice: SelfBuyersPrice;
  selfBuyerList: SelfBuyer[] = [];
  selfBuyerView: SelfBuyer[] = [];
  selfBuyerCurrentPage: number = 1;
  selfBuyerLastPage: number = 5;
  pageSize: number = 20;
  searchKey: string = '';
  myStringFilter = new MyStringFilter();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private adminService: AdminService) {
  }

  ngOnInit(): void {
    this.loadStatistics();
  }

  loadStatistics(): void {
    /*
    this.adminService.getPaymentCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stats: SelfBuyersStatistics) => {
        this.selfBuyersStatistics = stats;
      });
    this.adminService.getRecommendationCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stats: SelfBuyersStatistics) => {
        this.selfBuyersStatistics = stats;
      });
    this.adminService.getPriceCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stats: SelfBuyersStatistics) => {
        this.selfBuyersStatistics = stats;
        this.selfBuyerList = stats.selfBuyers;
        this.selfBuyerView = this.selfBuyerList;
        this.selfBuyerLastPage = Math.ceil(this.selfBuyerList.length / this.pageSize);
      });
    */
    this.adminService.getPaymentRecommendationCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stats: SelfBuyersPaymentsRecommendation) => {
        this.selfBuyerPaymentsRecommendation = stats;
      });
    this.adminService.getPriceCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stats: SelfBuyersPrice) => {
        this.selfBuyersPrice = stats;
        this.selfBuyerList = stats.selfBuyers;
        this.selfBuyerView = this.selfBuyerList;
        this.selfBuyerLastPage = Math.ceil(this.selfBuyerList.length / this.pageSize);
      });
  }

  search(): void {
    if (this.searchKey.length !== 0) {
      const filteredView: SelfBuyer[] = [];
      this.selfBuyerList.forEach((m) => {
        if (this.myStringFilter.accepts(m.name, this.searchKey)) {
          filteredView.push(m);
        }
      });
      this.selfBuyerView = filteredView;
    }
    else {
      this.selfBuyerView = this.selfBuyerList;
    }
  }

  nextPage(currentPage: number, lastPage: number): void {
    if (currentPage + 1 <= lastPage) {
      this.selfBuyerCurrentPage = currentPage + 1;
    }
  }

  previousPage(currentPage: number): void {
    if (currentPage - 1 >= 1) {
      this.selfBuyerCurrentPage = currentPage - 1;
    }
  }
}
