import {SuperlikeType} from '../../distance-graphic/superlike-type';
import {Superlike} from './superlike';

export class SuperlikeRepresentation {
  public readonly keycloakId: string;
  public readonly superlike: SuperlikeType;
  public readonly customMessage: string;

  constructor(keycloakId: string, superlike: SuperlikeType, customMessage: string) {
    this.keycloakId = keycloakId;
    this.superlike = superlike;
    this.customMessage = customMessage;
  }

  static toModelEntity(superlikeRepresentation: SuperlikeRepresentation): Superlike {
    return {
      keycloakId: superlikeRepresentation.keycloakId,
      superlike: SuperlikeType.fromName(superlikeRepresentation.superlike),
      customMessage: superlikeRepresentation.customMessage
    };
  }
}

