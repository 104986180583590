import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {MasterService} from '../master.service';
import {CustomerGroupFilter, CustomerMetaFilter, CustomerNameFilter, CustomerVariationFilter} from './customer-filter';
import {NewCustomersComponent} from './new-customers/new-customers.component';
import {Customer} from './types/customer';
import {Email} from './types/email-type';
import {Group} from './types/group-type';
import {ClrDatagridComparatorInterface} from '@clr/angular';
import {ChoicesOptions} from '../../user-data/choices-options';
import {TestInvitation} from './test-invitation';
import {Test} from '../../test/state/test';
import {TestVariation} from '../../test/state/test-variation';
import {CustomerResetService} from './customer-reset.service';
import {Choice} from '../../select/state/choices-types';
import {GroupRepresentation} from '../group-representation';

class VariationComparator implements ClrDatagridComparatorInterface<Customer> {

  private _translatorService: TranslatorService;

  constructor(translatorService: TranslatorService) {
    this._translatorService = translatorService;
  }

  compare(a: Customer, b: Customer) {
    const varA: string | undefined = a.getVariationOfFirstTest();
    const varB: string | undefined = b.getVariationOfFirstTest();
    const variationA: string = !!varA ? this._translatorService.translate(varA) : '';
    const variationB: string = !!varB ? this._translatorService.translate(varB) : '';
    return variationA.localeCompare(variationB);
  }
}

class ActionComparator implements ClrDatagridComparatorInterface<Customer> {
  private _myCustomerComponent: MyCustomersComponent;

  constructor(myCustomerComponent: MyCustomersComponent) {
    this._myCustomerComponent = myCustomerComponent;
  }

  compare(a: Customer, b: Customer) {
    const actionA: string = this._myCustomerComponent.computeAction(a);
    const actionB: string = this._myCustomerComponent.computeAction(b);
    return actionA.localeCompare(actionB);
  }
}

@Component({
  selector: 'app-my-customers',
  templateUrl: './my-customers.component.html',
  styleUrls: ['./my-customers.component.scss']
})
export class MyCustomersComponent implements OnInit, OnDestroy {

  openVariationModal: boolean = false;
  form = new FormGroup({
    testVariation: new FormControl('', Validators.required)
  });
  performingAction: boolean = false;
  loadingCustomers: boolean = false;
  customers$: Observable<Customer[]>;
  customers: Customer[] = [];
  customersView: Customer[] = [];
  customerMetaFilter: CustomerMetaFilter = new CustomerMetaFilter(this.translatorService);
  customerNameFilter: CustomerNameFilter = new CustomerNameFilter();
  customerGroupFilter: CustomerGroupFilter = new CustomerGroupFilter();
  customerVariationFilter: CustomerVariationFilter = new CustomerVariationFilter(this.translatorService);
  newCustomers: boolean;
  groups$: Observable<Group[]>;
  nameSortOrder: number;
  groupSortOrder: number;
  dateSortOrder: number;
  variationComparator = new VariationComparator(this.translatorService);
  variationSortOrder: number;
  actionComparator = new ActionComparator(this);
  actionSortOrder: number;
  variationString: string;
  metaSearch = '';
  page = 1;
  lastPage = 1;
  pageSize = 6;
  name: string;
  userId: string;
  dropdownVisible = false;
  dropdownPosition = {
    top: 0,
    left: 0
  };
  selectedCustomer: Customer;
  @ViewChild(NewCustomersComponent, {static: true})
    newCustomersComponent: NewCustomersComponent;
  isDeleteModalVisible: boolean = false;
  customerToDelete: Customer;
  isGroupChangeModalVisible: boolean = false;
  customerToChangeGroup: Customer;
  changeGroupNameForAll: boolean = false;
  newGroup = new FormControl('');
  initialVariationChoices: Choice[];
  variationChecked: any;
  private unsubscribe$ = new Subject<void>();

  constructor(private customerResetService: CustomerResetService,
              private formBuilder: FormBuilder,
              private masterService: MasterService,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private router: Router) {
    this.customerResetService.shouldReloadCustomers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.loadCustomers());
    this.initialVariationChoices = ChoicesOptions.variations();
    this.initialVariationChoices.push({value: 'customer-chooses', label: this.___('test.variation.unspecified')});
  }

  ngOnInit(): void {
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSent(post: Observable<void>): void {
    this.toggleAddCustomers();
    post.pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.newCustomersComponent.createFormArray();
        this.refreshData();
      }, () => {
        console.error('The given client data was rejected from the server.');
      }
    );
  }

  refreshData(): void {
    this.getMasterName();
    this.getUserId();
    this.loadCustomers();
    this.loadGroups();
  }

  getMasterName(): void {
    this.name = this.currentUser.fullName;
  }

  getUserId(): void {
    this.userId = this.currentUser.keycloakId;
  }

  loadCustomers(): void {
    this.loadingCustomers = true;
    this.customers$ = this.masterService.loadCustomers();
    this.customers$
      .pipe(takeUntil(this.unsubscribe$), map((data: Customer[]) => data.sort(
        (clientA, clientB) => clientB.creationDate.getTime() - clientA.creationDate.getTime())))
      .subscribe((data: Customer[]) => {
        this.customers = data;
        if (this.customers) {
          this.customersView = this.customers;
          this.lastPage = Math.ceil(this.customers.length / this.pageSize);
        }
        else {
          this.customersView = [];
          this.lastPage = 1;
        }
        setTimeout(() => this.loadingCustomers = false, 0);
      }, () => {
        setTimeout(() => this.loadingCustomers = false, 0);
      });
  }

  loadGroups(): void {
    this.groups$ = this.masterService.loadGroups();
  }

  doMetaSearch(): void {
    if (this.metaSearch.length !== 0) {
      const customersFiltered: Customer[] = [];
      for (const customer of this.customers) {
        if (
          this.customerMetaFilter.accepts(
            customer,
            this.metaSearch.toLowerCase()
          )
        ) {
          customersFiltered.push(customer);
        }
      }
      this.customersView = customersFiltered;
    }
    else {
      this.customersView = this.customers;
    }
  }

  toggleAddCustomers(): void {
    this.newCustomers = !this.newCustomers;
  }

  nextPage(): void {
    if (this.page + 1 <= this.lastPage) {
      this.page = this.page + 1;
    }
  }

  previousPage(): void {
    if (this.page - 1 >= 1) {
      this.page = this.page - 1;
    }
  }

  computeAction(customer: Customer): string {
    const test: Test = customer.tests[0];
    if (customer.hasNoTests()) {
      return 'newTestInvite';
    }
    if (!customer.emailVerified) {
      if (this.calculateDaysSinceCreation(customer.creationDate) > 29) {
        return 'newInvite';
      }
    }
    if (test.accessGranted) {
      return 'done';
    }
    if (test.finished) {
      return 'grant';
    }
    if (test.started) {
      return 'in progress';
    }
    return 'changeProduct';
  }

  public calculateDaysSinceCreation(creationDate: Date): number {
    const diff = Date.now().valueOf() - creationDate.valueOf();
    return Math.ceil(diff / (1000 * 3600 * 24));
  }

  performActionByClick(event: Event, action: string, customer: Customer): void {
    event.stopPropagation();
    this.performAction(action, customer);
  }


  performAction(action: string, customer: Customer): void {
    this.selectedCustomer = customer;
    if (action === 'newInvite') {
      this.performingAction = true;
      this.masterService.sendNewInvitation({email: this.selectedCustomer.email})
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.refreshData();
          setTimeout(() => this.performingAction = false, 0);
        }, () => {
          setTimeout(() => this.performingAction = false, 0);
        });
    }
    if (action === 'grant') {
      this.performingAction = true;
      this.masterService.grantTestAccess(new Email(customer.email), customer.getFirstTest().testId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.refreshData();
          setTimeout(() => this.performingAction = false, 0);
        }, () => {
          setTimeout(() => this.performingAction = false, 0);
        });
    }
    if (action === 'changeProduct' || action === 'newTestInvite') {
      this.openVariationModal = true;
      this.variationChecked = customer.getVariationOfFirstTest();
    }
  }

  changeProduct() {
    if (!!this.variationString) {
      let testVariation: TestVariation | undefined;
      if (this.variationString === 'customer-chooses') {
        testVariation = undefined;
      }
      else {
        testVariation = TestVariation.fromName(this.variationString);
      }
      this.performingAction = true;
      this.masterService.changeProduct(new TestInvitation(this.selectedCustomer.email, testVariation))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.refreshData();
          setTimeout(() => this.performingAction = false, 0);
        }, () => {
          setTimeout(() => this.performingAction = false, 0);
        });
    }
  }

  inviteToTest() {
    if (this.variationString) {
      let testVariation: TestVariation | undefined;
      if (this.variationString === 'customer-chooses') {
        testVariation = undefined;
      }
      else {
        testVariation = TestVariation.fromName(this.variationString);
      }
      this.performingAction = true;
      this.masterService.sendNewTestInvitation(new TestInvitation(this.selectedCustomer.email, testVariation))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.refreshData();
          setTimeout(() => this.performingAction = false, 0);
        }, () => {
          setTimeout(() => this.performingAction = false, 0);
        });
    }
  }

  onVariationSelection(): void {
    if (this.selectedCustomer.hasNoTests()) {
      this.inviteToTest();
    }
    else {
      this.changeProduct();
    }
    this.openVariationModal = false;
  }

  openReport(userId: string, testId: string): void {
    this.router.navigate(['report', userId, testId]);
  }

  trackById(customer: Customer) {
    return customer.keycloakId;
  }

  openDeleteModal(customer: Customer): void {
    this.customerToDelete = customer;
    this.isDeleteModalVisible = true;
  }

  closeDeleteModal(): void {
    this.isDeleteModalVisible = false;
  }

  delete(customer: Customer) {
    this.masterService.deleteCustomer(customer)
      .subscribe(() => {
        this.refreshData();
      });
  }

  openGroupChangeModal(customer: Customer): void {
    this.newGroup.setValue(customer.groupName);
    this.customerToChangeGroup = customer;
    this.isGroupChangeModalVisible = true;
    this.changeGroupNameForAll = false;
  }

  closeGroupChangeModal(): void {
    this.isGroupChangeModalVisible = false;
  }

  changeGroupOfCustomer(customer: Customer) {
    if (this.changeGroupNameForAll) {
      this.masterService.changeGroupOfCustomersWithTheSameGroup(customer,
        GroupRepresentation.buildFrom(this.newGroup.value, this.currentUser.keycloakId))
        .subscribe(() => {
          this.refreshData();
        });
    }
    else {
      this.masterService.changeGroupOfCustomer(customer,
        GroupRepresentation.buildFrom(this.newGroup.value, this.currentUser.keycloakId))
        .subscribe(() => {
          this.refreshData();
        });
    }
  }

  changeVariation(e) {
    this.variationString = e.target.value;
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }
}
