<div class="clr-row clr-justify-content-center margin-0" style="margin: 3em 0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <ng-container *ngIf="loading">
      <app-loading-indicator></app-loading-indicator>
    </ng-container>

    <ng-container *ngIf="!loading">

      <h2 *ngIf="orderProcessingResult.statusAfterProcessing !== orderStatus.CANCELED">
        {{__('heading')}}
      </h2>

      <ng-container *ngIf="orderProcessingResult.statusAfterProcessing === orderStatus.CANCELED">
        <p class="id37-text1">
          {{__('action.chargesCanceled.text1')}}
        </p>
      </ng-container>

      <ng-container *ngIf="orderProcessingResult.statusAfterProcessing === orderStatus.INITIALIZED">
        <p class="id37-text1">
          {{__('statusAfterProcessingIsInitialized.text1')}}
        </p>
        <button (click)="navigateToOrderPage()"
                class="id37-btn-outline navigate-to-test-button"
                style="margin-top: 4em;">
          {{__('statusAfterProcessingIsInitialized.continueOrdering')}}
        </button>
      </ng-container>

    </ng-container>

  </div>
</div>
