import {BillingAddressRepresentation} from './billing-address-representation';

export class BillingAddress {

  private readonly _address: string;

  constructor(address: string) {
    this._address = address;
  }

  get address(): string {
    return this._address;
  }

  static buildFrom(billingAddressRepresentation: BillingAddressRepresentation): BillingAddress {
    return new BillingAddress(
      billingAddressRepresentation.address
    );
  }

}
