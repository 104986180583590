import {MotiveScore} from '../../test/state/motive-score';
import {TeamUser} from './team-user';
import {Motive} from '../../report/types/motives/motive';
import {Layout} from '../team.component';
import * as bubbleColors from '../state/bubble-colors';


export interface BubbleScore {
  firstName:  string;
  lastName:   string;
  keycloakId: string;
  colorIndex: number;       // it's basically the position of the member in the member list
  motive: MotiveScore;      // Like: 6
  hide: boolean;
}

export interface HiddenBubbleScore {
  members: BubbleScore[];
  score: number;
}

export class BubbleScoreFunctions {

  // This function returns a BubbleScore array of a specific motive for all members.
  static getSpecificBubbleScores(members: TeamUser[], motiveName: string): BubbleScore[] | undefined {
    if (members.length === 0) {
      return undefined;
    }
    const bubbleScore: BubbleScore[] = [];
    members.forEach((member: TeamUser, index: number) => {
      if (member.result.motiveScores.get(Motive.fromName(motiveName))) {
        bubbleScore.push({
          firstName: member.firstName,
          lastName: member.lastName,
          keycloakId: member.keycloakId,
          colorIndex: index,
          motive: member.result.motiveScores.get(Motive.fromName(motiveName)),
          hide: member.hide
        });
      }
    });
    return bubbleScore;
  }

  /*
  This function returns a number array of the length 2.
  number[0] is the position of a certain member in specificBubbleScores.
  number[1] is the array length of specificBubbleScores with a specific score.
  Both values are needed to calculate the position of the bubbles when there are 1+ members with the same motive score.
   */
  static countScore(specificBubbleScores: BubbleScore[], colorIndex: number, score: number): number[] {
    const bubbles: BubbleScore[] = specificBubbleScores.filter(bubbleScore => bubbleScore.motive.score === score);
    const bubbles2: BubbleScore[] = bubbles.filter(bubbleScore => !bubbleScore.hide);
    return [
      bubbles2.findIndex((bubbleScore: BubbleScore) => bubbleScore.colorIndex === colorIndex),
      bubbles2.length
    ];
  }

  // This function returns the colorIndexes of hidden members, if there are more than 4 members with the same score
  static getHiddenBubbleScoreColorIndexes(specificBubbleScores: BubbleScore[]): number[] {
    const hiddenBubblesIndexes: number[] = [];
    for (let i = 1; i < 11; i++) {
      const specificScores = specificBubbleScores.filter(
        bubbleScore => bubbleScore.motive.score === i && !bubbleScore.hide);
      if (specificScores.length > 4) {
        hiddenBubblesIndexes.push(...specificScores.slice(3).map((hiddenBubble) => hiddenBubble.colorIndex));
      }
    }
    return hiddenBubblesIndexes;
  }

  /*
  This function returns an array of HiddenBubbleScore.
  The score and HiddenMember array are needed for displaying the tooltip infos.
  A HiddenBubbleScore will always have at least 2 members.
   */
  static computeHiddenBubbleScore(hidden: BubbleScore[]): HiddenBubbleScore[] {
    const hiddenBubbleScores: HiddenBubbleScore[] = [];
    for (let i = 1; i < 11; i++) {
      const hiddenScore = hidden.filter((bubbleScore) => bubbleScore.motive.score === i);
      if (hiddenScore.length) {
        hiddenBubbleScores.push({
          score: i,
          members: hiddenScore.map((bubbleScore2) => ({
            firstName: bubbleScore2.firstName,
            lastName: bubbleScore2.lastName,
            colorIndex: bubbleScore2.colorIndex,
            motive: bubbleScore2.motive,
            hide: bubbleScore2.hide
          }) as BubbleScore)
        });
      }
    }
    return hiddenBubbleScores;
  }

  static calculateBubblePositionX(bubbleScore: number, position: number, amount: number, layout: Layout): number {
    let xBase = 15.5; // 169;
    let xBaseSmallStep = 1.82; // 18;
    let xBaseLargeStep = 8.24; // 92;

    if (layout === 'tablet') {
      xBase = 4.8;
      xBaseSmallStep = 2.1;
      xBaseLargeStep = 9.6;
    }

    // bubbles formation for mobile is different from desktop and tablet
    if (layout === 'mobile') {
      return this.calculateMobileBubblePositionX(bubbleScore);
    }

    if (amount > 4) {
      if (position === 0 || position === 2) {
        return xBase + xBaseLargeStep * (bubbleScore - 1) - xBaseSmallStep;
      }
      else if (position === 1) {
        return xBase + xBaseLargeStep * (bubbleScore - 1) + xBaseSmallStep;
      }
      else {
        console.error(`calculateBubblePositionX - Amount: ${amount}, Position: ${position}`);
        return 0;
      }
    }

    switch (amount) {
      case 1:
      case 2:
        return xBase + xBaseLargeStep * (bubbleScore - 1);
      case 3:
        if (position === 0) {
          return xBase + xBaseLargeStep * (bubbleScore - 1);
        }
        else if (position === 1) {
          return xBase + xBaseLargeStep * (bubbleScore - 1) - xBaseSmallStep;
        }
        else if (position === 2) {
          return xBase + xBaseLargeStep * (bubbleScore - 1) + xBaseSmallStep;
        }
        else {
          console.error(`calculateBubblePositionX - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      case 4:
        if (position === 0 || position === 2) {
          return xBase + xBaseLargeStep * (bubbleScore - 1) - xBaseSmallStep;
        }
        else if (position === 1 || position === 3) {
          return xBase + xBaseLargeStep * (bubbleScore - 1) + xBaseSmallStep;
        }
        else {
          console.error(`calculateBubblePositionX - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      default:
        console.error(`calculateBubblePositionX - Amount: ${amount}, Position: ${position}`);
        return 0;
    }
  }

  static calculateBubblePositionY(position: number, amount: number, layout: Layout): number {
    let yBase = 31; // 27;
    let yBaseStep = 22;

    if (layout === 'tablet') {
      yBase = 51;
      yBaseStep = 13.5;
    }

    // bubbles formation for mobile is different from desktop and tablet
    if (layout === 'mobile') {
      return this.calculateMobileBubblePositionY(position, amount);
    }

    if (amount > 4) {
      if (position === 0 || position === 1) {
        return yBase - yBaseStep;
      }
      else if (position === 2) {
        return yBase + yBaseStep;
      }
      else {
        console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
        return 0;
      }
    }
    switch (amount) {
      case 1:
        return yBase;
      case 2:
      case 3:
        if (position === 0) {
          return yBase - yBaseStep;
        }
        else if (position === 1 || position === 2) {
          return yBase + yBaseStep;
        }
        else {
          console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      case 4:
        if (position === 0 || position === 1) {
          return yBase - yBaseStep;
        }
        else if (position === 2 || position === 3) {
          return yBase + yBaseStep;
        }
        else {
          console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      default:
        console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
        return 0;
    }
  }

  static computeBubbleColor(index: number, amount: number, single: boolean = false): string[] {

    if (single) {
      return [bubbleColors.bubbleColorsBorder[Math.floor(40 / amount) * index],
        bubbleColors.bubbleColorsBorder[Math.floor(40 / amount) * index]];
    }
    else {
      return [bubbleColors.bubbleColorsBorder[Math.floor(40 / amount) * index],
        bubbleColors.bubbleColorsInner[Math.floor(40 / amount) * index]];
    }

    /*
    const lightning = 50;
    const lightingStepSize = 40;
    const colorStepSize = 30;

    0: borderColor, 1: fillColor
    if (single) {
      return [`hsl(${index}, 100%, ${lightning}%)`, `hsl(${index}, 100%, ${lightning}%)`];
    }
    else {
      return [`hsl(${index * colorStepSize}, 100%, ${lightning}%)`,
        `hsl(${index * colorStepSize}, 100%, ${lightning + lightingStepSize}%)`];
    }
    */
  }

  private static calculateMobileBubblePositionX(bubbleScore: number): number {
    const xBase = 5.5;
    const xBaseLargeStep = 9.15;
    return xBase + xBaseLargeStep * (bubbleScore - 1);
  }

  private static calculateMobileBubblePositionY(position: number, amount: number): number {
    const yBase = 50;
    const yBaseStep = 14.5;

    if (amount > 4) {
      if (position === 0) {
        return yBase - 1.5 * yBaseStep;
      }
      else if (position === 1) {
        return yBase - yBaseStep / 1.85;
      }
      else if (position === 2) {
        return yBase + yBaseStep / 1.85;
      }
      else {
        console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
        return 0;
      }
    }
    switch (amount) {
      case 1:
        return yBase;
      case 2:
        if (position === 0) {
          return yBase - yBaseStep / 1.85;
        }
        else if (position === 1) {
          return yBase + yBaseStep / 1.85;
        }
        else {
          console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      case 3:
        if (position === 0) {
          return yBase - yBaseStep;
        }
        else if (position === 1) {
          return yBase;
        }
        else if (position === 2) {
          return yBase + yBaseStep;
        }
        else {
          console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      case 4:
        if (position === 0) {
          return yBase - 1.5 * yBaseStep;
        }
        else if (position === 1) {
          return yBase - yBaseStep / 1.85;
        }
        else if (position === 2) {
          return yBase + yBaseStep / 1.85;
        }
        else if (position === 3) {
          return yBase + 1.5 * yBaseStep;
        }
        else {
          console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
          return 0;
        }
      default:
        console.error(`calculateBubblePositionY - Amount: ${amount}, Position: ${position}`);
        return 0;
    }
  }

}
