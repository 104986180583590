export enum UserTargetFilter {

  /**
   * All users on the id37 platform.
   */
  ALL = 'ALL',

  /**
   * All administrators.
   */
  ALL_ADMINISTRATORS = 'ALL_ADMINISTRATORS',

  /**
   * All masters.
   */
  ALL_MASTERS = 'ALL_MASTERS',

  /**
   * All partners.
   */
  ALL_PARTNERS = 'ALL_PARTNERS',

  /**
   * All clients which are connected to a specific master.
   */
  CHOOSE_MASTER_CLIENTS = 'CHOOSE_MASTER_CLIENTS',

  /**
   * All clients which are connected to a specific partner.
   */
  CHOOSE_PARTNER_CLIENTS = 'CHOOSE_PARTNER_CLIENTS',

  /**
   * Search and choose masters.
   */
  CHOOSE_MASTERS = 'CHOOSE_MASTERS',

  /**
   * Search and choose masters.
   */
  CHOOSE_PARTNERS = 'CHOOSE_PARTNERS',

  /**
   * Just chosen users.
   */
  CHOSEN_FILTERED_USERS = 'CHOSEN_FILTERED_USERS'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserTargetFilter {
  export const LIST: UserTargetFilter[] = [
    UserTargetFilter.ALL,
    UserTargetFilter.ALL_ADMINISTRATORS,
    UserTargetFilter.ALL_MASTERS,
    UserTargetFilter.ALL_PARTNERS,
    UserTargetFilter.CHOOSE_MASTER_CLIENTS,
    UserTargetFilter.CHOOSE_PARTNER_CLIENTS,
    UserTargetFilter.CHOOSE_MASTERS,
    UserTargetFilter.ALL_PARTNERS,
    UserTargetFilter.CHOSEN_FILTERED_USERS
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): UserTargetFilter | undefined => {
    name = !!name ? name : '';
    const found = UserTargetFilter.LIST
      .find((action: string) => action.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a user target filter with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

