<div class="clr-row clr-justify-content-center" style="margin-top: 2em">
  <div class="clr-col-xl-8 clr-col-lg-10 clr-col-md-12 clr-col-sm-12">
    <h1 #h1>Header 1: {{getFontSize(head1)}}/{{getLineHeight(head1)}}</h1>
    <h2 #h2>Header 2: {{getFontSize(head2)}}/{{getLineHeight(head2)}}</h2>
    <h3 #h3>Header 3: {{getFontSize(head3)}}/{{getLineHeight(head3)}}</h3>
    <h4 #h4>Header 4: {{getFontSize(head4)}}/{{getLineHeight(head4)}}</h4>
    <h5 #h5>Header 5: {{getFontSize(head5)}}/{{getLineHeight(head5)}}</h5>
    <h6 #h6>Header 6: {{getFontSize(head6)}}/{{getLineHeight(head6)}}</h6>

    <div #t1 class="id37-text1">Text 1: {{getFontSize(text1)}}/{{getLineHeight(text1)}}</div>
    <div #t2 class="id37-text2">Text 2: {{getFontSize(text2)}}/{{getLineHeight(text2)}}</div>
    <div #t3 class="id37-text3">Text 3: {{getFontSize(text3)}}/{{getLineHeight(text3)}}</div>
    <div #t4 class="id37-text4">Text 4: {{getFontSize(text4)}}/{{getLineHeight(text4)}}</div>
    <div #t5 class="id37-text5">Text 5: {{getFontSize(text5)}}/{{getLineHeight(text5)}}</div>

    <h3 style="margin-top: 1em">3 main button types:</h3>
    <button class="id37-btn-outline">outline</button>
    <br>
    <button class="id37-btn-primary">primary</button>
    <br>
    <button class="id37-btn-secondary">secondary</button>

    <h4 style="margin-top: 1em">Button modifications</h4>
    <button class="id37-btn-primary id37-btn-flat">flat</button>
    <br>
    <button class="id37-btn-primary id37-btn-bold">bold</button>
    <br>
    <button class="id37-btn-primary id37-btn-sm">small</button>
    <br>
    <button class="id37-btn-primary id37-btn-rounded-1">rounded-1</button>
    <br>
    <button class="id37-btn-primary id37-btn-rounded-2">rounded-2</button>
    <br>
    <button class="id37-btn-primary id37-btn-disabled" disabled>disabled</button>
    <br>
    <button class="id37-btn-primary id37-btn-full-width">full-width</button>
    <br>

    <h4 style="margin-top: 1em">Secondary buttons</h4>
    <button class="id37-btn-danger">danger</button>
    <br>
    <button class="id37-btn-warning">warning</button>
    <br>

    <h2 style="margin-top: 1em">Inputs</h2>

    Checkbox <id37-checkbox></id37-checkbox>

    <div class="radio-container">
      <label class="id37-radio-label id37-text3" for="SCATTER">
        <input
               id="SCATTER"
               name="radio"
               type="radio"
               value="scatter">
        <span class="id37-radio-button"></span>
        Scatter
      </label>
      <label class="id37-radio-label id37-text3" for="BUBBLE">
        <input
               id="BUBBLE"
               name="radio"
               type="radio"
               value="bubble">
        <span class="id37-radio-button"></span>
        Bubble
      </label>
    </div>

    <input>

    <app-select [choices]="languageChoices" style="margin: 0.5em 0"></app-select>
    <app-select [choices]="languageChoices" [dropUp]="true" style="margin: 0.5em 0"></app-select>

    <h2>Farben</h2>

    <div class="grey0" #g0>grey-0: {{rgb2hex(gray0)}}</div>
    <div class="grey1" #g1>grey-1: {{rgb2hex(gray1)}}</div>
    <div class="grey2" #g2>grey-2: {{rgb2hex(gray2)}}</div>
    <div class="grey3" #g3>grey-3: {{rgb2hex(gray3)}}</div>
    <div class="grey4" #g4>grey-4: {{rgb2hex(gray4)}}</div>
    <div class="grey5" #g5>grey-5: {{rgb2hex(gray5)}}</div>
    <div class="grey6" #g6>grey-6: {{rgb2hex(gray6)}}</div>
    <div class="brand-color" #c1>brand: {{rgb2hex(color1)}}</div>
    <div class="background" #c2>background: {{rgb2hex(color2)}}</div>
    <div class="error" #c3>error: {{rgb2hex(color3)}}</div>
<!--    <div class="error-light" #c4>error-light: {{rgb2hex(color4)}}</div>-->
    <div class="danger" #c5>danger: {{rgb2hex(color5)}}</div>
<!--    <div class="danger-light" #c6>danger-light: {{rgb2hex(color6)}}</div>-->
    <div class="warning" #c7>warning: {{rgb2hex(color7)}}</div>
<!--    <div class="warning-light" #c8>warning-light: {{rgb2hex(color8)}}</div>-->
    <div class="success" #c9>success: {{rgb2hex(color9)}}</div>
<!--    <div class="success-light" #c10>success-light: {{rgb2hex(color10)}}</div>-->


  </div>
</div>
