import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FriendshipState, FriendshipStore} from './friendship.store';
import {Friendship} from './friendship';
import {CurrentUser} from '../../user/current-user.service';
import {FriendshipService} from './friendship.service';
import {FriendshipFunctions} from './friendship-functions';

@Injectable({providedIn: 'root'})
export class FriendshipQuery extends QueryEntity<FriendshipState, Friendship, number> {

  constructor(protected store: FriendshipStore,
              private friendshipService: FriendshipService,
              private currentUser: CurrentUser) {
    super(store);
  }

  hasFriendship(friendshipId: number): boolean {
    return typeof this.getEntity(friendshipId) !== typeof undefined;
  }

  selectHasFriendships(): Observable<boolean> {
    return this.selectFriendships().pipe(
      map(friendships => friendships.length !== 0)
    );
  }

  selectFriendships(): Observable<Friendship[]> {
    return this.selectAll();
  }

  selectFriendship(friendshipId: number): Observable<Friendship | undefined> {
    return this.selectEntity(friendshipId);
  }

  selectFriendshipWith(userId: string): Observable<Friendship | undefined> {
    return this.selectAll().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => friendship.userA.id === userId || friendship.userB.id === userId)
        .pop()
      )
    );
  }

  getFriendshipWith(userId: string): Friendship | undefined {
    return this
      .getAll()
      .filter(friendship => friendship.userA.id === userId || friendship.userB.id === userId)
      .pop();
  }

  selectApprovedFriendships(): Observable<Friendship[]> {
    return this.selectAll().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => friendship.inviteApproved)));
  }

  selectUnapprovedFriendships(): Observable<Friendship[]> {
    return this.selectAll().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => !friendship.inviteApproved)));
  }

  selectOpenInvitationsThatISent(): Observable<Friendship[]> {
    return this.selectUnapprovedFriendships().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => friendship.userA.id === this.currentUser.keycloakId)));
  }

  selectOpenInvitationsThatIReceived(): Observable<Friendship[]> {
    return this.selectUnapprovedFriendships().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => friendship.userB.id === this.currentUser.keycloakId)));
  }

  selectApprovedFriendshipWith(userId: string): Observable<Friendship | undefined> {
    return this.selectApprovedFriendships().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => friendship.userA.id === userId || friendship.userB.id === userId)
        .pop()
      )
    );
  }

  selectInProgressFriendships(): Observable<Friendship[]> {
    return this.selectApprovedFriendships().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => FriendshipFunctions.hasTestShare(friendship, this.currentUser.keycloakId) !== true)));
  }

  selectApprovedFriendshipWithStatusDone(): Observable<Friendship[]> {
    return this.selectApprovedFriendships().pipe(
      map((friendships: Friendship[]) => friendships
        .filter(friendship => FriendshipFunctions.hasTestShare(friendship, this.currentUser.keycloakId))));
  }

}
