import {TeamRepresentation} from './team-representation';
import {Team} from './team';


export class TeamsRepresentation {

  public readonly teams: TeamRepresentation[];

  static toModelEntity(teamsRepresentation: TeamsRepresentation): Team[] {
    return teamsRepresentation.teams
      .map((teamRepresentation: TeamRepresentation) =>
        TeamRepresentation.toModelEntity(teamRepresentation));
  }
}
