import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-chatbot-chat-create-admin-form',
  templateUrl: './chatbot-chat-create-admin-form.component.html',
  styleUrls: ['./chatbot-chat-create-admin-form.component.scss']
})
export class ChatbotChatCreateAdminFormComponent {
  @Input() form: FormGroup;
  @Input() modalOpen: boolean;
  @Output() formSubmit = new EventEmitter<void>();
  @Output() modalOpenChange = new EventEmitter<boolean>();

  submitForm() {
    this.modalOpen = false;
    this.modalOpenChange.emit(this.modalOpen);
    this.formSubmit.emit();
  }
  closeModal() {
    this.modalOpen = false;
    this.modalOpenChange.emit(this.modalOpen);
  }

}
