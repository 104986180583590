import {Component, Input} from '@angular/core';
import {Notification} from '../../../state/notification';

@Component({
  selector: 'app-raw-title',
  templateUrl: './raw-title.component.html',
  styleUrls: ['./raw-title.component.scss']
})
export class RawTitleComponent {

  @Input()
    notification: Notification;

}
