import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-chatbot-startscreen',
  templateUrl: './chatbot-startscreen.component.html',
  styleUrls: ['./chatbot-startscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotStartscreenComponent {
  @Output() startSample = new EventEmitter<string>();

  suggestionList = [
    {
      title: this.__('Prompt-1-Title'),
      content: this.__('Prompt-1-Content')
    },
    {
      title: this.__('Prompt-2-Title'),
      content: this.__('Prompt-2-Content')
    },
    {
      title: this.__('Prompt-3-Title'),
      content: this.__('Prompt-3-Content')
    },
    {
      title: this.__('Prompt-4-Title'),
      content: this.__('Prompt-4-Content')
    },
  ];

  constructor(
    private translatorService: TranslatorService
  ) {}

  sendMessage(title: string, content: string) {
    if (content.trim() === '') {
      this.startSample.emit(title);
    }
    else {
      this.startSample.emit(content);
    }
  }
  __(key: string): string {
    return this.translatorService.translate('chatbot-startscreen.' + key);
  }
}
