import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ChatbotChat, ChatbotChatCreate} from '../state/chatbot-chat';
import {ChatbotChatRepresentation} from '../state/chatbot-chat-representation';
import {Id37Router} from '../../id37-router';


const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotChatService {

  constructor(private http: HttpClient, private router: Id37Router) {
  }

  public addChat(create_chat: ChatbotChatCreate): Observable<ChatbotChat> {
    return this.http.post<ChatbotChat>(
      API_URL + '/chats/add',
      {
        user_id: create_chat.user_id
      }
    ).pipe(map((rep: ChatbotChatRepresentation) => ChatbotChat.buildFrom(rep)));
  }

  public rateChat(chat_id: number, chat_rating: number): Observable<ChatbotChat> {
    return this.http.post<ChatbotChat>(
      API_URL + '/chats/rating',
      {chat_id, chat_rating}
    ).pipe(map((rep: ChatbotChatRepresentation) => ChatbotChat.buildFrom(rep)));
  }

  public getChatByID(id: number): Observable<ChatbotChat> {
    return this.http.get<ChatbotChat>(
      API_URL + '/chats/get/chat/' + id.toString(10),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotChatRepresentation: ChatbotChatRepresentation) =>
      ChatbotChat.buildFrom(chatbotChatRepresentation)
    ));
  }

  public getChatList(): Observable<ChatbotChat[]> {
    return this.http.get<ChatbotChat[]>(
      API_URL + '/chats/list',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotChatRepresentations: ChatbotChatRepresentation[]) =>
      chatbotChatRepresentations.map(chatbotChatRepresentation =>
        ChatbotChat.buildFrom(chatbotChatRepresentation))
    ));
  }

  public getChatListByUserID(): Observable<ChatbotChat[]> {
    return this.http.get<ChatbotChat[]>(
      API_URL + '/chats/get/user',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotChatRepresentations: ChatbotChatRepresentation[]) =>
      chatbotChatRepresentations.map(chatbotChatRepresentation =>
        ChatbotChat.buildFrom(chatbotChatRepresentation))
    ), catchError((error: HttpErrorResponse) => {
      if (error.status === 403) {
        this.router.routeToDashboard();
        console.log('User not allowed');
        return EMPTY;
      }
    }));
  }

  public deleteChatByID(id: number): Observable<number> {
    console.log('Delete', id);
    return this.http.delete<number>(
      API_URL + '/chats/delete/' + id.toString(10),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    );
  }

  public deleteAllChats(): Observable<number> {
    console.log('Delete all chats');
    return this.http.delete<number>(
      API_URL + '/chats/delete_all',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    );
  }

  public generateChatTitleByID(id: number): Observable<ChatbotChat> {
    return this.http.get<ChatbotChat>(
      API_URL + '/chats/generate_title/' + id.toString(10),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    );
  }

  public updateTitleByID(chat_id: number, title: string): Observable<ChatbotChat> {
    return this.http.post<ChatbotChat>(
      API_URL + '/chats/update/title',
      {chat_id, title}
    ).pipe(map((rep: ChatbotChatRepresentation) => ChatbotChat.buildFrom(rep)));
  }

}
