import {Friendship} from './friendship';
import {FriendRepresentation} from './friend-representation';
import {FriendshipFunctions} from './friendship-functions';
import {TestShareRepresentation} from '../../test/state/test-share-representation';
import {TestResultDistanceRepresentation} from '../../test/state/test-result-distance-representation';

export class FriendshipRepresentation {

  public readonly id: number;
  public readonly userA: FriendRepresentation;
  public readonly userB: FriendRepresentation;
  public readonly createdAt: string;
  public readonly inviteApproved: string;
  public readonly testShares: TestShareRepresentation[];
  public readonly distances: TestResultDistanceRepresentation[];

  static toModelEntity(friendshipRepresentation: FriendshipRepresentation, currentUserId: string): Friendship {
    return {
      id: friendshipRepresentation.id,
      userA: FriendRepresentation.toModelEntity(friendshipRepresentation.userA),
      userB: FriendRepresentation.toModelEntity(friendshipRepresentation.userB),
      createdAt: new Date(friendshipRepresentation.createdAt),
      inviteApproved: Boolean(friendshipRepresentation.inviteApproved),
      testShares: friendshipRepresentation.testShares.map(testShareRepresentation =>
        TestShareRepresentation.toModelEntity(testShareRepresentation)),
      computedStatus: FriendshipFunctions.computeFriendshipStatusFromRepresentation(
        friendshipRepresentation, currentUserId),
      distances: friendshipRepresentation.distances.map(testResultDistanceRepresentation =>
        TestResultDistanceRepresentation.toModelEntity(testResultDistanceRepresentation))
    };
  }

}
