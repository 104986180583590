import {Motive} from '../../report/types/motives/motive';
import {TestVariation} from './test-variation';
import {TranslatorService} from '../../translation/translator.service';
import {CurrentUser} from '../../user/current-user.service';

export type MotiveScores = Map<Motive, MotiveScore>;

export interface MotiveScore {

  motiveIndex: number;      // Like: 0
  motive: Motive;           // Like: NEU
  score: number;            // Like: 6

}

export class MotiveScoreFunctions {

  static calculateMaximalNumberOfCommonMotives(results: MotiveScores[], variation?: TestVariation): number {
    let min = Number.MAX_VALUE;
    for (const motiveScores of results) {
      if (motiveScores.size < min) {
        min = motiveScores.size;
      }
    }
    if (typeof variation !== typeof undefined) {
      if (variation === TestVariation.ID37_14 && min >= 14) {
        return 14;
      }
      if (variation === TestVariation.ID37_15 && min >= 15) {
        return 15;
      }
      if (variation === TestVariation.ID37_16 && min >= 16) {
        return 16;
      }
    }
    return min;
  }

  static calculateMaxNumberOfMotives(results: MotiveScores[]): number {
    let max = Number.MIN_VALUE;
    for (const motiveScores of results) {
      if (motiveScores.size > max) {
        max = motiveScores.size;
      }
    }
    return max;
  }

  static calculateMinNumberOfMotives(results: MotiveScores[]): number {
    let min = Number.MAX_VALUE;
    for (const motiveScores of results) {
      if (motiveScores.size < min) {
        min = motiveScores.size;
      }
    }
    return min;
  }

  static computeAsTuple(motiveScores: MotiveScores[], minimumNumberOfMotives: number,
                        distance: 'DISTANT' | 'CLOSE' | 'MODERATE' | 'ALL'): [MotiveScore, MotiveScore][] {

    if (motiveScores.length !== 2) {
      throw new Error('There is only 1 MotiveScores');
    }
    motiveScores.map((m) => {
      if (m.size !== minimumNumberOfMotives) {
        throw new Error('MotiveScores is not normalized yet');
      }
    });

    const motiveTuple: [MotiveScore, MotiveScore][] = [];

    if (distance === 'DISTANT') {
      for (const key of motiveScores[0].keys()) {
        if (Math.abs(motiveScores[0].get(key).score - motiveScores[1].get(key).score) >= 5) {
          motiveTuple.push([motiveScores[0].get(key), motiveScores[1].get(key)]);
        }
      }
    }
    else if (distance === 'CLOSE') {
      for (const key of motiveScores[0].keys()) {
        if (Math.abs(motiveScores[0].get(key).score - motiveScores[1].get(key).score) <= 2) {
          motiveTuple.push([motiveScores[0].get(key), motiveScores[1].get(key)]);
        }
      }
    }
    else if (distance === 'MODERATE') {
      for (const key of motiveScores[0].keys()) {
        if (Math.abs(motiveScores[0].get(key).score - motiveScores[1].get(key).score) === 3 ||
          Math.abs(motiveScores[0].get(key).score - motiveScores[1].get(key).score) === 4) {
          motiveTuple.push([motiveScores[0].get(key), motiveScores[1].get(key)]);
        }
      }
    }
    else if (distance === 'ALL') {
      for (const key of motiveScores[0].keys()) {
        motiveTuple.push([motiveScores[0].get(key), motiveScores[1].get(key)]);
      }
    }
    return motiveTuple;
  }

  static normalizeMotiveScores(motiveScores: MotiveScores, maximumNumberOfMotives: number): MotiveScores {
    if (motiveScores.size === maximumNumberOfMotives) {
      return motiveScores;
    }
    const normalized = new Map<Motive, MotiveScore>();
    let i = 0;
    motiveScores.forEach((motiveScore, motive) => {
      if (i < maximumNumberOfMotives) {
        normalized.set(motive, motiveScore);
      }
      i++;
    });
    return normalized;
  }

  static convertIntoMotiveScoreArray(motiveScores: MotiveScores[]): MotiveScore[][] {
    return motiveScores.map((motiveScore) => Array.from(motiveScore.values()));
  }

  static getIntroQuestions(normalizedResults: MotiveScores[], minimumNumberOfMotives: number,
                           translatorService: TranslatorService, currentUser: CurrentUser,
                           friendGender: string): string[] {

    let questions: string[] = [];

    const mScoreTupleDistant = this.computeAsTuple(normalizedResults, minimumNumberOfMotives, 'DISTANT');
    questions = questions.concat(
      this.filterQuestion(
        mScoreTupleDistant, 3 - questions.length, translatorService, currentUser, friendGender));

    if (questions.length !== 3) {
      const mScoreTupleModerate = this.computeAsTuple(normalizedResults, minimumNumberOfMotives, 'MODERATE');
      questions = questions.concat(
        this.filterQuestion(
          mScoreTupleModerate, 3 - questions.length, translatorService, currentUser, friendGender));
    }

    if (questions.length !== 3) {
      const mScoreTupleClose = this.computeAsTuple(normalizedResults, minimumNumberOfMotives, 'CLOSE');
      questions = questions.concat(
        this.filterQuestion(
          mScoreTupleClose, 3 - questions.length, translatorService, currentUser, friendGender));
    }
    return questions;
  }

  static filterQuestion(motiveScoreTuple: [MotiveScore, MotiveScore][], i: number,
                        translatorService: TranslatorService, currentUser: CurrentUser,
                        friendGender: string): string[] {

    const questions: string[] = [];
    const sortedMotiveScoreTuple = motiveScoreTuple.sort((a, b) =>
      Math.abs(b[0].score - b[1].score) - Math.abs(a[0].score - a[1].score) // highest first
    );

    for (const tuple of sortedMotiveScoreTuple) {
      if (tuple[1].score > tuple[0].score) { // friend's score is higher than yours -> show high text
        questions.push(translatorService.translate('distanceGraphicPage.question.'
          + tuple[1].motive.toLocaleLowerCase() + '.high')
          .replace(/\*NOMINATIVE\*/g, translatorService.translate(
            'personalPronoun.nominative', currentUser.locale, friendGender))
          .replace(/\*DATIVE\*/g, translatorService.translate(
            'personalPronoun.dative', currentUser.locale, friendGender)));
      }
      else { // friend's score is lower than yours -> show low text
        questions.push(translatorService.translate('distanceGraphicPage.question.'
          + tuple[1].motive.toLocaleLowerCase() + '.low')
          .replace(/\*NOMINATIVE\*/g, translatorService.translate(
            'personalPronoun.nominative', currentUser.locale, friendGender))
          .replace(/\*DATIVE\*/g, translatorService.translate(
            'personalPronoun.dative', currentUser.locale, friendGender))
          .replace(/\*ACCUSATIVE.N\*/g, translatorService.translate(
            'possessivePronoun.accusative.n', currentUser.locale, friendGender))
        );
      }
      if (questions.length === i) {
        return questions;
      }
    }
    return questions;
  }
}
