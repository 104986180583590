import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CurrentUser} from '../user/current-user.service';
import {map, tap} from 'rxjs/operators';
import {AccountData} from './account-data';
import {AccountDataStore} from './account-data-store';
import {AccountDataRepresentation} from './account-data-representation';
import {UpdatableAccountData} from './updatable-account-data';
import {UpdatableAccountDataRepresentation} from './updatable-account-data-representation';
import {Observable} from 'rxjs';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {

  constructor(private accountDataStore: AccountDataStore,
              private currentUser: CurrentUser,
              private http: HttpClient) {
  }

  loadForCurrentUser(): Observable<AccountData> {
    return this.load(this.currentUser.keycloakId);
  }

  load(userId: string): Observable<AccountData> {
    return this.http.get<AccountDataRepresentation>(
      API_URL + '/accountdata/' + userId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=3'
        })
      }
    ).pipe(map((accountDataRepresentation: AccountDataRepresentation) => {
      const accountData: AccountData = AccountDataRepresentation.toModelEntity(accountDataRepresentation);
      this.accountDataStore.upsert(userId, accountData);
      return accountData;
    }));
  }

  updateForCurrentUser(updatableAccountData: UpdatableAccountData): Observable<void> {
    return this.update(this.currentUser.keycloakId, updatableAccountData);
  }

  update(userId: string, updatableAccountData: UpdatableAccountData): Observable<void> {
    return this.http.post<void>(
      API_URL + '/accountdata',
      UpdatableAccountDataRepresentation.buildFrom(updatableAccountData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(tap(() => {
      const newStateProvider: ((state: AccountData) => AccountData) = (state) => ({
        masterInfo: state.masterInfo,
        partnerInfo: state.partnerInfo,
        personalData: {
          firstName: updatableAccountData.firstName,
          lastName: updatableAccountData.lastName,
          email: updatableAccountData.email,
          gender: updatableAccountData.gender,
          mobilePhone: updatableAccountData.mobilePhoneNumber,
        },
        jobAndEducation: {
          industry: updatableAccountData.industry,
          company: updatableAccountData.company,
          profession: updatableAccountData.profession,
          academicDegree: updatableAccountData.academicDegree,
          academicTitle: updatableAccountData.academicTitle
        },
        origin: {
          origin: updatableAccountData.origin,
          dateOfBirth: updatableAccountData.dateOfBirth
        },
        address: {
          residence: updatableAccountData.residence,
          city: updatableAccountData.city
        }
      });
      this.accountDataStore.update(userId, newStateProvider);
    }));
  }

}
