export enum SuperlikeType {

  I_APPRECIATE_YOU_A_LOT = 'I_APPRECIATE_YOU_A_LOT',
  INTERESTING_PERSONALITY = 'INTERESTING_PERSONALITY',
  WE_HAVE_A_LOT_OF_POTENTIAL = 'WE_HAVE_A_LOT_OF_POTENTIAL',
  LETS_TALK = 'LETS_TALK'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SuperlikeType {
  export const LIST: SuperlikeType[] = [
    SuperlikeType.I_APPRECIATE_YOU_A_LOT,
    SuperlikeType.INTERESTING_PERSONALITY,
    SuperlikeType.WE_HAVE_A_LOT_OF_POTENTIAL,
    SuperlikeType.LETS_TALK
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): SuperlikeType => {
    name = !!name ? name : '';
    const found = SuperlikeType.LIST.find((superlikeType: string) =>
      superlikeType.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a superlikeType with an unknown name: "' + name + '"');
      throw new Error('Superlike type not found.');
    }
    return found;
  };
}
