<ng-container *ngIf="!accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 4em">
    <div class="clr-col">
      <app-loading-indicator></app-loading-indicator>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="accessGranted">
  <div class="clr-row clr-justify-content-center margin-0" style="margin-top: 3em; margin-bottom: 10em">
    <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

      <h2>
        {{__('headline')}}
      </h2>

      <p class="id37-text2 text-light">
        {{__('explanation1')}}
      </p>

      <p class="id37-text2 text-light">
        {{__('explanation2')}}
      </p>

      <p class="id37-text2 text-light">
        {{__('explanation3')}}
      </p>

      <p class="id37-text2 text-light">
        {{__('explanation4')}}
      </p>

      <p class="id37-text2 text-light">
        {{__('explanation5')}}
      </p>

      <p class="id37-text2 text-light">
        {{__('explanation6')}}
      </p>

      <!-- A dummy item -->
      <div class="id37-text-2 text-bold" style="margin-top: 3em; margin-bottom: 0">
        {{__('exampleItemHeadline')}}
      </div>
      <div style="margin-bottom: 3em;
                background-color: rgba(177, 178, 184, 0.05);">
        <app-item [_answerable]="false"
                  [_infoMsg]=""
                  [_initiallySelected]="true"
                  [_item]="dummyItem"
                  [_testId]="undefined"
                  style="outline: none;"
                  tabindex="0"></app-item>
      </div>

      <h3 style="margin-top: 2.5em">
        {{__('subline1')}}
      </h3>
      <ul class="id37-text2 text-light">
        <li>
          {{__('listing1')}}
        </li>
        <li>
          {{__('listing2')}}
        </li>
        <li>
          {{__('listing3')}}
        </li>
        <li>
          {{__('listing4')}}
        </li>
      </ul>

      <h3 style="margin-top: 2.5em">
        {{__('subline2')}}
      </h3>

      <ng-container *ngIf="showVariationSelection" id="variation-selection-container">
        <ng-container *ngIf="!!testDataForm">
          <form [formGroup]="testDataForm" class="clr-form">
            <div class="clr-form-group">
              <label for="variation-select">{{__('variationLabel')}}</label>
              <app-select
                  [choices]="variationChoices"
                  [formControl]="testDataForm.controls['variation']"
                  [placeholder]="__('variationPlaceholder')"
                  id="variation-select">
              </app-select>
            </div>
          </form>
          <ul class="id37-text2 text-light">
            <li>{{__('variationDescriptionID3716')}}</li>
            <li>{{__('variationDescriptionID3715')}}</li>
            <li>{{__('variationDescriptionID3714')}}</li>
          </ul>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!!personalDataForm">
        <form [formGroup]="personalDataForm" class="clr-form">
          <div class="clr-form-group">
            <label for="birthday-input">{{__('dateofbirth_label')}}</label>
            <app-datetime
              #birthdayInput
              [formPropagationType]="'isoFormatted'"
              [formPropagationZone]="'localeZone'"
              [guideSelectionMode]="'yearFirst'"
              [maxDate]="maxDate"
              [minDate]="minDate"
              [placeholder]="__('dateofbirth_placeholder')"
              [formControl]="personalDataForm.controls['dateOfBirth']"
              [type]="'date'"
              formControlName="dateOfBirth"
              id="birthday-input">
            </app-datetime>
            <div *ngIf="dateOfBirth.errors?.required && birthdayInput.touched" class="alert danger">
              {{__('errors.required')}}
            </div>
          </div>

          <div class="clr-form-group">
            <label for="country-select">{{__('country_label')}}</label>
            <app-select
              #residenceInput
              [choicesGroups]="countryChoices"
              [formControl]="personalDataForm.controls['residence']"
              [placeholder]="__('country_placeholder')"
              id="country-select">
            </app-select>
            <div *ngIf="residence.errors?.required && residenceInput.state.touched" class="alert danger">
              {{__('errors.required')}}
            </div>
          </div>

          <div class="clr-form-group">
            <label for="nationality-select">{{__('nationality_label')}}</label>
            <app-select
              #nationalityInput
              [choicesGroups]="nationalityChoices"
              [formControl]="personalDataForm.controls['origin']"
              [placeholder]="__('nationality_placeholder')"
              id="nationality-select">
            </app-select>
            <div *ngIf="origin.errors?.required && nationalityInput.state.touched" class="alert danger">
              {{__('errors.required')}}
            </div>
          </div>

          <div class="clr-form-group">
            <label for="gender-select">{{__('gender_label')}}</label>
            <app-select
              #genderInput
              [choices]="genderChoices"
              [formControl]="personalDataForm.controls['gender']"
              [placeholder]="__('gender_placeholder')"
              id="gender-select">
            </app-select>
            <div *ngIf="gender.errors?.required && genderInput.state.touched" class="alert danger">
              {{__('errors.required')}}
            </div>
          </div>

          <div class="id37-text3 text-light" style="margin-top: 1em">
            {{___('addressForm.required')}}
          </div>

          <h3 style="margin-top: 2.5em;">{{__('language_headline')}}</h3>

          <div class="id37-text2 text-light">{{__('language_text')}}</div>
          <div class="clr-form-group">
            <label for="language-select">{{__('language_label')}}</label>
            <app-select
                [choices]="languageChoices"
                [dropUp]="true"
                [formControl]="personalDataForm.controls['language']"
                [placeholder]="__('language_placeholder')"
                id="language-select">
            </app-select>
          </div>

        </form>

        <div class="clr-row aligned" style="margin-top: 2.5em; margin-bottom: 10em;">
          <div class="clr-col-12 aligned">
            <button (click)="leaveStartPage()"
                    class="id37-btn-outline start-button">
              {{__('start_button')}}
            </button>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>
