import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MotiveScore, MotiveScoreFunctions, MotiveScores} from '../../test/state/motive-score';
import {TranslatorService} from '../../translation/translator.service';
import {CurrentUser} from '../../user/current-user.service';
import {Friendship} from '../../friend/state/friendship';
import {ScrollService} from '../../id37/scroll.service';
import {ConsultMasterComponent} from './consult-master/consult-master.component';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';
import {TestVariation} from '../../test/state/test-variation';

interface ButtonFilterOption {
  filter: 'DISTANT' | 'CLOSE' | 'MODERATE' | 'ALL';
  displayName: string;
}

@Component({
  selector: 'app-distance-graphic-page',
  templateUrl: './distance-graphic-page.component.html',
  styleUrls: ['./distance-graphic-page.component.scss']
})
export class DistanceGraphicPageComponent implements OnInit {

  @ViewChild(ConsultMasterComponent)
    consultMaster: ConsultMasterComponent;

  @Input()
    results: MotiveScores[];

  @Input()
    friendId: string;

  @Input()
    friendFullName: string;

  @Input()
    friendFirstname: string;

  @Input()
    friendGender: '' | 'male' | 'female' | 'diverse' = '';

  @Input()
    distance: number;

  @ViewChild('svgWrapper')
    svgWrapper: ElementRef;

  friendship: Friendship;
  minimumNumberOfMotives: number;
  numberOfMotivesView: number = 0;
  normalizedResults: MotiveScores[];
  normalizedMotiveScoreTuple: [MotiveScore, MotiveScore][];
  height: number = 200;

  buttonFilterOptions: ButtonFilterOption[];
  variation: 'DISTANT' | 'CLOSE' | 'MODERATE' | 'ALL' = 'DISTANT';

  questions: string[] = [];

  constructor(private currentUser: CurrentUser,
              private renderer: Renderer2,
              public scrollService: ScrollService,
              private translatorService: TranslatorService) {
  }


  ngOnInit(): void {
    this.questions = MotiveScoreFunctions
      .getIntroQuestions(this.normalizedResults, this.minimumNumberOfMotives, this.translatorService,
        this.currentUser, this.friendGender);
  }

  @Input()
  set activeFriendship(friendship: Friendship) {
    this.friendship = friendship;
    this.minimumNumberOfMotives = TestVariation.getMinimum(
      FriendshipFunctions.getMyTestShare(this.friendship, this.currentUser.keycloakId).variation,
      FriendshipFunctions.getFriendTestShare(this.friendship, this.currentUser.keycloakId).variation
    ).motiveCount;
    this.numberOfMotivesView = this.minimumNumberOfMotives;
    this.normalizedResults = this.results.map((motiveScores) =>
      MotiveScoreFunctions.normalizeMotiveScores(motiveScores, this.minimumNumberOfMotives));
    this.computeButtonFilterOptions();
    this.calculateNormalizedResults('DISTANT');

  }

  changeFilter(filter: 'DISTANT' | 'CLOSE' | 'MODERATE') {
    this.variation = filter;
    this.calculateNormalizedResults(filter);
  }

  calculateNormalizedResults(option: 'DISTANT' | 'CLOSE' | 'MODERATE' | 'ALL'): void {
    this.normalizedMotiveScoreTuple = MotiveScoreFunctions.computeAsTuple(this.normalizedResults,
      this.minimumNumberOfMotives, option);
    this.numberOfMotivesView = this.normalizedMotiveScoreTuple.length;
    this.height = 200 + (this.numberOfMotivesView * 80);
    this.variation = option;
    setTimeout(() => {
      // Otherwise the svgWrapper is not in the page.
      if (this.numberOfMotivesView !== 0) {
        this.renderer.setAttribute(this.svgWrapper.nativeElement, 'viewBox', '0 0 1200 ' + this.height);
      }
    }, 0);
  }

  computeButtonFilterOptions(): void {
    this.buttonFilterOptions = [];
    this.buttonFilterOptions.push({filter: 'DISTANT', displayName: this.__('button.distant')});
    this.buttonFilterOptions.push({filter: 'CLOSE', displayName: this.__('button.close')});
    this.buttonFilterOptions.push({filter: 'MODERATE', displayName: this.__('button.moderate')});
    this.buttonFilterOptions.push({filter: 'ALL', displayName: this.__('button.all')});
  }

  expandCollapsibleMaster() {
    this.consultMaster.collapsibleItem.setExpanded(true);
    setTimeout(() => this.scrollService.scrollToElement('masterAnchor'), 200);
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('distanceGraphicPage.' + key);
    const number: string = Number(this.distance * 100).toFixed(0);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation.replace(/\*FIRSTNAME\*/g, this.friendFirstname);
    revisedTranslation = revisedTranslation.replace('*DISTANCE*', number);
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.___('accusative.f/pl', this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.___('accusative.n', this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.F\*/g, this.___('dative.f', this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService
        .translate('personalPronoun.nominative', this.currentUser.locale, this.friendGender));
    return revisedTranslation;
  }

  ___(key: string, friendGender: string): string {
    return this.translatorService.translate('possessivePronoun.' + key, this.currentUser.locale, friendGender);
  }

}
