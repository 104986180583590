import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {map, take, tap} from 'rxjs/operators';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {Notification} from './notification';
import {NotificationStore} from './notification.store';
import {NotificationRepresentation} from './notification.representation';
import {environment} from '../../../environments/environment';
import {CurrentUser} from '../../user/current-user.service';
import {LastKnownNotificationIdRepresentation} from './last-known-notification-id.representation';
import {NotificationReadStateRepresentation} from './notification-read-state.representation';
import {NotificationQuery} from './notification.query';

const API_URL = environment.API_URL;
const API_WS_URL = environment.API_WS_URL;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient,
              private currentUser: CurrentUser,
              private notificationQuery: NotificationQuery,
              private notificationStore: NotificationStore) {
  }

  public markAsRead(notification: Notification): void {
    if (!notification.read) {
      this.toggleReadState(notification);
    }
  }

  public toggleReadState(notification: Notification, notifyBackend: boolean = true): void {
    const updatedNotification = {
      notificationId: notification.notificationId,
      type: notification.type,
      data: notification.data,
      read: !notification.read,
      createdAt: notification.createdAt
    };
    this.notificationStore.upsert(notification.notificationId, updatedNotification);
    if (notifyBackend) {
      this.patchReadState(updatedNotification);
    }
  }

  private patchReadState(updatedNotification: Notification): void {
    this.http.patch<void>(
      API_URL + '/notifications/' + updatedNotification.notificationId,
      new NotificationReadStateRepresentation(updatedNotification.read),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(take(1)).subscribe(
      success => {
      },
      error => {
        // Revert change.
        this.toggleReadState(updatedNotification, false);
      }
    );
  }

  private storeNotification(notification: Notification): void {
    this.notificationStore.upsert(notification.notificationId, notification);
    this.notificationQuery.populateNotificationUpdate(notification);
  }

  public listenForNotifications(): Observable<Notification> {
    console.log('Listening for new notifications...');
    const webSocketSubject: WebSocketSubject<NotificationRepresentation>
      = webSocket(API_WS_URL + '/notifications/' + this.currentUser.keycloakId);
    console.log(webSocketSubject);
    return webSocketSubject
      .asObservable()
      .pipe(
        map((notificationRepresentation: NotificationRepresentation) =>
          NotificationRepresentation.toModelEntity(notificationRepresentation)
        ),
        tap((notification: Notification) => this.storeNotification(notification))
      );
  }

  public loadNotifications(): Observable<Notification[]> {
    return this.http.get<NotificationRepresentation[]>(
      API_URL + '/notifications',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((notificationRepresentations: NotificationRepresentation[]) => notificationRepresentations
        .map(notificationRepresentation => NotificationRepresentation.toModelEntity(notificationRepresentation))),
      tap((notifications: Notification[]) => notifications.forEach((notification: Notification) =>
        this.storeNotification(notification)))
    );
  }

  public loadNotificationUpdates(lastKnownNotificationIdRepresentation: LastKnownNotificationIdRepresentation): Observable<Notification[]> {
    return this.http.post<NotificationRepresentation[]>(
      API_URL + '/notifications/updates',
      lastKnownNotificationIdRepresentation,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((notificationRepresentations: NotificationRepresentation[]) => notificationRepresentations
        .map(notificationRepresentation => NotificationRepresentation.toModelEntity(notificationRepresentation))),
      tap((notifications: Notification[]) => notifications.forEach((notification: Notification) =>
        this.storeNotification(notification)))
    );
  }

  public delete(notification: Notification): void {
    this.notificationStore.remove(notification.notificationId);
    this.http.delete<HttpResponse<unknown>>(
      API_URL + '/notifications/' + notification.notificationId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(take(1)).subscribe(
      success => {
      },
      error => {
        // Revert change.
        this.storeNotification(notification);
      }
    );
  }

}
