import {Component, HostListener, Input} from '@angular/core';
import {ItemToHeaderAndBodyCommunicationService} from '../services/item-to-header-and-body-communication.service';
import {CollapsibleItemComponent} from '../collapsible-item/collapsible-item.component';

@Component({
  selector: 'app-collapsible-header',
  templateUrl: './collapsible-header.component.html',
  styleUrls: ['./collapsible-header.component.scss']
})
export class CollapsibleHeaderComponent {

  @Input()
    title: string = undefined;

  @Input()
    showHTML: string = undefined;

  itemIsExpanded = false;

  constructor(private itemToHeaderAndBodyCom: ItemToHeaderAndBodyCommunicationService) {
    this.itemToHeaderAndBodyCom.onItemStateChange$.subscribe((item: CollapsibleItemComponent) => {
      this.itemIsExpanded = item.expanded;
    });
  }

  @HostListener('click')
  click(): void {
    this.itemToHeaderAndBodyCom.headerClicked(this);
  }

}
