import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject, zip} from 'rxjs';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {TranslatorService} from '../../../translation/translator.service';
import {RoutingService} from '../../../routing/routing.service';
import {TestQuery} from '../../../test/state/test.query';
import {MotiveScores} from '../../../test/state/motive-score';

@Component({
  selector: 'app-comparer',
  templateUrl: './comparer.component.html',
  styleUrls: ['./comparer.component.scss']
})
export class ComparerComponent implements OnInit, OnDestroy {

  unsubscribe$: Subject<void> = new Subject<void>();
  loaded = false;
  user1Name: string;
  user1MotiveScores: MotiveScores;
  user2Name: string;
  user2MotiveScores: MotiveScores;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private testQuery: TestQuery,
              private translatorService: TranslatorService,
              private routingService: RoutingService) {
  }

  ngOnInit(): void {
    if (window.history.state.user1Id == null
      || window.history.state.user1TestId == null
      || window.history.state.user2Id == null
      || window.history.state.user2TestId == null) {
      this.router.navigate(['/toolbox', 'compare']);
    }
    else {
      this.activatedRoute.paramMap
        .pipe(
          map(() => window.history.state),
          mergeMap(state => zip(
            this.testQuery.selectTest(state.user1Id as string, state.user1TestId as string)
              .pipe(tap((test) => {
                this.user1Name = window.history.state.user1Name;
                this.user1MotiveScores = test.result.motiveScores;
              })),
            this.testQuery.selectTest(state.user2Id as string, state.user2TestId as string)
              .pipe(tap((test) => {
                this.user2Name = window.history.state.user2Name;
                this.user2MotiveScores = test.result.motiveScores;
              }))
          ).pipe(
            take(1)
          ))
        ).subscribe(() => this.loaded = true);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  title(): string {
    return '';
  }

  printMotiveProfile(): void {
    window.print();
  }

  closeReport(): void {
    this.routingService.routeBack();
  }

  __(key: string): string {
    return this.translatorService.translate('toolbox.' + key);
  }

}
