<ng-container *ngIf="!!openInvitationsThatIReceived && openInvitationsThatIReceived.length">
  <h3>{{__('receivedRequests')}} ({{openInvitationsThatIReceived.length}})</h3>
  <id37-friend-tile-container>
    <ng-container *ngFor="let friendship of openInvitationsThatIReceived">
      <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                       (declineFriendship)="declineFriendship.emit($event)"
                       (deleteFriendship)="deleteFriendship.emit($event)"
                       (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                       (updateTestShare)="updateTestShare.emit($event)"
                       [activeFriendship]="friendship"
                       [user]="undefined"
      ></app-friend-tile>
    </ng-container>
  </id37-friend-tile-container>
</ng-container>

<ng-container *ngIf="!!openInvitationsThatISent && openInvitationsThatISent.length">
  <h3>{{__('sentRequests')}} ({{openInvitationsThatISent.length}})</h3>
  <id37-friend-tile-container>
    <ng-container *ngFor="let friendship of openInvitationsThatISent">
      <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                       (declineFriendship)="declineFriendship.emit($event)"
                       (deleteFriendship)="deleteFriendship.emit($event)"
                       (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                       (updateTestShare)="updateTestShare.emit($event)"
                       [activeFriendship]="friendship"
                       [loading]="!!isLoading[friendship.userB.id] ? isLoading[friendship.userB.id] : false"
                       [user]="undefined"
      ></app-friend-tile>
    </ng-container>
  </id37-friend-tile-container>
</ng-container>

<ng-container *ngIf="!!inProgressFriendships &&inProgressFriendships.length">
  <h3>{{__('inProgress')}} ({{inProgressFriendships.length}})</h3>
  <id37-friend-tile-container>
    <ng-container *ngFor="let friendship of inProgressFriendships">
      <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                       (declineFriendship)="declineFriendship.emit($event)"
                       (deleteFriendship)="deleteFriendship.emit($event)"
                       (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                       (updateTestShare)="updateTestShare.emit($event)"
                       [activeFriendship]="friendship"
                       [loading]="!!isLoading[friendship.userA.id] ? isLoading[friendship.userA.id] : false"
                       [user]="undefined"
      ></app-friend-tile>
    </ng-container>
  </id37-friend-tile-container>
</ng-container>

<ng-container *ngIf="!!approvedFriendships && approvedFriendships.length">
  <h3>{{___('friends.myContacts')}} ({{approvedFriendships.length}})</h3>
  <id37-friend-tile-container>

    <!-- "Recommend" tile -->
    <app-friend-tile *featureAllowed="'recommend'"
                     (recommend)="recommend.emit($event)"
                     [activeFriendship]="undefined"
                     [loading]="false"
                     [user]="undefined"
    ></app-friend-tile>

    <ng-container *ngFor="let friendship of approvedFriendships">
      <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                       (declineFriendship)="declineFriendship.emit($event)"
                       (deleteFriendship)="deleteFriendship.emit($event)"
                       (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                       (updateTestShare)="updateTestShare.emit($event)"
                       [activeFriendship]="friendship"
                       [loading]="!!isLoading[friendship.userA.id] ? isLoading[friendship.userA.id] : false"
                       [user]="undefined"
      ></app-friend-tile>
    </ng-container>
  </id37-friend-tile-container>

</ng-container>
