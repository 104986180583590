import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUser} from './user/current-user.service';
import {DOCUMENT} from '@angular/common';
import {RoutingService} from './routing/routing.service';
import {UserService} from './user/user.service';
import {environment} from '../environments/environment';
import {enableAkitaProdMode} from '@datorama/akita';
import {ActiveUser} from './user/active-user';
import {NotificationService} from './notifications/state/notification.service';
import {take, takeUntil, tap} from 'rxjs/operators';
import {interval, Subject} from 'rxjs';
import {LastKnownNotificationIdRepresentation} from './notifications/state/last-known-notification-id.representation';
import {NotificationQuery} from './notifications/state/notification.query';
import {Title} from '@angular/platform-browser';
import {NotificationHandler} from './notifications/notification-handler';
import {FriendshipService} from './friend/state/friendship.service';
import {ChatMessagesAutomationService} from './chat/state/chat-messages-automation.service';
import {DynamicScriptLoaderService} from './order/dynamic-script-loader.service';
import {EmailSettingsService} from './account/email-settings/state/email-settings.service';
import {TestService} from './test/state/test.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  backendUserExists = false;
  backendIsExistent = true;
  testLoaded = false;
  isIE = /msie\s|trident\//i.test(window.navigator.userAgent);

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private routingService: RoutingService,
              public currentUser: CurrentUser,
              private userService: UserService,
              @Inject(DOCUMENT) private document: HTMLDocument,
              private router: Router,
              private notificationService: NotificationService,
              private notificationQuery: NotificationQuery,
              private notificationHandler: NotificationHandler,
              private titleService: Title,
              private friendshipService: FriendshipService,
              private chatMessagesAutomationService: ChatMessagesAutomationService,
              private dynamicScriptLoader: DynamicScriptLoaderService,
              private emailSettingsService: EmailSettingsService,
              private testService: TestService) {
  }

  ngOnInit() {
    if (environment.production) {
      enableAkitaProdMode();
    }

    this.routingService.init();
    this.backendIsExistent = true;
    this.userService.ensureBackendUserExists().subscribe(
      (activeUser: ActiveUser) => {
        this.loadHeidelpayScript();
        this.currentUser.setCurrentUser(activeUser);
        this.loadTest();
        this.backendUserExists = true;
        this.initEmailSettings();
        this.initNotifications();
        this.initFriendships();
        this.chatMessagesAutomationService.loadInitialChatMessages();
        this.chatMessagesAutomationService.startPollingUnreadChatMessages(
          ChatMessagesAutomationService.unreadMessagesSlowPollRateInSeconds
        );
      },
      err => {
        console.log('HTTP Error', err);
        this.backendIsExistent = false;
      }
    );
    if (this.document.referrer.includes(`/auth/realms/${this.currentUser.realm}/`)) {
      this.router.navigate(['dashboard']);
    }
  }

  ngOnDestroy(): void {
    this.chatMessagesAutomationService.stopPollingUnreadChatMessages();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadTest() {
    this.testService.loadTests(this.currentUser.keycloakId).pipe(takeUntil(this.unsubscribe$))
      .subscribe((a) => this.testLoaded = true);
  }

  private loadHeidelpayScript() {
    this.dynamicScriptLoader.load('unzer').then(data => {
      // Do nothing. We expect the code to be ready when used and therefore do not guard usage of "unzer" in th code.
    }).catch(error => console.error(error));
  }

  private initEmailSettings(): void {
    this.emailSettingsService.loadEmailSettings();
  }

  private initNotifications(): void {
    // Load all existing notifications.
    this.notificationService.loadNotifications()
      .pipe(take(1))
      .subscribe(notifications => {
        // Initial notifications are now ready.
        // All new notifications should be processed!
        this.notificationHandler.startListeningForNewNotifications();
      });

    // Load notification updates.
    interval(environment.NOTIFICATION_REFRESH_TIME_IN_SECONDS * 1000)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(next => this.notificationService.loadNotificationUpdates(
          new LastKnownNotificationIdRepresentation(this.notificationQuery.getLastKnownNotificationId()))
          .pipe(take(1))
          .subscribe())
      )
      .subscribe();

    // Subscribe to notification changes. The title of the page should be updated if unread notifications are present!
    const baseTitle = this.titleService.getTitle();
    this.notificationQuery.selectUnreadNotificationsAmount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((unreadNotificationsAmount: number) => {
        if (unreadNotificationsAmount > 0) {
          this.titleService.setTitle('(' + unreadNotificationsAmount + ') ' + baseTitle);
        }
        else {
          this.titleService.setTitle(baseTitle);
        }
      });
  }

  private initFriendships(): void {
    this.friendshipService.loadFriendships().pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

}
