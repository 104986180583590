import {Injectable} from '@angular/core';
import {Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {Observable, Subject} from 'rxjs';
import {Notification} from './notification';
import {NotificationsState, NotificationStore} from './notification.store';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
@QueryConfig({
  sortBy: 'notificationId',
  sortByOrder: Order.DESC
})
export class NotificationQuery extends QueryEntity<NotificationsState, Notification, number> {

  private notificationUpdates$: Subject<Notification> = new Subject<Notification>();

  constructor(protected store: NotificationStore) {
    super(store);
  }

  populateNotificationUpdate(notification: Notification) {
    this.notificationUpdates$.next(notification);
  }

  selectNotificationUpdates(): Observable<Notification> {
    return this.notificationUpdates$.asObservable();
  }

  getLastKnownNotificationId(): number | null {
    const notifications: Notification[] = this.getAll();
    if (!!notifications && notifications.length > 0) {
      // Store is configured to sort notifications desc by their id! See @QueryConfig.
      // => The newest notification is in the first slot of the given array.
      return notifications[0].notificationId;
    }
    return null;
  }

  selectNotifications(): Observable<Notification[]> {
    return this.selectAll();
  }

  selectUnreadNotifications(): Observable<Notification[]> {
    return this.selectAll()
      .pipe(
        map((notifications: Notification[]) =>
          notifications.filter(notification => !notification.read))
      );
  }

  selectUnreadNotificationsAmount(): Observable<number> {
    return this.selectUnreadNotifications()
      .pipe(
        map((unreadNotifications: Notification[]) => unreadNotifications.length)
      );
  }

}
