import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderAddress} from '../order-address';
import {ChoicesGroup} from '../../../select/state/choices-types';
import {ChoicesOptions} from '../../../user-data/choices-options';
import {CountryService} from '../../../user-data/country.service';
import {CurrentUser} from '../../../user/current-user.service';
import {CustomValidators} from '../../../form/custom-validators';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {

  @Input()
    showTitle: boolean = true;

  @Input()
    addressIn: EventEmitter<OrderAddress>;

  @Input()
    addressOutTrigger: EventEmitter<void>;

  @Output()
    valid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
    addressOut: EventEmitter<OrderAddress> = new EventEmitter<OrderAddress>();

  invoiceAddressForm: FormGroup;

  countryChoices: ChoicesGroup[];

  lastErrorState: boolean = true;

  constructor(private countryService: CountryService,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
    this.countryChoices = [
      {
        label: 'Top countries',
        id: 1,
        choices: ChoicesOptions.topCountries(this.currentUser.locale)
      },
      {
        label: 'All countries',
        id: 2,
        choices: this.countryService.getCountriesAsChoicesOptions()
      }
    ];
  }

  get firstName() {
    return this.invoiceAddressForm.get('firstName');
  }

  get lastName() {
    return this.invoiceAddressForm.get('lastName');
  }

  get company() {
    return this.invoiceAddressForm.get('company');
  }

  get vatIdentification() {
    return this.invoiceAddressForm.get('vatIdentification');
  }

  get country() {
    return this.invoiceAddressForm.get('country');
  }

  get state() {
    return this.invoiceAddressForm.get('state');
  }

  get zipCode() {
    return this.invoiceAddressForm.get('zipCode');
  }

  get city() {
    return this.invoiceAddressForm.get('city');
  }

  get street() {
    return this.invoiceAddressForm.get('street');
  }

  get houseNr() {
    return this.invoiceAddressForm.get('houseNr');
  }

  ngOnInit() {
    this.initForm();
    this.addressIn.subscribe((address: OrderAddress) => {
      if (address.firstName.length !== 0) {
        this.firstName.setValue(address.firstName);
      }
      if (address.lastName.length !== 0) {
        this.lastName.setValue(address.lastName);
      }
      if (address.company.length !== 0) {
        this.company.setValue(address.company);
      }
      if (address.country.length !== 0) {
        this.country.setValue(address.country);
      }
      if (address.state.length !== 0) {
        this.state.setValue(address.state);
      }
      if (address.vatIdentification.length !== 0) {
        this.vatIdentification.setValue(address.vatIdentification);
      }
      if (address.zipCode.length !== 0) {
        this.zipCode.setValue(address.zipCode);
      }
      if (address.city.length !== 0) {
        this.city.setValue(address.city);
      }
      if (address.street.length !== 0) {
        this.street.setValue(address.street);
      }
      if (address.houseNr.length !== 0) {
        this.houseNr.setValue(address.houseNr);
      }
    });

    this.addressOutTrigger.subscribe(() => this.triggerAddressOutput());
  }

  initForm(): void {
    this.invoiceAddressForm = new FormGroup({
      firstName: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      lastName: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      company: new FormControl('', {
        validators: [CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      vatIdentification: new FormControl('', {
        validators: [CustomValidators.noWhitespace, Validators.maxLength(64)],
        updateOn: 'change'
      }),
      country: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      state: new FormControl('', {
        validators: [CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      zipCode: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(16)],
        updateOn: 'change'
      }),
      city: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      street: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(256)],
        updateOn: 'change'
      }),
      houseNr: new FormControl('', {
        validators: [Validators.required, CustomValidators.noWhitespace, Validators.maxLength(32)],
        updateOn: 'change'
      })
    });
  }

  checkErrors(): boolean {
    const hasErrors = this.hasErrors();
    if (hasErrors !== this.lastErrorState) {
      this.lastErrorState = hasErrors;
      this.valid.emit(!hasErrors);
    }
    return hasErrors;
  }

  triggerAddressOutput(): void {
    if (!this.invoiceAddressForm.valid) {
      Object.keys(this.invoiceAddressForm.controls).forEach(field => {
        if (!(field === 'state')) {
          this.invoiceAddressForm.get(field).markAsTouched();
        }
      });
      return;
    }
    this.addressOut.emit(new OrderAddress(
      (this.firstName.value as string || '').trim(),
      (this.lastName.value as string || '').trim(),
      (this.company.value as string || '').trim(),
      (this.vatIdentification.value as string || '').trim(),
      (this.country.value as string || '').trim(),
      (this.state.value as string || '').trim(),
      (this.zipCode.value as string || '').trim(),
      (this.city.value as string || '').trim(),
      (this.street.value as string || '').trim(),
      (this.houseNr.value as string || '').trim()
    ));
  }

  __(key: string): string {
    return this.translatorService.translate('addressForm.' + key);
  }

  private hasErrors(): boolean {
    return !!this.firstName.errors
      || !!this.lastName.errors
      || !!this.company.errors
      || !!this.vatIdentification.errors
      || !!this.country.errors
      || !!this.zipCode.errors
      || !!this.city.errors
      || !!this.state.errors
      || !!this.street.errors
      || !!this.houseNr.errors;
  }

}
