// eslint-disable-next-line no-shadow
export enum KeycloakUserFeatureFlag {

  RESET_MY_TEST = 'RESET_MY_TEST'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace KeycloakUserFeatureFlag {
  export const LIST: KeycloakUserFeatureFlag[] = [
    KeycloakUserFeatureFlag.RESET_MY_TEST
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): KeycloakUserFeatureFlag => {
    name = !!name ? name : '';
    const found = KeycloakUserFeatureFlag.LIST.find(
      (keycloakUserFeatureFlag: string) => keycloakUserFeatureFlag.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a KeycloakUserFeatureFlag with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}
