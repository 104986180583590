import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';
import {Chart} from 'chart.js';
import {TranslatorService} from '../../translation/translator.service';
import {Motive, MotiveButton} from '../../report/types/motives/motive';
import {ChartColor} from '../chart-color';

@Component({
  selector: 'app-polar',
  templateUrl: './polar.component.html',
  styleUrls: ['./polar.component.scss']
})
export class PolarComponent implements OnInit {

  @Input()
    customerName: string[];

  @Input()
    customerMotiveScore: MotiveScore[][];

  motiveButtons: MotiveButton[] = [];
  variation: number;

  chart: Chart;
  borderColor: string[] = ChartColor.getChartColors(0.6);
  backgroundColor: string[] = ChartColor.getChartColors(0.3);
  chartData: number[] = [];

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.computeMotiveButtons();
    this.buildChart();
  }

  computeMotiveButtons(): void {
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        this.motiveButtons.push({
          motive: i,
          displayName: this.translatorService.translate('motive.' + m.valueOf().toLowerCase())
        });
      }
    });
  }

  buildChart(): void {
    this.chart = new Chart('polar-canvas', {
      type: 'polarArea',
      data: {
        labels: this.customerName
      },
      options: {
        scale: {
          ticks: {
            min: 0,
            max: 10,
            stepSize: 1
          }
        }
      }
    });
  }


  initChartData(motive: number): void {
    this.variation = motive;
    this.chartData = [];
    this.customerMotiveScore.forEach((motiveScoreArray: MotiveScore[]) => {
      motiveScoreArray.forEach((motiveScore: MotiveScore) => {
        if (motiveScore.motiveIndex === motive) {
          this.chartData.push(motiveScore.score);
        }
      });
    });
    this.chart.data.datasets = [{
      backgroundColor: this.backgroundColor,
      borderColor: this.borderColor,
      data: this.chartData
    }];
    this.chart.update();
  }
}
