export enum OrderStatus {

  INITIALIZED = 'INITIALIZED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  RETRYING = 'RETRYING',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  SUBSCRIPTION_TERMINATED = 'SUBSCRIPTION_TERMINATED'


}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OrderStatus {
  export const LIST: OrderStatus[] = [
    OrderStatus.INITIALIZED, OrderStatus.PENDING, OrderStatus.CANCELED, OrderStatus.COMPLETED, OrderStatus.ERROR,
    OrderStatus.RETRYING, OrderStatus.UNSUBSCRIBED, OrderStatus.SUBSCRIPTION_TERMINATED
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): OrderStatus | undefined => {
    name = !!name ? name : '';
    const found = OrderStatus.LIST.find((status: string) => status.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access an order status with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

