<ng-container *ngIf="!cart">
  <app-loading-indicator></app-loading-indicator>
</ng-container>

<ng-container *ngIf="cart">
  <app-positions [positions]="cart.positions" [showDiscount]="showSavings"></app-positions>

  <div id="summary">
    <h4 id="summary-title">
      {{__('summary')}}
    </h4>
    <div id="summary-data">
      <table>
        <tr>
          <td>{{__('netSum')}}:</td>
          <td>{{cart.positionsSummary.netSumAsMoney}}</td>
        </tr>
        <tr *ngIf="cart.positionsSummary.savingsAsMoney !== '€0.00'">
          <td>{{__('savings')}}:</td>
          <td>{{cart.positionsSummary.savingsAsMoney}}</td>
        </tr>
        <tr>
          <td>{{__('vatLosses')}}:</td>
          <td>{{cart.positionsSummary.vatLossesAsMoney}}</td>
        </tr>
        <tr>
          <td>{{__('grossSum')}}:</td>
          <td>{{cart.positionsSummary.grossSumAsMoney}}</td>
        </tr>
      </table>
    </div>
  </div>
</ng-container>
