<div *ngIf="!reload" class="selections">

  <div class="hour-selection">
    <div class="name">{{hourName}}</div>
    <div (click)="incHour()" class="arrow-up"></div>
    <input
      #hour
      (keyup)="hourInputChanged$.next($event.target['value'])"
      (paste)="hourInputChanged$.next($event.target['value'])"
      [value]="moment(selected).hours()"
      type="text">
    <div (click)="decHour()" class="arrow-down"></div>
  </div>

  <div *ngIf="allowMinuteSelection" class="minute-selection">
    <div class="name">{{minuteName}}</div>
    <div (click)="incMinute()" class="arrow-up"></div>
    <input
      #minute
      (change)="minuteInputChanged$.next($event.target['value'])"
      (keyup)="minuteInputChanged$.next($event.target['value'])"
      [value]="moment(selected).minutes()"
      type="text">
    <div (click)="decMinute()" class="arrow-down"></div>
  </div>

  <div *ngIf="allowSecondSelection" class="second-selection">
    <div class="name">{{secondName}}</div>
    <div (click)="incSecond()" class="arrow-up"></div>
    <input
      #second
      (change)="secondInputChanged$.next($event.target['value'])"
      (keyup)="secondInputChanged$.next($event.target['value'])"
      [value]="moment(selected).seconds()"
      type="text">
    <div (click)="decSecond()" class="arrow-down"></div>
  </div>

</div>

<div class="actions">
  <button (click)="propagateChangeAndClose()" class="id37-btn-outline" type="button">Ok</button>
</div>
