<ng-container *ngIf="!!activeContactMessagesByDay$">
  <ng-container *ngIf="activeContactMessagesByDay$ | async as activeContactMessages; else selectContact">
    <ng-container *ngIf="!!activeContactMessages">
      <ng-container
          *ngFor="let chatMessagesByDay of activeContactMessages.chatMessagesByDay | async; index as dayIndex; first as firstDay; last as lastDay">
        <app-chat-messages-day-separator [date]="chatMessagesByDay[0].sent"></app-chat-messages-day-separator>
        <ng-container
            *ngFor="let chatMessage of chatMessagesByDay; index as index; first as first; last as last">
          <ng-container *ngIf="first && !last">
            <app-chat-message (update)="update(activeContactMessages.friendship, chatMessage, $event)"
                              [chatMessage]="chatMessage"
                              [contact]="activeContactMessages.friendship"
                              [previousChatMessage]="undefined">
            </app-chat-message>
          </ng-container>
          <ng-container *ngIf="(!first && !last) || (last && !lastDay)">
            <app-chat-message (update)="update(activeContactMessages.friendship, chatMessage, $event)"
                              [chatMessage]="chatMessage"
                              [contact]="activeContactMessages.friendship"
                              [previousChatMessage]="chatMessagesByDay[index - 1]">
            </app-chat-message>
          </ng-container>
          <ng-container *ngIf="last && lastDay">
            <app-chat-message #lastMessage
                              (update)="update(activeContactMessages.friendship, chatMessage, $event)"
                              [chatMessage]="chatMessage"
                              [contact]="activeContactMessages.friendship"
                              [previousChatMessage]="chatMessagesByDay[index - 1]">
            </app-chat-message>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #selectContact>
  <div class="select-contact-info-wrapper">
    <div class="select-contact-info">
      {{__('pleaseSelectAContact')}}
    </div>
  </div>
</ng-template>

<ng-template #firstMessage>
  <div class="send-first-message">
    {{__('sendFirstMessage')}}
  </div>
</ng-template>
