import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ChatbotDocument} from '../state/chatbot-document';
import {ChatbotDocumentRepresentation} from '../state/chatbot-document-representation';
import {Response} from 'express';
import {NewChatbotDocument} from '../state/new-chatbot-document';
import {FileDownloadService} from '../../shared/file-download-service';
import {MediaType} from '../../shared/media-type';

const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotDocumentService {

  constructor(private http: HttpClient,
              private  fileDownloadService: FileDownloadService) {
  }

  public getDocumentList(): Observable<ChatbotDocument[]> {
    return this.http.get<ChatbotDocument[]>(
      API_URL + '/relevantdocuments/list',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotDocumentRepresentations: ChatbotDocumentRepresentation[]) =>
      chatbotDocumentRepresentations.map(chatbotDocumentRepresentation =>
        ChatbotDocument.buildFrom(chatbotDocumentRepresentation))
    ));
  }

  public getDocumentByID(id: number): Observable<ChatbotDocument> {
    return this.http.get<ChatbotDocument>(
      API_URL + '/relevantdocuments/get/' + id.toString(10),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(map((chatbotDocumentRepresentation: ChatbotDocumentRepresentation) =>
      ChatbotDocument.buildFrom(chatbotDocumentRepresentation)
    ));
  }

  // public getDocumentBySearchTitle(searchText: string): Observable<ChatbotDocument>{
  //   return this.http.get<ChatbotDocument>(
  //     API_URL + '/relevantdocuments/search/query/' + searchText,
  //     {
  //       headers: new HttpHeaders({
  //         Accept: 'application/json;'
  //       })
  //     }
  //   ).pipe(map((chatbotDocumentRepresentation: ChatbotDocumentRepresentation) =>
  //     ChatbotDocument.buildFrom(chatbotDocumentRepresentation)
  //   ));
  // }

  public addDocument(new_document: NewChatbotDocument): Observable<ChatbotDocument> {
    return this.http.post<ChatbotDocument>(
      API_URL + '/relevantdocuments/add',
      new_document
    ).pipe(map((chatbotDocumentRepresentation: ChatbotDocumentRepresentation) =>
      ChatbotDocument.buildFrom(chatbotDocumentRepresentation)
    ));
  }

  public deleteDocumentByID(id: number): Observable<number> {
    return this.http.delete<number>(
      API_URL + '/relevantdocuments/delete/' + id.toString(10),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    );
  }

  public updateDocument(document_id: number, document: NewChatbotDocument): Observable<ChatbotDocument> {
    return this.http.post<ChatbotDocument>(
      API_URL + '/relevantdocuments/update/?doc_id=' + document_id.toString(10),
      document
    ).pipe(map((chatbotDocumentRepresentation: ChatbotDocumentRepresentation) =>
      ChatbotDocument.buildFrom(chatbotDocumentRepresentation)
    ));
  }

  public importJson(formData: FormData): Observable<any>{
    return this.http.post<any>(API_URL + '/relevantdocuments/import_json', formData, {reportProgress: true});
  }

  public importExcel(formData: FormData): Observable<any>{
    return this.http.post<any>(API_URL + '/relevantdocuments/import_excel', formData, {reportProgress: true});
  }

  public exportJson(): void{
    this.fileDownloadService.downloadFile(
      API_URL + '/relevantdocuments/export_json',
      MediaType.JSON,
      'chatbot-documents.json'
    );
  }
}
