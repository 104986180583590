<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <div *ngIf="(loadingTeams$ | async); else loaded" class="centered">
      <app-loading-indicator></app-loading-indicator>
    </div>

    <ng-template #loaded>

      <ng-container *ngIf="hasTeams$ | async; else empty">

        <div *ngIf="loading" class="centered">
          <app-loading-indicator></app-loading-indicator>
        </div>

        <div class="top-placeholder"></div>


        <div class="team-container">
          <div class="sticky-header">
            <div class="title-container">
              <h2>{{__('headline')}}</h2>
              <div class="new-system" (click)="onCreateTeam()">
                <span class="id37-text4">{{__('new.team')}}</span>
                <img class="plus" src="assets/icons/ID37_X.svg" alt="plus sign">
              </div>
            </div>

            <app-team-navigation
                [teams]="teams"
                [selectedTeam]="selectedTeam"
                (editTeam)="onEditTeam($event)">
            </app-team-navigation>
          </div>

          <app-team-body
              [selectedTeam]="selectedTeam"
              [maxTestvariation]="maxTestvariation"
              [layout]="layout">
          </app-team-body>

        </div>


      </ng-container>


      <ng-template #empty>

        <app-loading-indicator *ngIf="hasTeams$ | async; else welcome"></app-loading-indicator>

        <ng-template #welcome>
          <h2 style="margin-top: 64px">{{__('headline')}}</h2>

          <div class="empty-list-container">
            <img src="/assets/icons/ID37_Team_Intro_220x160.svg" alt="pictogram of multiple skalas">

            <h4>{{__('greetings')}} {{this.firstName}} !</h4>

            <div class="id37-text3">{{__('introText1')}}</div>

            <button (click)="onCreateTeam()"
                    class="id37-btn-primary">
              {{__('button')}}</button>

            <div *featureAllowed="'recommend'" class="id37-text3">
              <span>{{__('introText2_1')}}</span>
              <a (click)="this.routeToFriends()">{{__('introText2_link_friends')}}</a>
              <span>{{__('introText2_2')}}</span>
              <a (click)="openRecommendations()">{{__('introText2_link_recommend')}}</a>
              <span>{{__('introText2_3')}}</span>
            </div>

          </div>
        </ng-template>

      </ng-template>

      <app-team-modal #teamModalComponent
                      (saved)="onCreatedTeam($event)"
                      (edited)="onEditedTeam($event)"
                      (deleted)="onDeleteTeam($event)">
      </app-team-modal>

    </ng-template>

    <app-user-invite-option #userInviteOptionComponent></app-user-invite-option>

  </div>
</div>
