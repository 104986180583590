import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../../test/state/motive-score';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {Friendship} from '../../../friend/state/friendship';

@Component({
  selector: 'app-moderate-filter',
  templateUrl: './moderate-filter.component.html',
  styleUrls: ['./moderate-filter.component.scss']
})
export class ModerateFilterComponent implements OnInit {

  @Input()
    distantMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    friendship: Friendship;

  @Input()
    friendFullName: string;

  @Input()
    friendGender: string;

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('moderateFilter.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
