import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {Router} from '@angular/router';
import {CurrentUser} from '../../../../user/current-user.service';
import {AccountDataQuery} from '../../../../account/account-data-query';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-account-created-message',
  templateUrl: './account-created-message.component.html',
  styleUrls: ['./account-created-message.component.scss']
})
export class AccountCreatedMessageComponent implements OnInit {

  @Input()
    notification: Notification;

  inviterName: string = '';
  messageInvitedBy: string = '';
  messagePartA: string = '';

  constructor(private currentUser: CurrentUser,
              private accountDataQuery: AccountDataQuery,
              private translatorService: TranslatorService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.computeMessage();
  }

  goToUserProfile(): void {
    this.router.navigate(['user-profile', this.notification.data.inviterId]);
  }

  computeMessage(): void {
    if (!!this.notification.data.inviterId) {
      this.accountDataQuery.selectAccountData(this.notification.data.inviterId as string)
        .pipe(take(1))
        .subscribe(inviterAccountData =>
          this.inviterName = (inviterAccountData.personalData.firstName + ' ' + inviterAccountData.personalData.lastName).trim()
        );
      this.messageInvitedBy = this.__('invitedBy');
    }
    this.messagePartA = this.__('partA');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
