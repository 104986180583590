<div class="clr-row clr-justify-content-center" style="margin-top: 100px">
  <div class="clr-col-lg-8 clr-col-md-10 clr-col-sm-12">
    <table class="table details-table">
      <tr>
        <th>Anzahl der Master</th>
        <td>{{adminReport?.masterCount}}</td>
      </tr>
      <tr>
        <th>Anzahl der Partners</th>
        <td>{{adminReport?.partnerCount}}</td>
      </tr>
      <tr>
        <th>Anzahl der Clients</th>
        <td>{{adminReport?.clientCount}}</td>
      </tr>
    </table>

    <table class="id37-table">
      <tr>
        <th class="left">Master</th>
        <th class="left">E-Mail</th>
        <th>Anzahl der Clients</th>
        <th>Anzahl abgeschlossener Tests</th>
        <th>Lizenz bestätigt am</th>
      </tr>
      <tr *ngFor="let masterData of adminReport?.masters">
        <td class="left">
          {{masterData.vorname}} {{masterData.name}}
        </td>
        <td class="left">
          {{masterData.email}}
        </td>
        <td>
          {{masterData.clientCount}}
        </td>
        <td>
          {{masterData.finishedTestsCount}}
        </td>
        <td>
          {{masterData.licenseConfirmationDate | date}}
        </td>
      </tr>

      <tr>
        <th class="left">Partner</th>
        <th class="left">E-Mail</th>
        <th>Anzahl der Clients</th>
        <th>Anzahl abgeschlossener Tests</th>
        <th>Lizenz bestätigt am</th>
      </tr>
      <tr *ngFor="let partnerData of adminReport?.partners">
        <td class="left">
          {{partnerData.vorname}} {{partnerData.name}}
        </td>
        <td class="left">
          {{partnerData.email}}
        </td>
        <td>
          {{partnerData.clientCount}}
        </td>
        <td>
          {{partnerData.finishedTestsCount}}
        </td>
        <td>
          {{partnerData.licenseConfirmationDate | date}}
        </td>
      </tr>
    </table>

  </div>
</div>

<div class="clr-row clr-justify-content-center" style="margin-top: 100px">
  <div class="clr-col-lg-8 clr-col-md-10 clr-col-sm-12">
    <table class="id37-table">
      <tr>
        <th class="left">Name</th>
        <th class="left">Test abgeschlossen am</th>
      </tr>
      <ng-container *ngFor="let report of billingReports$ | async">
        <tr>
          <td class="left masterHeadline">
            <ng-container *ngIf="report.masterName">
              {{report.masterVorname}} {{report.masterName}} | {{report.masterEmail}}
            </ng-container>
            <ng-container *ngIf="report.partnerName">
              {{report.partnerVorname}} {{report.partnerName}} | {{report.partnerEmail}}
            </ng-container>

          </td>
          <td class="left">
          </td>
        </tr>
        <ng-container *ngFor="let client of report.clients">
          <tr>
            <td class="left">
              {{client.clientFirstName}} {{client.clientLastName}} | {{client.clientEmail}}
            </td>
            <td class="left">
              {{client.finishedDateTime | date}}
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</div>
