import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DiscountCodeFormService} from '../discount-code-form.service';
import {NewDiscountCodeRepresentation} from '../../../order/discountcode/new-discount-code-representation';
import {take} from 'rxjs/operators';
import {DiscountCodeService} from '../../../order/discountcode/discount-code.service';

@Component({
  selector: 'app-discountcode-form',
  templateUrl: './discountcode-form.component.html',
  styleUrls: ['./discountcode-form.component.scss']
})
export class DiscountcodeFormComponent implements OnInit {
  @Input() edit: boolean;
  @Input() codeUsed: boolean;
  @Output() updateClickOnEdit = new EventEmitter<boolean>();
  @Output() refreshListonUpdate = new EventEmitter();

  constructor(
    private discountCodeFormService: DiscountCodeFormService,
    private discountCodeService: DiscountCodeService) {
  }

  ngOnInit() {
    this.edit = this.discountCodeFormService.edit;
  }

  onSubmit(): void {
    let newDiscountCode: NewDiscountCodeRepresentation;
    if (this.discountCodeFormService.form.valid) {
      newDiscountCode = {
        code: this.discountCodeFormService.form.value.code,
        discount: this.discountCodeFormService.form.value.discountAsNumber,
        active: this.discountCodeFormService.form.value.active,
        activeFrom: !!this.discountCodeFormService.form.value.activeFrom
          ? this.discountCodeFormService.form.value.activeFrom
          : null,
        activeUntil: !!this.discountCodeFormService.form.value.activeUntil
          ? this.discountCodeFormService.form.value.activeUntil
          : null,
        maximumUses: this.discountCodeFormService.form.value.maximumUses.length === 0
          ? null
          : this.discountCodeFormService.form.value.maximumUses,
        category: this.discountCodeFormService.form.value.category,
        description: this.discountCodeFormService.form.value.description,
        affiliateMail: !!this.discountCodeFormService.form.value.affiliateMail
          ? this.discountCodeFormService.form.value.affiliateMail : null
      };
    }
    if (this.discountCodeFormService.edit === false) {
      this.discountCodeService.addDiscountCode(newDiscountCode).pipe(take(1)).subscribe(
        () => this.refreshListonUpdate.emit()
      );
    }
    else {
      this.discountCodeService.updateDiscountCode(newDiscountCode).pipe(take(1)).subscribe(
        () => this.refreshListonUpdate.emit()
      );
    }
    this.discountCodeFormService.form.reset();
    this.onCancel();
  }

  onCancel() {
    this.discountCodeFormService.form.reset();
    this.discountCodeFormService.initializeFormGroup();
    this.updateClickOnEdit.emit(false);
  }

  getDiscountCodeFormService(): DiscountCodeFormService {
    return this.discountCodeFormService;
  }

}
