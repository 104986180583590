import {Component, Input} from '@angular/core';
import {MotiveScore} from '../../../../test/state/motive-score';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-grid]',
  templateUrl: './key-graphic-grid.component.html',
  styleUrls: ['./key-graphic-grid.component.scss']
})
export class KeyGraphicGridComponent {

  @Input()
    selectedMotiveScore: MotiveScore;
  linesArray: number[] = [];
  lineLength: number = 0;

  @Input()
  set lines(lines: number) {
    this.linesArray = Array(lines);
    this.lineLength = (lines + 1) * 80 + 20;
  }

  getLineOpacity(score: number) {
    return !this.selectedMotiveScore ? 1 : (score === this.selectedMotiveScore.score ? 1 : 0.5);
  }

}
