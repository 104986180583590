import {Component, Input} from '@angular/core';
import {ProfileIntroText} from '../types/profile-intro-text';

@Component({
  selector: 'app-profile-intro',
  templateUrl: './profile-intro.component.html',
  styleUrls: ['./profile-intro.component.scss']
})
export class ProfileIntroComponent {

  @Input()
    profileIntroText: ProfileIntroText;

  constructor() {
  }

}
