import {Choice, ChoicesGroup} from './choices-types';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChoicesService {

  find(value: string, groups: ChoicesGroup[]): Choice | undefined {
    for (const group of groups) {
      for (const choice of group.choices) {
        if (choice.value === value) {
          return choice;
        }
      }
    }
    return undefined;
  }

  cloneChoicesGroups(groups: ChoicesGroup[]): ChoicesGroup[] {
    const clonedGroups: ChoicesGroup[] = [];
    for (const group of groups) {
      clonedGroups.push({
        label: group.label,
        id: group.id,
        choices: this.cloneChoices(group.choices)
      });
    }
    return clonedGroups;
  }

  cloneChoices(choices: Choice[]): Choice[] {
    const clonedChoices: Choice[] = [];
    for (const choice of choices) {
      clonedChoices.push({
        value: choice.value,
        label: choice.label,
        selected: choice.selected
      });
    }
    return clonedChoices;
  }

  calculatePreviousChoice(groups: ChoicesGroup[], current: Choice | undefined): Choice {
    if (!current) {
      return groups[0].choices[0];
    }
    let selectNext = false;
    for (let g = groups.length - 1; g >= 0; g--) {
      const group = groups[g];
      for (let c = group.choices.length - 1; c >= 0; c--) {
        const choice = group.choices[c];
        if (selectNext) {
          return choice;
        }
        if (choice.value === current.value) {
          selectNext = true;
        }
      }
    }
    if (!selectNext) {
      console.error('Could not select previous choice. Currently selected was not found!');
      return undefined;
    }
    // Wrap around to last choice.
    const lastGroup = groups[groups.length - 1];
    return lastGroup.choices[lastGroup.choices.length - 1];
  }

  calculateNextChoice(groups: ChoicesGroup[], current: Choice | undefined): Choice {
    if (!current) {
      return groups[0].choices[0];
    }
    let selectNext = false;
    for (const group of groups) {
      for (const choice of group.choices) {
        if (selectNext) {
          return choice;
        }
        if (choice.value === current.value) {
          selectNext = true;
        }
      }
    }
    if (!selectNext) {
      console.error('Could not select next choice. Currently selected was not found!');
      return undefined;
    }
    // Wrap around to first choice.
    return groups[0].choices[0];
  }

}
