import {SimpleUserInvitation} from './simple-user-invitation';


export class SimpleUserInvitationRepresentation {

  public readonly email: string;


  constructor(email: string) {
    this.email = email;

  }

  static buildFrom(simpleUserInvitation: SimpleUserInvitation): SimpleUserInvitationRepresentation {
    return new SimpleUserInvitationRepresentation(simpleUserInvitation.email);
  }

}
