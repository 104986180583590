import {OrderRepresentation} from './order-representation';
import {OrderStatus} from './order-status';
import {UnzerPayment} from './payment/unzer-payment';
import {Invoice} from './invoice/invoice';
import {Position} from './position/position';
import {OrderAddress} from './address/order-address';
import {PositionsSummary} from './position/positions-summary';

export class Order {

  private readonly _orderId: string;
  private readonly _status: OrderStatus;
  private readonly _invoiceAddress: OrderAddress;
  private readonly _positions: Position[];
  private readonly _positionsSummary: PositionsSummary;
  private readonly _payments: UnzerPayment[];
  private readonly _invoice: Invoice;
  private readonly _creationDate: Date;
  private readonly _isRecurring: boolean;

  constructor(orderId: string,
              status: OrderStatus,
              invoiceAddress: OrderAddress,
              positions: Position[],
              positionsSummary: PositionsSummary,
              payments: UnzerPayment[],
              invoice: Invoice,
              creationDate: Date,
              isRecurring: boolean) {
    this._orderId = orderId;
    this._status = status;
    this._invoiceAddress = invoiceAddress;
    this._positions = positions;
    this._positionsSummary = positionsSummary;
    this._payments = payments;
    this._invoice = invoice;
    this._creationDate = creationDate;
    this._isRecurring = isRecurring;
  }

  get orderId(): string {
    return this._orderId;
  }

  get status(): OrderStatus {
    return this._status;
  }

  get invoiceAddress(): OrderAddress {
    return this._invoiceAddress;
  }

  get positions(): Position[] {
    return this._positions;
  }

  get positionsSummary(): PositionsSummary {
    return this._positionsSummary;
  }

  get payments(): UnzerPayment[] {
    return this._payments;
  }

  get invoice(): Invoice {
    return this._invoice;
  }

  get creationDate(): Date {
    return this._creationDate;
  }

  get isRecurring(): boolean {
    return this._isRecurring;
  }

  static copyOf(other: Order): Order {
    return new Order(
      other._orderId,
      other._status,
      other._invoiceAddress,
      other._positions,
      other._positionsSummary,
      other._payments,
      other._invoice,
      other._creationDate,
      other._isRecurring
    );
  }

  static buildFrom(orderRepresentation: OrderRepresentation) {
    return new Order(
      orderRepresentation.orderId,
      OrderStatus.fromName(orderRepresentation.status),
      OrderAddress.buildFrom(orderRepresentation.invoiceAddress),
      orderRepresentation.positions === null ? []
        : orderRepresentation.positions.map(representation => Position.buildFrom(representation)),
      PositionsSummary.buildFrom(orderRepresentation.positionsSummary),
      orderRepresentation.payments === null ? []
        : orderRepresentation.payments.map(representation => UnzerPayment.buildFrom(representation)),
      orderRepresentation.invoice === null ? null
        : Invoice.buildFrom(orderRepresentation.invoice),
      new Date(orderRepresentation.creationDate),
      orderRepresentation.isRecurring === null ? false : orderRepresentation.isRecurring
    );
  }

}
