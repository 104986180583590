<!-- Intro Questions -->
<h3> {{__('question.headline')}} </h3>

<div class="id37-text1 text-light questions">
  <div *ngFor="let q of questions">
    <span class="quotation-mark">„</span>{{q}}
  </div>
</div>

<!-- Intro text -->
<div class="id37-text2 text-light">
  <span [innerHTML]="__('intro.text_1')"></span>
  <a [innerHTML]="__('intro.distantLink')" (click)="this.calculateNormalizedResults('DISTANT');
      this.scrollService.scrollToElement('key-graphic-page-wrapper', 'middle')"></a>
  <span [innerHTML]="__('intro.text_2')"></span>
  <a [innerHTML]="__('intro.closeLink')" (click)="this.calculateNormalizedResults('CLOSE');
      this.scrollService.scrollToElement('key-graphic-page-wrapper', 'middle')"></a>
  <span [innerHTML]="__('intro.text_3')"></span>
  <a [innerHTML]="__('intro.moderateLink')" (click)="this.calculateNormalizedResults('MODERATE');
      this.scrollService.scrollToElement('key-graphic-page-wrapper', 'middle')"></a>
  <span [innerHTML]="__('intro.text_4')"></span>
</div>

<!-- Intro Filter text -->
<h3>{{__(variation + '.headline')}}</h3>
<div class="id37-text2 text-light" [innerHTML]="__(variation + '.text')"></div>

<!-- Filter buttons -->
<div class="filter-button-container">
  <ng-container *ngFor="let option of buttonFilterOptions">
    <button class="id37-btn-bw id37-btn-flat"
            [ngClass]="{'selected': variation === option.filter}"
            (click)="this.calculateNormalizedResults(option.filter)">
      {{option.displayName}}
    </button>
  </ng-container>
</div>


<ng-container *ngIf="numberOfMotivesView !== 0; else dummy">

  <!-- Distance graphic | id as anchor-->
  <div class="distance-graphic-wrapper" id="key-graphic-page-wrapper">
    <svg #svgWrapper width="100%" xmlns="http://www.w3.org/2000/svg" class="svg-wrapper">
      <svg app-distance-graphic
           [normalizedMotiveScoreTuple]="normalizedMotiveScoreTuple"
           [numberOfMotivesView]="numberOfMotivesView"
           [friendFirstname]="friendFirstname">
      </svg>
    </svg>
  </div>

  <!-- Filter texts -->
  <ng-container [ngSwitch]="variation">
    <app-distant-filter *ngSwitchCase="'DISTANT'"
                        [distantMotiveScoreTuple]="normalizedMotiveScoreTuple"
                        [friendship]="friendship"
                        [friendId]="friendId"
                        [friendFullName]="friendFullName"
                        [friendFirstname]="friendFirstname"
                        [friendGender]="friendGender"
                        [distance]="distance"
                        (clickedMaster)="expandCollapsibleMaster()">
    </app-distant-filter>
    <app-close-filter *ngSwitchCase="'CLOSE'"
                      [distantMotiveScoreTuple]="normalizedMotiveScoreTuple"
                      [friendship]="friendship"
                      [friendFullName]="friendFullName"
                      [friendGender]="friendGender">
    </app-close-filter>
    <app-moderate-filter *ngSwitchCase="'MODERATE'"
                         [distantMotiveScoreTuple]="normalizedMotiveScoreTuple"
                         [friendship]="friendship"
                         [friendFullName]="friendFullName"
                         [friendGender]="friendGender">
    </app-moderate-filter>
    <app-all-filter *ngSwitchCase="'ALL'"
                    [distantMotiveScoreTuple]="normalizedMotiveScoreTuple"
                    [friendship]="friendship"
                    [friendFullName]="friendFullName"
                    [friendGender]="friendGender"
                    (changeFilter)="changeFilter($event)">
    </app-all-filter>
  </ng-container>

</ng-container>


<ng-template #dummy>
  <div class="id37-text2 text-light big-space-bottom" [innerHTML]="__('empty.' + variation)"></div>
  <app-superlike *ngIf="variation === 'DISTANT'" [friendship]="friendship"
                 [friendId]="friendId"
                 [friendFullName]="friendFullName"
                 [friendFirstname]="friendFirstname"
                 [friendGender]="friendGender"
                 [distance]="distance"
                 (clickedMaster)="expandCollapsibleMaster()">
  </app-superlike>
</ng-template>

<!-- Master info -->
<app-consult-master [friendFullName]="friendFullName"
                    [friendGender]="friendGender">
</app-consult-master>

