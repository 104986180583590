import {NgModule} from '@angular/core';
import {NavComponent} from './nav.component';
import {BurgerIconComponent} from './burger-icon/burger-icon.component';
import {CloseIconComponent} from './close-icon/close-icon.component';
import {Id37Module} from '../id37/id37.module';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {RouterModule} from '@angular/router';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';
import {NotificationsModule} from '../notifications/notifications.module';
import {NotificationOverviewComponent} from './notification-overview/notification-overview.component';
import {ChatOverviewComponent} from './chat-overview/chat-overview.component';
import {DirectivesModule} from '../directives/directives.module';
import {UserModule} from '../user/user.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ClarityModule,
    Id37Module,
    ProfilePictureModule,
    NotificationsModule,
    DirectivesModule,
    UserModule
  ],
  declarations: [
    NavComponent,
    BurgerIconComponent,
    CloseIconComponent,
    NotificationOverviewComponent,
    ChatOverviewComponent
  ],
  exports: [
    NavComponent
  ]
})
export class NavModule {
}
