<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">Service Schnittstellen</h1>

    <app-collapsible-list [type]="'accordion'">

      <app-collapsible-item>
        <app-collapsible-header>
          Test-Benachrichtigung
        </app-collapsible-header>
        <app-collapsible-body>
          <p>
            Fügt diesem Account eine Test-Benachrichtigung hinzu.
          </p>
          <p>
            Benachrichtigs-Updates werden alle {{notificationRefreshTimeInSeconds}} Sekunden abgefragt.
            Es kann also bis zu {{notificationRefreshTimeInSeconds}} Sekunden dauern, bis die
            Benachrichtigung im
            Frontend registriert und im Benachrichtigungsbereich angezeigt wird.
          </p>
          <button (click)="triggerSystemTestNotification()"
                  [disabled]="triggerSystemTestNotificationOngoingRequest"
                  class="id37-btn">
            Test-Benachrichtigung hinzufügen
          </button>
          <span *ngIf="triggerSystemTestNotificationRequestSucceeded" class="ok">ERFOLGREICH</span>
          <span *ngIf="triggerSystemTestNotificationRequestFailed" class="failure">FEHLSCHLAG</span>
        </app-collapsible-body>
      </app-collapsible-item>

      <app-collapsible-item>
        <app-collapsible-header>
          Abgelaufene Tests
        </app-collapsible-header>
        <app-collapsible-body>
          <p>
            Überprüft alle im System vorhandenen Tests.
            Alle abgelaufenen Tests werden gelöscht und die User dieser Tests über das Ablaufen informiert.
          </p>
          <p>
            Dieser Prozess wird im Hintergrund alle 4 Stunden automatisch angestoßen.
          </p>
          <button (click)="triggerTestExpirationChecker()"
                  [disabled]="triggerTestExpirationCheckerOngoingRequest"
                  class="id37-btn">
            Prüfe auf abgelaufene Tests
          </button>
          <span *ngIf="triggerTestExpirationCheckerRequestSucceeded" class="ok">ERFOLGREICH</span>
          <span *ngIf="triggerTestExpirationCheckerRequestFailed" class="failure">FEHLSCHLAG</span>
        </app-collapsible-body>
      </app-collapsible-item>

      <app-collapsible-item>
        <app-collapsible-header>
          Keycloak-User-Cache
        </app-collapsible-header>
        <app-collapsible-body>
          <p>
            Alle Keycloak User werden in unserem Backend (im RAM) gecached, sodass User-Informationen nicht
            ständig per Request vom Keycloak-Service angefragt werden müssen.
          </p>
          <p>
            Dies setzt den Cache zurück: Alle Einträge werden gelöscht und der Cache wird neu befüllt,
            indem einmalig alle Keycloak User neu abgerufen werden.
            Dies führt dazu, dass Informationen zwangsläufig aktuell sind.
          </p>
          <p>
            Dieser Prozess besteht lediglich zur Sicherheit:
            Bei Systemgesteuerten Änderungen an einem Keycloak-User wird sichergestellt,
            dass der Cache-Eintrag für diesen User gelöscht wird.
            Er wird dann bei nächster Nutzung automatisch neu gecached.
          </p>
          <p>
            Der Cache-Eintrag eines Users wird ebenfalls gelöscht, wenn diser die Plattform betritt. Z.B.
            nach
            Login oder explizitem Neuladen der Seite über den Browser (Ladekreis wird angezeigt..).
          </p>
          <p>
            Dieser Prozess wird im Hintergrund alle 12 Stunden automatisch angestoßen.
          </p>
          <button (click)="triggerKeycloakUserCacheRefresher()"
                  [disabled]="triggerKeycloakUserCacheRefresherOngoingRequest"
                  class="id37-btn">
            Keycloak-User-Cache zurücksetzen
          </button>
          <span *ngIf="triggerKeycloakUserCacheRefresherRequestSucceeded" class="ok">ERFOLGREICH</span>
          <span *ngIf="triggerKeycloakUserCacheRefresherRequestFailed" class="failure">FEHLSCHLAG</span>
        </app-collapsible-body>
      </app-collapsible-item>

      <app-collapsible-item>
        <app-collapsible-header>
          Keycloak-Benutzername-Email-Gleichheit
        </app-collapsible-header>
        <app-collapsible-body>
          <p>
            Die ID37 Andwendung geht davon aus, mit Keycloak Usern zu arbeiten,
            deren Benutzername immer der Email-Adresse gleichgesetzt ist!
          </p>
          <p>
            Neu registrierte User, sowie von Mastern und Partnern eingeladene User,
            haben automatisch einen Benutzernamen gleich der eingegebenen Email-Adresse.
          </p>
          <p>
            Bei Aktualisieren der Email-Adresse über die MyAccount seite wird vom System sichergestellt,
            dass ebenfalls
            der Benutzername mit angepasst wird.
          </p>
          <p>
            Dieser Prozess dient lediglich der Sicherheit.
          </p>
          <p>
            Wird ein User gefunden, bei dem die Gleichheit nicht gegeben ist, so
            wird der Benutzername des Users auf den Wert der aktuell eingestellten Email-Adresse gesetzt.
          </p>
          <p>
            Dieser Prozess wird im Hintergrund alle 12 Stunden automatisch angestoßen.
          </p>
          <button (click)="triggerKeycloakUsernameAndEmailEqualityChecker()"
                  [disabled]="triggerKeycloakUsernameAndEmailEqualityCheckerOngoingRequest"
                  class="id37-btn">
            Benutzername und Email aller Keycloak User gleichsetzen
          </button>
          <span *ngIf="triggerKeycloakUsernameAndEmailEqualityCheckerRequestSucceeded"
                class="ok">ERFOLGREICH</span>
          <span *ngIf="triggerKeycloakUsernameAndEmailEqualityCheckerRequestFailed"
                class="failure">FEHLSCHLAG</span>
        </app-collapsible-body>
      </app-collapsible-item>
    </app-collapsible-list>
  </div>
</div>
