import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';
import {TranslatorService} from '../translation/translator.service';
import {Router} from '@angular/router';
import {CartService} from '../order/cart/cart-service';
import {Product} from '../product/product';
import {CartUpdate} from '../order/cart/cart-update';
import {Subject} from 'rxjs';
import {Test} from '../test/state/test';
import {TestQuery} from '../test/state/test.query';
import {takeUntil} from 'rxjs/operators';
import {
  faChartArea,
  faCogs,
  faDollarSign,
  faEnvelope,
  faMoneyBill,
  faProjectDiagram,
  faReceipt,
  faTable
} from '@fortawesome/free-solid-svg-icons';
import {UserInviteOptionComponent} from '../user/user-invite-option/user-invite-option.component';
import {PwaComponent} from '../pwa/pwa.component';
import {prices} from '../product/prices';
import {Referrer} from '../user/referrer';
import {TestService} from '../test/state/test.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('userInviteOptionComponent')
    userInviteOptionComponent: UserInviteOptionComponent;

  @ViewChild('pwaComponent')
    pwaComponent: PwaComponent;

  faCogs = faCogs;
  faEnvelope = faEnvelope;
  faReceipt = faReceipt;
  faChartArea = faChartArea;
  faDollarSign = faDollarSign;
  faMoneyBill = faMoneyBill;
  faTable = faTable;
  faProjectDiagram = faProjectDiagram;

  friendsAndFamilyDiscountInPercentage = prices.friends_and_family_discount_in_percentage;

  name: string;
  tests: Test[] | undefined = undefined;
  buyTestLoadingIndicator: boolean = false;
  hasReferrerWW: boolean;
  hasBot: boolean;

  private readonly documentsUrl = 'https://web.tresorit.com/l#ziNJ0alYOqwTbpiOvw0ypQ';
  private readonly videosUrl = 'https://vimeo.com/album/5805369';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private currentUser: CurrentUser,
              private cartService: CartService,
              private testService: TestService,
              private translatorService: TranslatorService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.name = this.currentUser.fullName;
    this.hasReferrerWW = this.currentUser.referrer === Referrer.WW;
    this.testQuery.selectTestsForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tests: Test[]) => {
        this.tests = tests;
      });
    this.hasBot = this.showBot();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buyTest(): void {
    this.buyTestLoadingIndicator = true;

    // Determine content of the cart based on the current user!
    let cartUpdates;
    if (this.currentUser.keycloakUser.referrer && this.currentUser.keycloakUser.referrer === Referrer.WW) {
      cartUpdates = [new CartUpdate(Product.ID37_TEST_WITH_COACHING, 1)];
    }
    else {
      cartUpdates = [new CartUpdate(Product.ID37_TEST, 1)];
    }

    this.cartService.replaceContent(cartUpdates as CartUpdate[]).subscribe(() => {
      this.buyTestLoadingIndicator = false;
      void this.router.navigate(['order']);
    }, () => {
      this.buyTestLoadingIndicator = false;
    });
  }

  showDocuments(): void {
    window.open(this.documentsUrl, '_blank');
  }

  showVideos(): void {
    window.open(this.videosUrl, '_blank');
  }

  openVimeoLink(): void {
    window.open('https://vimeo.com/showcase/5805384', '_blank');
  }

  openVertellisShop(): void {
    window.open('https://vertellis.de/collections/shop', '_blank');
  }

  doesUserComeFromPartnerOrIsPartner(): boolean {
    return this.currentUser.backendUser.partnerKeycloakId !== null
      || this.currentUser.hasRole('partner');
  }

  getPartnerUserId(): string {
    return this.currentUser.backendUser.partnerKeycloakId !== null
      ? this.currentUser.backendUser.partnerKeycloakId
      : this.currentUser.keycloakId;
  }

  openRecommendations(): void {
    this.userInviteOptionComponent.show();
  }

  showBot(): boolean {
    return this.currentUser.hasRole('administrator') ||
      (!!this.tests && this.tests[this.tests.length - 1].accessGranted);
  }

  __(key: string): string {
    return this.translatorService.translate('dashboard.' + key);
  }

}
