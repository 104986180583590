import {Notification} from './notification';
import {NotificationType} from './notification-type';

export class NotificationRepresentation {

  public readonly id: number;
  public readonly type: string;
  public readonly data: { [key: string]: any };
  public readonly read: boolean;
  public readonly createdAt: string;

  public static toModelEntity(notificationRepresentation: NotificationRepresentation): Notification {
    return {
      notificationId: notificationRepresentation.id,
      type: NotificationType.fromName(notificationRepresentation.type),
      data: notificationRepresentation.data,
      read: notificationRepresentation.read,
      createdAt: new Date(notificationRepresentation.createdAt)
    };
  }

}

