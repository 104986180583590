export class TestShareUpdateRepresentation {

  public readonly friendshipId: string;
  public readonly testShareId: string;
  public readonly variation: string;

  constructor(friendshipId: number, testShareId: string, variation: string) {
    this.friendshipId = String(friendshipId);
    this.testShareId = testShareId;
    this.variation = variation;
  }

}
