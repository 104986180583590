export enum OrderAction {

  FULFILLED = 'FULFILLED',
  INVOICE_SEND = 'INVOICE_SEND',
  CHARGES_CANCELED = 'CHARGES_CANCELED',
  PAYMENTS_REMOVED = 'PAYMENTS_REMOVED'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OrderAction {
  export const LIST: OrderAction[] = [
    OrderAction.FULFILLED, OrderAction.INVOICE_SEND, OrderAction.CHARGES_CANCELED, OrderAction.PAYMENTS_REMOVED
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): OrderAction | undefined => {
    name = !!name ? name : '';
    const found = OrderAction.LIST.find((action: string) => action.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access an order action with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

