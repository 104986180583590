import {UserProductPrice} from './user-product-price';

export class UserProductPriceRepresentations {

  public readonly userKeycloakId: string;
  public readonly product: string;
  public readonly netPrice: string;

  constructor(userKeycloakId: string, product: string, netPrice: string) {
    this.userKeycloakId = userKeycloakId;
    this.product = product;
    this.netPrice = String(netPrice).replace(',', '.');
  }

  static toModelEntity(userProductPriceRepresentation: UserProductPriceRepresentations): UserProductPrice {
    return {
      userKeycloakId: userProductPriceRepresentation.userKeycloakId
        ? userProductPriceRepresentation.userKeycloakId
        : undefined,
      product: userProductPriceRepresentation.product,
      netPrice: Number(userProductPriceRepresentation.netPrice
        .replace(',', '.')).toFixed(2)
    };
  }

}
