<div id="graphic-header">
  <h4 *ngIf="name.length !== 0">
    {{name}}
  </h4>
  <ng-content *ngIf="name.length === 0"></ng-content>

  <!-- This logo must not be clickable. -->
  <app-logo *ngIf="showLogo" [reactive]="false"></app-logo>
</div>

<div class="alert warning firefox-print-warning" *ngIf="browserService.isFirefox()">
  <div>
    {{__('firefoxPrintWarning')}}
    <a target="_blank" href="https://www.google.com/chrome/">Google Chrome</a>
  </div>
</div>

<div id="key-graphic-page-wrapper">
  <app-key-graphic-page [results]="results"></app-key-graphic-page>
</div>

<div id="graphic-footer-wrapper">
  <div id="graphic-footer">
    <a href="https://www.id37.io/" target="_blank">www.id37.io</a>
  </div>
</div>

<app-print-button [text]="__('printbutton')"
                  (buttonClicked)="issuePrintEvent()"></app-print-button>
