import {Component, Input} from '@angular/core';
import {GraphicGaussText} from '../types/graphic-gauss-text';
import {CurrentUser} from '../../user/current-user.service';

@Component({
  selector: 'app-graphic-gauss',
  templateUrl: './graphic-gauss.component.html',
  styleUrls: ['./graphic-gauss.component.scss']
})
export class GraphicGaussComponent {

  @Input()
    graphicGaussText: GraphicGaussText;

  locale: string;

  constructor(private currentUser: CurrentUser) {
    this.locale = currentUser.locale;
  }

}
