import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {TranslatorService} from '../../../translation/translator.service';

@Component({
  selector: 'app-chatbot-locked-screen',
  standalone: true,
  imports: [],
  templateUrl: './chatbot-locked-screen.component.html',
  styleUrl: './chatbot-locked-screen.component.scss'
})
export class ChatbotLockedScreenComponent implements OnInit{
  @Input()
    translatorKey: string;
  @Output()
    subscribeToChatbotJayEvent: EventEmitter<void> = new EventEmitter<void>(null);
  @Output()
    cancelSubscriptionEvent: EventEmitter<void> = new EventEmitter<void>(null);

  userName: string;

  constructor(private translatorService: TranslatorService,
              private keycloakService: KeycloakService) {
  }

  ngOnInit(): void {
    this.keycloakService.loadUserProfile()
      .then(profile => this.userName = profile?.firstName);
  }

  subscribeToChatbot() {
    this.subscribeToChatbotJayEvent.emit();
  }

  cancelSubscription() {
    this.cancelSubscriptionEvent.emit();
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-chat-locked.' + this.translatorKey + '.' + key);
  }
}
