import {Pipe, PipeTransform} from '@angular/core';
import {Moment} from 'moment';

@Pipe({
  name: 'MomentDateTime',
  pure: true
})
export class MomentDatetimePipe implements PipeTransform {

  constructor() {
  }

  transform(moment: Moment | undefined | null, args?: any): string {
    if (moment === null) {
      return '-';
    }
    if (!moment) {
      return '';
    }
    return moment.format('L LT');
  }

}
