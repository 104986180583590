<div class="chatbot-container chatbot-locked-container">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <div class="chatbot-startscreen">
      <div class="chatbot-welcome-frame">
        <div class="intro">
          <div class="avatar">
            <img src="assets/icons/chatbot/JayIconUnbordered.svg" class="icon-svg" alt="Jay Avatar Icon">
          </div>
          <div class="message">
            <h2>{{ __('header') }}</h2>
          </div>
          <div class="description">
            <!-- Text -->
            <h4> {{ __('hello') }} {{userName}}.</h4>
            <span>
                  <b>{{ __('subheader-cta') }}</b>
                </span>
            <span>
              {{ __('cta-description') }}
            </span>
          </div>
          <div class="btn-action-container">
            <button class="cancel-jay-chatbot-btn jay-chatbot-btn" (click)="cancelSubscription()">
              <span>{{ __('btn-cancel') }}</span>
            </button>
            <button class="subscribe-jay-chatbot-btn jay-chatbot-btn" (click)="subscribeToChatbot()">
              <span>{{ __('btn-subscribe') }}</span>
            </button>
          </div>
          <div class="chatbot-flatrate-information">
            <span innerHTML="{{ __('flatrate-information') }}"></span>
          </div>
          <div class="terms-and-conditions">
            <span innerHTML="{{ __('terms') }}"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
