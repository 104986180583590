<ng-container [ngSwitch]="layout">

  <ng-content></ng-content>

  <!-- DESKTOP version -->
  <ng-container *ngSwitchCase="'desktop'">
    <div *ngIf="show; else hide" class="motive-svg-wrapper">

      <svg width="100%" height="100%" viewBox="0 0 1105 88" fill="none" xmlns="http://www.w3.org/2000/svg">

        <g filter="url(#filter0_d)">
          <rect width="1105" height="88" rx="1" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="1105" height="88" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImaRgeFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="10" y="32" height="24" width="24"></image>

        <ng-container *ngIf="!isLinebreak; else linebreak">
          <text x="38" y="45" class="motive-text">{{motiveName}}</text>
        </ng-container>

        <ng-template #linebreak>
          <text x="30" y="21" class="motive-text">
            <tspan x="38" dy="1.2em">{{linebreakText[0]}}</tspan>
            <tspan x="38" dy="1.2em">{{linebreakText[1]}}</tspan>
          </text>
        </ng-template>

        <path d="M140 45H1057" stroke="#E7E7E7"/>
        <path d="M140 18L140 72" stroke="#E7E7E7"/>
        <path d="M1057 18L1057 72" stroke="#E7E7E7"/>
        <path d="M782 18L782 72" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M416 18L416 72" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M965 34L965 56" stroke="#E7E7E7"/>
        <path d="M691 34L691 56" stroke="#E7E7E7"/>
        <path d="M874 34L874 56" stroke="#E7E7E7"/>
        <path d="M599 34L599 56" stroke="#E7E7E7"/>
        <path d="M324 34L324 56" stroke="#E7E7E7"/>
        <path d="M507 34L507 56" stroke="#E7E7E7"/>
        <path d="M233 34L233 56" stroke="#E7E7E7"/>

      </svg>


      <ng-container *ngFor="let b of displayingBubbleScores">
        <app-team-graphic-bubble *ngIf="!b.hide"
                                 [bubbleScore]="b"
                                 [amount]="this.numberOfTeamMembers"
                                 [layout]="layout"
                                 [x]="computeBubblePositionX(b, layout)"
                                 [y]="computeBubblePositionY(b, layout)"></app-team-graphic-bubble>
      </ng-container>


      <ng-container *ngFor="let h of hiddenBubbleScores">
        <app-team-graphic-bubble
          [hiddenBubbleScores]="h"
          [layout]="layout"
          [amount]="this.numberOfTeamMembers"
          [x]="computeHiddenBubblePositionX(h, layout)"
          [y]="computeHiddenBubblePositionY(layout)"></app-team-graphic-bubble>
      </ng-container>


      <div class="eye" style="top: 35%; right: 1%; width: 2.7%; height: 30%;"
           [ngClass]="{'show': show}" (mousedown)="onEye()" [attr.aria-describedby]="__('tooltip.fade-out')">
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
      </div>

    </div>

    <!-- fade in/out -->
    <ng-template #hide>
      <svg width="100%" height="100%" viewBox="0 0 1105 50" fill="none">

        <g filter="url(#filter0_d)">
          <rect width="1105" height="50" rx="1" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="1105" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImaRgeFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="10" y="13"></image>

        <ng-container *ngIf="!isLinebreak; else linebreak">
          <text x="38" y="25.5" class="motive-text fade-text">{{motiveName}}</text>
        </ng-container>

        <ng-template #linebreak>
          <text x="30" y="2" class="motive-text fade-text">
            <tspan x="38" dy="1.2em">{{linebreakText[0]}}</tspan>
            <tspan x="38" dy="1.2em">{{linebreakText[1]}}</tspan>
          </text>
        </ng-template>

        <path d="M140 25H1057" stroke="#E7E7E7"/>


      </svg>

      <div class="eye" style="top: 25%; right: 1%; width: 2.7%; height: 50%" (mousedown)="onEye()"
           [attr.aria-describedby]="__('tooltip.fade-in')">
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
      </div>
    </ng-template>
  </ng-container>


  <!-- TABLET version -->
  <ng-container *ngSwitchCase="'tablet'">
    <div *ngIf="show; else hide" class="motive-svg-wrapper">

      <svg width="100%" height="100%" viewBox="0 0 704 115" fill="none" xmlns="http://www.w3.org/2000/svg">

        <g filter="url(#filter0_d)">
          <rect width="704" height="115" rx="1" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="704" height="115" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="12" y="12"></image>

        <text x="42" y="25" class="motive-text">{{motiveName}}</text>

        <path d="M17 73L687 73.0001" stroke="#E7E7E7"/>
        <path d="M17 46L17 100" stroke="#E7E7E7"/>
        <path d="M487 46L487 100" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M215 46L215 100" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M623 62L623 84" stroke="#E7E7E7"/>
        <path d="M419 62L419 84" stroke="#E7E7E7"/>
        <path d="M555 62L555 84" stroke="#E7E7E7"/>
        <path d="M351 62L351 84" stroke="#E7E7E7"/>
        <path d="M147 62L147 84" stroke="#E7E7E7"/>
        <path d="M283 62L283 84" stroke="#E7E7E7"/>
        <path d="M79 62L79 84" stroke="#E7E7E7"/>
        <path d="M687 46L687 100" stroke="#E7E7E7"/>

      </svg>

      <ng-container *ngFor="let b of displayingBubbleScores">
        <app-team-graphic-bubble *ngIf="!b.hide"
                                 [bubbleScore]="b"
                                 [layout]="layout"
                                 [amount]="this.numberOfTeamMembers"
                                 [x]="computeBubblePositionX(b, layout)"
                                 [y]="computeBubblePositionY(b, layout)"></app-team-graphic-bubble>
      </ng-container>

      <ng-container *ngFor="let h of hiddenBubbleScores">
        <app-team-graphic-bubble
          [hiddenBubbleScores]="h"
          [layout]="layout"
          [amount]="this.numberOfTeamMembers"
          [x]="computeHiddenBubblePositionX(h, layout)"
          [y]="computeHiddenBubblePositionY(layout)"></app-team-graphic-bubble>
      </ng-container>

      <div class="eye" style="top: 2%; right: 1.5%; width: 4%; height: 30%"
           [ngClass]="{'show': show}" (mousedown)="onEye()" [attr.aria-describedby]="__('tooltip.fade-out')">
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
      </div>

    </div>

    <!-- fade in/out -->
    <ng-template #hide>
      <svg width="100%" height="100%" viewBox="0 14 704 60" fill="none">

        <g filter="url(#filter0_d)">
          <rect width="704" height="60" rx="1" y="14" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="704" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="12" y="15"></image>
        <text x="42" y="28" class="motive-text fade-text">{{motiveName}}</text>
        <path d="M17 53L687 53.0001" stroke="#E7E7E7"/>
      </svg>

      <div class="eye" style="top: 2%; right: 1.5%; width: 4%; height: 45%"
           (mousedown)="onEye()" [attr.aria-describedby]="__('tooltip.fade-in')">
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
      </div>
    </ng-template>

  </ng-container>


  <!-- MOBILE version -->
  <ng-container *ngSwitchCase="'mobile'">
    <div *ngIf="show; else hide" class="motive-svg-wrapper">
      <svg viewBox="0 0 358 156" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">

        <g filter="url(#filter0_d)">
          <rect width="358" height="156" rx="1" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="358" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="12" y="12"></image>

        <text x="42" y="25" class="motive-text">{{motiveName}}</text>

        <path d="M15 88L340 88" stroke="#E7E7E7"/>
        <path d="M15 46L15 130" stroke="#E7E7E7"/>
        <path d="M242.985 46L242.985 130" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M111.045 46L111.045 130" stroke="#F9824E" stroke-dasharray="3 3"/>
        <path d="M308.955 70.8889L308.955 105.111" stroke="#E7E7E7"/>
        <path d="M210 70.8889L210 105.111" stroke="#E7E7E7"/>
        <path d="M275.97 70.8889L275.97 105.111" stroke="#E7E7E7"/>
        <path d="M177.015 70.8889L177.015 105.111" stroke="#E7E7E7"/>
        <path d="M78.0597 70.8889L78.0597 105.111" stroke="#E7E7E7"/>
        <path d="M144.03 70.8889L144.03 105.111" stroke="#E7E7E7"/>
        <path d="M45.0746 70.8889L45.0746 105.111" stroke="#E7E7E7"/>
        <path d="M340 46L340 130" stroke="#E7E7E7"/>

      </svg>

      <ng-container *ngFor="let b of displayingBubbleScores">
        <app-team-graphic-bubble *ngIf="!b.hide"
                                 [bubbleScore]="b"
                                 [layout]="layout"
                                 [amount]="this.numberOfTeamMembers"
                                 [x]="computeBubblePositionX(b, layout)"
                                 [y]="computeBubblePositionY(b, layout)"></app-team-graphic-bubble>
      </ng-container>

      <ng-container *ngFor="let h of hiddenBubbleScores">
        <app-team-graphic-bubble
          [hiddenBubbleScores]="h"
          [layout]="layout"
          [amount]="this.numberOfTeamMembers"
          [x]="computeHiddenBubblePositionX(h, layout)"
          [y]="computeHiddenBubblePositionY(layout)"></app-team-graphic-bubble>
      </ng-container>

      <div class="eye" style="top: 8%; right: 2%"
           [ngClass]="{'show': show}" (mousedown)="onEye()" [attr.aria-describedby]="__('tooltip.fade-out')">
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
      </div>
    </div>

    <!-- fade in/out -->
    <ng-template #hide>
      <svg *ngIf="!show" width="100%" height="100%" viewBox="0 13 358 50" fill="none">

        <g filter="url(#filter0_d)">
          <rect width="358" height="50" y="13" rx="1" fill="white"/>
        </g>

        <defs>
          <filter id="filter0_d" x="0" y="0" width="358" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>

        <image xlink:href="/assets/icons/move.svg" x="12" y="15"></image>
        <text x="42" y="28" class="motive-text fade-text">{{ motiveName }}</text>
        <path d="M17 53L341 53.0001" stroke="#E7E7E7"/>
      </svg>

      <div class="eye" style="top: 12%; right: 2%;"
           (mousedown)="onEye()" [attr.aria-describedby]="__('tooltip.fade-in')">
        <img class="eye-closed" src="/assets/icons/eye_inactive_24x24.svg"/>
        <img class="eye-opened" src="/assets/icons/eye_active_24x24.svg"/>
      </div>
    </ng-template>

  </ng-container>
</ng-container>
