<h3>{{__('exercise.headline')}}</h3>
<app-collapsible-list>
  <app-collapsible-item class="friends-collapsible-item">
    <app-collapsible-header [title]="__('exercise.experiences.headline')"></app-collapsible-header>
    <app-collapsible-body>
      <div class="id37-text2 text-light">
        <span [innerHTML]="__('exercise.experiences.text')"></span>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>
