import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ReportStore} from './report-store';
import {Report} from './types/report';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient,
              private reportStore: ReportStore) {
  }

  public loadReport(userId: string, testId: string): Observable<Report> {
    return this.http.get<Report>(
      API_URL + '/reports/' + userId + '/' + testId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      tap((report: Report) => {
        this.storeReport(testId, report, userId);
      })
    );
  }

  private storeReport(testId: string, report: Report, userId: string): void {
    this.reportStore.upsert([userId, testId].join(':'), report);
  }

}
