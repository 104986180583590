export class TestShareCreateRepresentation {

  public readonly friendshipId: string;
  public readonly testId: string;
  public readonly variation: string;

  constructor(friendshipId: number, testId: string, variation: string) {
    this.friendshipId = String(friendshipId);
    this.testId = testId;
    this.variation = variation;
  }

}
