import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-unread-info',
  templateUrl: './unread-info.component.html',
  styleUrls: ['./unread-info.component.scss']
})
export class UnreadInfoComponent {

  @Input()
    forceDisplay: boolean | undefined = undefined;

  @Input()
    displayIfAmountGreaterZero: boolean = true;

  @Input()
    amount: number = 0;

  // Styling!

  @Input()
    emSize: number = 1.0;

  @Input()
    emTop: number = 0;

  @Input()
    emRight: number = 0;

}
