<div class="notification" [ngClass]="{'unread': !notification.read}">

  <div class="notification-content-column">
    <div class="notification-message-row">
      <div class="notification-title"
           (click)="toggleReadState()">
        <ng-container [ngSwitch]="notification.type">
          <app-raw-title *ngSwitchCase="notificationType.RAW"
                         [notification]="notification"></app-raw-title>
          <app-localized-raw-title *ngSwitchCase="notificationType.LOCALIZED_RAW"
                                   [notification]="notification"></app-localized-raw-title>
          <ng-container *ngSwitchDefault>
            {{title}}
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="notification-message-row">
      <div class="notification-message">
        <ng-container [ngSwitch]="notification.type">
          <app-account-created-message *ngSwitchCase="notificationType.ACCOUNT_CREATED"
                                       [notification]="notification"></app-account-created-message>
          <app-test-invitation-message *ngSwitchCase="notificationType.TEST_INVITATION"
                                       [notification]="notification"></app-test-invitation-message>
          <app-test-revoked-message *ngSwitchCase="notificationType.TEST_REVOKED"
                                    [notification]="notification"></app-test-revoked-message>
          <app-test-finished-message *ngSwitchCase="notificationType.TEST_FINISHED"
                                     [notification]="notification"></app-test-finished-message>
          <app-test-access-granted-message *ngSwitchCase="notificationType.TEST_ACCESS_GRANTED"
                                           [notification]="notification"></app-test-access-granted-message>
          <app-test-expiring-master-message *ngSwitchCase="notificationType.TEST_EXPIRING_MASTER"
                                            [notification]="notification"></app-test-expiring-master-message>
          <app-test-expiring-client-message *ngSwitchCase="notificationType.TEST_EXPIRING_CLIENT"
                                            [notification]="notification"></app-test-expiring-client-message>
          <app-test-expired-of-client-message *ngSwitchCase="notificationType.TEST_EXPIRED_OF_CLIENT"
                                              [notification]="notification"></app-test-expired-of-client-message>
          <app-friendship-invitation-message *ngSwitchCase="notificationType.FRIENDSHIP_INVITATION"
                                             [notification]="notification"></app-friendship-invitation-message>
          <app-friendship-accepted-message *ngSwitchCase="notificationType.FRIENDSHIP_ACCEPTED"
                                           [notification]="notification"></app-friendship-accepted-message>
          <app-raw-message *ngSwitchCase="notificationType.RAW"
                           [notification]="notification"></app-raw-message>
          <app-localized-raw-message *ngSwitchCase="notificationType.LOCALIZED_RAW"
                                     [notification]="notification"></app-localized-raw-message>
          <app-superlike-message *ngSwitchCase="notificationType.SUPERLIKE_SENT"
                                 [notification]="notification"></app-superlike-message>
          <app-motives-changed-message *ngSwitchCase="notificationType.TESTSHARE_CHANGED"
                                       [notification]="notification"></app-motives-changed-message>
          <ng-container *ngSwitchDefault>
            {{message}}
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="notification-additional-data-row">
      <div class="notification-timestamp">
        {{notification.createdAt | JsDateTime}}
      </div>
    </div>
  </div>

  <div class="notification-actions-column">
    <div class="delete-action">
      <img (click)="delete()" src="assets/icons/ID37_X.svg" alt="close sign">
    </div>
    <div class="notification-read-state"
         [ngClass]="{'was-read': notification.read}"
         (click)="toggleReadState()">
      <div class="outer-read-state-visualisation">
        <div class="inner-read-state-visualisation"></div>
      </div>
    </div>
  </div>

</div>
