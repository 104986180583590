<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 class="order-headline" id="title">{{ __('orders') }}</h1>

    <app-orders-testing
      [canShowTestJayComponent]="canShowTestJayComponent()"></app-orders-testing>

    <app-orders-subscriptions
      *ngIf="chatbotUserStatus && orders"
      [orders]="orders"
      [chatbotUserStatus]="chatbotUserStatus"></app-orders-subscriptions>

    <app-orders-error
      *ngIf="chatbotUserStatus == 'CHATBOT_ERROR'"></app-orders-error>

    <app-orders-invoice
      *ngIf="chatbotUserStatus && orders"
      [orders]="orders"
      [chatbotUserStatus]="chatbotUserStatus"></app-orders-invoice>


  </div>
</div>
