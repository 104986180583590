import {ChatbotChat} from '../state/chatbot-chat';
import {Test} from '../../test/state/test';
import {ChatbotChatUser} from '../state/chatbot-chat-user';
import {Motive} from '../../report/types/motives/motive';
import {CurrentUser} from '../../user/current-user.service';
import {TestService} from '../../test/state/test.service';
import {Injectable} from '@angular/core';
import {ChatbotUserService} from '../api-services/chatbot-user.service';


@Injectable({
  providedIn: 'root'
})
export class ChatbotUserCreateService{

  constructor(private currentUser: CurrentUser,
              private chatbotUserService: ChatbotUserService,
              private testService: TestService) {
  }
  async createUser(): Promise<ChatbotChatUser> {
    console.log('createUser() in chat-create SERVICE');

    let tests: Test[];
    await this.testService.loadTests(this.currentUser.keycloakId).toPromise().then((data) => {
      tests = data;
    });


    const motiveScores = tests.pop().result.motiveScores;

    // missing scores for 14/15 Motive-Tests set to 0
    let famScore = 0;
    let sinScore = 0;

    if (motiveScores.size === 15){
      famScore = motiveScores.get(Motive.FAM).score;
    }
    else if (motiveScores.size === 16){
      famScore = motiveScores.get(Motive.FAM).score;
      sinScore = motiveScores.get(Motive.SIN).score;
    }

    const chatbotchatuser = new ChatbotChatUser(
      this.currentUser.keycloakId,
      motiveScores.get(Motive.NEU).score,
      motiveScores.get(Motive.SAN).score,
      motiveScores.get(Motive.EIN).score,
      motiveScores.get(Motive.STA).score,
      motiveScores.get(Motive.BES).score,
      motiveScores.get(Motive.AUT).score,
      motiveScores.get(Motive.SOZ).score,
      motiveScores.get(Motive.PRI).score,
      motiveScores.get(Motive.SEN).score,
      motiveScores.get(Motive.STR).score,
      motiveScores.get(Motive.SIC).score,
      motiveScores.get(Motive.REV).score,
      motiveScores.get(Motive.BEW).score,
      motiveScores.get(Motive.ESS).score,
      famScore,
      sinScore);


    let chatbotChatUser: ChatbotChatUser;
    try {
      chatbotChatUser = await this.chatbotUserService.createChatbotUser(chatbotchatuser).toPromise();
      console.log('ChatbotChatUser created: ', chatbotChatUser);
    }
    catch (error) {
      console.error('Error at createChatbotUser', error);
    }
    return chatbotChatUser;


  }
}
