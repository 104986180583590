import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Notification} from '../../../state/notification';
import {FriendshipQuery} from '../../../../friend/state/friendship.query';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Friendship} from '../../../../friend/state/friendship';
import {FriendshipFunctions} from '../../../../friend/state/friendship-functions';
import {CurrentUser} from '../../../../user/current-user.service';
import {Id37Router} from '../../../../id37-router';
import {TranslatorService} from '../../../../translation/translator.service';

@Component({
  selector: 'app-superlike-message',
  templateUrl: './superlike-message.component.html',
  styleUrls: ['./superlike-message.component.scss']
})
export class SuperlikeMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  private unsubscribe$: Subject<void> = new Subject<void>();

  customText: string = '';
  friendship: Friendship;
  friendName: string = '...';

  constructor(private currentUser: CurrentUser,
              private friendshipQuery: FriendshipQuery,
              private router: Id37Router,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.customText = this.notification.data.customText;
    this.friendshipQuery.selectApprovedFriendshipWith(this.notification.data.senderID as string)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((friendship: Friendship | undefined) => {
        if (!!friendship) {
          this.friendship = friendship;
          this.friendName = FriendshipFunctions.getFriendFullName(friendship, this.currentUser.keycloakId);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToFriend(): void {
    this.router.routeToFriends(this.notification.data.friendshipId as number);
  }

  __(key: string): string {
    return this.translatorService.translate('notification.SUPERLIKE_SENT.' + key);
  }

}
