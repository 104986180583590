<id37-modal [(modalOpen)]="open">
  <id37-modal-title></id37-modal-title>
  <id37-modal-body>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-xl-8 clr-col-lg-8 clr-col-md-8 clr-col-sm-9">

        <h3 *ngIf="isCurrentUserFriendA" class="text-centered">{{__('request.title')}}</h3>
        <h3 *ngIf="!isCurrentUserFriendA" class="text-centered">{{__('accept.title')}}</h3>

        <div class="id37-text3 text-centered">
          {{__('textA') + friend?.firstName + ' ' + friend?.lastName + __('textB')}}
        </div>

        <div [formGroup]="this.form">

          <div class="radio-buttons-container">
            <ng-container *ngIf="!ongoingRequest; else loading">

              <label *ngIf="userTestVariation === 'ID37_16'" class="id37-radio-label id37-text3" for="16">
                <input formControlName="testVariation" id="16" name="testVariation" type="radio"
                       value="ID37_16">
                <span class="id37-radio-button"></span>
                <span>{{__('16')}}</span>
              </label>

              <label class="id37-radio-label id37-text3" for="15">
                <input formControlName="testVariation" id="15" name="testVariation" type="radio"
                       value="ID37_15">
                <span class="id37-radio-button"></span>
                <span>{{__('15')}}</span>
              </label>

              <label class="id37-radio-label id37-text3" for="14">
                <input formControlName="testVariation" id="14" name="testVariation" type="radio"
                       value="ID37_14">
                <span class="id37-radio-button"></span>
                <span>{{__('14')}}</span>
              </label>

            </ng-container>

            <ng-template #loading>
              <app-loading-indicator></app-loading-indicator>
            </ng-template>
          </div>

          <button (click)="processForm()" [disabled]="ongoingRequest"
                  class="id37-btn-primary id37-btn-full-width"
                  type="button">
            {{__('shareNow')}}
          </button>

        </div>

      </div>
    </div>
  </id37-modal-body>
</id37-modal>
