import {BasicUser} from './basic-user';

export class BasicUserRepresentation {

  public readonly keycloakId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly groupName: string;

  static toModelEntity(basicUserRepresentation: BasicUserRepresentation): BasicUser {
    return {
      keycloakId: basicUserRepresentation.keycloakId,
      firstName: basicUserRepresentation.firstName,
      lastName: basicUserRepresentation.lastName,
      email: basicUserRepresentation.email,
      groupName: basicUserRepresentation.groupName
    };
  }

}
