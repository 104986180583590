<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">Metrics</h1>

    <ng-container *ngIf="metrics$ | async as metrics">
      <div class="id37-text2 text-light metrics">
        <ng-container *ngFor="let line of metrics.split('\n') as lines">
          <ng-container *ngIf="line.trim().startsWith('#'); else noComment">
            <div class="entry comment">
              {{line.trim()}}
            </div>
          </ng-container>
          <ng-template #noComment>
            <div class="entry">
              {{line.trim()}}
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>

  </div>
</div>
