import {Friendship, FriendshipStatus} from './friendship';
import {FriendshipRepresentation} from './friendship-representation';
import {Friend} from './friend';
import {TestResultDistance} from '../../test/state/test-result-distance';
import {TestShare} from '../../test/state/test-share';
import {Test} from '../../test/state/test';

export type ShareStatus = 'noTest' | 'unfinishedTest' | 'grantTest' | 'motiveSelection' | 'done';

export class FriendshipFunctions {

  static getFriend(friendship: Friendship, currentUserId: string): Friend {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? friendship.userB
      : friendship.userA;
  }

  static getFriendUserId(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? friendship.userB.id
      : friendship.userA.id;
  }

  static getFriendEmail(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return (currentUserId === friendship.userA.id)
      ? friendship.userB.email.trim()
      : friendship.userA.email.trim();
  }

  static getFriendFullName(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? (friendship.userB.firstName + ' ' + friendship.userB.lastName).trim()
      : (friendship.userA.firstName + ' ' + friendship.userA.lastName).trim();
  }

  static getFriendTestId(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? friendship.userB.testShares[0].testId
      : friendship.userA.testShares[0].testId;
  }

  static getFriendTestName(friendship: Friendship, userId: string): string {
    if (!userId) {
      console.error('undefined');
    }
    return userId === friendship.userA.id
      ? friendship.userB.testShares[0].variation.internalName
      : friendship.userA.testShares[0].variation.internalName;
  }

  static getMyFullName(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? (friendship.userA.firstName + ' ' + friendship.userA.lastName).trim()
      : (friendship.userB.firstName + ' ' + friendship.userB.lastName).trim();
  }

  static getMyTestName(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? friendship.userA.testShares[0].variation.internalName
      : friendship.userB.testShares[0].variation.internalName;
  }

  static getMyTestShare(friendship: Friendship, currentUserId: string): TestShare {
    return currentUserId === friendship.userA.id
      ? friendship.userA.testShares[0]
      : friendship.userB.testShares[0];
  }

  static getFriendTestShare(friendship: Friendship, currentUserId: string): TestShare {
    return currentUserId === friendship.userB.id
      ? friendship.userA.testShares[0]
      : friendship.userB.testShares[0];
  }

  static hasTestShare(friendship: Friendship, currentUserId: string): boolean {
    return currentUserId === friendship.userA.id
      ? Boolean(friendship.userA.testShares.length)
      : Boolean(friendship.userB.testShares.length);
  }

  static getMyTestId(friendship: Friendship, currentUserId: string): string {
    if (!currentUserId) {
      console.error('undefined');
    }
    return currentUserId === friendship.userA.id
      ? friendship.userA.testShares[0].testId
      : friendship.userB.testShares[0].testId;
  }

  static computeFriendshipStatusFromRepresentation(friendship: FriendshipRepresentation,
                                                   currentUserId: string): FriendshipStatus {
    if (!currentUserId) {
      console.error('undefined');
    }
    if (friendship.inviteApproved) {
      return 'friends';
    }
    if (currentUserId === friendship.userA.keycloakId) {
      return 'requested';
    }
    return 'pending';
  }

  static computeFriendshipStatus(friendship: Friendship, currentUserId: string): FriendshipStatus {
    if (!currentUserId) {
      console.error('undefined');
    }
    if (friendship.inviteApproved) {
      return 'friends';
    }
    if (currentUserId === friendship.userA.id) {
      return 'requested';
    }
    return 'pending';
  }

  static computeFriendHasTest(friendship: Friendship, userId: string): boolean {
    if (!userId) {
      console.error('undefined');
    }
    return (userId === friendship.userA.id)
      ? Boolean(friendship.userA.testShares.length)
      : Boolean(friendship.userB.testShares.length);
  }

  static getDistanceBetweenNewestTestShares(friendship: Friendship): TestResultDistance | undefined {
    if (friendship.distances.length === 0) {
      return undefined;
    }
    const latestFromUserA: TestShare | undefined = friendship.userA.testShares.length > 0
      ? friendship.userA.testShares[friendship.userA.testShares.length - 1]
      : undefined;

    const latestFromUserB: TestShare | undefined = friendship.userB.testShares.length > 0 ?
      friendship.userB.testShares[friendship.userB.testShares.length - 1]
      : undefined;

    if (!(!!latestFromUserA && !!latestFromUserB)) {
      return undefined;
    }
    return friendship.distances.find((distance) =>
      distance.testIdA === latestFromUserA.testId
      && distance.testIdB === latestFromUserB.testId);
  }

  static getShareStatus(friendship: Friendship, currentUserId: string, test: Test[]): ShareStatus {
    if (!currentUserId) {
      console.error('undefined');
    }
    const currentUser: Friend = currentUserId === friendship.userA.id ? friendship.userA : friendship.userB;

    if (test.length === 0) {
      return 'noTest';
    }
    if (currentUser.testShares.length === 0) {
      if (!test[0].finished && currentUser.testShares.length === 0) {
        return 'unfinishedTest';
      }
      if (test[0].finished && !test[0].accessGranted) {
        return 'grantTest';
      }
      if (test[0].finished) {
        return 'motiveSelection';
      }
    }
    if (currentUser.testShares.length) {
      return 'done';
    }
    throw new Error('Unknown ShareStatus');
  }

}
