<h3 [innerHTML]="__('motive.headline')"></h3>

<div class="motive-wrapper id37-text2 text-light">
  <div class="motive-text" *ngFor="let element of motiveTextHtml" [innerHTML]="element"></div>
</div>


<app-superlike [friendship]="friendship"
               [friendId]="friendId"
               [friendFullName]="friendFullName"
               [friendFirstname]="friendFirstname"
               [friendGender]="friendGender"
               [distance]="distance"
               (clickedMaster)="clickMaster()">
</app-superlike>

<!-- Impulse -->
<h3>{{__('impulses.headline')}}</h3>
<div class="id37-text2 text-light">
  <div class="headline-space-bottom" [innerHTML]="__('impulses.text1')"></div>

  <h4>
    <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
    <span [innerHTML]="__('impulses.listItem.title')"></span>
  </h4>
  <ul class="bold-items headline-space-bottom">
    <li>{{__('impulses.listItem1')}}</li>
    <li>{{__('impulses.listItem2')}}</li>
    <li>{{__('impulses.listItem3')}}</li>
    <li>{{__('impulses.listItem4')}}</li>
    <li>{{__('impulses.listItem5')}}</li>
    <li>
      {{__('impulses.listItem6_1')}}
      <a (click)="this.scrollService.scrollToElement('key-graphic-page-wrapper', 'middle')">
        {{__('impulses.listItem6_2')}}
      </a>
      {{__('impulses.listItem6_3')}}
    </li>
    <li>{{__('impulses.listItem7')}}</li>
  </ul>

  <span [innerHTML]="__('impulses.text2_1')"></span>
  <a (click)="scroll('dialogueAnchor', 'middle')">{{__('impulses.dialogue.link')}} </a>
  <span class="id37-text2 text-light" [innerHTML]="__('impulses.text2_2')"></span>
</div>

<h3>{{__('exercise.headline')}}</h3>
<app-collapsible-list>
  <app-collapsible-item class="friends-collapsible-item">
    <app-collapsible-header [title]="__('exercise.invest.headline')"></app-collapsible-header>
    <app-collapsible-body>
      <div class="id37-text2 text-light">
        <span [innerHTML]="__('exercise.invest.text1')"></span>
        <ul>
          <li>{{__('exercise.invest.listItem1')}}</li>
          <li>{{__('exercise.invest.listItem2')}}</li>
          <li>{{__('exercise.invest.listItem3')}}</li>
        </ul>
        <span [innerHTML]="__('exercise.invest.text2')"></span>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>

<app-collapsible-list>
  <app-collapsible-item class="friends-collapsible-item">
    <app-collapsible-header [title]="__('exercise.listen.headline')"></app-collapsible-header>
    <app-collapsible-body>
      <div class="id37-text2 text-light">
        <span [innerHTML]="__('exercise.listen.text1')"></span>
        <ul>
          <li>{{__('exercise.listen.listItem1')}}</li>
          <li>{{__('exercise.listen.listItem2')}}</li>
          <li>{{__('exercise.listen.listItem3')}}</li>
          <li>{{__('exercise.listen.listItem4')}}</li>
          <li>{{__('exercise.listen.listItem5')}}</li>
        </ul>
        <span [innerHTML]="__('exercise.listen.text2')"></span>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>

<app-collapsible-list id="dialogueAnchor">
  <app-collapsible-item #exerciseDialogue class="friends-collapsible-item">
    <app-collapsible-header [title]="__('exercise.dialogue.headline')"></app-collapsible-header>
    <app-collapsible-body>
      <div class="id37-text2 text-light">
        <span [innerHTML]="__('exercise.dialogue.text')"></span>
        <ul>
          <li [innerHTML]="__('exercise.dialogue.listItem1')"></li>
          <li [innerHTML]="__('exercise.dialogue.listItem2')"></li>
          <li [innerHTML]="__('exercise.dialogue.listItem3')"></li>
          <li [innerHTML]="__('exercise.dialogue.listItem4')"></li>
          <li [innerHTML]="__('exercise.dialogue.listItem5')"></li>
          <li [innerHTML]="__('exercise.dialogue.listItem6')"></li>
        </ul>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>
