import {NgModule} from '@angular/core';
import {UpdateComponent} from './update/update.component';
import {ModalModule} from '../modal/modal.module';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ModalModule
  ],
  declarations: [
    UpdateComponent
  ],
  exports: [
    UpdateComponent
  ]
})
export class LifecycleModule {
}
