import {Component, Input} from '@angular/core';
import {Friendship} from '../../friend/state/friendship';
import {ChatMessagesStore} from '../state/chat-messages.store';
import {Observable} from 'rxjs';
import {ChatMessagesQuery} from '../state/chat-messages.query';
import {Id37Router} from '../../id37-router';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {ChatMessage} from '../state/chat-message';

@Component({
  selector: 'app-chat-contact',
  templateUrl: './chat-contact.component.html',
  styleUrls: ['./chat-contact.component.scss']
})
export class ChatContactComponent {

  contact: Friendship | null;
  contactKeycloakId: string = '';
  contactFullName: string = '';
  contactActive$: Observable<boolean>;
  amountOfUnreadMessages$: Observable<number>;
  lastMessage$: Observable<ChatMessage | undefined>;

  constructor(private currentUser: CurrentUser,
              private chatMessagesStore: ChatMessagesStore,
              private chatMessagesQuery: ChatMessagesQuery,
              private id37Router: Id37Router,
              private translatorService: TranslatorService) {
  }

  @Input()
  set friendship(friendship: Friendship | null) {
    this.contact = friendship;

    if (!!this.contact) {
      this.contactKeycloakId = FriendshipFunctions.getFriendUserId(this.contact, this.currentUser.keycloakId);
      this.contactFullName = FriendshipFunctions.getFriendFullName(this.contact, this.currentUser.keycloakId);

      // Check if this contact is currently active.
      this.contactActive$ = this.chatMessagesQuery.selectIsActive(this.contactKeycloakId);

      this.amountOfUnreadMessages$ = this.chatMessagesQuery
        .selectAmountOfUnreadMessagesForContact(this.contactKeycloakId);

      this.lastMessage$ = this.chatMessagesQuery.selectLastChatMessage(this.contactKeycloakId);
    }
  }

  selectContact(): void {
    this.chatMessagesStore.setActiveContact(this.contactKeycloakId);
  }

  visitNetwork(): void {
    this.id37Router.routeToFriends();
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
