<!-- SELECTED -->
<div style="position: relative;">
  <div #selectInput
       (click)="toggleMenu()"
       (focusin)="state.inFocus = true"
       (focusout)="state.inFocus = false"
       [ngClass]="{
     'type-select': state.isSelect(),
     'type-multiselect': state.isMultiselect(),
     'disabled': state.disabled
     }"
       class="select-selected"
       tabindex="0">
    <ng-container *ngIf="!selected || (state.isMultiselect() && array.isArray(selected) && selected.length === 0)">
      <span class="placeholder">{{state.placeholder}}</span>
    </ng-container>
    <ng-container *ngIf="!!selected && state.isSelect() && !array.isArray(selected)">
      <span>{{selected.label}}</span>
    </ng-container>
    <ng-container *ngIf="!!selected && state.isMultiselect() && array.isArray(selected)">
      <div class="multiselect-label-wrapper">
        <ng-container *ngFor="let choice of selected">
        <span (click)="processLabelClick($event, choice)" class="multiselect-choice-label">
          {{choice.label}}
        </span>
        </ng-container>
      </div>
    </ng-container>
    <span *ngIf="!dropUp" class="arrow-down"></span>
    <span *ngIf="dropUp" class="arrow-up"></span>
  </div>

  <div [ngClass]="{'dropUp': dropUp}" class="select-dropdown-menu-ref">
    <div *ngIf="state.menuOpen" class="select-dropdown-menu">
      <!-- SEARCH -->
      <!--suppress HtmlFormInputWithoutLabel -->
      <input #searchInput
             *ngIf="state.menuOpen"
             [(ngModel)]="state.search"
             class="select-search"
             placeholder="{{state.searchPlaceholder}}"
             type="text">

      <!-- MENU -->
      <app-select-menu
          (deselect)="deselect($event)"
          (select)="select($event)"
          [choicesGroups]="choicesGroups"
          [filter]="state.search">
      </app-select-menu>

    </div>
  </div>

</div>
