<div #scrollContainer class="select-items">

  <ng-container *ngIf="filteredChoicesGroups.length > 0; else noChoices">

    <ng-container *ngFor="let choicesGroup of filteredChoicesGroups">
      <div class="choices-group">
        <div *ngIf="!!choicesGroup.label && choicesGroup.label.length > 0" class="choices-group-label">
          {{choicesGroup.label}}
        </div>
        <div class="choices-group-choices">
          <ng-container *ngFor="let choice of choicesGroup.choices">
            <div class="choice-scroll-wrapper"
                 id="{{seed}}-{{choice.value}}">
              <app-select-item
                  (deselect)="deselect.emit($event)"
                  (select)="select.emit($event)"
                  [choice]="choice"
                  [preSelected]="!!preSelected && preSelected.value === choice.value">
              </app-select-item>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <ng-template #noChoices>
    <div class="no-choices">
      {{state.noSearchResultsTranslation}}
    </div>
  </ng-template>

</div>
