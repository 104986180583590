import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'id37-avatar-distance-circle',
  templateUrl: './avatar-distance-circle.component.html',
  styleUrls: ['./avatar-distance-circle.component.scss']
})
export class AvatarDistanceCircleComponent {

  /* Profile picture */

  @Input()
    profilePictureUserId: string | undefined = undefined;

  @Input()
    profilePictureAllowAccountDataRetrieval: boolean = true;

  @Input()
    profilePictureWidth: string = '5em';

  @Input()
    profilePictureHeight: string = '5em';

  /* Circular ring */

  @Input()
    circularRingRender: boolean = false;

  @Input()
    circularRingProgress: number = 0.0;

  @Input()
    circularRingCircleSizePx: number = 125;

  @Input()
    circularRingCircleStrokeWidth: number = 7.5;

  @Output()
    circularRingProgressChange: EventEmitter<number> = new EventEmitter<number>();

}
