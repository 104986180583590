<ng-container *ngIf="!!searchResult || !!searchFriendsResult; else loading">

  <div *ngIf="!this.networkingState" class="id37-text1 empty-result-text">
    <span>{{__('networkingDisabledWarningA')}}</span>
    <a (click)="id37Router.routeToAccount()" class="id37-link">
      <span>{{__('networkingDisabledWarningAccount')}}</span>
    </a>
    <span>{{__('networkingDisabledWarningB')}}</span>
  </div>

  <h3 *ngIf="networkingState">{{__('searchResult')}} ({{searchResult.length}})</h3>
  <h3 *ngIf="!networkingState">{{__('searchResult')}} ({{searchFriendsResult.length}})</h3>

  <div *ngIf="((!!searchResult && searchResult.length === 0)
              || (!!searchFriendsResult && searchFriendsResult.length === 0))"
       [innerHTML]="__('emptyResult')"
       class="id37-text1 empty-result-text">
  </div>

  <id37-friend-tile-container>
    <!-- OptIn enabled: Show users from search -->
    <ng-container *ngIf="!!searchResult">
      <ng-container *ngFor="let user of searchResult">
        <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                         (createFriendship)="createFriendship.emit($event)"
                         (declineFriendship)="declineFriendship.emit($event)"
                         (deleteFriendship)="declineFriendship.emit($event)"
                         (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                         (updateTestShare)="updateTestShare.emit($event)"
                         [activeFriendship]="getFriendshipWith(user.keycloakId)"
                         [loading]="!!isLoading[user.keycloakId] ? isLoading[user.keycloakId] : false"
                         [user]="user"
        ></app-friend-tile>
      </ng-container>
    </ng-container>

    <!-- OptIn disabled: Show friends -->
    <ng-container *ngIf="!!searchFriendsResult">
      <ng-container *ngFor="let friendship of searchFriendsResult">
        <app-friend-tile (acceptFriendship)="acceptFriendship.emit($event)"
                         (declineFriendship)="declineFriendship.emit($event)"
                         (deleteFriendship)="declineFriendship.emit($event)"
                         (resendFriendshipRequest)="resendFriendshipRequest.emit($event)"
                         (updateTestShare)="updateTestShare.emit($event)"
                         [activeFriendship]="friendship"
                         [loading]="!!isLoading[friendship.userB.id] ? isLoading[friendship.userB.id] : false"
                         [user]="undefined"
        ></app-friend-tile>
      </ng-container>
    </ng-container>

    <!-- "Recommend" tile -->
    <app-friend-tile *featureAllowed="'recommend'"
                     (recommend)="recommend.emit($event)"
                     [activeFriendship]="undefined"
                     [loading]="false"
                     [user]="undefined"
    ></app-friend-tile>
  </id37-friend-tile-container>

</ng-container>

<ng-template #loading>
  <app-loading-indicator></app-loading-indicator>
</ng-template>
