<div *ngIf="showFirefoxWarning && browserService.isFirefox()" class="alert warning firefox-print-warning">
  <div>
    {{__('firefoxPrintWarning')}}
    <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>
  </div>
</div>

<div (click)="buttonClicked.emit()" class="print-btn hide-on-print">
  <img alt="printer icon" src="assets/icons/ID37_Print_20x20.svg"/> {{text}}
</div>
