import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() {
  }

  /**
   * Issues a {@code window.print()} command after adding the given class name to the specified element. <br>
   * After that, the class is removed from the element to leave the element as is.
   *
   * @param element The element on which the class is applied while printing.
   * @param className This class name will be set / added on {@code element} for the duration of the print.
   */
  printWithClass(element: HTMLElement, className: string): void {
    const classList = element.classList;
    if (!classList.contains(className)) {
      classList.add(className);
    }
    window.print();
    classList.remove(className);
  }

}
