import {Injectable, OnDestroy} from '@angular/core';
import {NotificationQuery} from './state/notification.query';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Notification} from './state/notification';
import {NotificationType} from './state/notification-type';
import {CustomerResetService} from '../master/my-customers/customer-reset.service';
import {TestResetService} from '../test/state/test-reset.service';
import {FriendshipResetService} from '../friend/state/friendship-reset.service';
import {AccountDataService} from '../account/account-data-service';
import {FriendshipFunctions} from '../friend/state/friendship-functions';
import {CurrentUser} from '../user/current-user.service';
import {Friendship} from '../friend/state/friendship';

@Injectable({
  providedIn: 'root'
})
export class NotificationHandler implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private accountDataService: AccountDataService,
              private currentUser: CurrentUser,
              private notificationQuery: NotificationQuery,
              private testResetService: TestResetService,
              private friendshipResetService: FriendshipResetService,
              private customerResetService: CustomerResetService) {
  }

  startListeningForNewNotifications(): void {
    this.notificationQuery.selectNotificationUpdates()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notification: Notification) => {
        switch (notification.type) {
          case NotificationType.ACCOUNT_CREATED:
            // Intentionally do nothing.
            break;
          case NotificationType.TEST_INVITATION:
          case NotificationType.TEST_REVOKED:
          case NotificationType.TEST_ACCESS_GRANTED:
            this.testResetService.reloadTestsForCurrentUser();
            break;
          case NotificationType.TEST_FINISHED:
            this.customerResetService.reloadCustomers();
            break;
          case NotificationType.FRIENDSHIP_INVITATION:
            this.friendshipResetService.reloadFriendships();
            this.accountDataService.load(FriendshipFunctions.getFriendUserId(
              notification.data.friendshipId as Friendship, this.currentUser.keycloakId)).subscribe();
            break;
          case NotificationType.FRIENDSHIP_ACCEPTED:
            this.friendshipResetService.reloadFriendships();
            break;
          case NotificationType.TESTSHARE_CHANGED:
            this.friendshipResetService.reloadFriendship(notification.data.friendshipId as number);
            break;
          case NotificationType.SUPERLIKE_SENT:
          case NotificationType.SYSTEM_TEST:
          case NotificationType.RAW:
          case NotificationType.LOCALIZED_RAW:
            // Intentionally do nothing.
            break;
          default:
            console.error('Notification handler did not understood the following notification type: ', notification.type);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
