import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Choice, ChoicesGroup, LocalizedChoices, LocalizedChoicesGroups} from '../state/choices-types';
import {CurrentUser} from '../../user/current-user.service';
import {SelectState} from '../state/select-state';
import {DOCUMENT} from '@angular/common';
import {ChoicesService} from '../state/choices.service';
import {TranslatorService} from '../../translation/translator.service';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    SelectState,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  array = Array;
  @ViewChild('selectInput')
    selectInput: ElementRef;
  // We have to use ViewChildren, as the *ngIf on the input prevent us from using ViewChild without problems.
  @ViewChildren('searchInput')
    searchInputs: QueryList<ElementRef>;
  searchInput: ElementRef | undefined;
  @Input()
    formControl: FormControl;
  @Input()
    dropUp: boolean = false;
  @Output()
    open: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    close: EventEmitter<void> = new EventEmitter<void>();
  @Output()
    selection: EventEmitter<Choice | Choice[] | undefined> = new EventEmitter<Choice | Choice[] | undefined>();
  @Output()
    selectionValues: EventEmitter<string> = new EventEmitter<string>();

  constructor(public state: SelectState,
              public currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private choicesService: ChoicesService,
              private cdRef: ChangeDetectorRef,
              @Inject(DOCUMENT) private document: HTMLDocument) {
    this.state.placeholder = this.__('defaultPlaceholder');
    this.state.searchPlaceholder = this.__('defaultSearchPlaceholder');
    this.state.noSearchResultsTranslation = this.__('defaultNoSearchResults');
    this.state.selectedTranslation = this.__('defaultSelected');
  }

  ngOnInit() {
    if (this.formControl !== undefined) { // only used in start-page.component
      this.formControl.markAsTouched = () => {
        this.onClickInside();
      };
    }
  }

  private _choicesGroups: ChoicesGroup[];

  get choicesGroups(): ChoicesGroup[] {
    return this._choicesGroups;
  }

  @Input()
  set choicesGroups(choicesGroups: ChoicesGroup[]) {
    this._choicesGroups = this.choicesService.cloneChoicesGroups(choicesGroups);
    this.searchAndSelectInitiallySelectedChoices();
  }

  private _selected: Choice | Choice[] | undefined;

  get selected(): Choice | Choice[] | undefined {
    return this._selected;
  }

  @Input()
  set type(type: 'select' | 'multiselect') {
    this.state.type = type;
  }

  @Input()
  set disabled(disabled: boolean) {
    this.state.disabled = disabled;
    if (this.state.disabled && this.state.menuOpen) {
      this.closeMenu();
    }
  }

  @HostBinding('style.opacity')
  get opacity() {
    return this.state.disabled ? 0.5 : 1;
  }

  @Input()
  set allowDeselect(allowDeselect: boolean) {
    this.state.deselectAllowed = allowDeselect;
  }

  @Input()
  set placeholder(placeholder: string) {
    this.state.placeholder = placeholder;
  }

  @Input()
  set searchPlaceholder(searchPlaceholder: string) {
    this.state.searchPlaceholder = searchPlaceholder;
  }

  @Input()
  set noSearchResultsTranslation(noSearchResultsTranslation: string) {
    this.state.noSearchResultsTranslation = noSearchResultsTranslation;
  }

  @Input()
  set selectedTranslation(selectedTranslation: string) {
    this.state.selectedTranslation = selectedTranslation;
  }

  @Input()
  set choices(choices: Choice[]) {
    this._choicesGroups = this.choicesService.cloneChoicesGroups([
      {
        label: '',
        id: 1,
        choices
      }
    ]);
    this.searchAndSelectInitiallySelectedChoices();
  }

  @Input()
  set localizedChoices(localizedChoices: LocalizedChoices) {
    this._choicesGroups = this.choicesService.cloneChoicesGroups([
      {
        label: '',
        id: 1,
        choices: localizedChoices[this.currentUser.locale]
      }
    ]);
    this.searchAndSelectInitiallySelectedChoices();
  }

  @Input()
  set localizedChoicesGroups(localizedChoicesGroups: LocalizedChoicesGroups) {
    this._choicesGroups = this.choicesService.cloneChoicesGroups(localizedChoicesGroups[this.currentUser.locale]);
    this.searchAndSelectInitiallySelectedChoices();
  }

  get selectedValues(): string {
    if (!this._selected) {
      return '';
    }
    if (Array.isArray(this._selected)) {
      return this._selected.map(choice => choice.value).join(',');
    }
    return this._selected.value;
  }

  searchAndSelectInitiallySelectedChoices(): void {
    if (this.state.isSelect()) {
      this._choicesGroups.forEach(group => group.choices.forEach(choice => {
        if (choice.selected) {
          this._selected = choice;
        }
      }));
    }
  }

  @HostListener('click')
  onClickInside() {
    this.state.touched = true;
    this.state.clickedInside = true;
  }

  @HostListener('document:click')
  onClickOutside() {
    if (!this.state.clickedInside && this.state.menuOpen) {
      // Clicked outside!
      this.closeMenu();
    }
    this.state.clickedInside = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.state.inFocus && !this.state.menuOpen
      && (event.key === 'ArrowDown' || event.key === 'Enter' || event.code === 'Space')) {
      event.preventDefault();
      this.openMenu();
    }
    if (this.state.menuOpen && (event.key === 'Escape' || event.key === 'Tab')) {
      this.closeMenu();
    }
  }


  ngAfterViewInit(): void {
    this.searchInputs.changes.subscribe((inputs: QueryList<ElementRef>) => {
      this.searchInput = inputs.first;
    });
  }

  resetSearch(): void {
    this.state.search = '';
  }

  openMenu(): void {
    if (!this.state.menuOpen) {
      this.state.menuOpen = true;
      this.cdRef.detectChanges();
      if (!!this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
      this.open.emit();
    }
  }

  closeMenu(): void {
    if (this.state.menuOpen) {
      // Opening the menu puts the focus on the search input.
      // On close, the focus on the select itself should be restored, as the user might still want to interact with it
      // or want to tab further down the focusable elements on the site.
      // If the menu is closed by pressing escape,
      // the search input is still focused and the focus can be restored safely.
      // If the menu is closed with a click outside the select menu and onto a focusable element, restoring focus
      // to the select would probably be against the users intention / will.
      if (!!this.selectInput && !!this.selectInput.nativeElement
        && !!this.searchInput && !!this.searchInput.nativeElement
        && !!this.document.activeElement
        && (this.document.activeElement === this.document.body
          || this.document.activeElement === this.document.body.parentElement
          || this.document.activeElement === this.searchInput.nativeElement)) {
        this.selectInput.nativeElement.focus();
      }
      this.state.menuOpen = false;
      if (this.state.isSelect()) {
        this.resetSearch();
      }
      this.close.emit();
    }
  }

  toggleMenu(): void {
    // Do not let the user interact with the select if it is disabled.
    if (this.state.disabled) {
      return;
    }
    if (this.state.menuOpen) {
      this.closeMenu();
    }
    else {
      this.openMenu();
    }
  }

  processLabelClick(event: Event, choice: Choice): void {
    event.stopPropagation();
    this.deselect(choice);
  }

  trySelectChoiceByValue(value: string): void {
    const choice: Choice | undefined = this.choicesService.find(value, this.choicesGroups);
    if (!!choice) {
      if (!choice.selected) {
        this.select(choice);
      }
    }
    else {
      // console.warn('Tried to select a choice by value. No choice was found for value: ', value);
    }
  }

  select(choice: Choice): void {
    // Process the currently selected and newly selected choices.
    if (!!this._selected) {
      if (!Array.isArray(this._selected)) {
        this._selected.selected = false;
      }
    }
    // Save the selected choice.
    if (this.state.isSelect()) {
      choice.selected = true;
      this._selected = choice;
      this.selection.emit(this._selected);
      this.propagateSelectedValueChanges(this.selectedValues);
    }
    else if (this.state.isMultiselect()) {
      if (!Array.isArray(this._selected)) {
        this._selected = [];
      }
      choice.selected = true;
      this._selected.push(choice);
      this.selection.emit(this._selected);
      this.propagateSelectedValueChanges(this.selectedValues);
    }
    else {
      console.error('Unknown select mode. Cannot process selection.');
    }
    // We should reset the search input when a choice is selected in single select mode.
    // The menu is not needed anymore and can be closed.
    // In multiselect mode, the menu should stay open and the search input must not be reset.
    if (this.state.isSelect()) {
      this.closeMenu();
      this.resetSearch();
    }
  }

  deselect(choice: Choice): void {
    if (Array.isArray(this._selected)) {
      for (let i = 0; i < this._selected.length; i++) {
        const selected = this._selected[i];
        if (selected === choice) {
          selected.selected = false;
          this._selected.splice(i, 1);
          this.selection.emit(this._selected);
          this.propagateSelectedValueChanges(this.selectedValues);
        }
      }
    }
    else {
      if (this.state.deselectAllowed && this._selected === choice) {
        this._selected.selected = false;
        this._selected = undefined;
        this.selection.emit(this._selected);
        this.propagateSelectedValueChanges(this.selectedValues);
      }
    }
    // We should reset the search input when a choice is selected in single select mode.
    // The menu is not needed anymore and can be closed.
    // In multiselect mode, the menu should stay open and the search input must not be reset.
    if (this.state.isSelect()) {
      this.closeMenu();
      this.resetSearch();
    }
  }

  __(key: string): string {
    return this.translatorService.translate('select.' + key);
  }

  propagateSelectedValueChanges(selectedValues) {
    this.selectionValues.emit(selectedValues as string);
    this.ngFormsPropagateChange(selectedValues);
  }

  /* ControlValueAccessor implementation */

  // The argument to this function is propagated to the form control value!
  ngFormsPropagateChange = (selectedValues) => {
  };

  registerOnChange(fn: any): void {
    this.ngFormsPropagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // On blur, etc...
    // We don't care right now.
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (typeof obj !== 'string') {
      console.error('Unable to write value to select. Not a string.', obj);
      return;
    }
    obj.split(',').map(string => string.trim()).forEach(value => {
      this.trySelectChoiceByValue(value);
    });
  }

}
