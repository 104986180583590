<div class="canvas-container">
  <canvas id="radar-canvas"></canvas>

  <div class="button-container">
    <ng-container *ngFor="let b of motiveButtons">
      <button
          (click)="toggleMotive(b.motive)"
          [ngClass]="{'selected': isSelected(b.motive)}"
          class="id37-btn id37-btn-bw id37-btn-flat">
        {{b.displayName}}
      </button>
    </ng-container>
  </div>
</div>
