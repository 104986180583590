import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ChatMessagesQuery} from '../../chat/state/chat-messages.query';
import {Id37Router} from '../../id37-router';

@Component({
  selector: 'app-chat-overview',
  templateUrl: './chat-overview.component.html',
  styleUrls: ['./chat-overview.component.scss']
})
export class ChatOverviewComponent implements OnInit {

  amountOfUnreadChatMessages$: Observable<number>;

  constructor(private chatMessagesQuery: ChatMessagesQuery,
              private id37Router: Id37Router) {
  }

  ngOnInit(): void {
    this.amountOfUnreadChatMessages$ = this.chatMessagesQuery.selectAmountOfUnreadMessages();
  }

  openChat(): void {
    this.id37Router.routeToChat();
  }

}
