import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../../test/state/motive-score';
import {Friendship} from '../../../friend/state/friendship';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';

@Component({
  selector: 'app-close-filter',
  templateUrl: './close-filter.component.html',
  styleUrls: ['./close-filter.component.scss']
})
export class CloseFilterComponent implements OnInit {

  @Input()
    distantMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    friendship: Friendship;

  @Input()
    friendFullName: string;

  @Input()
    friendGender: string;

  motivePrefix: string;
  motiveTextHtml: string[] = [];

  constructor(
    private currentUser: CurrentUser,
    private translatorService: TranslatorService
  ) {
  }

  ngOnInit(): void {
    if (this.distantMotiveScoreTuple.length === 1) {
      this.motivePrefix = this.translatorService.translate('closeFilter.motive');
    }
    else {
      this.motivePrefix = this.translatorService.translate('closeFilter.motives');
    }
    this.computeMotiveText();
  }

  computeMotiveText(): void {
    this.distantMotiveScoreTuple.forEach((tuple) => {
      const motive: string = this.translatorService.translate('motive.' + tuple[0].motive.toLowerCase());
      let htmlString: string = '<strong>' + motive.toUpperCase()
        + this.translatorService.translate('closeFilter.motiveText.headline')
        + Math.abs(tuple[0].score - tuple[1].score) + '</strong><br>';
      if (tuple[1].score > 7 && tuple[0].score < 8) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.high-medium');
      }
      else if (tuple[1].score > 7 && tuple[0].score > 7) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.high-high');
      }
      else if (tuple[0].score > 7) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.medium-high');
      }
      else if (tuple[1].score > 3 && tuple[1].score < 8 &&
        tuple[0].score > 3 && tuple[0].score < 8) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.medium-medium');
      }
      else if (tuple[1].score > 3 && tuple[1].score < 8 && tuple[0].score < 4) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.medium-low');
      }
      else if (tuple[1].score < 4 && tuple[0].score < 4) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.low-low');
      }
      else if (tuple[1].score < 4 && tuple[0].score > 3 && tuple[0].score < 8) {
        htmlString += this.__(tuple[1].motive.toLowerCase() + '.low-medium');
      }
      else {
        throw new Error('No cases');
      }

      htmlString = htmlString.replace('*SCORE_FR*', tuple[1].score.toString());
      htmlString = htmlString.replace('*SCORE*', tuple[0].score.toString());
      this.motiveTextHtml.push(htmlString);
    });
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('closeFilter.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation.replace(/\*MOTIVES\*/g, this.motivePrefix);
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.C\*/g, this.translatorService.translate(
        'personalPronoun.nominative.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.C\*/g, this.translatorService.translate(
        'personalPronoun.dative.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'personalPronoun.accusative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*GENITIVE.F\/PL\*/g, this.translatorService.translate(
        'possessivePronoun.genitive.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
