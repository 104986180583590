import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CurrentUser} from '../../user/current-user.service';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-datetime-time-selector',
  templateUrl: './datetime-time-selector.component.html',
  styleUrls: ['./datetime-time-selector.component.scss']
})
export class DatetimeTimeSelectorComponent implements OnInit, OnDestroy {

  readonly moment = moment;
  readonly now: Moment = moment().locale(this.currentUser.locale);

  selected: Moment = this.now;

  allowMinuteSelection: boolean = true;
  allowSecondSelection: boolean = false;

  hourName: string = 'Stunde';
  minuteName: string = 'Minute';
  secondName: string = 'Sekunde';
  hourInputChanged$: Subject<string> = new Subject<string>();
  minuteInputChanged$: Subject<string> = new Subject<string>();
  secondInputChanged$: Subject<string> = new Subject<string>();
  debounceTime = 500;
  reload: boolean = false;
  @ViewChild('hour')
    hourInput: ElementRef<HTMLInputElement>;
  @ViewChild('minute')
    minuteInput: ElementRef<HTMLInputElement>;
  @ViewChild('second')
    secondInput: ElementRef<HTMLInputElement>;
  @Output()
    selection: EventEmitter<Moment> = new EventEmitter<Moment>();
  @Output()
    closeRequest: EventEmitter<void> = new EventEmitter<void>();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private currentUser: CurrentUser) {
  }

  @Input()
  set currentlySelected(selected: Moment | undefined) {
    this.selected = !!selected ? selected : moment(this.now);
  }

  ngOnInit(): void {
    this.hourInputChanged$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.debounceTime)
    ).subscribe(value => this.onHourInputChange(value));

    this.minuteInputChanged$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.debounceTime)
    ).subscribe(value => this.onMinuteInputChange(value));

    this.secondInputChanged$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.debounceTime)
    ).subscribe(value => this.onSecondInputChange(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  propagateChange(): void {
    this.selection.emit(this.selected);
  }

  propagateChangeAndClose(): void {
    this.selection.emit(this.selected);
    this.closeRequest.emit();
  }

  onHourInputChange(input: string): void {
    let hour = Number.parseInt(input, 10);
    if (hour < 0 || hour > 23) {
      hour = 0;
    }
    this.selected.hours(hour);
    this.propagateChange();
    this.doReload();
    setTimeout(() => this.hourInput.nativeElement.focus(), 0);
  }

  onMinuteInputChange(input: string): void {
    let minute = Number.parseInt(input, 10);
    if (minute < 0 || minute > 59) {
      minute = 0;
    }
    this.selected.minutes(minute);
    this.propagateChange();
    this.doReload();
    setTimeout(() => this.minuteInput.nativeElement.focus(), 0);
  }

  onSecondInputChange(input: string): void {
    let second = Number.parseInt(input, 10);
    if (second < 0 || second > 59) {
      second = 0;
    }
    this.selected = moment(this.selected.seconds(second));
    this.propagateChange();
    this.doReload();
    setTimeout(() => this.secondInput.nativeElement.focus(), 0);
  }

  incHour(): void {
    this.selected.add(1, 'hours');
    this.propagateChange();
  }

  decHour(): void {
    this.selected.subtract(1, 'hours');
    this.propagateChange();
  }

  incMinute(): void {
    this.selected.add(1, 'minutes');
    this.propagateChange();
  }

  decMinute(): void {
    this.selected.subtract(1, 'minutes');
    this.propagateChange();
  }

  incSecond(): void {
    this.selected.add(1, 'seconds');
    this.propagateChange();
  }

  decSecond(): void {
    this.selected.subtract(1, 'seconds');
    this.propagateChange();
  }

  doReload(): void {
    setTimeout(() => this.reload = true, 0);
    setTimeout(() => this.reload = false, 0);
  }

}
