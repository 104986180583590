<ng-container [ngSwitch]="layout">

  <!-- Desktop version -->
  <svg *ngSwitchCase="'desktop'" viewBox="0 0 1108 67" height="100%" width="100%">

    <symbol id="header-desktop" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <g opacity="0.4">
        <path d="M42.55 2.6V1.88C42.93 1.88 43.2333 1.81 43.46 1.67C43.6867 1.53 43.8867 1.30667 44.06 0.999999H44.82V8H43.98V2.13C43.7933 2.31 43.59 2.43333 43.37 2.5C43.1567 2.56667 42.8833 2.6 42.55 2.6Z" fill="#383838"/>
        <path d="M132.57 7.45L135.27 4.74C135.637 4.37333 135.89 4.05667 136.03 3.79C136.17 3.52333 136.24 3.23 136.24 2.91C136.24 2.51 136.103 2.18667 135.83 1.94C135.563 1.68667 135.22 1.56 134.8 1.56C134.4 1.56 134.067 1.69 133.8 1.95C133.533 2.21 133.4 2.54667 133.4 2.96C133.4 3.12 133.427 3.27 133.48 3.41L132.74 3.53C132.66 3.32333 132.62 3.10333 132.62 2.87C132.62 2.33667 132.827 1.87 133.24 1.47C133.66 1.06333 134.19 0.859999 134.83 0.859999C135.497 0.859999 136.033 1.04333 136.44 1.41C136.853 1.77667 137.06 2.24 137.06 2.8C137.06 3.22 136.967 3.62 136.78 4C136.6 4.37333 136.28 4.79 135.82 5.25L133.82 7.26H137.09V8H132.57V7.45Z" fill="#383838"/>
        <path d="M224.71 5.56L225.48 5.75C225.453 5.84333 225.44 5.95667 225.44 6.09C225.44 6.47 225.587 6.78333 225.88 7.03C226.173 7.27667 226.54 7.4 226.98 7.4C227.453 7.4 227.823 7.27667 228.09 7.03C228.363 6.77667 228.5 6.42 228.5 5.96C228.5 5.49333 228.32 5.14667 227.96 4.92C227.607 4.69333 227.137 4.58 226.55 4.58V3.9C227.123 3.9 227.537 3.78333 227.79 3.55C228.05 3.31 228.18 3.01 228.18 2.65C228.18 2.32333 228.057 2.06 227.81 1.86C227.57 1.66 227.26 1.56 226.88 1.56C226.52 1.56 226.213 1.66667 225.96 1.88C225.707 2.09333 225.58 2.37 225.58 2.71C225.58 2.82333 225.597 2.92333 225.63 3.01L224.91 3.12C224.857 2.96 224.83 2.79 224.83 2.61C224.83 2.12333 225.027 1.71 225.42 1.37C225.813 1.03 226.303 0.859999 226.89 0.859999C227.53 0.859999 228.04 1.02 228.42 1.34C228.807 1.66 229 2.06333 229 2.55C229 2.90333 228.9 3.23 228.7 3.53C228.5 3.83 228.21 4.04 227.83 4.16C228.283 4.27333 228.647 4.48 228.92 4.78C229.2 5.08 229.34 5.45667 229.34 5.91C229.34 6.59 229.113 7.13333 228.66 7.54C228.213 7.94 227.653 8.14 226.98 8.14C226.273 8.14 225.7 7.94 225.26 7.54C224.82 7.13333 224.6 6.67 224.6 6.15C224.6 5.92333 224.637 5.72667 224.71 5.56Z" fill="#383838"/>
        <path d="M316.49 6.6V5.86L319.94 0.999999H320.78V5.86H321.57V6.6H320.78V8H319.94V6.6H316.49ZM319.94 2.22L317.38 5.86H319.94V2.22Z" fill="#383838"/>
        <path d="M408.63 4.78L408.96 0.999999H412.98V1.74H409.63L409.43 4.1C409.623 3.82 409.87 3.60333 410.17 3.45C410.477 3.29667 410.797 3.22 411.13 3.22C411.843 3.22 412.403 3.44667 412.81 3.9C413.223 4.34667 413.43 4.93333 413.43 5.66C413.43 6.38 413.197 6.97333 412.73 7.44C412.27 7.90667 411.667 8.14 410.92 8.14C410.227 8.14 409.663 7.96 409.23 7.6C408.803 7.24 408.59 6.79667 408.59 6.27C408.59 6.07667 408.62 5.91 408.68 5.77L409.45 5.95C409.423 6.04333 409.41 6.13667 409.41 6.23C409.41 6.61 409.55 6.9 409.83 7.1C410.11 7.3 410.473 7.4 410.92 7.4C411.413 7.4 411.813 7.24667 412.12 6.94C412.433 6.62667 412.59 6.2 412.59 5.66C412.59 5.12 412.437 4.69667 412.13 4.39C411.83 4.07667 411.443 3.92 410.97 3.92C410.61 3.92 410.283 4.02333 409.99 4.23C409.697 4.43 409.47 4.67667 409.31 4.97L408.63 4.78Z" fill="#383838"/>
        <path d="M505.2 3.04L504.41 2.88C504.463 2.74 504.49 2.62 504.49 2.52C504.49 2.24667 504.38 2.02667 504.16 1.86C503.94 1.68667 503.64 1.6 503.26 1.6C502.72 1.6 502.28 1.87 501.94 2.41C501.607 2.94333 501.423 3.64667 501.39 4.52C501.583 4.17333 501.853 3.90333 502.2 3.71C502.547 3.51667 502.91 3.42 503.29 3.42C503.937 3.42 504.477 3.64 504.91 4.08C505.35 4.52 505.57 5.07667 505.57 5.75C505.57 6.41 505.333 6.97333 504.86 7.44C504.393 7.90667 503.81 8.14 503.11 8.14C502.323 8.14 501.697 7.85667 501.23 7.29C500.77 6.71667 500.54 5.92333 500.54 4.91C500.54 4.07 500.677 3.30333 500.95 2.61C501.17 2.05667 501.483 1.62667 501.89 1.32C502.297 1.01333 502.75 0.859999 503.25 0.859999C503.87 0.859999 504.363 1.01333 504.73 1.32C505.103 1.62667 505.29 2.03 505.29 2.53C505.29 2.69667 505.26 2.86667 505.2 3.04ZM504.25 4.6C503.937 4.30667 503.557 4.16 503.11 4.16C502.663 4.16 502.28 4.30667 501.96 4.6C501.64 4.89333 501.48 5.28667 501.48 5.78C501.48 6.27333 501.64 6.66667 501.96 6.96C502.28 7.25333 502.663 7.4 503.11 7.4C503.557 7.4 503.937 7.25333 504.25 6.96C504.57 6.66667 504.73 6.27333 504.73 5.78C504.73 5.28667 504.57 4.89333 504.25 4.6Z" fill="#383838"/>
        <path d="M597.95 1.68L595.12 8H594.3L597.1 1.74H593.43V0.999999H597.95V1.68Z" fill="#383838"/>
        <path d="M689.57 7.57C689.13 7.95 688.573 8.14 687.9 8.14C687.227 8.14 686.667 7.95 686.22 7.57C685.78 7.18333 685.56 6.68 685.56 6.06C685.56 5.65333 685.663 5.29667 685.87 4.99C686.083 4.67667 686.367 4.43 686.72 4.25C686.427 4.09 686.197 3.87 686.03 3.59C685.863 3.31 685.78 3.00333 685.78 2.67C685.78 2.14333 685.983 1.71 686.39 1.37C686.797 1.03 687.3 0.859999 687.9 0.859999C688.5 0.859999 689.003 1.03 689.41 1.37C689.817 1.71 690.02 2.14333 690.02 2.67C690.02 3.00333 689.937 3.31 689.77 3.59C689.603 3.87 689.373 4.09 689.08 4.25C689.433 4.43 689.713 4.67667 689.92 4.99C690.133 5.29667 690.24 5.65333 690.24 6.06C690.24 6.68 690.017 7.18333 689.57 7.57ZM686.8 7.07C687.08 7.33 687.447 7.46 687.9 7.46C688.353 7.46 688.72 7.33 689 7.07C689.28 6.80333 689.42 6.45667 689.42 6.03C689.42 5.61 689.27 5.26667 688.97 5C688.677 4.73333 688.32 4.6 687.9 4.6C687.48 4.6 687.12 4.73333 686.82 5C686.527 5.26667 686.38 5.61 686.38 6.03C686.38 6.45667 686.52 6.80333 686.8 7.07ZM686.56 2.74C686.56 3.10667 686.687 3.40333 686.94 3.63C687.193 3.85 687.513 3.96 687.9 3.96C688.287 3.96 688.607 3.85 688.86 3.63C689.113 3.40333 689.24 3.10667 689.24 2.74C689.24 2.34667 689.11 2.04333 688.85 1.83C688.59 1.61 688.273 1.5 687.9 1.5C687.527 1.5 687.21 1.61 686.95 1.83C686.69 2.04333 686.56 2.34667 686.56 2.74Z" fill="#383838"/>
        <path d="M777.91 5.96L778.7 6.12C778.647 6.26 778.62 6.38 778.62 6.48C778.62 6.74 778.73 6.96 778.95 7.14C779.17 7.31333 779.487 7.4 779.9 7.4C781.087 7.4 781.68 6.42333 781.68 4.47C781.533 4.81 781.293 5.08 780.96 5.28C780.627 5.48 780.28 5.58 779.92 5.58C779.26 5.58 778.707 5.36 778.26 4.92C777.82 4.48 777.6 3.92333 777.6 3.25C777.6 2.59667 777.827 2.03667 778.28 1.57C778.733 1.09667 779.313 0.859999 780.02 0.859999C780.867 0.859999 781.51 1.17333 781.95 1.8C782.33 2.34667 782.52 3.05667 782.52 3.93C782.52 5.03667 782.403 5.9 782.17 6.52C781.977 7.03333 781.683 7.43333 781.29 7.72C780.897 8 780.433 8.14 779.9 8.14C779.307 8.14 778.81 7.98667 778.41 7.68C778.017 7.36667 777.82 6.96333 777.82 6.47C777.82 6.27 777.85 6.1 777.91 5.96ZM778.89 4.4C779.197 4.69333 779.567 4.84 780 4.84C780.433 4.84 780.803 4.69333 781.11 4.4C781.423 4.1 781.58 3.70667 781.58 3.22C781.58 2.73333 781.423 2.34333 781.11 2.05C780.803 1.75 780.433 1.6 780 1.6C779.567 1.6 779.197 1.75 778.89 2.05C778.59 2.34333 778.44 2.73333 778.44 3.22C778.44 3.70667 778.59 4.1 778.89 4.4Z" fill="#383838"/>
        <path d="M870.55 2.6V1.88C870.93 1.88 871.233 1.81 871.46 1.67C871.687 1.53 871.887 1.30667 872.06 0.999999H872.82V8H871.98V2.13C871.793 2.31 871.59 2.43333 871.37 2.5C871.157 2.56667 870.883 2.6 870.55 2.6Z" fill="#383838"/>
        <path d="M879.432 1.87C879.999 2.54333 880.282 3.42 880.282 4.5C880.282 5.58 879.999 6.45667 879.432 7.13C878.865 7.80333 878.135 8.14 877.242 8.14C876.349 8.14 875.619 7.80333 875.052 7.13C874.485 6.45667 874.202 5.58 874.202 4.5C874.202 3.42 874.485 2.54333 875.052 1.87C875.619 1.19667 876.349 0.859999 877.242 0.859999C878.135 0.859999 878.865 1.19667 879.432 1.87ZM875.652 6.62C876.059 7.14 876.589 7.4 877.242 7.4C877.895 7.4 878.425 7.14 878.832 6.62C879.239 6.1 879.442 5.39333 879.442 4.5C879.442 3.60667 879.239 2.9 878.832 2.38C878.425 1.86 877.895 1.6 877.242 1.6C876.589 1.6 876.059 1.86 875.652 2.38C875.245 2.9 875.042 3.60667 875.042 4.5C875.042 5.39333 875.245 6.1 875.652 6.62Z" fill="#383838"/>
      </g>
      <rect x="828" y="16" width="89" height="2" fill="#FF5711"/>
      <rect x="89" y="18" width="89" height="2" transform="rotate(-180 89 18)" fill="#FF5711"/>
      <rect x="736" y="16" width="89" height="2" fill="#FF5711" fill-opacity="0.8"/>
      <rect x="181" y="18" width="89" height="2" transform="rotate(-180 181 18)" fill="#FF5711" fill-opacity="0.8"/>
      <rect opacity="0.6" x="644" y="16" width="89" height="2" fill="#FF5711"/>
      <rect opacity="0.6" x="273" y="18" width="89" height="2" transform="rotate(-180 273 18)" fill="#FF5711"/>
      <rect x="365" y="18" width="89" height="2" transform="rotate(-180 365 18)" fill="#7F7F7F"/>
      <rect x="641" y="18" width="89" height="2" transform="rotate(-180 641 18)" fill="#7F7F7F"/>
      <rect opacity="0.6" x="457" y="18" width="89" height="2" transform="rotate(-180 457 18)" fill="#7F7F7F"/>
      <rect opacity="0.6" x="549" y="18" width="89" height="2" transform="rotate(-180 549 18)" fill="#7F7F7F"/>
      <path d="M274.5 6L274.5 27" stroke="#F9824E" stroke-dasharray="4 4"/>
      <path d="M642.5 6L642.5 27" stroke="#F9824E" stroke-dasharray="4 4"/>

      <text x="135 " y="30" class="label-text">{{__('svg.low')}}</text>
      <text x="457" y="30" class="label-text">{{__('svg.medium')}}</text>
      <text x="780" y="30" class="label-text">{{__('svg.high')}}</text>
    </symbol>

    <use [attr.x]="142" [attr.y]="0" xlink:href="#header-desktop" width="100%"/>

  </svg>

  <!-- Tablet version -->
  <svg *ngSwitchCase="'tablet'" width="100%" height="100%" viewBox="0 -10 705 80">

    <symbol id="header-tablet" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="611" y="19" width="66" height="2" fill="#FF5711"/>
      <rect x="66" y="21" width="66" height="2" transform="rotate(-180 66 21)" fill="#FF5711"/>
      <rect x="543" y="19" width="66" height="2" fill="#FF5711" fill-opacity="0.8"/>
      <rect x="134" y="21" width="66" height="2" transform="rotate(-180 134 21)" fill="#FF5711" fill-opacity="0.8"/>
      <rect opacity="0.6" x="475" y="19" width="66" height="2" fill="#FF5711"/>
      <rect opacity="0.6" x="202" y="21" width="66" height="2" transform="rotate(-180 202 21)" fill="#FF5711"/>
      <rect x="269" y="21" width="66" height="2" transform="rotate(-180 269 21)" fill="#7F7F7F"/>
      <rect x="473" y="21" width="66" height="2" transform="rotate(-180 473 21)" fill="#7F7F7F"/>
      <rect opacity="0.6" x="337" y="21" width="66" height="2" transform="rotate(-180 337 21)" fill="#7F7F7F"/>
      <rect opacity="0.6" x="405" y="21" width="66" height="2" transform="rotate(-180 405 21)" fill="#7F7F7F"/>
      <path opacity="0.4" d="M31.9404 5.6V4.88C32.3204 4.88 32.6237 4.81 32.8504 4.67C33.077 4.53 33.277 4.30667 33.4504 4H34.2104V11H33.3704V5.13C33.1837 5.31 32.9804 5.43333 32.7604 5.5C32.547 5.56667 32.2737 5.6 31.9404 5.6Z" fill="#383838"/>
      <path opacity="0.4" d="M98.57 10.45L101.27 7.74C101.637 7.37333 101.89 7.05667 102.03 6.79C102.17 6.52333 102.24 6.23 102.24 5.91C102.24 5.51 102.103 5.18667 101.83 4.94C101.563 4.68667 101.22 4.56 100.8 4.56C100.4 4.56 100.067 4.69 99.8 4.95C99.5333 5.21 99.4 5.54667 99.4 5.96C99.4 6.12 99.4267 6.27 99.48 6.41L98.74 6.53C98.66 6.32333 98.62 6.10333 98.62 5.87C98.62 5.33667 98.8267 4.87 99.24 4.47C99.66 4.06333 100.19 3.86 100.83 3.86C101.497 3.86 102.033 4.04333 102.44 4.41C102.853 4.77667 103.06 5.24 103.06 5.8C103.06 6.22 102.967 6.62 102.78 7C102.6 7.37333 102.28 7.79 101.82 8.25L99.82 10.26H103.09V11H98.57V10.45Z" fill="#383838"/>
      <path opacity="0.4" d="M166.71 8.56L167.48 8.75C167.453 8.84333 167.44 8.95667 167.44 9.09C167.44 9.47 167.587 9.78333 167.88 10.03C168.173 10.2767 168.54 10.4 168.98 10.4C169.453 10.4 169.823 10.2767 170.09 10.03C170.363 9.77667 170.5 9.42 170.5 8.96C170.5 8.49333 170.32 8.14667 169.96 7.92C169.607 7.69333 169.137 7.58 168.55 7.58V6.9C169.123 6.9 169.537 6.78333 169.79 6.55C170.05 6.31 170.18 6.01 170.18 5.65C170.18 5.32333 170.057 5.06 169.81 4.86C169.57 4.66 169.26 4.56 168.88 4.56C168.52 4.56 168.213 4.66667 167.96 4.88C167.707 5.09333 167.58 5.37 167.58 5.71C167.58 5.82333 167.597 5.92333 167.63 6.01L166.91 6.12C166.857 5.96 166.83 5.79 166.83 5.61C166.83 5.12333 167.027 4.71 167.42 4.37C167.813 4.03 168.303 3.86 168.89 3.86C169.53 3.86 170.04 4.02 170.42 4.34C170.807 4.66 171 5.06333 171 5.55C171 5.90333 170.9 6.23 170.7 6.53C170.5 6.83 170.21 7.04 169.83 7.16C170.283 7.27333 170.647 7.48 170.92 7.78C171.2 8.08 171.34 8.45667 171.34 8.91C171.34 9.59 171.113 10.1333 170.66 10.54C170.213 10.94 169.653 11.14 168.98 11.14C168.273 11.14 167.7 10.94 167.26 10.54C166.82 10.1333 166.6 9.67 166.6 9.15C166.6 8.92333 166.637 8.72667 166.71 8.56Z" fill="#383838"/>
      <path opacity="0.4" d="M233.49 9.6V8.86L236.94 4H237.78V8.86H238.57V9.6H237.78V11H236.94V9.6H233.49ZM236.94 5.22L234.38 8.86H236.94V5.22Z" fill="#383838"/>
      <path opacity="0.4" d="M301.63 7.78L301.96 4H305.98V4.74H302.63L302.43 7.1C302.623 6.82 302.87 6.60333 303.17 6.45C303.477 6.29667 303.797 6.22 304.13 6.22C304.843 6.22 305.403 6.44667 305.81 6.9C306.223 7.34667 306.43 7.93333 306.43 8.66C306.43 9.38 306.197 9.97333 305.73 10.44C305.27 10.9067 304.667 11.14 303.92 11.14C303.227 11.14 302.663 10.96 302.23 10.6C301.803 10.24 301.59 9.79667 301.59 9.27C301.59 9.07667 301.62 8.91 301.68 8.77L302.45 8.95C302.423 9.04333 302.41 9.13667 302.41 9.23C302.41 9.61 302.55 9.9 302.83 10.1C303.11 10.3 303.473 10.4 303.92 10.4C304.413 10.4 304.813 10.2467 305.12 9.94C305.433 9.62667 305.59 9.2 305.59 8.66C305.59 8.12 305.437 7.69667 305.13 7.39C304.83 7.07667 304.443 6.92 303.97 6.92C303.61 6.92 303.283 7.02333 302.99 7.23C302.697 7.43 302.47 7.67667 302.31 7.97L301.63 7.78Z" fill="#383838"/>
      <path opacity="0.4" d="M374.2 6.04L373.41 5.88C373.463 5.74 373.49 5.62 373.49 5.52C373.49 5.24667 373.38 5.02667 373.16 4.86C372.94 4.68667 372.64 4.6 372.26 4.6C371.72 4.6 371.28 4.87 370.94 5.41C370.607 5.94333 370.423 6.64667 370.39 7.52C370.583 7.17333 370.853 6.90333 371.2 6.71C371.547 6.51667 371.91 6.42 372.29 6.42C372.937 6.42 373.477 6.64 373.91 7.08C374.35 7.52 374.57 8.07667 374.57 8.75C374.57 9.41 374.333 9.97333 373.86 10.44C373.393 10.9067 372.81 11.14 372.11 11.14C371.323 11.14 370.697 10.8567 370.23 10.29C369.77 9.71667 369.54 8.92333 369.54 7.91C369.54 7.07 369.677 6.30333 369.95 5.61C370.17 5.05667 370.483 4.62667 370.89 4.32C371.297 4.01333 371.75 3.86 372.25 3.86C372.87 3.86 373.363 4.01333 373.73 4.32C374.103 4.62667 374.29 5.03 374.29 5.53C374.29 5.69667 374.26 5.86667 374.2 6.04ZM373.25 7.6C372.937 7.30667 372.557 7.16 372.11 7.16C371.663 7.16 371.28 7.30667 370.96 7.6C370.64 7.89333 370.48 8.28667 370.48 8.78C370.48 9.27333 370.64 9.66667 370.96 9.96C371.28 10.2533 371.663 10.4 372.11 10.4C372.557 10.4 372.937 10.2533 373.25 9.96C373.57 9.66667 373.73 9.27333 373.73 8.78C373.73 8.28667 373.57 7.89333 373.25 7.6Z" fill="#383838"/>
      <path opacity="0.4" d="M441.95 4.68L439.12 11H438.3L441.1 4.74H437.43V4H441.95V4.68Z" fill="#383838"/>
      <path opacity="0.4" d="M509.57 10.57C509.13 10.95 508.573 11.14 507.9 11.14C507.227 11.14 506.667 10.95 506.22 10.57C505.78 10.1833 505.56 9.68 505.56 9.06C505.56 8.65333 505.663 8.29667 505.87 7.99C506.083 7.67667 506.367 7.43 506.72 7.25C506.427 7.09 506.197 6.87 506.03 6.59C505.863 6.31 505.78 6.00333 505.78 5.67C505.78 5.14333 505.983 4.71 506.39 4.37C506.797 4.03 507.3 3.86 507.9 3.86C508.5 3.86 509.003 4.03 509.41 4.37C509.817 4.71 510.02 5.14333 510.02 5.67C510.02 6.00333 509.937 6.31 509.77 6.59C509.603 6.87 509.373 7.09 509.08 7.25C509.433 7.43 509.713 7.67667 509.92 7.99C510.133 8.29667 510.24 8.65333 510.24 9.06C510.24 9.68 510.017 10.1833 509.57 10.57ZM506.8 10.07C507.08 10.33 507.447 10.46 507.9 10.46C508.353 10.46 508.72 10.33 509 10.07C509.28 9.80333 509.42 9.45667 509.42 9.03C509.42 8.61 509.27 8.26667 508.97 8C508.677 7.73333 508.32 7.6 507.9 7.6C507.48 7.6 507.12 7.73333 506.82 8C506.527 8.26667 506.38 8.61 506.38 9.03C506.38 9.45667 506.52 9.80333 506.8 10.07ZM506.56 5.74C506.56 6.10667 506.687 6.40333 506.94 6.63C507.193 6.85 507.513 6.96 507.9 6.96C508.287 6.96 508.607 6.85 508.86 6.63C509.113 6.40333 509.24 6.10667 509.24 5.74C509.24 5.34667 509.11 5.04333 508.85 4.83C508.59 4.61 508.273 4.5 507.9 4.5C507.527 4.5 507.21 4.61 506.95 4.83C506.69 5.04333 506.56 5.34667 506.56 5.74Z" fill="#383838"/>
      <path opacity="0.4" d="M572.91 8.96L573.7 9.12C573.647 9.26 573.62 9.38 573.62 9.48C573.62 9.74 573.73 9.96 573.95 10.14C574.17 10.3133 574.487 10.4 574.9 10.4C576.087 10.4 576.68 9.42333 576.68 7.47C576.533 7.81 576.293 8.08 575.96 8.28C575.627 8.48 575.28 8.58 574.92 8.58C574.26 8.58 573.707 8.36 573.26 7.92C572.82 7.48 572.6 6.92333 572.6 6.25C572.6 5.59667 572.827 5.03667 573.28 4.57C573.733 4.09667 574.313 3.86 575.02 3.86C575.867 3.86 576.51 4.17333 576.95 4.8C577.33 5.34667 577.52 6.05667 577.52 6.93C577.52 8.03667 577.403 8.9 577.17 9.52C576.977 10.0333 576.683 10.4333 576.29 10.72C575.897 11 575.433 11.14 574.9 11.14C574.307 11.14 573.81 10.9867 573.41 10.68C573.017 10.3667 572.82 9.96333 572.82 9.47C572.82 9.27 572.85 9.1 572.91 8.96ZM573.89 7.4C574.197 7.69333 574.567 7.84 575 7.84C575.433 7.84 575.803 7.69333 576.11 7.4C576.423 7.1 576.58 6.70667 576.58 6.22C576.58 5.73333 576.423 5.34333 576.11 5.05C575.803 4.75 575.433 4.6 575 4.6C574.567 4.6 574.197 4.75 573.89 5.05C573.59 5.34333 573.44 5.73333 573.44 6.22C573.44 6.70667 573.59 7.1 573.89 7.4Z" fill="#383838"/>
      <path opacity="0.4" d="M638.55 5.6V4.88C638.93 4.88 639.233 4.81 639.46 4.67C639.687 4.53 639.887 4.30667 640.06 4H640.82V11H639.98V5.13C639.793 5.31 639.59 5.43333 639.37 5.5C639.157 5.56667 638.883 5.6 638.55 5.6ZM647.432 4.87C647.999 5.54333 648.282 6.42 648.282 7.5C648.282 8.58 647.999 9.45667 647.432 10.13C646.865 10.8033 646.135 11.14 645.242 11.14C644.349 11.14 643.619 10.8033 643.052 10.13C642.485 9.45667 642.202 8.58 642.202 7.5C642.202 6.42 642.485 5.54333 643.052 4.87C643.619 4.19667 644.349 3.86 645.242 3.86C646.135 3.86 646.865 4.19667 647.432 4.87ZM643.652 9.62C644.059 10.14 644.589 10.4 645.242 10.4C645.895 10.4 646.425 10.14 646.832 9.62C647.239 9.1 647.442 8.39333 647.442 7.5C647.442 6.60667 647.239 5.9 646.832 5.38C646.425 4.86 645.895 4.6 645.242 4.6C644.589 4.6 644.059 4.86 643.652 5.38C643.245 5.9 643.042 6.60667 643.042 7.5C643.042 8.39333 643.245 9.1 643.652 9.62Z" fill="#383838"/>
      <path d="M202.748 9L202.748 30" stroke="#F9824E" stroke-dasharray="3 3"/>
      <path d="M474.033 9L474.033 30" stroke="#F9824E" stroke-dasharray="3 3"/>
    </symbol>

    <text x="115" y="60" class="label-text">{{__('svg.low')}}</text>
    <text x="351" y="60" class="label-text">{{__('svg.medium')}}</text>
    <text x="589" y="60" class="label-text">{{__('svg.high')}}</text>

    <use [attr.x]="14" [attr.y]="30" xlink:href="#header-tablet" width="100%" />
  </svg>

  <!-- Mobile version -->
  <svg *ngSwitchCase="'mobile'" width="100%" height="100%" viewBox="0 0 351 75">
    <symbol id="header-mobile" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="294" y="18" width="32" height="2" fill="#FF5711" />
      <rect x="32" y="20" width="32" height="2" transform="rotate(-180 32 20)" fill="#FF5711" />
      <rect x="261" y="18" width="32" height="2" fill="#FF5711" fill-opacity="0.8" />
      <rect x="65" y="20" width="32" height="2" transform="rotate(-180 65 20)" fill="#FF5711" fill-opacity="0.8" />
      <rect opacity="0.6" x="228" y="18" width="32" height="2" fill="#FF5711" />
      <rect opacity="0.6" x="98" y="20" width="32" height="2" transform="rotate(-180 98 20)" fill="#FF5711" />
      <rect x="131" y="20" width="32" height="2" transform="rotate(-180 131 20)" fill="#7F7F7F" />
      <rect x="227" y="20" width="30" height="2" transform="rotate(-180 227 20)" fill="#7F7F7F" />
      <rect opacity="0.6" x="164" y="20" width="32" height="2" transform="rotate(-180 164 20)" fill="#7F7F7F" />
      <rect opacity="0.6" x="196" y="20" width="31" height="2" transform="rotate(-180 196 20)" fill="#7F7F7F" />
      <path d="M98.5 8L98.5 29" stroke="#F9824E" stroke-dasharray="3 3" />
      <path d="M227.5 8L227.5 29" stroke="#F9824E" stroke-dasharray="3 3" />
      <path opacity="0.4" d="M14.5563 5.5384V4.81019C14.9406 4.81019 15.2474 4.73939 15.4767 4.59779C15.7059 4.4562 15.9082 4.23032 16.0835 3.92015H16.8522V11H16.0026V5.06304C15.8138 5.24509 15.6081 5.36983 15.3856 5.43726C15.1699 5.50469 14.8934 5.5384 14.5563 5.5384Z" fill="#383838"/>
      <path opacity="0.4" d="M46.5765 10.4437L49.3073 7.70281C49.6782 7.33196 49.9344 7.01169 50.076 6.74198C50.2176 6.47227 50.2884 6.17559 50.2884 5.85194C50.2884 5.44738 50.1501 5.12035 49.8737 4.87087C49.604 4.61465 49.2567 4.48654 48.8319 4.48654C48.4274 4.48654 48.0902 4.61802 47.8205 4.88099C47.5508 5.14395 47.416 5.48446 47.416 5.90251C47.416 6.06433 47.4429 6.21605 47.4969 6.35764L46.7484 6.47901C46.6675 6.26999 46.6271 6.04748 46.6271 5.81148C46.6271 5.27207 46.8361 4.80008 47.2541 4.39551C47.6789 3.98421 48.215 3.77855 48.8623 3.77855C49.5366 3.77855 50.0793 3.96398 50.4906 4.33483C50.9087 4.70568 51.1177 5.1743 51.1177 5.74068C51.1177 6.16547 51.0233 6.57004 50.8345 6.95437C50.6525 7.33196 50.3288 7.75338 49.8636 8.21863L47.8408 10.2516H51.1481V11H46.5765V10.4437Z" fill="#383838"/>
      <path opacity="0.4" d="M79.7181 8.53217L80.4969 8.72433C80.4699 8.81873 80.4564 8.93336 80.4564 9.06821C80.4564 9.45255 80.6048 9.76945 80.9014 10.0189C81.1981 10.2684 81.569 10.3932 82.014 10.3932C82.4927 10.3932 82.8669 10.2684 83.1367 10.0189C83.4131 9.76271 83.5513 9.40198 83.5513 8.93673C83.5513 8.46474 83.3693 8.11412 83.0052 7.88487C82.6478 7.65561 82.1724 7.54099 81.5791 7.54099V6.85323C82.159 6.85323 82.577 6.73523 82.8332 6.49924C83.0962 6.2565 83.2277 5.95308 83.2277 5.58897C83.2277 5.25858 83.1029 4.99224 82.8535 4.78996C82.6107 4.58768 82.2972 4.48654 81.9129 4.48654C81.5487 4.48654 81.2386 4.59442 80.9824 4.81019C80.7261 5.02596 80.598 5.30578 80.598 5.64966C80.598 5.76428 80.6149 5.86542 80.6486 5.95308L79.9204 6.06433C79.8664 5.90251 79.8395 5.73057 79.8395 5.54852C79.8395 5.0563 80.0384 4.63825 80.4362 4.29437C80.834 3.95049 81.3296 3.77855 81.923 3.77855C82.5703 3.77855 83.0861 3.94038 83.4704 4.26403C83.8615 4.58768 84.057 4.99561 84.057 5.48783C84.057 5.8452 83.9559 6.17559 83.7536 6.47901C83.5513 6.78243 83.258 6.99483 82.8737 7.1162C83.3322 7.23082 83.6997 7.43985 83.9761 7.74327C84.2593 8.04669 84.4009 8.42766 84.4009 8.88616C84.4009 9.57392 84.1717 10.1234 83.7132 10.5348C83.2614 10.9393 82.695 11.1416 82.014 11.1416C81.2993 11.1416 80.7194 10.9393 80.2744 10.5348C79.8294 10.1234 79.6068 9.65483 79.6068 9.1289C79.6068 8.89964 79.6439 8.70073 79.7181 8.53217Z" fill="#383838"/>
      <path opacity="0.4" d="M112.496 9.58403V8.83559L115.985 3.92015H116.835V8.83559H117.634V9.58403H116.835V11H115.985V9.58403H112.496ZM115.985 5.15407L113.396 8.83559H115.985V5.15407Z" fill="#383838"/>
      <path opacity="0.4" d="M145.637 7.74327L145.971 3.92015H150.037V4.66859H146.649L146.446 7.05551C146.642 6.77232 146.891 6.55318 147.195 6.3981C147.505 6.24302 147.829 6.16547 148.166 6.16547C148.887 6.16547 149.454 6.39473 149.865 6.85323C150.283 7.30499 150.492 7.89835 150.492 8.63331C150.492 9.36152 150.256 9.96162 149.784 10.4336C149.319 10.9056 148.708 11.1416 147.953 11.1416C147.252 11.1416 146.682 10.9595 146.244 10.5954C145.812 10.2313 145.597 9.78294 145.597 9.25027C145.597 9.05473 145.627 8.88616 145.688 8.74456L146.467 8.92662C146.44 9.02101 146.426 9.11541 146.426 9.20981C146.426 9.59414 146.568 9.88745 146.851 10.0897C147.134 10.292 147.502 10.3932 147.953 10.3932C148.452 10.3932 148.857 10.2381 149.167 9.92791C149.484 9.611 149.642 9.17947 149.642 8.63331C149.642 8.08715 149.487 7.65899 149.177 7.34882C148.874 7.03191 148.483 6.87346 148.004 6.87346C147.64 6.87346 147.309 6.97797 147.013 7.187C146.716 7.38928 146.487 7.63876 146.325 7.93544L145.637 7.74327Z" fill="#383838"/>
      <path opacity="0.4" d="M182.259 5.98342L181.46 5.8216C181.514 5.68 181.541 5.55863 181.541 5.45749C181.541 5.18104 181.43 4.95853 181.207 4.78996C180.985 4.61465 180.682 4.527 180.297 4.527C179.751 4.527 179.306 4.80008 178.962 5.34624C178.625 5.88565 178.44 6.59701 178.406 7.4803C178.601 7.12968 178.874 6.8566 179.225 6.66106C179.576 6.46553 179.943 6.36776 180.328 6.36776C180.982 6.36776 181.528 6.59027 181.966 7.03528C182.411 7.4803 182.634 8.04332 182.634 8.72433C182.634 9.39186 182.394 9.96162 181.915 10.4336C181.443 10.9056 180.853 11.1416 180.145 11.1416C179.35 11.1416 178.716 10.855 178.244 10.2819C177.779 9.70203 177.546 8.89964 177.546 7.87475C177.546 7.02517 177.684 6.24976 177.961 5.54852C178.183 4.98887 178.5 4.55397 178.912 4.2438C179.323 3.93364 179.781 3.77855 180.287 3.77855C180.914 3.77855 181.413 3.93364 181.784 4.2438C182.162 4.55397 182.35 4.9619 182.35 5.4676C182.35 5.63617 182.32 5.80811 182.259 5.98342ZM181.298 7.56122C180.982 7.26454 180.597 7.1162 180.145 7.1162C179.694 7.1162 179.306 7.26454 178.982 7.56122C178.659 7.8579 178.497 8.25572 178.497 8.75468C178.497 9.25364 178.659 9.65146 178.982 9.94814C179.306 10.2448 179.694 10.3932 180.145 10.3932C180.597 10.3932 180.982 10.2448 181.298 9.94814C181.622 9.65146 181.784 9.25364 181.784 8.75468C181.784 8.25572 181.622 7.8579 181.298 7.56122Z" fill="#383838"/>
      <path opacity="0.4" d="M214.006 4.60791L211.144 11H210.315L213.147 4.66859H209.435V3.92015H214.006V4.60791Z" fill="#383838"/>
      <path opacity="0.4" d="M244.622 10.5651C244.177 10.9494 243.614 11.1416 242.933 11.1416C242.252 11.1416 241.686 10.9494 241.234 10.5651C240.789 10.174 240.566 9.66494 240.566 9.03787C240.566 8.62656 240.671 8.26583 240.88 7.95566C241.096 7.63876 241.382 7.38928 241.74 7.20722C241.443 7.0454 241.21 6.82289 241.042 6.5397C240.873 6.2565 240.789 5.94634 240.789 5.6092C240.789 5.07653 240.995 4.63825 241.406 4.29437C241.817 3.95049 242.326 3.77855 242.933 3.77855C243.54 3.77855 244.049 3.95049 244.46 4.29437C244.872 4.63825 245.077 5.07653 245.077 5.6092C245.077 5.94634 244.993 6.2565 244.824 6.5397C244.656 6.82289 244.423 7.0454 244.127 7.20722C244.484 7.38928 244.767 7.63876 244.976 7.95566C245.192 8.26583 245.3 8.62656 245.3 9.03787C245.3 9.66494 245.074 10.174 244.622 10.5651ZM241.821 10.0594C242.104 10.3224 242.475 10.4538 242.933 10.4538C243.392 10.4538 243.762 10.3224 244.046 10.0594C244.329 9.78968 244.47 9.43906 244.47 9.00753C244.47 8.58274 244.319 8.23549 244.015 7.96578C243.719 7.69607 243.358 7.56122 242.933 7.56122C242.508 7.56122 242.144 7.69607 241.841 7.96578C241.544 8.23549 241.396 8.58274 241.396 9.00753C241.396 9.43906 241.537 9.78968 241.821 10.0594ZM241.578 5.68C241.578 6.05085 241.706 6.3509 241.962 6.58015C242.218 6.80266 242.542 6.91392 242.933 6.91392C243.324 6.91392 243.648 6.80266 243.904 6.58015C244.16 6.3509 244.288 6.05085 244.288 5.68C244.288 5.28218 244.157 4.97539 243.894 4.75962C243.631 4.53711 243.311 4.42585 242.933 4.42585C242.555 4.42585 242.235 4.53711 241.972 4.75962C241.709 4.97539 241.578 5.28218 241.578 5.68Z" fill="#383838"/>
      <path opacity="0.4" d="M273.841 8.93673L274.64 9.09855C274.586 9.24015 274.559 9.36152 274.559 9.46266C274.559 9.72563 274.67 9.94814 274.892 10.1302C275.115 10.3055 275.435 10.3932 275.853 10.3932C277.053 10.3932 277.654 9.40535 277.654 7.42973C277.505 7.77361 277.262 8.04669 276.925 8.24897C276.588 8.45125 276.238 8.5524 275.873 8.5524C275.206 8.5524 274.646 8.32989 274.195 7.88487C273.75 7.43985 273.527 6.87683 273.527 6.19582C273.527 5.53503 273.756 4.96864 274.215 4.49665C274.673 4.01792 275.26 3.77855 275.975 3.77855C276.831 3.77855 277.482 4.09546 277.927 4.72928C278.311 5.28218 278.503 6.00028 278.503 6.88357C278.503 8.00286 278.385 8.87605 278.149 9.50312C277.954 10.0223 277.657 10.4269 277.259 10.7168C276.861 11 276.393 11.1416 275.853 11.1416C275.253 11.1416 274.751 10.9865 274.346 10.6763C273.948 10.3594 273.75 9.95151 273.75 9.45255C273.75 9.25027 273.78 9.07833 273.841 8.93673ZM274.832 7.35894C275.142 7.65561 275.516 7.80395 275.954 7.80395C276.393 7.80395 276.767 7.65561 277.077 7.35894C277.394 7.05551 277.552 6.65769 277.552 6.16547C277.552 5.67326 277.394 5.27881 277.077 4.98213C276.767 4.67871 276.393 4.527 275.954 4.527C275.516 4.527 275.142 4.67871 274.832 4.98213C274.528 5.27881 274.377 5.67326 274.377 6.16547C274.377 6.65769 274.528 7.05551 274.832 7.35894Z" fill="#383838"/>
      <path opacity="0.4" d="M304.55 5.67986V4.95986C304.93 4.95986 305.233 4.88986 305.46 4.74986C305.687 4.60986 305.887 4.38653 306.06 4.07986H306.82V11.0799H305.98V5.20986C305.793 5.38986 305.59 5.5132 305.37 5.57986C305.157 5.64653 304.883 5.67986 304.55 5.67986ZM313.432 4.94986C313.999 5.6232 314.282 6.49986 314.282 7.57986C314.282 8.65986 313.999 9.53653 313.432 10.2099C312.865 10.8832 312.135 11.2199 311.242 11.2199C310.349 11.2199 309.619 10.8832 309.052 10.2099C308.485 9.53653 308.202 8.65986 308.202 7.57986C308.202 6.49986 308.485 5.6232 309.052 4.94986C309.619 4.27653 310.349 3.93986 311.242 3.93986C312.135 3.93986 312.865 4.27653 313.432 4.94986ZM309.652 9.69986C310.059 10.2199 310.589 10.4799 311.242 10.4799C311.895 10.4799 312.425 10.2199 312.832 9.69986C313.239 9.17986 313.442 8.4732 313.442 7.57986C313.442 6.68653 313.239 5.97986 312.832 5.45986C312.425 4.93986 311.895 4.67986 311.242 4.67986C310.589 4.67986 310.059 4.93986 309.652 5.45986C309.245 5.97986 309.042 6.68653 309.042 7.57986C309.042 8.4732 309.245 9.17986 309.652 9.69986Z" fill="#383838"/>
    </symbol>

    <text x="60" y="60" class="label-text">{{__('svg.low')}}</text>
    <text x="176" y="60" class="label-text">{{__('svg.medium')}}</text>
    <text x="288" y="60" class="label-text">{{__('svg.high')}}</text>

    <use [attr.x]="11" [attr.y]="30" xlink:href="#header-mobile" width="100%" />
  </svg>
</ng-container>




