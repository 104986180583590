import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TestIds, TestIdStore} from './test-id.store';
import {TestStore} from './test.store';
import {CurrentUser} from '../../user/current-user.service';
import {Test} from './test';
import {TestRepresentation} from './test-representation';
import {map, tap} from 'rxjs/operators';
import {PersonalDataBeforeTest} from './personal-data-before-test';
import {PersonalDataBeforeTestRepresentation} from './personal-data-before-test-representation';
import {PersonalDataAfterTest} from './personal-data-after-test';
import {PersonalDataAfterTestRepresentation} from './personal-data-after-test-representation';
import {ItemId} from './item-id';
import {Item} from './item';
import {AnsweredItemRepresentation} from './answered-item-representation';
import {Answer} from './answer-type';
import {TestVariation} from './test-variation';
import {TestVariationNameRepresentation} from './test-variation-name-representation';
import {AccountDataRepresentation} from '../../account/account-data-representation';
import {AccountDataStore} from '../../account/account-data-store';
import {ActiveUser} from '../../user/active-user';
import {ActiveUserBuilder, ActiveUserRepresentation} from '../../user/active-user-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(private testIdStore: TestIdStore,
              private testStore: TestStore,
              private accountDataStore: AccountDataStore,
              private currentUser: CurrentUser,
              private http: HttpClient) {
  }

  public loadTest(userId: string, testId: string): Observable<Test> {
    return this.http.get<TestRepresentation>(
      API_URL + '/tests/' + userId + '/' + testId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((testRepresentation: TestRepresentation) =>
        TestRepresentation.toModelEntity(testRepresentation)
      ),
      tap((test: Test) => {
        this.testIdStore.upsert(userId, (oldState: TestIds | undefined) => {
          if (!oldState || !oldState.ids) {
            return {
              ids: [testId],
              loadedAll: false
            };
          }
          return oldState.ids.includes(testId) ? oldState : {
            ids: oldState.ids.concat(testId),
            loadedAll: oldState.loadedAll
          };
        });
        this.storeTest(test, userId);
      })
    );
  }

  public loadTests(userId: string): Observable<Test[]> {
    return this.http.get<TestRepresentation[]>(
      API_URL + '/tests/' + userId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((testRepresentations: TestRepresentation[]) =>
        testRepresentations.map(testRepresentation => TestRepresentation.toModelEntity(testRepresentation))
      ),
      tap((tests: Test[]) => {
        this.testIdStore.upsert(userId, {
          ids: tests.map(test => test.testId),
          loadedAll: true
        });
        tests.forEach((test: Test) => this.storeTest(test, userId));
      })
    );
  }

  public savePersonalDataBeforeTest(personalData: PersonalDataBeforeTest): Observable<void> {
    return this.http.post<AccountDataRepresentation>(
      API_URL + '/test/personaldata/before',
      PersonalDataBeforeTestRepresentation.fromModelEntity(personalData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(
      map(accountDataRepresentation => AccountDataRepresentation.toModelEntity(accountDataRepresentation)),
      tap(accountData => this.accountDataStore.upsert(this.currentUser.keycloakId, accountData)),
      map(() => null)
    );
  }

  public loadPersonalDataBeforeTest(): Observable<PersonalDataBeforeTest> {
    return this.http.get<PersonalDataBeforeTestRepresentation>(
      API_URL + '/test/personaldata/before',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(map((personalDataBeforeTestRepresentation: PersonalDataBeforeTestRepresentation) =>
      PersonalDataBeforeTestRepresentation.toModelEntity(personalDataBeforeTestRepresentation)
    ));
  }

  public savePersonalDataAfterTest(personalData: PersonalDataAfterTest): Observable<void> {
    return this.http.post<AccountDataRepresentation>(
      API_URL + '/test/personaldata/after',
      PersonalDataAfterTestRepresentation.fromModelEntity(personalData),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(
      map(accountDataRepresentation => AccountDataRepresentation.toModelEntity(accountDataRepresentation)),
      tap(accountData => this.accountDataStore.upsert(this.currentUser.keycloakId, accountData)),
      map(() => null)
    );
  }

  public loadPersonalDataAfterTest(): Observable<PersonalDataAfterTest> {
    return this.http.get<PersonalDataAfterTestRepresentation>(
      API_URL + '/test/personaldata/after',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(map((personalDataAfterTestRepresentation: PersonalDataAfterTestRepresentation) =>
      PersonalDataAfterTestRepresentation.toModelEntity(personalDataAfterTestRepresentation)
    ));
  }

  public loadAvailableItemIds(testId: string): Observable<ItemId[]> {
    return this.http.get<ItemId[]>(
      API_URL + '/test/' + testId + '/items/ids',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }

  public loadItems(testId: string, itemIds: ItemId[]): Observable<Item[]> {
    return this.http.post<AnsweredItemRepresentation[]>(
      API_URL + '/test/' + testId + '/items',
      itemIds,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(map((answeredItemRepresentations: AnsweredItemRepresentation[]) =>
      answeredItemRepresentations.map(answeredItemRepresentation =>
        Item.buildFromAnsweredItemRepresentation(answeredItemRepresentation)
      )
    ));
  }

  public saveAnswer(testId: string, itemId: ItemId, answer: Answer): Observable<void> {
    return this.http.post<TestRepresentation | null>(
      API_URL + '/test/' + testId + '/items/answer',
      {itemId, answer},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(
      tap((testRepresentation: TestRepresentation | null) => {
        if (!!testRepresentation) {
          const test = TestRepresentation.toModelEntity(testRepresentation);
          this.storeTest(test);
        }
      }),
      map(() => null)
    );
  }

  public updateVariation(testId: string, newVariation: TestVariation): Observable<Test> {
    return this.http.put<TestRepresentation>(
      API_URL + '/tests/' + testId + '/variation',
      TestVariationNameRepresentation.of(newVariation),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((testRepresentation: TestRepresentation) => TestRepresentation.toModelEntity(testRepresentation)),
      tap((test: Test) => this.storeTest(test))
    );
  }

  public setTestFinished(testId: string): Observable<Test> {
    return this.http.post<TestRepresentation>(
      API_URL + '/tests/' + testId + '/finished',
      null,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((testRepresentation: TestRepresentation) => TestRepresentation.toModelEntity(testRepresentation)),
      tap((test: Test) => this.storeTest(test))
    );
  }

  public resetTest(testId: string): Observable<Test> {
    return this.http.post<TestRepresentation>(
      API_URL + '/tests/' + testId + '/reset',
      null,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((testRepresentation: TestRepresentation) => TestRepresentation.toModelEntity(testRepresentation)),
      tap((test: Test) => this.storeTest(test))
    );
  }

  public getAffiliate(testId: string): Observable<ActiveUser | null> {
    return this.http.get<ActiveUserRepresentation | null>(
      API_URL + '/tests/' + testId + '/affiliate',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((activeUserRepresentation: ActiveUserRepresentation | null) => {
        if (activeUserRepresentation) {
          return ActiveUserBuilder.toModelEntity(activeUserRepresentation);
        }
        return null;
      })
    );
  }

  private storeTest(test: Test, userId?: string): void {
    if (!userId) {
      userId = this.currentUser.keycloakId;
    }
    this.testStore.upsert([userId, test.testId].join(':'), test);
  }

}
