<ng-container *ngIf="!friendship; else withFriendship">
  <app-centered-loading-indicator></app-centered-loading-indicator>
</ng-container>

<ng-template #withFriendship>
  <div class="clr-row clr-justify-content-center margin-0">
    <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
      <div class="view">

        <div class="avatar-title-container">
          <id37-avatar-distance-circle
              (circularRingProgressChange)="currentRingProgress = $event"
              [circularRingCircleSizePx]="100"
              [circularRingCircleStrokeWidth]="7.5"
              [circularRingProgress]="distance"
              [circularRingRender]="true"
              [profilePictureAllowAccountDataRetrieval]="true"
              [profilePictureHeight]="'4em'"
              [profilePictureUserId]="friend.id"
              [profilePictureWidth]="'4em'"
          ></id37-avatar-distance-circle>

          <div class="title-text-container">
            <h2>{{__('headline')}}</h2>
            <div class="id37-text3 arrow-distance-container">
              <span id="arrow"><img alt="green arrow" src="assets/icons/arrow_green_18x18.svg"></span>
              <span id="distance">{{currentRingProgress * 100 | number:'1.0-0'}}%</span>
              <span>{{__('different')}}</span>
            </div>
          </div>
        </div>

          <div class="id37-text2 text-light" id="upper-intro-text">
              <span [innerHTML]="__('intro.text1')"></span>
              <span [innerHTML]="__('intro.text2')"></span>
              <span [innerHTML]="__('intro.text3')"></span>
          </div>

        <app-distance-graphic-page
            *ngIf="!!currentUserMotiveScores && !!friendMotiveScores && friendGender !== undefined"
            [friendGender]="friendGender"
            [results]="[currentUserMotiveScores, friendMotiveScores]"
            [distance]="distance"
            [friendId]="friend.id"
            [friendFirstname]="friendFirstName"
            [friendFullName]="friendFullName"
            [activeFriendship]="friendship">
        </app-distance-graphic-page>
      </div>

    </div>
  </div>
</ng-template>

<!-- CLOSE -->
<div class="hide-on-print" id="close-sign">
  <img (click)="close()" alt="close sign" src="assets/icons/ID37_X.svg">
</div>
