import {BackendUserRepresentation} from './backend-user-representation';

export class BackendUser {

  private readonly _language: string;
  private readonly _keycloakId: string;
  private readonly _masterKeycloakId: string;
  private readonly _partnerKeycloakId: string;

  constructor(language: string,
              keycloakId: string,
              masterKeycloakId: string,
              partnerKeycloakId: string) {
    this._language = language;
    this._keycloakId = keycloakId;
    this._masterKeycloakId = masterKeycloakId;
    this._partnerKeycloakId = partnerKeycloakId;
  }

  get language(): string {
    return this._language;
  }

  get keycloakId(): string {
    return this._keycloakId;
  }

  get masterKeycloakId(): string | null {
    return this._masterKeycloakId;
  }

  get partnerKeycloakId(): string | null {
    return this._partnerKeycloakId;
  }

  static buildFrom(backendUserRepresentation: BackendUserRepresentation): BackendUser {
    return new BackendUser(
      backendUserRepresentation.language,
      backendUserRepresentation.keycloakId,
      backendUserRepresentation.masterKeycloakId,
      backendUserRepresentation.partnerKeycloakId
    );
  }

}
