import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TeamanalysisService} from 'src/app/master/teamanalysis.service';
import {Team} from '../../../team/state/team';
import {Layout} from '../../../team/team.component';
import {Subscription} from 'rxjs';
import {TeamService} from '../../../team/state/team.service';
import {TranslatorService} from '../../../translation/translator.service';
import {TeamQuery} from '../../../team/state/team.query';

@Component({
  selector: 'app-team-graphic',
  templateUrl: './team-graphic.component.html',
  styleUrls: ['./team-graphic.component.scss']
})
export class TeamGraphicComponent implements OnInit, OnDestroy {

  @Input() team: Team;
  @Input() maxTestVariation: number;
  @Input() layout: Layout;

  bubblesChangedSub: Subscription;
  motivesChangedSub: Subscription;

  changed: boolean = false;
  listIsChanged: boolean = false;
  bubblesChanged: boolean = false;
  motivesChanged: boolean = false;

  selectedVariation: number = 14;

  constructor(private teamAnalysisService: TeamanalysisService,
              private teamService: TeamService,
              private teamQuery: TeamQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.bubblesChangedSub = this.teamService.bubblesHidden.subscribe((changed) => {
      this.bubblesChanged = changed;
      this.checkChanged();
    });
    this.motivesChangedSub = this.teamService.motivesHidden.subscribe(() => {
      this.motivesChanged = true;
      this.checkChanged();
    });
    this.teamQuery.selectSelectedTeam().subscribe((t) => {
      if (this.team.teamId !== t.teamId) {
        this.selectedVariation = 14;
        this.teamService.motivesVariationChanged.next(this.selectedVariation);
      }
    });
  }

  ngOnDestroy(): void {
    this.bubblesChangedSub.unsubscribe();
  }

  downloadExcelForTeam(): void {
    this.teamAnalysisService.downloadExcelForIds(this.team.users.map((user) => user.keycloakId));
  }

  listChanged(changed: boolean): void {
    this.listIsChanged = changed;
    this.checkChanged();
  }

  reset(): void {
    if (this.changed) {
      this.teamService.resetTeamView();
      this.listIsChanged = false;
      this.bubblesChanged = false;
      this.motivesChanged = false;
      this.changed = false;
      this.selectedVariation = 14;
      this.teamService.motivesVariationChanged.next(this.selectedVariation);
    }
  }

  __(key: string): string {
    return this.translatorService.translate('teamGraphic.' + key);
  }

  changeTestvariation(variation: number) {
    if (variation !== this.selectedVariation) {
      this.selectedVariation = variation;
      this.teamService.motivesVariationChanged.next(variation);
    }
  }

  private checkChanged(): void {
    this.changed = this.listIsChanged || this.bubblesChanged || this.motivesChanged;
  }
}
