import {Moment} from 'moment';
import {ChatbotDocument} from './chatbot-document';
import {
  ChatbotMessagePairCreateRepresentation,
  ChatbotMessagePairRepresentation
} from './chatbot-message-pair-representation';

export class MessagePairBase {
  private readonly _chat_id: number;
  private readonly _question: string;

  private readonly _timestamp: Moment;


  constructor(chat_id: number, question: string, timestamp: moment.Moment) {
    this._chat_id = chat_id;
    this._question = question;
    this._timestamp = timestamp;
  }

  get chat_id(): number {
    return this._chat_id;
  }

  get question(): string {
    return this._question;
  }

  get timestamp(): moment.Moment {
    return this._timestamp;
  }
}

export class ChatbotMessagePairCreate extends MessagePairBase {
  static buildFrom(
    chatbotMessagePairCreateRepresentation: ChatbotMessagePairCreateRepresentation
  ): ChatbotMessagePairCreate {
    return new ChatbotMessagePairCreate(
      chatbotMessagePairCreateRepresentation.chat_id,
      chatbotMessagePairCreateRepresentation.question,
      chatbotMessagePairCreateRepresentation.timestamp
    );
  }
}

export class ChatbotMessagePair extends MessagePairBase {
  private readonly _question_id: number;
  private _answer: string;
  private _answer_rating: number | undefined;
  private _comment: string | undefined;
  private readonly _relevant_documents: ChatbotDocument[];


  constructor(chat_id: number, question: string, timestamp: moment.Moment, question_id: number, answer: string,
              answer_rating: number | undefined, comment: string | undefined, relevant_documents: ChatbotDocument[]) {
    super(chat_id, question, timestamp);
    this._question_id = question_id;
    this._answer = answer;
    this._answer_rating = answer_rating;
    this._comment = comment;
    this._relevant_documents = relevant_documents;
  }

  get answer(): string {
    return this._answer;
  }

  set answer(value: string) {
    this._answer = value;
  }

  get question_id(): number {
    return this._question_id;
  }

  get relevant_documents(): ChatbotDocument[] {
    return this._relevant_documents;
  }

  get answer_rating(): number | undefined {
    return this._answer_rating;
  }

  set answer_rating(value: number) {
    this._answer_rating = value;
  }

  get comment(): string | undefined {
    return this._comment;
  }
  set comment(value: string | undefined) {
    this._comment = value;
  }


  static buildFrom(
    chatbotMessagePairRepresentation: ChatbotMessagePairRepresentation
  ): ChatbotMessagePair {
    return new ChatbotMessagePair(
      chatbotMessagePairRepresentation.chat_id,
      chatbotMessagePairRepresentation.question,
      chatbotMessagePairRepresentation.timestamp,
      chatbotMessagePairRepresentation.question_id,
      chatbotMessagePairRepresentation.answer,
      chatbotMessagePairRepresentation.answer_rating,
      chatbotMessagePairRepresentation.comment,
      chatbotMessagePairRepresentation.relevant_documents
    );
  }
}
