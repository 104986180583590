import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ItemComponent} from '../item/item.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ItemSelectEvent} from '../item/item-select-event';
import {TranslatorService} from '../../../translation/translator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProgressItem} from '../../../id37/progress-box/progress-item';
import {ProgressSwitchEvent} from '../../../id37/progress-box/progress-switch-event';
import {ScrollService} from '../../../id37/scroll.service';
import {ItemId} from '../../state/item-id';
import {TestQuery} from '../../state/test.query';
import {TestService} from '../../state/test.service';
import {Test} from '../../state/test';
import {Item} from '../../state/item';
import {CurrentUser} from '../../../user/current-user.service';
import {LocaleService} from '../../../user/locale.service';

@Component({
  selector: 'app-item-page',
  templateUrl: './item-page.component.html',
  styleUrls: ['./item-page.component.scss']
})
export class ItemPageComponent implements OnInit, OnDestroy {

  @ViewChildren(ItemComponent)
    itemComponents: QueryList<ItemComponent>;
  testId: string;
  accessGranted: boolean = false;
  availableProgressItems: ProgressItem<number>[];
  availableItemIds: ItemId[];
  availablePageNumbers: number;
  currentPageNumber: number;
  lastFilledOutPageNumber: number;
  backButtonActive: boolean;
  continueButtonActive: boolean;
  items: unknown = {};
  /*
   * Manual setting:
   * How many items will be displayed on each page. Should be set to 9, as each motive contains 9 items.
   */
  itemsPerPage = 9;
  /*
   * Manual setting:
   * Defines how fast the application switches to the next page if the current page is already fully answered.
   */
  viewChildrenAccessDelay = 0;
  /*
   * Manual setting:
   * Tells whether the page is allowed to scroll to each newly selected item. Some selections are made programmatically.
   * Should be set to true. Remember: Scrolling on mouse clicks can be set later.
   */
  autoScrollAllowed = true;
  /*
   * Manual setting:
   * Tells whether the page is allowed to jump to the next page of items if the current page is fully answered.
   * This might irritate the user and will render the continue button obsolete. This setting should therefore be set to false.
   */
  autoPageJump = false;
  autoScrollOnError = true;
  pageJumpOnInitialLoad = true;
  autoScrollOnInitialLoad = true;
  selectBestFromTopOnInitialLoad = true;
  pageJumpOnPageKnobClick = false;
  autoScrollOnPageKnobClick = true;
  autoScrollOnKeyUp = true;
  autoScrollOnKeyDown = true;
  pageJumpOnSwitchToPreviousPage = false;
  autoScrollOnSwitchToPreviousPage = true;
  pageJumpOnSwitchToNextPage = false;
  autoScrollOnSwitchToNextPage = true;
  reselectOnClick = true;
  autoScrollOnClick = false;
  reselectOnNumberKey = true;
  autoScrollOnNumberKey = true;
  reselectOnArrowKey = false;
  autoScrollOnArrowKey = false;
  ongoingRequestToFinishTest: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private currentUser: CurrentUser,
              private localeService: LocaleService,
              private testQuery: TestQuery,
              private testService: TestService,
              private scrollService: ScrollService,
              private translatorService: TranslatorService,
              private route: ActivatedRoute,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (!!window.history.state.language && window.history.state.language !== this.currentUser.locale) {
      this.localeService.updateLanguage(window.history.state.language as string)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          location.reload();
        });
    }
    this.route.params.subscribe(params => {
      this.testId = params.testId;
    });

    this.checkIfUserHasAccess(() => {
      // We normally want to start on page 1. Could be altered if we inspect that the user already answered items.
      this.currentPageNumber = this.lastFilledOutPageNumber = 1;
      // Going back a page should generally be allowed.
      this.backButtonActive = true;
      // Going to the next page is generally not allowed. Only after all items got answered.
      this.continueButtonActive = false;

      this.testService.loadAvailableItemIds(this.testId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((itemIds: ItemId[]) => {
          // Store the item ids we can work with.
          this.availableItemIds = itemIds;

          // The amount of available page numbers depend on the total amount of items which are to be displayed to the user.
          this.availablePageNumbers = this.availableItemIds.length / this.itemsPerPage;

          this.availableProgressItems = [];
          for (let i = 1; i <= this.availablePageNumbers; i++) {
            this.availableProgressItems.push(new ProgressItem<number>('page', i));
          }

          // Load the first items.
          this.switchToPage(
            this.currentPageNumber,
            this.pageJumpOnInitialLoad, this.autoScrollOnInitialLoad, this.selectBestFromTopOnInitialLoad
          );
          // this.loadItems(this.pageJumpOnInitialLoad, this.autoScrollOnInitialLoad, this.selectBestFromTopOnInitialLoad);
        });
    }, () => {
      console.error('Forbidden!');
      this.router.navigate(['/dashboard']);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:keydown', ['$event'])
  keyEventOverrideDefaults(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' || event.key === 'Backspace') {
      event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.selectPreviousItem(null, this.autoScrollOnKeyUp, true);
    }
    if (event.key === 'ArrowDown') {
      this.selectNextItem(null, this.autoScrollOnKeyDown, true);
    }
    if (event.key === 'Enter') {
      this.goToNextPage(true);
    }
    if (event.key === 'Backspace') {
      this.goToPreviousPage(true);
    }
  }

  checkIfUserHasAccess(allowed: () => void, notAllowed: () => void): void {
    this.testQuery.selectTestForCurrentUser(this.testId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (test: Test) => {
          // We can't check against !test.started, as the test is only considered to be "started" after the first item was answered!
          if (test.finished) {
            this.router.navigate(['/my-test', this.testId]);
          }
          else {
            this.accessGranted = true;
            allowed();
          }
        },
        () => {
          this.accessGranted = false;
          notAllowed();
        }
      );
  }

  processClickOnPageKnob(progressSwitchEvent: ProgressSwitchEvent<number>): void {
    const pageNumber: number = progressSwitchEvent.item.value;
    if (pageNumber <= this.lastFilledOutPageNumber) {
      this.switchToPage(pageNumber, this.pageJumpOnPageKnobClick, this.autoScrollOnPageKnobClick, true);
    }
  }

  goToPreviousPage(selectBestFromTop: boolean): void {
    // Only let the user go back if the back button was armed.
    if (!this.backButtonActive) {
      return;
    }
    if (this.currentPageNumber <= 1) {
      this.router.navigate(['/my-test', this.testId]);
    }
    this.switchToPage(
      this.currentPageNumber + -1, this.pageJumpOnSwitchToPreviousPage,
      this.autoScrollOnSwitchToPreviousPage, selectBestFromTop
    );
  }

  goToNextPage(selectBestFromTop: boolean): void {
    // Only let the user advance if the button was armed.
    if (!this.continueButtonActive) {
      this.selectFirstUnansweredItemComponentAndShowError(this.autoScrollOnError);
      return;
    }
    // Let the parent component switch to the next part of the test if we are already on the last site of items.
    if (this.currentPageNumber === this.availablePageNumbers) {
      if (!this.ongoingRequestToFinishTest) {
        this.ongoingRequestToFinishTest = true;
        this.testService.setTestFinished(this.testId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => this.router.navigate(['/my-test', this.testId, 'thank-you']),
            () => this.ongoingRequestToFinishTest = false
          );
      }
      return;
    }
    // Just for safety.
    if (this.currentPageNumber >= this.availablePageNumbers) {
      console.log('Unable to go to the next page. We are already at the last page.');
      return;
    }
    this.switchToPage(
      this.currentPageNumber + 1, this.pageJumpOnSwitchToNextPage,
      this.autoScrollOnSwitchToNextPage, selectBestFromTop
    );
  }

  answerChangedThroughClick(itemComponent: ItemComponent): void {
    this.answerChanged(itemComponent, this.reselectOnClick, this.autoScrollOnClick);
  }

  answerChangedThroughNumberKey(itemComponent: ItemComponent): void {
    this.answerChanged(itemComponent, this.reselectOnNumberKey, this.autoScrollOnNumberKey);
  }

  answerChangedThroughArrowKey(itemComponent: ItemComponent): void {
    this.answerChanged(itemComponent, this.reselectOnArrowKey, this.autoScrollOnArrowKey);
  }

  answerSaved(itemComponent: ItemComponent): void {
    this.updateButtonState();
  }

  answerCouldNotBeSaved(itemComponent: ItemComponent): void {
    this.selectItemComponentForItem(itemComponent.item, this.autoScrollOnError);
  }

  itemComponentGotSelected(selectEvent: ItemSelectEvent, index: number): void {
    this.deselectOthers(selectEvent.itemComponent);
    if (this.autoScrollAllowed && selectEvent.autoScrollDesired) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      this.scrollService.scrollToElement('item-' + index, 'middle');
    }
  }

  anyAnswersGiven(): boolean {
    if (typeof this.itemComponents === typeof undefined) {
      return false;
    }
    if (this.itemComponents.length === 0) {
      return false;
    }
    let answersGiven = false;
    this.itemComponents.forEach(comp => {
      if (comp.isAnswerSaved()) {
        answersGiven = true;
        return true;
      }
    });
    return answersGiven;
  }

  allAnswersGiven(): boolean {
    if (typeof this.itemComponents === typeof undefined) {
      return false;
    }
    if (this.itemComponents.length === 0) {
      return false;
    }
    let allAnswersGiven = true;
    this.itemComponents.forEach(comp => {
      if (!comp.isAnswerSaved()) {
        allAnswersGiven = false;
      }
    });
    return allAnswersGiven;
  }

  selectItemComponentForItem(item: Item, autoScrollDesired: boolean): void {
    this.itemComponents.forEach((component) => {
      if (component.item.id === item.id) {
        component.select(autoScrollDesired);
      }
    });
  }

  selectFirstItemComponent(autoScrollDesired: boolean): ItemComponent {
    const itemComponent = this.itemComponents.first;
    itemComponent.select(autoScrollDesired);
    this.deselectOthers(itemComponent);
    return itemComponent;
  }

  selectLastItemComponent(autoScrollDesired: boolean): ItemComponent {
    const itemComponent = this.itemComponents.last;
    itemComponent.select(autoScrollDesired);
    this.deselectOthers(itemComponent);
    return itemComponent;
  }

  /**
   * Returns the selected item component if there was an unanswered item which got selected. Otherwise returns null.
   */
  selectFirstUnansweredItemComponent(autoScrollDesired: boolean): ItemComponent {
    const firstUnselectedComponent: ItemComponent = this.findFirstUnansweredItemComponent();
    if (firstUnselectedComponent) {
      firstUnselectedComponent.select(autoScrollDesired);
      this.deselectOthers(firstUnselectedComponent);
      return firstUnselectedComponent;
    }
    else {
      // console.log('There is no unanswered item to select!');
      return null;
    }
  }

  /**
   * Returns whether a component got selected.
   */
  selectFirstUnansweredItemComponentAndShowError(autoScrollDesired: boolean): ItemComponent {
    const selectedComponent = this.selectFirstUnansweredItemComponent(autoScrollDesired);
    if (selectedComponent !== null) {
      selectedComponent.showErrorMessage(this.__('errorItemMustBeAnswered'), ItemComponent.DEFAULT_MSG_DURATION);
    }
    return selectedComponent;
  }

  selectBestItemComponent(autoScrollDesired: boolean, selectBestFromTop: boolean): ItemComponent {
    // Select the first unanswered item if one is present.
    const firstUnanswered = this.selectFirstUnansweredItemComponent(autoScrollDesired);
    if (firstUnanswered !== null) {
      return firstUnanswered;
    }
    // Otherwise select the first item on the current page.
    if (selectBestFromTop) {
      return this.selectFirstItemComponent(autoScrollDesired);
    }
    return this.selectLastItemComponent(autoScrollDesired);
  }

  deselectOthers(currentlySelected: ItemComponent): void {
    this.itemComponents.forEach((component) => {
      if (component !== currentlySelected && typeof component !== typeof undefined) {
        component.deselect();
      }
    });
  }

  getItemsOnCurrentPage(): Item[] {
    return this.items[this.currentPageNumber] !== undefined ? this.items[this.currentPageNumber] : [];
  }

  onFirstPage(): boolean {
    return this.currentPageNumber === 1;
  }

  onLastPage(): boolean {
    return this.currentPageNumber === this.availablePageNumbers;
  }

  nextPageNumber(): number {
    return this.currentPageNumber + 1;
  }

  __(key: string): string {
    return this.translatorService.translate('my-test.items.' + key);
  }

  private switchToPage(targetPage: number, allowPageJump: boolean, autoScrollDesired: boolean, selectBestFromTop: boolean): void {
    // NOTE: The scrolling now happens by always scrolling to the currently selected answer.
    this.currentPageNumber = targetPage;
    this.continueButtonActive = false;
    this.loadItems(allowPageJump, autoScrollDesired, selectBestFromTop);
  }

  private loadItems(allowPageJump: boolean, autoScrollDesired: boolean, selectBestFromTop: boolean): void {
    // This page.
    // eslint-disable-next-line no-shadow
    this.loadItemsForPage(this.currentPageNumber, allowPageJump,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      autoScrollDesired, selectBestFromTop, this.checkPage.bind(this));
    // Next page.
    // this.loadItemsForPage(this.currentPageNumber + 1, false, false, true, null);
    // Previous page.
    // this.loadItemsForPage(this.currentPageNumber - 1, false, false, true, null);
  }

  private checkPage(allowPageJump: boolean, autoScrollDesired: boolean, selectBestFromTop: boolean): void {
    // The continue button must be active if all loaded components were already answered.
    this.continueButtonActive = false;

    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      const anyAnswersGiven: boolean = this.anyAnswersGiven();
      const allAnswersGiven: boolean = this.allAnswersGiven();
      this.updateButtonState(anyAnswersGiven, allAnswersGiven);
      this.lastFilledOutPageNumber = Math.max(this.lastFilledOutPageNumber, this.currentPageNumber);

      if (allAnswersGiven && allowPageJump && !this.onLastPage()) {
        this.switchToPage(this.nextPageNumber(), true, autoScrollDesired, selectBestFromTop);
      }
      else {
        this.selectBestItemComponent(autoScrollDesired, selectBestFromTop);
      }
    }, 0);
  }

  private updateButtonState(anyAnswersGiven: boolean | null = null, allAnswersGiven: boolean | null = null): void {
    if (anyAnswersGiven === null) {
      anyAnswersGiven = this.anyAnswersGiven();
    }
    if (allAnswersGiven === null) {
      allAnswersGiven = this.allAnswersGiven();
    }
    this.backButtonActive = !(this.onFirstPage() && anyAnswersGiven);
    this.continueButtonActive = allAnswersGiven;
  }

  private loadItemsForPage(page: number,
                           allowPageJump: boolean,
                           autoScrollDesired: boolean,
                           selectBestFromTop: boolean,
                           // eslint-disable-next-line no-shadow
                           done: (allowPageJump: boolean, autoScrollDesired: boolean, selectBestFromTop: boolean) => unknown = null): void {
    if ((page > 0) && (page <= this.availablePageNumbers)) {
      if (typeof this.items[page] === typeof undefined) {
        // console.log('Loading items for page ' + page + '...');
        this.items[page] = this.fetch(this.calcIdsForPage(page), allowPageJump, autoScrollDesired, selectBestFromTop, done);
      }
      else {
        if (done !== null) {
          done(allowPageJump, autoScrollDesired, selectBestFromTop);
        }
      }
    }
  }

  private calcIdsForPage(pageNumber: number): ItemId[] {
    // Calculate which items should be loaded.
    const itemsLeft = this.availableItemIds.length - ((pageNumber - 1) * this.itemsPerPage);
    // console.log('Page ' + pageNumber + ': There are \"' + itemsLeft + '\" item left to be answered.');

    // We might not have to load a full page of items.
    const amountToLoad = Math.min(itemsLeft, this.itemsPerPage);

    const ids: ItemId[] = [];
    for (let i = this.availableItemIds.length - itemsLeft; i < this.availableItemIds.length - itemsLeft + amountToLoad; i++) {
      const itemID = this.availableItemIds[i];
      ids.push(new ItemId(itemID.id));
    }

    return ids;
  }

  private fetch(ids: ItemId[],
                allowPageJump: boolean,
                autoScrollDesired: boolean,
                selectBestFromTop: boolean,
                // eslint-disable-next-line no-shadow
                done: (allowPageJump: boolean, autoScrollDesired: boolean, selectBestFromTop: boolean) => unknown = null): Item[] {
    const arr = new Array(ids.length);

    this.testService.loadItems(this.testId, ids)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items: Item[]) => {
        // console.log('Received items: ', itemsSend);
        for (let i = 0; i < items.length; i++) {
          arr[i] = items[i];
        }
        if (done != null) {
          done(allowPageJump, autoScrollDesired, selectBestFromTop);
        }
      });

    return arr;
  }

  private answerChanged(itemComponent: ItemComponent, reselectDesired: boolean, autoScrollDesired: boolean) {
    setTimeout(() => {
      if (reselectDesired) {
        this.selectNextItem(itemComponent, autoScrollDesired, this.autoPageJump);
      }
    }, this.viewChildrenAccessDelay);
  }

  private selectPreviousItem(currentlySelected: ItemComponent | null, autoScrollDesired: boolean, autoPageJump: boolean): void {
    this.selectNeighbour(currentlySelected, false, autoScrollDesired, autoPageJump);
  }

  private selectNextItem(currentlySelected: ItemComponent | null, autoScrollDesired: boolean, autoPageJump: boolean): void {
    if (!this.selectNeighbour(currentlySelected, true, autoScrollDesired, autoPageJump)) {
      // Only select the first unanswered item component if we are not currently at the last item.
      // This function is executed before the current item is really answered (when the "answer" request returns).
      // Without this check, the "not yet answered" error would appear immediately on the current and last item when that item is answered.
      // And would disappear when the request returns. We do not want that behaviour.
      if (this.itemComponents.last !== currentlySelected) {
        this.selectFirstUnansweredItemComponentAndShowError(autoScrollDesired);
      }
    }
  }

  private findItemComponentIndex(target: ItemComponent): number | undefined {
    let indexFound: number;
    this.itemComponents.forEach((component: ItemComponent, index: number) => {
      if (component === target) {
        indexFound = index;
        return;
      }
    });
    // noinspection JSUnusedAssignment
    return indexFound;
  }

  private selectItemComponentByIndex(indexOfComponentToSelect: number, autoScrollDesired: boolean): void {
    this.itemComponents.find((component, currentIndex) => currentIndex === indexOfComponentToSelect).select(autoScrollDesired);
  }

  private selectNextNeighbour(currentlySelected: ItemComponent, index: number,
                              autoScrollDesired: boolean, autoPageJump: boolean): boolean {
    if (index < this.itemComponents.length - 1) {
      // The currentlySelected item has a successor on the current page.
      currentlySelected.deselect();
      this.selectItemComponentByIndex(index + 1, autoScrollDesired);
      return true;
    }
    else {
      // The currentlySelected item has no successor on the current page. Calling checkPage() will perform a page jump if possible.
      if (this.allAnswersGiven() && !this.onLastPage()) {
        this.continueButtonActive = true;
        if (autoPageJump) {
          this.goToNextPage(true);
        }
      }
      return false;
    }
  }

  private selectPreviousNeighbour(currentlySelected: ItemComponent, index: number,
                                  autoScrollDesired: boolean, autoPageJump: boolean): boolean {
    if (index > 0) {
      // The currentlySelected item has a predecessor on the current page.
      currentlySelected.deselect();
      this.selectItemComponentByIndex(index - 1, autoScrollDesired);
      return true;
    }
    else {
      // The currentlySelected item has no predecessor on the current page. Jump to the previous page.
      if (!this.onFirstPage()) {
        if (autoPageJump) {
          this.goToPreviousPage(true);
        }
      }
      return false;
    }
  }

  private selectNeighbour(currentlySelected: ItemComponent | null, next: boolean,
                          autoScrollDesired: boolean, autoPageJump: boolean): boolean {
    if (!currentlySelected) {
      currentlySelected = this.findCurrentlySelectedItemComponent();
    }
    const index: number | undefined = this.findItemComponentIndex(currentlySelected);
    if (typeof index === typeof undefined) {
      console.error('Unable to find component of currently selected item: ', currentlySelected);
      return false;
    }
    // Select the next component or switch to the next page.
    return next ?
      this.selectNextNeighbour(currentlySelected, index, autoScrollDesired, autoPageJump) :
      this.selectPreviousNeighbour(currentlySelected, index, autoScrollDesired, autoPageJump);
  }

  private findCurrentlySelectedItemComponent(): ItemComponent {
    return this.itemComponents.find((component: ItemComponent) => component.isSelected());
  }

  private findFirstUnansweredItemComponent(): ItemComponent {
    return this.itemComponents.find((component: ItemComponent) => !component.item.answerSaved);
  }

}
