import {OrderStatus} from './order-status';
import {OrderAction} from './order-action';
import {OrderError} from './order-error';
import {OrderProcessingResultRepresentation} from './order-processing-result-representation';

export class OrderProcessingResult {

  private readonly _statusBeforeProcessing: OrderStatus;
  private readonly _statusAfterProcessing: OrderStatus;
  private readonly _actions: OrderAction[];
  private readonly _errors: OrderError[];

  constructor(statusBeforeProcessing: OrderStatus,
              statusAfterProcessing: OrderStatus,
              actions: OrderAction[],
              errors: OrderError[]) {
    this._statusBeforeProcessing = statusBeforeProcessing;
    this._statusAfterProcessing = statusAfterProcessing;
    this._actions = actions;
    this._errors = errors;
  }

  get statusBeforeProcessing(): OrderStatus {
    return this._statusBeforeProcessing;
  }

  get statusAfterProcessing(): OrderStatus {
    return this._statusAfterProcessing;
  }

  get actions(): OrderAction[] {
    return this._actions;
  }

  get errors(): OrderError[] {
    return this._errors;
  }

  static buildFrom(orderProcessingResultRepresentation: OrderProcessingResultRepresentation): OrderProcessingResult {
    return new OrderProcessingResult(
      OrderStatus.fromName(orderProcessingResultRepresentation.statusBeforeProcessing),
      OrderStatus.fromName(orderProcessingResultRepresentation.statusAfterProcessing),
      orderProcessingResultRepresentation.actions.map(action => OrderAction.fromName(action)),
      orderProcessingResultRepresentation.errors.map(error => OrderError.fromName(error))
    );
  }

  static copyOf(other: OrderProcessingResult): OrderProcessingResult {
    return new OrderProcessingResult(
      other._statusBeforeProcessing,
      other._statusAfterProcessing,
      other._actions,
      other._errors
    );
  }

}
