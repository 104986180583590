<div style="padding: 2em;">
  <h1>Self Buyer Statistics</h1>
  <div class="clr-row clr-justify-content-center" style="margin-top: 2em">
    <div class="clr-col-xl-8 clr-col-lg-10 clr-col-md-12 clr-col-sm-12">
      <table class="table details-table">
        <tr>
          <th>Count of Paypal Payments</th>
          <td>{{selfBuyerPaymentsRecommendation?.countOfPaypalPayments}}</td>
        </tr>
        <tr>
          <th>Count of CC Payments</th>
          <td>{{selfBuyerPaymentsRecommendation?.countOfCcPayments}}</td>
        </tr>
        <tr>
          <th>Count of Standard Recommendations</th>
          <td>{{selfBuyerPaymentsRecommendation?.countOfStandardRecommendation}}</td>
        </tr>
        <tr>
          <th>Count of F&F Recommendations</th>
          <td>{{selfBuyerPaymentsRecommendation?.countOfFfRecommendation}}</td>
        </tr>
        <tr>
          <th>Count of Full Price</th>
          <td>{{selfBuyersPrice?.countOfFullPrice}}</td>
        </tr>
        <tr>
          <th>Count of DiscountedPrice</th>
          <td>{{selfBuyersPrice?.countOfDiscountedPrice}}</td>
        </tr>
      </table>
      <br/>
      <br/>
      <div class="input-container">
        <h2>Self Buyer</h2>
        <label for="search"></label>
        <input (ngModelChange)="search()" [(ngModel)]="searchKey" id="search" placeholder="Suchen..."
               type="text">
      </div>
      <clr-datagrid>
        <clr-dg-column [clrDgSortBy]="'orderId'">Order Id</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'name'">Full Name</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'paymentDateTime'">PaymentDateTime</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'recommendationType'">RecommendationType</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'discount'">Discount</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'paidPrice'">Paid Price</clr-dg-column>
        <clr-dg-row *clrDgItems="let selfBuyer of selfBuyerView">
          <clr-dg-cell>{{selfBuyer.orderId}}</clr-dg-cell>
          <clr-dg-cell>{{selfBuyer.name}}</clr-dg-cell>
          <clr-dg-cell>{{selfBuyer.paymentDateTime}}</clr-dg-cell>
          <clr-dg-cell>{{selfBuyer.recommendationType}}</clr-dg-cell>
          <clr-dg-cell>{{selfBuyer.discount}}</clr-dg-cell>
          <clr-dg-cell>{{selfBuyer.paidPrice}}</clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [(clrDgPage)]="selfBuyerCurrentPage" [clrDgPageSize]="pageSize">
            <span class="pagination-text">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} users
            </span>
            <button (click)="previousPage(selfBuyerCurrentPage)"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow left" src="assets/icons/arrow_pager_left_20x20.svg"/>
            </button>
            <button (click)="nextPage(selfBuyerCurrentPage, selfBuyerLastPage)"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow right" src="assets/icons/arrow_pager_right_20x20.svg"/>
            </button>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

    </div>
  </div>
</div>
