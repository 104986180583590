<ng-container *ngIf="!!forceDisplay ? forceDisplay : (displayIfAmountGreaterZero ? amount > 0 : false)">

  <div [ngStyle]="{'top': emTop + 'em', 'right': emRight + 'em'}" class="unread-info-position">
    <div [ngStyle]="{'font-size': emSize + 'em'}" class="unread-info-scale">
      <div class="unread-info">
        {{amount}}
      </div>
    </div>
  </div>

</ng-container>
