<div class="card">
  <div class="card-header id37-text1">
    <div class="title">
      {{__('test')}}: {{test.creationDate | JsDateTime}}
    </div>
    <div class="collapsible-wrapper">
      <app-collapsible-list>
        <app-collapsible-item>
          <app-collapsible-header class="align-right">
            {{__('info')}}
          </app-collapsible-header>
          <app-collapsible-body>
            <div class="Info">
              <div class="id37-text1">
                <span class="text-bold">{{__('status')}}: </span>
                <span>{{status()}}</span>
                <ng-container *featureAllowed="'test-reset'">
                                    <span> (<a
                                        (click)="id37router.routeToTestReset()">{{__('testResetAllowed')}}</a>)</span>
                </ng-container>
              </div>
              <div class="id37-text1">
                <span class="text-bold">{{__('variation')}}: </span>
                <span>{{___(test.variation.translatorKey)}}</span>
                <span
                    *ngIf="testFunctions.isVariationUpdatePossible(test)"> - ({{__('variationUpdatePossible')}}
                  )</span>
              </div>
              <div class="id37-text1">
                <span class="text-bold">{{__('origin')}}: </span>
                <ng-container *ngIf="test.origin.isUnknown()">
                  <span>{{__('origin.unknown')}}</span>
                </ng-container>
                <ng-container *ngIf="test.origin.isBuy()">
                  <span>{{__('origin.buyA')}}</span>
                  <a (click)="navigateToOrders()">&nbsp;{{(test.origin | TestOriginBuyCast).orderId}}&nbsp;</a>
                  <span>{{__('origin.buyB')}}</span>
                </ng-container>
                <ng-container *ngIf="test.origin.isImport()">
                  <span>{{__('origin.importA')}}</span>
                  <span>&nbsp;{{(test.origin | TestOriginImportCast).importerId}}&nbsp;</span>
                  <span>{{__('origin.importB')}}</span>
                </ng-container>
                <ng-container *ngIf="test.origin.isInvitation()">
                  <span>{{__('origin.invitationA')}}</span>
                  <span>&nbsp;{{(test.origin | TestOriginInvitationCast).inviterId}}&nbsp;</span>
                  <span>{{__('origin.invitationB')}}</span>
                </ng-container>
              </div>
              <div class="id37-text1">
                <span class="text-bold">{{__('id')}}: </span> {{test.testId}}
              </div>
            </div>
          </app-collapsible-body>
        </app-collapsible-item>
      </app-collapsible-list>
    </div>
  </div>
  <div class="card-block">
    <div class="id37-text1 test-status-info">
      <div *ngIf="!test.started">
        {{__('testNotStarted')}}
      </div>
      <div *ngIf="test.started && !test.finished">
        {{__('testInProgress')}}
      </div>
      <div *ngIf="test.started && test.finished && !test.accessGranted">
        {{__('testFinished')}}
      </div>
      <div *ngIf="test.started && test.finished && test.accessGranted">
        {{__('testAccessGranted')}}
      </div>
    </div>

    <div *ngIf="!test.finished" class="clr-row clr-justify-content-center" style="margin: 1em 0 0 0">
      <button (click)="id37router.routeToTest(test.testId)"
              class="id37-btn-outline">
        {{__('myTestBtn')}}
      </button>
    </div>

    <ng-container *ngIf="test.finished && test.accessGranted">
      <div class="dashboard-btn-container">
        <div class="dashboard-btn-wrapper">
          <button (click)="id37router.routeToMyReport(test.testId)"
                  class="dashboard-btn">
            <img alt="card with user pictogram" class="icon-svg"
                 src="assets/icons/ID37_Profil_100x100.svg"/>
            <span class="icon-text">{{__('myId37Analysis')}}</span>
          </button>
        </div>

        <div class="dashboard-btn-wrapper">
          <button (click)="id37router.routeToMyKeyGraphic(test.testId)"
                  class="dashboard-btn">
            <img alt="example graphic with connected dots" class="icon-svg"
                 src="assets/icons/ID37_Grafik_100x100.svg"/>
            <span class="icon-text">{{__('myId37Graphic')}}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
