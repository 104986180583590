import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {AccountData} from '../account/account-data';
import {CurrentUser} from '../user/current-user.service';
import {RoutingService} from '../routing/routing.service';
import {TranslatorService} from '../translation/translator.service';
import {CountryService} from '../user-data/country.service';
import {SidebarComponent} from './sidebar/sidebar.component';
import {ReportQuery} from './report-query';
import {ReportFunctions} from './report-functions';
import {AccountDataQuery} from '../account/account-data-query';
import {ReportComponentService} from './report-component-service';
import {Report} from './types/report';
import {MotiveScores} from '../test/state/motive-score';
import {TestQuery} from '../test/state/test.query';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [
    ReportComponentService
  ]
})
export class ReportComponent implements OnInit, OnDestroy {

  @ViewChild(SidebarComponent)
    sidebarComponent: SidebarComponent;
  data$: Observable<{
    report: Report;
    motiveScores: MotiveScores;
    humanReadableAccountData: AccountData;
  }>;
  lastWidth: number;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private reportComponentService: ReportComponentService,
              private currentUser: CurrentUser,
              private countryService: CountryService,
              private testQuery: TestQuery,
              private reportQuery: ReportQuery,
              private reportFunctions: ReportFunctions,
              private accountDataQuery: AccountDataQuery,
              private translatorService: TranslatorService,
              private routingService: RoutingService,
              private route: ActivatedRoute) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentWidth = event.target.innerWidth;
    if (currentWidth < 991 && this.lastWidth >= 991 && !!this.sidebarComponent) {
      this.sidebarComponent.collapsed = true;
    }
    if (currentWidth >= 991 && this.lastWidth < 991 && !!this.sidebarComponent) {
      this.sidebarComponent.collapsed = false;
    }
    this.lastWidth = currentWidth;
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.data$ = combineLatest([
          this.reportQuery.selectProcessedReport(params.userId as string, params.testId as string),
          this.testQuery.selectTest(params.userId as string, params.testId as string).pipe(map(test => test.result.motiveScores)),
          this.accountDataQuery.selectHumanReadableAccountData(params.userId as string)
        ]).pipe(map((d) => ({
          report: d[0],
          motiveScores: d[1],
          humanReadableAccountData: d[2]
        })));
      });
  }

  closeReport(): void {
    this.routingService.routeBack();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  __(key: string): string {
    return this.translatorService.translate('report.' + key);
  }

}
