import {AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Report} from '../types/report';
import {MotiveComponent} from '../motive/motive.component';
import {Motive} from '../types/motives/motive';
import {AccountData} from '../../account/account-data';
import {CurrentUser} from '../../user/current-user.service';
import {ReportComponentService} from '../report-component-service';
import {ScrollService} from '../../id37/scroll.service';
import {PrintService} from '../../id37/print.service';
import {MotiveScores} from '../../test/state/motive-score';

const PRINT_MOTIVE_PROFILE_CLASS = 'print-motive-profile';
const PRINT_REPORT_CLASS = 'print-report';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss']
})
export class ReportContentComponent implements OnInit, AfterViewInit {

  @ViewChildren(MotiveComponent)
    motiveComponents: QueryList<MotiveComponent>;

  @Input()
    report: Report;

  @Input()
    motiveScores: MotiveScores;

  @Input()
    mainContainer: HTMLElement;

  @Input()
    humanReadableAccountData: AccountData;

  @Input()
    sidebarCollapsed = false;

  readonly motives: string[] = Object.keys(Motive);

  locale: string;

  reportOfClient: boolean = false;

  constructor(private currentUser: CurrentUser,
              public scrollService: ScrollService,
              private printService: PrintService,
              private reportComponentService: ReportComponentService) {
  }

  ngOnInit(): void {
    this.locale = this.currentUser.locale;
    if ((this.humanReadableAccountData.masterInfo !== undefined
      && this.currentUser.keycloakId === this.humanReadableAccountData.masterInfo.userId)
      || (this.humanReadableAccountData.partnerInfo !== undefined
        && this.currentUser.keycloakId === this.humanReadableAccountData.partnerInfo.userId)) {
      this.reportOfClient = true;
    }
  }

  ngAfterViewInit(): void {
    this.reportComponentService.setMotiveComponents(this.motiveComponents);
  }

  printMotiveProfile(): void {
    this.printService.printWithClass(this.mainContainer, PRINT_MOTIVE_PROFILE_CLASS);
  }

  printReport(): void {
    this.printService.printWithClass(this.mainContainer, PRINT_REPORT_CLASS);
  }

}
