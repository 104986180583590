import {AfterViewInit, ApplicationRef, Component, OnDestroy, ViewChild} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {Translatable} from '../../translation/translatable';
import {ModalComponent} from '../../modal/modal/modal.component';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval, Subject} from 'rxjs';
import {first, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements Translatable, OnDestroy, AfterViewInit {

  @ViewChild('updateAvailable')
    updateAvailable: ModalComponent;

  @ViewChild('unrecoverable')
    unrecoverable: ModalComponent;

  updateSuccessful: boolean = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private appRef: ApplicationRef,
              private swUpdate: SwUpdate,
              private translatorService: TranslatorService) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkForUpdates();
    });
  }

  checkForUpdates(): void {
    // this.modal.show();

    if (this.swUpdate.isEnabled) {

      this.swUpdate.versionUpdates
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event) => {
          if (event.type === 'VERSION_READY') {
            this.updateAvailable.show();
          }
        });

      this.swUpdate.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            this.updateAvailable.show();
            break;
          case 'VERSION_READY':
            this.updateSuccessful = true;
            setTimeout(() => {
              this.updateSuccessful = false;
            }, 5 * 1000);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            break;
        }
      });

      /*
      //Old code: SWUpdate.available and SWUpdate.activated are deprecated. Could be used as a reference if the new
      //code doesn't work

      //Handling available updates by informing the user and performing a full page reload through reloadApplication().
      this.swUpdate.available
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.updateAvailable.show();
        });

      this.swUpdate.activateUpdate().then(() => {
        this.swUpdate.versionUpdates
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.updateSuccessful = true;
            setTimeout(() => {
              this.updateSuccessful = false;
            }, 5 * 1000);
          });
      });

      // Handling available updates by informing the user and performing a full page reload through reloadApplication().
      // this.swUpdate.available
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(() => {
      //     this.updateAvailable.show();
      //   });
      //
      // // Handling performed updates by informing the user.
      // this.swUpdate.activated
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe(() => {
      //     this.updateSuccessful = true;
      //     setTimeout(() => {
      //       this.updateSuccessful = false;
      //     }, 5 * 1000);
      //   });
      // Handling performed updates by informing the user.
      this.swUpdate.activated
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.updateSuccessful = true;
          setTimeout(() => {
            this.updateSuccessful = false;
          }, 5 * 1000);
        });
       */

      this.swUpdate.unrecoverable
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.unrecoverable.show();
        });

      // Periodically checking for updates.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everyTwentyMinutes$ = interval(20 * 60 * 1000);
      const everyTwentyMinutesOnceAppIsStable$ = concat(appIsStable$, everyTwentyMinutes$);
      everyTwentyMinutesOnceAppIsStable$
        .subscribe(async () => {
          await this.swUpdate.checkForUpdate();
        });
    }
  }

  reloadApplication(): void {
    window.location.reload();
  }

  __(key: string): string {
    return this.translatorService.translate('update.' + key);
  }

}
