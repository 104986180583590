import {Team} from './team';
import {TeamUserRepresentation} from './team-user-representation';


export class TeamRepresentation {

  public readonly creatorId: string;
  public readonly teamId: string;
  public readonly teamName: string;
  public readonly users: TeamUserRepresentation[];

  static toModelEntity(teamRepresentation: TeamRepresentation): Team {
    return {
      creatorId: teamRepresentation.creatorId,
      teamId: teamRepresentation.teamId,
      name: teamRepresentation.teamName,
      users: teamRepresentation.users.map(
        teamUser => TeamUserRepresentation.toModelEntity(teamUser))
    };
  }
}
