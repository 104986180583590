import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {tap} from 'rxjs/operators';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  constructor(private cookieService: CookieService,
              private http: HttpClient) {
  }

  updateLanguage(language: string): Observable<void> {
    return this.http.patch<void>(
      API_URL + '/locale/language',
      {language},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(
      tap(() => this.cookieService.set('KEYCLOAK_LOCALE', language))
    );
  }

}
