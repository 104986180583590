<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12 comparebox spacing-top">
        <div class="clr-row">
          <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <div *ngIf="selectedUser1 != null" class="card">
              <div class="card-block">
                <div class="card-title">
                  {{selectedUser1.firstName}} {{selectedUser1.lastName}}
                </div>
                <div class="card-text">
                  {{selectedUser1.email}}
                </div>
              </div>
              <div class="card-footer">
                <a (click)="unselectCustomer(selectedUser1)"
                   class="no-underline">
                  {{__('action.removeuser')}}
                </a>
              </div>
            </div>
          </div>
          <div class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <div *ngIf="selectedUser1 != null && selectedUser2 == null && masterHasTest"
                 class="card selfselect">
              <div class="card-block">
                <div class="card-title">
                  {{name}}
                </div>
                <div class="card-text">
                  &nbsp;
                </div>
              </div>
              <div class="card-footer">
                <a (click)="selectMaster()"
                   class="no-underline">
                  {{__('action.selfcompare')}}
                </a>
              </div>
            </div>
            <div *ngIf="selectedUser2 != null" class="card">
              <div class="card-block">
                <div class="card-title">
                  {{selectedUser2.firstName}} {{selectedUser2.lastName}}
                </div>
                <div class="card-text">
                  {{selectedUser2.email}}
                  <ng-container *ngIf="!selectedUser2.email">&nbsp;</ng-container>
                </div>
              </div>
              <div class="card-footer">
                <a (click)="unselectCustomer(selectedUser2)"
                   class="no-underline">
                  {{__('action.removeuser')}}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-row clr-justify-content-center compareaction">
          <div *ngIf="bothSelected()" class="clr-col-12 action">
            <button (click)="compare()" class="id37-btn-outline" type="button">
              {{__('action.compareaction')}}
            </button>
          </div>
        </div>

      </div>

      <app-customerlist
          (selectionEvents)="onSelection($event)"
          [customers]="customersView"
          [maxselectable]="2"
          [metaSearch]="metaSearch"
          [mulitselect]="true"
          class="clr-col-12 spacing-top">
      </app-customerlist>

    </div>
  </div>
</div>
