import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ClrAlertModule, ClrDatagridModule, ClrIconModule} from '@clr/angular';
import {Id37Module} from '../../id37/id37.module';
import {ReportModule} from '../../report/report.module';
import {ImportComponent} from './import/import.component';
import {ToolboxComponent} from './toolbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComparisonComponent} from './comparison/comparison.component';
import {ComparerComponent} from './comparer/comparer.component';
import {TeamAnalysisComponent} from './teamanalysis/teamanalysis.component';
import {CustomerlistComponent} from '../../shared/customerlist/customerlist.component';
import {KeyGraphicModule} from '../../graphics/key-graphic/key-graphic.module';
import {ModalModule} from '../../modal/modal.module';
import {DirectivesModule} from '../../directives/directives.module';
import {ChatbotDocumentsToolsComponent} from './chatbot-documents-tools/chatbot-documents-tools.component';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ClrAlertModule,
    ClrIconModule,
    ClrDatagridModule,
    FormsModule,
    ReactiveFormsModule,
    ReportModule,
    Id37Module,
    KeyGraphicModule,
    ModalModule,
    DirectivesModule
  ],
  exports: [
    ImportComponent
  ],
  declarations: [
    ImportComponent,
    ToolboxComponent,
    ComparisonComponent,
    ComparerComponent,
    TeamAnalysisComponent,
    CustomerlistComponent,
    ChatbotDocumentsToolsComponent
  ]
})
export class ToolboxModule {
}
