import {Moment} from 'moment';

export class DateTimeSelectionEvent {

  readonly type: string;
  readonly locale: string;

  readonly moment: Moment;
  readonly isoZoneFormatted: string;
  readonly isoUtcFormatted: string;
  readonly localeZoneFormatted: string;
  readonly localeZoneFormattedWithOffset: string;
  readonly localeUtcFormatted: string;

  constructor(type: string,
              locale: string,
              moment: Moment,
              isoZoneFormatted: string,
              isoUtcFormatted: string,
              localeZoneFormatted: string,
              localeZoneFormattedWithOffset: string,
              localeUtcFormatted: string) {
    this.type = type;
    this.locale = locale;
    this.moment = moment;
    this.isoZoneFormatted = isoZoneFormatted;
    this.isoUtcFormatted = isoUtcFormatted;
    this.localeZoneFormatted = localeZoneFormatted;
    this.localeZoneFormattedWithOffset = localeZoneFormattedWithOffset;
    this.localeUtcFormatted = localeUtcFormatted;
  }

}
