import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {EmailSettingsRepresentation} from './email-settings.representation';
import {CurrentUser} from '../../../user/current-user.service';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsService {
  private emailSettings: BehaviorSubject<EmailSettingsRepresentation> = new BehaviorSubject<EmailSettingsRepresentation>(null);

  constructor(
    private currentUser: CurrentUser,
    private http: HttpClient
  ) {
  }

  loadEmailSettings(): void {
    this.http.get<EmailSettingsRepresentation>(
      `${API_URL}/users/current-user/email-settings`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).subscribe(emailSettings => this.emailSettings.next(emailSettings));
  }

  updateUnreadMessagesSettings(informAboutUnreadChatMessages: boolean): void {
    this.http.patch<EmailSettingsRepresentation>(
      `${API_URL}/users/current-user/email-settings/unread-messages`,
      {informAboutUnreadChatMessages},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).subscribe(emailSettings => this.emailSettings.next(emailSettings));
  }

  updateProductInformationSettings(informAboutProductInformations: boolean): void {
    this.http.patch<EmailSettingsRepresentation>(
      `${API_URL}/users/current-user/email-settings/product-informations`,
      {informAboutProductInformations},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).subscribe(emailSettings => this.emailSettings.next(emailSettings));
  }

  getEmailSettings(): BehaviorSubject<EmailSettingsRepresentation> {
    return this.emailSettings;
  }


  updateNetworkingSettings(informAboutNetworking: boolean) {
    this.http.patch<EmailSettingsRepresentation>(
      `${API_URL}/users/current-user/email-settings/uses-networking`,
      {informAboutNetworking},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).subscribe(emailSettings => this.emailSettings.next(emailSettings));
  }

  updateAdvertisingSettings(informAboutAdvertising: boolean) {
    this.http.patch<EmailSettingsRepresentation>(
      `${API_URL}/users/current-user/email-settings/getting-advertising`,
      {informAboutAdvertising},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).subscribe(emailSettings => this.emailSettings.next(emailSettings));
  }
}
