import {PersonalDataAfterTest} from './personal-data-after-test';

export class PersonalDataAfterTestRepresentation {

  public readonly city: string;
  public readonly academicDegree: string;
  public readonly profession: string;
  public readonly industry: string;
  public readonly company: string;
  public readonly mobilePhone: string;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(city, academicDegree, profession, industry, company, mobilePhone) {
    this.city = city;
    this.academicDegree = academicDegree;
    this.profession = profession;
    this.industry = industry;
    this.company = company;
    this.mobilePhone = mobilePhone;
  }

  static fromModelEntity(personalDataAfterTest: PersonalDataAfterTest): PersonalDataAfterTestRepresentation {
    return new PersonalDataAfterTestRepresentation(
      personalDataAfterTest.city,
      personalDataAfterTest.academicDegree,
      personalDataAfterTest.profession,
      personalDataAfterTest.industry,
      personalDataAfterTest.company,
      personalDataAfterTest.mobilePhone
    );
  }

  static toModelEntity(personalDataAfterTestRepresentation: PersonalDataAfterTestRepresentation): PersonalDataAfterTest {
    return {
      city: personalDataAfterTestRepresentation.city,
      academicDegree: personalDataAfterTestRepresentation.academicDegree,
      profession: personalDataAfterTestRepresentation.profession,
      industry: personalDataAfterTestRepresentation.industry,
      company: personalDataAfterTestRepresentation.company,
      mobilePhone: personalDataAfterTestRepresentation.mobilePhone
    };
  }

}
