import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DistanceLineComponent} from './distance-line/distance-line.component';
import {DistanceGraphicComponent} from './distance-graphic/distance-graphic.component';
import {KeyGraphicModule} from '../graphics/key-graphic/key-graphic.module';
import {DistanceGraphicTestComponent} from './distance-graphic-test/distance-graphic-test.component';
import {DistanceGraphicPageComponent} from './distance-graphic-page/distance-graphic-page.component';
import {DistanceScoreDescriptionComponent} from './distance-score-description/distance-score-description.component';
import {DistanceScoreDescriptionsComponent} from './distance-score-descriptions/distance-score-descriptions.component';
import {DistantFilterComponent} from './distance-graphic-page/distant-filter/distant-filter.component';
import {ConsultMasterComponent} from './distance-graphic-page/consult-master/consult-master.component';
import {CloseFilterComponent} from './distance-graphic-page/close-filter/close-filter.component';
import {ModerateFilterComponent} from './distance-graphic-page/moderate-filter/moderate-filter.component';
import {AllFilterComponent} from './distance-graphic-page/all-filter/all-filter.component';
import {CollapsibleModule} from '../collapsible/collapsible.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CircularRingModule} from '../circular-ring/circular-ring.module';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';
import {SuperlikeComponent} from './distance-graphic-page/superlike/superlike.component';
import {ModalModule} from '../modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    KeyGraphicModule,
    CollapsibleModule,
    ReactiveFormsModule,
    CircularRingModule,
    ProfilePictureModule,
    ModalModule
  ],
  declarations: [
    DistanceLineComponent,
    DistanceGraphicComponent,
    DistanceGraphicTestComponent,
    DistanceGraphicPageComponent,
    DistanceScoreDescriptionComponent,
    DistanceScoreDescriptionsComponent,
    DistantFilterComponent,
    ConsultMasterComponent,
    CloseFilterComponent,
    ModerateFilterComponent,
    AllFilterComponent,
    SuperlikeComponent
  ],
  exports: [
    DistanceGraphicComponent,
    DistanceGraphicTestComponent,
    DistanceGraphicPageComponent
  ]
})
export class DistanceGraphicModule {
}
