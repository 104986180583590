export interface ChatMessage {

  id: number;
  senderKeycloakId: string;
  receiverKeycloakId: string;
  message: string;
  sent: Date;
  received: Date | undefined;
  read: Date | undefined;
  updated: Date | undefined;

}

export const chatMessageComparator = (a: ChatMessage, b: ChatMessage): number =>
  // Converts the send dates to timestamps and substrats them, satisfying the comparison function demands.
  +a.sent - +b.sent
;

export const chatMessageComparatorDesc = (a: ChatMessage, b: ChatMessage): number =>
  // Converts the send dates to timestamps and substrats them, satisfying the comparison function demands.
  +b.sent - +a.sent
;

export class ChatMessageFunctions {

  static getContactUserId(chatMessage: ChatMessage, currentUserId: string): string {
    return currentUserId === chatMessage.receiverKeycloakId
      ? chatMessage.senderKeycloakId
      : chatMessage.receiverKeycloakId;
  }

  static createNew(contactUserId: string, currentUserId: string, message: string): ChatMessage {
    return {
      id: -1,
      senderKeycloakId: currentUserId,
      receiverKeycloakId: contactUserId,
      message,
      sent: new Date(),
      received: undefined,
      read: undefined,
      updated: undefined
    };
  }

  static createUpdated(chatMessage: ChatMessage, newContent: string): ChatMessage {
    return {
      id: chatMessage.id,
      senderKeycloakId: chatMessage.senderKeycloakId,
      receiverKeycloakId: chatMessage.receiverKeycloakId,
      message: newContent,
      sent: chatMessage.sent,
      received: chatMessage.received,
      read: chatMessage.read,
      updated: chatMessage.updated
    };
  }

}

