<!-- Raster -->

<svg [attr.height]="lineLength + 100">
  <line [attr.x1]="40" [attr.x2]="40" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(1)"
        class="vertical"/>
  <line [attr.x1]="120" [attr.x2]="120" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(2)"
        class="vertical"/>
  <line [attr.x1]="200" [attr.x2]="200" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(3)"
        class="vertical"/>
  <line [attr.x1]="240" [attr.x2]="240" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(-1)"
        class="vertical dashed"
        stroke-dasharray="16 8" stroke-dashoffset="0"/>

  <line [attr.x1]="560" [attr.x2]="560" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(-1)"
        class="vertical dashed"
        stroke-dasharray="16 8" stroke-dashoffset="0"/>
  <line [attr.x1]="600" [attr.x2]="600" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(8)"
        class="vertical"/>
  <line [attr.x1]="680" [attr.x2]="680" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(9)"
        class="vertical"/>
  <line [attr.x1]="760" [attr.x2]="760" [attr.y1]="70" [attr.y2]="lineLength" [style.opacity]="getLineOpacity(10)"
        class="vertical"/>

  <ng-container *ngFor="let line of linesArray; let idx = index">
    <line [attr.x1]="0" [attr.x2]="800" [attr.y1]="40 + ((idx + 1) * 80)" [attr.y2]="40 + ((idx + 1) * 80)"
          class="horizontal"/>
  </ng-container>

  <!-- Farbbalken, Beschriftungen und "weißes" (background-color) Overlay im Hintergrund unten -->
  <svg app-key-graphic-footer/>
</svg>
