import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslatorService} from '../../translation/translator.service';
import {Friendship} from '../../friend/state/friendship';
import {Friend} from '../../friend/state/friend';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';
import {CurrentUser} from '../../user/current-user.service';
import {Test} from '../state/test';
import {TestShareService} from '../state/test-share.service';
import {TestShare} from '../state/test-share';

@Component({
  selector: 'app-test-share',
  templateUrl: './test-share.component.html',
  styleUrls: ['./test-share.component.scss']
})
export class TestShareComponent implements OnInit {

  open: boolean = false;
  ongoingRequest: boolean;
  form: FormGroup;

  friendship: Friendship;
  friend: Friend;
  isCurrentUserFriendA: boolean;

  test: Test;
  myTestShare: TestShare;
  userTestVariation: string = undefined;

  constructor(private currentUser: CurrentUser,
              private testShareService: TestShareService,
              private translatorService: TranslatorService) {
    this.form = new FormGroup({
      testVariation: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
  }

  computeTestVariation(test: Test): void {
    this.test = test;
    this.myTestShare = FriendshipFunctions.getMyTestShare(this.friendship, this.currentUser.keycloakId);
    this.userTestVariation = this.test.variation.internalName;
    !!this.myTestShare
      ? this.form.setValue({testVariation: this.myTestShare.variation.internalName})
      : this.form.setValue({testVariation: this.userTestVariation});
  }

  processForm(): void {
    this.ongoingRequest = true;
    if (!!this.myTestShare) { // if user has a TestShare already, it gets updated
      if (this.myTestShare.variation.internalName === this.form.value.testVariation) {
        this.hide();
        this.ongoingRequest = false;
      }
      else {
        this.testShareService.updateTestShare(
          this.friendship.id,
          this.myTestShare.testShareId,
          this.form.value.testVariation as string).subscribe(
          () => {
            this.ongoingRequest = false;
            this.hide();
          },
          err => {
            this.ongoingRequest = false;
            console.error('Error:', err);
          }
        );
      }
    }
    else { // else a new TestShare is created
      this.testShareService.createTestShare(this.friendship.id, this.test.testId, this.form.value.testVariation as string)
        .subscribe(() => {
          this.ongoingRequest = false;
          this.hide();
        }, err => {
          this.ongoingRequest = false;
          console.error('Error:', err);
        }
        );
    }
  }

  show(friendship: Friendship, test: Test): void {
    this.friendship = friendship;
    this.computeTestVariation(test);
    if (!!this.userTestVariation) {
      this.friend = FriendshipFunctions.getFriend(friendship, this.currentUser.keycloakId);
      this.currentUser.keycloakId === friendship.userA.id
        ? this.isCurrentUserFriendA = true
        : this.isCurrentUserFriendA = false;
      if (this.userTestVariation === 'ID37_16' || this.userTestVariation === 'ID37_15') {
        this.open = true;
      }
      else if (this.userTestVariation === 'ID37_14') {
        // Do nothing. Backend will automatically create a testShare.
      }
      else {
        console.error('Unknown testVariation:', this.userTestVariation);
      }
    }
    else {
      console.error('User doesn\'t have a test yet');
    }
  }

  hide(): void {
    this.open = false;
  }

  __(key: string): string {
    return this.translatorService.translate('testShare.' + key);
  }

}
