import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  DotClickEvent,
  SignpostPosition
} from '../key-graphic/key-graphic-motive-score/key-graphic-motive-score.component';
import {MotiveScore, MotiveScoreFunctions, MotiveScores} from '../../../test/state/motive-score';
import {TranslatorService} from '../../../translation/translator.service';
import {RoutingService} from '../../../routing/routing.service';
import {TestVariation} from '../../../test/state/test-variation';

class SignpostData {
  motiveScore: MotiveScore;
  score = 1;
  title = 'Title';
  text = 'Text';
  top = 0;
  left = 0;
  visibility = 'hidden';
  orientation = 'right';
  opacity = 0;
}

interface VariationControlOption {
  variation: TestVariation;
  displayName: string;
}

@Component({
  selector: 'app-key-graphic-page',
  templateUrl: './key-graphic-page.component.html',
  styleUrls: ['./key-graphic-page.component.scss']
})
export class KeyGraphicPageComponent implements OnChanges {

  @Input()
    results: MotiveScores[];

  variationControlAllowed: boolean = true;
  variationControlOptions: VariationControlOption[] = [];
  variation: TestVariation | undefined = undefined; // If undefined, no processing should happen. Display as is.

  @ViewChild('signpost', {static: true})
    signpost: ElementRef;

  @ViewChild('svgwrapper', {static: true})
    svgWrapper: ElementRef;

  signpostData = new SignpostData();

  height = 200;

  constructor(private translatorService: TranslatorService,
              private detectorRef: ChangeDetectorRef,
              private renderer: Renderer2,
              private routingService: RoutingService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.results && changes.results.currentValue !== changes.results.previousValue) {
      this.computeHeight();
      this.computeVariationControlOptions();
      this.computeVariationSelection();
    }
  }

  computeHeight(): void {
    for (const motiveScores of this.results) {
      const newHeight = 200 + (motiveScores.size * 80);
      if (newHeight > this.height) {
        this.height = newHeight;
        this.renderer.setAttribute(this.svgWrapper.nativeElement, 'viewBox', '0 0 1150 ' + this.height);
      }
    }
  }

  computeVariationControlOptions(): void {
    const min: number = MotiveScoreFunctions.calculateMaximalNumberOfCommonMotives(this.results, undefined);
    const options: VariationControlOption[] = [];
    options.push({variation: TestVariation.ID37_14, displayName: this.___(TestVariation.ID37_14.translatorKey)});
    if (min >= 15) {
      options.push({variation: TestVariation.ID37_15, displayName: this.___(TestVariation.ID37_15.translatorKey)});
    }
    if (min >= 16) {
      options.push({variation: TestVariation.ID37_16, displayName: this.___(TestVariation.ID37_16.translatorKey)});
    }
    // We want the order to be 16 -> 15 -> 14: so reverse!
    this.variationControlOptions = options.reverse();
  }

  computeVariationSelection(): void {
    const min: number = MotiveScoreFunctions.calculateMaximalNumberOfCommonMotives(this.results, undefined);
    if (min === 16) {
      this.variation = TestVariation.ID37_16;
    }
    else if (min === 15) {
      this.variation = TestVariation.ID37_15;
    }
    else {
      this.variation = TestVariation.ID37_14;
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    // If any element other than one of the graphic dots is clicked, close the signpost.
    if (!!event.target.href && !!event.target.href.baseVal) {
      const baseVal: string = event.target.href.baseVal;
      if (baseVal.indexOf('small-circle') !== -1 || baseVal.indexOf('big-circle') !== -1) {
        return;
      }
    }
    this.closeSignpost();
  }

  showSignpost(dotClickEvent: DotClickEvent) {
    const type: 'low' | 'medium' | 'high' = dotClickEvent.motiveScore.score <= 3
      ? 'low'
      : dotClickEvent.motiveScore.score >= 8
        ? 'high'
        : 'medium';
    this.signpostData.motiveScore = dotClickEvent.motiveScore;
    this.signpostData.score = dotClickEvent.motiveScore.score;
    this.signpostData.title = this.___('motive.' + dotClickEvent.motiveScore.motive.toLowerCase());
    this.signpostData.text = this.___('motive.' + dotClickEvent.motiveScore.motive.toLowerCase() + '.' + type);
    this.signpostData.visibility = 'visible';
    this.signpostData.opacity = 1;
    this.setSignpostPos(dotClickEvent.position);
  }

  private setSignpostPos(signpostPosition: SignpostPosition) {
    this.detectorRef.detectChanges();
    const signRect = this.signpost.nativeElement.getBoundingClientRect();
    this.signpostData.top = signpostPosition.top - signRect.height / 2;
    this.signpostData.orientation = signpostPosition.orientation;
    if (signpostPosition.orientation === 'left') {
      this.signpostData.left = signpostPosition.left - signRect.width - 32;
    }
    else if (signpostPosition.orientation === 'right') {
      this.signpostData.left = signpostPosition.left + 32;
    }
  }

  private closeSignpost() {
    this.signpostData.visibility = 'hidden';
    this.signpostData.motiveScore = undefined;
    this.signpostData.opacity = 0;
  }

  close() {
    this.routingService.routeBack();
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
