import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SelectedCustomerAndTest} from './toolbox/teamanalysis/teamanalysis.component';
import {FileDownloadService} from '../shared/file-download-service';
import {MediaType} from '../shared/media-type';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class TeamanalysisService {

  constructor(private http: HttpClient,
              private fileDownloadService: FileDownloadService) {
  }

  downloadExcel(selectedCustomersAndTests: SelectedCustomerAndTest[]): void {
    const queryParams: string[] = [];
    for (const entry of selectedCustomersAndTests) {
      queryParams.push('userIds=' + entry.selectedCustomer.keycloakId);
    }
    const queryString = queryParams.join('&');

    this.fileDownloadService.downloadFile(
      API_URL + '/results/team?' + queryString,
      MediaType.EXCEL,
      'teamanalysis.xlsx'
    );
  }

  downloadExcelForIds(ids: string[]): void {
    const queryParams: string[] = [];
    for (const entry of ids) {
      queryParams.push('userIds=' + entry);
    }
    const queryString = queryParams.join('&');

    this.fileDownloadService.downloadFile(
      API_URL + '/results/team?' + queryString,
      MediaType.EXCEL,
      'teamanalysis.xlsx'
    );
  }

}
