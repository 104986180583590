import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {Vec2} from './vec-2';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public readonly windowResize$: Observable<Vec2>;

  constructor() {
    this.windowResize$ = fromEvent(window, 'resize')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .pipe(map((event) => new Vec2(event.target.innerWidth as number, event.target.innerHeight as number)));
  }

}
