<ng-container *ngIf="chats$ | async as chats">
  <ng-container *ngIf="chats.length !== 0">
    <div class="chats">
      {{__('chats')}}
    </div>
    <ng-container *ngFor="let friendship of chats; index as index; first as first; last as last">
      <app-chat-contact [friendship]="friendship" [ngClass]="{'first': first, 'last': last}"></app-chat-contact>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="contacts$ | async as contacts">
  <ng-container *ngIf="contacts.length !== 0">
    <div class="contacts">
      {{__('yourContacts')}}
    </div>
    <ng-container *ngFor="let friendship of contacts; index as index; first as first; last as last">
      <app-chat-contact [friendship]="friendship" [ngClass]="{'first': first, 'last': last}"></app-chat-contact>
    </ng-container>
  </ng-container>
</ng-container>

<app-chat-contact [friendship]="null" id="find-contacts"></app-chat-contact>
