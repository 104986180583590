<div class="flex-container">

  <div class="content">
    <h4 *ngIf="title" class="title">{{title}}</h4>

    <!-- Displays the actual content of this component if no html was provided -->
    <ng-content *ngIf="!showHTML"></ng-content>

    <!-- Displays provided HTML instead of the content -->
    <span *ngIf="showHTML" [innerHTML]="showHTML"></span>
  </div>

  <div class="arrow-icon" [ngClass]="{'up': itemIsExpanded}">
    <div class="bar1"></div>
    <div class="bar2"></div>
  </div>

</div>
