<div class="invoices-table-container">
  @for (order of orders; track order) {
    <div class="table-row">
      <div class="table-column left">
        <p class="bold-color">
          {{ order.creationDate | JsDate }}
        </p>
        <div class="table-coumn-left">
          <p>{{ getProduct(order.positions[0].product) }} </p>
          <p>&#x2022; {{ __('invoices.' + getProductStatus(order.status)) }}</p>
        </div>
      </div>
      <div class="table-column">
        <p class="bold-color amount">
          {{ order.positions[0].finalPriceAsNumber }},- €
        </p>
        <div class="table-coumn-right">
          <button class="download-button"
                  (click)="onClicked(order.orderId, order.invoice.invoiceNr)"> {{ __('invoices.no') }} {{ order.invoice.invoiceNr }}
            <img class="download-image" src="assets/icons/download_24x24.svg" alt="orange arrow"></button>
        </div>
      </div>
    </div>
  }
</div>
