import {Component, Input} from '@angular/core';
import {ExplanationMotivesText} from '../types/explanation-motives-text';

@Component({
  selector: 'app-explanation-motives',
  templateUrl: './explanation-motives.component.html',
  styleUrls: ['./explanation-motives.component.scss']
})
export class ExplanationMotivesComponent {

  @Input()
    explanationMotivesText: ExplanationMotivesText;

  constructor() {
  }

}
