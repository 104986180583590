import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Friendship} from '../state/friendship';
import {FriendshipService} from '../state/friendship.service';
import {FriendshipFunctions} from '../state/friendship-functions';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-delete-friend-modal',
  templateUrl: './delete-friend-modal.component.html',
  styleUrls: ['./delete-friend-modal.component.scss']
})
export class DeleteFriendModalComponent {

  @Input()
    friendship: Friendship;

  @Output()
    clicked = new EventEmitter<void>();

  open: boolean;

  constructor(private friendshipService: FriendshipService,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  show(friendship: Friendship): void {
    this.friendship = friendship;
    this.open = true;
  }

  hide(): void {
    this.open = false;
  }

  friendName(friendship: Friendship): string {
    return FriendshipFunctions.getFriendFullName(friendship, this.currentUser.keycloakId);
  }

  deleteFriendship() {
    // No request guard necessary. Modal is immediately closed.
    this.friendshipService.deleteFriendship(this.friendship).pipe(take(1)).subscribe();
    this.hide();
  }

  __(key: string): string {
    return this.translatorService.translate('friendship.deleteModal.' + key);
  }

}
