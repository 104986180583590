import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';
import {Chart, ChartPoint} from 'chart.js';
import {Motive, MotiveButton} from 'src/app/report/types/motives/motive';
import {ChartColor} from '../chart-color';
import {TranslatorService} from '../../translation/translator.service';

export interface ChartDataSets {
  label: string;
  data: ChartPoint[];
  backgroundColor: string;
  borderColor: string;
  showLine: boolean;
  lineTension: number;
  fill: boolean;
}

@Component({
  selector: 'app-scatter',
  templateUrl: './scatter.component.html',
  styleUrls: ['./scatter.component.scss']
})

export class ScatterComponent implements OnInit {

  @Input()
    customerName: string[];

  @Input()
    customerMotiveScore: MotiveScore[][];

  chart: Chart;
  chartDataPoints: ChartPoint[][] = [];
  chartDataSets: ChartDataSets;
  axesLabels: string[] = ['Motive', 'Score'];
  borderColor: string[] = ChartColor.getChartColors(0.6);
  backgroundColor: string[] = ChartColor.getChartColors(0.3);
  motiveLabels: string[] = [];
  motiveButtons: MotiveButton[] = [];
  unselected: number[] = [];
  isAxesSwitched: boolean = false;
  isLineShown: boolean = false;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.computeMotiveButtons();
    this.computeMotiveLabels();
    this.buildChart();
    this.initChartDataPoints();
    this.initChartDataSets();
  }

  computeMotiveLabels(): void {
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        this.motiveLabels.push(this.translatorService.translate('motive.' + m.toLowerCase()));
      }
    }
    );
  }

  updateTicksAndLabel(motiveScore: MotiveScore[]): void {
    this.motiveLabels = [];
    const xMax = motiveScore.length - 1;
    motiveScore.forEach((m: MotiveScore) =>
      this.motiveLabels.push(this.translatorService.translate('motive.' + m.motive.toLowerCase())));

    if (!this.isAxesSwitched) {
      this.chart.options.scales.xAxes[0].ticks = {
        beginAtZero: true,
        max: xMax,
        stepSize: 1,
        callback: (value, i) => this.motiveLabels[i]
      };
    }
    else {
      this.chart.options.scales.yAxes[0].ticks = {
        beginAtZero: true,
        max: xMax,
        stepSize: 1,
        callback: (value, i) => this.motiveLabels[xMax - i]
      };
    }

  }

  computeMotiveButtons(): void {
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        this.motiveButtons.push({
          motive: i,
          displayName: this.translatorService.translate('motive.' + m.valueOf().toLowerCase())
        });
      }
    });
  }

  toggleMotive(motive: number): void {
    const isUnselected: number | undefined = this.unselected.find(m => m === motive);
    if (isUnselected === undefined) {
      this.unselected.push(motive);
      this.initChartDataPoints();
      this.initChartDataSets();
    }
    else {
      this.unselected = this.unselected.filter(m => m !== motive);
      this.initChartDataPoints();
      this.initChartDataSets();
    }
  }

  isSelected(motive: number): boolean {
    const result = this.unselected.find(m => m === motive);
    return result === undefined;
  }

  initChartDataPoints(): void {
    this.chartDataPoints = [];
    this.customerMotiveScore.forEach((motiveScoreArray: MotiveScore[], index) => {
      const chartPointArray: ChartPoint[] = [];

      const filteredMotiveArray = motiveScoreArray.filter((m) =>
        this.unselected.find(filtered => filtered === m.motiveIndex) === undefined
      );

      this.updateTicksAndLabel(filteredMotiveArray);
      filteredMotiveArray.forEach((motiveScore: MotiveScore, index: number) => {
        chartPointArray.push({
          x: this.isAxesSwitched ? motiveScore.score : index,
          y: this.isAxesSwitched ? index : motiveScore.score
        });
      });
      this.chartDataPoints.push(chartPointArray);
    });
  }

  buildChart(): void {
    this.chart = new Chart('canvas', {
      type: 'scatter',
      options: {
        // TODO: switch aspectRatio based on isAxesSwitched (horizontal/vertical view)
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                labelString: this.axesLabels[0],
                display: true
              },
              ticks: {
                beginAtZero: true,
                max: this.motiveLabels.length,
                stepSize: 1,
                callback: (value, i) => this.motiveLabels[i]
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                labelString: this.axesLabels[1],
                display: true
              },
              ticks: { // Score: 1-10
                min: 1,
                max: 10,
                stepSize: 1
              }
            }
          ]
        }
      }
    });
  }

  initChartDataSets(): void {
    const chartDataSets: ChartDataSets[] = [];
    this.customerName.forEach((value, index) => {
      chartDataSets.push({
        label: value,
        data: this.chartDataPoints[index],
        backgroundColor: this.backgroundColor[index],
        borderColor: this.borderColor[index],
        showLine: this.isLineShown,
        lineTension: 0,
        fill: false
      });
    });
    this.chart.data.datasets = chartDataSets;
    this.chart.update();
  }

  switchAxes(): void {
    this.isAxesSwitched = !this.isAxesSwitched;
    const axes = this.chart.options.scales.xAxes;
    this.chart.options.scales.xAxes = this.chart.options.scales.yAxes;
    this.chart.options.scales.yAxes = axes;
    this.initChartDataPoints();
    this.initChartDataSets();
    this.chart.update();
  }

  toggleLines(): void {
    this.isLineShown = !this.isLineShown;
    this.chart.data.datasets.forEach(dataset => dataset.showLine = this.isLineShown);
    this.chart.update();
  }
}
