import {TestOriginBuyRepresentation} from './test-origin-buy-representation';
import {AbstractTestOrigin} from './abstract-test-origin';

export class TestOriginBuy extends AbstractTestOrigin {

  private readonly _orderId: string;

  constructor(orderId: string) {
    super();
    this._orderId = orderId;
  }

  get orderId(): string {
    return this._orderId;
  }

  static buildFrom(testOriginBuyRepresentation: TestOriginBuyRepresentation): TestOriginBuy {
    return new TestOriginBuy(
      testOriginBuyRepresentation.orderId as string
    );
  }

  isBuy(): boolean {
    return true;
  }

  isImport(): boolean {
    return false;
  }

  isInvitation(): boolean {
    return false;
  }

  isUnknown(): boolean {
    return false;
  }

}
