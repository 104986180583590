import {NgModule} from '@angular/core';
import {ModalComponent} from './modal/modal.component';
import {ModalTitleComponent} from './modal-title/modal-title.component';
import {ModalBodyComponent} from './modal-body/modal-body.component';
import {ModalFooterComponent} from './modal-footer/modal-footer.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {ModalBackdropComponent} from './modal-backdrop/modal-backdrop.component';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule
  ],
  declarations: [
    ModalComponent,
    ModalTitleComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ModalBackdropComponent,
    ModalDialogComponent
  ],
  exports: [
    ModalComponent,
    ModalTitleComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ModalBackdropComponent
  ]
})
export class ModalModule {
}
