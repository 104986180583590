import {PositionsSummaryRepresentation} from './positions-summary-representation';

export class PositionsSummary {

  private readonly _netSumAsNumber: number;
  private readonly _netSumAsMoney: string;
  private readonly _savingsAsNumber: number;
  private readonly _savingsAsMoney: string;
  private readonly _vatLossesAsNumber: number;
  private readonly _vatLossesAsMoney: string;
  private readonly _grossSumAsNumber: number;
  private readonly _grossSumAsMoney: string;

  constructor(netSumAsNumber: number,
              netSumAsMoney: string,
              savingsAsNumber: number,
              savingsAsMoney: string,
              vatLossesAsNumber: number,
              vatLossesAsMoney: string,
              grossSumAsNumber: number,
              grossSumAsMoney: string) {
    this._netSumAsNumber = netSumAsNumber;
    this._netSumAsMoney = netSumAsMoney;
    this._savingsAsNumber = savingsAsNumber;
    this._savingsAsMoney = savingsAsMoney;
    this._vatLossesAsNumber = vatLossesAsNumber;
    this._vatLossesAsMoney = vatLossesAsMoney;
    this._grossSumAsNumber = grossSumAsNumber;
    this._grossSumAsMoney = grossSumAsMoney;
  }

  get netSumAsNumber(): number {
    return this._netSumAsNumber;
  }

  get netSumAsMoney(): string {
    return this._netSumAsMoney;
  }

  get savingsAsNumber(): number {
    return this._savingsAsNumber;
  }

  get savingsAsMoney(): string {
    return this._savingsAsMoney;
  }

  get vatLossesAsNumber(): number {
    return this._vatLossesAsNumber;
  }

  get vatLossesAsMoney(): string {
    return this._vatLossesAsMoney;
  }

  get grossSumAsNumber(): number {
    return this._grossSumAsNumber;
  }

  get grossSumAsMoney(): string {
    return this._grossSumAsMoney;
  }

  static buildFrom(positionsSummaryRepresentation: PositionsSummaryRepresentation): PositionsSummary {
    return new PositionsSummary(
      Number(positionsSummaryRepresentation.netSumAsNumber.replace(',', '.')),
      positionsSummaryRepresentation.netSumAsMoney,
      Number(positionsSummaryRepresentation.savingsAsNumber.replace(',', '.')),
      positionsSummaryRepresentation.savingsAsMoney,
      Number(positionsSummaryRepresentation.vatLossesAsNumber.replace(',', '.')),
      positionsSummaryRepresentation.vatLossesAsMoney,
      Number(positionsSummaryRepresentation.grossSumAsNumber.replace(',', '.')),
      positionsSummaryRepresentation.grossSumAsMoney
    );
  }

}
