import {QueryEntity} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {AccountData, AccountDataFunctions} from './account-data';
import {AccountDataStore} from './account-data-store';
import {Observable} from 'rxjs';
import {CurrentUser} from '../user/current-user.service';
import {filter, map, take} from 'rxjs/operators';
import {AccountDataService} from './account-data-service';
import {CountryService} from '../user-data/country.service';

@Injectable({providedIn: 'root'})
export class AccountDataQuery extends QueryEntity<AccountData, AccountData, string> {

  constructor(protected store: AccountDataStore,
              private currentUser: CurrentUser,
              private accountDataService: AccountDataService,
              private countryService: CountryService) {
    super(store);
  }

  hasAccountDataForCurrentUser(): boolean {
    return this.hasAccountData(this.currentUser.keycloakId);
  }

  hasAccountData(userId: string): boolean {
    return this.hasEntity(userId);
  }

  selectAccountDataForCurrentUser(): Observable<AccountData> {
    return this.selectAccountData(this.currentUser.keycloakId);
  }

  selectAccountData(userId: string): Observable<AccountData> {
    if (!this.hasAccountData(userId)) {
      this.accountDataService.load(userId).pipe(take(1)).subscribe();
    }
    return this.selectEntity(userId).pipe(filter(data => !!data));
  }

  selectHumanReadableAccountData(userId: string): Observable<AccountData> {
    return this.selectAccountData(userId)
      .pipe(
        map((accountData: AccountData) =>
          !!accountData
            ? AccountDataFunctions.humanReadable(accountData, this.countryService, this.currentUser.locale)
            : undefined
        )
      );
  }

}
