import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {combineLatest, Observable} from 'rxjs';
import {ChatMessagesState, ChatMessagesStore, StoredContactChatMessages} from './chat-messages.store';
import {map} from 'rxjs/operators';
import {ChatMessage, chatMessageComparatorDesc, ChatMessageFunctions} from './chat-message';
import {FriendshipQuery} from '../../friend/state/friendship.query';
import {FriendshipChatMessages} from './friendship-chat-messages';
import {compareFriendNames, Friendship} from '../../friend/state/friendship';
import {CurrentUser} from '../../user/current-user.service';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';

@Injectable({providedIn: 'root'})
export class ChatMessagesQuery extends QueryEntity<ChatMessagesState, StoredContactChatMessages, string> {

  constructor(protected store: ChatMessagesStore,
              private friendshipQuery: FriendshipQuery,
              private currentUser: CurrentUser) {
    super(store);
  }

  selectUiOpened(): Observable<boolean> {
    return this.select((state: ChatMessagesState) => state.ui.open);
  }

  isUiOpened(): boolean {
    return this.store.getValue().ui.open;
  }

  selectContactsWithMessagesSortedDesc(): Observable<Friendship[]> {
    return combineLatest([
      this.selectAll(),
      this.friendshipQuery.selectApprovedFriendships()
    ]).pipe(
      map(data => data[0]),
      map((stored: StoredContactChatMessages[]) =>
        stored.filter((entry: StoredContactChatMessages) =>
          !!entry && !!entry.chatMessages && entry.chatMessages.length > 0)),
      map((stored: StoredContactChatMessages[]) =>
        stored.map(entry => entry.chatMessages[entry.chatMessages.length - 1])),
      map((latestMessages: ChatMessage[]) =>
        latestMessages.sort(chatMessageComparatorDesc)),
      map((latestMessages: ChatMessage[]) =>
        latestMessages.map(message =>
          // This may return undefined, as there might be messages for which a friendship no longer exists.
          // TODO: Optimize performance.
          this.friendshipQuery.getFriendshipWith(
            ChatMessageFunctions.getContactUserId(message, this.currentUser.keycloakId)
          ))),
      // Filtering the array is therefore necessary.
      map((friendships: Friendship[]) =>
        friendships.filter(friendship => !!friendship))
    );
  }

  selectContactsWithoutMessages(): Observable<Friendship[]> {
    return combineLatest([
      this.selectAll(),
      this.friendshipQuery.selectApprovedFriendships()
    ]).pipe(
      map(data => data[1].filter(friendship => {
        const friendUserId: string = FriendshipFunctions.getFriendUserId(friendship, this.currentUser.keycloakId);
        for (const chat of data[0]) {
          if (chat.contactUserId === friendUserId && !!chat.chatMessages && chat.chatMessages.length > 0) {
            return false;
          }
        }
        return true;
      })),
      map(friendships => friendships.sort(compareFriendNames(this.currentUser.keycloakId)))
    );
  }

  selectAmountOfUnreadMessages(): Observable<number> {
    return this.selectAll().pipe(
      map((arr: StoredContactChatMessages[]) => arr.map(messages => messages.unreadCount)),
      map((arr: number[]) => arr.reduce((a, b) => a + b, 0))
    );
  }

  selectAmountOfUnreadMessagesForContact(contactUserId: string): Observable<number> {
    return this.selectEntity(contactUserId).pipe(
      map((entity: StoredContactChatMessages | undefined) => !!entity ? entity.unreadCount : 0)
    );
  }

  selectIsActive(contactUserId: string): Observable<boolean> {
    return this.selectActiveId().pipe(
      map(activeContactUserId => activeContactUserId === contactUserId)
    );
  }

  selectActiveContactChanges(): Observable<StoredContactChatMessages | undefined> {
    return this.selectActiveId().pipe(
      map((activeId: string) => this.getEntity(activeId))
    );
  }

  selectActiveContactMessages(): Observable<FriendshipChatMessages | undefined> {
    return this.selectActive().pipe(
      map((active: StoredContactChatMessages) => {
        if (!!active) {
          const data: FriendshipChatMessages = {
            friendship: this.friendshipQuery.getFriendshipWith(active.contactUserId),
            chatMessages: active.chatMessages
          };
          return data;
        }
        return undefined;
      })
    );
  }

  selectHasChatMessages(contactUserId: string): Observable<boolean> {
    return this.selectEntity(contactUserId).pipe(
      map((data) => !!data && !!data.chatMessages && data.chatMessages.length > 0)
    );
  }

  selectChatMessages(contactUserId: string): Observable<ChatMessage[] | undefined> {
    return this.selectEntity(contactUserId).pipe(map((data) => !!data
      ? data.chatMessages
      : undefined)
    );
  }

  selectLastChatMessage(contactUserId: string): Observable<ChatMessage | undefined> {
    return this.selectChatMessages(contactUserId).pipe(
      map((chatMessages) => !!chatMessages ?
        chatMessages.length > 0
          ? chatMessages[chatMessages.length - 1]
          : undefined
        : undefined
      )
    );
  }

}
