import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'id37-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss']
})
export class ModalBodyComponent {

  @Input()
    align: 'left' | 'center' | 'right' = 'center';

  @Input()
    showHTML: string = undefined;

  @HostBinding('class.aligned')
    aligned = true;

  @HostBinding('class.align-column')
    alignColumn = true;

  @HostBinding('class.align-left')
  get alignLeft() {
    return this.align === 'left';
  }

  @HostBinding('class.align-center')
  get alignCenter() {
    return this.align === 'center';
  }

  @HostBinding('class.align-right')
  get alignRight() {
    return this.align === 'right';
  }

}
