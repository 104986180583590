import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CalendlyEventScheduledRepresentation} from './state/CalendlyEventScheduledRepresentation';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {NewCalendlyEventScheduledRepresentation} from './state/NewCalendlyEventScheduledRepresentation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class CalendlyService {

  constructor(private http: HttpClient) {
  }

  storeScheduledEvent(calendlyEvent: NewCalendlyEventScheduledRepresentation): Observable<any> {
    return this.http.post<void>(
      API_URL + '/calendly/events/scheduled',
      calendlyEvent,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    );
  }

  getScheduledEvents(): Observable<CalendlyEventScheduledRepresentation[]> {
    return this.http.get<CalendlyEventScheduledRepresentation[]>(
      API_URL + '/calendly/events/scheduled',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }
}
