import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {from} from 'rxjs';
import {map, pairwise, toArray} from 'rxjs/operators';
import {MotiveScore, MotiveScores} from '../../../../test/state/motive-score';

export interface MotiveScoreConnection {
  motiveAIndex: number;
  motiveAScore: number;
  motiveBIndex: number;
  motiveBScore: number;
}

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-motive-score-connection]',
  templateUrl: './key-graphic-motive-score-connection.component.html',
  styleUrls: ['./key-graphic-motive-score-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicMotiveScoreConnectionComponent implements OnInit {

  @Input()
    motiveScores: MotiveScores[];

  @Input()
    selectedMotiveScore: MotiveScore | undefined = undefined;

  connections: MotiveScoreConnection[] = [];

  private readonly yBase = 120;
  private readonly xBase = 40;
  private readonly gridStep = 80;

  ngOnInit() {
    from(this.motiveScores)
      .pipe(
        pairwise(),
        map((pair: MotiveScores[]) => ({
          motiveAIndex: pair[0][1].motiveIndex,
          motiveAScore: pair[0][1].score,
          motiveBIndex: pair[1][1].motiveIndex,
          motiveBScore: pair[1][1].score
        })),
        toArray()
      )
      .subscribe(data => this.connections = data);
  }

  transformScore(score: number) {
    return ((score - 1) * this.gridStep) + this.xBase;
  }

  transformIndex(index: number) {
    return (index * this.gridStep) + this.yBase;
  }

}
