import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class LevelAdjustmentService {

  private levelAdjustmentSource = new Subject<boolean>();

  onLevelShouldBeAdjusted$: Observable<boolean> = this.levelAdjustmentSource.asObservable();

  adjustLevels(parentIsOdd: boolean): void {
    this.levelAdjustmentSource.next(parentIsOdd);
  }

}
