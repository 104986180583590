@if (canShowTestJayComponent) {
  <div class="testing-container">

  <svg xmlns="http://www.w3.org/2000/svg" height="8" viewBox="0 0 913 8" fill="none" class="full-width-svg">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H905C909.418 0 913 3.58172 913 8H0Z" fill="url(#paint0_linear_1722_3977)"/>
      <defs>
        <linearGradient id="paint0_linear_1722_3977" x1="18.7921" y1="7.91767" x2="913" y2="7.91767"
                    gradientUnits="userSpaceOnUse">
          <stop stop-color="#05F5FD"/>
          <stop offset="1" stop-color="#00FF3D"/>
        </linearGradient>
      </defs>
  </svg>


    <div class="testing-information-container">
      <img class="icon-svg" src="assets/icons/chatbot/JayIcon.svg" alt="chatbot icon"/>
      <span>
      <h3 class="subscription-headline" id="title">{{ __('testing.header') }}</h3>
      <p>{{ __('testing.information') }}</p>
    </span>
    </div>
    <span class="get-us-now-btn-container">
  <button
    class="id37-btn id37-btn-primary id37-btn-rounded-1 get-us-now-btn" (click)="openActivateJay()">{{ __('testing.getToKnowUsNow') }}</button>
  </span>

  </div>
}
