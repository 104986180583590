import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) {
  }

  public triggerSystemTestNotification(): Observable<void> {
    return this.http.post<void>(
      API_URL + '/service/trigger-system-test-notification',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }

  public triggerTestExpirationChecker(): Observable<void> {
    return this.http.post<void>(
      API_URL + '/service/trigger-test-expiration-checker',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }

  public triggerKeycloakUserCacheRefresher(): Observable<void> {
    return this.http.post<void>(
      API_URL + '/service/trigger-keycloak-user-cache-refresher',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }

  public triggerKeycloakUsernameAndEmailEqualityChecker(): Observable<void> {
    return this.http.post<void>(
      API_URL + '/service/trigger-keycloak-username-and-email-equality-checker',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    );
  }

}
