import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-distance-graphic]',
  templateUrl: './distance-graphic.component.html',
  styleUrls: ['./distance-graphic.component.scss']
})
export class DistanceGraphicComponent implements OnInit {

  @Input()
    normalizedMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    numberOfMotivesView: number;

  @Input()
    friendFirstname: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
