import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-internet-explorer',
  templateUrl: './internet-explorer.component.html',
  styleUrls: ['./internet-explorer.component.scss']
})
export class InternetExplorerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
