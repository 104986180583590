import {ItemRepresentation} from './item-representation';
import {AnsweredItemRepresentation} from './answered-item-representation';

export class Item {

  private readonly _id: number;

  constructor(id: number, motive: string, text: string, answer: number, answerSaved?: boolean | undefined) {
    this._id = id;
    this._motive = motive;
    this._text = text;
    this._answer = answer;
    this._answerSaved = typeof answerSaved === typeof undefined ? answer !== -1 : answerSaved;
  }

  private _motive: string;

  get motive(): string {
    return this._motive;
  }

  set motive(value: string) {
    this._motive = value;
  }

  private _text: string;

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  private _answer: number | undefined;

  get answer(): number | undefined {
    return this._answer;
  }

  set answer(value: number | undefined) {
    this._answer = value;
  }

  private _answerSaved: boolean | undefined;

  get answerSaved(): boolean | undefined {
    return this._answerSaved;
  }

  set answerSaved(value: boolean | undefined) {
    this._answerSaved = value;
  }

  get id(): number {
    return this._id;
  }

  static buildFrom(itemRepresentation: ItemRepresentation): Item {
    return new Item(
      itemRepresentation.id,
      itemRepresentation.motive,
      itemRepresentation.text,
      -1,
      undefined
    );
  }

  static buildFromAnsweredItemRepresentation(answeredItemRepresentation: AnsweredItemRepresentation): Item {
    return new Item(
      answeredItemRepresentation.item.id,
      answeredItemRepresentation.item.motive,
      answeredItemRepresentation.item.text,
      answeredItemRepresentation.answer,
      answeredItemRepresentation.answer !== -1
    );
  }

}
