import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'id37-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  @Output()
    checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  @HostBinding('class.checked')
    checked: boolean = false;

  @HostListener('click')
  click(): void {
    this.flip();
  }

  flip(): void {
    this.set(!this.checked);
  }

  set(value: boolean): void {
    this.checked = value;
    this.checkedChange.emit(value);
  }

}
