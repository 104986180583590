import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RolesAllowedDirective} from './roles-allowed.directive';
import {HasMasterDirective} from './has-master.directive';
import {FeatureAllowedDirective} from './feature-allowed.directive';
import {DefaultImageDirective} from './default-image.directive';
import {DropdownDirective} from './dropdown.directive';

@NgModule({
  declarations: [
    RolesAllowedDirective,
    HasMasterDirective,
    FeatureAllowedDirective,
    DefaultImageDirective,
    DropdownDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RolesAllowedDirective,
    HasMasterDirective,
    FeatureAllowedDirective,
    DefaultImageDirective,
    DropdownDirective
  ]
})
export class DirectivesModule {
}
