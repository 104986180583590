import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {Group} from '../../types/group-type';
import {TranslatorService} from '../../../../translation/translator.service';

@Component({
  selector: 'app-new-customers-list',
  templateUrl: './new-customers-list.component.html',
  styleUrls: ['./new-customers-list.component.scss']
})
export class NewCustomersListComponent {

  @Input()
    customerFormArray: FormGroup[];

  @Input()
    groups$: Observable<Group[]>;

  @Output()
    validate = new EventEmitter();

  constructor(private translatorService: TranslatorService) {
  }

  onDeleted(idx: number) {
    if (this.customerFormArray.length > 1) {
      this.customerFormArray.splice(idx, 1);
      this.checkValid();
    }
  }

  checkValid(): void {
    this.validate.emit();
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

}
