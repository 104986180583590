import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Layout} from '../../../../team/team.component';

@Component({
  selector: 'app-team-graphic-header',
  templateUrl: './team-graphic-header.component.html',
  styleUrls: ['./team-graphic-header.component.scss']
})
export class TeamGraphicHeaderComponent implements OnInit {

  @Input() layout: Layout;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {}

  __(key: string): string {
    return this.translatorService.translate('teamGraphic.' + key);
  }
}
