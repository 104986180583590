<svg [attr.width]="900" [attr.height]="90" x="0" [attr.y]="y">

  <defs>
    <linearGradient id="leftRight" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color=#05F5FD />
        <stop offset="100%" stop-color=#00FF3D />
    </linearGradient>
    <linearGradient id="rightLeft" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stop-color=#00FF3D />
        <stop offset="100%" stop-color=#05F5FD />
    </linearGradient>
  </defs>

  <line [attr.x1]="49.5+x1" y1="50" [attr.x2]="50.5 + x2" y2="50.001" [attr.stroke]=strokeClass stroke-width="33"
        stroke-linecap="round"/>
  <circle [attr.cx]="50+x1" cy=50 r="8" fill="white"/>
  <line [attr.x1]="55+x1" y1="50" [attr.x2]="45+x2" y2="50.01" stroke="white" stroke-width="2"/>
  <polygon [ngClass]="{'hide': distance === 0}" [attr.points]="tPoint1 + ' ' + tPoint2 + ' ' + tPoint3" fill="white"/>

</svg>

