<div *ngIf="!ongoingRequest">
  <button (click)="getNextPageOfUsers()" class="id37-btn-outline" type="button">
    Load Next Page ({{pageNumber}})
  </button>
</div>
<div *ngIf="ongoingRequest">
  <app-loading-indicator></app-loading-indicator>
</div>

<div class="clr-row clr-justify-content-center" style="margin-top: 2em">
  <div class="clr-col-xl-10 clr-col-lg-11 clr-col-md-12 clr-col-sm-12">
    <div class="id37-table-wrapper">
      <table class="id37-table">
        <tr>
          <th>Nr.</th>
          <th>Client</th>
          <th>Mail</th>
          <th>Referrer</th>
          <th>Registrierung</th>
          <th>erstellt am</th>
          <th>ge<wbr>kauft</th>
          <th>ge<wbr>startet</th>
          <th>abge<wbr>schlossen</th>
          <th>Master</th>
          <th>Master Mail</th>
          <th>Aktionen</th>
        </tr>
        <tr *ngFor="let client of clients; let i = index">
          <td class="xs-small">{{i + 1}}</td>
          <td class="big">{{client.name}}</td>
          <td class="medium">{{client.mail}}</td>
          <td class="small" [ngClass]="{'referrer-ww': client.referrer === 'WW'}">{{client.referrer}}</td>
          <td class="medium">{{client.registrationSource}}</td>
          <td class="medium">{{formatDate(client.creationDate)}}</td>
          <td class="small" [ngClass]="{'true-color': client.hasTest, 'false-color': !client.hasTest}">{{client.hasTest}}</td>
          <td class="small" [ngClass]="{'true-color': client.testStarted, 'false-color': !client.testStarted}">{{client.testStarted}}</td>
          <td class="small" [ngClass]="{'true-color': client.testFinished, 'false-color': !client.testFinished}">{{client.testFinished}}</td>
          <td class="big">{{client.masterName}}</td>
          <td class="big">{{client.masterEmail}}</td>
          <td class="medium">
            <button (click)="openDeleteModal(client)" class="id37-btn id37-btn-danger id37-btn-sm">Löschen</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<id37-modal *ngIf="selectedClient !== null" [(modalOpen)]="isDeleteModalVisible">
  <id37-modal-title>
    Möchten Sie {{selectedClient.name}} wirklich löschen?
  </id37-modal-title>
  <id37-modal-body>
    Diese Entscheidung kann nicht rückgängig gemacht werden.
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="deleteClientOnServer(selectedClient); closeDeleteModal()" class="id37-btn id37-btn-danger"
              type="button">
        Löschen
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn id37-btn-secondary" type="button">
        Abbrechen
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
