import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ChatbotChatUser} from '../state/chatbot-chat-user';


const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotUserService {

  constructor(private http: HttpClient) {
  }

  public checkChatbotUserExists(): Observable<boolean> {
    return this.http.get<boolean>(
      API_URL + '/users/check_exists',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(tap((exists: boolean) => exists));
  }

  public createChatbotUser(chatbotChatUser: ChatbotChatUser): Observable<ChatbotChatUser> {
    return this.http.post<ChatbotChatUser>(
      API_URL + '/users/add',
      chatbotChatUser
    ).pipe(tap((user: ChatbotChatUser) => user));
  }

  public getChatbotUserByUserID(): Observable<ChatbotChatUser> {
    return this.http.get<ChatbotChatUser>(
      API_URL + '/users/get',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;'
        })
      }
    ).pipe(tap((chatbotChatUser: ChatbotChatUser) => chatbotChatUser));
  }

  public updateChatbotUser(chatbotChatUser: ChatbotChatUser): Observable<ChatbotChatUser> {
    return this.http.post<ChatbotChatUser>(
      API_URL + '/users/update',
      chatbotChatUser
    ).pipe(tap((user: ChatbotChatUser) => user));
  }
}
