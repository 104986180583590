<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <h2 id="title">
      {{__('title')}}
    </h2>

    <div class="dashboard-btn-container">
      <div class="dashboard-btn-wrapper">
        <button [routerLink]="['/toolbox', 'import']" class="dashboard-btn">
          <img alt="{{__('import')}}" class="icon-svg" src="assets/icons/ID37_AnalyseImport_100x100.svg"/>
          <span class="icon-text">{{__('import')}}</span>
        </button>
      </div>

      <div class="dashboard-btn-wrapper">
        <button [routerLink]="['/toolbox', 'compare']" class="dashboard-btn">
          <img alt="{{__('compare')}}" class="icon-svg" src="assets/icons/ID37_Profilvergleich_100x100.svg"/>
          <span class="icon-text">{{__('compare')}}</span>
        </button>
      </div>

      <div *featureAllowed="'test-reset'" class="dashboard-btn-wrapper">
        <button [routerLink]="['/test-reset']" class="dashboard-btn">
          <img alt="{{__('testReset')}}" class="icon-svg" src="assets/icons/ID37_Toolbox_100x100.svg"/>
          <span class="icon-text">{{__('testReset')}}</span>
        </button>
      </div>
      <ng-container *rolesAllowed="'administrator'">
        <div class="dashboard-btn-wrapper">
          <button [routerLink]="['/toolbox', 'chatbot-documents']" class="dashboard-btn">
            <img alt="{{__('chatbot-documents')}}" class="icon-svg" src="assets/icons/ID37_Doc_Export_100x100.svg"/>
            <span class="icon-text">{{__('chatbot-documents')}}</span>
          </button>
        </div>
      </ng-container>
    </div>

  </div>
</div>
