import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Team} from './team';
import {Injectable} from '@angular/core';

export interface TeamState extends EntityState<Team, string> {
  selectedTeam: Team;
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'team'})
export class TeamStore extends EntityStore<TeamState> {

  constructor() {
    super({selectedTeam: undefined});
  }

}
