import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Choice} from '../state/choices-types';
import {SelectState} from '../state/select-state';

@Component({
  selector: 'app-select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss']
})
export class SelectItemComponent implements OnInit {

  @Input()
    choice: Choice;

  @Input()
    preSelected: boolean;

  @Output()
    select: EventEmitter<Choice> = new EventEmitter<Choice>();

  @Output()
    deselect: EventEmitter<Choice> = new EventEmitter<Choice>();

  constructor(public state: SelectState) {
  }

  ngOnInit() {
  }

  doSelect(): void {
    this.select.emit(this.choice);
  }

  doDeselect(): void {
    this.deselect.emit(this.choice);
  }

}
