import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {MasterInfo} from '../../master/master-info';
import {Constants} from '../../shared/constants';

@Component({
  selector: 'app-master-info',
  templateUrl: './master-info.component.html',
  styleUrls: ['./master-info.component.scss']
})
export class MasterInfoComponent implements OnInit {

  @Input()
    masterInfo: MasterInfo;

  findMasterUrl = Constants.FIND_MASTER_URL;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit() {
  }

  __(key: string): string {
    return this.translatorService.translate('my-account.' + key);
  }

}
