import {AbstractTestOriginRepresentation} from '../origin/abstract-test-origin-representation';
import {Test} from './test';
import {AbstractTestOrigin} from '../origin/abstract-test-origin';
import {TestOriginType} from '../origin/test-origin-type';
import {TestOriginBuy} from '../origin/test-origin-buy';
import {TestOriginBuyRepresentation} from '../origin/test-origin-buy-representation';
import {TestOriginInvitation} from '../origin/test-origin-invitation';
import {TestOriginInvitationRepresentation} from '../origin/test-origin-invitation-representation';
import {TestOriginImport} from '../origin/test-origin-import';
import {TestOriginImportRepresentation} from '../origin/test-origin-import-representation';
import {TestOriginUnknown} from '../origin/test-origin-unknown';
import {TestOriginUnknownRepresentation} from '../origin/test-origin-unknown-representation';
import {TestVariation} from './test-variation';
import {TestResultRepresentation} from './test-result-representation';

export class TestRepresentation {

  public readonly testId: string;
  public readonly variation: string;
  public readonly variationUpdateAllowed: boolean;
  public readonly expirationDate: string | null;
  public readonly started: boolean;
  public readonly finished: boolean;
  public readonly accessGranted: boolean;
  public readonly result: TestResultRepresentation | undefined;
  public readonly origin: AbstractTestOriginRepresentation;
  public readonly creationDate: string;

  public static toModelEntity(testRepresentation: TestRepresentation): Test {
    let origin: AbstractTestOrigin;
    if (testRepresentation.origin.type === TestOriginType.BUY) {
      origin = TestOriginBuy.buildFrom(testRepresentation.origin as TestOriginBuyRepresentation);
    }
    else if (testRepresentation.origin.type === TestOriginType.INVITATION) {
      origin = TestOriginInvitation.buildFrom(testRepresentation.origin as TestOriginInvitationRepresentation);
    }
    else if (testRepresentation.origin.type === TestOriginType.IMPORT) {
      origin = TestOriginImport.buildFrom(testRepresentation.origin as TestOriginImportRepresentation);
    }
    else if (testRepresentation.origin.type === TestOriginType.UNKNOWN) {
      origin = TestOriginUnknown.buildFrom(testRepresentation.origin as TestOriginUnknownRepresentation);
    }
    else {
      console.error('Unable to parse test origin. Unknown type.');
    }

    return {
      testId: testRepresentation.testId,
      variation: TestVariation.fromName(testRepresentation.variation),
      variationUpdateAllowed: testRepresentation.variationUpdateAllowed,
      expirationDate: !!testRepresentation.expirationDate
        ? new Date(testRepresentation.expirationDate)
        : undefined,
      started: testRepresentation.started,
      finished: testRepresentation.finished,
      accessGranted: testRepresentation.accessGranted,
      result: !!testRepresentation.result
        ? TestResultRepresentation.toModelEntity(testRepresentation.result)
        : undefined,
      origin,
      creationDate: new Date(testRepresentation.creationDate)
    };
  }

}

