import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Test} from './test';

export type TestState = EntityState<Test>;

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'test'
})
// The key is a string and consists of the userId and testId, concatenated with a ":".
export class TestStore extends EntityStore<TestState, Test, string> {

  constructor() {
    super();
  }

}
