<div #container class="container">
    <!-- Question -->
    <ng-container *ngIf="chatQuestion">
        <div class="avatar-container">
            <app-profile-picture></app-profile-picture>
        </div>
        <div class="message-container">
            <div class="chat-message chat-message-question">
                {{chatQuestion.question}}
            </div>
        </div>
    </ng-container>

    <!-- Anwser -->
    <ng-container *ngIf="chatMessage">
        <div class="avatar-container">
            <img class="icon-svg" src="assets/icons/chatbot/JayIcon.svg"
                 alt="chatbot icon"/>
        </div>
        <div class="message-container">
            <div class="chatbot-name">
                Jay
            </div>
            <div markdown class="chat-message chat-message-answer"
                 [data]="chatMessage.answer">
            </div>
            <div class="reaction-container" *ngIf="chatMessage.question_id != -1">
                <div class="reaction" (click)="rateAnswerPositive()">
                    <img class="icon-svg"
                         [src]="chatMessage.answer_rating === 1 ? 'assets/icons/ID37_Chatbot_ThumbsUp_Filled.svg' : 'assets/icons/ID37_Chatbot_ThumbsUp.svg'"
                         alt="thumbs-up icon"/>
                </div>
                <div class="reaction" (click)="rateAnswerNegative()">
                    <img class="icon-svg"
                         [src]="chatMessage.answer_rating === -1 ? 'assets/icons/ID37_Chatbot_ThumbsDown_Filled.svg' : 'assets/icons/ID37_Chatbot_ThumbsDown.svg'"
                         alt="thumbs-down icon"/>
                </div>
                <div class="reaction" (click)="!isCopied && copyAnswer()" [title]="__('copyTooltip')">
                    <img class="icon-svg"
                         [src]="isCopied ? 'assets/icons/ID37_Chatbot_Clipboard_Filled.svg' : 'assets/icons/ID37_Chatbot_Clipboard_Outline.svg'"
                         alt="clipboard icon"/>
                </div>
                <!-- TODO: Reactivate Reload
                <div class="reaction" (click)="">
                    <img class="icon-svg" src="assets/icons/ID37_Chatbot_Reload.svg"
                         alt="reload icon"/>
                </div>-->
            </div>
        </div>
    </ng-container>

</div>

<!-- Modal Dialog -->
<ng-container *ngIf="chatMessage">
  <id37-modal [(modalOpen)]="additionalFeedbackDialog" [modalSize]="'normal'">
    <id37-modal-title [align]="'left'">
      <img [src]="chatMessage.answer_rating === -1 ? 'assets/icons/chatbot/ChatbotFeedbackDislikeIcon.svg' : 'assets/icons/chatbot/ChatbotFeedbackLikeIcon.svg'"  alt="Feedback icon"/>
      <span>
        {{__('feedbackTitle')}}
      </span>
    </id37-modal-title>
    <id37-modal-body>
      <textarea autosize [maxRows]="10" type="text" [(ngModel)]="comment" placeholder="{{__('feedbackPlaceholder')}}"></textarea>
    </id37-modal-body>
    <id37-modal-footer [align]="'right'">
      <button class="id37-btn id37-btn-primary id37-btn-rounded-1" (click)="commentAnswer()">
        <span>{{__('send')}}</span>
      </button>
    </id37-modal-footer>
  </id37-modal>
</ng-container>

