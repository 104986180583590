import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-circular-ring',
  templateUrl: './circular-ring.component.html',
  styleUrls: ['./circular-ring.component.scss']
})
export class CircularRingComponent implements OnInit {

  animate: boolean = false;
  animationRunning: boolean = false;
  animationTimeout: number;

  @Input()
    svgFill: boolean = false;

  @Input()
  set animated(animated: boolean) {
    this.animate = animated;
    this.evaluate();
  }

  @Input()
    animationSpeed: number = 0.006;

  @Input()
    animationUpdatesPerSecond: number = 60;

  targetProgress: number = 0.0;

  @Output()
    updateProgress = new EventEmitter<number>();

  currentProgress: number = 0.0;

  @Input()
    initialProgress: number = 0.0;

  @Input()
  set progress(progress: number) {
    if (progress !== this.targetProgress) {
      this.targetProgress = progress;
      this.evaluate();
    }
  }

  @Input()
    startColor: string = '#05F5FD';

  @Input()
    middleColor: string = '#02fa9d';

  @Input()
    endColor: string = '#00FF3D';

  // Must equal the width of the profile picture border (in px).
  private _circleStrokeWidth: number = 7.5;

  @Input()
  set circleStrokeWidth(circleStrokeWidth: number) {
    this._circleStrokeWidth = circleStrokeWidth;
    this.computeValues();
  }

  get circleStrokeWidth(): number {
    return this._circleStrokeWidth;
  }

  // Must equal the width/height of the profile picture (in px).
  private _circleSizePx: number = 125;

  @Input()
  set circleSizePx(circleSizePx: number) {
    this._circleSizePx = circleSizePx;
    this.computeValues();
  }


  get circleSizePx(): number {
    return this._circleSizePx;
  }

  circleBorderPx: number = 0;
  centerX: number = 0;
  centerY: number = 0;
  radius: number = 0;
  rightCircleX: number = 0;
  rightCircleY: number = 0;
  leftCircleX: number = 0;
  leftCircleY: number = 0;
  enableLeftCircle: boolean;

  constructor() {
  }

  ngOnInit(): void {
    this.computeValues();
    this.calculateCircle();
  }

  updateCurrentProgress(currentProgress: number): void {
    this.currentProgress = currentProgress;
    this.updateProgress.emit(currentProgress);
  }

  resetProgressWithoutAnimation(): void {
    this.updateCurrentProgress(this.initialProgress);
    this.calculateCircle();
  }

  computeValues() {
    this.circleBorderPx = this.circleStrokeWidth / 2.0;
    this.centerX = this.circleSizePx / 2;
    this.centerY = this.circleSizePx / 2;
    this.radius = this.circleSizePx / 2 - this.circleBorderPx;

    this.evaluate();
  }

  evaluate(): void {
    if (this.animate === true) {
      const animationStart: number = this.currentProgress;
      this.animateCircle(animationStart);
    }
    else {
      this.updateCurrentProgress(this.targetProgress);
      this.calculateCircle();
    }
  }

  // targetProgress must be set before calling this method.
  animateCircle(progress: number): void {
    if (this.animationRunning) {
      this.cancelAnimation();
    }
    this.animationRunning = true;
    if (progress < this.targetProgress) {
      this._animateCircleUp(progress);
    }
    else {
      this._animateCircleDown(progress);
    }
  }

  private _animateCircleUp(progress: number): void {
    if (progress <= this.targetProgress) {
      this.animationTimeout = setTimeout(() => {
        this.updateCurrentProgress(progress);
        this.calculateCircle();
        this._animateCircleUp(progress + this.animationSpeed);
      }, 1000 / this.animationUpdatesPerSecond)  as unknown as number;
    }
    else {
      this.finishAnimation();
    }
  }

  private _animateCircleDown(progress: number): void {
    if (progress >= this.targetProgress) {
      this.animationTimeout = setTimeout(() => {
        this.updateCurrentProgress(progress);
        this.calculateCircle();
        this._animateCircleDown(progress - this.animationSpeed);
      }, 1000 / this.animationUpdatesPerSecond) as unknown as number;
    }
    else {
      this.finishAnimation();
    }
  }

  finishAnimation(): void {
    this.updateCurrentProgress(this.targetProgress);
    this.calculateCircle();
    this.animationRunning = false;
  }


  cancelAnimation(): void {
    clearTimeout(this.animationTimeout);
    this.animationRunning = false;
  }

  calculateCircle(): void {
    let angleInDegrees = this.currentProgress * 360;
    if (angleInDegrees < 180) {
      angleInDegrees = 90 + (90 - angleInDegrees);
    }
    else {
      this.enableLeftCircle = true;
      angleInDegrees = 360;
    }
    let angleInRadians = angleInDegrees * Math.PI / 180.0;
    this.rightCircleX = this.centerY + this.radius * Math.sin(angleInRadians);
    this.rightCircleY = this.centerX + this.radius * Math.cos(angleInRadians);

    if (this.currentProgress > 0.5) {
      angleInRadians = (360 * (1 - this.currentProgress - 0.5)) * Math.PI / 180;
      this.leftCircleX = this.centerY + this.radius * Math.sin(angleInRadians);
      this.leftCircleY = this.centerX + this.radius * Math.cos(angleInRadians);
    }
    else {
      this.enableLeftCircle = false;
    }
  }

}
