<div [attr.aria-describedby]="tooltipUsername" [ngStyle]="getStyles()" class="bubble" >

  <ng-container *ngIf="hasProfilePicture$ | async as profilePicture; else noPic">
    <app-profile-picture *ngIf="profilePicture.base64; else noPic"
                         [allowAccountDataRetrieval]="false"
                         [userId]="bubbleScore.keycloakId"></app-profile-picture>
  </ng-container>

  <ng-template #noPic>
      <span [ngClass]="{'name-initial': nameBubble}">
        {{name}}
      </span>
  </ng-template>

</div>
