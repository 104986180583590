import {FormControl, ValidationErrors} from '@angular/forms';

export abstract class CustomValidators {

  public static noWhitespace(control: FormControl): ValidationErrors {
    let isOnlyWhitespace = false;
    if (!!control.value && control.value.length !== 0 && control.value.trim().length === 0) {
      isOnlyWhitespace = true;
    }
    const isValid = !isOnlyWhitespace;
    return isValid ? null : {whitespace: true};
  }

}
