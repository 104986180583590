<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h2 id="title">{{__('myAccount')}}</h2>

    <ng-container *rolesAllowed="['master', 'partner']; not 'administrator'">
      <h3 class="section-start">{{__('masterOverview')}}</h3>
      <div class="info-box">
        <app-master-report style="display: block; margin: 0.3em;"></app-master-report>
      </div>
    </ng-container>

    <div *rolesAllowed="'master'; not 'administrator'" class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="billing-address">{{__('billingAddress')}}</label>
          <input [formControl]="accountDataForm.controls['billingAddress']" id="billing-address" type="text">
        </div>
      </div>
    </div>

    <h3 class="section-start">{{__('personal')}}</h3>

    <app-profile-picture-manager></app-profile-picture-manager>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="first-name">{{__('firstName')}}</label>
          <input [formControl]="accountDataForm.controls['firstName']" id="first-name" type="text">
        </div>
      </div>
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="last-name">{{__('lastName')}}</label>
          <input [formControl]="accountDataForm.controls['lastName']" id="last-name" type="text">
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="email">{{__('email')}}</label>
          <input [formControl]="accountDataForm.controls['email']" id="email" type="text">
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="date-of-birth">{{__('dateOfBirth')}}</label>
          <app-datetime
              [formControl]="accountDataForm.controls['dateOfBirth']"
              [formPropagationType]="'isoFormatted'"
              [formPropagationZone]="'localeZone'"
              [guideSelectionMode]="'yearFirst'"
              [maxDate]="maxDate"
              [minDate]="minDate"
              [placeholder]="__('dateOfBirthPlaceholder')"
              [type]="'date'"
              id="date-of-birth">
          </app-datetime>
        </div>
      </div>
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="gender">{{__('gender')}}</label>
          <app-select
              [choices]="genderChoices"
              [formControl]="accountDataForm.controls['gender']"
              [placeholder]="__('genderPlaceholder')"
              id="gender">
          </app-select>
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="residence">{{__('residence')}}</label>
          <app-select
              [choicesGroups]="countryChoices"
              [formControl]="accountDataForm.controls['residence']"
              [placeholder]="__('residencePlaceholder')"
              id="residence">
          </app-select>
        </div>
      </div>
      <div class="clr-col-md-6">
        <div class="clr-form-group">
          <label for="origin">{{__('origin')}}</label>
          <app-select
              [choicesGroups]="nationalityChoices"
              [formControl]="accountDataForm.controls['origin']"
              [placeholder]="__('originPlaceholder')"
              id="origin">
          </app-select>
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="city">{{__('city')}}</label>
          <input [formControl]="accountDataForm.controls['city']"
                 id="city"
                 placeholder="{{__('cityPlaceholder')}}"
                 type="text">
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-form-group">
          <label for="mobile-phone-number">{{__('mobilePhoneNumber')}}</label>
          <input [formControl]="accountDataForm.controls['mobilePhone']"
                 id="mobile-phone-number"
                 placeholder="{{__('mobilePhoneNumberPlaceholder')}}"
                 type="text">
        </div>
      </div>
    </div>

    <h3 class="section-start">{{__('jobAndEducation')}}</h3>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="academic-degree">{{__('academicDegree')}}</label>
          <app-select
              [choices]="academicDegreeChoices"
              [formControl]="accountDataForm.controls['academicDegree']"
              [placeholder]="__('academicDegreePlaceholder')"
              id="academic-degree">
          </app-select>
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="academic-title">{{__('academicTitle')}}</label>
          <input [formControl]="accountDataForm.controls['academicTitle']"
                 id="academic-title"
                 type="text">
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="industry">{{__('industry')}}</label>
          <app-select
              [choices]="industryChoices"
              [formControl]="accountDataForm.controls['industry']"
              [placeholder]="__('industryPlaceholder')"
              id="industry">
          </app-select>
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="company">{{__('company')}}</label>
          <input [formControl]="accountDataForm.controls['company']"
                 id="company"
                 placeholder="{{__('companyPlaceholder')}}"
                 type="text">
        </div>
      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <label for="profession">{{__('profession')}}</label>
          <app-select
              [choices]="professionChoices"
              [formControl]="accountDataForm.controls['profession']"
              [placeholder]="__('professionPlaceholder')"
              id="profession">
          </app-select>
        </div>
      </div>
    </div>

    <h3 class="section-start">{{__('optIns')}}</h3>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col">
        <div class="clr-form-group">
          <app-email-setting-management></app-email-setting-management>
        </div>
      </div>
    </div>


    <ng-container>
      <h3 class="section-start">{{__('yourMaster')}}</h3>
      <div class="clr-row clr-justify-content-center">
        <div class="clr-col">
          <app-master-info
              [masterInfo]="accountData ? accountData.masterInfo : undefined"
              style="display: block; margin: 0.3em;">
          </app-master-info>
        </div>
      </div>
    </ng-container>

    <div class="save-changes">
      <div class="save-btn-row">
        <button (click)="saveChanges()" class="id37-btn-primary">
          {{__('saveChanges')}}
        </button>
        <app-loading-indicator *ngIf="saveOngoing"></app-loading-indicator>
      </div>
      <div *ngIf="showSaveSuccessfulMsg" class="alert success">
        {{__('saveSuccessful')}}
      </div>
      <div *ngIf="showSaveUnsuccessfulMsg" class="alert warning">
        {{__('saveUnsuccessful')}}
      </div>
    </div>

    <div class="button-row-below">
      <button *ngIf="!canRemoveAssociatedMaster()"
              class="id37-btn-outline-secondary remove-master-btn disabled">
        {{__('removeAssociatedMaster')}}
      </button>

      <button (click)="openRemoveMasterModal()"
              *ngIf="canRemoveAssociatedMaster()"
              class="id37-btn id37-btn-danger remove-master-btn">
        {{__('removeAssociatedMaster')}}
      </button>

      <button (click)="openDeleteModal()" class="id37-btn id37-btn-danger delete-account-btn">
        {{__('deleteMyAccount')}}
      </button>
    </div>

  </div>
</div>

<id37-modal *ngIf="criticalDataIsToBeChanged"
            [(modalOpen)]="criticalDataIsToBeChanged"
            id="critical-data-is-to-be-changed-modal">
  <id37-modal-title>
    {{__('criticalDataIsToBeChanged.title')}}
  </id37-modal-title>
  <id37-modal-body>
    <div>
      {{__('criticalDataIsToBeChanged.body')}}
    </div>
    <div class="changed-fields-overview">
      <div *ngIf="firstNameIsToBeChanged()">
        <span class="text-bold">{{__('firstName')}}</span>:
        {{this.accountData.personalData.firstName}} &rarr; <span
          class="text-bold">{{this.dataToSave.firstName}}</span>
      </div>
      <div *ngIf="lastNameIsToBeChanged()">
        <span class="text-bold">{{__('lastName')}}</span>:
        {{this.accountData.personalData.lastName}} &rarr; <span
          class="text-bold">{{this.dataToSave.lastName}}</span>
      </div>
      <div *ngIf="emailIsToBeChanged()">
        <span class="text-bold">{{__('email')}}</span>:
        {{this.accountData.personalData.email}} &rarr; <span class="text-bold">{{this.dataToSave.email}}</span>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="saveAccountData(true); closeApproveCriticalChangesModal();" class="id37-btn-outline"
              type="button">
        {{__('criticalDataIsToBeChanged.applyChanges')}}
      </button>
      <button (click)="closeApproveCriticalChangesModal()" class="id37-btn-outline-secondary"
              type="button">
        {{__('criticalDataIsToBeChanged.abort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<id37-modal [(modalOpen)]="isRemoveMasterModalVisible">
  <id37-modal-title>
    {{__('removeAssociatedMasterAreYouSure')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('removeAssociatedMasterExplanation')}}
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="removeAssociatedMasterOnServer()" class="id37-btn-danger"
              type="button">
        {{__('removeAssociatedMasterAccept')}}
      </button>
      <button (click)="closeRemoveMasterModal()" class="id37-btn-outline-secondary"
              type="button">
        {{__('removeAssociatedMasterAbort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<id37-modal [(modalOpen)]="isDeleteModalVisible" id="delete-account-modal">
  <id37-modal-title>
    {{__('deleteMyAccountAreYouSure')}}
  </id37-modal-title>
  <id37-modal-body [align]="'left'">
    <div *ngIf="chatbotDeletionWarning">
      {{__('chatbotDeletionWarning')}}
    </div>
    <div>
      {{__('deleteMyAccountExplanation')}}
    </div>
    <div>
      {{__('deleteMyAccountConfirmationInfoA')}}
      <span style="white-space: nowrap">
        "<span style="font-weight: bold">{{__('deleteMyAccountInputToConfirm')}}</span>"
      </span>
      {{__('deleteMyAccountConfirmationInfoB')}}
      <input #confirmationInput [(ngModel)]="deleteConfirmationInput" placeholder="" type="text">
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button *ngIf="!deleteConfirmationInputMatches()"
              class="id37-btn-outline-secondary" type="button">
        {{__('deleteMyAccountWaitingForConfirmation')}}
      </button>
      <button (click)="deleteCurrentUserOnServer()"
              *ngIf="deleteConfirmationInputMatches()" class="id37-btn id37-btn-danger"
              type="button">
        {{__('deleteMyAccountAccept')}}
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn-outline-secondary"
              type="button">
        {{__('deleteMyAccountAbort')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
