<h3>{{__('glance.headline')}}</h3>
<div class="id37-text2 text-light" [innerHTML]="__('glance.text')"></div>

<ul class="id37-text2 text-light">

  <li (click)="clickOnLink('DISTANT')" [innerHTML]="__('listItemDistant')"></li>

  <li (click)="clickOnLink('CLOSE')" [innerHTML]="__('listItemClose')"></li>

  <li (click)="clickOnLink('MODERATE')" [innerHTML]="__('listItemModerate')"></li>

</ul>
