import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ChatMessage} from './chat-message';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {ChatMessageRepresentation} from './chat-message-representation';
import {ChatMessagesStore, StoredContactChatMessages} from './chat-messages.store';
import {arrayRemove, arrayUpsert} from '@datorama/akita';
import {CurrentUser} from '../../user/current-user.service';
import {ContactChatMessages} from './contact-chat-messages';
import {ContactChatMessagesRepresentation} from './contact-chat-messages-representation';
import {ChatMessagesQuery} from './chat-messages.query';
import {ChatMessageContentUpdateRepresentation} from './chat-message-content-update-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ChatMessagesService {

  constructor(private http: HttpClient,
              private currentUser: CurrentUser,
              private chatMessageStore: ChatMessagesStore,
              private chatMessagesQuery: ChatMessagesQuery) {
  }

  removeChatMessageFromStore(contactKeycloakId: string, chatMessageId: number): void {
    this.chatMessageStore.update(contactKeycloakId, ({chatMessages}) => ({
      chatMessages: arrayRemove(chatMessages, [chatMessageId])
    }));
  }

  storeChatMessage(contactKeycloakId: string,
                   chatMessage: ChatMessage,
                   ensureEntryExists: boolean = true,
                   checkReceivedState: boolean = true,
                   checkReadState: boolean = true,
                   delayCallbacks: boolean = false): void {
    if (ensureEntryExists) {
      this.chatMessageStore.ensureEntryExists(contactKeycloakId);
    }
    this.chatMessageStore.upsert(contactKeycloakId, (oldState: StoredContactChatMessages) => ({
      chatMessages: arrayUpsert(oldState.chatMessages, chatMessage.id, chatMessage),
      delayCallbacks
    }));
    if (chatMessage.id >= 0) {
      if (checkReceivedState) {
        this.updateReceivedStateOfMessage(contactKeycloakId, chatMessage);
      }
      if (checkReadState) {
        this.updateReadStateOfMessage(contactKeycloakId, chatMessage);
      }
    }
  }

  storeChatMessages(contactKeycloakId: string, chatMessages: ChatMessage[]): void {
    this.chatMessageStore.ensureEntryExists(contactKeycloakId);
    chatMessages.forEach((chatMessage, index) => {
      this.storeChatMessage(
        contactKeycloakId, chatMessage,
        false, true, true, index < chatMessages.length - 1
      );
    });
  }

  updateReceivedStateOfMessages(contactUserId: string, messages: ChatMessage[]): void {
    messages.forEach(message => this.updateReceivedStateOfMessage(contactUserId, message));
  }

  updateReceivedStateOfMessage(contactUserId: string, message: ChatMessage): void {
    // We must only update the received state of messages that were sent to us!
    if (message.receiverKeycloakId === this.currentUser.keycloakId
      && typeof message.received === typeof undefined) {
      this.updateChatMessageReceivedState(contactUserId, message.id)
        .pipe(take(1))
        .subscribe();
    }
  }

  updateReadStateOfMessages(contactUserId: string, messages: ChatMessage[]): void {
    // Only do that if the specified messages contact is the active contact and the chat ui is opened.
    if (contactUserId === this.chatMessagesQuery.getActiveId() && this.chatMessagesQuery.isUiOpened()) {
      messages.forEach(message => this._updateReadStateOfMessage(contactUserId, message));
    }
  }

  updateReadStateOfMessage(contactUserId: string, message: ChatMessage): void {
    // Only do that if the specified messages contact is the active contact and the chat ui is opened.
    if (contactUserId === this.chatMessagesQuery.getActiveId() && this.chatMessagesQuery.isUiOpened()) {
      this._updateReadStateOfMessage(contactUserId, message);
    }
  }

  _updateReadStateOfMessage(contactUserId: string, message: ChatMessage): void {
    // We must only process messages that were send to us and are currently no marked as read!
    if (message.receiverKeycloakId === this.currentUser.keycloakId
      && typeof message.read === typeof undefined) {
      this.updateChatMessageReadState(contactUserId, message.id, true)
        .pipe(take(1))
        .subscribe();
    }
  }

  loadUnreadChatMessages(): Observable<ContactChatMessages[]> {
    return this.http.post<ContactChatMessagesRepresentation[]>(
      API_URL + '/chat/messages/unread',
      {},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessagesRepresentations: ContactChatMessagesRepresentation[]) =>
        chatMessagesRepresentations.map((chatMessagesRepresentation: ContactChatMessagesRepresentation) =>
          ContactChatMessagesRepresentation.toModelEntity(chatMessagesRepresentation))),
      tap((chatMessages: ContactChatMessages[]) =>
        chatMessages.forEach(entry => this.storeChatMessages(entry.contactKeycloakId, entry.chatMessages)))
    );
  }

  loadInitialChatMessages(): Observable<ContactChatMessages[]> {
    return this.http.post<ContactChatMessagesRepresentation[]>(
      API_URL + '/chat/messages/initial',
      {},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessagesRepresentations: ContactChatMessagesRepresentation[]) =>
        chatMessagesRepresentations.map((chatMessagesRepresentation: ContactChatMessagesRepresentation) =>
          ContactChatMessagesRepresentation.toModelEntity(chatMessagesRepresentation))),
      tap((chatMessages: ContactChatMessages[]) =>
        chatMessages.forEach(entry => this.storeChatMessages(entry.contactKeycloakId, entry.chatMessages)))
    );
  }

  // TODO: Add a parameter which constraints the request to only return (for example) 100 messages.
  // TODO: Add a mechanic that loads more (older) messages if necessary.
  loadAllChatMessages(contactUserId: string): Observable<ChatMessage[]> {
    return this.http.get<ChatMessageRepresentation[]>(
      API_URL + '/chat/messages/' + contactUserId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((chatMessageRepresentations: ChatMessageRepresentation[]) =>
        chatMessageRepresentations.map((chatMessageRepresentation: ChatMessageRepresentation) =>
          ChatMessageRepresentation.toModelEntity(chatMessageRepresentation))),
      tap((chatMessages: ChatMessage[]) => this.storeChatMessages(contactUserId, chatMessages))
    );
  }

  sendChatMessage(contactUserId: string, chatMessage: ChatMessage): Observable<ChatMessage> {
    return this.http.post<ChatMessageRepresentation>(
      API_URL + '/chat/messages/' + contactUserId,
      ChatMessageRepresentation.fromModelEntity(chatMessage),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessageRepresentation: ChatMessageRepresentation) =>
        ChatMessageRepresentation.toModelEntity(chatMessageRepresentation)),
      tap((savedChatMessage: ChatMessage) => this.storeChatMessage(contactUserId, savedChatMessage))
    );
  }

  updateChatMessageReceivedState(contactUserId: string, chatMessageId: number): Observable<ChatMessage> {
    return this.http.post<ChatMessageRepresentation>(
      API_URL + '/chat/messages/' + contactUserId + '/' + chatMessageId + '/received',
      {},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessageRepresentation: ChatMessageRepresentation) =>
        ChatMessageRepresentation.toModelEntity(chatMessageRepresentation)),
      tap((savedChatMessage: ChatMessage) => {
        // This operation is triggered when storing a message.
        // So we already saw this message and triggered other checks.
        // We only allow re-evaluating the received state to potentially correct errors.
        this.storeChatMessage(contactUserId, savedChatMessage, true, true, false);
      })
    );
  }

  updateChatMessageReadState(contactUserId: string, chatMessageId: number, readState: boolean): Observable<ChatMessage> {
    return this.http.post<ChatMessageRepresentation>(
      API_URL + '/chat/messages/' + contactUserId + '/' + chatMessageId + '/read-state/' + readState,
      {},
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessageRepresentation: ChatMessageRepresentation) =>
        ChatMessageRepresentation.toModelEntity(chatMessageRepresentation)),
      tap((savedChatMessage: ChatMessage) => {
        // This operation is triggered when storing a message.
        // So we already saw this message and triggered other checks.
        // We only allow re-evaluating the read state to potentially correct errors.
        this.storeChatMessage(contactUserId, savedChatMessage, true, false, true);
      })
    );
  }

  updateChatMessage(contactUserId: string, messageId: number, newContent: string): Observable<ChatMessage> {
    return this.http.patch<ChatMessageRepresentation>(
      API_URL + '/chat/messages/' + contactUserId + '/' + messageId,
      new ChatMessageContentUpdateRepresentation(newContent),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((chatMessageRepresentation: ChatMessageRepresentation) =>
        ChatMessageRepresentation.toModelEntity(chatMessageRepresentation)),
      tap((savedChatMessage: ChatMessage) => this.storeChatMessage(contactUserId, savedChatMessage))
    );
  }

}
