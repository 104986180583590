import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {take} from 'rxjs/operators';
import {Cart} from '../cart';
import {CartService} from '../cart-service';
import {TranslatorService} from '../../../translation/translator.service';
import {Product} from '../../../product/product';
import {Position} from '../../position/position';

@Component({
  selector: 'app-cart-overview',
  templateUrl: './cart-overview.component.html',
  styleUrls: ['./cart-overview.component.scss']
})
export class CartOverviewComponent implements OnInit {

  @Input()
    refresh: EventEmitter<void>;

  @Output()
    shown: EventEmitter<Cart> = new EventEmitter<Cart>();

  cart: Cart;
  showSavings: boolean = true;

  constructor(private cartService: CartService,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.doRefresh();
    this.refresh.subscribe(() => this.doRefresh());
  }

  doRefresh(): void {
    this.shown.emit(null);
    this.cartService.getContent()
      .pipe(take(1))
      .subscribe(cart => {
        if (cart.containsProduct(Product.ID37_CHATBOT)) {
          this.showSavings = false;
        }
        this.cart = cart;
        this.shown.emit(cart);
      });
  }

  __(key: string): string {
    return this.translatorService.translate('order.cart.' + key);
  }

}
