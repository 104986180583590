import {UserGroup} from './user-group';

export class UserGroupRepresentation {

  public readonly keycloakId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly groupName: string;

  static toModelEntity(userGroupRepresentation: UserGroupRepresentation): UserGroup {
    return {
      keycloakId: userGroupRepresentation.keycloakId,
      firstName: userGroupRepresentation.firstName,
      lastName: userGroupRepresentation.lastName,
      groupName: userGroupRepresentation.groupName
    };
  }

}
