<div class="clr-col-12 spacing-top">

  <div class="clr-row">
    <div class="clr-col-8 existing-customers-title">
      {{__('existingClients')}} ({{customers.length}})
    </div>
    <div class="clr-col-4">
      <div *ngIf="customersView" class="search-input-container">
        <input (ngModelChange)="doMetaSearch()"
               [(ngModel)]="metaSearch" placeholder="{{__('searchPlaceholder')}}"
               title="Search" type="text">
        <img src="assets/icons/arrow_link_12x12.svg"/>
      </div>
    </div>
  </div>

  <div *ngIf="!hasCustomers()" class="no-customers-initial">
    <div class="svg">
      <img src="assets/icons/testgraphic_120x120.png"/>
    </div>
    <div class="text text-important">
      <!-- {{__('hello')}} {{name}}.<br> -->
      {{__('noCustomersYet')}}<br>
    </div>
  </div>
  <clr-datagrid *ngIf="customersView">
    <clr-dg-column [(clrDgSortOrder)]="nameSortOrder" [(clrFilterValue)]="customerNameFilter" [clrDgField]="'username'"
                   [clrDgSortBy]="'surname'" class="customer-col">
      <div class="cell-label">{{__('name')}}
        <img *ngIf="nameSortOrder === 1" src="assets/icons/arrow_up_20x20.svg"/>
        <img *ngIf="nameSortOrder === -1 || !nameSortOrder" src="assets/icons/arrow_down_20x20.svg"/>
      </div>
      <clr-dg-string-filter [clrDgStringFilter]="customerNameFilter">
      </clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [(clrDgSortOrder)]="groupSortOrder" [(clrFilterValue)]="customerGroupFilter" [clrDgField]="'group'"
                   [clrDgSortBy]="'group'" class="customer-col">
      <div class="cell-label">{{__('group')}}
        <img *ngIf="groupSortOrder === 1" src="assets/icons/arrow_up_20x20.svg"/>
        <img *ngIf="groupSortOrder === -1 || !groupSortOrder" src="assets/icons/arrow_down_20x20.svg"/>
      </div>
      <clr-dg-string-filter [clrDgStringFilter]="customerGroupFilter">
      </clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [(clrDgSortOrder)]="dateSortOrder" [clrDgSortBy]="'date'" class="customer-col">
      <div class="cell-label">{{__('date')}}
        <img *ngIf="dateSortOrder === 1" src="assets/icons/arrow_up_20x20.svg"/>
        <img *ngIf="dateSortOrder === -1 || !dateSortOrder" src="assets/icons/arrow_down_20x20.svg"/>
      </div>
    </clr-dg-column>
    <clr-dg-column [(clrDgSortOrder)]="variationSortOrder" [(clrFilterValue)]="customerVariationFilter"
                   [clrDgField]="'variation'"
                   [clrDgSortBy]="'variation'" class="customer-col">
      <div class="cell-label">{{__('variation')}}
        <img *ngIf="variationSortOrder === 1" src="assets/icons/arrow_up_20x20.svg"/>
        <img *ngIf="variationSortOrder=== -1 || !variationSortOrder" src="assets/icons/arrow_down_20x20.svg"/>
      </div>
      <clr-dg-string-filter [clrDgStringFilter]="customerVariationFilter">
      </clr-dg-string-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'action'" class="customer-col">
      <div class="cell-label">{{__('action')}}</div>
    </clr-dg-column>
    <clr-dg-placeholder *ngIf="!customersView" class="no-customers-yet">
      <table>
        <tr>
          <td><img src="../../../assets/icons/smiley_neutral_42x42.svg"/></td>
          <td><span>{{__('noCustomersYet')}}</span></td>
        </tr>
      </table>
    </clr-dg-placeholder>
    <clr-dg-row *clrDgItems="let customer of customersView" [clrDgItem]="customer">
      <clr-dg-cell class="cell-field username-col-width">
        <div class="double-align">
          <div class="username">{{customer.firstName}} {{customer.lastName}}</div>
          <div class="email">{{customer.email}}</div>
        </div>
      </clr-dg-cell>
      <clr-dg-cell class="cell-field group-col-width">
        <div class="align group">{{customer.groupName}}</div>
      </clr-dg-cell>
      <clr-dg-cell class="cell-field date-col-width">
        <div class="align date">{{customer.creationDate | date}}</div>
      </clr-dg-cell>
      <clr-dg-cell class="cell-field variation-col-width">
        <div class="align variation">{{___(customer.getVariationOfFirstTest())}}</div>
      </clr-dg-cell>
      <clr-dg-cell class="cell-field action-col-width">
        <div class="action">
          <button (click)="selectCustomer(customer)"
                  *ngIf="canSelect() && !isSelected(customer)"
                  class="id37-btn id37-btn-primary id37-btn-transparent id37-btn-select">
            <span>{{__('action.comparisonselect')}}</span>
          </button>
        </div>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [(clrDgPage)]="page" [clrDgPageSize]="pageSize">
        <table class="footer">
          <tr>
            <td>
                                <span class="footer-text">
                                  {{__('page')}} {{pagination.currentPage}} {{__('of')}} {{pagination.lastPage}}
                                </span>
            </td>
            <td>
              <button (click)="previousPage()" class="id37-btn-outline pagination-btn">
                <img src="assets/icons/arrow_pager_left_20x20.svg"/>
              </button>
            </td>
            <td style="padding-left: 8px;">
              <button (click)="nextPage()" class="id37-btn-outline pagination-btn">
                <img src="assets/icons/arrow_pager_right_20x20.svg"/>
              </button>
            </td>
          </tr>
        </table>
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</div>
