<ng-container *ngIf="iOS; else chrome">

  <id37-modal-backdrop></id37-modal-backdrop>

  <div class="pwa-container" *ngIf="open">

    <img (click)="hide()"
         class="close"
         src="assets/icons/x_grey_16x16.svg"
         alt="X Icon for closing the dialog"/>

    <div class="logo-container">
      <img class="logo" src="assets/icons/app/icon-64x64.png" alt="ID37 Logo"/>
    </div>

    <h4>{{__('headline')}}</h4>

    <ol class="id37-text4" style="line-height: 21px">
      <li><span style="padding-right: 4px"></span>{{__('iosStep1')}} </li>
      <li>{{__('iosStep2')}} </li>
      <li>{{__('iosStep3')}} </li>
    </ol>

    <div class="arrow">
      <img src="assets/icons/arrow_down_36x36.svg" alt="arrow down">
    </div>

  </div>

</ng-container>


<ng-template #chrome>

  <id37-modal-backdrop></id37-modal-backdrop>

  <div class="pwa-container" *ngIf="open">

    <img (click)="hide()"
         class="close"
         src="assets/icons/x_grey_16x16.svg"
         alt="X Icon for closing the dialog"/>

    <img class="android-logo" src="assets/icons/app/icon-64x64.png" alt="ID37 Logo"/>

    <h4>{{__('headline')}}</h4>

    <button (click)="installPrompt()">
      <span>{{__('installButton')}}</span>
    </button>

  </div>

</ng-template>
