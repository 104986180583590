<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <h2 id="title">
          {{__('title')}}
        </h2>

        <a class="id37-btn-outline id37-btn-icon"
           href="assets/data/import-template.xlsx"
           id="template-download">
          <clr-icon shape="download"></clr-icon>
          {{__('download')}}
        </a>

        <div class="paragraph">
          <h3>
            {{__('luxxHeadline')}}
          </h3>
          <div class="id37-text1 text-light">
            <p>
              {{__('luxxText1')}}
            </p>
            <p>
              {{__('luxxText2')}}<br/>
              {{__('luxxText3')}}<br/>
              {{__('luxxText4')}}
            </p>
            <p>
              {{__('luxxText5')}}
            </p>
          </div>
        </div>

        <div class="paragraph">
          <h3>
            {{__('id37Headline')}}
          </h3>
          <div class="id37-text1 text-light">
            <p>
              {{__('id37Text1')}}<br/>
              {{__('id37Text2')}}<br/>
              {{__('id37Text3')}}
            </p>
            <p>
              {{__('id37Text4')}}
              {{__('id37Text5')}}
            </p>
            <p>
              {{__('id37Text7')}}
            </p>
          </div>
        </div>

      </div>
    </div>

    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="card">
          <div *ngIf="selectedFile" class="card-header">
            <clr-icon shape="file"></clr-icon>
            {{selectedFile.name}}
          </div>
          <div class="card-block">
            <input #fileInput (change)="onFileChanged($event)"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                   style="display: none" type="file">

            <button (click)="fileInput.click()" class="id37-btn-outline">
              {{__('btnSelectFile')}}
            </button>
            <button (click)="openImportConfirmationModal()" *ngIf="selectedFile"
                    class="id37-btn-outline">
              {{__('btnDoUpload')}}
            </button>
          </div>
          <div class="card-footer">
            <div class="card-text">
              <div *ngIf="inProgress" class="progress loop">
                <progress></progress>
              </div>

              <div *ngIf="success && failureState !== true" class="alert alert-success" role="alert">
                <div class="alert-items">
                  <div class="alert-item static">
                    <div class="alert-icon-wrapper">
                      <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                    </div>
                    <span class="alert-text">{{__('success')}}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="failureState">
                <div *ngFor="let error of failure.importErrors" class="alert alert-danger" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                      </div>
                      <span class="alert-text">{{__('errorInRow')}} {{error.rowNumber}}</span>
                    </div>
                    <div class="alert-item static">
                      <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                      </div>
                      <span class="alert-text">{{error.errorMessage}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<id37-modal [(modalOpen)]="isImportConfirmationModalVisible">
  <id37-modal-title>
    {{__('confirmation.title')}}
  </id37-modal-title>
  <id37-modal-body [align]="'left'">
    <div>
      {{__('confirmation.text1')}}<br>
      {{__('confirmation.text2')}}
    </div>
    <div id="confirmation">
      <label (click)="approval.flip()" class="id37-text3 text-bold"
             style="font-size: 1em; line-height: 3em; margin: 0 1em 0 0;">
        {{__('confirmation.confirm')}}:
      </label>
      <id37-checkbox #approval [(checked)]="isDisclaimerConfirmed"></id37-checkbox>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <button *ngIf="!isDisclaimerConfirmed" class="id37-btn-outline id37-btn-disabled" type="button">
      {{__('confirmation.acceptBtnUnarmed')}}
    </button>
    <button (click)="onUpload(); closeImportConfirmationModal()" *ngIf="isDisclaimerConfirmed"
            class="id37-btn-outline"
            type="button">
      {{__('confirmation.acceptBtnArmed')}}
    </button>
    <button (click)="closeImportConfirmationModal()" class="id37-btn id37-btn-secondary" type="button">
      {{__('confirmation.abortBtn')}}
    </button>
  </id37-modal-footer>
</id37-modal>
