import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {MotiveScore} from '../../../test/state/motive-score';
import {Friendship} from '../../../friend/state/friendship';
import {ScrollService} from '../../../id37/scroll.service';

@Component({
  selector: 'app-all-filter',
  templateUrl: './all-filter.component.html',
  styleUrls: ['./all-filter.component.scss']
})
export class AllFilterComponent implements OnInit {

  @Input()
    distantMotiveScoreTuple: [MotiveScore, MotiveScore][];

  @Input()
    friendship: Friendship;

  @Input()
    friendFullName: string;

  @Input()
    friendGender: string;

  @Output()
    changeFilter = new EventEmitter<'DISTANT' | 'CLOSE' | 'MODERATE'>();

  constructor(private currentUser: CurrentUser,
              public scrollService: ScrollService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
  }

  clickOnLink(filter: 'DISTANT' | 'CLOSE' | 'MODERATE') {
    this.changeFilter.emit(filter);
    setTimeout(() =>
      this.scrollService.scrollToElement('key-graphic-page-wrapper', 'middle'), 200);
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('allFilter.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
