<ng-container *ngIf="choice.selected === true; else notSelected">

  <div (click)="doDeselect()" [ngClass]="{'pre-selected': preSelected}" class="select-item">
    <span>{{choice.label}}</span>
    <span class="selected">{{state.selectedTranslation}}</span>
  </div>

</ng-container>

<ng-template #notSelected>

  <div (click)="doSelect()" [ngClass]="{'pre-selected': preSelected}" class="select-item">
    <span>{{choice.label}}</span>
  </div>

</ng-template>

