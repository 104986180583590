import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OpenArrowComponent} from './open-arrow/open-arrow.component';

@NgModule({
  declarations: [
    OpenArrowComponent
  ],
  exports: [
    OpenArrowComponent
  ],
  imports: [
    CommonModule
  ]
})
export class GeometryModule {
}
