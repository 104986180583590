<id37-modal [(modalOpen)]="open">

  <id37-modal-title>
    {{__('titleA')}} {{!!friendship ? friendName(friendship) : ''}} {{__('titleB')}}
  </id37-modal-title>

  <id37-modal-body>
    {{__('info')}}
  </id37-modal-body>

  <id37-modal-footer>
    <div class="id37-btn-row">
      <button (click)="deleteFriendship()" class="id37-btn id37-btn-danger" type="button">
        {{__('accept')}}
      </button>
      <button (click)="hide()" class="id37-btn id37-btn-secondary" type="button">
        {{__('decline')}}
      </button>
    </div>
  </id37-modal-footer>

</id37-modal>
