import {Component, OnInit, ViewChild} from '@angular/core';
import {DiscountCodeService} from '../../order/discountcode/discount-code.service';
import {DiscountCode} from '../../order/discountcode/discount-code';
import {take} from 'rxjs/operators';
import {DiscountCodeFormService} from './discount-code-form.service';
import {DiscountCodeGenerateFormComponent} from './discount-code-generate-form/discount-code-generate-form.component';

@Component({
  selector: 'app-discountcodes',
  templateUrl: './discountcodes.component.html',
  styleUrls: ['./discountcodes.component.scss']
})

export class DiscountcodesComponent implements OnInit {

  @ViewChild('dcgForm')
    dcgForm: DiscountCodeGenerateFormComponent;

  searchKey: string;
  clickDelete: boolean = false;
  clickOnEdit: boolean = false;
  codeUsed: boolean;
  discountCodeList: DiscountCode[] = [];
  discountCodeView: DiscountCode[] = [];
  loading: boolean;

  lastPage = 0;
  currentPage = 0;
  pageSize = 500;
  allCodesLoaded: boolean = false;

  constructor(
    private discountCodeFormService: DiscountCodeFormService,
    private discountCodeService: DiscountCodeService) {
  }

  ngOnInit(): void {
    this.getDiscountCodeList();
  }

  onCreate(): void {
    this.discountCodeFormService.edit = false;
    this.discountCodeFormService.initializeFormGroup();
    this.clickOnEdit = true;
  }

  onGenerateCode(): void {
    this.dcgForm.openForm();
  }

  applyFilter(): void {
    if (this.searchKey.length !== 0) {
      this.discountCodeView = this.discountCodeList.filter(d =>
        d.code.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1 ||
        (d.category ? d.category.toLowerCase().indexOf(this.searchKey.toLowerCase()) !== -1 : false));
    }
    else {
      this.discountCodeView = this.discountCodeList;
    }
  }

  onClear(): void {
    this.searchKey = '';
    this.applyFilter();
  }

  onEdit(discountCode: DiscountCode): void {
    this.codeUsed = discountCode.uses > 0;
    this.discountCodeFormService.edit = true;
    this.discountCodeFormService.populateForm(discountCode);
    this.clickOnEdit = true;
  }

  onDelete(row: DiscountCode): void {
    this.discountCodeFormService.populateForm(row);
    this.openDeleteModal();
  }

  openDeleteModal(): void {
    this.clickDelete = true;
  }

  closeDeleteModal(): void {
    this.clickDelete = false;
  }

  onDeleteConfirm(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.discountCodeService.deleteDiscountCode(this.discountCodeFormService.form.value.code as string)
      .pipe(take(1))
      .subscribe(() => {
        this.resetDiscountCodeList();
        this.getDiscountCodeList();
      });
  }

  getDiscountCodeList(): void {
    this.loading = true;
    this.discountCodeService.getDiscountCodeList(this.lastPage, this.pageSize).pipe(take(1)).subscribe(
      (list: DiscountCode[]) => {
        this.allCodesLoaded = list.length < this.pageSize;
        this.discountCodeList.push(...list);
        this.discountCodeView = list;
        this.loading = false;
      }, (error) => {
        console.error(error);
        this.loading = false;
      }
    );
  }

  getUpdatedValue(value: boolean): void {
    this.clickOnEdit = value;
  }

  getDiscountCodeFormService(): DiscountCodeFormService {
    return this.discountCodeFormService;
  }

  nextPage(): void {
    if (this.currentPage < this.lastPage) {
      this.currentPage++;
      this.discountCodeView = this.discountCodeList.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
    }
    else if (this.currentPage === this.lastPage && !this.allCodesLoaded) {
      this.currentPage++;
      this.lastPage++;
      this.getDiscountCodeList();
    }
    else {
      console.error('Unknown state');
    }

  }

  previousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.discountCodeView = this.discountCodeList.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
    }
  }

  calcLastPage(): number {
    let a = (this.currentPage + 1) * this.pageSize;
    if (a > this.discountCodeList.length) {
      a = this.discountCodeList.length;
    }
    return a;
  }

  resetDiscountCodeList(): void {
    this.currentPage = 0;
    this.lastPage = 0;
    this.discountCodeList = [];
    this.discountCodeView = [];
    this.allCodesLoaded = false;
  }

}
