import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {TranslatorService} from '../../translation/translator.service';
import {
  CustomerGroupFilter,
  CustomerMetaFilter,
  CustomerNameFilter,
  CustomerVariationFilter
} from '../../master/my-customers/customer-filter';
import {Customer} from '../../master/my-customers/types/customer';
import {Subscription} from 'rxjs';
import {SelectedCustomerAndTest} from '../../master/toolbox/teamanalysis/teamanalysis.component';
import {Test} from '../../test/state/test';

@Component({
  selector: 'app-customerlist',
  templateUrl: './customerlist.component.html',
  styleUrls: ['./customerlist.component.scss']
})
export class CustomerlistComponent implements OnInit, OnDestroy, OnChanges {

  @Input() customers: Customer[] = [];
  @Input() metaSearch: string = '';
  @Input() mulitselect: boolean = true;
  @Input() maxselectable: number = -1;
  @Input() pageSize: number = 6;

  @Output() customersView: Customer[] = [];
  @Output() selectionEvents: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>();

  nameSortOrder: number;
  groupSortOrder: number;
  dateSortOrder: number;
  variationSortOrder: number;

  customerMetaFilter: CustomerMetaFilter = new CustomerMetaFilter(this.translatorService);
  customerNameFilter: CustomerNameFilter = new CustomerNameFilter();
  customerGroupFilter: CustomerGroupFilter = new CustomerGroupFilter();
  customerVariationFilter: CustomerVariationFilter = new CustomerVariationFilter(this.translatorService);
  public page: number = 1;
  private selectedCustomers: SelectedCustomerAndTest[] = [];
  private lastPage: number = 1;

  private subscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.subscription = this.selectionEvents.subscribe((event: SelectionEvent) => {
      if (event.selected === true) {
        this.selectedCustomers.push({
          selectedCustomer: event.customer,
          selectedTest: event.test
        } as SelectedCustomerAndTest);
      }
      else {
        const index: number = this.selectedCustomers.findIndex(selectedCustomerAndTest =>
          selectedCustomerAndTest.selectedCustomer.keycloakId === event.customer.keycloakId);
        if (index > -1) {
          this.selectedCustomers.splice(index, 1);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.customersView = changes.customers.currentValue;
    if (changes.customers.currentValue !== changes.customers.previousValue) {
      this.customersView = changes.customers.currentValue;
      this.selectedCustomers = [];
      this.page = 1;
      this.lastPage = Math.ceil(changes.customers.currentValue.length / this.pageSize);
      this.doMetaSearch();
    }
  }

  doMetaSearch(): void {
    if (this.metaSearch.length !== 0) {
      const customersFiltered: Customer[] = [];
      for (const customer of this.customers) {
        if (this.customerMetaFilter.accepts(customer, this.metaSearch.toLowerCase())) {
          customersFiltered.push(customer);
        }
      }
      this.customersView = customersFiltered;
    }
    else {
      this.customersView = this.customers;
    }
  }

  nextPage(): void {
    if (this.page + 1 <= this.lastPage) {
      this.page = this.page + 1;
    }
  }

  previousPage(): void {
    if (this.page - 1 >= 1) {
      this.page = this.page - 1;
    }
  }

  unselectCustomer(toBeUnselected: Customer): void {
    if (this.isSelected(toBeUnselected)) {
      const event: SelectionEvent = {
        customer: toBeUnselected,
        test: toBeUnselected.getFirstTest(),
        selected: false
      };
      this.selectionEvents.emit(event);
    }
  }

  selectCustomer(toBeSelected: Customer) {
    if (this.canSelect() && !this.isSelected(toBeSelected)) {
      const event: SelectionEvent = {
        customer: toBeSelected,
        test: toBeSelected.getFirstTest(),
        selected: true
      };
      this.selectionEvents.emit(event);
    }
  }

  canSelect(): boolean {
    if (this.mulitselect) {
      return this.maxselectable < 0 || this.selectedCustomers.length < this.maxselectable;
    }
    return this.selectedCustomers.length < 1;
  }

  isSelected(customer: Customer): boolean {
    return this.selectedCustomers.some(selectedCustomerAndTest =>
      selectedCustomerAndTest.selectedCustomer.keycloakId === customer.keycloakId);
  }

  hasCustomers(): boolean {
    return this.customers !== undefined && this.customers.length > 0;
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}

export interface SelectionEvent {
  customer: Customer;
  test: Test;
  selected: boolean;
}
