import {Component, OnInit} from '@angular/core';
import {TranslatorService} from '../translation/translator.service';
import {Motive} from '../report/types/motives/motive';
import {CurrentUser} from '../user/current-user.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {


  friendFullName = 'FriendFullName';
  friendGender = 'male';
  questionTextHtml: string[] = [];
  distantMotiveTextHtml: string[] = [];
  closeMotiveTextHtml: string[] = [];

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
  }

  switchGender(): void {
    this.questionTextHtml = [];
    this.distantMotiveTextHtml = [];
    this.closeMotiveTextHtml = [];
    this.computeQuestionText();
    this.computeDistantText();
    this.computeCloseText();
  }

  reset(): void {
    this.closeMotiveTextHtml = [];
  }

  computeQuestionText(): void {
    Motive.STRING_LIST.forEach((m) => {
      const motive: string = this.translatorService.translate('motive.' + m.toLowerCase());
      let htmlString: string = '<strong>' + motive.toUpperCase() + '</strong><br>';

      htmlString += '<br><strong>high</strong><br>' + this.__('distanceGraphicPage.question.' + m.toLowerCase() + '.high');

      htmlString += '<br><strong>low</strong><br>' + this.__('distanceGraphicPage.question.' + m.toLowerCase() + '.low');

      this.questionTextHtml.push(htmlString);
    });
  }

  computeDistantText(): void {
    Motive.STRING_LIST.forEach((m) => {
      const motive: string = this.translatorService.translate('motive.' + m.toLowerCase());
      let htmlString: string = '<strong>' + motive.toUpperCase() + '</strong><br>';

      htmlString += '<br><strong>high</strong><br>' + this.__('distantFilter.' + m.toLowerCase() + '.high');

      htmlString += '<br><strong>low</strong><br>' + this.__('distantFilter.' + m.toLowerCase() + '.low');

      htmlString += '<br><strong>medium-high</strong><br>' + this.__('distantFilter.' + m.toLowerCase() + '.medium-high');

      htmlString += '<br><strong>medium-low</strong><br>' + this.__('distantFilter.' + m.toLowerCase() + '.medium-low');

      htmlString = htmlString.replace(/\*SCORE_FR\*/g, 'X');
      htmlString = htmlString.replace(/\*SCORE\*/g, 'X');

      this.distantMotiveTextHtml.push(htmlString);
    });
  }

  computeCloseText(): void {
    Motive.STRING_LIST.forEach((m) => {
      const motive: string = this.translatorService.translate('motive.' + m.toLowerCase());
      let htmlString: string = '<strong>' + motive.toUpperCase() + '</strong><br>';

      htmlString += '<br><strong>high-medium</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.high-medium');

      htmlString += '<br><strong>high-high</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.high-high');

      htmlString += '<br><strong>medium-high</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.medium-high');

      htmlString += '<br><strong>medium-medium</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.medium-medium');

      htmlString += '<br><strong>medium-low</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.medium-low');

      htmlString += '<br><strong>low-low</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.low-low');

      htmlString += '<br><strong>low-medium</strong><br>' + this.__('closeFilter.' + m.toLowerCase() + '.low-medium');

      htmlString = htmlString.replace(/\*SCORE_FR\*/g, 'X');
      htmlString = htmlString.replace(/\*SCORE\*/g, 'X');

      this.closeMotiveTextHtml.push(htmlString);
    });
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate(key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.C\*/g, this.translatorService.translate(
        'personalPronoun.nominative.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.C\*/g, this.translatorService.translate(
        'personalPronoun.dative.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'personalPronoun.accusative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.nominative.m/n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*GENITIVE.F\/PL\*/g, this.translatorService.translate(
        'possessivePronoun.genitive.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.dative.f',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.M\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.m',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N.C\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n.c',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
