import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IntroText} from '../types/intro-text';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent {

  @Input()
    introText: IntroText;

  @Input()
    masterId = '';

  @Input()
    locale = 'de';

  @Output()
    shouldScrollToScienceText = new EventEmitter();

  constructor(private translatorService: TranslatorService) {
  }

  scrollToScienceText(): void {
    this.shouldScrollToScienceText.emit();
  }

  __(key: string): string {
    return this.translatorService.translate('report.intro.' + key);
  }

}
