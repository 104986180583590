import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DateTimeComponent} from './datetime/datetime.component';
import {DateTimeTestComponent} from './datetime-test/datetime-test.component';
import {DatetimeDateSelectorComponent} from './datetime-date-selector/datetime-date-selector.component';
import {DatetimeTimeSelectorComponent} from './datetime-time-selector/datetime-time-selector.component';
import {JsDatetimePipe} from './js-datetime.pipe';
import {JsDatePipe} from './js-date.pipe';
import {JsTimePipe} from './js-time.pipe';
import {MomentDatetimePipe} from './moment-datetime.pipe';
import {JsDayMonthPipe} from './js-day-month.pipe';

@NgModule({
  declarations: [
    DateTimeComponent,
    DateTimeTestComponent,
    DatetimeDateSelectorComponent,
    DatetimeTimeSelectorComponent,
    JsDatetimePipe,
    JsDatePipe,
    JsTimePipe,
    JsDayMonthPipe,
    MomentDatetimePipe
  ],
  exports: [
    DateTimeComponent,
    DateTimeTestComponent,
    JsDatetimePipe,
    JsDatePipe,
    JsTimePipe,
    JsDayMonthPipe,
    MomentDatetimePipe
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class DateTimeModule {
}
