<div [ngClass]="{'fixed': fixed, 'displayProgressItems': displayProgressItems}" class="progress-box aligned">

  <div class="wrapper aligned">
    <!-- BACK -->
    <div (click)="backButtonActive && !onFirstPage() && shouldSwitchToPreviousProgressItem.emit()"
         class="page-switch aligned">
      <img *ngIf="backButtonActive && !onFirstPage()" src="assets/icons/arrow_pager_left_20x20.svg">
      <img *ngIf="!backButtonActive || onFirstPage()" src="assets/icons/arrow_pager_left_grey_20x20.svg">
    </div>

    <!-- BAR-WRAPPER -->
    <div class="bar-wrapper aligned">
      <!-- SLIDER -->
      <div *ngIf="!!progressionPercentage"
           [ngStyle]="{ 'left': progressionPercentage + '%'}"
           class="slider-wrapper aligned">
        <div class="slider">
          <div *ngIf="sliderDisplayType === 'progress'" class="text">
            {{currentIndex + 1}}/{{!!available ? available.length : 0}}
          </div>
          <div *ngIf="sliderDisplayType === 'arrow'" class="arrow-down active" style="font-size: 0.4em;">
          </div>
        </div>
      </div>

      <!-- BAR -->
      <div [ngStyle]="{ 'background': 'linear-gradient(to right, #FF5711 ' + (progressionPercentage || 0) +
           '%, rgba(197, 198, 203, 0.45) 0%)' }"
           class="bar aligned">
        <div class="blocker start filled"></div>
        <div *ngFor="let progressItem of available; let i = index" class="divider-wrapper">
          <div (click)="emitProgressSwitchEventTo(progressItem, i)"
               [ngClass]="{ 'filled': lastIndex >= i, 'left-of-current-page': currentIndex >= i }"
               class="divider"
          ></div>
          <div *ngIf="displayProgressItems" [ngClass]="{'active': currentIndex === i}" class="itemInfo">
            <span class="text">{{progressItem.value}}</span>
          </div>
        </div>
        <div class="blocker end"></div>
      </div>
    </div>

    <!-- CONTINUE -->
    <div (click)="continueButtonActive && !onLastPage() && shouldSwitchToNextProgressItem.emit()"
         class="page-switch aligned">
      <img *ngIf="continueButtonActive && !onLastPage()" src="assets/icons/arrow_pager_right_20x20.svg">
      <img *ngIf="!continueButtonActive || onLastPage()" src="assets/icons/arrow_pager_right_grey_20x20.svg">
    </div>

  </div>
</div>

<div *ngIf="fixed" class="progress-box-spacing"></div>
