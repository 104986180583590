export class DateTimeRangeRepresentation {

  public readonly from: string | null;
  public readonly to: string | null;

  constructor(from: string | undefined, to: string | undefined) {
    this.from = !!from ? from : null;
    this.to = !!to ? to : null;
  }
}
