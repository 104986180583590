import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollapsibleItemComponent} from './collapsible-item/collapsible-item.component';
import {CollapsibleHeaderComponent} from './collapsible-header/collapsible-header.component';
import {CollapsibleBodyComponent} from './collapsible-body/collapsible-body.component';
import {CollapsibleAnimationsService} from './services/collapsible-animation.service';
import {CollapsibleListComponent} from './collapsible-list/collapsible-list.component';
import {CollapsibleTestComponent} from './collapsible-test/collapsible-test.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CollapsibleListComponent,
    CollapsibleItemComponent,
    CollapsibleHeaderComponent,
    CollapsibleBodyComponent,
    CollapsibleTestComponent
  ],
  exports: [
    CollapsibleListComponent,
    CollapsibleItemComponent,
    CollapsibleHeaderComponent,
    CollapsibleBodyComponent
  ],
  providers: [
    CollapsibleAnimationsService
  ]
})
export class CollapsibleModule {
}
