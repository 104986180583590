import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {Test} from '../test/state/test';
import {Observable, of, Subject} from 'rxjs';
import {ActiveUser} from '../user/active-user';
import {TestQuery} from '../test/state/test.query';
import {TestService} from '../test/state/test.service';
import {TranslatorService} from '../translation/translator.service';
import {CalendlyService} from './calendly.service';
import {CalendlyFunctions} from './state/CalendlyFunctions';
import {environment} from '../../environments/environment';

declare global {
  interface Window {
    Calendly: any;
  }
}

export interface CalendlyScheduledEvent {
  event: string;
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
}

const HIDE_BOOKED_INFO_AFTER_4_Hours: number = environment.HIDE_CALENDLY_BOOKED_INFO_AFTER_X_HOURS;

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
  styleUrls: ['./calendly.component.scss']
})
export class CalendlyComponent implements OnInit, OnDestroy {

  hasAffiliate: boolean = false;
  affiliateID: string;
  coachName: string = '';

  name = '';
  tests: Test[] | undefined = undefined;

  // https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced#6
  calendlyEvents: string[] = ['profile_page_viewed', 'event_type_viewed', 'date_and_time_selected', 'event_scheduled'];
  calendlyBooked$: Observable<boolean>;
  showCalendlyBookedInfo: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private calendlyService: CalendlyService,
    private currentUser: CurrentUser,
    private testService: TestService,
    private testQuery: TestQuery,
    private translatorService: TranslatorService) {
  }

  @HostListener('window:message', ['$event'])
  isCalendlyEvent(e: MessageEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
    if (e.data.event && e.data.event.includes(this.calendlyEvents[3])) {
      const csEvent: CalendlyScheduledEvent = e.data;
      const eventUri = csEvent.payload.event.uri;
      const eventId = eventUri.substring(eventUri.lastIndexOf('events') + 7);
      this.calendlyService.storeScheduledEvent({eventId}).subscribe(
        () => {
        },
        error => console.error(error)
      );
      this.calendlyBooked$ = of(true);
    }
  }

  ngOnInit(): void {
    this.name = this.currentUser.fullName;
    this.calendlyService.getScheduledEvents()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(calendlyEvents => {
        this.calendlyBooked$ = of(!!calendlyEvents.length);
        if (!!calendlyEvents.length) {
          CalendlyFunctions.calcDateDiffInHoursFromNow(calendlyEvents[0].createdAt) > HIDE_BOOKED_INFO_AFTER_4_Hours ?
            this.showCalendlyBookedInfo = false : this.showCalendlyBookedInfo = true;
        }
      });
    this.testQuery.selectTestsForCurrentUser().pipe(
      takeUntil(this.unsubscribe$),
      map(tests => {
        this.tests = tests;
        return tests;
      }),
      mergeMap((tests: Test[]) => {
        if (tests.length && tests[0].accessGranted) {
          return this.testService.getAffiliate(tests[0].testId);
        }
        return of(null);
      })
    ).subscribe((activeUser: ActiveUser) => {
      if (activeUser !== null) {
        this.hasAffiliate = true;
        this.affiliateID = activeUser.keycloakUser.id;
        this.coachName = activeUser.keycloakUser.firstName;
      }
    }, error => console.error(error));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openCalendly(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/ww-id37-coaches/ww-id37-assessment-session',
      prefill: {
        name: this.name,
        email: this.currentUser.email
      }
    });
  }

  openCoachCalendly(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.Calendly.initPopupWidget({
      url: 'https://calendly.com/ww-id37-coaches/' + this.affiliateID,
      prefill: {
        name: this.name,
        email: this.currentUser.email
      }
    });
  }

  __(key: string): string {
    return this.translatorService.translate('dashboard.' + key);
  }
}
