import {UpdatableAccountData} from './updatable-account-data';

export class UpdatableAccountDataRepresentation {

  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly gender: string | null;
  public readonly academicDegree: string;
  public readonly academicTitle: string;
  public readonly city: string;
  public readonly company: string;
  public readonly dateOfBirth: string | null;
  public readonly industry: string;
  public readonly mobilePhoneNumber: string;
  public readonly origin: string;
  public readonly profession: string;
  public readonly residence: string;

  constructor(firstName: string,
              lastName: string,
              email: string,
              gender: string | null,
              academicDegree: string,
              academicTitle: string,
              city: string,
              company: string,
              dateOfBirth: string | null,
              industry: string,
              mobilePhoneNumber: string,
              origin: string,
              profession: string,
              residence: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.gender = gender;
    this.academicDegree = academicDegree;
    this.academicTitle = academicTitle;
    this.city = city;
    this.company = company;
    this.dateOfBirth = dateOfBirth;
    this.industry = industry;
    this.mobilePhoneNumber = mobilePhoneNumber;
    this.origin = origin;
    this.profession = profession;
    this.residence = residence;
  }

  static buildFrom(updatableAccountData: UpdatableAccountData): UpdatableAccountDataRepresentation {
    return new UpdatableAccountDataRepresentation(
      updatableAccountData.firstName,
      updatableAccountData.lastName,
      updatableAccountData.email,
      updatableAccountData.gender === '' ? null : updatableAccountData.gender,
      updatableAccountData.academicDegree,
      updatableAccountData.academicTitle,
      updatableAccountData.city,
      updatableAccountData.company,
      updatableAccountData.dateOfBirth,
      updatableAccountData.industry,
      updatableAccountData.mobilePhoneNumber,
      updatableAccountData.origin,
      updatableAccountData.profession,
      updatableAccountData.residence
    );
  }

}
