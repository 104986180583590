<div class="canvas-container">
  <canvas id="bubble-canvas"></canvas>
</div>

<ng-container *ngFor="let f of filter; let i = index">
  {{displayAsAxes(i)}} :
  <button (click)="removeFilter(f)"
          class="id37-btn-flat">
    {{motiveButtons[f].displayName}}
  </button>
</ng-container>

<div>
  <button (click)="showBubbles()"
          [disabled]="filter.length !== 2"
          class="id37-btn-primary">
    Show Bubbles
  </button>
</div>

<div class="button-container">
  <ng-container *ngFor="let b of motiveButtons">
    <button
        (click)="addFilter(b.motive)"
        [ngClass]="{'selected': isSelected(b.motive)}"
        class="id37-btn id37-btn-bw id37-btn-flat">
      {{b.displayName}}
    </button>
  </ng-container>
</div>
