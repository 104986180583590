import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {PaymentProvider} from '../payment-provider';
import {PaymentProviderAndMethod} from '../payment-provider-and-method';
import {PaymentMethod} from '../payment-method';

@Component({
  selector: 'app-payment-provider-and-method-chooser',
  templateUrl: './payment-provider-and-method-chooser.component.html',
  styleUrls: ['./payment-provider-and-method-chooser.component.scss']
})
export class PaymentProviderAndMethodChooserComponent implements OnInit {

  @Output()
    paymentProviderAndMethod: EventEmitter<PaymentProviderAndMethod> = new EventEmitter<PaymentProviderAndMethod>();

  provider = PaymentProvider;
  method = PaymentMethod;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit() {
  }

  chooseProvider(provider: PaymentProvider, method: PaymentMethod): void {
    this.paymentProviderAndMethod.emit(new PaymentProviderAndMethod(provider, method));
  }

  __(key: string): string {
    return this.translatorService.translate('order.paymentProviderChooser.' + key);
  }

}
