import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'id37-open-arrow',
  templateUrl: './open-arrow.component.html',
  styleUrls: ['./open-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenArrowComponent {

  @Input()
    orientation: 'top' | 'right' | 'bottom' | 'left' = 'right';

  @Input()
    scale: string | undefined = undefined;

  @HostBinding('style.transform')
  get rotation(): string {
    switch (this.orientation) {
      case 'left':
        return 'rotate(0deg)';
      case 'top':
        return 'rotate(90deg)';
      case 'right':
        return 'rotate(180deg)';
      case 'bottom':
        return 'rotate(270deg)';
      default:
        throw new Error('Unexpected state!');
    }
  }

}
