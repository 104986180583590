<h2>{{___('friends.myContacts')}}</h2>

<ng-container *ngIf="!searchMode; else searchTemplate">
  <div class="input-button-container">
    <div class="input-container">
      <input (ngModelChange)='searchInputChanged.next($event)' [(ngModel)]="searchKey" maxlength="100"
             placeholder="{{__('searchInputPlaceholder')}}"
             title="Search"
             type="search">

      <ng-container *ngIf="searchKey.length === 0; else resetButton">
        <img alt="search" src="assets/icons/arrow_link_12x12.svg"/>
      </ng-container>

      <ng-template #resetButton>
        <img (click)="resetSearch()" alt="Abort search" src="assets/icons/ID37_X.svg" style="cursor: pointer">
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #searchTemplate>
  <div class="input-button-container">
    <div class="input-container">
      <!-- TODO: Refactor into <id37-search-button> component. -->
      <input (ngModelChange)='searchInputChanged.next($event)' [(ngModel)]="searchKey" maxlength="100"
             placeholder="{{__('searchInputPlaceholder')}}"
             title="Search"
             type="search">

      <ng-container *ngIf="searchKey.length === 0; else resetButton">
        <img alt="search" src="assets/icons/arrow_link_12x12.svg"/>
      </ng-container>

      <ng-template #resetButton>
        <img (click)="resetSearch()" alt="Abort search" src="assets/icons/ID37_X.svg" style="cursor: pointer">
      </ng-template>
      <!-- --------------------------------- -->
    </div>

    <ng-container *ngIf="searchMode">
      <button (click)="abortSearch()" class="id37-btn-primary">
        <span>{{__('button.abort')}}</span>
      </button>
    </ng-container>
  </div>
</ng-template>
