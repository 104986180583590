import {TestResult} from './test-result';
import {MotiveScoreRepresentation} from './motive-score-representation';
import {Motive} from '../../report/types/motives/motive';
import {MotiveScore} from './motive-score';

export class TestResultRepresentation {

  motiveScores: MotiveScoreRepresentation[];

  static toModelEntity(testResultRepresentation: TestResultRepresentation): TestResult {
    const motiveScores = new Map<Motive, MotiveScore>();
    for (const motiveScoreRepresentation of testResultRepresentation.motiveScores) {
      const motiveScore = MotiveScoreRepresentation.toModelEntity(motiveScoreRepresentation);
      motiveScores.set(motiveScore.motive, motiveScore);
    }
    return {
      motiveScores
    };
  }

}
