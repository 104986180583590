import {Component, Input} from '@angular/core';
import {ContactText} from '../types/contact-text';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Input()
    contactText: ContactText;

  constructor() {
  }

}
