import {Product} from '../../product/product';

export class CartUpdate {

  private readonly _product: Product;
  private readonly _amount: number | undefined;
  private readonly _discountCode: string | undefined;

  constructor(product: Product, amount?: number | undefined, discountCode?: string | undefined) {
    this._product = product;
    this._amount = typeof amount === typeof undefined ? null : amount;
    this._discountCode = typeof discountCode === typeof undefined ? null : discountCode;
  }

  get product(): Product {
    return this._product;
  }

  get amount(): number | undefined {
    return this._amount;
  }

  get discountCode(): string | undefined {
    return this._discountCode;
  }

}
