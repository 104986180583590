import {Order} from './order';
import {OrderProcessingResult} from './order-processing-result';
import {OrderAndOrderProcessingResultRepresentation} from './order-and-order-processing-result-representation';

export class OrderAndOrderProcessingResult {

  private readonly _order: Order;
  private readonly _orderProcessingResult: OrderProcessingResult;

  constructor(order: Order,
              orderProcessingResult: OrderProcessingResult) {
    this._order = order;
    this._orderProcessingResult = orderProcessingResult;
  }

  get order(): Order {
    return this._order;
  }

  get orderProcessingResult(): OrderProcessingResult {
    return this._orderProcessingResult;
  }

  static buildFrom(
    orderAndOrderProcessingResultRepresentation: OrderAndOrderProcessingResultRepresentation): OrderAndOrderProcessingResult {
    return new OrderAndOrderProcessingResult(
      Order.buildFrom(orderAndOrderProcessingResultRepresentation.orderRepresentation),
      OrderProcessingResult.buildFrom(orderAndOrderProcessingResultRepresentation.orderProcessingResultRepresentation)
    );
  }

}
