import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {take} from 'rxjs/operators';
import {ProfilePictureEditorComponent} from '../profile-picture-editor/profile-picture-editor.component';
import {TranslatorService} from '../../translation/translator.service';
import {ProfilePicture} from '../profile-picture';
import {ProfilePictureService} from '../profile-picture-service';
import {ProfilePictureQuery} from '../profile-picture-query';
import {CurrentUser} from '../../user/current-user.service';

const MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES = 2.0;
const QUALITY_FACTOR = 0.8;

@Component({
  selector: 'app-profile-picture-manager',
  templateUrl: './profile-picture-manager.component.html',
  styleUrls: ['./profile-picture-manager.component.scss']
})
export class ProfilePictureManagerComponent implements OnInit {

  @ViewChild('profilePictureFileInput')
    profilePictureFileInput: ElementRef;

  @ViewChild('profilePictureEditor')
    profilePictureEditor: ProfilePictureEditorComponent;

  selectedFile: File | undefined = undefined;
  selectedProfilePicture: ProfilePicture | undefined = undefined;

  errorMessageDuration: number = 4000;
  errorMessageKey: string | undefined;

  userId: string;

  constructor(private currentUser: CurrentUser,
              private profilePictureService: ProfilePictureService,
              public profilePictureQuery: ProfilePictureQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.userId = this.currentUser.keycloakId;
  }

  deleteProfilePicture(): void {
    this.profilePictureService.deleteProfilePictureForCurrentUser().pipe(take(1)).subscribe();
  }

  selectProfilePicture(): void {
    this.profilePictureFileInput.nativeElement.value = '';
    this.profilePictureFileInput.nativeElement.click();
  }

  clearProfilePictureSelection(): void {
    this.profilePictureFileInput.nativeElement.value = '';
    this.selectedFile = undefined;
    this.selectedProfilePicture = undefined;
  }

  showError(errorMessageKey: string) {
    this.errorMessageKey = errorMessageKey;
    setTimeout(() => {
      this.errorMessageKey = undefined;
    }, this.errorMessageDuration);
  }

  onProfilePictureSelection(event): void {
    if (event.target.files.length === 0) {
      console.error('No picture selected.');
      this.selectedProfilePicture = undefined;
      return;
    }
    // Getting a hold of the file reference.
    const file: File = event.target.files[0];
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      this.showError('fileTypeNotSupported');
      return;
    }
    if ((file.size / 1024.0 / 1024.0) > MAXIMUM_IMAGE_FILE_SIZE_IN_MEGA_BYTES) {
      this.showError('fileToLarge');
      return;
    }
    this.selectedFile = file;
    // Setting up the reader.
    const reader: FileReader = new FileReader();
    // reader.readAsText(file, 'UTF-8');
    reader.readAsDataURL(file);
    // Here we tell the reader what to do when it's done reading...
    reader.onload = (progressEvent: ProgressEvent) => {
      const content = (progressEvent.target as FileReader).result; // this is the content!
      if (typeof content === 'string') {
        this.selectedProfilePicture = undefined;
        setTimeout(() => {
          this.selectedProfilePicture = {
            base64: content
          };
        }, 0);
      }
      else {
        this.showError('couldNotLoad');
      }
    };
  }

  saveProfilePicture(): void {
    if (!this.selectedProfilePicture) {
      console.error('No picture selected.');
      return;
    }
    const editedPictureAsBase64: string = this.profilePictureEditor.angularCropper.cropper
      .getCroppedCanvas({})
      .toDataURL(this.selectedFile.type, QUALITY_FACTOR);
    // console.log('File type: ', this.selectedFile.type);
    // console.log('File size: ', (editedPictureAsBase64.length / 4 * 3) / (1024.0 * 1024.0));
    this.profilePictureService.updateProfilePictureForCurrentUser(editedPictureAsBase64)
      .pipe(take(1))
      .subscribe();
    this.selectedProfilePicture = undefined;
  }

  __(key: string): string {
    return this.translatorService.translate('profilePictureManager.' + key);
  }

}
