<svg app-key-graphic-header/>

<svg app-key-graphic-grid [lines]="numberOfMotivesView"/>

<svg app-distance-score-descriptions
     [normalizedMotiveScoreTuple]="normalizedMotiveScoreTuple"
     [friendFirstname]="friendFirstname"/>

<ng-container *ngFor="let motiveScoreTuple of normalizedMotiveScoreTuple; let i = index">

  <svg app-distance-line
       [motiveScoreA]="motiveScoreTuple[0]"
       [motiveScoreB]="motiveScoreTuple[1]"
       [index]="i"/>

</ng-container>

