import {UserProductPriceRepresentations} from '../../account/user-product-price-representation';
import {AdminProductPrice} from './admin-product-price';

export class AdminProductPriceRepresentations {
  public readonly keycloakId: string;
  public readonly mail: string;
  public readonly fullName: string;
  public readonly role: string;
  public readonly userProductPrices: UserProductPriceRepresentations[];

  static toModelEntity(adminProductPriceRepresentation: AdminProductPriceRepresentations): AdminProductPrice {
    return {
      keycloakId: adminProductPriceRepresentation.keycloakId,
      mail: adminProductPriceRepresentation.mail,
      fullName: adminProductPriceRepresentation.fullName,
      role: adminProductPriceRepresentation.role,
      userProductPrices: adminProductPriceRepresentation.userProductPrices.map(userProductPricesRepresentation =>
        UserProductPriceRepresentations.toModelEntity(userProductPricesRepresentation))
    };
  }
}
