import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotSystemPromptService {
  constructor(private http: HttpClient) {
  }

  public getSystemPrompt(): Observable<string> {
    return this.http.get<string>(API_URL + '/system-prompt/get', {headers: new HttpHeaders({
      Accept: 'application/json;'})});
  }

  public updateSystemPrompt(newPrompt: string): Observable<any> {
    return this.http.post(API_URL + '/system-prompt/update', { payload: newPrompt});
  }
}
