/* eslint-disable @typescript-eslint/unbound-method */
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AdminService} from '../../admin.service';
import {FileDownloadService} from '../../../shared/file-download-service';
import {MediaType} from '../../../shared/media-type';
import {DiscountCodeGenerateRepresentation} from '../../types/discount-code-generate-representation';
import {HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-discount-code-generate-form',
  templateUrl: './discount-code-generate-form.component.html',
  styleUrls: ['./discount-code-generate-form.component.scss']
})
export class DiscountCodeGenerateFormComponent implements OnInit {

  open: boolean = false;

  codeGenerateForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private adminService: AdminService, private fileDownloadService: FileDownloadService) {
  }


  ngOnInit(): void {
    this.codeGenerateForm = this.formBuilder.group({
      prefixCode: ['', Validators.required],
      codeLength: ['', Validators.required],
      numberOfCodes: ['', Validators.required],
      discount: ['', [Validators.required, Validators.pattern('^[0]|[0][.][0-9]+|[1]$')]],
      active: ['true'],
      activeFrom: [''],
      activeUntil: [''],
      maximumUses: [''],
      category: [''],
      description: [''],
      affiliateMail: ['']
    });
  }

  openForm(): void {
    this.codeGenerateForm.reset();
    this.open = true;
  }

  generateCode(): void {
    const reqBody: DiscountCodeGenerateRepresentation = {
      discount: this.codeGenerateForm.get('discount').value,
      codeLength: this.codeGenerateForm.get('codeLength').value,
      numberOfCodes: this.codeGenerateForm.get('numberOfCodes').value,
      prefixCode: this.codeGenerateForm.get('prefixCode').value,
      active: this.codeGenerateForm.get('active').value,
      activeFrom: this.codeGenerateForm.get('activeFrom').value === '' ? null : this.codeGenerateForm.get('activeFrom').value,
      activeUntil: this.codeGenerateForm.get('activeUntil').value === '' ? null : this.codeGenerateForm.get('activeUntil').value,
      maximumUses: this.codeGenerateForm.get('maximumUses').value === '' ? null : this.codeGenerateForm.get('maximumUses').value,
      category: this.codeGenerateForm.get('category').value === '' ? null : this.codeGenerateForm.get('category').value,
      description: this.codeGenerateForm.get('description').value === '' ? null : this.codeGenerateForm.get('description').value,
      affiliateMail: this.codeGenerateForm.get('affiliateMail').value === '' ? null : this.codeGenerateForm.get('affiliateMail').value
    };

    this.adminService.generateCode(reqBody).subscribe((response: HttpResponse<ArrayBuffer>) => {
      this.open = false;
      this.fileDownloadService.saveToFileSystemBlob(
        response,
        MediaType.EXCEL,
        'generateCodes.xlsx');
    }, (error) => {
      this.open = false;
      window.alert('Fehler');
      console.log(error);
    });
  }

  // private hasErrors(): boolean {
  //   return !!this.codeGenerateForm.get('code').errors
  //     || !!this.codeGenerateForm.get('discountAsNumber').errors
  //     || !!this.codeGenerateForm.get('active').errors
  //     || !!this.codeGenerateForm.get('category').errors;
  // }

}
