import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-collapsible-test',
  templateUrl: './collapsible-test.component.html',
  styleUrls: ['./collapsible-test.component.scss']
})
export class CollapsibleTestComponent implements OnInit {

  showCollapsible3 = false;

  constructor() {
  }

  ngOnInit() {
    setTimeout(() => {
      this.showCollapsible3 = true;
    }, 3000);
  }

}
