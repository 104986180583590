import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChatbotChat} from '../state/chatbot-chat';

@Injectable({
  providedIn: 'root'
})
export class ChatbotChatEventService {
  private messageSend = new BehaviorSubject<boolean>(false);
  private chatCreated = new BehaviorSubject<ChatbotChat>(null);

  public messageSend$ = this.messageSend.asObservable();
  public chatCreated$ = this.chatCreated.asObservable();

  emitMessageSend() {
    this.messageSend.next(true);
  }

  emitChatCreated(chat: ChatbotChat) {
    this.chatCreated.next(chat);
  }

  emitChatUndefined() {
    this.chatCreated.next(undefined);
  }
}
