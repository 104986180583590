<svg>
  <symbol id="big-circle_{{colorIndex}}" [ngClass]="class" viewBox="-1 -1 101 101" preserveAspectRatio="xMinYMin">
    <circle [attr.cx]="50" [attr.cy]="50" r="35" fill="#FFFFFF"/>
    <circle [attr.cx]="50" [attr.cy]="50" r="30.0" stroke-width="4%" class="inner"/>
    <circle [attr.cx]="50" [attr.cy]="50" r="5%" stroke="black" stroke-width="1%" fill="black"/>
  </symbol>
</svg>
<svg>
  <symbol id="small-circle" viewBox="-1 -1 101 101" preserveAspectRatio="xMinYMin">
    <circle [attr.cx]="50" [attr.cy]="50" r="25" fill="#FFFFFF"/>
    <circle [attr.cx]="50" [attr.cy]="50" r="20.0" stroke="#939393" stroke-width="4%" fill="#F3F3F3"/>
    <circle [attr.cx]="50" [attr.cy]="50" r="5%" stroke="black" stroke-width="1%" fill="black"/>
  </symbol>
</svg>
<svg>
  <use [attr.xlink:href]="circleClass" [attr.width]="90" [attr.height]="90" [attr.x]="x" [attr.y]="y"/>
</svg>
