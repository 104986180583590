import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import index from 'eslint-plugin-jsdoc';

@Component({
  selector: 'app-webapp-table',
  templateUrl: './webapp-table.component.html',
  styleUrl: './webapp-table.component.scss'
})
export class WebappTableComponent{
  @Input()
    tableHeader: string[] = [];
  @Input()
    webappTableBodyRows: TableRow[] = [];
  @Input()
    buttonClickedParams: (number | string)[][] = [];
  @Output()
    downloadInvoicesButtonClickedEvent: EventEmitter<(number | string)[]>
      = new EventEmitter<(number | string)[]>(null);

  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate(key);
  }

  onClicked(index: number) {
    this.downloadInvoicesButtonClickedEvent.emit(this.buttonClickedParams[index]);
  }
}

export interface TableRow {
  columns: TableColumn[];
}

export interface TableColumn {
  label: string;
  cssClass?: string;
  hasButton?: boolean;
  buttonIcon?: TableColumnButtonIcon;
}

export interface TableColumnButtonIcon {
  imageUrl: string;
  imageAlt: string;
  imageCssClass?: string;
}

export interface ColumnStyles {
  [key: string]: { [styleProperty: string]: string };
}
