<id37-modal *ngIf="!!recommendationFFInfo" [(modalOpen)]="open">

  <id37-modal-title>
    {{__('title')}}
  </id37-modal-title>

  <id37-modal-body>
    <div [innerHTML]="__('text')" class="id37-text2 text-center"></div>

    <div class="dashboard-btn-container">
      <div class="dashboard-btn-wrapper">
        <div (click)="clickedOnFF()" class="dashboard-btn">
          <div class="title">{{__('leftButton.title')}}</div>
          <div class="price-container">
            <span class="price">{{id37TestPriceWithFriendsAndFamily | number: '1.2-2': this.decimalSeparatorLocaleID}}</span>
            <div class="currency-container">
              <span class="currency">€</span>
              <span class="tax">{{__('leftButton.tax.text')}}</span>
            </div>
          </div>
          <div class="usage">{{__('leftButton.usage.text')}}</div>
        </div>
        <div class="stoerer-icon">
          <div
              class="stoerer-text">{{friendsAndFamilyDiscountInPercentage}}%
          </div>
        </div>
      </div>

      <div class="dashboard-btn-wrapper">
        <div (click)="clickedOnStandard()" class="dashboard-btn">
          <div class="title">{{__('rightButton.title')}}</div>
          <div class="price-container">
            <span class="price">{{id37TestPrice | number:'1.2-2': this.decimalSeparatorLocaleID}}</span>
            <div class="currency-container">
              <span class="currency">€</span>
              <span class="tax">{{__('rightButton.tax.text')}}</span>
            </div>
          </div>
          <div class="usage">{{__('rightButton.usage.text')}}</div>
        </div>
      </div>
    </div>
  </id37-modal-body>

</id37-modal>

<app-user-invite #userInviteComponent
                 (invited)="onRecommendation()"
                 [inviteAsFriend]="true">
</app-user-invite>
