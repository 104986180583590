export enum Motive {

  NEU = 'NEU',
  SAN = 'SAN',
  EIN = 'EIN',
  STA = 'STA',
  BES = 'BES',
  AUT = 'AUT',
  SOZ = 'SOZ',
  PRI = 'PRI',
  SEN = 'SEN',
  STR = 'STR',
  SIC = 'SIC',
  REV = 'REV',
  BEW = 'BEW',
  ESS = 'ESS',
  FAM = 'FAM',
  SIN = 'SIN'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Motive {
  export const LIST: Motive[] = [
    Motive.NEU,
    Motive.SAN,
    Motive.EIN,
    Motive.STA,
    Motive.BES,
    Motive.AUT,
    Motive.SOZ,
    Motive.PRI,
    Motive.SEN,
    Motive.STR,
    Motive.SIC,
    Motive.REV,
    Motive.BEW,
    Motive.ESS,
    Motive.FAM,
    Motive.SIN
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromIndex = (index: number): Motive => {
    switch (index) {
      case 0:
        return Motive.NEU;
      case 1:
        return Motive.SAN;
      case 2:
        return Motive.EIN;
      case 3:
        return Motive.STA;
      case 4:
        return Motive.BES;
      case 5:
        return Motive.AUT;
      case 6:
        return Motive.SOZ;
      case 7:
        return Motive.PRI;
      case 8:
        return Motive.SEN;
      case 9:
        return Motive.STR;
      case 10:
        return Motive.SIC;
      case 11:
        return Motive.REV;
      case 12:
        return Motive.BEW;
      case 13:
        return Motive.ESS;
      case 14:
        return Motive.FAM;
      case 15:
        return Motive.SIN;
    }
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    throw new Error('There is no motive for index "' + index + '"!');
  };

  export const fromName = (name: string | null): Motive => {
    name = !!name ? name : '';
    const found = Motive.LIST.find((motive: string) => motive === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a motive with an unknown name: "' + name + '"');
      throw new Error('Motive not found!');
    }
    return found;
  };
}

export interface MotiveButton {
  motive: number;
  displayName: string;
}
