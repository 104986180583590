<ng-container *ngIf="!continuation; else sendDateOnSide">
  <app-profile-picture [userId]="chatMessage.senderKeycloakId"></app-profile-picture>
</ng-container>

<ng-template #sendDateOnSide>
  <div class="send-wrapper">
    <div class="send">
      {{chatMessage.sent | JsTime }}
    </div>
  </div>
</ng-template>

<div class="chat-message">
  <div *ngIf="!continuation" class="chat-message-header">
    <div class="name">
      {{senderName}}
    </div>
    <div class="send">
      {{chatMessage.sent | JsTime}}
    </div>
  </div>

  <div class="chat-message-content">
    <ng-container *ngIf="!editMode; else edit">
      <div class="message">
        <div *ngIf="!!chatMessage.updated" class="edited">
          {{__('edited')}}
        </div>
        {{chatMessage.message}}
      </div>
    </ng-container>
    <ng-template #edit>
      <div class="message">
        <!--suppress HtmlFormInputWithoutLabel -->
        <textarea #editInput
                  (focusin)="editModeInputFocused = true"
                  (focusout)="editModeInputFocused = false"
                  [(ngModel)]="editedMessage"
        ></textarea>
      </div>
    </ng-template>
    <div class="chat-message-actions">
      <div (click)="enableEditMode()" *ngIf="own">
        <img alt="edit pencil" class="edit-pencil" src="assets/icons/pencil.svg">
      </div>
    </div>
  </div>

  <div *ngIf="editMode" class="action-buttons">
    <button (click)="disableEditMode()" class="id37-btn-outline-secondary id37-btn-sm " type="button">
      {{__('cancelEdit')}}
    </button>
    <button (click)="confirmEdit()" class="id37-btn-outline id37-btn-sm" type="button">
      {{__('saveEdit')}}
    </button>
  </div>

  <!--
  <div class="chat-message-footer">
    <div class="read" *ngIf="own">
      {{!!chatMessage.read ? __('readState.read') : __('readState.unread')}}<span> - </span>
    </div>
    <div class="send" [innerHTML]="chatMessage.sent | JsTime">
    </div>
  </div>
  -->
</div>
