import {Component, Input} from '@angular/core';
import {Notification} from '../../../state/notification';

@Component({
  selector: 'app-raw-message',
  templateUrl: './raw-message.component.html',
  styleUrls: ['./raw-message.component.scss']
})
export class RawMessageComponent {

  @Input()
    notification: Notification;

}
