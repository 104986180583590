<div (click)="select(false)" [ngClass]="{'selected' : isSelected()}" class="item">
  <div class="item-box">

    <h3 class="text-center text-bold">
      {{ item.text }}
    </h3>

    <div class="options-wrapper aligned">
      <div class="text-center value-info left-value-info">
        {{__('notApplying')}}
      </div>

      <div class="options aligned">
        <div (click)="setAnswerFromClick(option)"
             *ngFor="let option of options" [ngClass]="{'selected': (item.answer === option)}"
             class="option">
          <span class="value">{{ option }}</span>
          <span class="dot"></span>
        </div>
      </div>

      <div class="text-center value-info right-value-info">
        {{__('applying')}}
      </div>
    </div>

    <div *ngIf="errorMsg !== null" [innerHTML]="errorMsg" class="error-msg"></div>
  </div>
  <div *ngIf="infoMsg !== null" [innerHTML]="infoMsg" class="info-msg"></div>
</div>
