<div id37Dropdown  (clickOutside)="close()">
<div class="dropdown-header" (click)="toggle()">
  <h4>{{selectedItem.name}}</h4>
  <img class="hide-on-print" src="assets/icons/caret_down_24x24.svg" alt="caret down" [ngClass]="{'open': this.showList === false}">
  <img class="hide-on-print" src="assets/icons/caret_up_24x24.svg" alt="caret up" [ngClass]="{'open': this.showList}">
</div>


<div class="dropdown-box" [ngClass]="{'open-list': this.showList}">
  <cdk-virtual-scroll-viewport itemSize="35"
                               class="dropdown-viewport"
                               [style.height.px]="calculateDropdownHeight()">
    <div class="dropdown-list">
      <ng-container *cdkVirtualFor="let l of listItems;let i=index; let lst=last">
        <div class="list-item" (click)="select(i)">
          <span>{{l.name}}</span>
        </div>
        <hr *ngIf="!lst">
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
</div>
