<div cdkDropList
     (cdkDropListDropped)="drop($event)" >
  <div cdkDrag *ngFor="let m of displayingMotives" [ngClass]="{'page-break': hasMotiveEros(m)}">
    <app-team-graphic-motive
        [teamId]="teamId"
        [numberOfTeamMembers]="_members.length"
        [motiveName]="__(m)"
        [setSpecificBubbleScores]="getSpecificBubbleScores(m)"
        [layout]="layout">

      <div cdkDragHandle [ngClass]="getLayoutClass()"></div>

    </app-team-graphic-motive>

  </div>
</div>

