import {CustomerRepresentation} from './customer-representation';
import {Test} from '../../../test/state/test';
import {TestRepresentation} from '../../../test/state/test-representation';

export class Customer {

  private _keycloakId: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _groupName: string;
  private _tests: Test[];
  private _creationDate: Date;
  private readonly _emailVerified: boolean;

  constructor(keycloakId: string,
              firstName: string,
              lastName: string,
              email: string,
              groupName: string,
              tests: Test[],
              creationDate: Date,
              emailVerified: boolean) {
    this._keycloakId = keycloakId;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email || '';
    this._groupName = groupName;
    this._tests = tests;
    this._creationDate = creationDate;
    this._emailVerified = emailVerified;
  }

  get keycloakId(): string {
    return this._keycloakId;
  }

  set keycloakId(value: string) {
    this._keycloakId = value;
  }

  get firstName(): string {
    return this._firstName.trim();
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName.trim();
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get groupName(): string {
    return this._groupName;
  }

  set groupName(value: string) {
    this._groupName = value;
  }

  get tests(): Test[] {
    return this._tests;
  }

  set tests(value: Test[]) {
    this._tests = value;
  }

  get creationDate(): Date {
    return this._creationDate;
  }

  set creationDate(value: Date) {
    this._creationDate = value;
  }

  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get emailVerified(): boolean {
    return this._emailVerified;
  }

  static buildFrom(customerRepresentation: CustomerRepresentation): Customer {
    return new Customer(
      customerRepresentation.keycloakId,
      customerRepresentation.firstName,
      customerRepresentation.lastName,
      customerRepresentation.email,
      customerRepresentation.groupName,
      customerRepresentation.tests.map(testRepresentation => TestRepresentation.toModelEntity(testRepresentation)),
      new Date(customerRepresentation.creationDate),
      Boolean(customerRepresentation.emailVerified)
    );
  }

  public hasTests(): boolean {
    return this._tests.length > 0;
  }

  public hasNoTests(): boolean {
    return !this.hasTests();
  }

  public getFirstTest(): Test | undefined {
    return this.hasTests() ? this.tests[0] : undefined;
  }

  public hasStartedTests(): boolean {
    return this._tests.some((test: Test) => test.started);
  }

  public hasFinishedTests(): boolean {
    return this._tests.some((test: Test) => test.finished);
  }

  public getIdOfFirstFinishedTest(): string | undefined {
    return this._tests.filter(test => test.finished).map(test => test.testId).shift();
  }

  // FIXME: Deprecated. Remove.
  public getVariationOfFirstTest(): string | undefined {
    return this._tests.map(test => {
      if (test.variationUpdateAllowed) {
        return 'test.variation.unspecified';
      }
      return test.variation.translatorKey;
    }).shift();
  }

}

