import { Injectable } from '@angular/core';
import {ChatbotMessagePair, ChatbotMessagePairCreate} from '../state/chatbot-message-pair';
import * as moment from 'moment';
import {ChatbotChat} from '../state/chatbot-chat';
import {ChatbotErrorService} from './chatbot-error.service';
import {ChatbotMessagePairService} from '../api-services/chatbot-message-pair.service';
import {CurrentUser} from '../../user/current-user.service';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {TranslatorService} from '../../translation/translator.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotSendMessageService {

  constructor(private chatbotErrorService: ChatbotErrorService,
              private chatbotMessagePairService: ChatbotMessagePairService,
              private chatbotChatService: ChatbotChatService,
              private translatorService: TranslatorService,
              private currentUser: CurrentUser,
  ) {
  }

  async sendMessage(message: string, currentChat: ChatbotChat): Promise<void> {
    currentChat.sending = true;
    const newChatbotMessage = new ChatbotMessagePairCreate(currentChat.chat_id, message,
      moment().locale(this.currentUser.locale));

    // temporary message to display while waiting for response
    const userMsgToDisplay = new ChatbotMessagePair(currentChat.chat_id, message, moment().locale(this.currentUser.locale), -1,
      '...', undefined, undefined, []);
    currentChat.messages.push(userMsgToDisplay);

    let too_long = false;
    return new Promise((resolve, reject) => {
      this.chatbotMessagePairService.addMessagePairStreaming(newChatbotMessage).subscribe({
        next: chunk => {
          this.chatbotErrorService.setErrorOccurred(false);
          let data: object;
          try {
            if (chunk.partialText) {
              let jsonString: string = chunk.partialText.replace(/}\s*{/g, '},{');
              jsonString = '[' + jsonString + ']';
              data = JSON.parse(jsonString);
            }
          }
          catch (error) {
            console.error('Failed to parse JSON', error);
          }

          let answer = '';
          if (data) {
            for (const responseChunk of Object.values(data)) {
              if (responseChunk && responseChunk.finish_reason) {
                too_long = (responseChunk.finish_reason === 'length');
              }
              else if (responseChunk && responseChunk.delta && responseChunk.delta.content) {
                answer += responseChunk.delta.content;
              }
            }

            // if (this.chatbotChat.chat_id === chat_id){
            //   this.chatbotChat.messages[this.chatbotChat.messages.length - 1].answer = answer;
            // }

            currentChat.messages[currentChat.messages.length - 1].answer = answer;
          }

        },
        error: error => {
          this.chatbotErrorService.setErrorOccurred(true);
          this.chatbotErrorService.setErrorMessage(this.__('errorMessage'));
          console.error(error);
          reject(error);

          setTimeout(() => {
            this.chatbotErrorService.setErrorOccurred(false);
          }, 10000);

          currentChat.sending = false;
          resolve();
        },
        complete: async () => {
          if (too_long) {
            this.chatbotErrorService.setErrorOccurred(true);
            this.chatbotErrorService.setErrorMessage(this.__('errorMessageLength'));
          }
          const chatbotChat: ChatbotChat = await this.chatbotChatService.getChatByID(currentChat.chat_id).toPromise();
          chatbotChat.messages.sort((a, b) => a.question_id - b.question_id);
          currentChat.messages.splice(0, currentChat.messages.length, ...chatbotChat.messages);

          if (currentChat.messages.length === 1) {
            const chatbotChatTit: ChatbotChat = await this.chatbotChatService.generateChatTitleByID(currentChat.chat_id).toPromise();
            currentChat.title = chatbotChatTit.title;
          }
          currentChat.sending = false;
          resolve();
        }
      });
    });
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-send-message.' + key);
  }

}
