import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-chat-messages-day-separator',
  templateUrl: './chat-messages-day-separator.component.html',
  styleUrls: ['./chat-messages-day-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesDaySeparatorComponent {

  @Input()
    date: Date;

}
