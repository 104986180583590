export class ProgressItem<T> {

  constructor(key: string, value: T) {
    this._key = key;
    this._value = value;
  }

  private _key: string;

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  private _value: T;

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
  }

}
