<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">DateTime-Test</h1>

    <input placeholder="test" style="margin-bottom: 1em" type="text">

    <label for="date">Datum</label>
    <app-datetime
        (selection)="onSelection($event)"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [placeholder]="'Ein Datum...'"
        [type]="'date'"
        id="date"
        style="margin-bottom: 1em">
    </app-datetime>

    <label for="time">Uhrzeit</label>
    <app-datetime
        (selection)="onSelection($event)"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [placeholder]="'Uhrzeit...'"
        [type]="'time'"
        id="time"
        style="margin-bottom: 1em">
    </app-datetime>

    <label for="datetime">Datum und Uhrzeit</label>
    <app-datetime
        (selection)="onSelection($event)"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [placeholder]="'Datum und Uhrzeit...'"
        [type]="'datetime'"
        id="datetime"
        style="margin-bottom: 1em">
    </app-datetime>

    <label for="disabled">Disabled</label>
    <app-datetime
        (selection)="onSelection($event)"
        [disabled]="true"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [placeholder]="'Dein Geburtstag...'"
        [type]="'date'"
        id="disabled"
        style="margin-bottom: 1em">
    </app-datetime>

    <p style="margin-top: 1em">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
      et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
      amet,
      consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
      sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
      sea
      takimata sanctus est Lorem ipsum dolor sit amet.
    </p>

  </div>
</div>
