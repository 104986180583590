import {Component} from '@angular/core';
import {Choice, ChoicesGroup} from '../state/choices-types';
import {ChoicesOptions} from '../../user-data/choices-options';
import {CurrentUser} from '../../user/current-user.service';
import {CountryService} from '../../user-data/country.service';

@Component({
  selector: 'app-select-test',
  templateUrl: './select-test.component.html',
  styleUrls: ['./select-test.component.scss']
})
export class SelectTestComponent {

  countries: ChoicesGroup[] = [
    {
      label: 'Top Countries',
      id: 1,
      choices: ChoicesOptions.topCountries(this.currentUser.locale)
    },
    {
      label: 'All Countries',
      id: 2,
      choices: this.countryService.getCountriesAsChoicesOptions()
    }
  ];

  constructor(private currentUser: CurrentUser,
              private countryService: CountryService) {
  }

  onSelection(selected: Choice | Choice[] | undefined): void {
    console.log('selected: ', selected);
  }

}
