import {Friend} from './friend';
import {TestShareRepresentation} from '../../test/state/test-share-representation';

export class FriendRepresentation {

  public readonly keycloakId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly recommenderKeycloakId: string | null;
  public readonly testShares: TestShareRepresentation[];

  static toModelEntity(friendRepresentation: FriendRepresentation): Friend {
    return {
      id: friendRepresentation.keycloakId,
      firstName: friendRepresentation.firstName,
      lastName: friendRepresentation.lastName,
      email: friendRepresentation.email,
      recommenderId: friendRepresentation.recommenderKeycloakId != null
        ? friendRepresentation.recommenderKeycloakId
        : undefined,
      testShares: friendRepresentation.testShares.map(testShare => TestShareRepresentation.toModelEntity(testShare))
    };
  }

}
