import {Report} from './types/report';
import {AccountData} from '../account/account-data';
import {MotiveTexts} from './types/motives/motive-texts';
import {Motive} from './types/motives/motive';
import {MotiveText} from './types/motives/motive-text';
import {Pair} from './types/pair-type';
import {IntroText} from './types/intro-text';
import {InterpretationText} from './types/interpretation-text';
import {ProfileApplicationText} from './types/profile-application';
import {FindMasterText} from './types/find-master-text';
import {ContactText} from './types/contact-text';
import {TranslatorService} from '../translation/translator.service';
import {CurrentUser} from '../user/current-user.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportFunctions {

  constructor(private translatorService: TranslatorService,
              private currentUser: CurrentUser) {
  }

  public reportWithReplacements(report: Report, accountData: AccountData): Report {
    return {
      introText: this.introTextWithReplacements(report.introText, accountData),
      profileText: report.profileText,
      interpretationText: this.interpretationTextWithReplacements(report.interpretationText),
      profileIntroText: report.profileIntroText,
      profileNarrowingsText: report.profileNarrowingsText,
      profileSpecialText: report.profileSpecialText,
      profileExampleText: report.profileExampleText,
      profileApplicationText: this.profileApplicationTextWithReplacements(report.profileApplicationText),
      explanationMotivesText: report.explanationMotivesText,
      scienceText: report.scienceText,
      graphicGaussText: report.graphicGaussText,
      howMotivesWorkText: report.howMotivesWorkText,
      motivesDescriptionText: report.motivesDescriptionText,
      findMasterText: this.findMasterTextWithReplacements(report.findMasterText),
      buyBookText: report.buyBookText,
      contactText: this.contactTextWithReplacements(report.contactText),
      printDocumentText: report.printDocumentText,
      motiveTexts: this.consolidatedMotiveTexts(report.motiveTexts)
    };
  }

  __(key: string, language?: string | undefined, gender?: string | undefined): string {
    return this.translatorService.translate('report.' + key, language, gender);
  }

  private consolidatedMotiveTexts(motiveTexts: MotiveTexts): MotiveTexts {
    const result: MotiveTexts = {};
    for (const motive of Motive.LIST) {
      result[motive.toLowerCase()] = this.consolidatedMotiveText(motiveTexts[motive.toLowerCase()]);
    }
    return result;
  }

  private consolidatedMotiveText(motiveText: MotiveText): MotiveText {
    if (typeof motiveText === typeof undefined) {
      return null;
    }

    const consolidatedElements: Pair<string, any>[] = [];
    let index = 0;
    for (let i = 0; i < motiveText.elements.length; i++) {
      if (motiveText.elements[i].key !== 'bulletPoint') {
        if (i > 0 && motiveText.elements[i - 1].key === 'bulletPoint') {
          index++;
        }
        consolidatedElements[index] = motiveText.elements[i];
        index++;
      }
      else {
        // We have a new bulletPoint entry. It (with every other bulletPoint before and after) must be appended to an array.
        if (consolidatedElements[index] === undefined) {
          consolidatedElements[index] = new Pair<string, any[]>('bulletPoint', []);
        }
        consolidatedElements[index].value.push(motiveText.elements[i].value);
      }
    }
    return {
      elements: consolidatedElements
    };
  }

  private introTextWithReplacements(introText: IntroText, accountData: AccountData): IntroText {
    const result: IntroText = {
      heading: introText.heading,
      subheading: introText.subheading
        .replace('{formOfAddress}', this.calculateAppropriateFormOfAddress(accountData.personalData.gender))
        .replace('{academicTitle}', accountData.jobAndEducation.academicTitle)
        .replace(/\s{2,}/g, ' '),
      mainText: undefined,
      mainTextPartA: undefined,
      mainTextPartB: undefined,
      selectMasterText: this.replaceMasterPlaceholder(introText.selectMasterText)
    };

    const introTextSearchKey = '$$Wie ist Dein Ergebnis einzuordnen$';
    const introTextParts = this.splitTextAt(introText.mainText, introTextSearchKey);
    result.mainTextPartA = introTextParts[0];
    result.mainTextPartB = introTextParts[1];

    return result;
  }

  private interpretationTextWithReplacements(interpretationText: InterpretationText): InterpretationText {
    return {
      heading: interpretationText.heading,
      mainText: interpretationText.mainText,
      bulletPointTexts: interpretationText.bulletPointTexts,
      endText: interpretationText.endText,
      selectMasterText: this.replaceMasterPlaceholder(interpretationText.selectMasterText)
    };
  }

  private profileApplicationTextWithReplacements(profileApplicationText: ProfileApplicationText): ProfileApplicationText {
    const profileApplicationTextSearchKey = '$$Vorstellung der einzelnen Motive und ihrer Wirkung$';
    const profileApplicationTextParts = this.splitTextAt(profileApplicationText.mainText1, profileApplicationTextSearchKey);

    const result: ProfileApplicationText = {
      subheading: profileApplicationText.subheading,
      mainText1: undefined,
      mainText1PartA: undefined,
      mainText1PartB: undefined,
      selectMasterText: this.replaceMasterPlaceholder(profileApplicationText.selectMasterText),
      mainText2: profileApplicationText.mainText2,
      printButtonText: profileApplicationText.printButtonText
    };
    result.mainText1PartA = this.replaceMasterPlaceholder(profileApplicationTextParts[0]);
    result.mainText1PartB = this.replaceMasterPlaceholder(profileApplicationTextParts[1]);

    return result;
  }

  private findMasterTextWithReplacements(findMasterText: FindMasterText): FindMasterText {
    return {
      heading: findMasterText.heading,
      mainText: this.replaceMasterPlaceholder(findMasterText.mainText)
    };
  }

  private contactTextWithReplacements(contactText: ContactText): ContactText {
    return {
      heading: contactText.heading,
      mainText: this.replaceMasterPlaceholder(contactText.mainText)
    };
  }

  private calculateAppropriateFormOfAddress(gender: string): string {
    return this.__('formOfAddress', undefined, gender)
      || this.__('formOfAddress', undefined, '');
  }

  private findMasterUrl(): string {
    if (this.currentUser.locale.toLowerCase() === 'de') {
      return 'https://www.id37.io/master-finden';
    }
    else {
      return 'https://www.id37.io/en/master-finder';
    }
  }

  private replaceMasterPlaceholder(text: string): string {
    return text.replace(
      '$$ID37 Master$',
      '<a class="id37-link" href="' + this.findMasterUrl() + '" target="_blank">' + this.__('id37Master') + '</a>'
    );
  }

  private splitTextAt(text: string, searchKey: string): [string, string] {
    const index = text.indexOf(searchKey);
    return [
      text.slice(0, index),
      text.slice(index + searchKey.length, text.length)
    ];
  }

}
