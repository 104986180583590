import {Component, HostBinding, Input} from '@angular/core';
import {Test} from '../state/test';

@Component({
  selector: 'app-test-overview',
  templateUrl: './test-overview.component.html',
  styleUrls: ['./test-overview.component.scss']
})
export class TestOverviewComponent {

  @Input()
    tests: Test[];

  @HostBinding('class.hidden')
  get isHidden() {
    return this.tests.length === 0;
  }

}
