import {Component} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {PositionUserReportRepresentation} from '../types/position-user-report-representation';
import {AdminService} from '../admin.service';
import {FileDownloadService} from '../../shared/file-download-service';
import {MediaType} from '../../shared/media-type';
import {HttpResponse} from '@angular/common/http';


@Component({
  selector: 'app-position-user-report',
  templateUrl: './position-user-report.component.html',
  styleUrls: ['./position-user-report.component.scss']
})
export class PositionUserReportComponent {

  form: FormGroup;
  minDate: Moment;
  maxDate: Moment;

  constructor(
    private translatorService: TranslatorService,
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private fileDownloadService: FileDownloadService) {

    const from = moment().startOf('month').startOf('day').toISOString();
    const to = moment().endOf('month').endOf('day').toISOString();
    this.form = this.formBuilder.group({
      from: [from, Validators.compose([Validators.required])],
      to: [to, Validators.compose([Validators.required])]
    });
    this.minDate = moment().subtract(10, 'years');
    this.maxDate = moment().add(10, 'years');
  }

  download(): void {
    const reqBody: PositionUserReportRepresentation = {
      from: this.form.get('from').value,
      till: this.form.get('to').value
    };
    this.adminService.getPositionUserReport(reqBody).subscribe((response: HttpResponse<ArrayBuffer>) => {
      this.fileDownloadService.saveToFileSystemBlob(
        response,
        MediaType.EXCEL,
        'PositionUserReport.xlsx');
    }, (error) => {
      window.alert('Fehler');
      console.log(error);
    });
  }

  __(key: string): string {
    return this.translatorService.translate('positionUserReportExport.' + key);
  }

}
