import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';
import {FriendshipQuery} from '../../../../friend/state/friendship.query';
import {Id37Router} from '../../../../id37-router';
import {TranslatorService} from '../../../../translation/translator.service';
import {Subject} from 'rxjs';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {FriendshipFunctions} from '../../../../friend/state/friendship-functions';
import {AccountDataQuery} from '../../../../account/account-data-query';

@Component({
  selector: 'app-motives-changed-message',
  templateUrl: './test-share-changed-message.component.html',
  styleUrls: ['./test-share-changed-message.component.scss']
})
export class TestShareChangedMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  friendName: string = '...';
  friendGender: string;
  messageC: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private accountDataQuery: AccountDataQuery,
              private currentUser: CurrentUser,
              private friendshipQuery: FriendshipQuery,
              private router: Id37Router,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.friendshipQuery.selectFriendship(this.notification.data.friendshipId as number).pipe(
      takeUntil(this.unsubscribe$),
      map((friendship) => {
        if (!!friendship) {
          this.friendName = FriendshipFunctions.getFriendFullName(friendship, this.currentUser.keycloakId);
          return friendship;
        }
      }),
      mergeMap((friendship) => this.accountDataQuery.selectAccountData(
        FriendshipFunctions.getFriendUserId(friendship, this.currentUser.keycloakId)))
    ).subscribe((data) => {
      this.friendGender = data.personalData.gender;
      this.computeMessage();
    },
    err => {
      console.log('Error', err);
    });
  }

  computeMessage() {
    this.messageC = this.__('messageC');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToFriend(): void {
    this.router.routeToFriends(this.notification.data.friendshipId as number);
  }

  goToCompareSite(): void {
    this.router.routeToFriendCompare(this.notification.data.friendshipId as number);
  }

  __(key: string): string {
    return this.translatorService.translate('notification.TESTSHARE_CHANGED.' + key)
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
  }

}
