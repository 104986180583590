import {Component, OnInit} from '@angular/core';
import {Order} from '../order';
import {OrderStatus} from '../order-status';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../order.service';
import {OrderAndOrderProcessingResult} from '../order-and-order-processing-result';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-order-processing',
  templateUrl: './order-processing.component.html',
  styleUrls: ['./order-processing.component.scss']
})
export class OrderProcessingComponent implements OnInit {

  order: Order;

  constructor(private orderService: OrderService,
              private translatorService: TranslatorService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.orderService.processOrder(params.orderId as string).subscribe(orderAndOrderProcessingResult => {
        this.order = orderAndOrderProcessingResult.order;
        if (orderAndOrderProcessingResult.order.status === OrderStatus.COMPLETED) {
          this.navigateToOrderCompletedPage(orderAndOrderProcessingResult);
        }
        else {
          this.navigateToOrderNotCompletedPage(orderAndOrderProcessingResult);
        }
      });
    });
  }

  public navigateToOrderCompletedPage(orderAndOrderProcessingResult: OrderAndOrderProcessingResult): void {
    this.router.navigate(['/orders', this.order.orderId, 'completed'], {
      state: {orderAndOrderProcessingResult},
      replaceUrl: true
    });
  }

  public navigateToOrderNotCompletedPage(orderAndOrderProcessingResult: OrderAndOrderProcessingResult): void {
    this.router.navigate(['orders', this.order.orderId, 'not-completed'], {
      state: {orderAndOrderProcessingResult},
      replaceUrl: true
    });
  }

  __(key: string): string {
    return this.translatorService.translate('order.processing.' + key);
  }

}
