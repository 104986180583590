import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';

const API_URL = environment.CHATBOT_API;

@Injectable({
  providedIn: 'root'
})
export class ChatbotHealthService {
  private isChatbotAPIHealthy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient) {
  }

  public check_health(): void {
    const trimmedUrl: string = API_URL.replace('/chatbot', '');
    this.http.get<string>(
      trimmedUrl + '/health',
      {
        responseType: 'text' as 'json',
        observe: 'response'
      }).subscribe({
      next: (response: HttpResponse<string>) => {
        this.isChatbotAPIHealthy.next(true);
      },
      error: error => {
        this.isChatbotAPIHealthy.next(false);
      }
    });
  }

  getChatbotAPIHealthStatus() {
    return this.isChatbotAPIHealthy;
  }
}
