<ng-container *ngIf="!selectedProfilePicture">
  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-12">
      <app-profile-picture [userId]="userId"></app-profile-picture>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!!selectedProfilePicture">
  <app-profile-picture-editor
    #profilePictureEditor
    [imageData]="selectedProfilePicture.base64"></app-profile-picture-editor>
</ng-container>

<div class="clr-row clr-justify-content-center">
  <div class="clr-col-12">
    <div class="clr-form-group">
      <input type="file" id="profilePictureFileInput"
             #profilePictureFileInput style="display: none"
             (change)="onProfilePictureSelection($event)">
      <button type="button" class="id37-btn-outline"
              (click)="selectProfilePicture()">
        {{__('selectProfilePicture')}}
      </button>
      <button *ngIf="!selectedProfilePicture && profilePictureQuery.hasProfilePictureForCurrentUser()"
              type="button" class="id37-btn-outline-secondary"
              (click)="deleteProfilePicture()">
        {{__('deleteProfilePicture')}}
      </button>
      <button *ngIf="!!selectedProfilePicture"
              type="button" class="id37-btn-outline-secondary"
              (click)="clearProfilePictureSelection()">
        {{__('clearProfilePictureSelection')}}
      </button>
      <button *ngIf="!!selectedProfilePicture"
              type="button" class="id37-btn-outline"
              (click)="saveProfilePicture()">
        {{__('saveProfilePicture')}}
      </button>
      <button *ngIf="!selectedProfilePicture"
              disabled
              type="button" class="id37-btn-outline-secondary">
        {{__('saveProfilePicture')}}
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="!!errorMessageKey">
  <div class="clr-row clr-justify-content-center">
    <div class="clr-col-12">
      <div class="alert error">
        {{__(errorMessageKey)}}
      </div>
    </div>
  </div>
</ng-container>
