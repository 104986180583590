import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Team} from '../state/team';
import {Layout} from '../team.component';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-team-navigation',
  templateUrl: './team-navigation.component.html',
  styleUrls: ['./team-navigation.component.scss']
})
export class TeamNavigationComponent implements OnInit {

  @Output() editTeam = new EventEmitter<Team>();

  @Input() teams: Team[];

  @Input() selectedTeam: Team;

  @Input() layout: Layout;

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {

  }

  onEdit(): void {
    this.editTeam.emit(this.selectedTeam);
  }

  __(key: string): string {
    return this.translatorService.translate('teamNav.' + key);
  }

}
