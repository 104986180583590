<div (click)="openChat()" class="chat-icon-wrapper">
  <ng-container *ngIf="amountOfUnreadChatMessages$ | async as amount; else noNewMessages">
    <img alt="chat - {{amount}} new messages" class="icon-svg"
         src="assets/icons/ID37_Chat-Inactive_36x36.svg"/>
    <app-unread-info
        [amount]="amount"
        [emRight]="0.55"
        [emSize]="0.4"
        [emTop]="-0.15"
    ></app-unread-info>
  </ng-container>

  <ng-template #noNewMessages>
    <img alt="chat - 0 new messages" class="icon-svg"
         src="assets/icons/ID37_Chat-Inactive_36x36.svg"/>
  </ng-template>
</div>
