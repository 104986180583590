import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {TestShareCreateRepresentation} from './test-share-create-representation';
import {FriendshipRepresentation} from '../../friend/state/friendship-representation';
import {CurrentUser} from '../../user/current-user.service';
import {Friendship} from '../../friend/state/friendship';
import {FriendshipService} from '../../friend/state/friendship.service';
import {TestShareUpdateRepresentation} from './test-share-update-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class TestShareService {

  constructor(private currentUser: CurrentUser,
              private friendshipService: FriendshipService,
              private http: HttpClient) {
  }

  createTestShare(friendshipId: number, testId: string, testVariation: string): Observable<Friendship> {
    return this.http.post<FriendshipRepresentation>(
      API_URL + '/testshares',
      new TestShareCreateRepresentation(friendshipId, testId, testVariation),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;version=1',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((updatedFriendship: Friendship) => {
        this.friendshipService.storeFriendship(updatedFriendship);
      })
    );
  }

  updateTestShare(friendshipId: number, testShareId: string, testVariation: string): Observable<Friendship> {
    return this.http.patch<FriendshipRepresentation>(
      API_URL + '/testshares',
      new TestShareUpdateRepresentation(friendshipId, testShareId, testVariation),
      {
        headers: new HttpHeaders({
          Accept: 'application/json;version=1',
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((updatedFriendship: Friendship) => {
        this.friendshipService.storeFriendship(updatedFriendship);
      })
    );
  }

}
