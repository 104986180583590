import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {AccountDataQuery} from '../../account/account-data-query';
import {Gender} from '../../client/gender';
import {ProfilePictureQuery} from '../profile-picture-query';
import {ProfilePicture} from '../profile-picture';
import {CurrentUser} from '../../user/current-user.service';
import {Subject} from 'rxjs';
import {AccountData} from '../../account/account-data';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit, OnDestroy {

  @Input()
    allowAccountDataRetrieval: boolean = true;

  unsubscribe$: Subject<void> = new Subject<void>();
  wasSet: boolean = false;
  profilePicture: ProfilePicture | undefined | null = null;
  userIsMale: boolean = false;
  userIsFemale: boolean = false;

  constructor(private currentUser: CurrentUser,
              private accountDataQuery: AccountDataQuery,
              private profilePictureQuery: ProfilePictureQuery) {
  }

  ngOnInit(): void {
    // This component should show the profile picture of the current user by default.
    // If this component is used without a specific userId,
    // we must manually initialize this component to show the profile picture of the current user.
    // If the user of this component gave any userId input, this initialization must not take place.
    if (!this.wasSet) {
      this.userId = undefined;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Sets the id of the user of which the profile picture should be displayed.
   *
   * @param userId
   *   If null, no profile picture will be displayed.
   *   If undefined, the profile picture of the current user is shown.
   *   If any id is given, the profile picture of that user is displayed.
   */
  @Input()
  set userId(userId: string | undefined | null) {
    this.wasSet = true;
    this.unsubscribe$.next();
    if (userId === null) {
      this.userIsMale = false;
      this.userIsFemale = false;
      this.profilePicture = null;
      return;
    }
    const id: string = !!userId ? userId : this.currentUser.keycloakId;
    this.queryProfilePictureForUser(id);
    this.queryAccountDataForUser(id);
  }

  private queryProfilePictureForUser(userId: string) {
    this.profilePictureQuery.selectProfilePicture(userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profilePicture => {
        this.profilePicture = profilePicture;
      });
  }

  private queryAccountDataForUser(userId: string) {
    if (this.allowAccountDataRetrieval) {
      this.accountDataQuery.selectAccountData(userId)
        .pipe(
          takeUntil(this.unsubscribe$),
          map((accountData: AccountData) => accountData.personalData.gender))
        .subscribe(gender => {
          if (!!gender) {
            this.userIsMale = gender === Gender.MALE;
            this.userIsFemale = gender === Gender.FEMALE;
          }
        });
    }
  }

}
