import {NgModule, SecurityContext} from '@angular/core';
import {CommonModule, NgIf, NgOptimizedImage} from '@angular/common';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Id37Module} from '../id37/id37.module';
import {DateTimeModule} from '../datetime/datetime.module';
import {GeometryModule} from '../geometry/geometry.module';
import {ChatbotChatComponent} from './chat/chatbot-chat.component';
import {ChatbotChatInputComponent} from './chatbot-chat-input/chatbot-chat-input.component';
import {ChatbotChatMessageComponent} from './chatbot-chat-message/chatbot-chat-message.component';
import {ChatbotChatMessagesComponent} from './chatbot-chat-messages/chatbot-chat-messages.component';
import {ChatbotChatSelectionComponent} from './chatbot-chat-selection/chatbot-chat-selection.component';
import {ChatbotChatSelectionsComponent} from './chatbot-chat-selections/chatbot-chat-selections.component';
import { ChatbotChatMobileBarComponent } from './chatbot-chat-mobile-bar/chatbot-chat-mobile-bar.component';
import { ChatbotChatMobileSidebarComponent } from './chatbot-chat-mobile-sidebar/chatbot-chat-mobile-sidebar.component';
import {DirectivesModule} from '../directives/directives.module';
import {ChatbotChatCreateComponent} from './chatbot-chat-create/chatbot-chat-create.component';
import {ModalModule} from '../modal/modal.module';
import {ChatModule} from '../chat/chat.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MarkdownModule, MarkedRenderer, MARKED_OPTIONS} from 'ngx-markdown';
import {Renderer} from 'marked';
import {ServiceUnavailableComponent} from '../service-unavailable/service-unavailable.component';
import {ChatbotStartscreenComponent} from './chatbot-startscreen/chatbot-startscreen.component';
import {
  ChatbotChatCreateAdminFormComponent
} from './chatbot-chat-create-admin-form/chatbot-chat-create-admin-form.component';
import {AutosizeModule} from 'ngx-autosize';
import {ClrDropdownModule} from '@clr/angular';
import {EditableModule} from '@ngneat/edit-in-place';
import {HttpClient} from '@angular/common/http';
import {ChatbotChatEditChatTitleComponent} from './chatbot-chat-edit-chat-title/chatbot-chat-edit-chat-title.component';
import {ChatbotLockedScreenComponent} from './chat/chatbot-locked-screen/chatbot-locked-screen.component';
import {CurrentUser} from '../user/current-user.service';
import {ChatbotErrorScreenComponent} from './chat/chatbot-error-screen/chatbot-error-screen.component';

/* Solution to chatbot links opening in a new tab. Links come in Markdown syntax as [Text shown](url).
 * This function transforms them into working html syntax.
 *
 * The firstName attribute is used to replace '$Name$' with the name of the current user by the Markdown renderer
 */
export const markedOptionsFactory = (firstName: string): {
  smartLists: boolean;
  renderer: Renderer;
  tables: boolean;
  breaks: boolean;
  smartypants: boolean;
  pedantic: boolean;
  gfm: boolean;
  sanitize: boolean;
} => {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
  };

  /*
  The chatbot prompts use $Name$ as a placeholder name. $Name$ is then replaced by the user's actual name through this
  renderer option. This avoids storing the user's name.
   */
  renderer.text = (text: string) => text.replace(/\$Name\$/g, firstName);

  return {
    renderer,
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
  };
};


@NgModule({
  declarations: [
    ChatbotChatComponent,
    ChatbotChatInputComponent,
    ChatbotChatMessageComponent,
    ChatbotChatMessagesComponent,
    ChatbotChatSelectionComponent,
    ChatbotChatSelectionsComponent,
    ChatbotStartscreenComponent,
    ChatbotChatCreateComponent,
    ChatbotStartscreenComponent,
    ChatbotChatCreateAdminFormComponent,
    ChatbotChatMobileBarComponent,
    ChatbotChatMobileSidebarComponent
  ],
  imports: [
    CommonModule,
    ProfilePictureModule,
    FormsModule,
    Id37Module,
    DateTimeModule,
    GeometryModule,
    DirectivesModule,
    ModalModule,
    ReactiveFormsModule,
    ChatModule,
    FontAwesomeModule,
    ServiceUnavailableComponent,
    NgOptimizedImage,
    AutosizeModule,
    ClrDropdownModule,
    EditableModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: (currentUser: CurrentUser) => markedOptionsFactory(currentUser.firstName),
        deps: [CurrentUser]
      },
    }),
    ChatbotChatEditChatTitleComponent,
    NgIf,
    ChatbotLockedScreenComponent,
    ChatbotErrorScreenComponent
  ],
  exports: [
    ChatbotChatComponent
  ]
})
export class ChatbotModule {
}
