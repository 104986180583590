import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ProfileApplicationText} from '../types/profile-application';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-profile-application',
  templateUrl: './profile-application.component.html',
  styleUrls: ['./profile-application.component.scss']
})
export class ProfileApplicationComponent {

  @Input()
    profileApplicationText: ProfileApplicationText;

  @Input()
    masterId = '';

  @Output()
    shouldPrintReportEmitter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
    shouldScrollToMotivesDescriptionText = new EventEmitter();

  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate('report.profile-application.' + key);
  }

}
