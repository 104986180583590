import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationQuery} from '../../notifications/state/notification.query';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NotificationService} from '../../notifications/state/notification.service';
import {TranslatorService} from '../../translation/translator.service';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss']
})
export class NotificationOverviewComponent implements OnInit, OnDestroy {

  unreadNotificationAmount: number = 0;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private notificationService: NotificationService,
              private notificationQuery: NotificationQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.notificationQuery.selectUnreadNotificationsAmount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((unreadNotificationsAmount: number) => this.unreadNotificationAmount = unreadNotificationsAmount);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  markAllAsRead(): void {
    this.notificationQuery.selectNotifications()
      .pipe(take(1))
      .subscribe(notifications =>
        notifications.forEach(notification =>
          this.notificationService.markAsRead(notification)));
  }

  __(key: string): string {
    return this.translatorService.translate('nav.notificationOverview.' + key);
  }

}
