import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AccountData} from '../account/account-data';
import {CurrentUser} from '../user/current-user.service';
import {AccountDataQuery} from '../account/account-data-query';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasMaster]'
})
export class HasMasterDirective {

  private unsubscribe$: Subject<void> = new Subject<void>();
  private isHidden: boolean = true;
  private accountData: AccountData | undefined = undefined;

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private currentUser: CurrentUser,
              private accountDataQuery: AccountDataQuery) {
  }

  private updateView(): void {
    if (this.checkViewAllowed()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    }
    else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkViewAllowed(): boolean {
    return !!this.accountData && !!this.accountData.masterInfo;
  }

  private reset(): void {
    this.unsubscribe$.next();
    this.accountData = undefined;
  }

  @Input()
  set hasMaster(userId: string | undefined) {
    this.reset();
    if (typeof userId === typeof undefined) {
      userId = this.currentUser.keycloakId;
    }
    this.accountDataQuery.selectAccountData(userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accountData => {
        this.accountData = accountData;
      });
    this.updateView();
  }

}
