<div class="input">
  <!--suppress HtmlFormInputWithoutLabel -->
  <textarea (focusin)="inputFocused = true"
            (focusout)="inputFocused = false"
            [(ngModel)]="message"
            placeholder="{{__('inputPlaceholder')}}">
    </textarea>
</div>

<div class="actions">
  <!--
  <div class="send-with-enter">
    <input type="checkbox" id="send-with-enter" [(ngModel)]="sendWithEnter">
    <label for="send-with-enter" class="id37-text1">
      {{__('sendWithEnter')}}
    </label>
  </div>
  -->

  <ng-container *ngIf="message.length === 0; else active">
    <div class="send">
      <div class="img-wrapper">
        <img alt="send message: inactive, please enter a message" src="assets/icons/send.svg"/>
      </div>
    </div>
  </ng-container>

  <ng-template #active>
    <div class="send active">
      <div class="img-wrapper">
        <img (click)="sendMessage()" alt="send message" src="assets/icons/send.svg"/>
      </div>
    </div>
  </ng-template>

</div>
