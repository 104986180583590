import {BackendUser} from './backend-user';
import {KeycloakUser} from './keycloak-user';
import {KeycloakUserRepresentation} from './keycloak-user-representation';
import {BackendUserRepresentation} from './backend-user-representation';
import {ActiveUser} from './active-user';

export interface ActiveUserRepresentation {

  readonly keycloakUser: KeycloakUserRepresentation;
  readonly backendUser: BackendUserRepresentation;

}

export class ActiveUserBuilder {

  public static toModelEntity(activeUserRepresentation: ActiveUserRepresentation): ActiveUser {
    return {
      keycloakUser: KeycloakUser.buildFrom(activeUserRepresentation.keycloakUser),
      backendUser: BackendUser.buildFrom(activeUserRepresentation.backendUser)
    };
  }

}
