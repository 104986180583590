<div class="clr-row clr-justify-content-center margin-0 outermost-element">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <div class="img-container">
      <img alt="smiley face with headset" src="assets/icons/ID37_Analyse_Support_100x100.svg"/>
    </div>

    <h3 id="page-title">{{__('analysisSupport')}}</h3>

    <div class="id37-text2" id="info">
      {{__('info')}}
    </div>

    <div id="options">
      <ul class="id37-text2">
        <li [innerHTML]="__('option1')"></li>
        <li [innerHTML]="__('option2')"></li>
      </ul>
    </div>

    <div id="buttons">
      <div class="clr-row">
        <div class="clr-col-xs-12 clr-col-sm-6" id="button1Col">
          <button (click)="openPaidService()" class="id37-btn">
            {{__('option1Btn')}}
          </button>
        </div>
        <div class="clr-col-xs-12 clr-col-sm-6" id="button2Col">
          <button (click)="openFreeService()" class="id37-btn-outline">
            {{__('option2Btn')}}
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
