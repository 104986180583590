import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-centered-loading-indicator',
  templateUrl: './centered-loading-indicator.component.html',
  styleUrls: ['./centered-loading-indicator.component.scss']
})
export class CenteredLoadingIndicatorComponent {

  @Input()
    atTop: boolean = false;

}
