import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Notification} from '../../../state/notification';
import {Subject} from 'rxjs';
import {AccountDataQuery} from '../../../../account/account-data-query';
import {TranslatorService} from '../../../../translation/translator.service';
import {Id37Router} from '../../../../id37-router';
import {takeUntil} from 'rxjs/operators';
import {AccountDataFunctions} from '../../../../account/account-data';

@Component({
  selector: 'app-test-expired-of-client-message',
  templateUrl: './test-expired-of-client-message.component.html',
  styleUrls: ['./test-expired-of-client-message.component.scss']
})
export class TestExpiredOfClientMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  private unsubscribe$: Subject<void> = new Subject<void>();

  userName: string = '...';
  messagePartA: string = '';
  messagePartB: string = '';

  constructor(private accountDataQuery: AccountDataQuery,
              private translatorService: TranslatorService,
              private id37Router: Id37Router) {
  }

  ngOnInit(): void {
    this.computeMessage();
    this.accountDataQuery.selectAccountData(this.notification.data.userId as string)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accountData => this.userName = AccountDataFunctions.fullName(accountData));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToCustomers(): void {
    this.id37Router.routeToCustomers(this.notification.data.userId as string);
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
    this.messagePartB = this.__('partB');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
