import {ChatbotChat, ChatbotChatCreate} from '../state/chatbot-chat';
import {Test} from '../../test/state/test';
import {ChatbotChatUser} from '../state/chatbot-chat-user';
import {Motive} from '../../report/types/motives/motive';
import {CurrentUser} from '../../user/current-user.service';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {TestService} from '../../test/state/test.service';
import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ChatbotChatCreateService{

  constructor(private currentUser: CurrentUser,
              private chatbotChatService: ChatbotChatService,
              private testService: TestService) {
  }
  async createChat(): Promise<ChatbotChat> {
    const chatCreate = new ChatbotChatCreate(this.currentUser.keycloakId);
    const chatPromise = this.chatbotChatService.addChat(chatCreate).toPromise();
    let chat: ChatbotChat;

    try {
      await chatPromise.then((data) => {
        chat = data;
      });
    }
    catch (error) {
      console.error('Error', error);
    }

    return  chat;
  }
}
