import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';
import {Id37Router} from '../../../../id37-router';
import {Router} from '@angular/router';
import {AccountDataQuery} from '../../../../account/account-data-query';

@Component({
  selector: 'app-test-invitation-message',
  templateUrl: './test-invitation-message.component.html',
  styleUrls: ['./test-invitation-message.component.scss']
})
export class TestInvitationMessageComponent implements OnInit {

  @Input()
    notification: Notification;

  inviterName: string = '';
  messagePartA: string = '';
  questionnaire: string = '';
  messagePartB: string = '';

  constructor(private currentUser: CurrentUser,
              private accountDataQuery: AccountDataQuery,
              private translatorService: TranslatorService,
              private router: Router,
              private id37Router: Id37Router) {
  }

  ngOnInit(): void {
    this.computeMessage();
  }

  goToUserProfile(): void {
    this.router.navigate(['user-profile', this.notification.data.inviterId]);
  }

  goToTest(): void {
    this.id37Router.routeToTest(this.notification.data.testId as string);
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
    this.questionnaire = this.__('questionnaire');
    this.messagePartB = this.__('partB');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
