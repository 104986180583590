import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {CollapsibleItemComponent} from '../../../collapsible/collapsible-item/collapsible-item.component';

@Component({
  selector: 'app-consult-master',
  templateUrl: './consult-master.component.html',
  styleUrls: ['./consult-master.component.scss']
})
export class ConsultMasterComponent implements OnInit {

  @ViewChild('master')
    collapsibleItem: CollapsibleItemComponent;

  @Input()
    friendFullName: string;

  @Input()
    friendGender: string;

  constructor(
    private currentUser: CurrentUser,
    private translatorService: TranslatorService
  ) {
  }

  ngOnInit(): void {
  }

  __(key: string): string {
    let revisedTranslation: string = this.translatorService.translate('consultMaster.' + key);
    revisedTranslation = revisedTranslation.replace(/\*NAME\*/g, this.friendFullName);
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.dative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.F\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.f/pl',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*NOMINATIVE\*/g, this.translatorService.translate(
        'personalPronoun.nominative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*DATIVE\*/g, this.translatorService.translate(
        'personalPronoun.dative',
        this.currentUser.locale,
        this.friendGender));
    revisedTranslation = revisedTranslation
      .replace(/\*ACCUSATIVE\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
    return revisedTranslation;
  }

}
