<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h1 id="title">Select-Test</h1>

    <input placeholder="test" style="margin-bottom: 1em" type="text">

    <app-select (selection)="onSelection($event)"
                [choicesGroups]="countries"
                [placeholder]="'Dein Land...'"
                [type]="'select'"
                style="margin-bottom: 1em"></app-select>

    <app-select (selection)="onSelection($event)"
                [choicesGroups]="countries"
                [placeholder]="'Dein Land...'"
                [type]="'select'"
                style="margin-bottom: 1em"></app-select>

    <app-select (selection)="onSelection($event)"
                [choicesGroups]="countries"
                [placeholder]="'Dein Land...'"
                [type]="'select'"
                style="margin-bottom: 1em"></app-select>

    <app-select (selection)="onSelection($event)"
                [choicesGroups]="countries"
                [placeholder]="'Dein Land...'"
                [type]="'select'"
                style="margin-bottom: 1em"></app-select>

    <app-select (selection)="onSelection($event)"
                [choicesGroups]="countries"
                [disabled]="true"
                [type]="'multiselect'"></app-select>

    <p style="margin-top: 1em">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
      et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
      amet,
      consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
      sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no
      sea
      takimata sanctus est Lorem ipsum dolor sit amet.
    </p>

  </div>
</div>
