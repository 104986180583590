<h3>{{__('title')}}</h3>
<app-collapsible-list>
  <app-collapsible-item class="friends-collapsible-item">
    <app-collapsible-header [title]="__('headline')"></app-collapsible-header>
    <app-collapsible-body>

      <div class="id37-text2 text-light" [innerHTML]="__('text')"></div>

      <ol class="id37-text2 text-light">
        <li>
          <span [innerHTML]="__('listItem1')"></span>
          <ol>
            <li [innerHTML]="__('listItem1_1')"></li>
            <li [innerHTML]="__('listItem1_2')"></li>
            <li [innerHTML]="__('listItem1_3')"></li>
          </ol>
        </li>
        <li [innerHTML]="__('listItem2')"></li>
        <li [innerHTML]="__('listItem3')"></li>
      </ol>

      <div class="id37-text2 text-light" [innerHTML]="__('attitude')"></div>

    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>

<app-collapsible-list>
  <app-collapsible-item #master class="friends-collapsible-item">
    <app-collapsible-header [title]="__('deep.headline')"></app-collapsible-header>
    <app-collapsible-body>

      <div class="id37-text2 text-light" [innerHTML]="__('deep.text')"></div>

      <div id="masterAnchor" class="id37-text2 text-light section" [innerHTML]="__('deep.master')"></div>
      <div class="id37-text2 text-light section" [innerHTML]="__('deep.book')"></div>
      <div class="id37-text2 text-light section">
        <span [innerHTML]="__('deep.videos1')"></span>
        <span [innerHTML]="__('deep.videos.link')" class="id37-link"></span>
        <span [innerHTML]="__('deep.videos2')"></span>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>







