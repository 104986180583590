import {Injectable} from '@angular/core';

@Injectable()
export class SelectState {

  type: 'select' | 'multiselect' = 'select';
  disabled: boolean = false;
  deselectAllowed: boolean = false; // Only relevant on type='select'
  search: string = '';
  menuOpen: boolean = false;
  clickedInside: boolean = false;
  inFocus: boolean = false;
  touched: boolean = false;

  placeholder: string = '';
  searchPlaceholder: string = '';
  noSearchResultsTranslation: string = '';
  selectedTranslation: string = '';

  isSelect(): boolean {
    return this.type === 'select';
  }

  isMultiselect(): boolean {
    return this.type === 'multiselect';
  }

}
