export enum PaymentProvider {

  UNZER = 'UNZER'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PaymentProvider {
  export const LIST: PaymentProvider[] = [
    PaymentProvider.UNZER
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): PaymentProvider | undefined => {
    name = !!name ? name : '';
    const found = PaymentProvider.LIST.find((status: string) => status.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a payment provide with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

