<div *ngIf="motiveText.elements.length !== 0">
  <app-collapsible-item>

    <app-collapsible-header [title]="motiveText.elements[0].value.trim().replace('<br>', '')"
                            id="motive-{{motiveName}}-collapsible-header">
    </app-collapsible-header>

    <app-collapsible-body *ngIf="show">
      <div *ngFor="let element of motiveText.elements; let i = index">
        <div *ngIf="!!element && i !== 0">
          <h4 *ngIf="element.key === 'heading'" class="heading"
              [innerHTML]="element.value">
          </h4>

          <h2 *ngIf="element.key === 'headingIndented'" [innerHTML]="element.value"
              class="indented">
          </h2>

          <h4 *ngIf="element.key === 'subheading'"
              [innerHTML]="element.value">
          </h4>

          <h5 *ngIf="element.key === 'subheadingIndented'" [innerHTML]="element.value">
          </h5>

          <div *ngIf="element.key === 'mainText'" [innerHTML]="element.value"
               class="id37-text2 text-light">
          </div>

          <div *ngIf="element.key === 'mainTextIndented'" [innerHTML]="element.value"
               class="id37-text2 text-light indented">
          </div>

          <ul *ngIf="element.key === 'bulletPoint'" class="id37-text2 text-light">
            <li *ngFor="let entry of element.value" [innerHTML]="entry"></li>
          </ul>

          <ul *ngIf="element.key === 'bulletPointIndented'" class="id37-text2 text-light indented">
            <li *ngFor="let entry of element.value" [innerHTML]="entry"></li>
          </ul>
        </div>
      </div>
    </app-collapsible-body>

  </app-collapsible-item>
</div>
