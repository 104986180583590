import {PartnerInfo} from './partner-info';

export class PartnerInfoRepresentation {

  public readonly userId: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;

  constructor(userId: string, firstName: string, lastName: string, email: string) {
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }

  public static toModelEntity(partnerInfoRepresentation: PartnerInfoRepresentation): PartnerInfo {
    return {
      userId: partnerInfoRepresentation.userId,
      firstName: partnerInfoRepresentation.firstName,
      lastName: partnerInfoRepresentation.lastName,
      email: partnerInfoRepresentation.email
    };
  }

}
