import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateTimeSelectionEvent} from '../state/date-time-selection-event';

@Component({
  selector: 'app-datetime-test',
  templateUrl: './datetime-test.component.html',
  styleUrls: ['./datetime-test.component.scss']
})
export class DateTimeTestComponent implements OnInit {

  minDate: Moment = moment('2018-05-06').hour(0).minute(0);
  maxDate: Moment = moment().hour(23).minute(59);

  constructor() {
  }

  ngOnInit(): void {
  }

  onSelection(selected: DateTimeSelectionEvent): void {
  }

}
