import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TeamUser} from '../../../../team/state/team-user';
import {BubbleScoreFunctions} from '../../../../team/state/bubble-score';
import {TeamService} from '../../../../team/state/team.service';
import {ProfilePictureQuery} from '../../../../profile-picture/profile-picture-query';
import {Observable} from 'rxjs';
import {ProfilePicture} from '../../../../profile-picture/profile-picture';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-team-graphic-nav-bubble',
  templateUrl: './team-graphic-nav-bubble.component.html',
  styleUrls: ['./team-graphic-nav-bubble.component.scss']
})
export class TeamGraphicNavBubbleComponent implements OnInit {

  @Input() name: string;

  @Input() colorIndex: number;

  @Input() amount: number;

  @Input() teamUser: TeamUser;

  @Input() teamUsers: TeamUser[];

  @Output() openDropdown = new EventEmitter();

  @Output() toggledEye = new EventEmitter();

  @Input() hide: boolean;
  hasProfilePicture$: Observable<ProfilePicture>;
  borderColor: string;
  fillColor: string;
  cssClass: string;
  tooltipUsername: string;

  constructor(private profilePictureQuery: ProfilePictureQuery,
              private teamService: TeamService) {
  }

  ngOnInit(): void {
    this.tooltipUsername = '';
    this.computeBubbleColor();
  }

  public toggleEye(): void {
    if (!this.teamUsers) {
      this.hide = !this.hide;

      this.teamService.changedSelectedTeamView(this.teamUser, this.hide);
      this.toggledEye.emit();
    }
    else {
      this.openDropdown.emit();
    }
  }

  private computeBubbleColor() {
    if (!this.teamUsers) {
      const bubbleColors: string[] = BubbleScoreFunctions.computeBubbleColor(this.colorIndex, this.amount);
      this.borderColor = bubbleColors[0];
      this.fillColor = bubbleColors[1];
      this.cssClass = 'name-bubble';
      this.hide = this.teamUser.hide;
      this.hasProfilePicture$ = this.profilePictureQuery.selectProfilePictureWithoutLoading(this.teamUser.keycloakId);
      this.tooltipUsername = `${this.teamUser.firstName} ${this.teamUser.lastName}`;
    }
    else {
      const bubbleColors: string[] = BubbleScoreFunctions.computeBubbleColor(0, this.amount, true);
      this.borderColor = bubbleColors[0];
      this.fillColor = bubbleColors[1];
      this.cssClass = 'placeholder-bubble';
    }
  }
}
