<ng-container *ngIf="profilePicture !== null">
  <ng-container *ngIf="!!profilePicture && profilePicture.base64">
    <img [src]="profilePicture.base64"
         alt="Your profile picture">
  </ng-container>

  <ng-container *ngIf="!profilePicture || !profilePicture.base64">
    <img *ngIf="userIsMale"
         class="default"
         src="assets/images/ID37_default_profile_picture_male_100x100.svg"
         alt="Your profile picture">
    <img *ngIf="userIsFemale"
         class="default"
         src="assets/images/ID37_default_profile_picture_female_100x100.svg"
         alt="Your profile picture">
    <img *ngIf="!userIsMale && !userIsFemale"
         class="default"
         src="assets/images/ID37_default_profile_picture_diverse_100x100.svg"
         alt="Your profile picture">
  </ng-container>
</ng-container>

