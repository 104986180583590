import {Subscription, timer} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ChatMessagesService} from './chat-messages.service';
import {ChatMessagesQuery} from './chat-messages.query';
import {CurrentUser} from '../../user/current-user.service';
import {ChatMessagesStore, StoredContactChatMessages} from './chat-messages.store';

@Injectable({
  providedIn: 'root'
})
export class ChatMessagesAutomationService {

  public static unreadMessagesSlowPollRateInSeconds: number = 45;
  public static unreadMessagesFastPollRateInSeconds: number = 5;

  private activeContactSubscription: Subscription;
  private pollingSubscription: Subscription;

  constructor(private currentUser: CurrentUser,
              private chatMessagesStore: ChatMessagesStore,
              private chatMessagesQuery: ChatMessagesQuery,
              private chatMessagesService: ChatMessagesService) {
  }

  startListeningForContactChanges(): void {
    if (!this.activeContactSubscription) {
      this.activeContactSubscription = this.chatMessagesQuery
        .selectActiveContactChanges()
        .pipe(
          tap((activeContact: StoredContactChatMessages | undefined) => {
            // console.log('Active chat contact changed.');
            if (!!activeContact) {
              // New contact got selected: Load messages for this contact.
              if (!activeContact.allLoaded) {
                this.chatMessagesService.loadAllChatMessages(activeContact.contactUserId)
                  .pipe(take(1))
                  .subscribe();
                this.chatMessagesStore.updateAllLoadedState(activeContact.contactUserId, true);
              }
              // And check if already loaded but still unreceived messages exist.
              this.chatMessagesService.updateReceivedStateOfMessages(
                activeContact.contactUserId, activeContact.chatMessages);
              // Check if already loaded but still unread messages exist.
              this.chatMessagesService.updateReadStateOfMessages(
                activeContact.contactUserId, activeContact.chatMessages);
            }
          })
        ).subscribe();
    }
    else {
      console.warn('Already started!');
    }
  }

  stopListeningForContactChanges(): void {
    if (!!this.activeContactSubscription) {
      this.activeContactSubscription.unsubscribe();
      this.activeContactSubscription = undefined;
    }
  }

  loadInitialChatMessages(): void {
    this.chatMessagesService.loadInitialChatMessages().pipe(take(1)).subscribe();
  }

  startPollingUnreadChatMessages(pollRateInSeconds: number): void {
    if (!this.pollingSubscription) {
      // Start polling unread. A message is marked as unread again if it was updated.
      // Messages will be stored by the service automatically,
      // which includes checks against the received and read state.
      this.pollingSubscription =
        timer(0, pollRateInSeconds * 1000)
          .pipe(switchMap(_ => this.chatMessagesService.loadUnreadChatMessages().pipe(take(1))))
          .subscribe();
    }
    else {
      console.warn('Already started!');
    }
  }

  switchToFastPollingOfUnreadChatMessages(): void {
    this.stopPollingUnreadChatMessages();
    this.startPollingUnreadChatMessages(
      ChatMessagesAutomationService.unreadMessagesFastPollRateInSeconds
    );
  }

  switchToSlowPollingOfUnreadChatMessages(): void {
    this.stopPollingUnreadChatMessages();
    this.startPollingUnreadChatMessages(
      ChatMessagesAutomationService.unreadMessagesSlowPollRateInSeconds
    );
  }

  stopPollingUnreadChatMessages(): void {
    if (!!this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = undefined;
    }
  }

}
