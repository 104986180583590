import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChoicesOptions} from '../user-data/choices-options';
import {CurrentUser} from '../user/current-user.service';



@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit, AfterViewInit {

  @ViewChild('h1', {static: true}) head1: ElementRef;
  @ViewChild('h2', {static: true}) head2: ElementRef;
  @ViewChild('h3', {static: true}) head3: ElementRef;
  @ViewChild('h4', {static: true}) head4: ElementRef;
  @ViewChild('h5', {static: true}) head5: ElementRef;
  @ViewChild('h6', {static: true}) head6: ElementRef;

  @ViewChild('t1', {static: true}) text1: ElementRef;
  @ViewChild('t2', {static: true}) text2: ElementRef;
  @ViewChild('t3', {static: true}) text3: ElementRef;
  @ViewChild('t4', {static: true}) text4: ElementRef;
  @ViewChild('t5', {static: true}) text5: ElementRef;


  @ViewChild('g0', {static: true}) gray0: ElementRef;
  @ViewChild('g1', {static: true}) gray1: ElementRef;
  @ViewChild('g2', {static: true}) gray2: ElementRef;
  @ViewChild('g3', {static: true}) gray3: ElementRef;
  @ViewChild('g4', {static: true}) gray4: ElementRef;
  @ViewChild('g5', {static: true}) gray5: ElementRef;
  @ViewChild('g6', {static: true}) gray6: ElementRef;

  @ViewChild('c1', {static: true}) color1: ElementRef;
  @ViewChild('c2', {static: true}) color2: ElementRef;
  @ViewChild('c3', {static: true}) color3: ElementRef;
  @ViewChild('c4', {static: true}) color4: ElementRef;
  @ViewChild('c5', {static: true}) color5: ElementRef;
  @ViewChild('c6', {static: true}) color6: ElementRef;
  @ViewChild('c7', {static: true}) color7: ElementRef;
  @ViewChild('c8', {static: true}) color8: ElementRef;
  @ViewChild('c9', {static: true}) color9: ElementRef;
  @ViewChild('c10', {static: true}) color10: ElementRef;

  hexDigits: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'e', 'd', 'f'];
  languageChoices;

  constructor(private currentUser: CurrentUser) {
  }

  ngOnInit(): void {
    this.languageChoices = ChoicesOptions.translatedLanguages(this.currentUser.locale);
  }


  getFontSize(ele: ElementRef<HTMLElement>): string {
    return getComputedStyle(ele.nativeElement).fontSize;
  }


  getLineHeight(ele: ElementRef<HTMLElement>): string {
    return getComputedStyle(ele.nativeElement).lineHeight;
  }


  // Function to convert rgb color to hex format
  rgb2hex(ele: ElementRef<HTMLElement>): string {
    const rgb = getComputedStyle(ele.nativeElement).backgroundColor;
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    const rgb2 = rgb.match(RegExp(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/));
    return '#' + this.hex(rgb2[1]) + this.hex(rgb2[2]) + this.hex(rgb2[3]);
  }

  hex(x): string {
    return isNaN(x as number) ? '00' : this.hexDigits[(x - x % 16) / 16] + this.hexDigits[x % 16];
  }

  ngAfterViewInit(): void {


  }

}
