import {ChatbotChatCreateRepresentation} from './chatbot-chat-representation';

export class ChatbotChatUser {
  public readonly user_id: string;

  public readonly neugier: number;
  public readonly soziale_anerkennung: number;
  public readonly einfluss: number;
  public readonly status: number;
  public readonly besitzen: number;
  public readonly autonomie: number;
  public readonly sozialkontakte: number;
  public readonly prinzipien: number;
  public readonly soziales_engagement: number;
  public readonly struktur: number;
  public readonly sicherheit: number;
  public readonly revanche: number;
  public readonly bewegung: number;
  public readonly essensgenuss: number;
  public readonly familie: number;
  public readonly sinnlichkeit: number;


  constructor(user_id: string, neugier: number, soziale_anerkennung: number, einfluss: number, status: number, besitzen: number,
              autonomie: number, sozialkontakte: number, prinzipien: number, soziales_engagement: number, struktur: number,
              sicherheit: number, revanche: number, bewegung: number, essensgenuss: number, familie: number, sinnlichkeit: number) {
    this.user_id = user_id;
    this.neugier = neugier;
    this.soziale_anerkennung = soziale_anerkennung;
    this.einfluss = einfluss;
    this.status = status;
    this.besitzen = besitzen;
    this.autonomie = autonomie;
    this.sozialkontakte = sozialkontakte;
    this.prinzipien = prinzipien;
    this.soziales_engagement = soziales_engagement;
    this.struktur = struktur;
    this.sicherheit = sicherheit;
    this.revanche = revanche;
    this.bewegung = bewegung;
    this.essensgenuss = essensgenuss;
    this.familie = familie;
    this.sinnlichkeit = sinnlichkeit;
  }

}
