import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {Friendship} from '../state/friendship';
import {CurrentUser} from '../../user/current-user.service';
import {FriendshipFunctions, ShareStatus} from '../state/friendship-functions';
import {BasicUser} from '../../user/basic-user';
import {TranslatorService} from '../../translation/translator.service';
import {Id37Router} from '../../id37-router';
import {TestQuery} from '../../test/state/test.query';


@Component({
  selector: 'app-friend-tile',
  templateUrl: './friend-tile.component.html',
  styleUrls: ['./friend-tile.component.scss']
})
export class FriendTileComponent {

  @Input()
    user: BasicUser | undefined;

  /* Either one of user or friendship must be set! */
  distance: number = 0.0;
  currentRingProgress: number = 0.0;
  shareStatus: ShareStatus;
  friendship: Friendship | undefined;
  friendUserId: string;
  friendName: string;
  friendEmail: string | undefined;
  @Input()
    loading: boolean = false;
  @Output()
    createFriendship: EventEmitter<BasicUser> = new EventEmitter<BasicUser>();
  @Output()
    resendFriendshipRequest: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    acceptFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    declineFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    deleteFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    updateTestShare: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    recommend: EventEmitter<void> = new EventEmitter<void>();
  private readonly currentUserId: string;

  constructor(private currentUser: CurrentUser,
              private id37Router: Id37Router,
              private testQuery: TestQuery,
              private translatorService: TranslatorService) {
    this.currentUserId = this.currentUser.keycloakId;
  }

  @HostBinding('class.default-cursor')
  get hasDefaultCursor() {
    // Friends, but at least one user did not finish his/her test yet.
    return !!this.friendship && this.friendship.testShares.length !== 2;
  }

  @Input()
  set activeFriendship(friendship: Friendship | undefined) {
    this.friendship = friendship;
    if (!!this.friendship) {
      this.friendUserId = FriendshipFunctions.getFriendUserId(this.friendship, this.currentUserId);
      this.friendName = FriendshipFunctions.getFriendFullName(this.friendship, this.currentUserId);
      this.testQuery.selectTestsForCurrentUser().subscribe((test) => {
        this.shareStatus = FriendshipFunctions.getShareStatus(friendship, this.currentUserId, test);
      }
      );
      // If the current user was the recommender of the friend at hand and the friendship is not yet approved,
      // then show the email of the friend.
      if (this.currentUserId === this.friendship.userA.id
        && this.friendship.userB.recommenderId === this.currentUserId
        && this.friendship.inviteApproved === false) {
        this.friendEmail = FriendshipFunctions.getFriendEmail(this.friendship, this.currentUserId);
      }
      const testResultDistance = FriendshipFunctions.getDistanceBetweenNewestTestShares(this.friendship);
      if (!!testResultDistance) {
        this.distance = testResultDistance.skewedDistance;
      }
      else {
        this.distance = 0.0;
      }
    }
    else {
      this.friendUserId = '---';
      this.friendName = '---';
      this.friendEmail = undefined;
      this.distance = 0.0;
    }
  }

  routeToDashboard(): void {
    this.id37Router.routeToDashboard();
  }

  openCompare(): void {
    if (!!this.friendship && this.friendship.userA.testShares.length && this.friendship.userB.testShares.length) {
      this.id37Router.routeToFriendCompare(this.friendship.id);
    }
  }

  __(key: string): string {
    return this.translatorService.translate('friendTile.' + key);
  }

}
