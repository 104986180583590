export enum Product {

  ID37_TEST = 'ID37_TEST',
  ID37_TEST_WITH_COACHING = 'ID37_TEST_WITH_COACHING',
  ID37_CHATBOT= 'ID37_CHATBOT'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Product {
  export const LIST: Product[] = [Product.ID37_TEST, Product.ID37_TEST_WITH_COACHING, Product.ID37_CHATBOT];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): Product | undefined => {
    name = !!name ? name : '';
    const found = Product.LIST.find((product: string) => product.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a product with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };
}
