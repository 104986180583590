<ng-container *ngIf="activeContact$ | async as activeContact">
  <ng-container *ngIf="!!activeContact">

    <id37-open-arrow (click)="deselect()" [orientation]="'left'"></id37-open-arrow>

    <div class="contact-info">
      <app-profile-picture [userId]="activeContact.friend.id"></app-profile-picture>
      <div class="contact-data">
        <div class="contact-name">
          {{activeContact.friend.firstName}} {{activeContact.friend.lastName}}
        </div>
        <div class="last-message-date">
          <ng-container *ngIf="!!activeContact.lastMessage">
            {{activeContact.lastMessage.sent | JsDateTime}}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="to-dashboard id37-text3">
      <a (click)="id37router.routeToDashboard()">{{__('backToDashboard')}}</a>
    </div>

  </ng-container>
</ng-container>
