import {Node} from './node';

export abstract class NodeRepresentation<T> {

  public readonly id: number;
  public readonly payload: T;

  public static toModelEntity<T, U>(nodeRepresentation: NodeRepresentation<T>,
                                    payloadConverter: (payload: T) => U): Node<U> {
    return {
      id: nodeRepresentation.id,
      payload: payloadConverter.call(this, nodeRepresentation.payload)
    };
  }

}
