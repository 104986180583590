import {Component, Inject, OnInit} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {MotiveComponent} from '../motive/motive.component';
import {ReportComponentService} from '../report-component-service';
import {Observable} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {ScrollService} from '../../id37/scroll.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  motives$: Observable<MotiveComponent[]>;

  collapsed = false;
  overlapping = false;
  mobileMenuOpen = false;
  isMobile = /mobile/i.test(navigator.userAgent);

  constructor(public scrollService: ScrollService,
              private reportComponentService: ReportComponentService,
              private translatorService: TranslatorService,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.motives$ = this.reportComponentService.motiveComponents$;
  }

  toggleCollapsedState(): void {
    this.collapsed = !this.collapsed;
    this.overlapping = false;
  }

  enableOverlapping(): void {
    setTimeout(() => {
      this.overlapping = true;
    }, 200);
  }

  toggleMobileMenu(): void {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  closeMobileMenu(): void {
    this.mobileMenuOpen = false;
  }

  __(key: string): string {
    return this.translatorService.translate('report.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
