import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Friendship} from './friendship';

export type FriendshipState = EntityState<Friendship, number>;

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'friendship'})
export class FriendshipStore extends EntityStore<FriendshipState> {

  constructor() {
    super();
  }

}
