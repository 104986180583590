import {Injectable} from '@angular/core';

import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class CollapsibleAnimationsService {

  private static collapsibleBodyAnimationDuration = '0.25s';
  private static easeInQuad = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)';
  private static easeOutQuad = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';

  static collapsibleBodyAnimations(triggerName: string): AnimationTriggerMetadata[] {
    return [
      trigger(triggerName, [
        // General styles.
        state('*', style({
          height: 0,
          padding: 0,
          overflow: 'hidden'
        })),
        // Styles when body is shown.
        state('true', style({
          overflow: 'inherit'
        })),
        transition('* => true',
          animate(
            CollapsibleAnimationsService.collapsibleBodyAnimationDuration + ' ' +
            CollapsibleAnimationsService.easeInQuad
          )
        ),
        transition('* => false',
          animate(CollapsibleAnimationsService.collapsibleBodyAnimationDuration + ' ' +
            CollapsibleAnimationsService.easeOutQuad
          )
        )
      ])
    ];
  }

}
