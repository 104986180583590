import {Component} from '@angular/core';
import {DiscountCodeService} from '../../../order/discountcode/discount-code.service';
import {TranslatorService} from '../../../translation/translator.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'app-discount-code-usages-report',
  templateUrl: './discount-code-usages-report.component.html',
  styleUrls: ['./discount-code-usages-report.component.scss']
})
export class DiscountCodeUsagesReportComponent {

  form: FormGroup;
  minDate: Moment;
  maxDate: Moment;

  constructor(private discountCodeService: DiscountCodeService,
              private translatorService: TranslatorService,
              private formBuilder: FormBuilder) {

    const from = moment().startOf('month').startOf('day').toISOString();
    const to = moment().endOf('month').endOf('day').toISOString();
    this.form = this.formBuilder.group({
      from: [from, Validators.compose([Validators.required])],
      to: [to, Validators.compose([Validators.required])],
      codePattern: '',
      categoryPattern: ''
    });
    this.minDate = moment().subtract(10, 'years');
    this.maxDate = moment().add(10, 'years');
  }

  download(): void {
    this.discountCodeService.downloadUsageExportExcel(
      this.form.controls.from.value as string || null,
      this.form.controls.to.value as string || null,
      this.form.controls.codePattern.value as string || null,
      this.form.controls.categoryPattern.value as string || null
    );
  }

  __(key: string): string {
    return this.translatorService.translate('discountCodeUsagesExport.' + key);
  }

}
