import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input()
    reactive: boolean = true;

  @Output()
    activated: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  onClick(): void {
    this.activated.emit();
  }

}
