<div #graphContainer class="graph-container"></div>

<div *ngIf="!!graph; else loading" class="menu">
  <div class="option">
    <button (click)="resetCamera()" class="id37-btn id37-btn-secondary id37-btn-sm">Reset camera</button>
  </div>
  <div class="option">
    <app-toggle-switch (valueChange)="toggleTextNodes($event)" [(value)]="showTextNodes"></app-toggle-switch>
    <span class="name">Show text nodes</span>
  </div>
</div>

<ng-template #loading>
  <div [ngStyle]="{'background-color': backgroundColor}" class="loading">
    Loading the friendship graph...
    <app-loading-indicator></app-loading-indicator>
  </div>
</ng-template>
