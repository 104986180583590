import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChatbotChat} from '../state/chatbot-chat';

@Component({
  selector: 'app-chatbot-chat-mobile-bar',
  templateUrl: './chatbot-chat-mobile-bar.component.html',
  styleUrls: ['./chatbot-chat-mobile-bar.component.scss']
})
export class ChatbotChatMobileBarComponent {
  @Input()
    conversationName: string;
  @Input()
    selectedChat: ChatbotChat;
  @Output()
    newChatClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output()
    sidebarEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
    chatSelected: EventEmitter<ChatbotChat> = new EventEmitter<ChatbotChat>();

  newChat() {
    this.selectedChat = null;
    this.chatSelected.emit(this.selectedChat);
  }

  toggleSidebar() {
    this.sidebarEnabled.emit();
  }
}
