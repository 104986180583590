import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';

const API_URL = environment.API_URL;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  selectedFile: File;
  success = false;
  failureState = false;
  failure: { importErrors: [{ rowNumber: number; errorMessage: string }] };
  inProgress = false;

  isImportConfirmationModalVisible = false;
  isDisclaimerConfirmed = false;

  @ViewChild('fileInput', {static: true})
    fileInput: ElementRef;

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private http: HttpClient) {
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.failure = null;
    this.failureState = false;
    this.success = false;
  }

  onUpload() {
    this.inProgress = true;
    const uploadData = new FormData();
    uploadData.append('file', this.selectedFile, this.selectedFile.name);
    this.http.post(API_URL + '/import', uploadData, {
      reportProgress: true
    })
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.failure = err.error;
            this.failureState = true;
          }
          else {
            this.failure = null;
            this.failureState = false;
          }
          this.success = false;
          this.selectedFile = null;
          this.inProgress = false;
          this.fileInput.nativeElement.value = '';
          return of(err);
        })
      )
      .subscribe(response => {
        if (response === null) {
          this.success = true;
          this.failure = null;
          this.failureState = false;
          this.inProgress = false;
          this.selectedFile = null;
          this.fileInput.nativeElement.value = '';
        }
      });
  }

  ngOnInit() {
  }

  openImportConfirmationModal(): void {
    this.isImportConfirmationModalVisible = true;
    this.isDisclaimerConfirmed = false;
  }

  closeImportConfirmationModal(): void {
    this.isImportConfirmationModalVisible = false;
  }

  __(key: string): string {
    return this.translatorService.translate('import.' + key);
  }

}
