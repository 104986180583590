import {Component, Input, OnInit} from '@angular/core';
import {MotiveScore} from '../../test/state/motive-score';
import {Chart} from 'chart.js';
import {Motive, MotiveButton} from '../../report/types/motives/motive';
import {TranslatorService} from '../../translation/translator.service';
import {ChartColor} from '../chart-color';

export interface RadarDatasets {
  label: string;
  borderColor: string;
  backgroundColor: string;
  data: number[];
  borderWidth: number;
}

@Component({
  selector: 'app-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.scss']
})
export class RadarComponent implements OnInit {

  @Input()
    customerName: string[];

  @Input()
    customerMotiveScore: MotiveScore[][];

  chart: Chart;
  motiveLabels: string[] = [];
  motiveButtons: MotiveButton[] = [];
  unselected: number[] = [];
  borderColor: string[] = ChartColor.getChartColors(0.6);
  backgroundColor: string[] = ChartColor.getChartColors(0.3);

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.computeMotiveButtons();
    this.buildChart();
    this.computeMotiveLabels();
    this.initDatasets();
  }

  computeMotiveButtons(): void {
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        this.motiveButtons.push({
          motive: i,
          displayName: this.translatorService.translate('motive.' + m.valueOf().toLowerCase())
        });
      }
    });
  }

  computeMotiveLabels(): void {
    this.motiveLabels = [];
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        if (this.unselected.find(filtered => filtered === i) === undefined) {
          this.motiveLabels.push(this.translatorService.translate('motive.' + m.toLowerCase()));
        }
      }
    }
    );
    this.chart.data.labels = this.motiveLabels;
  }

  toggleMotive(motive: number): void {
    const isUnselected: number | undefined = this.unselected.find(m => m === motive);
    if (isUnselected === undefined) {
      this.unselected.push(motive);
      this.initDatasets();
    }
    else {
      this.unselected = this.unselected.filter(m => m !== motive);
      this.initDatasets();
    }
  }

  isSelected(motive: number): boolean {
    const result = this.unselected.find(m => m === motive);
    return result === undefined;
  }

  buildChart(): void {
    this.chart = new Chart('radar-canvas', {
      type: 'radar',
      data: {
        labels: this.motiveLabels
      },
      options: {
        scale: {
          angleLines: {
            display: true
          },
          ticks: {
            min: 1,
            max: 10,
            stepSize: 1
          }
        }
      }
    });
  }

  initDatasets(): void {
    const datasets: RadarDatasets[] = [];
    this.customerMotiveScore.forEach((motiveArray: MotiveScore[], customerIndex: number) => {
      const scores: number[] = [];

      const filteredMotiveArray = motiveArray.filter((m) =>
        this.unselected.find(filtered => filtered === m.motiveIndex) === undefined
      );

      filteredMotiveArray.forEach((motive: MotiveScore) => {
        scores.push(motive.score);
      });

      datasets.push({
        label: this.customerName[customerIndex],
        borderColor: this.borderColor[customerIndex],
        backgroundColor: this.backgroundColor[customerIndex],
        data: scores,
        borderWidth: 1
      });

    });
    this.computeMotiveLabels();
    this.chart.data.datasets = datasets;
    this.chart.update();
  }

}
