import {Component, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {FriendshipService} from '../../friend/state/friendship.service';
import {ChatMessagesAutomationService} from '../state/chat-messages-automation.service';
import {ChatMessagesStore, StoredContactChatMessages} from '../state/chat-messages.store';
import {Subject} from 'rxjs';
import {ChatMessagesQuery} from '../state/chat-messages.query';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  activeContactUserId: string | undefined = undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private chatMessagesAutomationService: ChatMessagesAutomationService,
              private chatMessagesStore: ChatMessagesStore,
              private chatMessagesQuery: ChatMessagesQuery,
              private friendshipService: FriendshipService) {
  }

  ngOnInit(): void {
    this.chatMessagesQuery.selectActiveContactChanges()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((activeContact: StoredContactChatMessages | undefined) =>
        this.activeContactUserId = !!activeContact ? activeContact.contactUserId : undefined
      );

    // TODO: Decouple the friendship retrieval.
    this.friendshipService.loadFriendships().pipe(take(1)).subscribe();

    this.chatMessagesStore.updateUiOpenState(true);
    this.chatMessagesAutomationService.startListeningForContactChanges();
    this.chatMessagesAutomationService.switchToFastPollingOfUnreadChatMessages();
  }

  ngOnDestroy(): void {
    this.chatMessagesAutomationService.stopListeningForContactChanges();
    this.chatMessagesAutomationService.switchToSlowPollingOfUnreadChatMessages();
    this.chatMessagesStore.updateUiOpenState(false);
  }

}
