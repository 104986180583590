/* eslint-disable @typescript-eslint/dot-notation */
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakTokenParsed} from 'keycloak-js';
import {BackendUser} from './backend-user';
import {KeycloakUser} from './keycloak-user';
import {ActiveUser} from './active-user';
import {RegistrationSource} from './registration-source';
import {Gender} from '../client/gender';
import {KeycloakUserFeatureFlag} from './keycloak-user-feature-flag';
import {Referrer} from './referrer';

@Injectable({
  providedIn: 'root'
})
export class CurrentUser {

  public featureFlags = KeycloakUserFeatureFlag;
  private _currentUser: ActiveUser;

  constructor(private keycloakService: KeycloakService) {
  }

  private _keycloakId: string;

  get keycloakId(): string {
    return this._keycloakId;
  }

  private _firstName: string;

  get firstName(): string {
    return this._firstName;
  }

  private _lastName: string;

  get lastName(): string {
    return this._lastName;
  }

  private _fullName: string;

  get fullName(): string {
    return this._fullName;
  }

  private _email: string;

  get email(): string {
    return this._email;
  }

  private _gender: Gender;

  get gender(): string {
    return this._gender;
  }

  private _locale: string;

  get locale(): string {
    return this._locale;
  }

  private _keycloakLocale: string;

  get keycloakLocale(): string {
    return this._keycloakLocale;
  }

  private _roles: string[];

  get roles(): string[] {
    return this._roles;
  }

  private _referrer: Referrer;

  get referrer(): Referrer {
    return this._referrer;
  }

  private _realm: string;

  get realm(): string {
    return this._realm;
  }

  get backendUser(): BackendUser {
    return this._currentUser.backendUser;
  }

  get keycloakUser(): KeycloakUser {
    return this._currentUser.keycloakUser;
  }

  get registrationSource(): RegistrationSource {
    return this.keycloakUser.registrationSource;
  }

  setCurrentUser(activeUser: ActiveUser): void {
    this.setKeycloakToken();
    this._currentUser = activeUser;
    this._keycloakId = activeUser.keycloakUser.id;
    this._firstName = activeUser.keycloakUser.firstName.trim();
    this._lastName = activeUser.keycloakUser.lastName.trim();
    this._fullName = this._firstName + ' ' + this._lastName; // No trimming necessary.
    this._email = activeUser.keycloakUser.email;
    this._referrer = activeUser.keycloakUser.referrer;
    // This check maps any non standard gender to the UNSPECIFIED gender, shown by an empty string.
    this._gender = Gender.STRING_LIST.includes(activeUser.keycloakUser.gender)
      ? activeUser.keycloakUser.gender
      : Gender.UNSPECIFIED;
    this._locale = activeUser.backendUser.language;

  }

  private setKeycloakToken(): void {
    const token: KeycloakTokenParsed = this.keycloakService.getKeycloakInstance().tokenParsed;
    this._keycloakLocale = token['locale'];
    this._realm = token['iss'].split('/').reverse()[0];
    this._roles = token.resource_access['ID37'].roles;
  }

  hasGender(genderName: Gender | string): boolean {
    return this._gender === genderName;
  }

  hasRole(roleName: string): boolean {
    return this._roles.indexOf(roleName) !== -1;
  }

  hasPartner(): boolean {
    return !!this.backendUser.partnerKeycloakId;
  }

  hasMaster(): boolean {
    return !!this.backendUser.masterKeycloakId;
  }

  isFeatureAllowed(feature: KeycloakUserFeatureFlag): boolean {
    return this.keycloakUser.isFeatureAllowed(feature);
  }

  isFeatureNotAllowed(feature: KeycloakUserFeatureFlag): boolean {
    return this.keycloakUser.isFeatureNotAllowed(feature);
  }

}
