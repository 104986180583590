import {ChatMessageRepresentation} from './chat-message-representation';
import {ContactChatMessages} from './contact-chat-messages';
import {ChatMessage} from './chat-message';

export class ContactChatMessagesRepresentation {

  public readonly contactKeycloakId: string;
  public readonly chatMessages: ChatMessageRepresentation[];

  constructor(contactKeycloakId: string,
              chatMessages: ChatMessageRepresentation[]) {
    this.contactKeycloakId = contactKeycloakId;
    this.chatMessages = chatMessages;
  }

  static toModelEntity(contactChatMessagesRepresentation: ContactChatMessagesRepresentation): ContactChatMessages {
    return {
      contactKeycloakId: contactChatMessagesRepresentation.contactKeycloakId,
      chatMessages: contactChatMessagesRepresentation.chatMessages
        .map((chatMessageRepresentation: ChatMessageRepresentation) =>
          ChatMessageRepresentation.toModelEntity(chatMessageRepresentation))
    };
  }

  static fromModelEntity(contactChatMessages: ContactChatMessages): ContactChatMessagesRepresentation {
    return new ContactChatMessagesRepresentation(
      contactChatMessages.contactKeycloakId,
      contactChatMessages.chatMessages
        .map((chatMessage: ChatMessage) =>
          ChatMessageRepresentation.fromModelEntity(chatMessage))
    );
  }

}
