<ng-container *ngIf="!!chatbotChat">

  <div [ngClass]="{
        'active': chatbotChat === selectedChat}" class="chat-item-row">
    <ng-container>
      <div (click)="selectChat()" class="chat-name-wrapper">
        <div class="chat-name">
          <app-chatbot-chat-edit-chat-title (newTitle)="onTitleChange($event)" [chatbotTitle]="chatbotChat.title" [isEditEnabled]="isEditEnabled"></app-chatbot-chat-edit-chat-title>
        </div>
      </div>
      <div class="dropdown-wrapper">
        <clr-dropdown>
          <button clrDropdownTrigger
                  class="id37-btn id37-btn-secondary id37-btn-transparent id37-btn-rounded-1 id37-btn-sm"
                  aria-label="Dropdown button">
            <img src="assets/icons/chatbot/ChatMore_Filled.svg" alt="more icon"/>
          </button>
          <clr-dropdown-menu *clrIfOpen [clrPosition]="isMobile ? 'bottom-right' : 'bottom-left'">
            <div class="button-container">
              <button clrDropdownItem (click)="enableEditMode()" class="" aria-label="Edit button">
                <img src="assets/icons/chatbot/ChatEditButton.svg" alt="edit icon"/>
                <span>{{__('edit')}}</span>
              </button>
              <button clrDropdownItem (click)="openDeleteForm()" class="" aria-label="Delete button">
                <img src="assets/icons/chatbot/TrashCanIcon.svg" alt="delete icon"/>
                <span>{{__('delete')}}</span>
              </button>
            </div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>


    </ng-container>
  </div>

  <id37-modal [(modalOpen)]="deleteFormOpen" [modalSize]="'small'">
    <id37-modal-title [align]="'left'" [showX]="false">{{__('deleteTitle')}}</id37-modal-title>
    <id37-modal-body>
      <span>
        {{__('deleteChat')}}
        <span class="text-bold">
          {{chatbotChat.title}}</span>?
      </span>

    </id37-modal-body>
    <id37-modal-footer [align]="'right'">
      <div class="id37-btn-row button-row">
        <button class="id37-btn id37-btn-secondary id37-btn-rounded-1" (click)="closeDeleteForm()">
          <span>{{__('cancel')}}</span>
        </button>
        <button class="id37-btn id37-btn-primary id37-btn-rounded-1" (click)="deleteChat()">
          <img class="fit-parent" src="assets/icons/chatbot/TrashCanIconWhite.svg" alt="Trashcan Icon"/>
          <span>{{__('delete')}}</span>
        </button>
      </div>
    </id37-modal-footer>
  </id37-modal>
</ng-container>


