import {
  ChangeDetectionStrategy,
  Component, HostListener,
  Input
} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {ChatbotChat} from '../state/chatbot-chat';

@Component({
  selector: 'app-chatbot-chat-messages',
  templateUrl: './chatbot-chat-messages.component.html',
  styleUrls: ['./chatbot-chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatbotChatMessagesComponent {

  @Input()
    chatbotChat: ChatbotChat;
  @Input()
    mobileSidebar: boolean;

  autoscroll: boolean = true;
  showScrollButton: boolean = false;

  constructor(private translatorService: TranslatorService) {}


  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const threshold = 30;
    const atBottom = event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - threshold;
    if (atBottom) {
      if (this.autoscroll === false) {
        if (this.chatbotChat.sending) {
          this.autoscroll = true;
        }
      }
    }
    else if (this.autoscroll === true) {
      this.autoscroll = false;
    }

    this.showScrollButton = !atBottom;
  }


  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

  scrollToBottom(el: HTMLElement): void {
    el.scrollIntoView({behavior: 'smooth'});
  }

}
