import {Component} from '@angular/core';
import {CurrentUser} from '../../../user/current-user.service';

@Component({
  selector: 'app-key-graphic-example',
  templateUrl: './key-graphic-example.component.html',
  styleUrls: ['./key-graphic-example.component.scss']
})
export class KeyGraphicExampleComponent {

  locale: string;

  constructor(private currentUser: CurrentUser) {
    this.locale = currentUser.locale;
  }

}
