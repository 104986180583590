<ng-container *ngIf="!!contact; else findContacts">
  <ng-container *ngIf="{
        contactActive: contactActive$ | async,
        lastMessage: lastMessage$ | async,
        unreadCount: amountOfUnreadMessages$ | async
      } as data">

    <div (click)="selectContact()" [ngClass]="{
        'active': data.contactActive,
        'has-unread-messages': data.unreadCount > 0
      }" class="contact">
      <app-profile-picture [userId]="contactKeycloakId"></app-profile-picture>

      <div class="contact-data">
        <ng-container *ngIf="!!data.lastMessage; else noLastMessage">
          <div class="contact-name-wrapper">
            <div class="contact-name">
              {{contactFullName}}
            </div>
            <div class="last-message-date">
              {{data.lastMessage.sent | JsDayMonth}}
            </div>
          </div>
          <div class="last-message">
            {{data.lastMessage.message}}
          </div>
        </ng-container>

        <ng-template #noLastMessage>
          <div class="contact-wrapper">
            <div class="contact-name">
              {{contactFullName}}
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="amountOfUnreadMessages$ | async as amount" class="unread-info-wrapper">
        <app-unread-info [amount]="amount" [emRight]="-1.9" [emSize]="0.6" [emTop]="-1.2"></app-unread-info>
      </div>
    </div>

  </ng-container>
</ng-container>


<ng-template #findContacts>

  <div (click)="visitNetwork()" class="contact find-contacts">
    <img alt="plus" src="assets/icons/add_onButton.svg">
    <div class="find-contacts-text">
      {{__('findContacts')}}
    </div>
  </div>

</ng-template>
