import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {FriendshipStore} from './friendship.store';
import {FriendshipQuery} from './friendship.query';
import {FriendshipService} from './friendship.service';

@Injectable({
  providedIn: 'root'
})
export class FriendshipResetService {

  constructor(private friendshipService: FriendshipService,
              private friendshipQuery: FriendshipQuery,
              private friendshipStore: FriendshipStore) {
  }

  public reloadFriendships(): void {
    this.friendshipQuery.getAll().forEach(friendship =>
      this.friendshipStore.remove(friendship.id)
    );
    this.friendshipService.loadFriendships().pipe(take(1)).subscribe();
  }

  public reloadFriendship(friendshipId: number): void {
    this.friendshipStore.remove(friendshipId);
    this.friendshipService.loadFriendship(friendshipId).pipe(take(1)).subscribe();
  }

}
