import {MotiveScore} from './motive-score';
import {Motive} from '../../report/types/motives/motive';

export class MotiveScoreRepresentation {

  public readonly motiveIndex: number;
  public readonly motiveDescriptor: string;
  public readonly score: number;

  public static toModelEntity(motiveScoreRepresentation: MotiveScoreRepresentation): MotiveScore {
    return {
      motiveIndex: motiveScoreRepresentation.motiveIndex,
      motive: Motive.fromIndex(motiveScoreRepresentation.motiveIndex),
      score: motiveScoreRepresentation.score
    };
  }

}
