import {PersonalDataBeforeTest} from './personal-data-before-test';
import {Gender} from '../../client/gender';

export class PersonalDataBeforeTestRepresentation {

  public readonly residence: string;
  public readonly origin: string;
  public readonly gender: string | null;
  public readonly dateOfBirth: string;

  constructor(residence: string, origin: string, gender: string | null, dateOfBirth: string) {
    this.residence = residence;
    this.origin = origin;
    this.gender = gender;
    this.dateOfBirth = dateOfBirth;
  }

  static fromModelEntity(personalDataBeforeTest: PersonalDataBeforeTest): PersonalDataBeforeTestRepresentation {
    return new PersonalDataBeforeTestRepresentation(
      personalDataBeforeTest.residence,
      personalDataBeforeTest.origin,
      personalDataBeforeTest.gender,
      personalDataBeforeTest.dateOfBirth
    );
  }

  static toModelEntity(personalDataBeforeTestRepresentation: PersonalDataBeforeTestRepresentation): PersonalDataBeforeTest {
    return {
      residence: personalDataBeforeTestRepresentation.residence,
      origin: personalDataBeforeTestRepresentation.origin,
      gender: Gender.fromName(personalDataBeforeTestRepresentation.gender),
      dateOfBirth: personalDataBeforeTestRepresentation.dateOfBirth
    };
  }

}
