<id37-modal #updateAvailable (afterClose)="reloadApplication()">
  <id37-modal-title>
    {{__('updateAvailable.title')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('updateAvailable.body')}}
  </id37-modal-body>
  <id37-modal-footer>
    <button type="button" class="id37-btn-primary"
            (click)="updateAvailable.hide()">
      {{__('updateAvailable.action')}}
    </button>
  </id37-modal-footer>
</id37-modal>

<id37-modal #unrecoverable (afterClose)="reloadApplication()">
  <id37-modal-title>
    {{__('unrecoverable.title')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('unrecoverable.body')}}
  </id37-modal-body>
  <id37-modal-footer>
    <button type="button" class="id37-btn-primary"
            (click)="unrecoverable.hide()">
      {{__('unrecoverable.action')}}
    </button>
  </id37-modal-footer>
</id37-modal>

<ng-container *ngIf="updateSuccessful">
  <div style="position: fixed; bottom: 1em; left: 1em; font-size: 1.2em;">
    <div class="alert success">
      {{__('updateSuccessful')}}
    </div>
  </div>
</ng-container>
