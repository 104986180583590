import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ChatMessagesQuery} from '../state/chat-messages.query';
import {from, GroupedObservable, Observable} from 'rxjs';
import {FriendshipChatMessages} from '../state/friendship-chat-messages';
import {TranslatorService} from '../../translation/translator.service';
import {ChatMessage, ChatMessageFunctions} from '../state/chat-message';
import {Friendship} from '../../friend/state/friendship';
import {ChatMessagesService} from '../state/chat-messages.service';
import {groupBy, map, mergeMap, take, tap, toArray} from 'rxjs/operators';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';
import {CurrentUser} from '../../user/current-user.service';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessagesComponent implements OnInit {

  activeContactMessagesByDay$: Observable<{ friendship: Friendship; chatMessagesByDay: Observable<ChatMessage[][]> }>;

  @ViewChild('lastMessage', {read: ElementRef})
    lastMessage: ElementRef;

  constructor(private currentUser: CurrentUser,
              private chatMessagesQuery: ChatMessagesQuery,
              private chatMessagesService: ChatMessagesService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.activeContactMessagesByDay$ = this.chatMessagesQuery.selectActiveContactMessages()
      .pipe(
        tap(_ => {
          setTimeout(() => {
            if (!!this.lastMessage) {
              this.lastMessage.nativeElement.scrollIntoView();
            }
          }, 0);
        }),
        map((data: FriendshipChatMessages | undefined) => !!data ? {
          friendship: data.friendship,
          chatMessagesByDay: from(data.chatMessages)
            .pipe(
              groupBy(
                (message: ChatMessage) => message.sent.toLocaleDateString(),
                (message: ChatMessage) => message
              ),
              mergeMap((group: GroupedObservable<string, ChatMessage>) => group.pipe(toArray())),
              toArray()
            )
        } : undefined)
      );
  }

  update(friendship: Friendship, oldMessage: ChatMessage, newContent: string): void {
    const contactUserId = FriendshipFunctions.getFriendUserId(friendship, this.currentUser.keycloakId);
    const updatedChatMessage: ChatMessage = ChatMessageFunctions.createUpdated(oldMessage, newContent);
    this.chatMessagesService.storeChatMessage(contactUserId, updatedChatMessage);
    this.chatMessagesService.updateChatMessage(contactUserId, oldMessage.id, newContent)
      .pipe(take(1))
      .subscribe(
        () => {
          // Updated response from server already stored by 'updateChatMessage'.
        },
        () => {
          // Restore old message on error.
          this.chatMessagesService.storeChatMessage(contactUserId, oldMessage);
        }
      );
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
