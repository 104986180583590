import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {CurrentUser} from '../../../user/current-user.service';
import {TranslatorService} from '../../../translation/translator.service';
import {MasterService} from '../../master.service';
import {Customer} from '../../my-customers/types/customer';
import {Group} from '../../my-customers/types/group-type';
import {CustomerlistComponent, SelectionEvent} from '../../../shared/customerlist/customerlist.component';
import {Test} from '../../../test/state/test';
import {TestQuery} from '../../../test/state/test.query';
import {TestFunctions} from '../../../test/state/test.functions';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit, OnDestroy {
  @ViewChild(CustomerlistComponent, {static: true})
    customerListComponent: CustomerlistComponent;
  selectedUser1: Customer | null = null;
  selectedUser2: Customer | null = null;
  customers$: Observable<Customer[]>;
  customers: Customer[] = [];
  customersView: Customer[] = [];
  groups$: Observable<Group[]>;
  metaSearch = '';
  len = 0;
  page = 1;
  lastPage = 1;
  pageSize = 6;
  name: string;
  userId: string;
  masterHasTest = false;
  masterFirstCompletedTest: Test | null = null;
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder,
              private masterService: MasterService,
              private currentUser: CurrentUser,
              private testQuery: TestQuery,
              private testFunctions: TestFunctions,
              private translatorService: TranslatorService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.refreshData();
    this.testQuery.selectTestsForCurrentUser()
      .pipe(tap((tests: Test[]) => {
        const completedTests = tests.filter(test => this.testFunctions.isCompleted(test));
        const firstCompletedTest = completedTests.shift();
        if (!!firstCompletedTest) {
          this.masterHasTest = true;
          this.masterFirstCompletedTest = firstCompletedTest;
        }
      })).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  refreshData(): void {
    this.getMasterName();
    this.getUserId();
    this.loadCustomers();
    this.loadGroups();
  }

  getMasterName(): void {
    this.name = this.currentUser.fullName;
  }

  getUserId(): void {
    this.userId = this.currentUser.keycloakId;
  }

  loadCustomers(): void {
    this.customers$ = this.masterService.loadCustomers()
      .pipe(
        takeUntil(this.unsubscribe$),
        map((customers: Customer[]) => customers.filter(customer => customer.hasFinishedTests())),
        map((data: Customer[]) => data.sort((clientA, clientB) => clientB.creationDate.getTime() - clientA.creationDate.getTime()))
      );

    this.customers$
      .subscribe((data: Customer[]) => {
        this.customers = data;

        if (this.customers && this.customers.length > 0) {
          this.customersView = this.customers;
          this.len = this.customers.length;
          this.lastPage = Math.ceil(this.len / this.pageSize);
        }
      });
  }

  loadGroups(): void {
    this.groups$ = this.masterService.loadGroups();
  }

  // TODO: Allow selection of specific tests.
  compare(): void {
    this.router.navigateByUrl('/toolbox/comparer', {
      state: {
        user1Id: this.selectedUser1.keycloakId,
        user1TestId: this.selectedUser1.tests[0].testId,
        user1Name: this.selectedUser1.firstName + ' ' + this.selectedUser1.lastName,
        user2Id: this.selectedUser2.keycloakId,
        user2TestId: this.selectedUser2.tests[0].testId,
        user2Name: this.selectedUser2.firstName + ' ' + this.selectedUser2.lastName
      }
    });
  }

  selectCustomer(customer: Customer): void {
    if (this.selectedUser1 == null) {
      this.selectedUser1 = customer;
      return;
    }
    if (this.selectedUser2 == null) {
      this.selectedUser2 = customer;
      return;
    }
  }

  unselectCustomer(customer: Customer): void {
    if (this.selectedUser1 && this.selectedUser1.keycloakId === customer.keycloakId) {
      this.selectedUser1 = null;
    }
    else {
      this.selectedUser2 = null;
    }
    this.customerListComponent.unselectCustomer(customer);
  }

  selectMaster(): void {
    this.selectedUser2 = new Customer(
      this.userId, this.name, '', '', '', [this.masterFirstCompletedTest], undefined, undefined);
  }

  bothSelected(): boolean {
    return this.selectedUser1 != null && this.selectedUser2 != null;
  }

  isSelected(customer: Customer): boolean {
    return (this.selectedUser1 != null && this.selectedUser1.keycloakId === customer.keycloakId) ||
      (this.selectedUser2 != null && this.selectedUser2.keycloakId === customer.keycloakId);
  }

  onSelection(event: SelectionEvent): void {
    if (event.selected === true) {
      this.selectCustomer(event.customer);
    }
  }

  __(key: string): string {
    return this.translatorService.translate('master.my-customers.' + key);
  }

}
