<h3 [innerHTML]="profileApplicationText.subheading"></h3>

<!-- If the placeholder was not replaced... -->
<div *ngIf="profileApplicationText.mainText1" [innerHTML]="profileApplicationText.mainText1"
     class="id37-text2 text-light"></div>

<!-- If the placeholder was replaced... -->
<div *ngIf="!profileApplicationText.mainText1" class="id37-text2 text-light">
  <span [innerHTML]="profileApplicationText.mainText1PartA"></span>
  <span>
    <span>(</span>
    <a (click)="shouldScrollToMotivesDescriptionText.emit()"
       class="id37-link"
    >{{__('toMotivesDescriptionLink')}}</a>
    <span>)</span>
  </span>
  <span [innerHTML]="profileApplicationText.mainText1PartB"></span>
</div>

<div *ngIf="!masterId" [innerHTML]="profileApplicationText.selectMasterText" class="id37-text2 text-light"></div>

<div [innerHTML]="profileApplicationText.mainText2" class="id37-text2 text-light"></div>

<app-print-button (buttonClicked)="shouldPrintReportEmitter.emit()"
                  [showFirefoxWarning]="true"
                  [text]="profileApplicationText.printButtonText"></app-print-button>
