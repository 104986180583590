import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamComponent} from './team.component';
import {TeamBodyComponent} from './team-body/team-body.component';
import {TeamNavigationComponent} from './team-navigation/team-navigation.component';
import {TeamModalComponent} from './team-modal/team-modal.component';
import {ModalModule} from '../modal/modal.module';
import {TeamUsersSelectionComponent} from './team-modal/team-users-selection/team-users-selection.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {Id37Module} from '../id37/id37.module';
import {DirectivesModule} from '../directives/directives.module';
import {TeamGraphicModule} from '../graphics/team-graphic/team-graphic.module';
import {CollapsibleModule} from '../collapsible/collapsible.module';
import {UserModule} from '../user/user.module';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';


@NgModule({
  declarations: [
    TeamComponent,
    TeamBodyComponent,
    TeamNavigationComponent,
    TeamModalComponent,
    TeamUsersSelectionComponent
  ],
  imports: [
    CommonModule,
    ModalModule,
    ReactiveFormsModule,
    ScrollingModule,
    FormsModule,
    Id37Module,
    DirectivesModule,
    TeamGraphicModule,
    CollapsibleModule,
    UserModule,
    ProfilePictureModule
  ],
  exports: [
    TeamComponent
  ]
})
export class TeamModule {
}
