import {TeamState, TeamStore} from './team.store';
import {QueryConfig, QueryEntity} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Team} from './team';
import {map} from 'rxjs/operators';


@Injectable({providedIn: 'root'})
@QueryConfig({sortBy: 'name'})
export class TeamQuery extends QueryEntity<TeamState> {

  constructor(protected store: TeamStore) {
    super(store);
  }

  selectSelectedTeam(): Observable<Team> {
    return this.select('selectedTeam');
  }

  selectTeamById(teamId: string): Observable<Team | undefined> {
    return this.selectEntity(teamId);
  }

  selectNewTeamAfterDelete(teamId: string): Team | undefined {
    return this.getAll()
      .filter(team => team.teamId !== teamId)
      .shift();
  }

  hasTeams(): Observable<boolean> {
    return this.selectAll().pipe(
      map(teams => teams.length !== 0)
    );
  }

}
