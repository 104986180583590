import {TestOriginImportRepresentation} from './test-origin-import-representation';
import {AbstractTestOrigin} from './abstract-test-origin';

export class TestOriginImport extends AbstractTestOrigin {

  private readonly _importerId: string;

  constructor(importerId: string) {
    super();
    this._importerId = importerId;
  }

  get importerId(): string {
    return this._importerId;
  }

  static buildFrom(testOriginImportRepresentation: TestOriginImportRepresentation): TestOriginImport {
    return new TestOriginImport(
      testOriginImportRepresentation.importerId as string
    );
  }

  isBuy(): boolean {
    return false;
  }

  isImport(): boolean {
    return true;
  }

  isInvitation(): boolean {
    return false;
  }

  isUnknown(): boolean {
    return false;
  }

}
