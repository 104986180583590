import {TestOriginInvitationRepresentation} from './test-origin-invitation-representation';
import {AbstractTestOrigin} from './abstract-test-origin';

export class TestOriginInvitation extends AbstractTestOrigin {

  private readonly _inviterId: string;

  constructor(inviterId: string) {
    super();
    this._inviterId = inviterId;
  }

  get inviterId(): string {
    return this._inviterId;
  }

  static buildFrom(testOriginInvitationRepresentation: TestOriginInvitationRepresentation): TestOriginInvitation {
    return new TestOriginInvitation(
      testOriginInvitationRepresentation.inviterId as string
    );
  }

  isBuy(): boolean {
    return false;
  }

  isImport(): boolean {
    return false;
  }

  isInvitation(): boolean {
    return true;
  }

  isUnknown(): boolean {
    return false;
  }

}
