<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12" style="max-width: 1200px;">
    <div class="graphic">
      <div *ngIf="!motiveScores">
        <app-loading-indicator></app-loading-indicator>
      </div>
      <div *ngIf="motiveScores">
        <app-key-graphic-print-layout [results]="motiveScores"
                                      [name]="clientName"
                                      (shouldBePrinted)="printKeyGraphic()"></app-key-graphic-print-layout>
      </div>
    </div>
  </div>
</div>
