import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MotiveScore} from '../../../../test/state/motive-score';

export interface SignpostPosition {
  top: number;
  left: number;
  orientation: 'left' | 'right';
}

export interface DotClickEvent {
  motiveScore: MotiveScore;
  position: SignpostPosition;
}

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-motive-score]',
  templateUrl: './key-graphic-motive-score.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicMotiveScoreComponent implements OnInit {

  @Input()
    motiveScore: MotiveScore;
  @Input()
    class: string;
  @Input()
    colorIndex;
  @Input()
    isCompare: boolean;
  @Output()
    dotClick: EventEmitter<DotClickEvent> = new EventEmitter<DotClickEvent>();
  y: number;
  x: number;
  circleClass: string;
  private readonly yBase = 75;
  private readonly xBase = -5.5;
  private readonly gridStep = 80;

  ngOnInit(): void {
    this.y = (this.motiveScore.motiveIndex * this.gridStep) + this.yBase;
    this.x = ((this.motiveScore.score - 1) * this.gridStep) + this.xBase;
    this.circleClass = this.computeCircleClass(this.motiveScore.score);
  }

  @HostListener('click', ['$event']) onClick($event) {
    this.dotClick.emit({
      motiveScore: this.motiveScore,
      position: this.calculatePosition($event.target)
    });
  }

  private calculatePosition(el): SignpostPosition {
    const rect = el.getBoundingClientRect(); // Not giving back true position in Firefox !!!
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop + rect.height / 2,
      left: rect.left + scrollLeft + rect.width / 2,
      orientation: (rect.left > window.innerWidth / 2) ? 'left' : 'right'
    };
  }

  private computeCircleClass(x: number): string {
    if (this.isCompare) {
      return '#big-circle_' + this.colorIndex;
    }
    else {
      return x < 4 || x > 7 ? '#big-circle_' + this.colorIndex : '#small-circle';
    }
  }

}
