import {ChatbotMessagePair} from './chatbot-message-pair';
import {ChatbotChatUser} from './chatbot-chat-user';
import {
  ChatbotChatCreateRepresentation,
  ChatbotChatRepresentation
} from './chatbot-chat-representation';


export class ChatbotChatBase {
  private readonly _user_id: string;


  get user_id(): string {
    return this._user_id;
  }

  constructor(user_id: string) {
    this._user_id = user_id;
  }
}

export class ChatbotChatCreate extends ChatbotChatBase {
  private readonly _chatbot_chat_user: ChatbotChatUser;

  constructor(user_id: string) {
    super(user_id);
  }

  get chatbot_chat_user(): ChatbotChatUser {
    return this._chatbot_chat_user;
  }

  static buildFrom(
    chatbotChatCreateRepresentation: ChatbotChatCreateRepresentation
  ): ChatbotChatCreate {
    return new ChatbotChatCreate(
      chatbotChatCreateRepresentation.user_id
    );
  }
}


export class ChatbotChat extends ChatbotChatBase {
  private readonly _chat_id: number;
  private _chat_rating: number | undefined;
  private _messages: ChatbotMessagePair[];
  private _title: string = 'New Chat';
  private _sending: boolean = false;
  constructor(chat_id: number, user_id: string, chat_rating: number, message_pairs: ChatbotMessagePair[], title: string) {
    super(user_id);
    this._chat_id = chat_id;
    this._chat_rating = chat_rating;
    this._messages = message_pairs === undefined ? [] : this._messages = message_pairs;
    this._title = title;
  }

  get sending(): boolean {
    return this._sending;
  }

  set sending( state) {
    this._sending = state;
  }

  get chat_id(): number {
    return this._chat_id;
  }

  get chat_rating(): number {
    return this._chat_rating;
  }

  get messages(): ChatbotMessagePair[] {
    return this._messages;
  }

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
  }

  static buildFrom(
    chatbotChatRepresentation: ChatbotChatRepresentation
  ): ChatbotChat {
    return new ChatbotChat(
      Number(chatbotChatRepresentation.chat_id),
      chatbotChatRepresentation.user_id,
      Number(chatbotChatRepresentation.chat_rating),
      chatbotChatRepresentation.messages,
      chatbotChatRepresentation.title
    );
  }

  public addMessagePair(messagePair: ChatbotMessagePair): void {
    this._messages.push(messagePair);
  }

}
