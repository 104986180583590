export enum NotificationType {

  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  TEST_INVITATION = 'TEST_INVITATION',
  TEST_REVOKED = 'TEST_REVOKED',
  TEST_FINISHED = 'TEST_FINISHED',
  TEST_EXPIRING_MASTER = 'TEST_EXPIRING_MASTER',
  TEST_EXPIRING_CLIENT = 'TEST_EXPIRING_CLIENT',
  TEST_EXPIRED_OF_CLIENT = 'TEST_EXPIRED_OF_CLIENT',
  TEST_ACCESS_GRANTED = 'TEST_ACCESS_GRANTED',
  FRIENDSHIP_INVITATION = 'FRIENDSHIP_INVITATION',
  FRIENDSHIP_ACCEPTED = 'FRIENDSHIP_ACCEPTED',
  SYSTEM_TEST = 'SYSTEM_TEST',
  RAW = 'RAW',
  LOCALIZED_RAW = 'LOCALIZED_RAW',
  SUPERLIKE_SENT = 'SUPERLIKE_SENT',
  TESTSHARE_CHANGED = 'TESTSHARE_CHANGED'
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NotificationType {
  export const LIST: NotificationType[] = [
    NotificationType.ACCOUNT_CREATED,
    NotificationType.TEST_INVITATION,
    NotificationType.TEST_REVOKED,
    NotificationType.TEST_FINISHED,
    NotificationType.TEST_ACCESS_GRANTED,
    NotificationType.TEST_EXPIRING_MASTER,
    NotificationType.TEST_EXPIRING_CLIENT,
    NotificationType.TEST_EXPIRED_OF_CLIENT,
    NotificationType.FRIENDSHIP_INVITATION,
    NotificationType.FRIENDSHIP_ACCEPTED,
    NotificationType.SYSTEM_TEST,
    NotificationType.RAW,
    NotificationType.LOCALIZED_RAW,
    NotificationType.SUPERLIKE_SENT,
    NotificationType.TESTSHARE_CHANGED
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): NotificationType => {
    name = !!name ? name : '';
    const found = NotificationType.LIST.find((notificationType: string) =>
      notificationType.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a notificationType with an unknown name: "' + name + '"');
      throw new Error('Notification type not found.');
    }
    return found;
  };

}
