<div class="content-container">
  <div class="parent">
    <div class="error-smiley">
      <img src="assets/icons/error-smiley.svg" alt="sad smiley"/>
    </div>
    <div class="error-text">
      <h1>{{ __('header') }}</h1>
      <h2 [innerHTML]="__('errorText')" >
    </h2>
  </div>
</div>
</div>
