import {InvoiceRepresentation} from './invoice-representation';

export class Invoice {

  private readonly _invoiceNr: number;
  private readonly _creationDate: Date;

  constructor(invoiceNr: number,
              creationDate: string) {
    this._invoiceNr = invoiceNr;
    this._creationDate = new Date(creationDate);
  }

  get invoiceNr(): number {
    return this._invoiceNr;
  }

  get creationDate(): Date {
    return this._creationDate;
  }

  static buildFrom(invoiceRepresentation: InvoiceRepresentation): Invoice {
    return new Invoice(
      invoiceRepresentation.invoiceNr,
      invoiceRepresentation.creationDate
    );
  }

}
