import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {takeUntil} from 'rxjs/operators';
import {CurrentUser} from '../../user/current-user.service';
import {Friendship} from '../state/friendship';
import {FriendshipQuery} from '../state/friendship.query';
import {Subject} from 'rxjs';
import {BasicUser} from '../../user/basic-user';
import {Id37Router} from '../../id37-router';
import {EmailSettingsService} from '../../account/email-settings/state/email-settings.service';

@Component({
  selector: 'app-friend-search-output',
  templateUrl: './friend-search-output.component.html',
  styleUrls: ['./friend-search-output.component.scss']
})
export class FriendSearchOutputComponent implements OnInit, OnDestroy {

  @Input()
  set searchResult(value: BasicUser[] | undefined) {
    this._searchResult = value;
  }

  get searchResult(): BasicUser[] | undefined {
    return this._searchResult;
  }

  private _searchResult: BasicUser[] | undefined;

  @Input()
  set searchFriendsResult(value: Friendship[] | undefined) {
    this._searchFriendsResult = value;
  }

  get searchFriendsResult(): Friendship[] | undefined {
    return this._searchFriendsResult;
  }

  private _searchFriendsResult: Friendship[] | undefined;

  @Input()
  set isLoading(value: { [key: string]: boolean }) {
    this._isLoading = value;
  }

  get isLoading(): { [key: string]: boolean } {
    return this._isLoading;
  }

  private _isLoading: { [key: string]: boolean };

  @Output()
    createFriendship: EventEmitter<BasicUser> = new EventEmitter<BasicUser>();
  @Output()
    resendFriendshipRequest: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    acceptFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    declineFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    deleteFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    updateTestShare: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    recommend: EventEmitter<void> = new EventEmitter<void>();

  currentUserId: string;
  friendshipsList: Friendship[];
  networkingState: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private currentUser: CurrentUser,
              private friendshipQuery: FriendshipQuery,
              private translatorService: TranslatorService,
              private emailSettingsService: EmailSettingsService,
              public id37Router: Id37Router
  ) {
    this.currentUserId = this.currentUser.keycloakId;
  }

  ngOnInit() {
    this.friendshipQuery.selectFriendships()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((friendships: Friendship[]) => {
        this.friendshipsList = friendships;
      });
    this.emailSettingsService.getEmailSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(emailSettings => {
        this.networkingState = emailSettings.informAboutNetworking;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFriendshipWith(userBId: string): Friendship | undefined {
    for (const friendship of this.friendshipsList) {
      if ((friendship.userA.id === this.currentUserId && friendship.userB.id === userBId)
        || (friendship.userA.id === userBId && friendship.userB.id === this.currentUserId)) {
        return friendship;
      }
    }
    return undefined;
  }

  __(key: string): string {
    return this.translatorService.translate('friendSearch.' + key);
  }
}
