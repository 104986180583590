import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {DiscountCodeApplicationService} from '../discountcode/discount-code-application-service';
import {SimpleDiscountCode} from '../discountcode/simple-discount-code';
import {Cart} from '../cart/cart';
import {DiscountCodeService} from '../discountcode/discount-code.service';
import {DiscountCode} from '../discountcode/discount-code';
import {CurrentUser} from '../../user/current-user.service';
import {Referrer} from '../../user/referrer';
import {Product} from '../../product/product';

@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss']
})
export class OrderOverviewComponent implements OnInit {
  @Output()
    back: EventEmitter<void> = new EventEmitter<void>();

  @Output()
    continue: EventEmitter<void> = new EventEmitter<void>();

  @Output()
    completeOrder: EventEmitter<void> = new EventEmitter<void>();

  refreshCart: EventEmitter<void> = new EventEmitter<void>();

  cartOverviewShown: boolean = false;

  isWW: boolean = false;

  isID37TestPurchase: boolean = true;

  discountCodeString: string = '';
  activeDiscountCode: SimpleDiscountCode | null = null;
  completeActiveDiscountCode: DiscountCode | null = null;

  earlyCompletion: boolean = false;
  ongoingRequest: boolean = false;

  displayDiscountCodeSuccessfullyApplied: boolean = false;
  displayDiscountCodeNotSuccessfullyApplied: boolean = false;

  digitalProductInfoConfirmed: boolean = false;

  constructor(
    private currentUser: CurrentUser,
    private discountCodeApplicationService: DiscountCodeApplicationService,
    private translatorService: TranslatorService,
    private discountCodeService: DiscountCodeService
  ) {
  }

  ngOnInit(): void {
    this.currentUser.referrer === Referrer.WW ? this.isWW = true : this.isWW = false;
    this.discountCodeService
      .getDiscountCodeListFromUser()
      .subscribe((codes) => {
        const optimal: DiscountCode | null = this.discountCodeService.findOptimalDiscountCode(
          codes
        );
        if (optimal !== null) {
          this.setDiscountCode(optimal);
        }
      });
  }

  setDiscountCode(discountCode: DiscountCode): void {
    this.discountCodeString = discountCode.code;
    this.completeActiveDiscountCode = discountCode;
    this.applyDiscountCode();
  }

  applyDiscountCode(): void {
    // this.earlyCompletion = false;
    this.ongoingRequest = true;
    this.discountCodeApplicationService
      .apply(this.discountCodeString)
      .subscribe(
        (simpleDiscountCode: SimpleDiscountCode) => {
          console.log(
            'Successfully applied discount code: ',
            simpleDiscountCode
          );
          this.activeDiscountCode = simpleDiscountCode;
          this.checkDiscountCode(simpleDiscountCode);
          this.refreshCart.emit();
          this.ongoingRequest = false;
          this.displayDiscountCodeSuccessfullyApplied = true;
          setTimeout(
            () => (this.displayDiscountCodeSuccessfullyApplied = false),
            3000
          );
        },
        () => {
          console.log('Unable to apply discount code:', this.discountCodeString);
          this.ongoingRequest = false;
          this.displayDiscountCodeNotSuccessfullyApplied = true;
          setTimeout(
            () => (this.displayDiscountCodeNotSuccessfullyApplied = false),
            3000
          );
        }
      );
  }

  completeOrderNow(): void {
    this.ongoingRequest = true;
    this.completeOrder.emit();
  }

  setCartOverviewShown(cart: Cart): void {
    if (cart === null) {
      this.cartOverviewShown = false;
      this.activeDiscountCode = null;
    }
    else {
      this.cartOverviewShown = true;
      if (typeof cart.discountCode !== typeof undefined) {
        this.activeDiscountCode = cart.discountCode;
        this.checkDiscountCode(cart.discountCode);
      }
      if (cart.containsProduct(Product.ID37_CHATBOT)){
        this.isID37TestPurchase = false;
      }
    }
  }

  emitBack(): void {
    this.back.emit();
  }

  emitContinue(): void {
    this.continue.emit();
  }

  __(key: string): string {
    return this.translatorService.translate('order.overview.' + key);
  }

  private checkDiscountCode(simpleDiscountCode: SimpleDiscountCode): void {
    this.earlyCompletion = simpleDiscountCode.discountAsNumber === 1;
  }
}
