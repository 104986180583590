import {NgModule} from '@angular/core';
import {CommonModule, JsonPipe} from '@angular/common';
import {LogoComponent} from './logo/logo.component';
import {RouterModule} from '@angular/router';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {CenteredLoadingIndicatorComponent} from './centered-loading-indicator/centered-loading-indicator.component';
import {PrintButtonComponent} from './print-button/print-button.component';
import {ProgressBoxComponent} from './progress-box/progress-box.component';
import {ToggleSwitchComponent} from './toggle-switch/toggle-switch.component';
import {UnreadInfoComponent} from './unread-info/unread-info.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {DropdownComponent} from './dropdown/dropdown.component';
import {DirectivesModule} from '../directives/directives.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {WebappTableComponent} from './table/webapp-table/webapp-table.component';
import {ClrDatagridModule} from '@clr/angular';
import {DateTimeModule} from '../datetime/datetime.module';
import {MobileTableComponent} from './table/mobile-table/mobile-table.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule,
    ScrollingModule,
    ClrDatagridModule,
    DateTimeModule
  ],
  declarations: [
    LogoComponent,
    LoadingIndicatorComponent,
    CenteredLoadingIndicatorComponent,
    PrintButtonComponent,
    ProgressBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    UnreadInfoComponent,
    DropdownComponent,
    WebappTableComponent,
    MobileTableComponent
  ],
  exports: [
    LogoComponent,
    LoadingIndicatorComponent,
    CenteredLoadingIndicatorComponent,
    PrintButtonComponent,
    ProgressBoxComponent,
    ToggleSwitchComponent,
    CheckboxComponent,
    UnreadInfoComponent,
    DropdownComponent,
    WebappTableComponent,
    MobileTableComponent
  ]
})
export class Id37Module {
}
