export abstract class AbstractTestOrigin {

  abstract isBuy(): boolean;

  abstract isInvitation(): boolean;

  abstract isImport(): boolean;

  abstract isUnknown(): boolean;

}
