import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {MediaType} from './media-type';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private http: HttpClient) {
  }

  public downloadFile(atResourcePath: string, asMediaType: MediaType, withDefaultFilename: string, body?: any): void {
    this.getFile(atResourcePath, body)
      .subscribe(response => this.saveToFileSystemBlob(response, asMediaType, withDefaultFilename));
  }

  public getFile(path: string, body?: unknown): Observable<HttpResponse<ArrayBuffer>> {
    const options: any = {
      observe: 'response',
      responseType: 'arraybuffer'
    };
    if (!!body) {
      options.headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return this.http.post<any>(path, body, options) as any;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.http.get(path, options) as any;
  }

  public saveToFileSystemBlob(response: HttpResponse<ArrayBuffer>, mediaType: MediaType, defaultFilename: string) {
    const blob = new Blob([response.body], {
      type: mediaType
    });
    const filename = this.extractFilename(response, defaultFilename);
    saveAs(blob, filename);
  }

  // TODO: fix error
  private extractFilename(response: HttpResponse<ArrayBuffer>, defaultFilename: string): string {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (!contentDisposition) {
      console.error('Could not extract Content-Disposition from response headers. Using the default filename.');
      return defaultFilename;
    }
    let filename = defaultFilename;
    const filenameParts = contentDisposition.split(';')
      .map(part => part.trim())
      .filter(part => part.startsWith('filename'));
    const plainIndex = filenameParts.findIndex(part => part.startsWith('filename="'));
    if (plainIndex !== -1) {
      filename = filenameParts[plainIndex].split('=', 2)[1]
        .replace(/^"/, '')
        .replace(/"$/, '');
    }
    const utf8Index = filenameParts.findIndex(part => part.startsWith('filename*=UTF-8\'\''));
    if (utf8Index !== -1) {
      filename = decodeURI(filenameParts[utf8Index]
        .split('=', 2)[1]
        .replace(/^UTF-8''/, ''));
    }
    return filename;
  }

}
