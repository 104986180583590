<ng-container *ngIf="calendlyBooked$ === undefined || (calendlyBooked$ | async); else showCalendly">
  <div *ngIf="showCalendlyBookedInfo" style="margin-bottom: 1em">
    <div class="calendly-reminder id37-text3">
      <img src="assets/icons/ID37_Analyse_Support_100x100.svg" alt="smiley with headset">
      <div class="text-bold">Vielen Dank!</div>
      <div style="margin-bottom: 0.5em">Du hast Dein ID37 Analysegespräch gebucht.</div>
      <div>Terminänderungen erledigst Du über den Calendly-Link in Deiner Terminbestätigungs-E-Mail.</div>
    </div>
  </div>
</ng-container>

<ng-template #showCalendly>
  <div *ngIf="!!tests && tests.length > 0 && tests[0].accessGranted;" class="ww-wrapper">
    <div *ngIf="hasAffiliate" class="dashboard-btn-wrapper">
      <button class="dashboard-btn" (click)="openCoachCalendly()">
        <app-profile-picture [userId]="affiliateID"></app-profile-picture>
        <span class="icon-text">{{__('coachCalendly_A')}} {{coachName}} {{__('coachCalendly_B')}}</span>
      </button>
    </div>
    <div class="dashboard-btn-wrapper">
      <button class="dashboard-btn" (click)="openCalendly()">
        <img alt="calendar" class="icon-svg"
             src="assets/icons/ID37_Calendar_80_88.svg"/>
        <span class="icon-text">{{__('defaultCalendly')}}</span>
      </button>
    </div>
  </div>
</ng-template>
