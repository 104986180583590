import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ChartData} from '../master/toolbox/teamanalysis/teamanalysis.component';
import {MotiveScore, MotiveScoreFunctions, MotiveScores} from '../test/state/motive-score';
import {RoutingService} from '../routing/routing.service';

interface ButtonChartOptions {
  filter: 'scatter' | 'bubble' | 'polar' | 'radar';
  displayName: string;
}

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {


  customerName: string[] = [];
  customerMotiveScore: MotiveScore[][];
  customerMotiveScores: MotiveScores[] = [];
  normalizedMotiveScores: MotiveScores[];
  minimumNumberOfMotives: number;

  button: ButtonChartOptions[] = [];
  variation: 'scatter' | 'bubble' | 'polar' | 'radar';

  constructor(
    private router: Router,
    private routingService: RoutingService
  ) {
  }

  ngOnInit(): void {
    if (!!window.history.state.chartData) {
      const customers: ChartData[] = window.history.state.chartData;
      customers.forEach((chartData: ChartData) => {
        this.customerName.push(chartData.name);
        this.customerMotiveScores.push(chartData.motiveScore);
      });
      this.computeMotiveScoreArray();
      this.computeButtons();
    }
    else {
      this.router.navigate(['/teamanalysis']);
    }
  }

  computeMotiveScoreArray(): void {
    this.minimumNumberOfMotives = MotiveScoreFunctions.calculateMaximalNumberOfCommonMotives(this.customerMotiveScores);
    this.normalizedMotiveScores = this.customerMotiveScores.map(motiveScores =>
      MotiveScoreFunctions.normalizeMotiveScores(motiveScores, this.minimumNumberOfMotives));
    this.customerMotiveScore = MotiveScoreFunctions.convertIntoMotiveScoreArray(this.normalizedMotiveScores);
  }

  computeButtons(): void {
    this.button.push({filter: 'scatter', displayName: 'Scatter'});
    this.button.push({filter: 'bubble', displayName: 'Bubble'});
    this.button.push({filter: 'polar', displayName: 'Polar'});
    this.button.push({filter: 'radar', displayName: 'Radar'});
  }

  onClick(option: 'scatter' | 'bubble' | 'polar' | 'radar'): void {
    this.variation = option;
  }

  close(): void {
    this.routingService.routeBack();
  }
}
