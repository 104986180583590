import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import ForceGraph3D, {ForceGraph3DInstance} from '3d-force-graph';
import SpriteText from 'three-spritetext';
import {Vector3} from 'three';
import {FriendshipService} from '../../friend/state/friendship.service';
import {first, takeUntil} from 'rxjs/operators';
import {FriendshipGraph} from '../../friend/state/friendship-graph';
import {combineLatest, Subject} from 'rxjs';
import {EventService} from '../../shared/event.service';

@Component({
  selector: 'app-friendship-graph',
  templateUrl: './friendship-graph.component.html',
  styleUrls: ['./friendship-graph.component.scss']
})
export class FriendshipGraphComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('graphContainer')
  graphContainer: ElementRef<HTMLElement>;
  graph: ForceGraph3DInstance;
  backgroundColor: string = '#444444';
  nodeRelSize: number = 4;
  showTextNodes: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private viewInitialized$: Subject<boolean> = new Subject<boolean>();
  private dataInitialized$: Subject<boolean> = new Subject<boolean>();
  private friendshipGraph: FriendshipGraph;
  private initialCameraPosition: Vector3;

  constructor(private friendshipService: FriendshipService,
              private eventService: EventService) {
  }

  ngOnInit(): void {
    // Load the friendship graph.
    this.friendshipService.loadFriendshipGraph()
      .pipe(first())
      .subscribe(friendshipGraph => {
        this.friendshipGraph = friendshipGraph;
        this.dataInitialized$.next(true);
      });

    // Create the graph after the view was initialized and the data was loaded.
    combineLatest([this.viewInitialized$, this.dataInitialized$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([viewInitialized, dataInitialized]) => {
        if (viewInitialized && dataInitialized) {
          this.createGraph();
        }
      });

    // Update the graph's dimensions whenever the window is resized.
    this.eventService.windowResize$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(size => this.configureSize(size.x, this.graphContainer.nativeElement.clientHeight));
  }

  ngAfterViewInit(): void {
    this.viewInitialized$.next(true);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetCamera(): void {
    this.graph.cameraPosition(
      {
        x: this.initialCameraPosition.x,
        y: this.initialCameraPosition.y,
        z: this.initialCameraPosition.z
      },
      {
        x: 0,
        y: 0,
        z: 0
      },
      3000
    );
  }

  toggleTextNodes(showTextNodes: boolean): void {
    this.showTextNodes = showTextNodes;
    this.configureNodeStyling();
  }

  private createGraph(): void {
    if (!!this.graph) {
      console.error('Graph was already created. Recreating it leads to visual errors.');
      return;
    }

    const graphData = {
      nodes: this.friendshipGraph.nodes.map(node => {
        return {
          id: node.id,
          name: !!node.payload ? node.payload.firstName + ' ' + node.payload.lastName : 'deleted'
        };
      }),
      links: this.friendshipGraph.edges
    };

    // 3D-Force-Graph examples: https://github.com/vasturiano/3d-force-graph
    // Example configuration: https://codepen.io/aimeelally/pen/qydMNQ?editors=1010.
    this.graph = ForceGraph3D();
    this.graph(this.graphContainer.nativeElement)
      .graphData(graphData)
      // TODO: Cluster nodes and give each node the color of its cluster.
      // .nodeAutoColorBy('group')
      .backgroundColor(this.backgroundColor)
      .nodeRelSize(this.nodeRelSize)
      .onNodeClick((node, _) => this.focusNode(node));
    this.configureSize(this.graphContainer.nativeElement.clientWidth, this.graphContainer.nativeElement.clientHeight);
    this.configureNodeStyling();

    this.initialCameraPosition = new Vector3();
    this.graph.camera().getWorldPosition(this.initialCameraPosition);
  }

  private configureSize(width: number, height: number): void {
    if (!!this.graph) {
      this.graph.width(width).height(height);
    }
  }

  private configureNodeStyling(): void {
    if (!!this.graph) {
      if (this.showTextNodes) {
        this.graph
          .nodeThreeObject(node => {
            // @ts-ignore
            const sprite = new SpriteText(node.name);
            sprite.color = '#ffffff';
            sprite.textHeight = 5;
            return sprite;
          });
      }
      else {
        this.graph
          .nodeThreeObject(_ => null);
      }
    }
  }

  private focusNode(node: any): void {
    // Aim at node from outside it
    const distance = 40;
    const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
    this.graph.cameraPosition(
      {
        x: node.x * distRatio,
        y: node.y * distRatio,
        z: node.z * distRatio
      },
      node,
      3000
    );
  }

}
