import {Component, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {MotiveScores} from '../../../test/state/motive-score';
import {CurrentUser} from '../../../user/current-user.service';
import {TestQuery} from '../../../test/state/test.query';

@Component({
  selector: 'app-key-graphic-view',
  templateUrl: './key-graphic-view.component.html',
  styleUrls: ['./key-graphic-view.component.scss']
})
export class KeyGraphicViewComponent implements OnInit {

  private unsubscribe$: Subject<void> = new Subject<void>();

  private testId: string;

  clientId = '';
  clientName = '';
  motiveScores: MotiveScores[] = undefined;

  constructor(private currentUser: CurrentUser,
              private testQuery: TestQuery,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    window.history.state.clientId != null ?
      this.clientId = window.history.state.clientId :
      this.clientId = this.currentUser.keycloakId;

    window.history.state.clientName != null ?
      this.clientName = window.history.state.clientName :
      this.clientName = this.currentUser.firstName + ' ' + this.currentUser.lastName;

    this.route.params.subscribe(params => {
      this.testId = params.testId;
      this.loadResult();
    });
  }

  loadResult() {
    this.testQuery.selectTest(this.clientId, this.testId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(test => this.motiveScores = new Array(test.result.motiveScores));
  }

  printKeyGraphic(): void {
    window.print();
  }

}
