import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Id37Module} from '../id37/id37.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserInviteComponent} from './user-invite/user-invite.component';
import {UserSearchComponent} from './user-search/user-search.component';
import {UserSearchInputComponent} from './user-search/user-search-input/user-search-input.component';
import {UserSearchOutputComponent} from './user-search/user-search-output/user-search-output.component';
import {SelectModule} from '../select/select.module';
import {UserInviteOptionComponent} from './user-invite-option/user-invite-option.component';
import {ModalModule} from '../modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    Id37Module,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    ModalModule
  ],
  declarations: [
    UserInviteComponent,
    UserSearchComponent,
    UserSearchInputComponent,
    UserSearchOutputComponent,
    UserInviteOptionComponent
  ],
  exports: [
    UserInviteComponent,
    UserSearchComponent,
    UserInviteOptionComponent
  ]
})
export class UserModule {
}
