import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor() {
  }

  /**
   * Scrolls the view to the target element.
   *
   * @param container
   *   The container in which the scroll should happen.
   *   Either the ID of the element in the DOM or the element itself.
   * @param target
   *   The target to scroll to. Either the ID of the element in the DOM or the element itself.
   * @param position
   *   Either 'top' or 'middle'.
   *   If not specified, 'top' is used.
   *   If 'top', then the target element is scrolled to the top of the viewport.
   *   If 'middle', then the target element is scrolled to the middle of the viewport.
   * @param offset
   *   Additional offset which is added to the final position to which the browser will scroll.
   *   Zero by default.
   */
  scrollToElementInContainer(container: string | HTMLElement,
                             target: string | HTMLElement,
                             position?: 'top' | 'middle',
                             offset?: number): void {
    if (!position) {
      position = 'top';
    }
    if (!offset) {
      offset = 0;
    }
    const containerElement: HTMLElement = (typeof container === 'string') ?
      document.getElementById(container)
      : container;
    const targetElement: HTMLElement = (typeof target === 'string') ?
      document.getElementById(target)
      : target;
    const elementTopOffset: number = targetElement.offsetTop;
    const positionToScrollTo: number = position === 'middle'
      ? elementTopOffset - (containerElement.scrollHeight / 2) - (targetElement.clientHeight / 2) + offset
      : elementTopOffset + offset;
    containerElement.scrollTo({
      top: positionToScrollTo,
      behavior: 'smooth'
    });
  }

  /**
   * Scrolls the view to the target element.
   *
   * @param target
   *   The target to scroll to. Either the ID of the element in the DOM or the element itself.
   * @param position
   *   Either 'top' or 'middle'.
   *   If not specified, 'top' is used.
   *   If 'top', then the target element is scrolled to the top of the viewport.
   *   If 'middle', then the target element is scrolled to the middle of the viewport.
   * @param offset
   *   Additional offset which is added to the final position to which the browser will scroll.
   *   Zero by default.
   */
  scrollToElement(target: string | HTMLElement,
                  position?: 'top' | 'middle',
                  offset?: number): void {
    if (!position) {
      position = 'top';
    }
    if (!offset) {
      offset = 0;
    }
    const element: HTMLElement = (typeof target === 'string') ?
      document.getElementById(target)
      : target;
    const elementRect: ClientRect = element.getBoundingClientRect();
    const absoluteElementTop: number = elementRect.top + window.pageYOffset;
    const positionToScrollTo: number = position === 'middle'
      ? absoluteElementTop - (window.innerHeight / 2) + offset
      : absoluteElementTop + offset;
    window.scrollTo({
      top: positionToScrollTo,
      behavior: 'smooth'
    });
  }

}
