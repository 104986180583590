<div class="clr-row clr-justify-content-center">
  <div class="clr-col-12">
    <div class="clr-row clr-justify-content-start label-row">
      <div class="clr-col-3 header-cell"><label>{{__('email')}}</label></div>
      <div class="clr-col-2 header-cell"><label>{{__('lastName')}}</label></div>
      <div class="clr-col-2 header-cell"><label>{{__('firstName')}}</label></div>
      <div class="clr-col-2 header-cell"><label>{{__('group')}}</label></div>
      <div class="clr-col-1 header-cell"><label>{{__('variation')}}</label></div>
      <div class="clr-col-1 header-cell"><!-- empty --></div>
    </div>
    <app-new-customers-row (deleted)="onDeleted(idx)"
                           (validate)="checkValid()"
                           *ngFor="let customerForm of customerFormArray; let idx = index"
                           [customerForm]="customerForm"
                           [groups$]="groups$">
    </app-new-customers-row>
  </div>
</div>
