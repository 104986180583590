<div class="invoices-table-container">
  <clr-datagrid>
    @for (headerElement of tableHeader; track headerElement;) {
      <clr-dg-column>{{ __(headerElement) }}</clr-dg-column>
    }
    @for (tableRow of webappTableBodyRows; track tableRow; let indexOuter = $index) {
      <clr-dg-row>
        @for (tableColumn of tableRow.columns; track tableColumn) {
          <clr-dg-cell class="{{tableColumn.cssClass}}">
            @if (!tableColumn.hasButton) {
              {{ tableColumn.label }}
            } @else {
              <button class="download-button" (click)="onClicked(indexOuter)"
              > {{ tableColumn.label }}
                @if (tableColumn.buttonIcon) {
                  <img class="{{tableColumn.buttonIcon.imageCssClass}}"
                       src="{{tableColumn.buttonIcon.imageUrl}}"
                       alt="{{tableColumn.buttonIcon.imageAlt}}">
                }
              </button>
            }
          </clr-dg-cell>
        }
      </clr-dg-row>
    }
  </clr-datagrid>
</div>
