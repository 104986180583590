import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnzerPaymentResourceRepresentation } from '../unzer-payment-resource-representation';
import { environment } from '../../../../environments/environment';
import { TranslatorService } from '../../../translation/translator.service';
import { CurrentUser } from '../../../user/current-user.service';

declare let unzer: any;

const HEIDELPAY_PUBLIC_KEY = environment.HEIDELPAY_PUBLIC_KEY;

@Component({
  selector: 'app-unzer-credit-card-form',
  templateUrl: './unzer-credit-card-form.component.html',
  styleUrls: ['./unzer-credit-card-form.component.scss']
})
export class UnzerCreditCardFormComponent implements OnInit {
  @Output() doCharge: EventEmitter<UnzerPaymentResourceRepresentation> = new EventEmitter<UnzerPaymentResourceRepresentation>();

  cardForm: FormGroup;
  ongoingSubmission: boolean = false;
  cardInstance: any;

  constructor(
      private currentUser: CurrentUser,
      private translatorService: TranslatorService,
      private formBuilder: FormBuilder
  ) {
    this.cardForm = this.formBuilder.group({
      number: ['', Validators.required],
      expiry: ['', Validators.required],
      cvc: ['']
    });
  }

  ngOnInit(): void {
    this.initHeidelpay();
    console.log(this.cardForm.valid);
  }

  initHeidelpay() {
    const heidelpayInstance = new unzer(HEIDELPAY_PUBLIC_KEY, { locale: this.currentUser.locale });
    this.cardInstance = heidelpayInstance.Card();

    /*
    The svg icons for the credit cards and other Unzer form UI details are in:
        /assets/images/brands
        /assets/images/common
    The paths and names of the files cannot be changed since they're loaded by the Unzer script and css file
     */

    this.cardInstance.create('number', {
      containerId: 'card-element-id-number',
      onlyIframe: false
    });
    this.cardInstance.create('expiry', {
      containerId: 'card-element-id-expiry',
      onlyIframe: false
    });
    this.cardInstance.create('cvc', {
      containerId: 'card-element-id-cvc',
      onlyIframe: false
    });

    // Add event listeners for validation
    this.cardInstance.addEventListener('change', (event: any) => {
      if (event.success) {
        this.cardForm.get(event.type)?.setErrors(null);
      }
      else {
        this.cardForm.get(event.type)?.setErrors({ invalid: true });
      }
      this.cardForm.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.cardForm.valid) {
      this.ongoingSubmission = true;
      const cardPromise = this.cardInstance.createResource();
      Promise.all([cardPromise])
          .then(values => {
            this.doCharge.emit(new UnzerPaymentResourceRepresentation(
                values[0].id as string
            ));
            this.ongoingSubmission = false;
          })
          .catch(error => {
            console.error('Unable to execute heidelpay promises. ', error);
            this.ongoingSubmission = false;
          });
    }
  }

  isFormValid(): boolean {
    return this.cardForm.valid && !this.ongoingSubmission;
  }

  __(key: string): string {
    return this.translatorService.translate('order.' + key);
  }
}
