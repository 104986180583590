export class ChartColor {


  static getChartColors(alpha: number): string[] {
    return [
      `rgb(255, 160, 31, ${alpha})`,
      `rgb(43, 255, 100, ${alpha})`,
      `rgb(255, 253, 45, ${alpha})`,
      `rgb(43, 176, 255, ${alpha})`,
      `rgb(255, 1, 150, ${alpha})`,

      `rgb(255, 13, 94, ${alpha})`,
      `rgb(255, 236, 25, ${alpha})`,
      `rgb(207, 0, 255, ${alpha})`,
      `rgb(25, 255, 47, ${alpha})`,
      `rgb(78, 13, 255, ${alpha})`
    ];
  }
}
