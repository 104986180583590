import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';

@Directive({
  // eslint-disable-next-line
  selector: '[rolesAllowed]'
})
export class RolesAllowedDirective {

  private roles: string[] = [];
  private excludedRoles: string[] = [];
  private logicalOperator: 'OR' | 'AND' = 'OR';
  private strict: boolean = false;
  private isHidden: boolean = true;

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private currentUser: CurrentUser) {
  }

  private updateView(): void {
    if (this.checkViewAllowed()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    }
    else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkViewAllowed(): boolean {
    const operator = this.strict ? 'AND' : this.logicalOperator;

    // At least one role must be present.
    if (operator === 'OR') {
      let allowed = false;
      if (this.roles.length === 0) {
        allowed = true;
      }
      for (const role of this.roles) {
        if (this.currentUser.hasRole(role)) {
          allowed = true;
        }
      }
      for (const excludedRole of this.excludedRoles) {
        if (this.currentUser.hasRole(excludedRole)) {
          allowed = false;
        }
      }
      return allowed;
    }
    // All roles must be present.
    else if (operator === 'AND') {
      let allowed = true;
      if (this.roles.length === 0) {
        allowed = true;
      }
      for (const role of this.roles) {
        if (!this.currentUser.hasRole(role)) {
          allowed = false;
        }
      }
      for (const excludedRole of this.excludedRoles) {
        if (this.currentUser.hasRole(excludedRole)) {
          allowed = false;
        }
      }
      if (this.strict) {
        return allowed && this.roles.length === this.currentUser.roles.length;
      }
      else {
        return allowed;
      }
    }
  }

  @Input()
  set rolesAllowed(roles: undefined | string | string[]) {
    if (typeof roles === typeof undefined) {
      roles = [];
    }
    if (!(roles instanceof Array)) {
      roles = [roles];
    }
    this.roles = roles;
    this.updateView();
  }

  /**
   * Allows to declare roles with which access is not granted.
   *
   * @param roles Roles which must not be present on the current user.
   */
  @Input()
  set rolesAllowedNot(roles: undefined | string | string[]) {
    if (typeof roles === typeof undefined) {
      roles = [];
    }
    if (!(roles instanceof Array)) {
      roles = [roles];
    }
    this.excludedRoles = roles;
    this.updateView();
  }


  /**
   * When 'OR': The user must have at least one of the allowed roles.
   * When 'AND': The user must have all of the allowed roles.
   *
   * @param operator The operation to perform when computing the access right.
   */
  @Input()
  set rolesAllowedOp(operator: 'OR' | 'AND') {
    this.logicalOperator = operator;
    this.updateView();
  }

  /**
   * When strict: The user must not have any other roles then the ones specified as being allowed.
   * The operation "op" is forced to "AND" when computing the access right.
   */
  @Input()
  set rolesAllowedStrict(strict: boolean) {
    this.strict = strict;
    this.updateView();
  }

}
