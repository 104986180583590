<ng-container *ngIf="backendIsExistent && !isIE">
  <app-centered-loading-indicator *ngIf="!backendUserExists; else withBackendUser"></app-centered-loading-indicator>

  <ng-template #withBackendUser>

      <ng-container *ngIf="testLoaded">
        <app-nav></app-nav>
        <div class="content-container">
          <div class="content-area">
            <router-outlet></router-outlet>
            <app-invited-by-partner *ngIf="currentUser.hasPartner()"></app-invited-by-partner>
          </div>
        </div>
        <app-pwa></app-pwa>
      </ng-container>


    <!-- Checking for new versions of the application... -->
    <app-update></app-update>

  </ng-template>
</ng-container>

<div *ngIf="isIE">
  <app-internet-explorer></app-internet-explorer>
</div>

<div *ngIf="!backendIsExistent && !isIE">
  <app-service-unavailable></app-service-unavailable>
</div>
