
<div *ngIf="(loadingFriendships$ | async)" class="centered">
  <app-loading-indicator></app-loading-indicator>
</div>


<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">


    <ng-container *ngIf="!(loadingFriendships$ | async)">


      <ng-container *ngIf="!(hasFriends$ | async) && !clickedWelcome; else showFriends">
        <div class="welcome-container id37-text2">
          <img alt="" src="/assets/icons/ID37_Netzwerk_100x100.svg">
          <h2>{{__('welcome.headline')}}</h2>
          <div class="welcome-text">{{__('welcome.text')}}</div>
          <button (click)="clickWelcome()" class="id37-btn-primary id37-btn-sm">{{__('welcome.button')}}</button>
        </div>

        <!-- CLOSE -->
        <div class="hide-on-print" id="close-sign">
          <img (click)="routingService.routeBack()" alt="close sign" src="assets/icons/ID37_X.svg">
        </div>
      </ng-container>

      <ng-template #showFriends>
        <app-friend-search-input
            (searchFriendsResult)="searchFriendsResult=$event"
            (searchResult)="searchResult=$event"
            (searching)="searching=$event">
        </app-friend-search-input>

        <ng-container *ngIf="!(hasFriends$ | async) && !searching; else showFriendlist">
          <div class="no-contact-container">
            <img alt="" src="/assets/icons/ID37_Netzwerk_100x100.svg">
            <h3>{{__('introText2.headline')}}</h3>
            <div class="id37-text4">{{__('introText2')}}</div>
          </div>
        </ng-container>

        <ng-template #showFriendlist>
          <app-friend-search-output
              (acceptFriendship)="acceptFriendship($event)"
              (createFriendship)="createFriendship($event)"
              (declineFriendship)="declineFriendship($event)"
              (deleteFriendship)="deleteFriendship($event)"
              (recommend)="openRecommendations()"
              (resendFriendshipRequest)="resendFriendshipRequest($event)"
              (updateTestShare)="updateTestShare($event)"
              *ngIf="searching"
              [isLoading]="isLoading"
              [searchFriendsResult]="searchFriendsResult"
              [searchResult]="searchResult">

          </app-friend-search-output>

          <app-friend-list
              (acceptFriendship)="acceptFriendship($event)"
              (declineFriendship)="declineFriendship($event)"
              (deleteFriendship)="deleteFriendship($event)"
              (recommend)="openRecommendations()"
              (resendFriendshipRequest)="resendFriendshipRequest($event)"
              (updateTestShare)="updateTestShare($event)"
              *ngIf="!searching"
              [isLoading]="isLoading">
          </app-friend-list>
        </ng-template>

      </ng-template>

    </ng-container>
    <app-delete-friend-modal #deleteFriendModal></app-delete-friend-modal>

    <app-user-invite-option #userInviteOptionComponent></app-user-invite-option>

    <app-test-share #testShareModal></app-test-share>

  </div>
</div>
