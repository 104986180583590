import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChatComponent} from './chat/chat.component';
import {ChatMessageComponent} from './chat-message/chat-message.component';
import {ChatInputComponent} from './chat-input/chat-input.component';
import {ChatContactsComponent} from './chat-contacts/chat-contacts.component';
import {ChatMessagesComponent} from './chat-messages/chat-messages.component';
import {ChatContactComponent} from './chat-contact/chat-contact.component';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';
import {FormsModule} from '@angular/forms';
import {Id37Module} from '../id37/id37.module';
import {ChatMessagesDaySeparatorComponent} from './chat-messages-day-separator/chat-messages-day-separator.component';
import {DateTimeModule} from '../datetime/datetime.module';
import {ActiveContactInfoComponent} from './active-contact-info/active-contact-info.component';
import {GeometryModule} from '../geometry/geometry.module';

@NgModule({
  declarations: [
    ChatComponent,
    ChatMessagesComponent,
    ChatMessageComponent,
    ChatInputComponent,
    ChatContactsComponent,
    ChatContactComponent,
    ChatMessagesDaySeparatorComponent,
    ActiveContactInfoComponent
  ],
  imports: [
    CommonModule,
    ProfilePictureModule,
    FormsModule,
    Id37Module,
    DateTimeModule,
    GeometryModule
  ],
  exports: [
    ChatComponent
  ]
})
export class ChatModule {
}
