export enum Gender {

  UNSPECIFIED = '',
  MALE = 'male',
  FEMALE = 'female',
  DIVERSE = 'diverse'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Gender {
  export const LIST: Gender[] = [Gender.UNSPECIFIED, Gender.MALE, Gender.FEMALE, Gender.DIVERSE];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): Gender => {
    name = !!name ? name : '';
    const found = Gender.LIST.find((gender: string) => gender.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a gender with an unknown name: "' + name + '"');
      return Gender.UNSPECIFIED;
    }
    return found;
  };

}
