<!-- Farbbalken und Beschriftung oben -->
<svg app-key-graphic-header [selectedMotiveScore]="selectedMotiveScore"/>

<svg app-key-graphic-grid [lines]="minimumNumberOfMotives" [selectedMotiveScore]="selectedMotiveScore"/>

<!-- Kreise (80er raster) -->
<ng-container *ngFor="let motiveScores of normalizedResults; index as index; first as first; last as last">

  <ng-container *ngFor="let motiveScore of motiveScores | keyvalue">
    <svg app-key-graphic-motive-score
         [motiveScore]="motiveScore.value"
         [style.opacity]="getOpacity(motiveScore.value)"
         [class]="getDotClass(index, first, last)"
         [colorIndex]="index"
         [isCompare]="first != last"
         style="transition: opacity 0.3s;"
         (dotClick)="tooltip.emit($event)"/>
  </ng-container>

  <svg app-key-graphic-motive-score-connection
       [motiveScores]="motiveScores"
       [selectedMotiveScore]="selectedMotiveScore"
  />

</ng-container>

<svg app-key-graphic-motive-score-descriptions [results]="normalizedResults"/>
