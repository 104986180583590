<div *ngIf="!loaded" class="clr-row clr-justify-content-center margin-0" style="margin-top: 100px">
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>

<div *ngIf="loaded" class="clr-row clr-justify-content-center margin-0">

  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-col-12 padding-0 title header-logo">
          <h2>
            {{__('compare')}}
          </h2>
          <app-logo [reactive]="false"></app-logo>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-col-12 padding-0">
          <app-key-graphic-print-layout (shouldBePrinted)="printMotiveProfile()"
                                        [name]=""
                                        [results]="[user1MotiveScores, user2MotiveScores]"
                                        [showLogo]="false">

            <h2 class="graphic-title">
              <span class="user-dot" id="user1-dot"></span>
              <span class="user-name" id="user1-name">{{user1Name}}</span>
              <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
              <span class="user-dot" id="user2-dot"></span>
              <span class="user-name" id="user2-name">{{user2Name}}</span>
            </h2>

          </app-key-graphic-print-layout>
        </div>
      </div>
    </div>
  </div>
</div>
