import {Pipe, PipeTransform} from '@angular/core';
import {TestOriginImport} from './test-origin-import';

@Pipe({
  name: 'TestOriginImportCast',
  pure: true
})
export class TestOriginImportCastPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, args?: any): TestOriginImport {
    return value as TestOriginImport;
  }

}
