import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent {

  @Input()
    round: boolean;

  @Output()
    valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  checked: boolean = false;

  @Input()
  set value(value: boolean) {
    this.checked = value;
    // this.emitValueChange(value);
  }

  valueChanged(value: boolean): void {
    this.checked = value;
    this.valueChange.emit(value);
  }

}
