import {TestService} from './test.service';
import {CurrentUser} from '../../user/current-user.service';
import {TestIdQuery} from './test-id.query';
import {TestStore} from './test.store';
import {TestIds, TestIdStore} from './test-id.store';
import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestResetService {

  constructor(private testService: TestService,
              private testIdQuery: TestIdQuery,
              private testStore: TestStore,
              private testIdStore: TestIdStore,
              private currentUser: CurrentUser) {
  }

  public reloadTestsForCurrentUser(): void {
    const userId = this.currentUser.keycloakId;
    const testIds: TestIds | undefined = this.testIdQuery.getEntity(userId);
    // When the user has no tests and therefore not test ids, the testIdQuery will give an undefined result.
    if (!!testIds) {
      this.testIdQuery.getEntity(userId).ids.forEach(testId =>
        this.testStore.remove([userId, testId].join(':'))
      );
      this.testIdStore.remove(userId);
    }
    this.testService.loadTests(userId).pipe(take(1)).subscribe();
  }

}
