<id37-modal [(modalOpen)]="open" [modalSize]="'lg'">
  <id37-modal-title>Codes generieren</id37-modal-title>
  <id37-modal-body>
    <p>Das ist ein Test</p>
    <form [formGroup]="codeGenerateForm">
      <div class="clr-row clr-justify-content-between margin-0">
        <div class="clr-col-md-8 clr-col-sm-12">
          <div class="columns">
            <!-- Column 1 -->
            <div class="column-1">
              <div class="clr-form-group">
                <label for="prefixCode">Prefix</label>
                <input type="text" id="prefixCode" formControlName="prefixCode">
              </div>
              <div class="clr-form-group">
                <label for="codeLength">Codelänge (generiert)</label>
                <input type="text" id="codeLength" formControlName="codeLength">
              </div>
              <div class="clr-form-group">
                <label for="numberOfCodes">Anzahl</label>
                <input type="text" id="numberOfCodes" formControlName="numberOfCodes">
              </div>
              <div class="clr-form-group">
                <label for="discount">Rabatt</label>
                <input type="text" id="discount" formControlName="discount">
              </div>
              <div class="clr-form-group">
                <div class="radio-buttons">
                  <label for="isActive-true">Aktiv</label>
                  <input type="radio" id="isActive-true" formControlName="active" value="true">
                  <label for="isActive-false">Inaktiv</label>
                  <input type="radio" id="isActive-false" formControlName="active" value="false">
                </div>
              </div>
            </div>
            <!-- Column 2 -->
            <div class="column-2">
              <div class="clr-form-group">
                <label for="maximumUses">Maximale Verwedung</label>
                <input type="text" id="maximumUses" formControlName="maximumUses">
              </div>
              <div class="clr-form-group">
                <label for="activeFrom">Aktiv von</label>
                <app-datetime
                  #d1
                  [type]="'datetime'"
                  formControlName="activeFrom"
                  id="activeFrom">
                </app-datetime>
              </div>

              <div class="clr-form-group">
                <label for="activeUntil">Aktiv von</label>
                <app-datetime
                  #d1
                  [type]="'datetime'"
                  formControlName="activeUntil"
                  id="activeUntil">
                </app-datetime>
              </div>

              <div class="clr-form-group">
                <label for="category">Kategorie</label>
                <input type="text" id="category" formControlName="category">
              </div>
              <div class="clr-form-group">
                <label for="description">Beschreibung</label>
                <input type="text" id="description" formControlName="description">
              </div>
              <div class="clr-form-group">
                <label for="affiliateMail">AffiliateEmail</label>
                <input type="text" id="affiliateMail" formControlName="affiliateMail">
              </div>
              <div class="clr-col-12">
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </id37-modal-body>

  <id37-modal-footer>
    <button (click)="generateCode()" class="id37-btn id37-btn-primary">Generate</button>
  </id37-modal-footer>
</id37-modal>
