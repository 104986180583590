<app-profile-picture
    [allowAccountDataRetrieval]="profilePictureAllowAccountDataRetrieval"
    [userId]="profilePictureUserId"
    [ngStyle]="{'width': profilePictureWidth, 'height': profilePictureHeight}"
></app-profile-picture>

<app-circular-ring
    *ngIf="circularRingRender"
    [svgFill]="true"
    [animated]="true"
    [progress]="circularRingProgress"
    [startColor]="'#05F5FD'"
    [middleColor]="'#02fa9d'"
    [endColor]="'#00FF3D'"
    [circleSizePx]="circularRingCircleSizePx"
    [circleStrokeWidth]="circularRingCircleStrokeWidth"
    (updateProgress)="circularRingProgressChange.emit($event)"
></app-circular-ring>
