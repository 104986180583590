import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ChatbotChat} from '../state/chatbot-chat';
import {ChatbotChatService} from '../api-services/chatbot-chat.service';
import {FormControl} from '@angular/forms';
import {TranslatorService} from '../../translation/translator.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import chatBreakPoint from '../chat/chatbot-chat.component';

@UntilDestroy()
@Component({
  selector: 'app-chatbot-chat-selection',
  templateUrl: './chatbot-chat-selection.component.html',
  styleUrls: ['./chatbot-chat-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatbotChatSelectionComponent implements OnInit {

  @Input()
    chatbotChat: ChatbotChat;

  @Input()
    selectedChat: ChatbotChat;

  @Output()
    chatSelected: EventEmitter<ChatbotChat> = new EventEmitter<ChatbotChat>();

  @Output()
    chatDeleted: EventEmitter<ChatbotChat> = new EventEmitter<ChatbotChat>();

  deleteFormOpen: boolean = false;

  chatNameControl: FormControl;
  isEditEnabled: boolean = false;

  isMobile: boolean = false;


  constructor(private chatbotChatService: ChatbotChatService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.chatNameControl = new FormControl(this.chatbotChat?.title);

    if (window.screen.width <= chatBreakPoint) {
      this.isMobile = true;
    }
  }


  selectChat(): void {
    if (!this.isEditEnabled){
      this.chatSelected.emit(this.chatbotChat);
    }
  }

  openDeleteForm(): void {
    this.deleteFormOpen = true;
  }

  closeDeleteForm(): void {
    this.deleteFormOpen = false;
  }

  deleteChat(): void {
    this.deleteFormOpen = false;
    setTimeout(() => {
      this.chatbotChatService.deleteChatByID(this.chatbotChat.chat_id).pipe(untilDestroyed(this)).subscribe(value => {
        console.log('deleteChatByID at chatbot-chat-selection', value);
        this.chatDeleted.emit(this.chatbotChat);
      });
    }, 0);
  }

  onTitleChange(newTitle: string): void {
    this.isEditEnabled = false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.chatbotChatService.updateTitleByID(this.chatbotChat.chat_id, newTitle)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        console.log('updateTitle at chatbot-chat-selection', value);
      });
    this.chatbotChat.title = newTitle;
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-chat-selection.' + key);
  }

  enableEditMode() {
    this.isEditEnabled = true;
  }
}
