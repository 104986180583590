import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Friendship} from './friendship';
import {FriendshipStore} from './friendship.store';
import {FriendshipsRepresentation} from './friendships-representation';
import {CurrentUser} from '../../user/current-user.service';
import {FriendshipFunctions} from './friendship-functions';
import {FriendshipRepresentation} from './friendship-representation';
import {BasicUser} from '../../user/basic-user';
import {SuperlikeRepresentation} from './superlike-representation';
import {FriendshipGraph} from './friendship-graph';
import {FriendshipGraphRepresentation} from './friendship-graph-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class FriendshipService {

  constructor(private http: HttpClient,
              private friendshipStore: FriendshipStore,
              private currentUser: CurrentUser) {
  }

  storeFriendship(friendship: Friendship): void {
    const updatedFriendship: Friendship = {
      id: friendship.id,
      userA: friendship.userA,
      userB: friendship.userB,
      createdAt: friendship.createdAt,
      inviteApproved: friendship.inviteApproved,
      testShares: friendship.testShares,
      distances: friendship.distances,
      computedStatus: FriendshipFunctions.computeFriendshipStatus(
        friendship,
        this.currentUser.keycloakId
      )
    };
    this.friendshipStore.upsert(friendship.id, () => updatedFriendship);
  }

  storeFriendships(friendships: Friendship[]): void {
    friendships.forEach(friendship => this.storeFriendship(friendship));
  }

  clearFriendships(): void {
    this.friendshipStore.getValue().ids.forEach(id => this.friendshipStore.remove(id));
  }

  clearDeletedFriendships(friendships: Friendship[]): void {
    const friendshipIds = friendships.map(friendship => friendship.id);
    this.friendshipStore.getValue().ids
      .filter(id => !friendshipIds.includes(id))
      .forEach(id => this.friendshipStore.remove(id));
  }

  loadFriendships(): Observable<Friendship[]> {
    this.friendshipStore.setLoading(true);
    return this.http.get<FriendshipsRepresentation>(
      API_URL + '/friendships',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((friendshipsRepresentation: FriendshipsRepresentation) =>
        FriendshipsRepresentation.toModelEntity(friendshipsRepresentation, this.currentUser.keycloakId)),
      tap((friendships: Friendship[]) => {
        this.storeFriendships(friendships);
        this.friendshipStore.setLoading(false);
      })
    );
  }

  loadFriendship(friendshipId: number): Observable<Friendship> {
    this.friendshipStore.setLoading(true);
    return this.http.get<FriendshipRepresentation>(
      API_URL + '/friendships/' + friendshipId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((friendship: Friendship) => {
        this.storeFriendship(friendship);
        this.friendshipStore.setLoading(false);
      })
    );
  }

  loadFriendshipGraph(): Observable<FriendshipGraph> {
    return this.http.get<FriendshipGraphRepresentation>(
      API_URL + '/friendships/graph',
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      map((friendshipGraphRepresentation: FriendshipGraphRepresentation) =>
        FriendshipGraphRepresentation.toModelEntity(friendshipGraphRepresentation))
    );
  }

  revalidateFriendships(): Observable<Friendship[]> {
    this.friendshipStore.setLoading(true);
    return this.http.get<FriendshipsRepresentation>(
      API_URL + '/friendships',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;version=1'
        })
      }
    ).pipe(
      map((friendshipsRepresentation: FriendshipsRepresentation) =>
        FriendshipsRepresentation.toModelEntity(friendshipsRepresentation, this.currentUser.keycloakId)),
      tap((friendships: Friendship[]) => {
        this.clearDeletedFriendships(friendships);
        this.friendshipStore.setLoading(false);
      })
    );
  }

  sendFriendshipInvitationTo(user: BasicUser): Observable<Friendship> {
    return this.http.post<FriendshipRepresentation>(
      API_URL + '/friendships',
      user,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((friendship: Friendship) =>
        this.storeFriendship(friendship))
    );
  }

  resendFriendshipInvitationTo(friendshipToResend: Friendship): Observable<Friendship> {
    return this.http.post<FriendshipRepresentation>(
      API_URL + '/friendships/resend/' + friendshipToResend.id,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((friendship: Friendship) =>
        this.storeFriendship(friendship))
    );
  }

  acceptFriendship(friendship: Friendship): Observable<Friendship> {
    return this.updateFriendshipInviteApprovedState(friendship, true);
  }

  declineFriendship(friendship: Friendship): Observable<Friendship> {
    return this.updateFriendshipInviteApprovedState(friendship, false);
  }

  deleteFriendship(friendship: Friendship): Observable<void> {
    // Delete the friendship from the store right away.
    // We assume, that the delete request will work correctly.
    this.friendshipStore.remove(friendship.id);
    return this.http.delete<void>(
      API_URL + '/friendships/' + friendship.id,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      catchError(
        (error: HttpErrorResponse): Observable<void> => {
          // Restore friendship on error.
          this.storeFriendship(friendship);
          // Rethrow.
          return throwError(error);
        }
      )
    );
  }

  sendSuperLike(superLike: SuperlikeRepresentation): Observable<void> {
    return this.http.post<void>(
      API_URL + '/superlike',
      superLike,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json;version=1'
        })
      }
    );
  }

  private updateFriendshipInviteApprovedState(oldFriendship: Friendship,
                                              newInviteApprovedState: boolean): Observable<Friendship> {
    const updatedFriendship: Friendship = {
      id: oldFriendship.id,
      userA: oldFriendship.userA,
      userB: oldFriendship.userB,
      createdAt: oldFriendship.createdAt,
      inviteApproved: newInviteApprovedState,
      testShares: oldFriendship.testShares,
      distances: oldFriendship.distances,
      computedStatus: oldFriendship.computedStatus
    };
    if (newInviteApprovedState) {
      this.storeFriendship(updatedFriendship);
    }
    else {
      this.friendshipStore.remove(oldFriendship.id);
    }
    return this.http.patch<FriendshipRepresentation>(
      API_URL + '/friendships/' + oldFriendship.id,
      {
        inviteApproved: newInviteApprovedState
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((receivedFriendshipRepresentation: FriendshipRepresentation | null | undefined) => {
        if (receivedFriendshipRepresentation) {
          return FriendshipRepresentation.toModelEntity(
            receivedFriendshipRepresentation,
            this.currentUser.keycloakId
          );
        }
      }),
      tap((receivedFriendship: Friendship | null | undefined) => {
        if (receivedFriendship) {
          this.storeFriendship(receivedFriendship);
        }
      }),
      catchError(
        (error: HttpErrorResponse): Observable<Friendship> => {
          console.error(error);
          // Restore friendship on error.
          this.storeFriendship(oldFriendship);
          // Rethrow.
          return throwError(error);
        }
      )
    );
  }

}
