import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MotiveScores} from '../../../../test/state/motive-score';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic-motive-score-descriptions]',
  templateUrl: './key-graphic-motive-score-descriptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicMotiveScoreDescriptionsComponent {

  @Input()
    results: MotiveScores[];

}
