import {Component, Input, OnInit} from '@angular/core';
import {Notification} from '../state/notification';
import {TranslatorService} from '../../translation/translator.service';
import {NotificationService} from '../state/notification.service';
import {NotificationType} from '../state/notification-type';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input()
    notification: Notification;

  notificationType = NotificationType;

  title: string = '';
  message: string = '';

  constructor(private translatorService: TranslatorService,
              private notificationService: NotificationService) {
  }

  ngOnInit(): void {
    this.computeMessage();
  }

  computeMessage(): void {
    this.title = this.__(this.notification.type + '.title');
    if (this.notification.type === NotificationType.SUPERLIKE_SENT) {
      this.title += this.__(this.notification.data.superlike + '.message');
    }
    this.message = this.__(this.notification.type + '.message');
  }

  toggleReadState(): void {
    this.notificationService.toggleReadState(this.notification);
  }

  delete(): void {
    this.notificationService.delete(this.notification);
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + key);
  }

}
