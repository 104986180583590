import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectComponent} from './select/select.component';
import {SelectItemComponent} from './select-item/select-item.component';
import {SelectTestComponent} from './select-test/select-test.component';
import {SelectMenuComponent} from './select-menu/select-menu.component';
import {FormsModule} from '@angular/forms';

@NgModule({
  exports: [
    SelectComponent,
    SelectTestComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    SelectComponent,
    SelectItemComponent,
    SelectTestComponent,
    SelectMenuComponent
  ]
})
export class SelectModule {
}
