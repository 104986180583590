import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {environment} from '../../environments/environment';
import {CurrentUser} from '../user/current-user.service';
import {KeycloakUserFeatureFlag} from '../user/keycloak-user-feature-flag';
import {Referrer} from '../user/referrer';

@Directive({
  // eslint-disable-next-line
  selector: '[featureAllowed]'
})
export class FeatureAllowedDirective {

  private featureName: string | undefined;
  private isHidden: boolean = true;

  constructor(private element: ElementRef,
              private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private currentUser: CurrentUser) {
  }

  private updateView(): void {
    if (this.checkViewAllowed()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    }
    else {
      this.viewContainer.clear();
    }
  }

  private checkViewAllowed(): boolean {
    switch (this.featureName) {
      case 'chat':
        return environment.FEATURE_CHAT;
      case 'chatbot':
        return environment.FEATURE_CHATBOT;
      case 'friends':
        return environment.FEATURE_FRIENDS;
      case 'payment':
        return environment.FEATURE_PAYMENT;
      case 'recurring_payment':
        return environment.FEATURE_RECURRING_PAYMENT;
      case 'test-reset':
        return this.currentUser.isFeatureAllowed(KeycloakUserFeatureFlag.RESET_MY_TEST);
      case 'teams':
        return environment.FEATURE_TEAMS;
      case 'recommend':
        return this.currentUser.referrer !== Referrer.WW;
      default:
        return false;
    }
  }

  private reset(): void {
    this.featureName = undefined;
  }

  @Input()
  set featureAllowed(
    featureName: 'chat' | 'chatbot' | 'friends' | 'payment' | 'test-reset' |
    'teams' | 'recommend' | 'recurring_payment' | undefined) {
    this.reset();
    this.featureName = featureName;
    this.updateView();
  }

}
