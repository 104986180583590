import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UnzerPaymentResourceRepresentation} from '../unzer-payment-resource-representation';
import {environment} from '../../../../environments/environment';
import {TranslatorService} from '../../../translation/translator.service';
import {CurrentUser} from '../../../user/current-user.service';

declare let unzer: any;

const HEIDELPAY_PUBLIC_KEY = environment.HEIDELPAY_PUBLIC_KEY;

@Component({
  selector: 'app-unzer-paypal-form',
  templateUrl: './unzer-paypal-form.component.html',
  styleUrls: ['./unzer-paypal-form.component.scss']
})
export class UnzerPayPalFormComponent implements OnInit {

  @Output()
    doCharge: EventEmitter<UnzerPaymentResourceRepresentation> =
      new EventEmitter<UnzerPaymentResourceRepresentation>();

  ongoingSubmission: boolean = false;

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.initHeidelpay();
  }

  initHeidelpay() {
    const unzerInstance = new unzer(HEIDELPAY_PUBLIC_KEY, {locale: this.currentUser.locale});
    const paypal = unzerInstance.Paypal();
    const paypalPromise = paypal.createResource();

    this.ongoingSubmission = true;
    Promise.all([paypalPromise])
      .then(resource => {
        this.doCharge.emit(new UnzerPaymentResourceRepresentation(
          resource[0].id as string
        ));
        this.ongoingSubmission = false;
      })
      .catch(error => {
        console.error('Unable to execute heidelpay promises. ', error);
        this.ongoingSubmission = false;
      });
  }

  __(key: string): string {
    return this.translatorService.translate('order.' + key);
  }

}
