<div
    style="display: flex; flex-direction: column; text-align: center; justify-content: center; align-items: center;height: 100%; padding: 3em">
  <div>
    <p><b>Achtung</b>: Der Browser Microsoft Internet Explorer wird <b>nicht unterstützt</b>, da er ein hohes
      Sicherheitsrisiko darstellt. Microsoft hat bereits in 2016 den Support für diesen Browser eingestellt.</p>
    Die ID37 Anwendung ist für <br/>
    <b>Chrome </b> <a href="https://www.google.com/intl/de_de/chrome/">herunterladen</a>, <br/>
    <b>Microsoft Edge </b> <a href="https://www.microsoft.com/de-de/edge">herunterladen</a>, <br/>
    <b>Safari </b> <a href="https://support.apple.com/de_DE/downloads/safari">herunterladen</a> und <br/>
    <b>Firefox </b> <a href="https://www.opera.com/de">herunterladen</a> <br/>
    optimiert, da diese Internet Browser ein hohes Sicherheitsniveau aufweisen. Bitte verwende einen der genannten
    Browser um fortzufahren.
  </div>
  <div style="padding-top: 3em">
    <p><b>Please note</b>: Microsoft Internet Explorer is <b>not supported</b> as it presents a high security risk.
      Microsoft support for the browser ended in 2016.</p>
    The ID37 application is optimized for <b>Chrome</b>, <b>Edge</b>, <b>Safari</b> and <b>Firefox</b>. These
    browsers have a high level of security. Please switch to one of these browsers to continue.
  </div>


</div>
