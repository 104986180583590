import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[id37Dropdown]'
})
export class DropdownDirective {

  @Output() clickOutside = new EventEmitter<void>();

  @HostListener('document:click', ['$event.target'])

  public onClick(target): void {
    const clickedInside = this.elRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickOutside.emit();
    }
  }

  constructor(private elRef: ElementRef) {
  }

}
