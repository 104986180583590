import {TestOriginUnknownRepresentation} from './test-origin-unknown-representation';
import {AbstractTestOrigin} from './abstract-test-origin';

export class TestOriginUnknown extends AbstractTestOrigin {

  constructor() {
    super();
  }

  static buildFrom(testOriginUnknownRepresentation: TestOriginUnknownRepresentation): TestOriginUnknown {
    return new TestOriginUnknown();
  }

  isBuy(): boolean {
    return false;
  }

  isImport(): boolean {
    return false;
  }

  isInvitation(): boolean {
    return false;
  }

  isUnknown(): boolean {
    return true;
  }

}
