<id37-modal [(modalOpen)]="show">

  <id37-modal-title *ngIf="editMode; else create"><h4>{{__('edit.title')}}</h4></id37-modal-title>

  <ng-template #create>
    <id37-modal-title>{{__('create.title')}}</id37-modal-title>
  </ng-template>

  <ng-container *ngIf="!deleteMode; else deleteWindow">
    <id37-modal-body>

      <div class="input-container">
        <label for="name">{{__('name.label')}}</label>
        <span *ngIf="editMode" class="remove-button id37-text5" (click)="onDeleteTeam()">{{__('remove.team')}}</span>
        <input id="name" type="text" [placeholder]="__('name.placeholder')" [(ngModel)]="teamName">
      </div>

      <div class="input-container">
        <label for="member">{{__('member.label')}}&nbsp;<span>(max. 40)</span></label>
        <input id="member"
               style="margin-bottom: 0"
               type="text"
               [placeholder]="__('member.placeholder')"
               (ngModelChange)='searchInputChanged.next($event)'
               [(ngModel)]="searchKey">
      </div>

      <!-- search result -->
      <div *ngIf="debounceSearchKey" style="margin-bottom: 10px; width: 100%;   min-height: 5em;">
        <app-team-users-selection
          [searchKey]="debounceSearchKey"
          [members]="members"
          (addMember)="addMember($event)"
          (deleteMember)="deleteMember($event)"
          (limit)="limitReached($event)">
        </app-team-users-selection>
      </div>

      <!-- dummy view -->
      <div *ngIf="!debounceSearchKey && members.length === 0" class="empty-selection-container">
        <img src="assets/icons/smiley_happy_42x42.svg" alt="a happy smiley face">
        <h5>{{__('greeting')}} {{this.fullName}}.</h5>
        <div class="id37-text4 text-light">{{__('text')}}</div>
      </div>

      <!-- selected member -->
      <div *ngIf="!debounceSearchKey && members.length" class="member-list">
        <h6>{{__('members')}}</h6>
        <cdk-virtual-scroll-viewport itemSize="70" class="item-list" style="height: 200px">

          <ng-container *cdkVirtualFor="let p of members;let idx = index">
            <div class="item-container">
              <app-profile-picture [userId]="p.keycloakId"></app-profile-picture>
              <div class="id37-text3">{{p.firstName}} {{p.lastName}}</div>
              <span class="id37-text5 remove" (click)="deleteMember(p)">{{__('remove.member')}}</span>
            </div>
            <hr style="width: 90%; margin: 0 5%">
          </ng-container>

        </cdk-virtual-scroll-viewport>
      </div>

    </id37-modal-body>

    <button *ngIf="debounceSearchKey; else save"
            class="id37-btn-primary id37-btn-rounded-1"
            [disabled]="members.length === 0 || this.maxSelectedUsers"
            (click)="onContinue()">
      {{__('continue.button')}}</button>

    <ng-template #save>
      <button (click)="onSave()"
              [disabled]="teamName === '' || this.tooManyUsers"
              class="id37-btn-primary id37-btn-rounded-1">
        {{__('save.button')}}
      </button>
    </ng-template>

  </ng-container>

  <!-- Delete Modal -->
  <ng-template #deleteWindow>
    <id37-modal-body>
      <img class="neutral-smiley" src="assets/icons/smiley_neutral_42x42.svg" alt="neutral smiley face">
      <h4>Hey {{fullName}}.</h4>
      <div class="id37-text3">{{__('delete.message')}}</div>
    </id37-modal-body>

    <id37-modal-footer style="margin-top: 6.5em">
      <button class="id37-btn-secondary" (click)="onDeleteCancel()">{{__('delete.decline')}}</button>
      <button class="id37-btn-primary" (click)="onDeleteApprove()">{{__('delete.approve')}}</button>
    </id37-modal-footer>
  </ng-template>

</id37-modal>

