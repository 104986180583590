import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {BasicUser} from './basic-user';
import {BasicUserRepresentation} from './basic-user-representation';
import {Injectable} from '@angular/core';
import {UserGroup} from './user-group';
import {UserGroupRepresentation} from './user-group-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UserSearchService {

  private TIMEOUT_DURATION = 180000;

  constructor(private http: HttpClient) {
  }

  public searchUsers(nameFilter: string, userTargetFilter: string): Observable<BasicUser[]> {
    return this.http.get<BasicUserRepresentation[]>(
      API_URL + `/users/${nameFilter}/${userTargetFilter}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map((basicUserRepresentations: BasicUserRepresentation[]) =>
        basicUserRepresentations.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation))
      ));
  }

  public searchUsersUsingNetworking(nameFilter: string): Observable<BasicUser[]> {
    return this.http.get<BasicUserRepresentation[]>(
      API_URL + `/users/using-networking/${nameFilter}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map((basicUserRepresentations: BasicUserRepresentation[]) =>
        basicUserRepresentations.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation))
      ));
  }

  public searchUsersInFriendsAndGroups(nameFilter: string): Observable<UserGroup[]> {
    return this.http.get<UserGroupRepresentation[]>(
      API_URL + `/users/teams/${nameFilter}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map((userGroupRepresentations: UserGroupRepresentation[]) =>
        userGroupRepresentations.map(userGroupRepresentation =>
          UserGroupRepresentation.toModelEntity(userGroupRepresentation))
      ));
  }

  public searchUsersByMasterOrOfPartner(role: string, keyCloakId: string): Observable<BasicUser[]> {
    return this.http.get<BasicUserRepresentation[]>(
      API_URL + `/users/searchByPartnerOrMaster/${role}/${keyCloakId}`,
      {
        headers: new HttpHeaders({
          Accept: 'application/json'
        })
      }
    ).pipe(
      timeout(this.TIMEOUT_DURATION),
      map((basicUserRepresentations: BasicUserRepresentation[]) =>
        basicUserRepresentations.map(basicUserRepresentation =>
          BasicUserRepresentation.toModelEntity(basicUserRepresentation))
      ));
  }
}
