import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslatorService} from '../translation/translator.service';
import {Friendship} from './state/friendship';
import {FriendshipFunctions} from './state/friendship-functions';
import {takeUntil} from 'rxjs/operators';
import {DeleteFriendModalComponent} from './delete-friend-modal/delete-friend-modal.component';
import {BasicUser} from '../user/basic-user';
import {FriendshipService} from './state/friendship.service';
import {Observable, Subject} from 'rxjs';
import {CurrentUser} from '../user/current-user.service';
import {FriendshipQuery} from './state/friendship.query';
import {RoutingService} from '../routing/routing.service';
import {UserInviteOptionComponent} from '../user/user-invite-option/user-invite-option.component';
import {TestShareComponent} from '../test/test-share/test-share.component';
import {TestQuery} from '../test/state/test.query';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss']
})
export class FriendsComponent implements OnInit, OnDestroy {

  @ViewChild('deleteFriendModal')
    deleteFriendModal: DeleteFriendModalComponent;
  @ViewChild('userInviteOptionComponent')
    userInviteOptionComponent: UserInviteOptionComponent;
  @ViewChild('testShareModal')
    testShareModal: TestShareComponent;
  /**
   * Determines whether or not the friends list should be hidden.
   */
  searching: boolean = false;
  searchResult: BasicUser[] | undefined = undefined;
  searchFriendsResult: Friendship[] | undefined = undefined;
  hasFriends$: Observable<boolean>;
  loadingFriendships$: Observable<boolean>;
  clickedWelcome: boolean = false;
  clickedOnDelete: boolean = false;
  isLoading: { [key: string]: boolean } = {};
  private unsubscribe$: Subject<void> = new Subject<void>();
  private ongoingRequest: boolean = false;

  constructor(private friendshipService: FriendshipService,
              private friendshipQuery: FriendshipQuery,
              private currentUser: CurrentUser,
              public routingService: RoutingService,
              private testQuery: TestQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.friendshipService.revalidateFriendships().pipe(takeUntil(this.unsubscribe$)).subscribe();
    this.loadingFriendships$ = this.friendshipQuery.selectLoading();
    this.hasFriends$ = this.friendshipQuery.selectHasFriendships();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  clickWelcome(): void {
    this.clickedWelcome = true;
  }

  createFriendship(user: BasicUser): void {
    if (!this.ongoingRequest) {
      this.ongoingRequest = true;
      this.isLoading[user.keycloakId] = true;
      this.friendshipService.sendFriendshipInvitationTo(user)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (friendship) => {
            this.testQuery.selectTestsForCurrentUser().subscribe(test => {
              if (test.length && test[0].accessGranted) {
                this.testShareModal.show(friendship, test[0]);
                this.ongoingRequest = false;
                this.isLoading[user.keycloakId] = false;
              }
              else {
                this.ongoingRequest = false;
                this.isLoading[user.keycloakId] = false;
              }
            }
            );
          },
          () => {
            this.ongoingRequest = false;
            this.isLoading[user.keycloakId] = false;
          }
        );
    }
  }

  resendFriendshipRequest(friendship: Friendship): void {
    const friendId: string = friendship.userB.id;
    if (!this.ongoingRequest) {
      this.ongoingRequest = true;
      this.isLoading[friendId] = true;
      this.friendshipService.resendFriendshipInvitationTo(friendship)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.ongoingRequest = false;
            this.isLoading[friendId] = false;
          },
          () => {
            this.ongoingRequest = false;
            this.isLoading[friendId] = false;
          }
        );
    }
  }

  acceptFriendship(friendship: Friendship): void {
    const friendId: string = friendship.userA.id;
    if (!this.ongoingRequest) {
      this.ongoingRequest = true;
      this.isLoading[friendId] = true;
      this.friendshipService.acceptFriendship(friendship)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.testQuery.selectTestsForCurrentUser().subscribe(test => {
              if (test.length && test[0].accessGranted) {
                this.testShareModal.show(friendship, test[0]);
                this.ongoingRequest = false;
                this.isLoading[friendId] = false;
              }
              else {
                this.ongoingRequest = false;
                this.isLoading[friendId] = false;
              }
            });
          },
          () => {
            this.ongoingRequest = false;
            this.isLoading[friendId] = false;
          }
        );
    }
  }

  updateTestShare(friendship: Friendship): void {
    this.testQuery.selectTestsForCurrentUser().subscribe(test => {
      if (test.length && test[0].accessGranted) {
        this.testShareModal.show(friendship, test[0]);
      }
    });
  }

  declineFriendship(friendship: Friendship): void {
    if (!this.ongoingRequest) {
      this.ongoingRequest = true;
      this.friendshipService.declineFriendship(friendship)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.ongoingRequest = false;
          },
          () => {
            this.ongoingRequest = false;
          }
        );
      this.clickedOnDelete = false;
    }
  }

  deleteFriendship(friendship: Friendship): void {
    this.deleteFriendModal.show(friendship);
  }

  openRecommendations(): void {
    this.userInviteOptionComponent.show();
  }

  isCurrentUserInviter(friendship: Friendship): boolean {
    return this.currentUser.keycloakId === friendship.userA.id;
  }

  myTestName(friendship: Friendship): string  {
    return FriendshipFunctions.getMyTestName(friendship, this.currentUser.keycloakId);
  }

  __(key: string): string {
    return this.translatorService.translate('friends.' + key);
  }

}
