<div class="clr-row clr-justify-content-center">
  <div class="clr-col-xs-12 clr-col-sm-11 clr-col-md-10 clr-col-lg-9 clr-col-xl-8">

    <h2>Chart</h2>
    <div style="margin-bottom: 1em">
      <ng-container *ngFor="let b of button">
        <button (click)="onClick(b.filter)"
                [ngClass]="{'selected': variation === b.filter}"
                class="id37-btn id37-btn-bw id37-btn-flat">
          {{b.displayName}}
        </button>
      </ng-container>
    </div>

    <ng-container [ngSwitch]="variation">
      <app-scatter *ngSwitchCase="'scatter'"
                   [customerMotiveScore]="customerMotiveScore"
                   [customerName]="customerName">
      </app-scatter>

      <app-bubble *ngSwitchCase="'bubble'"
                  [customerMotiveScore]="customerMotiveScore"
                  [customerName]="customerName">
      </app-bubble>

      <app-polar *ngSwitchCase="'polar'"
                 [customerMotiveScore]="customerMotiveScore"
                 [customerName]="customerName">
      </app-polar>

      <app-radar *ngSwitchCase="'radar'"
                 [customerMotiveScore]="customerMotiveScore"
                 [customerName]="customerName">
      </app-radar>
    </ng-container>

  </div>
</div>

<!-- CLOSE -->
<div class="hide-on-print" id="close-sign">
  <img (click)="close()" alt="close sign" src="assets/icons/ID37_X.svg">
</div>



