export enum Id37Language {

  /*
  ISO 639 language codes.
   */

  de = 'de',
  en = 'en',
  ru = 'ru',
  nl = 'nl',
  fr = 'fr',
  es = 'es',
  it = 'it',
  ka = 'ka'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Id37Language {
  export const LIST: Id37Language[] = [
    Id37Language.de,
    Id37Language.en,
    Id37Language.ru,
    Id37Language.nl,
    Id37Language.fr,
    Id37Language.es,
    Id37Language.it,
    Id37Language.ka
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): Id37Language | undefined => {
    name = !!name ? name : '';
    const found = Id37Language.LIST
      .find((language: string) => language.toLowerCase() === name.toLowerCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access a language with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

