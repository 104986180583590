import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewChecked, AfterViewInit
} from '@angular/core';
import {ChatbotMessagePair, ChatbotMessagePairCreate} from '../state/chatbot-message-pair';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {ChatbotMessagePairService} from '../api-services/chatbot-message-pair.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Clipboard} from '@angular/cdk/clipboard';
import { trigger, state, style, animate, transition } from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-chatbot-chat-message',
  templateUrl: './chatbot-chat-message.component.html',
  styleUrls: ['./chatbot-chat-message.component.scss'],
  animations: [
    trigger('changeIcon', [
      state('initial', style({
        backgroundImage: 'url("assets/icons/ID37_Chatbot_Clipboard_Outline.svg")'
      })),
      state('copied', style({
        backgroundImage: 'url("assets/icons/ID37_Chatbot_Clipboard_Filled.svg")'
      })),
      transition('initial => copied', animate('2000ms')),
      transition('copied => initial', animate('2000ms'))
    ])
  ]
})
export class ChatbotChatMessageComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @Input()
    chatQuestion: ChatbotMessagePairCreate;

  @Input()
    chatMessage: ChatbotMessagePair;

  @Input()
    question: boolean = false;

  @Input()
    enableAutoscroll: boolean = false;

  @Output()
    update: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('container', {static: false})
    container: ElementRef;

  displayName: string = '';

  additionalFeedbackDialog: boolean = false;

  comment: string;

  isCopied: boolean = false;
  copyTimeout: NodeJS.Timeout | null = null;

  constructor(private currentUser: CurrentUser,
              private clipboard: Clipboard,
              private translatorService: TranslatorService,
              private chatbotMessagePairService: ChatbotMessagePairService) {
  }

  ngOnInit(): void {
    if (this.question) {
      this.displayName = this.currentUser.fullName;
    }
    else {
      this.displayName = 'Jay';
    }

    if (this.chatMessage !== undefined){
      if (this.chatMessage.comment) {
        this.comment = this.chatMessage.comment;
      }
    }
  }

  ngOnDestroy() {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
  }

  ngAfterViewChecked() {
    if (this.enableAutoscroll) {
      this.scroll();
    }
  }

  ngAfterViewInit() {
    this.scroll();
  }

  scroll() {
    (this.container.nativeElement as HTMLElement).scrollIntoView({block: 'end'});
  }

  rateAnswerPositive(): void {
    if (this.chatMessage.answer_rating === undefined) {
      return;
    }
    if (this.chatMessage.answer_rating === 1) {
      this.chatbotMessagePairService.rateAnswer(this.chatMessage.question_id, 0).subscribe();
      this.chatMessage.answer_rating = 0;
      return;
    }
    else {
      this.chatbotMessagePairService.rateAnswer(this.chatMessage.question_id, 1).subscribe();
      this.chatMessage.answer_rating = 1;
      this.additionalFeedbackDialog = true;
    }
  }

  rateAnswerNegative(): void {
    if (this.chatMessage.answer_rating === undefined) {
      return;
    }
    if (this.chatMessage.answer_rating === -1) {
      this.chatbotMessagePairService.rateAnswer(this.chatMessage.question_id, 0).subscribe();
      this.chatMessage.answer_rating = 0;
      return;
    }
    else {
      this.chatbotMessagePairService.rateAnswer(this.chatMessage.question_id, -1).subscribe();
      this.chatMessage.answer_rating = -1;
      this.additionalFeedbackDialog = true;
    }
  }

  commentAnswer(): void {
    this.chatbotMessagePairService.commentAnswer(this.chatMessage.question_id, this.comment).pipe(untilDestroyed(this)).subscribe();
    this.chatMessage.comment = this.comment;
    this.additionalFeedbackDialog = false;
  }

  copyAnswer(): void {
    this.clipboard.copy(this.chatMessage.answer);

    if (!this.isCopied) {
      this.isCopied = true;

      this.copyTimeout = setTimeout(() => {
        this.isCopied = false;
      }, 1000);
    }
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-message.' + key);
  }
}
