<svg [attr.width]="300"
     [attr.height]="90"
     [attr.x]="x"
     [attr.y]="y"
     viewBox="0 0 350 100"
     preserveAspectRatio="xMinYMin">

  <circle class="inner" [attr.cx]="50" [attr.cy]="50" r="20.0"/>

  <text [attr.fill]="isSingle ? '#ffffff' : '#000000'"
        text-anchor="middle" dominant-baseline="central"
        x="50" y="50">
    {{motiveScore.score}}
  </text>

  <text *ngIf="isLast"
        text-anchor="start" dominant-baseline="central"
        x="90" y="50">
    {{motiveName}}
  </text>

</svg>
