<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12 comparebox spacing-top">
        <div class="clr-row">
          <div *ngFor="let selectedCustomerAndTest of selectedCustomers"
               class="clr-col-lg-6 clr-col-md-6 clr-col-12">
            <div class="card">
              <div class="card-block">
                <div class="card-title">
                  {{selectedCustomerAndTest.selectedCustomer.fullName}}
                </div>
                <div class="card-text">
                  {{selectedCustomerAndTest.selectedCustomer.email}}
                </div>
              </div>
              <div class="card-footer">
                <a (click)="unselectCustomer(selectedCustomerAndTest.selectedCustomer)">
                  {{__('action.removeuser')}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="clr-row clr-justify-content-center compareaction">
          <div *ngIf="selectedCustomers.length > 1 && this.teamEnabled">
            <button (click)="toChart()" class="id37-btn-outline">
              Team
            </button>
          </div>
          <div *ngIf="selectedCustomers.length > 1" class="clr-col-12 action">
            <button (click)="compare()" class="id37-btn-outline" type="button">
              <clr-icon shape="download"></clr-icon>
              {{__('action.download')}}
            </button>
          </div>
        </div>
      </div>

      <app-customerlist (selectionEvents)="onSelection($event)"
                        [customers]="customersView"
                        [metaSearch]="metaSearch"
                        [mulitselect]="true"
                        class="clr-col-12 spacing-top">
      </app-customerlist>
    </div>
  </div>
</div>
