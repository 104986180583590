import {Friendship} from './friendship';
import {FriendshipRepresentation} from './friendship-representation';

export class FriendshipsRepresentation {

  public readonly friendships: FriendshipRepresentation[];

  static toModelEntity(friendshipsRepresentation: FriendshipsRepresentation, currentUserId: string): Friendship[] {
    return friendshipsRepresentation.friendships
      .map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, currentUserId));
  }

}
