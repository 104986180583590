import {KeycloakUserRepresentation} from './keycloak-user-representation';
import {Gender} from '../client/gender';
import {RegistrationSource} from './registration-source';
import {KeycloakUserFeatureFlag} from './keycloak-user-feature-flag';
import {Referrer} from './referrer';

export class KeycloakUser {

  private readonly _id: string;
  private readonly _email: string;
  private readonly _firstName: string;
  private readonly _lastName: string;
  private readonly _gender: Gender | null;
  private readonly _mobilePhone: string;
  private readonly _referrer: Referrer | null;
  private readonly _locale: string;
  private readonly _dateClientEula: Date | null;
  private readonly _dateMasterEula: Date | null;
  private readonly _registrationSource: RegistrationSource;
  private readonly _featureFlagStates: Map<KeycloakUserFeatureFlag, boolean>;

  constructor(id: string,
              email: string,
              firstName: string,
              lastName: string,
              gender: Gender | null,
              mobilePhone: string,
              referrer: Referrer | null,
              locale: string,
              dateClientEula: Date | null,
              dateMasterEula: Date | null,
              registrationSource: RegistrationSource,
              featureFlags: { [key: string]: boolean }) {
    this._id = id;
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._gender = gender;
    this._mobilePhone = mobilePhone;
    this._referrer = referrer;
    this._locale = locale;
    this._dateClientEula = dateClientEula;
    this._dateMasterEula = dateMasterEula;
    this._registrationSource = registrationSource;
    this._featureFlagStates = new Map<KeycloakUserFeatureFlag, boolean>();
    for (const key in featureFlags) {
      if (Object.prototype.hasOwnProperty.call(featureFlags, key)) {
        this._featureFlagStates.set(KeycloakUserFeatureFlag.fromName(key), featureFlags[key]);
      }
    }
  }

  get id(): string {
    return this._id;
  }

  get email(): string {
    return this._email;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get gender(): Gender | null {
    return this._gender;
  }

  get mobilePhone(): string {
    return this._mobilePhone;
  }

  get referrer(): Referrer | null {
    return this._referrer;
  }

  get locale(): string {
    return this._locale;
  }

  get dateClientEula(): Date | null {
    return this._dateClientEula;
  }

  get dateMasterEula(): Date | null {
    return this._dateMasterEula;
  }

  get registrationSource(): RegistrationSource {
    return this._registrationSource;
  }

  static buildFrom(keycloakUserRepresentation: KeycloakUserRepresentation): KeycloakUser {
    return new KeycloakUser(
      keycloakUserRepresentation.id,
      keycloakUserRepresentation.email,
      keycloakUserRepresentation.firstName,
      keycloakUserRepresentation.lastName,
      keycloakUserRepresentation.gender == null ? null : Gender.fromName(keycloakUserRepresentation.gender),
      keycloakUserRepresentation.mobilePhone,
      keycloakUserRepresentation.referrer == null ? null : Referrer.fromName(keycloakUserRepresentation.referrer),
      keycloakUserRepresentation.locale,
      keycloakUserRepresentation.dateClientEula == null
        ? null : new Date(keycloakUserRepresentation.dateClientEula),
      keycloakUserRepresentation.dateMasterEula == null
        ? null : new Date(keycloakUserRepresentation.dateMasterEula),
      RegistrationSource.fromName(keycloakUserRepresentation.registrationSource),
      keycloakUserRepresentation.featureFlags
    );
  }

  isFeatureAllowed(feature: KeycloakUserFeatureFlag): boolean {
    return this._featureFlagStates.get(feature) === true;
  }

  isFeatureNotAllowed(feature: KeycloakUserFeatureFlag): boolean {
    return !this.isFeatureAllowed(feature);
  }

}
