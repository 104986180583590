import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CurrentUser} from '../user/current-user.service';
import {RecommendationInfo} from './recommendation-info';
import {RecommendationCount} from './recommendation-count';
import {RecommendationCountRepresentation} from './recommendation-count-representation';

const API_URL = environment.API_URL;
const friendsAndFamily: boolean = environment.FEATURE_RECOMMEND_FRIENDS_AND_FAMILY;
const friendsAndFamilyForMasterClients: boolean = environment.FEATURE_RECOMMEND_FRIENDS_AND_FAMILY_FOR_MASTER_CLIENTS;
const friendsAndFamilyForPartnerClients: boolean = environment.FEATURE_RECOMMEND_FRIENDS_AND_FAMILY_FOR_PARTNER_CLIENTS;

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  constructor(private http: HttpClient,
              private currentUser: CurrentUser) {
  }

  loadRecommendationInfo(recommendationType: string): Observable<RecommendationInfo> {
    return this.getCountOfRecommendationsLeft(recommendationType).pipe(
      map(count => {
        // Count all special recommendations left...
        let recommendationCount = 0;
        recommendationCount += count.recommendationsLeft;
        // Other recommendation types go here.

        return {
          canRecommend: true, // Recommendations could be shut off completely...
          canRecommendFriendsAndFamily: this.areFriendsAndFamilyRecommendationsAllowed(),
          recommendationsLeft: recommendationCount,
          friendsAndFamilyRecommendationsLeft: count.recommendationsLeft
        };
      })
    );
  }

  // noinspection RedundantIfStatementJS
  areFriendsAndFamilyRecommendationsAllowed(): boolean {
    return friendsAndFamily
      && !(this.currentUser.hasPartner() && !friendsAndFamilyForPartnerClients)
      && !(this.currentUser.hasMaster() && !friendsAndFamilyForMasterClients);
  }

  getCountOfRecommendationsLeft(recommendationType: string): Observable<RecommendationCount> {
    return this.http.get<RecommendationCountRepresentation>(
      API_URL + '/recommendations/' + recommendationType + '/left',
      {
        headers: new HttpHeaders({
          Accept: 'application/json;version=1'
        })
      }
    ).pipe(
      map((recommendationCountRepresentation: RecommendationCountRepresentation) =>
        RecommendationCount.buildFrom(recommendationCountRepresentation))
    );
  }

}
