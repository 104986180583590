import {Component, OnInit, ViewChild} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';
import {TeamService} from './state/team.service';
import {TranslatorService} from '../translation/translator.service';
import {TeamModalComponent} from './team-modal/team-modal.component';
import {Team} from './state/team';
import {NewTeamRepresentation} from './state/new-team-representation';
import {TeamQuery} from './state/team.query';
import {Observable} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Id37Router} from '../id37-router';
import {UserInviteOptionComponent} from '../user/user-invite-option/user-invite-option.component';
import {ProfilePictureLoadService} from '../profile-picture/profile-picture-load-service';
import {take} from 'rxjs/operators';
import {ProfilePictureQuery} from '../profile-picture/profile-picture-query';
import {ProfilePictureStore} from '../profile-picture/profile-picture-store';
import {MotiveScoreFunctions, MotiveScores} from '../test/state/motive-score';
import {TeamUser} from './state/team-user';

export type Layout = 'desktop' | 'tablet' | 'mobile';

export interface UpdateTeam {
  ssid: string;
  team: NewTeamRepresentation;
}

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  @ViewChild('userInviteOptionComponent')
    userInviteOptionComponent: UserInviteOptionComponent;

  @ViewChild('teamModalComponent')
    teamModalComponent: TeamModalComponent;

  loadingTeams$: Observable<boolean>; // loading status while while getting teams from API call
  hasTeams$: Observable<boolean>; // loading status while checking if user has any teams
  loadingProfilePictures$: Observable<boolean>;
  loadingProfilePictures: boolean;
  loading: boolean;

  firstName: string = '';
  teams: Team[];
  selectedTeam: Team;
  team: UpdateTeam;
  maxTestvariation: number;

  layout: Layout = 'desktop';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private currentUser: CurrentUser,
    private teamService: TeamService,
    private profilePictureQuery: ProfilePictureQuery,
    private profilePictureStore: ProfilePictureStore,
    private profilePictureLoadService: ProfilePictureLoadService,
    private teamQuery: TeamQuery,
    private translatorService: TranslatorService,
    private router: Id37Router
  ) {
  }

  ngOnInit(): void {
    this.firstName = this.currentUser.firstName;
    this.teamService.loadTeams().subscribe();
    this.loadingTeams$ = this.teamQuery.selectLoading();
    this.hasTeams$ = this.teamQuery.hasTeams();
    this.loadingProfilePictures$ = this.profilePictureQuery.selectLoading();
    this.teamQuery.selectAll().subscribe((teams: Team[]) => this.teams = teams);

    this.teamQuery.selectSelectedTeam().subscribe((selectedTeam: Team) => {
      this.loadTeamMembersPicture(selectedTeam);
      this.selectedTeam = selectedTeam;
      this.maxTestvariation = this.calculateMaxVariation(selectedTeam);
    });

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetPortrait,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
        Breakpoints.WebPortrait,
        Breakpoints.WebLandscape
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.HandsetPortrait || Breakpoints.HandsetLandscape]) {
          this.layout = 'mobile';
        }
        if (state.breakpoints[Breakpoints.TabletPortrait || Breakpoints.HandsetLandscape]) {
          this.layout = 'tablet';
        }
        if (state.breakpoints[Breakpoints.WebPortrait || Breakpoints.WebLandscape]) {
          this.layout = 'desktop';
        }
      });
  }

  onCreateTeam(): void {
    this.teamModalComponent.createTeam();
  }

  onEditTeam(s: Team): void {
    this.teamModalComponent.editTeam(s);
  }

  onCreatedTeam(team: NewTeamRepresentation): void {
    if (!this.loading) {
      this.loading = true;
      this.teamService.createTeam(team).subscribe(() => {
        this.loading = false;
        this.teamModalComponent.closeModal();
      });
    }
  }

  onEditedTeam(updateTeam: UpdateTeam): void {
    if (!this.loading) {
      this.loading = true;
      this.teamService.updateTeam(updateTeam.ssid, updateTeam.team).subscribe(() => {
        this.loading = false;
        this.teamModalComponent.closeModal();
      });
    }
  }

  onDeleteTeam(team: Team): void {
    this.loading = true;
    this.teamModalComponent.closeModal();
    this.teamService.deleteTeam(team).subscribe(() => this.loading = false);
    this.teamService.changedSelectedTeam(this.teamQuery.selectNewTeamAfterDelete(team.teamId));
  }

  routeToFriends(): void {
    this.router.routeToFriends();
  }

  openRecommendations(): void {
    this.userInviteOptionComponent.show();
  }

  public __(key: string): string {
    return this.translatorService.translate('team.' + key);
  }

  private calculateMaxVariation(team: Team): number {
    if (!!team) {
      const results: MotiveScores[] = team.users.map((teamUser: TeamUser) => teamUser.result.motiveScores);
      return MotiveScoreFunctions.calculateMaxNumberOfMotives(results);
    }
    return 14;
  }

  // load profile pictures of all team members when selecting
  private loadTeamMembersPicture(team: Team): void {
    if (!!team) {
      this.profilePictureStore.setLoading(true);
      team.users.map(
        user => user.keycloakId).forEach(
        id => {
          if (!this.profilePictureQuery.hasLoadedProfilePicture(id)) {
            this.profilePictureLoadService.loadProfilePicture(id).pipe(take(1)).subscribe();
          }
        });
      this.profilePictureStore.setLoading(false);
    }
  }
}
