export const bubbleColorsBorder: string[] = [
  'hsl(201, 100%,51%)',
  'hsl(209, 99%, 51%)',
  'hsl(217, 99%, 50%)',
  'hsl(224, 97%, 50%)',
  'hsl(229, 89%, 49%)',
  'hsl(234, 80%, 48%)',
  'hsl(241, 73%, 47%)',
  'hsl(249, 81%, 41%)',
  'hsl(258, 91%, 36%)',
  'hsl(264, 99%, 33%)',
  'hsl(270, 99%, 34%)',
  'hsl(275, 99%, 34%)',
  'hsl(281, 99%, 34%)',
  'hsl(286, 99%, 35%)',
  'hsl(292, 94%, 32%)',
  'hsl(302, 88%, 30%)',
  'hsl(311, 84%, 32%)',
  'hsl(321, 80%, 34%)',
  'hsl(330, 77%, 36%)',
  'hsl(339, 74%, 38%)',
  'hsl(353, 65%, 45%)',
  'hsl(4,   66%, 49%)',
  'hsl(12,  81%, 50%)',
  'hsl(18,  96%, 51%)',
  'hsl(23,  98%, 51%)',
  'hsl(29,  98%, 50%)',
  'hsl(34,  98%, 50%)',
  'hsl(38,  98%, 50%)',
  'hsl(40,  98%, 50%)',
  'hsl(43,  98%, 49%)',
  'hsl(45,  98%, 49%)',
  'hsl(49,  90%, 49%)',
  'hsl(54,  83%, 49%)',
  'hsl(61,  75%, 50%)',
  'hsl(67,  81%, 54%)',
  'hsl(71,  74%, 53%)',
  'hsl(75,  67%, 51%)',
  'hsl(80,  62%, 49%)',
  'hsl(85,  60%, 47%)',
  'hsl(91,  57%, 45%)'
];


export const bubbleColorsInner: string[] = [
  // Floor(borderColor/0.55)
  'hsl(201, 100%,90%)',
  'hsl(209, 99%, 90%)',
  'hsl(217, 99%, 90%)',
  'hsl(224, 97%, 90%)',
  'hsl(229, 89%, 90%)',
  'hsl(234, 80%, 90%)',
  'hsl(241, 73%, 90%)',
  'hsl(249, 81%, 90%)',
  'hsl(258, 91%, 90%)',
  'hsl(264, 99%, 90%)',
  'hsl(270, 99%, 90%)',
  'hsl(275, 99%, 90%)',
  'hsl(281, 99%, 90%)',
  'hsl(286, 99%, 90%)',
  'hsl(292, 94%, 90%)',
  'hsl(302, 88%, 90%)',
  'hsl(311, 84%, 90%)',
  'hsl(321, 80%, 90%)',
  'hsl(330, 77%, 90%)',
  'hsl(339, 74%, 90%)',
  'hsl(353, 65%, 90%)',
  'hsl(4,   66%, 90%)',
  'hsl(12,  81%, 90%)',
  'hsl(18,  96%, 90%)',
  'hsl(23,  98%, 90%)',
  'hsl(29,  98%, 90%)',
  'hsl(34,  98%, 90%)',
  'hsl(38,  98%, 90%)',
  'hsl(40,  98%, 90%)',
  'hsl(43,  98%, 90%)',
  'hsl(45,  98%, 90%)',
  'hsl(49,  90%, 90%)',
  'hsl(54,  83%, 90%)',
  'hsl(61,  75%, 90%)',
  'hsl(67,  81%, 90%)',
  'hsl(71,  74%, 90%)',
  'hsl(75,  67%, 90%)',
  'hsl(80,  62%, 90%)',
  'hsl(85,  60%, 90%)',
  'hsl(91,  57%, 90%)',
];

