import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BasicUserInvitationRepresentation} from './basic-user-invitation-representation';
import {CurrentUser} from '../current-user.service';
import {MasterUserInvitationRepresentation} from './master-user-invitation-representation';
import {environment} from '../../../environments/environment';
import {Friendship} from '../../friend/state/friendship';
import {FriendshipRepresentation} from '../../friend/state/friendship-representation';
import {FriendshipService} from '../../friend/state/friendship.service';
import {map, tap} from 'rxjs/operators';
import {RecommendationUserInvitationRepresentation} from './recommendation-user-invitation-representation';
import {RecommendationType} from '../../recommendation/recommendation-type';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class UserInvitationService {

  constructor(private currentUser: CurrentUser,
              private friendshipService: FriendshipService,
              private http: HttpClient) {
  }

  recommendUser(email: string, firstName: string, lastName: string): Observable<void> {
    return this.http.post<void>(
      API_URL + '/recommendations',
      new BasicUserInvitationRepresentation(email.trim(), firstName.trim(), lastName.trim()),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    );
  }

  recommendAsFriend(email: string, firstName: string, lastName: string,
                    recommendationType: RecommendationType): Observable<Friendship> {
    return this.http.post<FriendshipRepresentation>(
      API_URL + '/recommendations/as-friend',
      new RecommendationUserInvitationRepresentation(
        email.trim(),
        firstName.trim(),
        lastName.trim(),
        recommendationType),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      map((friendshipRepresentation: FriendshipRepresentation) =>
        FriendshipRepresentation.toModelEntity(friendshipRepresentation, this.currentUser.keycloakId)),
      tap((friendship: Friendship) =>
        this.friendshipService.storeFriendship(friendship))
    );
  }

  inviteUsersByMasterOrPartner(invitationRepresentations: MasterUserInvitationRepresentation[]): Observable<void> {
    if (!(this.currentUser.hasRole('master') || this.currentUser.hasRole('partner'))) {
      console.error('Forbidden. You are not a master or a partner.');
      return;
    }
    return this.http.post<void>(
      API_URL + '/users/invitations',
      invitationRepresentations.map(representation => new MasterUserInvitationRepresentation(
        representation.email.trim(),
        representation.firstName.trim(),
        representation.lastName.trim(),
        representation.groupName.trim(),
        representation.variation
      )),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    );
  }

}
