<div class="selection-wrapper">
  <form [formGroup]="listForm" *ngIf="loaded && !!members">
    <cdk-virtual-scroll-viewport *ngIf="loaded" itemSize="70" class="item-list" formArrayName="items"
                                 style="height: 230px">

      <div class="item-header">
        <span class="searchKey profile id37-text5">{{searchWord}}</span>

        <ng-container *ngIf="searchResult.length">
        <span class="id37-text5 text-light">
          {{this.selectedUsers}}/{{this.searchResult.length}} {{__('members')}}
        </span>
          <id37-checkbox (click)="onSelectAll(allSelect.checked)" #allSelect></id37-checkbox>
        </ng-container>
      </div>
      <hr>

      <ng-container *cdkVirtualFor="let item of items;let idx = index; templateCacheSize: 0" [formGroupName]="idx">
        <div class="item-container">
          <app-profile-picture [userId]="this.searchResult[idx].keycloakId"></app-profile-picture>
          <div class="id37-text3">{{this.searchResult[idx].firstName}} {{this.searchResult[idx].lastName}} </div>
          <id37-checkbox [(checked)]="item.get('checked').value" (click)="onCheckbox(idx, item.get('checked').value)"></id37-checkbox>
        </div>
        <hr style="width: 90%; margin: 0">
      </ng-container>

    </cdk-virtual-scroll-viewport>
  </form>

  <div *ngIf="!loaded" style="padding-top: 4em">
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>

