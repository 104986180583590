import {PositionRepresentation} from './position-representation';
import {Product} from '../../product/product';

export class Position {

  private readonly _product: Product;
  private readonly _displayName: string;
  private readonly _amount: number;
  private readonly _netPriceAsNumber: number;
  private readonly _netPriceAsMoney: string;
  private readonly _discountAsNumber: number;
  private readonly _discountAsPercentage: string;
  private readonly _discountedFromDiscountCode: boolean;
  private readonly _vatAsNumber: number;
  private readonly _vatAsPercentage: string;
  private readonly _finalPriceAsNumber: number;
  private readonly _finalPriceAsMoney: string;

  constructor(product: Product,
              displayName: string,
              amount: number,
              netPriceAsNumber: number,
              netPriceAsMoney: string,
              discountAsNumber: number,
              discountAsPercentage: string,
              discountedFromDiscountCode: boolean,
              vatAsNumber: number,
              vatAsPercentage: string,
              finalPriceAsNumber: number,
              finalPriceAsMoney: string) {
    this._product = product;
    this._displayName = displayName;
    this._amount = amount;
    this._netPriceAsNumber = netPriceAsNumber;
    this._netPriceAsMoney = netPriceAsMoney;
    this._discountAsNumber = discountAsNumber;
    this._discountAsPercentage = discountAsPercentage;
    this._discountedFromDiscountCode = discountedFromDiscountCode;
    this._vatAsNumber = vatAsNumber;
    this._vatAsPercentage = vatAsPercentage;
    this._finalPriceAsNumber = finalPriceAsNumber;
    this._finalPriceAsMoney = finalPriceAsMoney;
  }

  get product(): Product {
    return this._product;
  }

  get displayName(): string {
    return this._displayName;
  }

  get amount(): number {
    return this._amount;
  }

  get netPriceAsNumber(): number {
    return this._netPriceAsNumber;
  }

  get netPriceAsMoney(): string {
    return this._netPriceAsMoney;
  }

  get discountAsNumber(): number {
    return this._discountAsNumber;
  }

  get discountAsPercentage(): string {
    return this._discountAsPercentage;
  }

  get discountedFromDiscountCode(): boolean {
    return this._discountedFromDiscountCode;
  }

  get vatAsNumber(): number {
    return this._vatAsNumber;
  }

  get vatAsPercentage(): string {
    return this._vatAsPercentage;
  }

  get finalPriceAsNumber(): number {
    return this._finalPriceAsNumber;
  }

  get finalPriceAsMoney(): string {
    return this._finalPriceAsMoney;
  }

  static buildFrom(positionRepresentation: PositionRepresentation): Position {
    return new Position(
      Product.fromName(positionRepresentation.product),
      positionRepresentation.displayName,
      positionRepresentation.amount,
      Number(positionRepresentation.netPriceAsNumber.replace(',', '.')),
      positionRepresentation.netPriceAsMoney,
      Number(positionRepresentation.discountAsNumber.replace(',', '.')),
      positionRepresentation.discountAsPercentage,
      positionRepresentation.discountedFromDiscountCode,
      Number(positionRepresentation.vatAsNumber.replace(',', '.')),
      positionRepresentation.vatAsPercentage,
      Number(positionRepresentation.finalPriceAsNumber.replace(',', '.')),
      positionRepresentation.finalPriceAsMoney
    );
  }

}
