<h3>{{__('motive.headline')}}</h3>

<div class="motive-wrapper id37-text2 text-light">
  <div class="motive-text" *ngFor="let element of motiveTextHtml" [innerHTML]="element"></div>
</div>

<h3>{{__('reflexion.headline')}}</h3>
<div class="id37-text2 text-light">
  <div class="headline-space-bottom" [innerHTML]="__('reflexion.text')"></div>
  <h4>
    <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
    <span [innerHTML]="__('reflexion.listItem1.headline')"></span>
  </h4>
  <ul class="bold-items headline-space-bottom">
    <li [innerHTML]="__('reflexion.listItem1_1')"></li>
    <li [innerHTML]="__('reflexion.listItem1_2')"></li>
    <li [innerHTML]="__('reflexion.listItem1_3')"></li>
  </ul>
  <h4>
    <img class="orange-arrow" src="assets/icons/arrow_orange_18x18.svg" alt="orange arrow">
    <span [innerHTML]="__('reflexion.listItem2.headline')"></span>
  </h4>
  <ul class="bold-items headline-space-bottom">
    <li [innerHTML]="__('reflexion.listItem2_1')"></li>
    <li [innerHTML]="__('reflexion.listItem2_2')"></li>
    <li [innerHTML]="__('reflexion.listItem2_3')"></li>
    <li [innerHTML]="__('reflexion.listItem2_4')"></li>
  </ul>
  <div [innerHTML]="__('reflexion.attitude')"></div>
</div>
