import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewChatbotDocument } from '../../chatbot/state/new-chatbot-document';
import {ChatbotDocument} from '../../chatbot/state/chatbot-document';

@Injectable({
  providedIn: 'root'
})
export class ChatbotDocumentsFormService {

  form: FormGroup = new FormGroup({
    document_id: new FormControl(''),
    title: new FormControl('', Validators.required),
    summary: new FormControl(''),
    content: new FormControl('', Validators.required),
    embedding: new FormControl(''),
    token_length: new FormControl(''),
  });
  lastErrorState: boolean = true;
  error: boolean = true;

  constructor() {
  }

  private _edit: boolean = false;

  get edit(): boolean {
    return this._edit;
  }

  set edit(value: boolean) {
    this._edit = value;
  }



  populateForm(chatbotDocument: ChatbotDocument) {
    this.form.setValue({
      document_id: chatbotDocument.document_id || '',
      title: chatbotDocument.title,
      summary: chatbotDocument.summary || '',
      content: chatbotDocument.content,
      embedding: chatbotDocument.embedding || '',
      token_length: chatbotDocument.token_length || ''
    });
  }

  initializeFormGroup() {
    this.form.setValue({
      document_id: '',
      title: '',
      summary: '',
      content: '',
      embedding: '',
      token_length: '',
    });
  }

  getForm(formName: string) {
    return this.form.get(formName);
  }

  checkErrors(): boolean {
    const hasErrors = this.hasErrors();
    if (hasErrors !== this.lastErrorState) {
      this.lastErrorState = hasErrors;
      this.error = hasErrors; // this.valid.emit(!hasErrors);
    }
    return hasErrors;
  }

  private hasErrors(): boolean {
    return!!this.getForm('title').errors
      || !!this.getForm('content').errors
      || !!this.getForm('summary').errors;
  }
}
