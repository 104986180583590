import {UnzerPaymentRepresentation} from './unzer-payment-representation';

export class UnzerPayment {

  private readonly _paymentId: string;

  constructor(paymentId: string) {
    this._paymentId = paymentId;
  }

  get paymentId(): string {
    return this._paymentId;
  }

  static buildFrom(unzerPaymentRepresentation: UnzerPaymentRepresentation) {
    return new UnzerPayment(
      unzerPaymentRepresentation.paymentId
    );
  }

}
