import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CropperComponent} from 'angular-cropperjs';

@Component({
  selector: 'app-profile-picture-editor',
  templateUrl: './profile-picture-editor.component.html',
  styleUrls: ['./profile-picture-editor.component.scss']
})
export class ProfilePictureEditorComponent implements OnInit {

  @Input()
    imageData: string;

  @ViewChild('image')
  public image: ElementRef;

  @ViewChild('angularCropper')
  public angularCropper: CropperComponent;

  config: any;

  constructor() {
    this.config = {
      aspectRatio: 1
    };
  }

  ngOnInit() {
  }

}
