import {Injectable} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const de: CountryAndNationalitySet = require('./countries-and-nationalities_de.json');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const en: CountryAndNationalitySet = require('./countries-and-nationalities_en.json');

interface CountryAndNationalityOption {
  aliases: [];
  alpha2: string;
  name: string;
  nationality: string;
}

interface CountryAndNationalitySet {
  [key: string]: CountryAndNationalityOption;
}

interface CountryAndNationalityArraySet {
  [key: string]: CountryAndNationalityOption[];
}

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  /* eslint-disable object-shorthand */
  countries: { [key: string]: CountryAndNationalitySet } = {
    de: de,
    en: en,
    ru: en
  };

  readonly supportedLocales = ['de', 'en', 'ru'];
  readonly fallbackLocale = 'en';

  countriesSortedByName: CountryAndNationalityArraySet = this.computeCountriesSortedByName();
  countriesSortedByNationality: CountryAndNationalityArraySet = this.computeCountriesSortedByNationality();

  constructor(private currentUser: CurrentUser) {
  }

  computeCountriesSortedByName(): CountryAndNationalityArraySet {
    const sorted: CountryAndNationalityArraySet = {};
    this.supportedLocales.forEach(locale =>
      sorted[locale] = this.sortObjectValues(this.countries[locale], this.by((a) => a.name)));
    return sorted;
  }

  computeCountriesSortedByNationality(): CountryAndNationalityArraySet {
    const sorted: CountryAndNationalityArraySet = {};
    this.supportedLocales.forEach(locale =>
      sorted[locale] = this.sortObjectValues(this.countries[locale], this.by((a) => a.nationality)));
    return sorted;
  }

  sortObjectValues<T>(object: { [key: string]: T }, sortFunction: (a: T, b: T) => number): T[] {
    return Object.values(object).sort(sortFunction);
  }

  by<T>(stringExtractor: (x: T) => string): (a: T, b: T) => -1 | 0 | 1 {
    return (a: T, b: T) => this.lexicographicComparer(stringExtractor(a), stringExtractor(b));
  }

  lexicographicComparer(a: string, b: string): -1 | 0 | 1 {
    const x = a.toLowerCase();
    const y = b.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  }

  getCurrentLocale(): string {
    return this.supportedLocales.includes(this.currentUser.locale) ?
      this.currentUser.locale :
      this.fallbackLocale;
  }

  getNameByAlpha2(alpha2: string): string {
    const countries = this.countries[this.getCurrentLocale()];
    return !!countries[alpha2] ? countries[alpha2].name : '';
  }

  getNationalityByAlpha2(alpha2: string): string {
    const countries = this.countries[this.getCurrentLocale()];
    return !!countries[alpha2] ? countries[alpha2].nationality : '';
  }

  getCountriesAsChoicesOptions(): {value: string; label: string}[] {
    const allCountries: {value: string; label: string}[] = [];
    this.countriesSortedByName[this.getCurrentLocale()].forEach(option => {
      allCountries.push({value: option.alpha2, label: option.name});
      option.aliases.forEach(alias => allCountries.push({value: option.alpha2, label: alias}));
    });
    return allCountries;
  }

  getNationalitiesAsChoicesOptions(): {value: string; label: string}[] {
    return this.countriesSortedByNationality[this.getCurrentLocale()]
      .filter(option => option.nationality.length > 0)
      .map(option => ({value: option.alpha2, label: option.nationality}));
  }

}
