import {ActiveState, EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {ChatMessage, chatMessageComparator} from './chat-message';
import {CurrentUser} from '../../user/current-user.service';

export interface StoredContactChatMessages {
  contactUserId: string;
  chatMessages: ChatMessage[];
  allLoaded: boolean;
  unreadCount: number;
  delayCallbacks: boolean;
}

export interface ChatMessagesState extends EntityState<StoredContactChatMessages, string>, ActiveState<string> {
  ui: {
    open: boolean;
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'chatMessage', idKey: 'contactUserId'})
export class ChatMessagesStore extends EntityStore<ChatMessagesState> {

  constructor(private currentUser: CurrentUser) {
    // Default state.
    super({
      ui: {
        open: false
      }
    });
    this.akitaPreAddEntity = this.akitaPreAddEntity.bind(this);
    this.akitaPreUpdateEntity = this.akitaPreUpdateEntity.bind(this);
    this.updateUiOpenState(false);
  }

  updateAllLoadedState(contactUserId: string, allLoaded: boolean) {
    this.update(contactUserId, {allLoaded});
  }

  updateUiOpenState(open: boolean) {
    this.update({
      ui: {
        open
      }
    });
  }

  ensureEntryExists(activeContactUserId: string): void {
    if (!!this.getValue() && !!this.getValue().ids && !this.getValue().ids.includes(activeContactUserId)) {
      this.upsert(activeContactUserId, (_: StoredContactChatMessages) => ({
        contactUserId: activeContactUserId,
        chatMessages: [],
        unreadCount: 0,
        allLoaded: false,
        delayCallbacks: false
      }));
    }
  }

  unsetActiveContact(): void {
    super.setActive(null);
  }

  setActiveContact(activeContactUserId: string): void {
    this.ensureEntryExists(activeContactUserId);
    super.setActive(activeContactUserId);
  }

  // TODO: Do not iterate over all messages to get the unreadCount!
  // TODO: Update the counter every time a message is added or removed from the store.

  akitaPreAddEntity(next: StoredContactChatMessages): StoredContactChatMessages {
    if (!Object.isFrozen(next.chatMessages) && !next.delayCallbacks) {
      next.unreadCount = this.countUnread(next.chatMessages);
      next.chatMessages.sort(chatMessageComparator);
    }
    return next;
  }

  akitaPreUpdateEntity(prev: StoredContactChatMessages, next: StoredContactChatMessages): StoredContactChatMessages {
    if (!Object.isFrozen(next.chatMessages) && !next.delayCallbacks) {
      next.unreadCount = this.countUnread(next.chatMessages);
      next.chatMessages.sort(chatMessageComparator);
    }
    return next;
  }

  private countUnread(messages: ChatMessage[]): number {
    let count = 0;
    for (const message of messages) {
      // Only count my unread messages, not also the ones my contact did not read.
      if (message.receiverKeycloakId === this.currentUser.keycloakId) {
        if (typeof message.read === typeof undefined) {
          count++;
        }
      }
    }
    return count;
  }

}
