<app-collapsible-list>
  <app-collapsible-item>
    <app-collapsible-header [title]="scienceText.heading"></app-collapsible-header>
    <app-collapsible-body>
      <div [innerHTML]="scienceText.mainText" class="id37-text2 text-light"></div>
      <h5 [innerHTML]="scienceText.graphicHeading" class="graphic-heading"></h5>
      <div class="report-element graphic-gauss spacing-top">
        <app-graphic-gauss [graphicGaussText]="graphicGaussText"></app-graphic-gauss>
      </div>
    </app-collapsible-body>
  </app-collapsible-item>
</app-collapsible-list>
