import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Friendship} from '../../friend/state/friendship';
import {TranslatorService} from '../../translation/translator.service';
import {ChatMessagesQuery} from '../state/chat-messages.query';

@Component({
  selector: 'app-chat-contacts',
  templateUrl: './chat-contacts.component.html',
  styleUrls: ['./chat-contacts.component.scss']
})
export class ChatContactsComponent implements OnInit {

  chats$: Observable<Friendship[]>;
  contacts$: Observable<Friendship[]>;

  constructor(private chatMessagesQuery: ChatMessagesQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.chats$ = this.chatMessagesQuery.selectContactsWithMessagesSortedDesc();
    this.contacts$ = this.chatMessagesQuery.selectContactsWithoutMessages();
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
