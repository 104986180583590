import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';
import {Id37Router} from '../../../../id37-router';

@Component({
  selector: 'app-test-access-granted-message',
  templateUrl: './test-access-granted-message.component.html',
  styleUrls: ['./test-access-granted-message.component.scss']
})
export class TestAccessGrantedMessageComponent implements OnInit {

  @Input()
    notification: Notification;

  messagePartA: string = '';
  report: string = '';
  messagePartB: string = '';

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private id37router: Id37Router) {
  }

  ngOnInit(): void {
    this.computeMessage();
  }

  goToProfile(): void {
    this.id37router.routeToReport(this.currentUser.keycloakId, this.notification.data.testId as string);
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
    this.report = this.__('report');
    this.messagePartB = this.__('partB');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key);
  }

}
