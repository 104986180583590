import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ListToItemCommunicationService} from '../services/list-to-item-communication.service';
import {ItemToHeaderAndBodyCommunicationService} from '../services/item-to-header-and-body-communication.service';

@Component({
  selector: 'app-collapsible-item',
  templateUrl: './collapsible-item.component.html',
  styleUrls: ['./collapsible-item.component.scss'],
  providers: [ItemToHeaderAndBodyCommunicationService]
})
export class CollapsibleItemComponent {

  @Input()
    expanded = false;

  @Output()
    stateChange = new EventEmitter<boolean>();

  constructor(private listToItemCom: ListToItemCommunicationService,
              private itemToHeaderAndBodyCom: ItemToHeaderAndBodyCommunicationService) {
    this.listToItemCom.addItem(this);
    this.listToItemCom.onLevelShouldBePropagated$.subscribe(parentIsOdd => this.propagateLevelAdjustment(parentIsOdd));

    this.itemToHeaderAndBodyCom.itemStateChanged(this); // Propagates the current expanded state (after initialization).
    this.itemToHeaderAndBodyCom.onHeaderClick$.subscribe(() => {
      this.toggle();
    });
  }

  toggle() {
    this.setExpanded(!this.expanded);
  }

  setExpanded(newState: boolean, shouldNotifyParent: boolean = true): void {
    this.expanded = newState;
    this.itemToHeaderAndBodyCom.itemStateChanged(this);
    if (shouldNotifyParent) {
      this.listToItemCom.itemStateChange(this);
    }
    this.stateChange.emit(newState);
  }

  propagateLevelAdjustment(parentIsOdd: boolean): void {
    this.itemToHeaderAndBodyCom.propagateLevelAdjustment(parentIsOdd);
  }

}
