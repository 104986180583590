import {MasterReportRepresentation} from './master-report-representation';
import {UserProductPrice} from '../account/user-product-price';
import {UserProductPriceRepresentations} from '../account/user-product-price-representation';


export class MasterReport {

  private readonly _clientCount: number;
  private readonly _finishedTestsCount: number;
  private readonly _userProductPrices: UserProductPrice[];

  constructor(clientCount: number, finishedTestsCount: number, userProductPrices: UserProductPrice[]) {
    this._clientCount = clientCount;
    this._finishedTestsCount = finishedTestsCount;
    this._userProductPrices = userProductPrices;
  }

  get clientCount(): number {
    return this._clientCount;
  }

  get finishedTestsCount(): number {
    return this._finishedTestsCount;
  }

  get getPriceForId37Test(): string {
    return this._userProductPrices.filter(userProductPrices => userProductPrices.product === 'ID37_TEST')
      .pop().netPrice;
  }

  static buildFrom(masterReportRepresentation: MasterReportRepresentation) {
    return new MasterReport(
      masterReportRepresentation.clientCount,
      masterReportRepresentation.finishedTestsCount,
      masterReportRepresentation.userProductPrices
        .map(userProductPriceRepresentation =>
          UserProductPriceRepresentations.toModelEntity(userProductPriceRepresentation))
    );
  }

}
