import {SimpleDiscountCodeRepresentation} from './simple-discount-code-representation';

export class SimpleDiscountCode {

  private readonly _code: string;
  private readonly _discountAsNumber: number;
  private readonly _discountAsPercentage: string;

  constructor(code: string,
              discountAsNumber: number,
              discountAsPercentage: string) {
    this._code = code;
    this._discountAsNumber = discountAsNumber;
    this._discountAsPercentage = discountAsPercentage;
  }

  get code(): string {
    return this._code;
  }

  get discountAsNumber(): number {
    return this._discountAsNumber;
  }

  get discountAsPercentage(): string {
    return this._discountAsPercentage;
  }

  static buildFrom(simpleDiscountCodeRepresentation: SimpleDiscountCodeRepresentation): SimpleDiscountCode {
    return new SimpleDiscountCode(
      simpleDiscountCodeRepresentation.code,
      Number(simpleDiscountCodeRepresentation.discountAsNumber.replace(',', '.')),
      simpleDiscountCodeRepresentation.discountAsPercentage
    );
  }

}
