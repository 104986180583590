import {ChatbotDocumentRepresentation} from './chatbot-document-representation';

export class ChatbotDocument {
  public _show_more: boolean;

  private readonly _document_id: number;
  private readonly _title: string;
  private readonly _summary: string | undefined;
  private readonly _content: string;
  private readonly _embedding: number[];
  private readonly _token_length: number;


  constructor(document_id: number, title: string, summary: string, content: string, embedding: number[],
              token_length: number, show_more: boolean) {
    this._document_id = document_id;
    this._title = title;
    this._summary = summary;
    this._content = content;
    this._embedding = embedding;
    this._token_length = token_length;
    this._show_more = false;
  }


  get document_id(): number {
    return this._document_id;
  }

  get title(): string {
    return this._title;
  }

  get summary(): string {
    return this._summary;
  }

  get content(): string {
    return this._content;
  }

  get embedding(): number[] {
    return this._embedding;
  }

  get token_length(): number {
    return this._token_length;
  }

  get show_more(): boolean {
    return this._show_more;
  }

  set show_more(showmore: boolean) {
    this._show_more = showmore;
  }

  static buildFrom(
    chatbotDocumentRepresentation: ChatbotDocumentRepresentation
  ): ChatbotDocument {
    return new ChatbotDocument(
      Number(chatbotDocumentRepresentation.document_id),
      chatbotDocumentRepresentation.title,
      chatbotDocumentRepresentation.summary,
      chatbotDocumentRepresentation.content,
      chatbotDocumentRepresentation.embedding,
      chatbotDocumentRepresentation.token_length,
      false
    );
  }
}
