import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Report} from './types/report';

export type ReportState = EntityState<Report>;

@Injectable({
  providedIn: 'root'
})
@StoreConfig({
  name: 'report'
})
// The key is a string and consists of the userId and testId, concatenated with a ":".
export class ReportStore extends EntityStore<ReportState, Report, string> {

  constructor() {
    super();
  }

}
