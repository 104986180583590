import {Position} from '../position/position';
import {CartRepresentation} from './cart-representation';
import {PositionsSummary} from '../position/positions-summary';
import {SimpleDiscountCode} from '../discountcode/simple-discount-code';
import {Product} from '../../product/product';

export class Cart {

  private readonly _positions: Position[];
  private readonly _positionsSummary: PositionsSummary;
  private readonly _discountCode: SimpleDiscountCode | undefined;

  constructor(positions: Position[],
              positionsSummary: PositionsSummary,
              simpleDiscountCode: SimpleDiscountCode | undefined) {
    this._positions = positions;
    this._positionsSummary = positionsSummary;
    this._discountCode = simpleDiscountCode;
  }

  get positions(): Position[] {
    return this._positions;
  }

  get positionsSummary(): PositionsSummary {
    return this._positionsSummary;
  }

  get discountCode(): SimpleDiscountCode | undefined {
    return this._discountCode;
  }

  containsProduct(product: Product): boolean {
    return this.positions.some(position => position.product === product);
  }

  static buildFrom(cartRepresentation: CartRepresentation): Cart {
    return new Cart(
      cartRepresentation.positions.map(positionRepresentation => Position.buildFrom(positionRepresentation)),
      PositionsSummary.buildFrom(cartRepresentation.positionsSummary),
      cartRepresentation.discountCode === null ? undefined : SimpleDiscountCode.buildFrom(cartRepresentation.discountCode)
    );
  }

}
