<div class="clr-row clr-justify-content-center margin-0" style="margin-top: 4em">
  <div class="clr-col-10">

    <app-collapsible-list [type]="'accordion'">
      <app-collapsible-item>
        <app-collapsible-header>collapsible 1</app-collapsible-header>
        <app-collapsible-body>b1</app-collapsible-body>
      </app-collapsible-item>

      <app-collapsible-item>
        <app-collapsible-header>inner-accordion</app-collapsible-header>
        <app-collapsible-body>


          <app-collapsible-list [type]="'accordion'">
            <app-collapsible-item>
              <app-collapsible-header>inner collapsible 1</app-collapsible-header>
              <app-collapsible-body>foo</app-collapsible-body>
            </app-collapsible-item>
            <app-collapsible-item>
              <app-collapsible-header>inner collapsible 2</app-collapsible-header>
              <app-collapsible-body>bar</app-collapsible-body>
            </app-collapsible-item>
            <app-collapsible-item>
              <app-collapsible-header>inner inner collapsible</app-collapsible-header>
              <app-collapsible-body>

                <app-collapsible-list [type]="'expandable'">
                  <app-collapsible-item>
                    <app-collapsible-header>wooooooah</app-collapsible-header>
                    <app-collapsible-body>:)</app-collapsible-body>
                  </app-collapsible-item>
                </app-collapsible-list>

              </app-collapsible-body>
            </app-collapsible-item>
          </app-collapsible-list>

        </app-collapsible-body>
      </app-collapsible-item>

      <div *ngIf="!showCollapsible3">Loading...</div>
      <app-collapsible-item *ngIf="showCollapsible3">
        <app-collapsible-header>collapsible 3</app-collapsible-header>
        <app-collapsible-body>b3</app-collapsible-body>
      </app-collapsible-item>

      <app-collapsible-item>
        <app-collapsible-header>inner-expandable</app-collapsible-header>
        <app-collapsible-body>

          <app-collapsible-list [type]="'expandable'">
            <app-collapsible-item>
              <app-collapsible-header>inner collapsible 1</app-collapsible-header>
              <app-collapsible-body>foo</app-collapsible-body>
            </app-collapsible-item>
            <app-collapsible-item>
              <app-collapsible-header>inner collapsible 2</app-collapsible-header>
              <app-collapsible-body>bar</app-collapsible-body>
            </app-collapsible-item>
          </app-collapsible-list>

        </app-collapsible-body>
      </app-collapsible-item>

    </app-collapsible-list>

  </div>
</div>
