import {Component, Input} from '@angular/core';
import {BuyBookText} from '../types/buy-book-text';

@Component({
  selector: 'app-buy-book',
  templateUrl: './buy-book.component.html',
  styleUrls: ['./buy-book.component.scss']
})
export class BuyBookComponent {

  @Input()
    buyBookText: BuyBookText;

  constructor() {
  }

}
