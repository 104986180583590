export class Pair<K, V> {

  private readonly _key: K;
  private readonly _value: V;

  constructor(key: K,
              value: V) {
    this._key = key;
    this._value = value;
  }

  get key(): K {
    return this._key;
  }

  get value(): V {
    return this._value;
  }

}
