import {Component, Input} from '@angular/core';
import {InterpretationText} from '../types/interpretation-text';

@Component({
  selector: 'app-interpretation',
  templateUrl: './interpretation.component.html',
  styleUrls: ['./interpretation.component.scss']
})
export class InterpretationComponent {

  @Input()
    interpretationText: InterpretationText;

  @Input()
    masterId = '';

  constructor() {
  }

}
