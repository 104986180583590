import {Component, OnInit} from '@angular/core';
import {MetricsService} from './metrics.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  metrics$: Observable<string>;

  constructor(private metricsService: MetricsService) {
  }

  ngOnInit() {
    this.metrics$ = this.metricsService.getMetrics();
  }

}
