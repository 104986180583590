import {Component, OnInit} from '@angular/core';
import {MasterReport} from '../../master/master-report';
import {TranslatorService} from '../../translation/translator.service';
import {take} from 'rxjs/operators';
import {MasterService} from '../../master/master.service';

@Component({
  selector: 'app-master-report',
  templateUrl: './master-report.component.html',
  styleUrls: ['./master-report.component.scss']
})
export class MasterReportComponent implements OnInit {

  masterReport: MasterReport | undefined = undefined;

  constructor(private translatorService: TranslatorService,
              private masterService: MasterService) {
  }

  ngOnInit() {
    this.masterService.loadReport()
      .pipe(take(1))
      .subscribe((report: MasterReport) => {
        this.masterReport = report;
      });
  }

  __(key: string): string {
    return this.translatorService.translate('my-account.' + key);
  }

}
