<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <h2 id="title">
          {{__('title')}}
        </h2>
        <div class="id37-text1 ">
          <div id="paragraph">
            <p>
              {{__('description')}}
            </p>
            <p>
              {{__('description_2')}}
            </p>
          </div>
          <textarea id="prompt"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="5"
                    [(ngModel)]="currentPrompt"
          >{{currentPrompt}}
          </textarea>
        </div>
          <button (click)="onUpdate()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2">
            <img alt="plus" ngSrc="assets/icons/add_onButton.svg" height="20" width="21"/>
            <span>{{__('btn_update')}}</span>
          </button>
          <button (click)="getSystemPrompt()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2">
            <img alt="plus" ngSrc="assets/icons/close_onButton.svg" height="21" width="21"/>
            <span>{{__('btn_cancel')}}</span>
          </button>
      </div>
    </div>
  </div>
</div>
