import {Component, OnInit} from '@angular/core';
import {EmailSettingsService} from '../state/email-settings.service';
import {TranslatorService} from '../../../translation/translator.service';
import {EmailSettingsRepresentation} from '../state/email-settings.representation';

@Component({
  selector: 'app-email-setting-management',
  templateUrl: './email-setting-management.component.html',
  styleUrls: ['./email-setting-management.component.scss']
})
export class EmailSettingManagementComponent implements OnInit {
  emailSettings: EmailSettingsRepresentation;

  constructor(
    private emailSettingsService: EmailSettingsService,
    private translatorService: TranslatorService
  ) {}

  ngOnInit(): void {
    this.emailSettingsService.loadEmailSettings();
    this.emailSettingsService.getEmailSettings().subscribe(emailSettings => this.emailSettings = emailSettings);
  }

  updateInformAboutUnreadChatMessages(newState: boolean): void {
    this.emailSettingsService.updateUnreadMessagesSettings(newState);
  }

  updateInformAboutProductInformation(newState: boolean): void {
    this.emailSettingsService.updateProductInformationSettings(newState);
  }

  updateInformAboutNetworking(newState: boolean): void {
    this.emailSettingsService.updateNetworkingSettings(newState);
  }


  updateInformAboutAdvertising(newState: boolean): void {
    this.emailSettingsService.updateAdvertisingSettings(newState);
  }

  __(key: string): string {
    return this.translatorService.translate('emailSettings.' + key);
  }
}
