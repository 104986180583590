<div [formGroup]="getChatbotDocumentFormService().form"
     [ngClass] ="{'errors': getChatbotDocumentFormService().checkErrors()}">

  <div class="clr-form-group cb-doc-form-group">
    <label for="title-label">{{__("title")}}</label>
    <input formControlName="title" id="title-label" type="text">
    <div *ngIf="getChatbotDocumentFormService().getForm('title').errors?.required"
         class="alert danger">
      {{__("not-empty")}}
    </div>
    <label for="title-label">{{__("summary")}}</label>
    <textarea formControlName="summary" id="summary-label" type="text"></textarea>
    <div *ngIf="getChatbotDocumentFormService().getForm('summary').errors?.required"
         class="alert danger">
      {{__("not-empty")}}
    </div>
    <label for="title-label">{{__("content")}}</label>
    <textarea formControlName="content" id="content-label" type="text" rows="15"></textarea>
    <div *ngIf="getChatbotDocumentFormService().getForm('content').errors?.required"
         class="alert danger">
      {{__("not-empty")}}
    </div>
  </div>
</div>

<div class="ok-cancel-group">
  <button (click) ="onSubmit()" [disabled] ="getChatbotDocumentFormService().error" class = "id37-btn-outline"
          type = "button"> {{__("button-ok")}}</button>
  <button (click)="onCancel()" class="id37-btn id37-btn-secondary" type="button"> {{__("button-cancel")}}</button>
</div>
