import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../../../translation/translator.service';
import {Notification} from '../../../state/notification';
import {CurrentUser} from '../../../../user/current-user.service';
import {Id37Router} from '../../../../id37-router';
import {FriendshipQuery} from '../../../../friend/state/friendship.query';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Friendship} from '../../../../friend/state/friendship';
import {FriendshipFunctions} from '../../../../friend/state/friendship-functions';
import {AccountDataQuery} from '../../../../account/account-data-query';

@Component({
  selector: 'app-friendship-invitation-message',
  templateUrl: './friendship-invitation-message.component.html',
  styleUrls: ['./friendship-invitation-message.component.scss']
})
export class FriendshipInvitationMessageComponent implements OnInit, OnDestroy {

  @Input()
    notification: Notification;

  private unsubscribe$: Subject<void> = new Subject<void>();

  friendName: string = '...';
  messagePartA: string = '';
  friendGender: string;

  constructor(private accountDataQuery: AccountDataQuery,
              private friendshipQuery: FriendshipQuery,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private router: Id37Router) {
  }

  ngOnInit(): void {
    this.friendshipQuery.selectFriendship(this.notification.data.friendshipId as number)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((friendship: Friendship | undefined) => {
        if (!!friendship) {
          this.friendName = FriendshipFunctions.getFriendFullName(friendship, this.currentUser.keycloakId);
          this.accountDataQuery.selectAccountData(
            FriendshipFunctions.getFriendUserId(friendship, this.currentUser.keycloakId))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
              this.friendGender = data.personalData.gender;
              this.computeMessage();
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToFriend(): void {
    this.router.routeToFriends(this.notification.data.friendshipId as number);
  }

  computeMessage(): void {
    this.messagePartA = this.__('partA');
  }

  __(key: string): string {
    return this.translatorService.translate('notification.' + this.notification.type + '.message.' + key)
      .replace(/\*ACCUSATIVE.N\*/g, this.translatorService.translate(
        'possessivePronoun.accusative.n',
        this.currentUser.locale,
        this.friendGender));
  }

}
