import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ChoicesOptions} from '../../../user-data/choices-options';
import {TranslatorService} from '../../../translation/translator.service';
import {CurrentUser} from '../../../user/current-user.service';
import {TestQuery} from '../../state/test.query';
import {TestService} from '../../state/test.service';
import {Test} from '../../state/test';
import {PersonalDataAfterTest} from '../../state/personal-data-after-test';
import {Choice} from '../../../select/state/choices-types';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit, OnDestroy {

  testId: string;
  accessGranted = false;
  name: string;
  personalDataForm: FormGroup;
  academicDegreeChoices: Choice[];
  professionChoices: Choice[];
  industryChoices: Choice[];
  private unsubscribe$ = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private testService: TestService,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.academicDegreeChoices = ChoicesOptions.academicDegrees(this.currentUser.locale);
    this.professionChoices = ChoicesOptions.professions(this.currentUser.locale);
    this.industryChoices = ChoicesOptions.industries(this.currentUser.locale);

    this.route.params.subscribe(params => {
      this.testId = params.testId;
    });

    this.checkIfUserHasAccess(() => {
      this.name = this.currentUser.fullName;
      this.buildPersonalDataAfterTestForm();
      this.loadPersonalDataAfterTest();
    }, () => {
      console.error('Forbidden!');
      this.router.navigate(['/dashboard']);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkIfUserHasAccess(allowed: () => void, notAllowed: () => void): void {
    this.testQuery.selectTestForCurrentUser(this.testId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (test: Test) => {
          if (!test.finished) {
            this.router.navigate(['/my-test', this.testId]);
          }
          else {
            this.accessGranted = true;
            allowed();
          }
        },
        () => {
          this.accessGranted = false;
          notAllowed();
        }
      );
  }

  buildPersonalDataAfterTestForm(): void {
    this.personalDataForm = this.formBuilder.group({
      city: ['', Validators.required],
      academicDegree: ['', Validators.required],
      profession: ['', Validators.required],
      industry: ['', Validators.required],
      company: ['', Validators.required],
      mobilePhone: ['', Validators.required]
    });
  }

  loadPersonalDataAfterTest(): void {
    this.testService.loadPersonalDataAfterTest()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(personalData => {
        this.personalDataForm.controls.city.setValue(personalData.city);
        this.personalDataForm.controls.academicDegree.setValue(personalData.academicDegree);
        this.personalDataForm.controls.profession.setValue(personalData.profession);
        this.personalDataForm.controls.industry.setValue(personalData.industry);
        this.personalDataForm.controls.company.setValue(personalData.company);
        this.personalDataForm.controls.mobilePhone.setValue(personalData.mobilePhone);
      });
  }

  savePersonalDataAfterTest(): void {
    const personalData: PersonalDataAfterTest = {
      city: this.personalDataForm.controls.city.value,
      academicDegree: this.personalDataForm.controls.academicDegree.value,
      profession: this.personalDataForm.controls.profession.value,
      industry: this.personalDataForm.controls.industry.value,
      company: this.personalDataForm.controls.company.value,
      mobilePhone: this.personalDataForm.controls.mobilePhone.value
    };
    const subscription = this.testService.savePersonalDataAfterTest(personalData).subscribe(() => subscription.unsubscribe());
  }

  __(key: string): string {
    return this.translatorService.translate('my-test.thank-you.' + key);
  }

}
