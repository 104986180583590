import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CollapsibleModule} from '../collapsible/collapsible.module';
import {Id37Module} from '../id37/id37.module';
import {BuyBookComponent} from './buy-book/buy-book.component';
import {ClientInfoComponent} from './client-info/client-info.component';
import {ContactComponent} from './contact/contact.component';
import {ExplanationMotivesComponent} from './explanation-motives/explanation-motives.component';
import {FindMasterComponent} from './find-master/find-master.component';
import {GraphicGaussComponent} from './graphic-gauss/graphic-gauss.component';
import {HowMotivesWorkComponent} from './how-motives-work/how-motives-work.component';
import {InterpretationComponent} from './interpretation/interpretation.component';
import {IntroComponent} from './intro/intro.component';
import {MotiveComponent} from './motive/motive.component';
import {MotivesDescriptionComponent} from './motives-description/motives-description.component';
import {PrintDocumentComponent} from './print-document/print-document.component';
import {ProfileApplicationComponent} from './profile-application/profile-application.component';
import {ProfileExampleComponent} from './profile-example/profile-example.component';
import {ProfileIntroComponent} from './profile-intro/profile-intro.component';
import {ProfileNarrowingsComponent} from './profile-narrowings/profile-narrowings.component';
import {ProfileSpecialComponent} from './profile-special/profile-special.component';
import {ProfileComponent} from './profile/profile.component';
import {ReportContentComponent} from './report-content/report-content.component';
import {ReportComponent} from './report.component';
import {ScienceComponent} from './science/science.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {DirectivesModule} from '../directives/directives.module';
import {KeyGraphicModule} from '../graphics/key-graphic/key-graphic.module';

@NgModule({
  imports: [
    CommonModule,
    KeyGraphicModule,
    CollapsibleModule,
    Id37Module,
    DirectivesModule
  ],
  declarations: [
    ReportComponent,
    ClientInfoComponent,
    BuyBookComponent,
    ContactComponent,
    ExplanationMotivesComponent,
    FindMasterComponent,
    GraphicGaussComponent,
    HowMotivesWorkComponent,
    InterpretationComponent,
    IntroComponent,
    MotivesDescriptionComponent,
    PrintDocumentComponent,
    ProfileApplicationComponent,
    ProfileExampleComponent,
    ProfileIntroComponent,
    ProfileNarrowingsComponent,
    ProfileSpecialComponent,
    ProfileComponent,
    ScienceComponent,
    MotiveComponent,
    SidebarComponent,
    ReportContentComponent
  ],
  exports: [
    ReportComponent
  ]
})
export class ReportModule {
}
