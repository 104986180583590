import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatbotErrorService {
  private errorOccurredSource = new BehaviorSubject<boolean>(false);
  private errorMessageSource = new BehaviorSubject<string>('');

  // eslint-disable-next-line @typescript-eslint/member-ordering
  errorOccurred$ = this.errorOccurredSource.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  errorMessage$ = this.errorMessageSource.asObservable();

  setErrorOccurred(errorOccurred: boolean) {
    // console.log('ES new value: ' + errorOccurred);
    this.errorOccurredSource.next(errorOccurred);
  }

  setErrorMessage(errorMessage: string) {
    // console.log('ES new msg: ' + errorMessage);
    this.errorMessageSource.next(errorMessage);
  }
}
