export enum OrderError {

  ADDRESS_INVALID = 'ADDRESS_INVALID',
  NO_POSITIONS = 'NO_POSITIONS',
  PAYMENT_NOT_FINISHED = 'PAYMENT_NOT_FINISHED'

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OrderError {
  export const LIST: OrderError[] = [
    OrderError.ADDRESS_INVALID, OrderError.NO_POSITIONS, OrderError.PAYMENT_NOT_FINISHED
  ];
  export const STRING_LIST: string[] = LIST;

  export const fromName = (name: string | null): OrderError | undefined => {
    name = !!name ? name : '';
    const found = OrderError.LIST.find((error: string) => error.toUpperCase() === name.toUpperCase());
    if (typeof found === typeof undefined) {
      console.error('Tried to access an order error with an unknown name: "' + name + '"');
      return undefined;
    }
    return found;
  };

}

