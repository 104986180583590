import {BasicUserInvitationRepresentation} from './basic-user-invitation-representation';

export class MasterUserInvitationRepresentation extends BasicUserInvitationRepresentation {

  public readonly groupName: string;
  public readonly variation: string | undefined;

  constructor(email: string, firstName: string, lastName: string, groupName: string, variation: string | undefined) {
    super(email, firstName, lastName);
    this.groupName = groupName;
    this.variation = variation;
  }

}
