import {Component, Input} from '@angular/core';
import {ProfileNarrowingsText} from '../types/profile-narrowings-text';

@Component({
  selector: 'app-profile-narrowings',
  templateUrl: './profile-narrowings.component.html',
  styleUrls: ['./profile-narrowings.component.scss']
})
export class ProfileNarrowingsComponent {

  @Input()
    profileNarrowingsText: ProfileNarrowingsText;

  constructor() {
  }

}
