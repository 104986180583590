import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {DotClickEvent} from './key-graphic-motive-score/key-graphic-motive-score.component';
import {MotiveScore, MotiveScoreFunctions, MotiveScores} from '../../../test/state/motive-score';
import {TestVariation} from '../../../test/state/test-variation';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-key-graphic]',
  templateUrl: './key-graphic.component.html',
  styleUrls: ['./key-graphic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KeyGraphicComponent implements OnInit, OnChanges {

  @Input()
    results: MotiveScores[];

  // This input may be used to only show motives used in a certain test variation.
  @Input()
    variation: TestVariation | undefined = undefined;

  @Input()
    selectedMotiveScore: MotiveScore;

  @Output()
    tooltip: EventEmitter<DotClickEvent> = new EventEmitter<DotClickEvent>();

  minimumNumberOfMotives: number = 0;
  normalizedResults: MotiveScores[];

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.calculateNormalizedResults();
  }

  calculateNormalizedResults(): void {
    this.minimumNumberOfMotives = MotiveScoreFunctions.calculateMaximalNumberOfCommonMotives(this.results, this.variation);
    this.normalizedResults = this.results.map((motiveScores) =>
      MotiveScoreFunctions.normalizeMotiveScores(motiveScores, this.minimumNumberOfMotives));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.variation && changes.variation.currentValue !== changes.variation.previousValue ||
      !!changes.results && changes.results.currentValue !== changes.results.previousValue) {
      this.calculateNormalizedResults();
      this.cdr.markForCheck();
    }
  }

  getDotClass(i: number, first, last): string {
    if (i === 0 && first === last) {
      return 'dot_red';
    }
    else if (i === 1) {
      return 'dot_green';
    }
    // else if (i == 2 || (i == 0 && first != last))
    return 'dot_blue';
  }

  getOpacity(motiveScore: MotiveScore): number {
    if (!this.selectedMotiveScore) {
      return 1;
    }
    return ((motiveScore.motiveIndex === this.selectedMotiveScore.motiveIndex
        && motiveScore.score === this.selectedMotiveScore.score)
      ? 1 : 0.5
    );
  }

}
