

<div class="sticky-header">

  <div class="download-motive-variation-wrapper hide-on-print">
    <div id="download-button" [attr.aria-describedby]="__('tooltip.download-icon')">
      <svg class="clickable" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
           (click)="downloadExcelForTeam()" data-test="download-button">
        <g id="00:-Design-System" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="ID37---Platform-Design-System" transform="translate(-120.000000, -3227.000000)">
            <g transform="translate(120.000000, 3227.000000)">
              <path
                  d="M4.774,15.164 C4.347,15.164 4,15.51 4,15.938 L4,18.218 C4,19.202 4.809,20 5.806,20 L18.194,20 C19.191,20 20,19.202 20,18.218 L20,15.938 C20,15.51 19.653,15.164 19.226,15.164 C18.798,15.164 18.452,15.51 18.452,15.938 L18.452,18.218 C18.452,18.359 18.336,18.473 18.194,18.473 L5.806,18.473 C5.664,18.473 5.548,18.359 5.548,18.218 L5.548,15.938 C5.548,15.51 5.202,15.164 4.774,15.164 Z M15.611,9.931 L12.774,12.449 L12.774,4 L11.226,4 L11.226,12.449 L8.389,9.931 L7.353,11.066 L11.482,14.731 C11.778,14.994 12.222,14.994 12.518,14.731 L16.647,11.066 L15.611,9.931 Z"
                  id="download-icon" fill="#C0C2C3"></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div class="motive-variation-buttons-wrapper ">
      <div *ngIf="maxTestVariation > 14" (click)="changeTestvariation(14)"
            class="motive-variation-button" [ngClass]="{'selected': selectedVariation === 14}"
           [attr.aria-describedby]="__('tooltip.motive-variation-14')"><span>14</span></div>
      <div *ngIf="maxTestVariation > 14" (click)="changeTestvariation(15)"
           class="motive-variation-button" [ngClass]="{'selected': selectedVariation === 15}"
           [attr.aria-describedby]="__('tooltip.motive-variation-15')"><span>15</span></div>
      <div *ngIf="maxTestVariation === 16" (click)="changeTestvariation(16)"
           class="motive-variation-button" [ngClass]="{'selected': selectedVariation === 16}"
           [attr.aria-describedby]="__('tooltip.motive-variation-16')"><span>16</span></div>
    </div>

  </div>
    <div class="hide-on-print" id="reset-button" [attr.aria-describedby]="__('tooltip.reset-icon')">
      <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
           [ngClass]="{'clickable': changed}" (click)="reset()">
        <g id="00:-Design-System" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="ID37---Platform-Design-System" transform="translate(-320.000000, -3227.000000)">
            <g id="reset-icon" transform="translate(320.000000, 3227.000000)" fill="#C0C2C3" stroke="#C0C2C3" stroke-width="0.2">
              <path
                  d="M9.948,5.00022663 C13.484,4.97322663 16.325,7.36122663 16.898,10.8392266 C17.147,12.3502266 16.955,13.6422266 16.662,14.6142266 L18.353,13.8822266 L19,15.3032266 L15.575,16.7872266 C15.086,16.9982266 14.517,16.7902266 14.281,16.3132266 L12.828,13.3752266 L14.264,12.6992266 L15.079,14.3462266 C15.346,13.5412266 15.544,12.4222266 15.324,11.0862266 C14.872,8.34222663 12.684,6.53522663 9.96,6.55622663 C7.016,6.57822663 4.594,8.97322663 4.594,12.0002266 C4.594,15.0072266 7.316,17.4442266 9.954,17.4442266 C10.394,17.4442266 10.751,17.7932266 10.751,18.2222266 C10.751,18.6522266 10.394,19.0002266 9.954,19.0002266 C6.436,19.0002266 3,15.8662266 3,12.0002266 C3,8.15422663 6.092,5.03022663 9.948,5.00022663 Z"
                  id="Update---16px"
                  transform="translate(11.000000, 12.000113) scale(-1, 1) translate(-11.000000, -12.000113) "></path>
            </g>
          </g>
        </g>
      </svg>
    </div>

<div class="graphic-header-grid">
  <app-team-graphic-header [layout]="layout"></app-team-graphic-header>
</div>

</div>

<div class="motives-wrapper">
  <!-- somehow needed this div otherwise firefox goes crazy -->
  <app-team-graphic-motives
      (orderChange)="listChanged($event)"
      [layout]="layout"
      [members]="team">
  </app-team-graphic-motives>
</div>
