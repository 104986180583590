import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ChatMessage} from '../state/chat-message';
import {Friendship} from '../../friend/state/friendship';
import {CurrentUser} from '../../user/current-user.service';
import {TranslatorService} from '../../translation/translator.service';
import {FriendshipFunctions} from '../../friend/state/friendship-functions';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnInit {

  @ViewChild('editInput')
    editInput: ElementRef<HTMLTextAreaElement>;

  @Input()
    contact: Friendship;

  @Input()
    previousChatMessage: ChatMessage | undefined;

  @Input()
    chatMessage: ChatMessage;

  @Output()
    update: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.own')
    own: boolean = false;

  @HostBinding('class.continuation')
    continuation: boolean = false;

  senderName: string = '';

  editModeInputFocused: boolean = false;
  editModeSendWithEnter: boolean = true;
  editMode: boolean = false;
  editedMessage: string = '';

  constructor(private currentUser: CurrentUser,
              private translatorService: TranslatorService) {
  }

  @HostListener('window:keydown', ['$event'])
  keyEventOverrideDefaults(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.editMode && this.editModeInputFocused
      && this.editModeSendWithEnter && !event.shiftKey) {
      // Prevent, that enter adds an additional newline before sending the new message.
      event.preventDefault();
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter'
      && this.editMode && this.editModeInputFocused
      && this.editModeSendWithEnter && !event.shiftKey
      && this.editedMessage.length !== 0) {
      this.confirmEdit();
    }
  }

  ngOnInit(): void {
    this.own = this.chatMessage.senderKeycloakId === this.currentUser.keycloakId;
    if (this.own) {
      this.senderName = FriendshipFunctions.getMyFullName(this.contact, this.currentUser.keycloakId);
    }
    else {
      this.senderName = FriendshipFunctions.getFriendFullName(this.contact, this.currentUser.keycloakId);
    }
    if (!!this.previousChatMessage) {
      this.continuation = this.previousChatMessage.senderKeycloakId === this.chatMessage.senderKeycloakId;
    }
  }

  enableEditMode(): void {
    this.editMode = true;
    this.editedMessage = this.chatMessage.message;
    setTimeout(() => {
      this.editInput.nativeElement.focus();
    }, 0);
  }

  disableEditMode(): void {
    this.editMode = false;
    this.editedMessage = '';
  }

  confirmEdit(): void {
    this.update.emit(this.editedMessage);
    this.editMode = false;
  }

  __(key: string): string {
    return this.translatorService.translate('chat.' + key);
  }

}
