import {Component, Input, OnInit} from '@angular/core';
import {BubbleScore, BubbleScoreFunctions, HiddenBubbleScore} from '../../../../../../team/state/bubble-score';
import {Layout} from '../../../../../../team/team.component';
import {TeamFunctions} from '../../../../../../team/state/team-functions';
import {TranslatorService} from '../../../../../../translation/translator.service';
import {ProfilePictureQuery} from '../../../../../../profile-picture/profile-picture-query';
import {Observable} from 'rxjs';
import {ProfilePicture} from '../../../../../../profile-picture/profile-picture';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-team-graphic-bubble',
  templateUrl: './team-graphic-bubble.component.html',
  styleUrls: ['./team-graphic-bubble.component.scss']
})
export class TeamGraphicBubbleComponent implements OnInit {

  @Input() bubbleScore: BubbleScore;

  @Input() amount: number;

  @Input() hiddenBubbleScores: HiddenBubbleScore;

  @Input() navClass: boolean = false;

  @Input() x: number = 0;

  @Input() y: number = 0;

  @Input() layout: Layout;

  hasProfilePicture$: Observable<ProfilePicture>;
  name: string = 'XX';
  bubbleWidth: string;
  bubbleHeight: string;
  borderColor: string;
  fillColor: string;
  nameBubble: boolean;
  tooltipUsername: string = '';

  constructor(private translatorService: TranslatorService,
              private profilePictureQuery: ProfilePictureQuery) {
  }

  ngOnInit(): void {
    this.computeName();
    this.computeBubbleSize();
    this.computeBubbleColor();
  }

  computeBubbleColor(): void {
    if (!this.hiddenBubbleScores) {
      const bubbleColors: string[] = BubbleScoreFunctions.computeBubbleColor(
        this.bubbleScore.colorIndex, this.amount);
      this.borderColor = bubbleColors[0];
      this.fillColor = bubbleColors[1];
      this.nameBubble = true;
      this.hasProfilePicture$ = this.profilePictureQuery
        .selectProfilePictureWithoutLoading(this.bubbleScore.keycloakId);
    }
    else {
      const bubbleColors: string[] = BubbleScoreFunctions.computeBubbleColor(0, this.amount, true);
      this.borderColor = bubbleColors[0];
      this.fillColor = bubbleColors[1];
    }
  }

  computeBubbleSize(): void {
    if (this.layout === 'desktop') {
      this.bubbleWidth = '3.4'; // %
      this.bubbleHeight = '40'; // %
    }
    if (this.layout === 'tablet') {
      this.bubbleWidth = '3.9';
      this.bubbleHeight = '24';
    }
    if (this.layout === 'mobile') {
      this.bubbleWidth = '5.6';
      this.bubbleHeight = '12.5';
    }
  }

  getStyles(): any {
    /* eslint-disable */
    return {
      'width': this.bubbleWidth + '%',
      'height': this.bubbleHeight + '%',
      'border': '1.9px solid ' + this.borderColor,
      'background-color': this.fillColor,
      'left': this.x + '%',
      'top': this.y + '%'
    };
    /* eslint-enable */
  }

  __(key: string): string {
    return this.translatorService.translate('motive.' + key.toLowerCase());
  }

  computeName(): void {
    if (!this.hiddenBubbleScores) {
      this.name = TeamFunctions.getNameInitials(this.bubbleScore.firstName, this.bubbleScore.lastName);
      this.tooltipUsername = `${this.bubbleScore.firstName} ${this.bubbleScore.lastName}` +
        `\n${this.__(this.bubbleScore.motive.motive)}: ${this.bubbleScore.motive.score}`;
    }
    else {
      this.name = `+${this.hiddenBubbleScores.members.length}`;
      const names = [];
      this.hiddenBubbleScores.members.slice(-37).forEach(
        member => {
          names.push(member.firstName + ' ' + member.lastName + '\n');
        }
      );
      this.tooltipUsername = names.join('') + `${this.__(this.hiddenBubbleScores.members[0].motive.motive)}: `
        + `${this.hiddenBubbleScores.members[0].motive.score}`;
    }
  }
}
