import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {TestService} from './test.service';
import {Observable} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {TestIds, TestIdsState, TestIdStore} from './test-id.store';
import {TestStore} from './test.store';

@Injectable({providedIn: 'root'})
export class TestIdQuery extends QueryEntity<TestIdsState, TestIds, string> {

  constructor(protected store: TestIdStore,
              private testStore: TestStore,
              private testService: TestService) {
    super(store);
  }

  hasTestIds(userId: string): boolean {
    return this.hasEntity(userId);
  }

  selectTestIds(userId: string): Observable<TestIds> {
    if (!this.hasTestIds(userId) || (this.hasTestIds(userId) && this.getEntity(userId).loadedAll === false)) {
      this.testService.loadTests(userId).pipe(take(1)).subscribe();
    }
    return this.selectEntity(userId)
      .pipe(filter(data => typeof data !== typeof undefined));
  }

}
