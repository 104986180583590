<div style="padding: 2em;">
  <h1>Pricing Report</h1>
  <div class="clr-row clr-justify-content-center" style="margin-top: 2em">
    <div class="clr-col-xl-8 clr-col-lg-10 clr-col-md-12 clr-col-sm-12">

      <h2 style="margin-top: 3em">Produkte</h2>
      <clr-datagrid>
        <clr-dg-column>Produkt</clr-dg-column>
        <clr-dg-column>Preis</clr-dg-column>
        <clr-dg-column>Aktion</clr-dg-column>

        <clr-dg-row *ngFor="let product of defaultProducePrices$ | async">
          <clr-dg-cell>{{product.product}}</clr-dg-cell>
          <clr-dg-cell>
            <img (click)="openNetPriceChangeModal(product)" alt="edit pencil" class="edit-pencil"
                 src="assets/icons/pencil.svg">
            {{product.netPrice}}
          </clr-dg-cell>
          <clr-dg-cell><a (click)="openSetNetPriceForMastersWithDefaultNetPriceChangeModal(product)">Individueller Preis</a></clr-dg-cell>
        </clr-dg-row>
      </clr-datagrid>

      <div class="input-container">
        <h2>Master</h2>
        <label for="search"></label>
        <input (ngModelChange)="search()" [(ngModel)]="searchKey" id="search" placeholder="Suchen..."
               type="text">
      </div>
      <clr-datagrid>
        <clr-dg-column [clrDgSortBy]="'mail'">E-Mail</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'fullName'">Name</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'userProductPrices.0.netPrice'">Preis</clr-dg-column>

        <clr-dg-row *clrDgItems="let master of masterView">
          <clr-dg-cell>{{master.mail}}</clr-dg-cell>
          <clr-dg-cell>{{master.fullName}}</clr-dg-cell>
          <clr-dg-cell>
            <img (click)="openNetPriceChangeModal(master.userProductPrices[0], master)"
                 alt="edit pencil" class="edit-pencil" src="assets/icons/pencil.svg">
            {{master.userProductPrices[0].product + ' / ' + master.userProductPrices[0].netPrice}}
            <img (click)="deleteCustomPrice(master.userProductPrices[0])"
                 *ngIf="isThisACustomPrice(master.userProductPrices[0])"
                 alt="edit x" class="edit-pencil" src="assets/icons/x.svg">
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [(clrDgPage)]="masterCurrentPage" [clrDgPageSize]="pageSize">
            <span class="pagination-text">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} users
            </span>
            <button (click)="previousPage(masterCurrentPage, 'M')"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow left" src="assets/icons/arrow_pager_left_20x20.svg"/>
            </button>
            <button (click)="nextPage(masterCurrentPage, masterLastPage, 'M')"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow right" src="assets/icons/arrow_pager_right_20x20.svg"/>
            </button>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

      <h2>Partner</h2>
      <clr-datagrid>
        <clr-dg-column [clrDgField]="'mail'">E-Mail</clr-dg-column>
        <clr-dg-column [clrDgField]="'fullName'">Name</clr-dg-column>
        <clr-dg-column [clrDgField]="'userProductPrices.0.netPrice'">Produkt/Preis
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let partner of partnerList">
          <clr-dg-cell>{{partner.mail}}</clr-dg-cell>
          <clr-dg-cell>{{partner.fullName}}</clr-dg-cell>
          <clr-dg-cell>
            <img (click)="openNetPriceChangeModal(partner.userProductPrices[0], partner)"
                 alt="edit pencil" class="edit-pencil" src="assets/icons/pencil.svg">
            {{partner.userProductPrices[0].product + ' / ' + partner.userProductPrices[0].netPrice}}
            <img (click)="deleteCustomPrice(partner.userProductPrices[0])"
                 *ngIf="isThisACustomPrice(partner.userProductPrices[0])"
                 alt="edit x" class="edit-pencil" src="assets/icons/x.svg">
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination2 [(clrDgPage)]="partnerCurrentPage" [clrDgPageSize]="pageSize">
            <span class="pagination-text">
              {{pagination2.firstItem + 1}} - {{pagination2.lastItem + 1}} of {{pagination2.totalItems}} users
            </span>
            <button (click)="previousPage(partnerCurrentPage, 'P')"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow left" src="assets/icons/arrow_pager_left_20x20.svg"/>
            </button>
            <button (click)="nextPage(partnerCurrentPage, partnerLastPage, 'P')"
                    class="id37-btn-outline id37-btn-sm pagination-btn">
              <img alt="arrow right" src="assets/icons/arrow_pager_right_20x20.svg"/>
            </button>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>

    </div>
  </div>
</div>

<id37-modal [(modalOpen)]="isUserProductPriceChangeModalVisible">
  <id37-modal-title>
    Ändere den Default-Preis für ID37-Test
  </id37-modal-title>
  <id37-modal-body [align]="'left'" style="overflow: hidden;">
    <div>Here you can change the User Product Price</div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div [formGroup]="form" class="clr-form-group">
          <label for="new-net-price">Sollte der Netprice sein</label>
          <input formControlName="newNetPrice" id="new-net-price" type="text">
        </div>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="changeNetPriceOfUserProductPrice(userProductPriceToChange)" [disabled]="hasErrors()"
              class="id37-btn-outline" type="button">
        Change
      </button>
      <button (click)="closeNetPriceChangeModal()" class="id37-btn id37-btn-secondary" type="button">
        Cancel
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<id37-modal [(modalOpen)]="setNetPriceForMasterChangeModalVisible">
  <id37-modal-title>
    Setze einen individuellen Preis für Master
  </id37-modal-title>
  <id37-modal-body [align]="'left'" style="overflow: hidden;">
    <div>Mit dieser Funktionalität bekommen alle Master, die keinen individuellen Preis haben, einen individuellen Preis.
      Hiernach werden diese Preise nicht mehr durch die Änderung des Default-Preises beeinflusst.</div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div [formGroup]="form" class="clr-form-group">
          <label for="new-net-price">Sollte der Netprice sein</label>
          <input formControlName="newNetPrice" id="new-net-price" type="text">
        </div>
      </div>
    </div>
  </id37-modal-body>
  <id37-modal-footer [align]="'right'">
    <div class="id37-btn-row">
      <button (click)="setNetPriceForMastersWithDefaultNetPrice(userProductPriceToChange)" [disabled]="hasErrors()"
              class="id37-btn-outline" type="button">
        Change
      </button>
      <button (click)="closeSetNetPriceForMastersWithDefaultNetPriceChangeModal()" class="id37-btn id37-btn-secondary" type="button">
        Cancel
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>
