import {MessageRepresentation} from './message-representation';
import {LocalizedMessage} from './localized-message';
import {Id37Language} from '../../../shared/id37-language';
import {Message} from './message';

export class LocalizedMessageRepresentation {

  public readonly localizedMessage: { [key: string]: MessageRepresentation };

  constructor(localizedMessage: { [key: string]: MessageRepresentation }) {
    this.localizedMessage = localizedMessage;
  }

  public static toModelEntity(localizedMessageRepresentation: LocalizedMessageRepresentation): LocalizedMessage {
    const map = new Map<Id37Language, Message>();
    for (const key in localizedMessageRepresentation.localizedMessage) {
      if (localizedMessageRepresentation.localizedMessage.hasOwnProperty(key)) {
        map.set(Id37Language.fromName(key), localizedMessageRepresentation.localizedMessage[key]);
      }
    }
    return {
      localizedMessage: map
    };
  }

  public static fromModelEntity(localizedMessage: LocalizedMessage): LocalizedMessageRepresentation {
    const map = {};
    localizedMessage.localizedMessage.forEach((value, key) =>
      map[key] = MessageRepresentation.fromModelEntity(value));
    return new LocalizedMessageRepresentation(map);
  }

}
