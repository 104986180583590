import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CurrentUser} from '../user/current-user.service';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private currentUser: CurrentUser,
              private http: HttpClient) {
  }

  public deleteCurrentUserAccount(): Observable<void> {
    return this.http.delete<void>(
      API_URL + '/user',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public deleteAccount(userId: string): Observable<void> {
    return this.http.delete<void>(
      API_URL + '/user/' + userId
    );
  }

  public removeAssociatedMaster(): Observable<void> {
    return this.http.post<void>(
      API_URL + '/user/removeAssociatedMaster',
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

}
