import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {CartRepresentation} from './cart-representation';
import {Cart} from './cart';
import {CartUpdate} from './cart-update';
import {CartUpdateRepresentation} from './cart-update-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient) {
  }

  public getContent(): Observable<Cart> {
    return this.http.get<CartRepresentation>(
      API_URL + '/cart',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((cartRepresentation: CartRepresentation) =>
      Cart.buildFrom(cartRepresentation)
    ));
  }

  public updateContent(cartUpdates: CartUpdate[]): Observable<Cart> {
    return this.http.post<CartRepresentation>(
      API_URL + '/cart',
      cartUpdates,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((cartRepresentation: CartRepresentation) =>
      Cart.buildFrom(cartRepresentation)
    ));
  }

  public replaceContent(cartUpdates: CartUpdate[]): Observable<Cart> {
    return this.http.post<CartRepresentation>(
      API_URL + '/cart/replace-content',
      cartUpdates.map(cartUpdate => CartUpdateRepresentation.buildFrom(cartUpdate)),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((cartRepresentation: CartRepresentation) =>
      Cart.buildFrom(cartRepresentation)
    ));
  }

}
