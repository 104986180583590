import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import {MotiveScore} from '../../test/state/motive-score';
import {Motive, MotiveButton} from '../../report/types/motives/motive';
import {TranslatorService} from '../../translation/translator.service';
import {ChartColor} from '../chart-color';

interface BubbleChartDatasets {
  label: string;
  borderColor: string;
  backgroundColor: string;
  data: BubbleChartData[];
}

export interface BubbleChartData {
  x: number;
  y: number;
  r: number;
}

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss']
})
export class BubbleComponent implements OnInit {

  @Input()
    customerName: string[];

  @Input()
    customerMotiveScore: MotiveScore[][];

  motiveButtons: MotiveButton[] = [];
  filter: number[] = [];

  chart: Chart;
  borderColor: string[] = ChartColor.getChartColors(0.6);
  backgroundColor: string[] = ChartColor.getChartColors(0.3);

  constructor(private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    this.computeMotiveButtons();
    this.buildChart();
  }

  computeMotiveButtons(): void {
    Motive.LIST.forEach((m, i) => {
      if (i < this.customerMotiveScore[0].length) {
        this.motiveButtons.push({
          motive: i,
          displayName: this.translatorService.translate('motive.' + m.valueOf().toLowerCase())
        });
      }
    });
  }

  addFilter(motive: number): void {
    if (this.filter.length < 2) {
      this.filter.push(motive);
    }
  }

  removeFilter(motive: number): void {
    const i = this.filter.findIndex(m => m === motive);
    if (i === 0) {
      this.filter.shift();
    }
    else if (i === 1) {
      this.filter.pop();
    }
    else {
      console.error(this.filter);
    }
  }

  showBubbles(): void {
    this.addScales();
    this.initDataSets();
  }

  displayAsAxes(i: number): string {
    return i === 0 ? 'x' : 'y';
  }

  isSelected(motive: number): boolean {
    const result = this.filter.find(m => m === motive);
    return !!result || result === 0;
  }

  initDataSets(): void {
    const datasets: BubbleChartDatasets[] = [];
    this.customerMotiveScore.forEach((motiveScoreArray: MotiveScore[], index: number) => {

      const datas: BubbleChartData[] = [];
      const selectedMotive: MotiveScore[] = [];

      selectedMotive.push(motiveScoreArray.find(motiveScore => motiveScore.motiveIndex === this.filter[0]));
      selectedMotive.push(motiveScoreArray.find(motiveScore => motiveScore.motiveIndex === this.filter[1]));

      datas.push({x: selectedMotive[0].score, y: selectedMotive[1].score, r: 10});
      datasets.push({
        label: this.customerName[index],
        data: datas,
        backgroundColor: this.backgroundColor[index],
        borderColor: this.borderColor[index]
      });
    });
    this.chart.data.datasets = this.increaseRadio(datasets);
    this.chart.update();
  }

  increaseRadio(datasets: BubbleChartDatasets[]): BubbleChartDatasets[] {
    datasets.forEach((b1, i1) => {
      datasets.forEach((b2, i2) => {
        if (b1.data[0].x === b2.data[0].x && b1.data[0].y === b2.data[0].y && i1 !== i2) {
          datasets[i1].data[0].r += 10;
        }
      });
    });
    return datasets;
  }

  addScales(): void {
    this.chart.options.scales = {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: this.motiveButtons[this.filter[0]].displayName
        },
        ticks: {
          min: 1,
          max: 10,
          stepSize: 1
        }
      }],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: this.motiveButtons[this.filter[1]].displayName
          },
          ticks: {
            min: 1,
            max: 10,
            stepSize: 1
          }
        }
      ]
    };
  }

  buildChart(): void {
    this.chart = new Chart('bubble-canvas', {
      type: 'bubble',
      options: {
        aspectRatio: 1
      }
    });
  }
}
