import {Component, Input} from '@angular/core';
import {Position} from '../position';
import {TranslatorService} from '../../../translation/translator.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent {

  @Input()
    positions: Position[];

  @Input()
    showDiscount: boolean = true;

  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate('order.position.' + key);
  }

}
