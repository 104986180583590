import {Component, Input, ViewChild} from '@angular/core';
import {ScienceText} from '../types/science-text-type';
import {GraphicGaussText} from '../types/graphic-gauss-text';
import {CollapsibleItemComponent} from '../../collapsible/collapsible-item/collapsible-item.component';

@Component({
  selector: 'app-science',
  templateUrl: './science.component.html',
  styleUrls: ['./science.component.scss']
})
export class ScienceComponent {

  @Input()
    scienceText: ScienceText;

  @Input()
    graphicGaussText: GraphicGaussText;

  @Input()
    locale = 'de';

  @ViewChild(CollapsibleItemComponent, {static: true})
    collapsible: CollapsibleItemComponent;

  constructor() {
  }

}
