import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {RecommendationInfo} from '../../recommendation/recommendation-info';
import {RecommendationService} from '../../recommendation/recommendation.service';
import {take} from 'rxjs/operators';
import {UserInviteComponent} from '../user-invite/user-invite.component';
import {RecommendationType} from '../../recommendation/recommendation-type';
import {prices} from '../../product/prices';
import {CurrentUser} from '../current-user.service';



@Component({
  selector: 'app-user-invite-option',
  templateUrl: './user-invite-option.component.html',
  styleUrls: ['./user-invite-option.component.scss']
})
export class UserInviteOptionComponent implements OnInit {

  @ViewChild('userInviteComponent')
    userInviteComponent: UserInviteComponent;

  open: boolean;

  id37TestPrice = prices.id37_test;
  id37TestPriceWithFriendsAndFamily = prices.id37_test_with_friends_and_family;
  friendsAndFamilyDiscountInPercentage = prices.friends_and_family_discount_in_percentage;

  decimalSeparatorLocaleID: string = 'de-DE';


  recommendationFFInfo: RecommendationInfo | undefined = undefined;
  recommendationStandardInfo: RecommendationInfo | undefined = undefined;

  constructor(private currentUser: CurrentUser,
              private recommendationService: RecommendationService,
              private translatorService: TranslatorService) {
  }

  ngOnInit(): void {
    if (this.currentUser.locale === 'en') {
      this.decimalSeparatorLocaleID = 'en-EN';
    }
    this.loadRecommendationInfo();
  }

  loadRecommendationInfo(): void {
    this.recommendationService.loadRecommendationInfo(RecommendationType.FRIENDS_AND_FAMILY).pipe(take(1))
      .subscribe(data => {
        this.recommendationFFInfo = data;
      });
    this.recommendationService.loadRecommendationInfo(RecommendationType.STANDARD).pipe(take(1))
      .subscribe(data => {
        this.recommendationStandardInfo = data;
      });

  }

  show(): void {
    // Directly open the standard recommendation modal if no special recommendation types are available.
    if (!!this.recommendationFFInfo
      && (!this.recommendationFFInfo.canRecommendFriendsAndFamily
        || this.recommendationFFInfo.friendsAndFamilyRecommendationsLeft === 0)) {
      this.clickedOnStandard();
      return;
    }
    // Otherwise open the chooser.
    this.open = true;
  }

  hide(): void {
    this.open = false;
  }

  clickedOnStandard(): void {
    this.hide();
    this.userInviteComponent.show(RecommendationType.STANDARD);
  }

  clickedOnFF(): void {
    this.hide();
    this.userInviteComponent.show(RecommendationType.FRIENDS_AND_FAMILY);
  }

  onRecommendation(): void {
    this.loadRecommendationInfo();
    this.hide();
  }

  __(key: string): string {
    return this.translatorService.translate('userInviteOption.' + key)
      .replace(/\*USAGE\*/g, String(this.recommendationFFInfo.recommendationsLeft));
  }

}
