import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor(private http: HttpClient) {
  }

  public getMetrics(): Observable<string> {
    return this.http.get<string>(
      API_URL + '/metrics',
      {
        headers: new HttpHeaders({
          Accept: 'text/plain'
        }),
        responseType: 'text' as 'json'
      }
    );
  }

}
