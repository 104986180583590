import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Friendship} from '../state/friendship';
import {Subject} from 'rxjs';
import {FriendshipQuery} from '../state/friendship.query';
import {TranslatorService} from '../../translation/translator.service';
import {TestQuery} from '../../test/state/test.query';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss']
})
export class FriendListComponent implements OnInit, OnDestroy {

  @Input()
    isLoading: { [key: string]: boolean };
  @Output()
    resendFriendshipRequest: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    acceptFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    declineFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    deleteFriendship: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    updateTestShare: EventEmitter<Friendship> = new EventEmitter<Friendship>();
  @Output()
    recommend: EventEmitter<void> = new EventEmitter<void>();
  approvedFriendships: Friendship[];
  openInvitationsThatISent: Friendship[];
  openInvitationsThatIReceived: Friendship[];
  inProgressFriendships: Friendship[];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private friendshipQuery: FriendshipQuery,
              private testQuery: TestQuery,
              private translatorService: TranslatorService) {
  }

  ngOnInit() {
    this.friendshipQuery.selectApprovedFriendshipWithStatusDone().pipe(takeUntil(this.unsubscribe$))
      .subscribe(friendships => this.approvedFriendships = friendships);

    this.friendshipQuery.selectInProgressFriendships().pipe(takeUntil(this.unsubscribe$))
      .subscribe(friendships => this.inProgressFriendships = friendships);

    this.friendshipQuery.selectOpenInvitationsThatISent().pipe(takeUntil(this.unsubscribe$))
      .subscribe(friendships => this.openInvitationsThatISent = friendships);

    this.friendshipQuery.selectOpenInvitationsThatIReceived().pipe(takeUntil(this.unsubscribe$))
      .subscribe(friendships => this.openInvitationsThatIReceived = friendships);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  __(key: string): string {
    return this.translatorService.translate('friendList.' + key);
  }

  ___(key: string): string {
    return this.translatorService.translate(key);
  }

}
