<div [ngClass]="{'sidebar-collapsed': sidebarCollapsed}" class="report-content">
  <div class="clr-row clr-justify-content-center margin-0">
    <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">
      <div class="report">

        <div *ngIf="reportOfClient" class="scroll-anchor" id="client-info-anchor"></div>
        <div *ngIf="reportOfClient" class="report-element client-info" id="client-info">
          <app-client-info [clientAccountData]="humanReadableAccountData"></app-client-info>
        </div>

        <div class="scroll-anchor" id="intro-anchor"></div>
        <div class="report-element intro spacing-top" id="intro">
          <app-intro
              (shouldScrollToScienceText)="
                scrollService.scrollToElement('science');
                science.collapsible.setExpanded(true)
              "
              [introText]="report.introText"
              [locale]="locale"
              [masterId]="!!humanReadableAccountData.masterInfo
                ? humanReadableAccountData.masterInfo.userId
                : undefined"
          >
          </app-intro>
        </div>

        <div class="report-element profile spacing-top">
          <app-profile [profileText]="report.profileText"></app-profile>
        </div>

        <div class="report-element key-graphic main-key-graphic spacing-top">
          <app-key-graphic-print-layout
              (shouldBePrinted)="printMotiveProfile()"
              [name]="humanReadableAccountData.personalData.firstName
                + ' ' + humanReadableAccountData.personalData.lastName"
              [results]="[motiveScores]">
          </app-key-graphic-print-layout>
        </div>

        <div class="report-element interpretation spacing-top">
          <app-interpretation
              [interpretationText]="report.interpretationText"
              [masterId]="!!humanReadableAccountData.masterInfo
                ? humanReadableAccountData.masterInfo.userId
                : undefined">
          </app-interpretation>
        </div>

        <div class="report-element profile-intro spacing-top">
          <app-profile-intro [profileIntroText]="report.profileIntroText"></app-profile-intro>
        </div>

        <div *ngIf="!!report.profileNarrowingsText" class="report-element profile-narrowings">
          <app-profile-narrowings
              [profileNarrowingsText]="report.profileNarrowingsText"></app-profile-narrowings>
        </div>

        <div *ngIf="!!report.profileSpecialText" class="report-element profile-special">
          <app-profile-special [profileSpecialText]="report.profileSpecialText"></app-profile-special>
        </div>

        <div class="report-element profile-example">
          <app-profile-example [profileExample]="report.profileExampleText"></app-profile-example>
        </div>

        <div class="report-element key-graphic example-key-graphic">
          <app-key-graphic-example></app-key-graphic-example>
        </div>

        <div class="report-element profile-application spacing-top">
          <app-profile-application
              (shouldPrintReportEmitter)="printReport()"
              (shouldScrollToMotivesDescriptionText)=" scrollService.scrollToElement('motives-description')"
              [masterId]="!!humanReadableAccountData.masterInfo
                ? humanReadableAccountData.masterInfo.userId
                : undefined"
              [profileApplicationText]="report.profileApplicationText">
          </app-profile-application>
        </div>

        <div class="report-element explanation-motives small-spacing-top">
          <app-explanation-motives
              [explanationMotivesText]="report.explanationMotivesText"></app-explanation-motives>
        </div>

        <div class="scroll-anchor" id="science"></div>
        <div class="report-element science small-spacing-top">
          <app-science
            #science
            [graphicGaussText]="report.graphicGaussText"
            [locale]="locale"
            [scienceText]="report.scienceText">
          </app-science>
        </div>

        <div class="report-element how-motives-work small-spacing-top">
          <app-how-motives-work [howMotivesWorkText]="report.howMotivesWorkText"></app-how-motives-work>
        </div>

        <div class="scroll-anchor" id="motives-description"></div>
        <div class="report-element motives-description-text spacing-top">
          <app-motives-description
              [motivesDescriptionText]="report.motivesDescriptionText"></app-motives-description>
        </div>

        <!-- MOTIVES -->
        <app-collapsible-list [type]="'expandable'">
          <ng-container *ngFor="let motiveName of motives">
            <div class="scroll-anchor" id="motive-{{motiveName.toLowerCase()}}"></div>
            <div *ngIf="!!report.motiveTexts[motiveName.toLowerCase()]"
                 class="report-element motive motive-spacing">
              <app-motive
                  [motiveName]="motiveName.toLowerCase()"
                  [motiveText]="report.motiveTexts[motiveName.toLowerCase()]">
              </app-motive>
            </div>
          </ng-container>
        </app-collapsible-list>

        <div *ngIf="!humanReadableAccountData.masterInfo"
             class="report-element find-master spacing-top" id="find-master">
          <app-find-master [findMasterText]="report.findMasterText"></app-find-master>
        </div>

        <div class="scroll-anchor" id="book-anchor"></div>
        <div class="report-element buy-book spacing-top" id="book">
          <app-buy-book [buyBookText]="report.buyBookText"></app-buy-book>
        </div>

        <div class="scroll-anchor" id="contact-anchor"></div>
        <div class="report-element contact spacing-top" id="contact">
          <app-contact [contactText]="report.contactText"></app-contact>
        </div>

        <div class="scroll-anchor" id="print-anchor"></div>
        <div class="report-element print-document spacing-top" id="print">
          <app-print-document
              (shouldPrintReportEmitter)="printReport()"
              [printDocumentText]="report.printDocumentText">
          </app-print-document>
        </div>
      </div>
    </div>
  </div>
</div>
