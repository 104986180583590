import {Component, OnInit} from '@angular/core';
import {AdminService} from '../admin.service';
import {Observable} from 'rxjs';
import {AdminReport} from '../types/admin-report-type';
import {BillingReport} from '../types/billing-type';

@Component({
  selector: 'app-adminreport',
  templateUrl: './adminreport.component.html',
  styleUrls: ['./adminreport.component.scss']
})
export class AdminreportComponent implements OnInit {

  adminReport: AdminReport;
  billingReports$: Observable<BillingReport[]>;

  constructor(private adminService: AdminService) {
  }

  ngOnInit() {
    this.adminService.adminReport()
      .subscribe(report => this.adminReport = report);
    this.billingReports$ = this.adminService.billingReport();
  }
}
