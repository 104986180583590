import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MotiveScores} from '../../test/state/motive-score';
import {takeUntil} from 'rxjs/operators';
import {TestQuery} from '../../test/state/test.query';
import {Subject} from 'rxjs';
import {CurrentUser} from '../../user/current-user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-distance-graphic-test',
  templateUrl: './distance-graphic-test.component.html',
  styleUrls: ['./distance-graphic-test.component.scss']
})
export class DistanceGraphicTestComponent implements OnInit {

  @ViewChild('svgwrapper', {static: true})
    svgWrapper: ElementRef;

  private unsubscribe$: Subject<void> = new Subject<void>();
  clientId = '';
  clientName = '';
  private testId: string;
  motiveScores: MotiveScores[] = undefined;
  minimumNumberOfMotives: number = 15;
  normalizedResults: MotiveScores[];

  constructor(private currentUser: CurrentUser,
              private testQuery: TestQuery,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.clientId = this.currentUser.keycloakId;
    this.clientName = this.currentUser.firstName + ' ' + this.currentUser.lastName;

    this.route.params.subscribe(params => {
      console.log(params.testId);
      this.testId = params.testId;
    });
    // this.testId = 'f2e10534-5e8c-49de-a97d-f15f0ccb776e';
    setTimeout(() => {
      this.loadResult();
    }, 1000);
  }

  loadResult() {
    this.testQuery.selectTest(this.clientId, this.testId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(test => this.motiveScores = new Array(test.result.motiveScores));
  }

}
