import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Team} from '../state/team';
import {Layout} from '../team.component';
import {TranslatorService} from '../../translation/translator.service';
import {UserInviteOptionComponent} from '../../user/user-invite-option/user-invite-option.component';
import {ViewportScroller} from '@angular/common';

@Component({
  selector: 'app-team-body',
  templateUrl: './team-body.component.html',
  styleUrls: ['./team-body.component.scss']
})
export class TeamBodyComponent implements OnInit {

  @ViewChild('userInviteOptionComponent') userInviteOptionComponent: UserInviteOptionComponent;

  @Input() selectedTeam: Team;

  @Input() maxTestvariation: number;

  @Input() layout: Layout;

  constructor(private translatorService: TranslatorService,
              private vS: ViewportScroller) {
  }

  ngOnInit(): void {
  }

  __(key: string): string {
    return this.translatorService.translate('team.' + key);
  }

  print(): void {
    this.vS.scrollToPosition([0, 0]);
    window.print();
  }

  openRecommendModal(): void {
    this.userInviteOptionComponent.show();
  }
}
