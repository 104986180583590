import { Component } from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-chatbot-error-screen',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './chatbot-error-screen.component.html',
  styleUrl: './chatbot-error-screen.component.scss'
})
export class ChatbotErrorScreenComponent {

  constructor(private translatorService: TranslatorService) {
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-error-screen.' + key);
  }

}
