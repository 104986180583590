import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FriendsComponent} from './friends.component';
import {FriendListComponent} from './friend-list/friend-list.component';
import {RouterModule} from '@angular/router';
import {Id37Module} from '../id37/id37.module';
import {ProfilePictureModule} from '../profile-picture/profile-picture.module';
import {ClarityModule} from '@clr/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FriendTileComponent} from './friend-tile/friend-tile.component';
import {UserModule} from '../user/user.module';
import {DeleteFriendModalComponent} from './delete-friend-modal/delete-friend-modal.component';
import {FriendSearchInputComponent} from './friend-search-input/friend-search-input.component';
import {FriendSearchOutputComponent} from './friend-search-output/friend-search-output.component';
import {FriendCompareSiteComponent} from './friend-compare-site/friend-compare-site.component';
import {CircularRingModule} from '../circular-ring/circular-ring.module';
import {DistanceGraphicModule} from '../distance-graphic/distance-graphic.module';
import {TestModule} from '../test/test.module';
import {ModalModule} from '../modal/modal.module';
import {FriendTileContainerComponent} from './friend-tile-container/friend-tile-container.component';
import {DirectivesModule} from '../directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Id37Module,
    ProfilePictureModule,
    ClarityModule,
    FormsModule,
    UserModule,
    CircularRingModule,
    DistanceGraphicModule,
    ReactiveFormsModule,
    TestModule,
    ModalModule,
    DirectivesModule
  ],
  declarations: [
    FriendsComponent,
    FriendListComponent,
    FriendTileComponent,
    DeleteFriendModalComponent,
    FriendSearchInputComponent,
    FriendSearchOutputComponent,
    FriendCompareSiteComponent,
    FriendTileContainerComponent
  ],
  exports: [
    FriendsComponent
  ]
})
export class FriendModule {
}
