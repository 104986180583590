import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AdminService} from '../admin.service';
import {AdminProductPrice} from '../types/admin-product-price';
import {UserProductPrice} from '../../account/user-product-price';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ClrDatagridStringFilterInterface} from '@clr/angular';

class MyStringFilter implements ClrDatagridStringFilterInterface<string> {
  accepts(item: string, search: string): boolean {
    return '' + item === search.toLowerCase() || item.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  }
}

@Component({
  selector: 'app-pricing-report',
  templateUrl: './pricing-report.component.html',
  styleUrls: ['./pricing-report.component.scss']
})
export class PricingReportComponent implements OnInit, OnDestroy {

  defaultProducePrices$: Observable<UserProductPrice[]>;
  myStringFilter = new MyStringFilter();
  searchKey: string = '';
  masterList: AdminProductPrice[] = [];
  masterView: AdminProductPrice[] = [];
  masterCurrentPage: number = 1;
  masterLastPage: number = 5;
  partnerList: AdminProductPrice[] = [];
  partnerCurrentPage: number = 1;
  partnerLastPage: number = 5;
  partnerTotal = 0;
  pageSize: number = 20;
  form: FormGroup;
  isUserProductPriceChangeModalVisible: boolean = false;
  setNetPriceForMasterChangeModalVisible: boolean = false;
  userProductPriceToChange: UserProductPrice = undefined;
  adminProductPriceToChangeFor: AdminProductPrice = undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private adminService: AdminService) {
  }

  ngOnInit() {
    this.loadMasters();
    this.loadPartners();
    this.defaultProducePrices$ = this.adminService.getDefaultProductPrices();

    this.form = new FormGroup({
      newNetPrice: new FormControl('', [Validators.required,
        Validators.pattern('^[[0-9]+([.|,][0-9]{1,2}){0,1}$')])
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadMasters(): void {
    this.adminService.getAllMasters()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((adminProductPrice: AdminProductPrice[]) => {
        this.masterList = adminProductPrice;
        this.masterView = this.masterList;
        this.masterLastPage = Math.ceil(adminProductPrice.length / this.pageSize);
      });
  }

  loadPartners(): void {
    this.adminService.getAllPartners()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((adminProductPrice: AdminProductPrice[]) => {
        this.partnerList = adminProductPrice;
        this.partnerLastPage = Math.ceil(adminProductPrice.length / this.pageSize);
      });
  }

  openNetPriceChangeModal(userProductPrice: UserProductPrice, adminProductPrice?: AdminProductPrice): void {
    this.userProductPriceToChange = userProductPrice;
    if (adminProductPrice) {
      this.adminProductPriceToChangeFor = adminProductPrice;
    }
    this.isUserProductPriceChangeModalVisible = true;
  }

  closeNetPriceChangeModal(): void {
    this.form.reset();
    this.isUserProductPriceChangeModalVisible = false;
  }

  changeNetPriceOfUserProductPrice(userProductPrice: UserProductPrice) {
    userProductPrice.netPrice = this.form.value.newNetPrice;
    if (this.adminProductPriceToChangeFor === undefined) {
      this.updateChange(userProductPrice);
    }
    else {
      if (userProductPrice.userKeycloakId === undefined) {
        userProductPrice.userKeycloakId = this.adminProductPriceToChangeFor.keycloakId;
      }
      this.updateChange(userProductPrice);
    }
  }

  openSetNetPriceForMastersWithDefaultNetPriceChangeModal(userProductPrice: UserProductPrice): void {
    this.setNetPriceForMasterChangeModalVisible = true;
    this.userProductPriceToChange = userProductPrice;
  }

  closeSetNetPriceForMastersWithDefaultNetPriceChangeModal(): void {
    this.form.reset();
    this.isUserProductPriceChangeModalVisible = false;
  }

  setNetPriceForMastersWithDefaultNetPrice(userProductPrice: UserProductPrice) {
    userProductPrice.netPrice = this.form.value.newNetPrice;
    this.adminService.setNetPriceForMastersWithDefaultPrice(userProductPrice)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.resetEverything();
      },
      error => {
        console.log(error);
      });
  }

  public isThisACustomPrice(price: UserProductPrice): boolean {
    return !!price.userKeycloakId;
  }

  public deleteCustomPrice(price: UserProductPrice) {
    this.adminService.deleteUserProductPrice(price)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.resetEverything();
      },
      error => {
        console.log(error);
      });
  }

  hasErrors(): boolean {
    return !!this.form.get('newNetPrice').errors;
  }

  search(): void {
    if (this.searchKey.length !== 0) {
      const filteredView: AdminProductPrice[] = [];
      this.masterList.forEach((m) => {
        if (this.myStringFilter.accepts(m.mail, this.searchKey) ||
          this.myStringFilter.accepts(m.fullName, this.searchKey)) {
          filteredView.push(m);
        }
      });
      this.masterView = filteredView;
    }
    else {
      this.masterView = this.masterList;
    }
  }

  nextPage(currentPage: number, lastPage: number, list: string | 'M' | 'P'): void {
    if (currentPage + 1 <= lastPage) {
      list === 'M' ? this.masterCurrentPage = currentPage + 1 : this.partnerCurrentPage = currentPage + 1;
    }
  }

  previousPage(currentPage: number, list: string | 'M' | 'P'): void {
    if (currentPage - 1 >= 1) {
      list === 'M' ? this.masterCurrentPage = currentPage - 1 : this.partnerCurrentPage = currentPage - 1;
    }
  }

  private updateChange(userProductPrice: UserProductPrice) {
    this.adminService.updateUserProductPrice(userProductPrice)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.resetEverything();
      },
      error => {
        console.log(error);
      });
  }

  private resetEverything() {
    this.updateUsers();
    this.form.reset();
    this.isUserProductPriceChangeModalVisible = false;
    this.setNetPriceForMasterChangeModalVisible = false;
    this.userProductPriceToChange = undefined;
    this.adminProductPriceToChangeFor = undefined;
  }

  private updateUsers() {
    this.loadMasters();
    this.loadPartners();
    this.defaultProducePrices$ = this.adminService.getDefaultProductPrices();
  }

}
