<div class="clr-row clr-justify-content-center margin-0" >
  <div class="clr-col-xl-9 clr-col-lg-10 clr-col-md-11 clr-col-sm-12">

    <div class="clr-row clr-justify-content-center" style="margin-top: 60px; margin-bottom: 50px;">
      <div class="clr-col-12">
        <h2>
          {{__('heading')}}
        </h2>
        <button (click)="onCreate()" class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 add-docs-button">
          <img alt="plus" src="assets/icons/add_onButton.svg"/>
          <span>{{__('button-add-document')}}</span>
        </button>
        <button class="id37-btn-outline id37-btn-sm id37-btn-rounded-2 add-docs-button"
                (click)="openImportModal()">
          <img alt="plus" src="assets/icons/add_onButton.svg"/>
          <span>{{__('button-import-document')}}</span>
        </button>
      </div>
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-12">
        <div class="clr-row">
          <h3 class="clr-col-8 existing-docs-title">
            {{__('existing-docs')}} ({{documentList.length}})
          </h3>
          <div class="clr-col-4">
            <div class="search-input-container">
              <input (input)="applyFilter()" [(ngModel)]="searchKey" autocomplete="off" placeholder="{{__('search-field')}}"/>
              <img (click)="onClear()" *ngIf="searchKey" alt="X" src="assets/icons/ID37_X.svg"/>
              <img *ngIf="!searchKey" src="assets/icons/arrow_link_12x12.svg"/>
            </div>
          </div>
        </div>
        <clr-datagrid class="cb-docs-datagrid">
          <clr-dg-column [clrDgField]="'ID'"  class="cb-docs-col " >
            <div class="cell-label">{{__('column-id')}}</div>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'title'" class="cb-docs-col " >
            <div class="cell-label">{{__('column-title')}}</div>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'summary'" class="cb-docs-col " >
            <div class="cell-label">{{__('column-summary')}}</div>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'content'" class="cb-docs-col " >
            <div class="cell-label">{{__('column-content')}}</div>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'tokens'" class="cb-docs-col " >
            <div class="cell-label">{{__('column-tokens')}}</div>
          </clr-dg-column>
          <clr-dg-column class="cb-docs-col ">
            <div class="cell-label">{{__('column-actions')}}</div>
          </clr-dg-column>

          <ng-container *ngIf="!loading">
            <clr-dg-row *clrDgItems="let d of documentView" class="cb-docs-clr-dg-row">
              <clr-dg-cell class="cell-field cb-id-col-width ">
                <div class="align cb-id">{{d.document_id}}</div>
              </clr-dg-cell>
              <clr-dg-cell class="cell-field cb-title-col-width">
                <div class="align cb-title">{{d.title}}</div>
              </clr-dg-cell>
              <clr-dg-cell class="cell-field cb-summary-col-width ">
                <div class="align cb-summary">{{d.summary}}</div>
              </clr-dg-cell>
              <clr-dg-cell class="cell-field cb-content-col-width">
                <div class="cb-content" [ngClass]="{'expanded':d.show_more}" >
                  <div [innerHTML]="truncateContent(d)"></div>
                  <button (click)="toggleContent(d)" *ngIf="d.show_more" class="id37-btn id37-btn-sm id37-btn-transparent ">
                    {{__('button-toggle-content-less')}}
                  </button>
                  <button (click)="toggleContent(d)" *ngIf="!d.show_more && d.content.length > maxContentLength" class="id37-btn id37-btn-sm id37-btn-transparent">
                    {{__('button-toggle-content-more')}}
                  </button>
                </div>
              </clr-dg-cell>
              <clr-dg-cell class="cell-field cb-token-col-width">
                <div class="align cb-token">{{d.token_length}}</div>
              </clr-dg-cell>
              <clr-dg-cell class="cell-field cb-action-col-width">
                <div class="cb-actions">
                  <button (click)="onEdit(d)" class="id37-btn id37-btn-secondary ">{{__('button-edit')}}</button>
                  <button (click)="onDelete(d)" class="id37-btn id37-btn-danger ">{{__('button-delete')}}</button>
                </div>
              </clr-dg-cell>
            </clr-dg-row>
          </ng-container>


          <clr-dg-footer>
            <clr-dg-pagination #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="pageSize">
              <table class="footer">
                <tr>
                  <td>
                    <span class="footer-text">
                      {{__('page')}} {{pagination.currentPage}} {{__('of')}} {{pagination.lastPage}}
                    </span>
                  </td>
                  <td>
                    <button (click)="previousPage()" class="id37-btn-outline pagination-btn"
                            [disabled]="(currentPage < 1) || loading">
                      <img src="assets/icons/arrow_pager_left_20x20.svg" alt="arrow left"/>
                    </button>
                  </td>
                  <td style="padding-left: 8px;">
                    <button (click)="nextPage()" class="id37-btn-outline pagination-btn"
                            [disabled]="(this.currentPage === this.lastPage ) || loading">
                      <img src="assets/icons/arrow_pager_right_20x20.svg" alt="arrow right"/>
                    </button>
                  </td>
                </tr>
              </table>
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>
</div>

<app-loading-indicator *ngIf="loading"></app-loading-indicator>

<id37-modal [(modalOpen)]="clickOnEdit" [modalSize]="'lg'">
  <id37-modal-title>{{__('edit-modal-title')}}</id37-modal-title>
  <id37-modal-body>
    <app-chatbot-document-form (refreshListonUpdate)="this.getDocumentList()"
                           (updateClickOnEdit)="getUpdatedValue($event)"
                               [edit] = "getDocumentFormService().edit"
                           >
    </app-chatbot-document-form>
  </id37-modal-body>
</id37-modal>

<id37-modal [(modalOpen)]="clickDelete">
  <id37-modal-title>
    {{__('delete-modal-title')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('delete-modal-body')}}
  </id37-modal-body>
  <id37-modal-footer>
    <div class="id37-btn-row">
      <button (click)="onDeleteConfirm(); closeDeleteModal()" class="id37-btn id37-btn-danger"
              type="button">
        {{__('delete-modal-button-delete')}}
      </button>
      <button (click)="closeDeleteModal()" class="id37-btn id37-btn-secondary"
              type="button">
        {{__('delete-modal-button-cancel')}}
      </button>
    </div>
  </id37-modal-footer>
</id37-modal>

<id37-modal [(modalOpen)]="clickOnImport">
  <id37-modal-title>
    {{__('import-modal-title')}}
  </id37-modal-title>
  <id37-modal-body>
    {{__('import-modal-body')}}
  </id37-modal-body>
  <id37-modal-footer>
    <div class="card">
      <div *ngIf="selectedFile" class="card-header">
        <clr-icon shape="file"></clr-icon>
        {{selectedFile.name}}
      </div>
      <div class="card-block">
        <input #fileInput (change)="onFileChanged($event)"
               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
               style="display: none" type="file">

        <button (click)="fileInput.click()" class="id37-btn-outline">
          {{__('import-modal-btnSelectFile')}}
        </button>
        <button (click)="onImportFile()" *ngIf="selectedFile"
                class="id37-btn-outline">
          {{__('import-modal-btnDoUpload')}}
        </button>
        <button (click)="closeImportModal()" class="id37-btn id37-btn-secondary"
                type="button">
          {{__('delete-modal-button-cancel')}}
        </button>
      </div>
      <div class="card-footer">
        <div class="card-text">
          <div *ngIf="inProgress" class="progress loop">
            <progress></progress>
          </div>
          <div *ngIf="success && failureState !== true" class="alert alert-success" role="alert">
            <div class="alert-items">
              <div class="alert-item static">
                <div class="alert-icon-wrapper">
                  <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                </div>
                <span class="alert-text">{{__('import-modal-success')}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="failureState">
            <div *ngFor="let error of failure.importErrors" class="alert alert-danger" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                  <span class="alert-text">{{__('import-modal-errorInRow')}} {{error.rowNumber}}</span>
                </div>
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                  <span class="alert-text">{{error.errorMessage}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </id37-modal-footer>
</id37-modal>
