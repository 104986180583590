import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ChatbotChatCreateAdminFormService {

  constructor(private formBuilder: FormBuilder) { }

  getAdminForm() {
    return this.formBuilder.group(
      {
        NEU: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        SAN: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        EIN: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        STA: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        BES: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        AUT: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        SOZ: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        PRI: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        SEN: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        STR: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        SIC: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        REV: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        BEW: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        ESS: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        FAM: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]],
        SIN: ['', [Validators.required, Validators.pattern('^([1-9]|10)$'), Validators.min(1), Validators.max(10)]]
      }
    );
  }
}
