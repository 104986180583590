import {Component, Input} from '@angular/core';
import {MotivesDescriptionText} from '../types/motives-description-text';

@Component({
  selector: 'app-motives-description',
  templateUrl: './motives-description.component.html',
  styleUrls: ['./motives-description.component.scss']
})
export class MotivesDescriptionComponent {

  @Input()
    motivesDescriptionText: MotivesDescriptionText;

}
